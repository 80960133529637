import { HraAnswer, HraAnswerMap } from './hra-answer';
import { HraCategoryTypes } from './hra-category';
import { HraResult } from './hra-result';
import { HraScoreCategoryTypes } from './hra-score-category';
import { HraScores } from './hra-scores';
import { UserResult } from '../user-result/user-result';
import { LabResultCode, UserTest } from '../user-test/user-test';
import { UserResultTestResult } from '../user-result/user-result-test-result';

/**
 * The question prompt shown to the user.
 */
export enum HraQuestionType {
  SINGLE = 'single',
  MULTI = 'multi',
  CUSTOM = 'custom'
}

/**
 * Function that needs to return true or false to
 * show and hide the field.
 */
export type HraFormShow = (params: { record: Partial<HraResult> }) => boolean;

/**
 * Custom function that returns the value for the given question.
 * This is used for any "custom value" calculations that doesn't match
 * with the base model of adding the answers, or questions.
 */
export type HraFormCustomValue = (params: {
  record: HraResult;
  question: HraQuestion;
  answerMap: HraAnswerMap;
  /**
   * The corresponding user-result, if there is one
   * for this hra-result.
   */
  userResult: UserResult;
  /**
   * Map of user-tests where the key is
   * the code of the user-test, and the value is the user-test and result
   */
  flatUserTestMap: Record<
    LabResultCode,
    {
      userTest: UserTest;
      userResultTestResult: UserResultTestResult;
    }
  >;
  /**
   * Default handler that can be called to "skip"
   * the custom error handler logic and just use the default
   * handling.
   */
  defaultFn: (params: {
    record: HraResult;
    question: HraQuestion;
    answerMap: HraAnswerMap;
  }) => number | undefined;
}) => number;

/**
 * Custom function that returns the **entire** hra-scores.
 * This provides more control over the customValue property by allowing
 * us to change multiple values in the final result,
 * rather than just 1.
 */
export type HraFormCustomScoreCalc = (params: {
  record: HraResult;
  question: HraQuestion;
  answerMap: HraAnswerMap;
  scores: HraScores;
  /**
   * The corresponding user-result, if there is one
   * for this hra-result.
   */
  userResult: UserResult;
  /**
   * Map of user-tests where the key is
   * the code of the user-test, and the value is the user-test and result
   */
  flatUserTestMap: Record<
    LabResultCode,
    {
      userTest: UserTest;
      userResultTestResult: UserResultTestResult;
    }
  >;
}) => HraScores;

/**
 * An Hra question defines the question the user
 * sees in the hra form.
 */
export interface HraQuestion {
  /**
   * The category this question is shown in. Only used
   * for display purposes within the hra form.
   */
  category: HraCategoryTypes;
  /**
   * The category this question is calculated with when
   * determining the values later.
   */
  scoreCategory: HraScoreCategoryTypes | HraScoreCategoryTypes[];
  /**
   * The unique code for the question, usually is a string
   * of the question number. This is a string due to question codes
   * potentially having letters.
   */
  code: string;
  /**
   * The question's description that is shown to the user.
   */
  description: string;
  /**
   * The list of available answers.
   */
  answers: HraAnswer[];
  /**
   * The type of the question, currently only
   * "single" and "multi" are available, which represent
   * a radio button or checkbox group.
   */
  type: HraQuestionType;
  /**
   * A question can be set to "noForm" if we are to now show it on
   * the form, so we wont display it or consider it a field we
   * need to handle on the HRA. It can still be used in the results,
   * or for defining other aspects, and its store still be calculated
   */
  noForm?: boolean;
  /**
   * Function that we can define to show the field or not.
   * If this is not given the field is always shown
   */
  show?: HraFormShow;
  /**
   * Function that can be given if we are to calculate a custom
   * value for this given question. Used during score calculations
   */
  customValue?: HraFormCustomValue;
  /**
   * Function that can be given to override the customValue definition function,
   * and is used during score calculations to apply each value with more
   * control then the default approach.
   */
  customScoreCalc?: HraFormCustomScoreCalc;
}
