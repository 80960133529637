/* eslint-disable @typescript-eslint/ban-types */
import {
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector
} from '@ngrx/store';

/**
 * This is a factory function that creates
 * selectors for ehs-reg-filters, from the router-reducer state.
 */
export const ehsRegFiltersSelectorsFactory = ({
  selectQueryParam
}: {
  selectQueryParam: (
    param: string
  ) => MemoizedSelector<
    object,
    string | undefined,
    DefaultProjectorFn<string | undefined>
  >;
}) => {
  const eventDateSelector = selectQueryParam('eventDate');
  const startDateSelector = selectQueryParam('startDate');
  const releasedSelector = selectQueryParam('released');
  const endDateSelector = selectQueryParam('endDate');
  const adminSelector = selectQueryParam('admin');
  // Adding search to allFilters to pass to ehs-reg-filters
  const searchSelector = selectQueryParam('search');
  const rackSelector = selectQueryParam('rack');
  const screeningSelector = selectQueryParam('screeningFilter');
  const beginEndVaccinationSelector = selectQueryParam('vaccinationAction');

  return {
    // General selectors
    searchSelector: createSelector(
      selectQueryParam('search'),
      (search) => search || ''
    ),

    // Onsite-specific selectors
    eventDateSelector,
    startDateSelector,
    endDateSelector,
    adminSelector,
    rackSelector,
    releasedSelector,
    screeningSelector,
    beginEndVaccinationSelector,

    // Offsite-specific selectors
    // provider-specific selectors

    // **note** this might be removed later?
    allFiltersSelector: createSelector(
      searchSelector,
      eventDateSelector,
      startDateSelector,
      endDateSelector,
      adminSelector,
      rackSelector,
      releasedSelector,
      screeningSelector,
      beginEndVaccinationSelector,
      (
        search,
        eventDate,
        startDate,
        endDate,
        admin,
        rack,
        released,
        screeningFilter,
        vaccinationAction
      ) => ({
        search,
        eventDate,
        startDate,
        endDate,
        admin,
        rack,
        released,
        screeningFilter,
        vaccinationAction
      })
    )
  };
};
