import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { EhsUserRegistrationListComponent } from './ehs-user-registration-list.component';
import { PipesModule } from '../../pipes/pipes.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { EhsCardLabelModule } from '../ehs-card-label/ehs-card-label.module';
import { EhsCardSeparatorModule } from '../ehs-card-separator/ehs-card-separator.module';

@NgModule({
  declarations: [EhsUserRegistrationListComponent],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    // Angular Material
    FlexLayoutModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatExpansionModule,
    EhsCardLabelModule,
    EhsCardSeparatorModule
  ],
  exports: [EhsUserRegistrationListComponent]
})
export class EhsUserRegistrationListModule {}
