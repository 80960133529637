/** Script Tag to load the OneTrust cookie policy */
export const COOKIE_LAW_SCRIPT_URL =
  'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';

export const COOKIE_LAW_SCRIPT_BANNER_URL =
  'https://cdn.cookielaw.org/scripttemplates/202211.2.0/otBannerSdk.js';

/** Attribute required to be applied on the Script tag while loading the cookie policy script */
export const COOKIE_DATA_DOMAIN_SCRIPT = 'data-domain-script';

/** We use this to remove the Cookie Policy Styles from being loaded once the user leaves the Login Page */
export const ONE_TRUST_STYLE_ID = 'onetrust-style';

export const ONE_TRUST_CONSENT_ID = 'onetrust-consent-sdk';
