import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { EhsCompanyOverviewComponent } from './ehs-company-overview.component';

@NgModule({
  declarations: [EhsCompanyOverviewComponent],
  imports: [
    CommonModule,

    // Angular Material
    FlexLayoutModule
  ],
  exports: [EhsCompanyOverviewComponent]
})
export class EhsCompanyOverviewModule {}
