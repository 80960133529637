<div class="ehs-reg-table ehs-table-wrapper">
  <cdk-virtual-scroll-viewport itemSize="40"
                               minBufferPx="40"
                               maxBufferPx="500"
                               class="viewport">
    <table class="ehs-table sticky-header sticky-last">
      <tr>
        <th *ngFor="let col of propColumns; let i = index"
            [class]="col.prop"
            [style.top]="headerTop"
            [style.--width]="i === propColumns.length - 1 ? '100%' : (col.width || '200px')">
          <ng-container [ngSwitch]="col.prop">
            <ng-container *ngSwitchCase="'checkbox'">
              <mat-checkbox [indeterminate]="indeterminate"
                            [checked]="!!allAreSelected"
                            (change)="toggleAll.emit()"
                            [disabled]="disabled"
                            color="primary">
              </mat-checkbox>
            </ng-container>
            <ng-container *ngSwitchCase="'groupType'">
              <div fxLayout="row"
                   fxLayoutAlign="space-between center">{{ col.columnTitle }}
                <!--Collapse button for group type-->
                <button (click)="collapse($event)"
                        mat-button
                        color="primary"
                        type="button"
                        class="icon-small"
                        title="collapse">
                  <mat-icon>{{collapsed ? 'unfold_less' : 'unfold_more'}}</mat-icon>
                </button>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ col.columnTitle }}
            </ng-container>
          </ng-container>
        </th>

        <th *ngIf="actionColumn"
            fxLayout="row"
            fxLayoutAlign="center"
            [style.top]="headerTop">
          <button *ngIf="!disabled"
                  mat-button
                  color="primary"
                  type="button"
                  title="Refreshes the list of user-registrations for this event"
                  (click)="handleRefresh()">
            <mat-icon class="icon-small">refresh</mat-icon>
          </button>
          <mat-spinner *ngIf="disabled"
                       [diameter]="22"
                       style="margin: auto;"></mat-spinner>
        </th>
      </tr>

      <tr *cdkVirtualFor="let userRegistration of userRegistrations">
        <td *ngFor="let col of propColumns"
            [class]="col.prop">
          <ng-container [ngSwitch]="col.prop">
            <ng-container *ngSwitchCase="'checkbox'">
              <div (click)="$event.stopPropagation()"
                   *ngIf="!clientAdmin">
                <mat-checkbox color="primary"
                              [disabled]="disabled"
                              [checked]="isSelected(userRegistration)"
                              (change)="toggle.emit(userRegistration)">
                </mat-checkbox>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'lastName'">
              {{ getUser(userRegistration)?.lastName || '' }}
            </ng-container>

            <ng-container *ngSwitchCase="'firstName'">
              {{ getUser(userRegistration)?.firstName || '' }}
            </ng-container>

            <ng-container *ngSwitchCase="'ssn'">
              {{ getUser(userRegistration)?.ssn || '' }}
            </ng-container>

            <ng-container *ngSwitchCase="'birthDay'">
              {{ getUser(userRegistration)?.birthDay | date: 'shortDate' }}
            </ng-container>

            <ng-container *ngSwitchCase="'email'">
              {{ getUser(userRegistration)?.email || '' }}
            </ng-container>

            <ng-container *ngSwitchCase="'createdAt'">
              {{ userRegistration.createdAt | date: 'mediumDate' }}
            </ng-container>

            <ng-container *ngSwitchCase="'collectionDate'">
              {{ getUserResult(userRegistration)?.collectionDate | date:
              'mediumDate' : '-00:00'}}
            </ng-container>

            <ng-container *ngSwitchCase="'status'">
              <ng-container *ngIf="getRegistrationStatus(userRegistration) as regStatus">
                <mat-icon [title]="regStatus.title"
                          [ngStyle]="{color: regStatus?.color}">
                  {{ regStatus.icon}}
                </mat-icon>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'hasResults'">
              <mat-icon [ngStyle]="{color: getUserResult(userRegistration) ? 'green' : 'red'}">
                {{ getUserResult(userRegistration) ? 'done' : 'closes'}}
              </mat-icon>
            </ng-container>

            <ng-container *ngSwitchCase="'hasFailedTest'">
              <mat-icon [ngStyle]="{color: getFailedTest(userRegistration) ? 'red' : 'black'}">
                {{ getFailedTest(userRegistration) ? 'cancel' : ''}}
              </mat-icon>
            </ng-container>

            <ng-container *ngSwitchCase="'eventDate'">
              <!-- lab req received at for onsite-->
              {{ getOnsiteUserRegistration(userRegistration)?.eventDate | date:
              'mediumDate' }}
            </ng-container>

            <ng-container *ngSwitchCase="'eventTime'">
              <!-- lab req received at for onsite-->
              {{ getOnsiteUserRegistration(userRegistration)?.eventTime | ampm }}
            </ng-container>

            <ng-container *ngSwitchCase="'hasBeenReleased'">
              <!-- **NOTE** displaying the admin who did this isn't easy to support right now-->
              <mat-icon [ngStyle]="{color: getSiteUserRegistration(userRegistration)?.releasedAt ? 'green' : 'red'}"
                        [title]="(getSiteUserRegistration(userRegistration)?.releasedAt | date: 'mediumDate') || ''">
                {{ getSiteUserRegistration(userRegistration)?.releasedAt ?
                'done' : 'closes'}}
              </mat-icon>
            </ng-container>

            <ng-container *ngSwitchCase="'labOrderFailed'">
              <mat-icon [ngStyle]="{color: getOffsiteUserRegistration(userRegistration)?.labOrderFailed ? 'red' : ''}"
                        [title]="(getOffsiteUserRegistration(userRegistration)?.labOrderFailed ? 'Failed to Send to Lifepoint' : '') || ''">
                {{ getOffsiteUserRegistration(userRegistration)?.labOrderFailed
                ?
                'error_outline' : ''}}
              </mat-icon>
            </ng-container>

            <ng-container *ngSwitchCase="'resultsCreatedAt'">
              {{ getUserResult(userRegistration)?.createdAt | date: 'mediumDate'
              }}
            </ng-container>

            <ng-container *ngSwitchCase="'groupType'">
              <div [ngClass]="collapsed ? 'collapsed' : 'uncollapsed'">
                {{
                getGroupType(userRegistration)}}</div>
            </ng-container>

            <!-- onsite specific -->
            <ng-container *ngSwitchCase="'userReqCreatedAt'">
              <!-- lab req received at for onsite-->
              {{ getUserReq(userRegistration)?.createdAt | date: 'mediumDate' }}
            </ng-container>
            <ng-container *ngSwitchCase="'shotLocation'">
              <mat-radio-group fxLayoutAlign="space-around"
                               [value]="
                          getUserVaccination(userRegistration)?.vaccinationArm"
                               (change)="onChange({ change: $event.value, userRegistration, eventService, eventLocation })">
                <mat-radio-button value="right"
                                  [disabled]="getUserVaccination(userRegistration)?.vaccinationArm">Right</mat-radio-button>
                <mat-radio-button value="left"
                                  [disabled]="getUserVaccination(userRegistration)?.vaccinationArm">Left</mat-radio-button>
              </mat-radio-group>
            </ng-container>

            <ng-container *ngSwitchCase="'reviewedAt'">
              <!-- lab req received at for onsite-->
              {{ getOnsiteUserRegistration(userRegistration)?.reviewedAt | date:
              'mediumDate' }}
              <span style="font-size: 11px; color: #666;">{{
                getOnsiteUserRegistration(userRegistration)?.reviewedAt | date:
                'shortTime' }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="'fluReviewedAt'">
              <!-- lab req received at for onsite-->
              {{ getOnsiteUserRegistration(userRegistration)?.fluReviewedAt | date:
              'mediumDate' }}
              <span style="font-size: 11px; color: #666;">{{
                getOnsiteUserRegistration(userRegistration)?.fluReviewedAt | date:
                'shortTime' }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="'releasedAt'">
              <!-- lab req received at for onsite-->
              {{ getOnsiteUserRegistration(userRegistration)?.releasedAt | date:
              'mediumDate' }}
            </ng-container>

            <ng-container *ngSwitchCase="'releasedBy'">
              <!-- lab req received at for onsite-->
              {{ getReleasedBy(userRegistration)?.email | emailName | titlecase
              }}
            </ng-container>

            <ng-container *ngSwitchCase="'rackNumber'">
              <!-- rack number associated with registration-->
              {{ userRegistration?.rackNumber || '' }} -
              {{getRackEmail(userRegistration?.rackId) | emailName | titlecase}}
            </ng-container>

            <!-- offsite form specific -->
            <ng-container *ngSwitchCase="'shortId'">
              {{ userRegistration?.shortId || '' }}
            </ng-container>

            <!-- health provider form specific -->
            <ng-container *ngSwitchCase="'resultsUploaded'">
              <mat-icon [ngStyle]="{
                          color: getUserResult(userRegistration)
                            ? 'green'
                            : 'red'
                        }">
                {{
                getUserResult(userRegistration)
                ? 'done'
                : 'closes'
                }}
              </mat-icon>
            </ng-container>

            <ng-container *ngSwitchCase="'biometric'">
              <div style="margin: 2px">
                <div fxLayout="row"
                     fxLayoutGap="2px">
                  <span>Height: </span>
                  <span>
                    {{ getUserResult(userRegistration)?.selfReportedHeight |
                    displayHeight }}
                  </span>
                </div>
                <div fxLayout="row"
                     fxLayoutGap="2px">
                  <span>Weight: </span>
                  <span>
                    {{ getUserResult(userRegistration)?.selfReportedWeight |
                    displayWeight }}
                  </span>
                </div>
                <div fxLayout="row"
                     fxLayoutGap="2px">
                  <span>BP: </span>
                  <span>
                    <ng-container *ngIf="getUserResult(userRegistration)?.bloodPressureDia &&
                                  getUserResult(userRegistration)?.bloodPressureSys; else bpNotSpecified">
                      {{ getUserResult(userRegistration)?.bloodPressureDia }} /
                      {{ getUserResult(userRegistration)?.bloodPressureSys }}
                    </ng-container>
                    <ng-template #bpNotSpecified>
                      Not specified
                    </ng-template>
                  </span>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'selfReportedHeight'">
              <!-- **DEPRECATED** use biometric instead -->
              {{ getUserResult(userRegistration)?.selfReportedHeight |
              displayHeight }}
            </ng-container>

            <ng-container *ngSwitchCase="'selfReportedWeight'">
              <!-- **DEPRECATED** use biometric instead -->
              {{getUserResult(userRegistration)?.selfReportedWeight |
              displayWeight }}
            </ng-container>

            <ng-container *ngSwitchCase="'bloodPressure'">
              <!-- **DEPRECATED** use biometric instead -->
              <ng-container *ngIf="getUserResult(userRegistration)?.bloodPressureDia &&
                getUserResult(userRegistration)?.bloodPressureSys; else bpNotSpecified">
                {{ getUserResult(userRegistration)?.bloodPressureDia }} /
                {{ getUserResult(userRegistration)?.bloodPressureSys }}
              </ng-container>
              <ng-template #bpNotSpecified>
                Not specified
              </ng-template>
            </ng-container>

            <ng-container *ngSwitchDefault>
              Unknown column specified: {{ col.prop }}
            </ng-container>
          </ng-container>
        </td>

        <td *ngIf="actionColumn"
            [class.hide-border]="clientAdmin">
          <div fxLayout="row"
               *ngIf="!clientAdmin">

            <a mat-flat-button
               title="Edit Results"
               [routerLink]="getEditResultsLink(userRegistration)"
               [queryParams]="getEditResultsLinkParams(userRegistration)"
               queryParamsHandling="merge">
              <mat-icon color="primary">{{getUserResult(userRegistration)
                ? 'edit' : 'add'}}
              </mat-icon>
            </a>
            <button *ngIf="getActions(userRegistration)?.length"
                    mat-icon-button
                    type="button"
                    aria-label="Menu"
                    [matMenuTriggerFor]="actionMenu"
                    [matMenuTriggerData]="{ userRegistration: userRegistration }"
                    (click)="$event.stopPropagation(); $event.preventDefault()">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </td>
      </tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>

<mat-menu #actionMenu="matMenu">
  <ng-template matMenuContent
               let-userRegistration="userRegistration">
    <ng-container *ngFor="let action of getActions(userRegistration); trackBy: trackByFn">
      <ng-container *ngIf="action.actionType === 'link' else showButton">
        <a mat-menu-item
           [routerLink]="action.route"
           [queryParams]="action.routeQueryParams"
           queryParamsHandling="merge">
          {{action.name}}
        </a>
      </ng-container>
      <ng-template #showButton>

        <button mat-menu-item
                [disabled]="disabled"
                type="button"
                (click)="actionChange.emit({ actionType: $any(action.actionType), userRegistration: userRegistration })">
          {{action.name}}
        </button>
      </ng-template>
    </ng-container>
  </ng-template>
</mat-menu>
