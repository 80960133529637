export enum HiddenRegistrationText {
  LOCATION = 'location',
  BIOMETRICS = 'biometrics',
  REGISTRATION = 'registration'
}

export interface OffsiteEventServiceText {
  /**
   * The info/explain header text, not HTML
   * Shown on reg-offsite-explain component
   */
  explainHeader: string;
  /**
   * The info/explain body html
   * Shown on reg-offsite-explain component
   */
  explainBody: string;
  /**
   * The explain header text, not HTML,
   * shown on the reg-offsite-consent component
   */
  consentHeader: string;
  /**
   * The explain body html
   * shown on the reg-offsite-consent component
   */
  consentBody: string;
  /**
   * The consent text shown on the instructions page, above the
   * congrats text
   */
  instructionsConsent: string;
  /**
   * The congrats text shown on the instructions page,
   *  HTML
   */
  congrats: string;
  /**
   * This will toggle on and off custom text.
   */
  hiddenText?: HiddenRegistrationText[];
  /**
   * This text replaces offsite screening deadlines on the dashboard and email
   */
  deadlineText?: string;
}
