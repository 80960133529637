import { DateTime } from 'luxon';
import { OnsiteRegistrationType } from '../constants/onsite-registration-type';
import {
  EventLocation,
  EventLocationWithTimes,
  EventStatus
} from '../models/event-location/event-location';
import {
  EventLocationCustomTimeFlu,
  EventLocationCustomTimeScreening
} from '../models/event-location/event-location-custom-time';
import { EventLocationRegistrationTime } from '../models/event-location/event-location-registration-time';
import { EventAlmostFullResponse } from '../responses/event-almost-full-response';
import { DateUtil } from './date-util';
import { LocationDataUtil } from './location-data-util';
import { TimeUtil } from './time-util';
import { toMap } from './to-map';
import { EventServiceUtil } from './event-service-util';
import { SingleEventStats } from '../models/event-location';
import { EventService } from '../models/event-service/event-service';
/**
 * The different capacity levels to send warning emails.
 * If its under, we can do nothing
 */
export enum EventLocationCapacityLevel {
  FULL = '100',
  NINETY = '90',
  EIGHTY = '80',
  NOP = 'nop'
}
/**
 * This static utility class provides some methods
 * to calculate aspects of event locations.
 */
export class EventLocationUtil {
  /**
   * Returns if the given event-location is "recent".
   * Currently this means within 1 week before, and 1 week
   * after the current date.
   */
  public static isRecent(params: {
    /**
     * The event-location we are checking
     */
    eventLocation: Pick<EventLocation, 'eventDate'>;
    /**
     * The current date, usually generated from, if
     * not given we automatically generate it.
     * `new Date()`
     */
    date?: Date;
    /**
     * The number of days before/after considered recent, if not given
     * or 0, we will use 1 week (7 days)
     */
    days?: number;
  }): boolean {
    const { eventLocation } = params;
    const date = params.date || new Date();
    const days = params.days || 7;
    // Move to luxon for easier checks
    const currentDateTime = DateTime.fromJSDate(date);
    const eventLocationDateTime = DateUtil.getDateTime(eventLocation.eventDate);

    return (
      Math.abs(currentDateTime.diff(eventLocationDateTime, 'days').days) <= days
    );
  }

  public static isFutureActive(params: {
    eventLocation: EventLocation;
    //For testing
    date?: Date;
  }) {
    const { eventLocation, date } = params;
    const currentDateTime = date ? date : DateTime.fromJSDate(new Date());
    const eventLocationDateTime = DateUtil.getDateTime(eventLocation.eventDate);

    return (
      eventLocation.status === EventStatus.ACTIVE &&
      !eventLocation.canceled &&
      eventLocationDateTime >= currentDateTime
    );
  }

  /**
   * Utility function that returns the capacity level.
   * This holds the primary business logic that is easily testable.
   */
  public static getIsNearCapacity(params: {
    /**
     * The event-location with alloted/fluAlloted information
     */
    eventLocation: EventLocation;
    /**
     * Screening almost-full response info
     */
    screening: EventAlmostFullResponse | 'not-supported';
    /**
     * Vaccination almost-full response info
     */
    vaccination: EventAlmostFullResponse | 'not-supported';
  }): {
    screening: EventLocationCapacityLevel;
    vaccination: EventLocationCapacityLevel;
  } {
    const { eventLocation, screening, vaccination } = params;

    const result: {
      screening: EventLocationCapacityLevel;
      vaccination: EventLocationCapacityLevel;
    } = {
      screening: EventLocationCapacityLevel.NOP,
      vaccination: EventLocationCapacityLevel.NOP
    };

    const screeningIsNear80Capacity =
      !!eventLocation.alloted &&
      screening !== 'not-supported' &&
      screening.percentage <= 0.2 &&
      screening.percentage > 0.1;

    const vaccinationIsNear80Capacity =
      !!eventLocation.fluAlloted &&
      vaccination !== 'not-supported' &&
      vaccination.percentage <= 0.2 &&
      vaccination.percentage > 0.1;

    if (screeningIsNear80Capacity) {
      if (eventLocation.capacityWarning80) {
        // If we already sent this, then don't do anything again.
        result.screening = EventLocationCapacityLevel.NOP;
      } else {
        // If we are near 80 capacity, return 80 capacity reached
        result.screening = EventLocationCapacityLevel.EIGHTY;
      }
    }

    if (vaccinationIsNear80Capacity) {
      if (eventLocation.vaccineCapacityWarning80) {
        // If we already sent this, then don't do anything again.
        result.vaccination = EventLocationCapacityLevel.NOP;
      } else {
        // If we are near 80 capacity, return 80 capacity reached
        result.vaccination = EventLocationCapacityLevel.EIGHTY;
      }
    }

    const screeningIsNear90Capacity =
      !!eventLocation.alloted &&
      screening !== 'not-supported' &&
      screening.percentage <= 0.1 &&
      screening.percentage > 0;
    const vaccinationIsNear90Capacity =
      !!eventLocation.fluAlloted &&
      vaccination !== 'not-supported' &&
      vaccination.percentage <= 0.1 &&
      vaccination.percentage > 0;

    if (screeningIsNear90Capacity) {
      // If we are near 90 capacity, return 90 capacity reached, send email even if `capacityWarning90` is true
      result.screening = EventLocationCapacityLevel.NINETY;
    }

    if (vaccinationIsNear90Capacity) {
      // If we are near 90 capacity, return 90 capacity reached, send email even if `capacityWarning90` is true
      result.vaccination = EventLocationCapacityLevel.NINETY;
    }

    const screeningIsNearFullCapacity =
      !!eventLocation.alloted &&
      screening !== 'not-supported' &&
      screening.percentage <= 0;
    const vaccinationIsNearFullCapacity =
      !!eventLocation.fluAlloted &&
      vaccination !== 'not-supported' &&
      vaccination.percentage <= 0;

    if (screeningIsNearFullCapacity) {
      if (eventLocation.capacityFull) {
        // If we already sent this, then don't do anything again
        result.screening = EventLocationCapacityLevel.NOP;
      } else {
        // If we are near 100 capacity, return 100 capacity reached
        result.screening = EventLocationCapacityLevel.FULL;
      }
    }

    if (vaccinationIsNearFullCapacity) {
      if (eventLocation.vaccineCapacityFull) {
        // If we already sent this, then don't do anything again
        result.vaccination = EventLocationCapacityLevel.NOP;
      } else {
        // If we are near 100 capacity, return 100 capacity reached
        result.vaccination = EventLocationCapacityLevel.FULL;
      }
    }

    return result;
  }

  /**
   * Returns the lowest "response" between the two, or
   * throws an error if neither are supported.
   *
   * **Note** This is similar to `getIsNearCapacity` and may be merged in the future.
   */
  public static getLowestCapacity({
    screening,
    vaccination
  }: {
    screening: EventAlmostFullResponse | 'not-supported';
    vaccination: EventAlmostFullResponse | 'not-supported';
  }): EventAlmostFullResponse {
    if (typeof screening === 'object' && typeof vaccination === 'object') {
      // First go off the percentage remaining
      if (screening.percentage < vaccination.percentage) {
        return screening;
      } else if (screening.percentage > vaccination.percentage) {
        return vaccination;
      }

      // Otherwise go off the actual remaining
      if (screening.remaining < vaccination.remaining) {
        return screening;
      } else if (screening.remaining > vaccination.remaining) {
        return vaccination;
      }

      // Otherwise just return screening for simplicity
      return screening;
    }

    if (typeof screening === 'object') {
      return screening;
    }

    if (typeof vaccination === 'object') {
      return vaccination;
    }

    throw new Error('Screening and vaccination are both not supported');
  }

  /**
   * Returns how many spots are available for a
   * given eventLocation time slot.
   */
  public static getAllotedSpots(params: {
    eventLocation: EventLocation;
    /**
     * The types supported.
     */
    onsiteRegistrationTypes: OnsiteRegistrationType[];
  }): number {
    const { eventLocation, onsiteRegistrationTypes } = params;

    if (!onsiteRegistrationTypes) {
      return 0;
    }

    const isScreening = onsiteRegistrationTypes.includes(
      OnsiteRegistrationType.SCREENING
    );
    const isVaccine =
      onsiteRegistrationTypes.includes(OnsiteRegistrationType.COVID_VACCINE) ||
      onsiteRegistrationTypes.includes(OnsiteRegistrationType.FLU_VACCINE);

    if (isScreening && isVaccine) {
      // If both are supported, return the min alloted between the 2 types:
      return Math.min(eventLocation.alloted, eventLocation.fluAlloted) || 0;
    }

    if (isScreening) {
      return eventLocation.alloted || 0;
    }

    if (isVaccine) {
      return eventLocation.fluAlloted || 0;
    }

    return 0;
  }

  /**
   * Returns the number of allotted, fluAllotted and custom spots (both flu and screening) for a given eventLocation
   */
  public static getTotalSpots(params: { eventLocation: EventLocation }): {
    screeningTotal: number;
    fluTotal: number;
  } {
    const { eventLocation } = params;

    const totals = {
      screeningTotal: 0,
      fluTotal: 0
    };

    if (
      !eventLocation ||
      !eventLocation.startTime ||
      !eventLocation.endTime ||
      eventLocation.endTime < eventLocation.startTime
    ) {
      return totals;
    }

    const times = TimeUtil.getIncrements({
      startTime: eventLocation.startTime,
      endTime: eventLocation.endTime
    });

    const customTimesMap = toMap({
      entities: eventLocation.customTimes || [],
      key: 'time'
    }) as Record<
      string,
      EventLocationCustomTimeFlu & EventLocationCustomTimeScreening
    >;

    times.forEach((time) => {
      const screening =
        typeof customTimesMap[time]?.screening === 'number' &&
        customTimesMap[time]?.screening >= 0
          ? customTimesMap[time]?.screening
          : eventLocation.alloted || 0;
      const flu =
        typeof customTimesMap[time]?.flu === 'number' &&
        customTimesMap[time]?.flu >= 0
          ? customTimesMap[time]?.flu
          : eventLocation.fluAlloted || 0;

      totals.screeningTotal += screening;
      totals.fluTotal += flu;
    });

    return totals;
  }

  /**
   * Returns array of object that contain how many slots are available for each time increment
   */
  public static getTotalSpotsByTime(params: {
    eventLocation: EventLocation;
    registrationTimes?: EventLocationRegistrationTime[];
  }): Array<{
    time: string;
    screening: EventAlmostFullResponse;
    flu: EventAlmostFullResponse;
  }> {
    const { eventLocation, registrationTimes } = params;

    if (
      !eventLocation ||
      !eventLocation.startTime ||
      !eventLocation.endTime ||
      eventLocation.endTime < eventLocation.startTime
    ) {
      return [];
    }

    const times = TimeUtil.getIncrements({
      startTime: eventLocation.startTime,
      endTime: eventLocation.endTime
    });

    const customTimesMap = toMap({
      entities: eventLocation.customTimes || [],
      key: 'time'
    }) as Record<
      string,
      EventLocationCustomTimeFlu & EventLocationCustomTimeScreening
    >;

    const registrationTimeMap = toMap({
      entities: registrationTimes || [],
      key: 'time'
    }) as Record<string, EventLocationRegistrationTime>;

    return times.map((time) => {
      const screeningTotal =
        typeof customTimesMap[time]?.screening === 'number' &&
        customTimesMap[time]?.screening >= 0
          ? customTimesMap[time]?.screening
          : eventLocation.alloted || 0;
      const fluTotal =
        typeof customTimesMap[time]?.flu === 'number' &&
        customTimesMap[time]?.flu >= 0
          ? customTimesMap[time]?.flu
          : eventLocation.fluAlloted || 0;

      // Screening Numbers
      const screeningRegistered = registrationTimeMap[time]?.taken || 0;
      const screeningRemaining = screeningTotal - screeningRegistered;
      const screeningPercentage = registrationTimeMap[time]
        ? Number(((screeningRemaining / screeningTotal) * 100).toFixed(2))
        : 1;
      // Flu Numbers
      const fluRegistered =
        typeof registrationTimeMap[time]?.fluTaken === 'number'
          ? registrationTimeMap[time]?.fluTaken
          : registrationTimeMap[time]?.taken || 0;
      const fluRemaining = fluTotal - fluRegistered;
      const fluPercentage = registrationTimeMap[time]
        ? Number(((fluRemaining / fluTotal) * 100).toFixed(2))
        : 1;

      return {
        time,
        screening: {
          total: screeningTotal,
          registered: screeningRegistered,
          percentage: screeningPercentage,
          remaining: screeningRemaining
        },
        flu: {
          total: fluTotal,
          registered: fluRegistered,
          percentage: fluPercentage,
          remaining: fluRemaining
        }
      };
    });
  }

  /**
   * Returns the number of event-locations available for the
   * given eventLocationType;
   */
  public static getRemainingSpots(params: {
    eventLocation: EventLocationWithTimes;
    /**
     * The types supported.
     */
    onsiteRegistrationTypes: OnsiteRegistrationType[];
  }): number {
    const { eventLocation, onsiteRegistrationTypes } = params;

    if (!eventLocation?.times?.length) {
      return 0;
    }

    const allSlots = this.getTotalSpotsByTime({
      eventLocation,
      registrationTimes: eventLocation.times
    });
    const allSlotsAggregated = allSlots.map((slot) => {
      if (
        !onsiteRegistrationTypes.includes(OnsiteRegistrationType.SCREENING) &&
        onsiteRegistrationTypes.includes(OnsiteRegistrationType.FLU_VACCINE)
      ) {
        return slot.flu.remaining;
      } else if (
        onsiteRegistrationTypes.includes(OnsiteRegistrationType.SCREENING) &&
        !onsiteRegistrationTypes.includes(OnsiteRegistrationType.FLU_VACCINE)
      ) {
        return slot.screening.remaining;
      } else {
        return Math.min(slot.flu.remaining, slot.screening.remaining);
      }
    });

    return allSlotsAggregated.reduce((prev, curr) => prev + curr, 0);
  }

  /**
   * Function to return the available onsite types the event location has available,
   * if one of the types is full,it will disable the button
   */
  public static availableOnsiteTypes(params: {
    eventLocation: EventLocation;
    eventLocationRegStats: SingleEventStats;
    eventService: EventService;
    icons: {
      screening: string;
      vaccination: string;
    };
  }): {
    type: OnsiteRegistrationType;
    name: string;
    icon: string;
    disabled?: boolean;
  }[] {
    const { eventService, eventLocation, eventLocationRegStats, icons } =
      params;

    if (!eventService || !eventLocation || !eventLocationRegStats) {
      return [];
    }

    const onsiteTypes = EventServiceUtil.getOnsiteTypes({
      eventServices: [eventService]
    });

    if (!onsiteTypes?.length) {
      return [];
    }

    const totalSlots = EventLocationUtil.getTotalSpots({
      eventLocation
    });

    const remainingScreenings =
      (totalSlots?.screeningTotal || 0) -
      (eventLocationRegStats?.registrationsCount || 0);

    const remainingVaccinations =
      (totalSlots?.fluTotal || 0) -
      (eventLocationRegStats?.vaccinationRegistrationsCount || 0);

    return onsiteTypes.map(
      (onsiteType) =>
        (
          ({
            [OnsiteRegistrationType.FLU_VACCINE]: {
              name: 'Flu Vaccine Only',
              type: OnsiteRegistrationType.FLU_VACCINE,
              icon: icons.vaccination,
              disabled: remainingVaccinations === 0
            },
            [OnsiteRegistrationType.SCREENING]: {
              name: 'Screening Only',
              type: OnsiteRegistrationType.SCREENING,
              icon: icons.screening,
              disabled: remainingScreenings === 0
            }
          }) as Record<
            OnsiteRegistrationType,
            {
              type: OnsiteRegistrationType;
              name: string;
              icon: string;
              disabled?: boolean;
            }
          >
        )[onsiteType]
    );
  }

  /**
   * Returns a "combined location" selection
   * from a given eventLocation's city, address, zip.]
   * This **does not** include state at this time
   * Part of transitioning to more specific properties with #517
   */
  public static getLocation(params: { eventLocation: EventLocation }): string {
    const { eventLocation } = params;

    if (!eventLocation) {
      return '';
    }

    return LocationDataUtil.getDisplayLocation({ locationData: eventLocation });
  }

  /**
   * Utility method that creates an es6 map where the key
   * is the time, and value is the eventLocationRegistrationTime
   */
  private static getTimesMap(
    times: EventLocationRegistrationTime[]
  ): Map<string, EventLocationRegistrationTime> {
    return times && times.length
      ? times.reduce(
          (map, time) => map.set(time.time, time),
          new Map<string, EventLocationRegistrationTime>()
        )
      : new Map();
  }

  /**
   * Utility method that returns eventLocations with times, calculated
   * from the list of grouped user-registrations based upon their time.
   *
   * **note** can throw an error from the `getIncrements` timeUtil method.
   */
  public static getWithTimes(params: {
    /**
     * The eventLocation we are to use as the "base" of our
     * returned eventLocation.
     */
    eventLocation: EventLocation;
    /**
     * The list of times that already exist in the database from the user-registrations, if fluTimes is passed this represents times for screening
     */
    times: EventLocationRegistrationTime[];
    /**
     * The list of times that already exist in the database from the user-registrations, if this is passed we need to return times for both
     */
    fluTimes?: EventLocationRegistrationTime[];
  }): EventLocationWithTimes {
    const { eventLocation, times, fluTimes } = params;
    const timesMap = this.getTimesMap(times);
    const fluTimesMap = this.getTimesMap(fluTimes || []);
    const defaultTimes = TimeUtil.getIncrements(eventLocation);

    return {
      ...eventLocation,
      times: defaultTimes.map(
        (defaultTime) =>
          ({
            time: defaultTime,
            taken: timesMap.has(defaultTime)
              ? timesMap.get(defaultTime).taken || 0
              : 0,
            ...(fluTimes?.length && {
              fluTaken: fluTimesMap.has(defaultTime)
                ? fluTimesMap.get(defaultTime).taken || 0
                : 0
            })
          }) as EventLocationRegistrationTime
      )
    } as EventLocationWithTimes;
  }

  /**
   * Filters the given event-locations by the start and end dates.
   */
  public static filter(params: {
    /**
     * The list of event-locations to filter
     */
    eventLocations: EventLocation[];
    /**
     * The date-string the event must start after
     */
    startDate?: string;
    /**
     * The date-string the event must START before
     */
    endDate?: string;
  }): EventLocation[] {
    const { eventLocations, startDate, endDate } = params;

    if (!eventLocations) {
      return [];
    }

    if (!startDate && !endDate) {
      return eventLocations;
    }

    const events = eventLocations.filter((eventLocation) => {
      const isOlderThanStartDate = startDate
        ? eventLocation.eventDate >= startDate
        : true;
      const isNewerThanEndDate = endDate
        ? eventLocation.eventDate <= endDate
        : true;

      return isOlderThanStartDate && isNewerThanEndDate;
    });

    return events;
  }

  /**
   * Sort the Event Locations by event date first, and if the event date is the same, then we
   * sort by the event start time.
   */
  public static sortEventsByDate({
    eventLocations,
    sort
  }: {
    eventLocations: EventLocation[];
    sort?: 'asc' | 'desc';
  }): EventLocation[] {
    return (eventLocations || []).sort((event1, event2) => {
      if (event1.eventDate < event2.eventDate) {
        return sort === 'desc' ? 1 : -1;
      }

      if (event1.eventDate > event2.eventDate) {
        return sort === 'desc' ? -1 : 1;
      }

      if (event1.startTime < event2.startTime) {
        return sort === 'desc' ? 1 : -1;
      }

      if (event1.startTime > event2.startTime) {
        return sort === 'desc' ? -1 : 1;
      }

      return 0;
    });
  }
}
