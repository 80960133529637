import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsApiErrorFiltersComponent } from './ehs-api-error-filters.component';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [EhsApiErrorFiltersComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule
  ],
  exports: [EhsApiErrorFiltersComponent]
})
export class EhsApiErrorFiltersModule {}
