import { Injectable } from '@angular/core';
import { UserRegistrationHttpService } from '../http/user-registration-http.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { clientLogger } from '../client-logger';
import { tap, take, catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserReimbursementHttpService } from '@http';

/**
 * This service interfaces with the back-end to get signed-urls for
 * provider user-registration file uploads.
 *
 * **note** as of #2601 this service is not used, as end-user's don't
 * upload their results anymore.
 */
@Injectable({
  providedIn: 'root'
})
export class CloudStorageService {
  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private userRegistrationHttp: UserRegistrationHttpService,
    private userReimbursementHttp: UserReimbursementHttpService
  ) {}

  /**
   * Uploads a provider results to the cloud storage bucket using a
   * signed url from the backend for the given user registration.
   *
   * This method will throw an error if there is an issue
   * with accessing the bucket, or getting the signed url, or
   * if the user-registration already has a file uploaded.
   *
   * TODO: update return type
   */
  public async uploadProviderResults(params: {
    /**
     * Id of the user registration to upload the results to.
     */
    id: string;
    /**
     * The file to upload
     */
    file: File;
  }) {
    const { id, file } = params;
    const contentType = file.type;

    const signedUrlRes = await this.userRegistrationHttp
      .getProviderUploadSignedUrl({
        id,
        contentType
      })
      .pipe(
        take(1),
        tap((res) =>
          clientLogger.log('test getting signed url', {
            res,
            file: file.name,
            baseUrl: environment.baseUrl
          })
        )
      )
      .toPromise();

    const { url, fileId } = signedUrlRes;

    return this.http
      .put(url, file, {
        headers: new HttpHeaders()
          .set('content-disposition', `attachments; filename="${file.name}"`)
          .set('content-type', file.type)
          .set('access-control-allow-origin', environment.baseUrl)
          .set('no-retry', 'true')
      })
      .pipe(
        catchError(() => {
          this.snackbar.open('something went wrong', 'ok');

          return undefined;
        }),
        map(() => {
          this.snackbar.open('File successfully uploaded', 'ok');

          return { file, id, fileId };
        })
      );
  }

  /**
   * Uploads reimbursement receipts to the cloud storage bucket using a
   * signed url from the backend.
   *
   * This method will throw an error if there is an issue
   * with accessing the bucket, or getting the signed url, or
   * if the user-reimbursement already has a file uploaded.
   *
   * TODO: update return type
   */
  public async uploadReimbursements(params: {
    /**
     * The file to upload
     * ToDo: Update to handle multiple files
     */
    file: File;
  }) {
    const { file } = params;
    const contentType = file.type;

    const signedUrlRes = await this.userReimbursementHttp
      .getReimbursementUploadSignedUrl({
        contentType
      })
      .pipe(
        take(1),
        tap((res) =>
          clientLogger.log('test getting signed url', {
            res,
            file: file.name,
            baseUrl: environment.baseUrl
          })
        )
      )
      .toPromise();

    const { url, fileId } = signedUrlRes;

    return this.http
      .put(url, file, {
        headers: new HttpHeaders()
          .set('content-disposition', `attachments; filename="${file.name}"`)
          .set('content-type', file.type)
          .set('access-control-allow-origin', environment.baseUrl)
          .set('no-retry', 'true')
      })
      .pipe(
        catchError(() => {
          this.snackbar.open('something went wrong', 'ok');

          return undefined;
        }),
        map(() => {
          this.snackbar.open('File successfully uploaded', 'ok');

          return { file, fileId };
        })
      );
  }

  /**
   * Opens a new tab for the user to download
   * the files for health provider uploads
   */
  public downloadResults(params: {
    /**
     * The id of the user registration to download hpf
     */
    id: string;
    /**
     * The generated id of the file
     */
    fileId: string;
    // Simplify function by reusing and passing provider boolean if downloading provider form
    provider?: boolean;
  }): Observable<any> {
    const { id, fileId, provider } = params;

    if (provider) {
      return this.userRegistrationHttp
        .getProviderDownloadSignedUrl({ id, fileId })
        .pipe(tap(({ url }) => window.open(url, '_blank')));
    }

    return this.userReimbursementHttp
      .getReimbursementDownloadSignedUrl({ id, fileId })
      .pipe(tap(({ url }) => window.open(url, '_blank')));
  }
}
