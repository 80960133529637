import { Injectable } from '@angular/core';
import { PhoneUtil, UserResult } from '@common';

/**
 * This service is used to render the lab for a given user-result.
 * This wraps more or less simple "get base64, and download it" logic,
 * but is provided to keep things similar to the other types of pdfs
 * rendered from scratch on the platform.
 */
@Injectable({
  providedIn: 'root'
})
export class LabPdfService {
  /**
   * Generates and downloads the user-results lab pdf. If there is one,
   * otherwise this doesn't do anything.
   *
   * TODO: verify naming convention
   */
  public generateAndDownload(userResult: UserResult & { pdf: string }) {
    if (!userResult.pdf) {
      return;
    }

    if (PhoneUtil.isSafari()) {
      PhoneUtil.openBase64Pdf(userResult.pdf);

      return;
    }

    const downloadLinkElement: HTMLAnchorElement = document.createElement('a');

    downloadLinkElement.href = `data:application/pdf;base64,${userResult.pdf}`;
    downloadLinkElement.download = `health-report-${userResult.collectionDate}.pdf`;
    downloadLinkElement.click();
  }
}
