import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EhsAddAdminDialogComponent } from './ehs-add-admin-dialog.component';
import { Observable } from 'rxjs';

@Injectable()
export class EhsAddAdminDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(): Observable<string> {
    return this.matDialog
      .open(EhsAddAdminDialogComponent, {
        width: '450px',
        disableClose: true
      })
      .afterClosed();
  }
}
