<ng-container *ngIf="userReg && layout">
  <ng-container *ngFor="let row of layout.rows">
    <div [gdColumns]="row.gdColumns | tofr"
         [gdColumns.sm]="row.gdColumnsSm | tofr"
         [gdColumns.xs]="row.gdColumnsXs "
         gdGap="8px">
      <ng-container *ngFor="let field of row.fields">
        <ng-container [ngSwitch]="field.fieldType">

          <ng-container *ngSwitchCase="fieldTypes.STATUS">
            <mat-form-field class="form-spacing">
              <mat-label>Status</mat-label>
              <mat-select name="status"
                          #status="ngModel"
                          [(ngModel)]="userReg.status"
                          [required]="!!field.required"
                          [disabled]="!!field.disabled">
                <mat-option *ngFor="let status of registrationStatuses"
                            [value]="status">
                  {{status}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="status.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.PERSON_TYPE">
            <mat-form-field class="form-spacing">
              <mat-label>Member Type</mat-label>
              <mat-select name="personType"
                          #personType="ngModel"
                          [(ngModel)]="userReg.personType"
                          [required]="!!field.required"
                          [disabled]="!!field.disabled">
                <mat-option *ngFor="let personType of personTypes"
                            [value]="personType">
                  {{getPersonTypeName(personType)}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="personType.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.SERVICE_TYPE">
            <mat-form-field class="form-spacing">
              <mat-label>Service Type</mat-label>
              <mat-select name="serviceType"
                          #serviceType="ngModel"
                          [(ngModel)]="userReg.serviceType"
                          [required]="!!field.required"
                          [disabled]="!!field.disabled">
                <mat-option *ngFor="let serviceType of serviceTypes"
                            [value]="serviceType">
                  {{serviceType | titlecase}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="serviceType.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.GROUP_TYPE">
            <div *ngIf="selectedGroupType !== undefined">
              <mat-form-field class="form-spacing"
                              class="full-row">
                <mat-label>Eligibility Group</mat-label>
                <mat-select name="groupType"
                            #groupType="ngModel"
                            (selectionChange)="onGroupTypeChange(selectedGroupType)"
                            [(ngModel)]="selectedGroupType"
                            [required]="!!field.required"
                            [disabled]="!!field.disabled">
                  <mat-option *ngFor="let type of groupTypes; index as i"
                              [value]="i">
                    {{getGroupTypeName(i)}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="groupType.hasError('required')">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
          <!--INSURANCE FIELDS-->
          <ng-container *ngSwitchCase="fieldTypes.INSURANCE_PROVIDER">
            <div *ngIf="hasInsurance || newInsurance">
              <mat-form-field class="form-spacing"
                              class="full-row">
                <mat-label>Insurance Provider</mat-label>
                <mat-select name="insuranceProvider"
                            #insuranceProvider="ngModel"
                            [(ngModel)]="insurance.insuranceProvider"
                            [required]="!!field.required || newInsurance"
                            [disabled]="!!field.disabled && !newInsurance">
                  <mat-option *ngFor="let id of insuranceProviderIds"
                              [value]="id">
                    {{insuranceProviders[id]?.name || ""}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="insuranceProvider.hasError('required')">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.INSURANCE_TYPE">
            <div *ngIf="(hasInsurance || newInsurance) && typeDisplayed">
              <mat-form-field class="form-spacing"
                              class="full-row">
                <mat-label>Insurance Type</mat-label>
                <mat-select name="insuranceType"
                            #insuranceType="ngModel"
                            [(ngModel)]="insurance.insuranceType"
                            [required]="!!field.required || typeDisplayed"
                            [disabled]="!!field.disabled && !newInsurance">
                  <mat-option *ngFor="let type of insuranceTypes"
                              [value]="type">
                    {{type || ""}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="insuranceType.hasError('required')">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>


          <ng-container *ngSwitchCase="fieldTypes.INSURANCE_GROUP">
            <div *ngIf="(hasInsurance || newInsurance) && groupDisplayed">
              <mat-form-field class="form-spacing"
                              class="full-row">
                <mat-label>Group ID</mat-label>
                <input type="text"
                       matInput
                       name="groupNumber"
                       #groupNumber="ngModel"
                       [(ngModel)]="insurance.groupNumber"
                       [required]="!!field.required || groupDisplayed"
                       [disabled]="!!field.disabled && !newInsurance"
                       [minLength]="1" />
                <mat-error *ngIf="groupNumber.hasError('required')">
                  This field is required
                </mat-error>
                <mat-error *ngIf="groupNumber.hasError('minLength')">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.INSURANCE_POLICY">
            <div *ngIf="(hasInsurance || newInsurance) && policyDisplayed">
              <mat-form-field class="form-spacing"
                              class="full-row">
                <mat-label>Policy ID</mat-label>
                <input type="text"
                       matInput
                       name="policyNumber"
                       #policyNumber="ngModel"
                       [(ngModel)]="insurance.policyNumber"
                       [required]="!!field.required || policyDisplayed"
                       [disabled]="!!field.disabled"
                       [minlength]="1" />
                <mat-error *ngIf="policyNumber.hasError('required')">
                  This field is required
                </mat-error>
                <mat-error *ngIf="policyNumber.hasError('minLength')">
                  This field is required
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <div>
              unsupported field-type: {{field.fieldType}}
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
