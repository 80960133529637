import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app-state';
import { AdminCoreState } from './admin-core.state';

const adminCoreFeatureSelector = createFeatureSelector<AdminCoreState>(
  'adminCore' as keyof AppState
);

const userIdsSelector = createSelector(
  adminCoreFeatureSelector,
  (state) => state.ids
);
const usersSelector = createSelector(
  adminCoreFeatureSelector,
  (state) => state.entities
);

export const adminCoreSelectors = {
  loadingSelector: createSelector(
    adminCoreFeatureSelector,
    (state) => state.loading
  ),
  userSelector: createSelector(adminCoreFeatureSelector, (state) => state.user),
  usersSelector,
  usersArr: createSelector(userIdsSelector, usersSelector, (ids, entities) =>
    ((ids || []) as string[]).map((id) => entities[id])
  )
};
