import { ObjectId } from 'mongodb';
import { InsuranceProvider } from './insurance-provider';

export enum InsuranceFields {
  INSURANCE_TYPE = 'insurance_type',
  POLICY_NUMBER = 'policy_number',
  GROUP_NUMBER = 'group_number'
}

/**
 * The type of insurance the user has
 */
export enum UserInsuranceType {
  PPO = 'ppo',
  HMO = 'hmo',
  POS = 'pos',
  NA = 'not applicable'
}
/**
 * Get the end-user name for the insurance type
 */
export const getUserInsuranceTypeName = (type: UserInsuranceType) =>
  ({
    [UserInsuranceType.PPO]: 'PPO',
    [UserInsuranceType.HMO]: 'HMO',
    [UserInsuranceType.POS]: 'POS'
  }[type] || '');

export enum UserInsuranceHolder {
  YOURSELF = 'yourself',
  SPOUSE = ' spouse',
  LIFE_PARTNER = 'life_partner',
  PARENT = 'parent'
}

/**
 * Get the end-user name for the insurance holder
 */
export const getUserInsuranceHolderName = (holder: UserInsuranceHolder) =>
  ({
    [UserInsuranceHolder.YOURSELF]: 'Yourself',
    [UserInsuranceHolder.SPOUSE]: 'Spouse',
    [UserInsuranceHolder.LIFE_PARTNER]: 'Life Partner',
    [UserInsuranceHolder.PARENT]: 'Parent'
  }[holder] || '');

/**
 * The properties saved for a given insurance
 */
export interface UserInsurance {
  /**
   * The insurance provider selected
   */
  insuranceProvider: string | ObjectId | InsuranceProvider;
  /**
   * The type of insurance the user has
   */
  insuranceType: UserInsuranceType;
  /**
   * Insurance group number
   * Usually 12 alphanumeric digits.
   */
  groupNumber: string;
  /**
   * The identification or policy number.
   * Usually 12 alphanumeric digits.
   */
  policyNumber: string;
  /**
   * The insurance holder, if selected as yourself, then
   * **note** not prompted, see #2750
   */
  primaryAccountHolder?: UserInsuranceHolder;
  /**
   * The name of the policy holder name, not required or given if the
   * primaryAccountHolder is "yourself", then this isn't defined
   * **note** not prompted, see #2750
   */
  primaryPolicyHolderName?: string;
  /**
   * The date of birth for the primary policy holder
   * formatted as a date.
   * If the primaryAccountHolder is "yourself" then this isn't defined
   * **note** not prompted, see #2750
   */
  primaryPolicyHolderDob?: string;
}
