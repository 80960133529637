import { InjectionToken } from '@angular/core';

/**
 * This token represents the base_url for loading logos for companies.
 * This is primarily used for the ehs-gwapps-company component, but could
 * be used in other components, such as the ehs-gwapps-company component.
 */
export const EHS_COMPANY_LOGO_BASE_URL = new InjectionToken(
  'EHS_COMPANY_LOGO_BASE_URL'
);
