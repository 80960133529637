import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminUser, getId } from '@common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AdminCoreHttpService } from '../../http/admin/admin-core-http.service';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { adminCoreActions } from './admin-core.actions';
import { AdminCoreFacade } from './admin-core.facade';

@Injectable()
export class AdminCoreEffects {
  constructor(
    private actions$: Actions,
    private errorReportFacade: ErrorReportFacade,
    private adminCoreFacde: AdminCoreFacade,
    private adminCoreHttp: AdminCoreHttpService
  ) {}

  getAdminUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCoreActions.getAdminUser),
      mergeMap(() =>
        this.adminCoreHttp.getAdminUser().pipe(
          map((user) => adminCoreActions.getAdminUserSuccess({ user })),
          catchError((err: HttpErrorResponse) => [
            adminCoreActions.getAdminUserFailed({ err: err.error })
          ])
        )
      )
    )
  );

  getAdminList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCoreActions.getAdminList),
      mergeMap((action) =>
        this.adminCoreHttp.getAdminList().pipe(
          map((users) => adminCoreActions.getAdminListSuccess({ users })),
          catchError((err: HttpErrorResponse) => [
            adminCoreActions.getAdminListFailed({ err: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  updateAdminScopes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCoreActions.updateAdminScopes),
      withLatestFrom(this.adminCoreFacde.user$),
      mergeMap(([action, currentAdminUser]) =>
        this.adminCoreHttp.updateAdminScopes(action.admin).pipe(
          mergeMap(() => this.updateAdminScopes({ action, currentAdminUser })),
          catchError((err: HttpErrorResponse) => [
            adminCoreActions.updateAdminScopesFailed({ err: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  createAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCoreActions.createAdmin),
      mergeMap((action) =>
        this.adminCoreHttp.createAdmin(action.email).pipe(
          mergeMap(() => [
            adminCoreActions.createAdminSuccess(),
            adminCoreActions.getAdminList()
          ]),
          catchError((err: HttpErrorResponse) => [
            adminCoreActions.createAdminFailed({ err: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  deleteAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCoreActions.deleteAdmin),
      mergeMap((action) =>
        this.adminCoreHttp.deleteAdmin(action.adminId).pipe(
          mergeMap(() => [
            adminCoreActions.deleteAdminSuccess(),
            adminCoreActions.getAdminList()
          ]),
          catchError((err: HttpErrorResponse) => [
            adminCoreActions.deleteAdminFailed({ err: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  private updateAdminScopes(params: {
    action: { admin: AdminUser };
    currentAdminUser: AdminUser;
  }): Array<Action> {
    const { action, currentAdminUser } = params;
    const { admin: adminToChange } = action;

    if (getId(adminToChange) === getId(currentAdminUser)) {
      // This will update the current admin user's session information on the client-side
      return [
        adminCoreActions.updateAdminScopesSuccess(),
        adminCoreActions.getAdminUser()
      ];
    }

    return [adminCoreActions.updateAdminScopesSuccess()];
  }
}
