import { IncentiveCategory } from '../models/incentives/incentive-category';

export const incentiveCategoryColumns = [
  IncentiveCategory.ANNUAL_AWARENESS,
  IncentiveCategory.ILLNESS_PREVENTION,
  IncentiveCategory.COMMUNITY_INVOLVEMENT,
  IncentiveCategory.SELF_CARE,
  IncentiveCategory.ACTIVE_LIFESTYLE,
  IncentiveCategory.BALANCED_NUTRITION,
  IncentiveCategory.MONEY_MANAGEMENT,
  IncentiveCategory.ONGOING_EDUCATION,
  IncentiveCategory.ALT_QUALIFIER,
  IncentiveCategory.CHALLENGES
];
