import { Params } from '@angular/router';
import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

/**
 * **NOTE** this selector is duplicated in the ng-portal project
 * in `router.selectors`.
 */
export const selectRouter =
  createFeatureSelector<RouterReducerState>('routerNgrx');

export const {
  selectCurrentRoute, // Select the current route
  selectFragment, // Select the current route fragment
  selectQueryParams, // Select the current route query params
  selectRouteParams, // Select the current route params
  selectRouteParam, // Factory function to select a route param
  selectRouteData, // Select the current route data
  selectUrl // Select the current url
} = getRouterSelectors(selectRouter);

export const selectQueryParam = (param: string) => {
  return createSelector(
    selectQueryParams,
    (params) => params && (params[param] as string | undefined)
  );
};
/**
 * Custom param, to deal with nested route-params.
 */
export const selectRouteNestedParams = createSelector(
  selectRouter,
  (router) => {
    let currentRoute = router?.state?.root;
    let params: Params = {};

    while (currentRoute?.firstChild) {
      currentRoute = currentRoute.firstChild;

      params = {
        ...params,
        ...currentRoute.params
      };
    }

    return params;
  }
);

export const selectRouteNestedParam = (param: string) =>
  createSelector(
    selectRouteNestedParams,
    (params) => params && (params[param] as string | undefined)
  );
