import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthFacade } from '../app-store/auth/auth.facade';

/**
 * Guard to prevent unauthorized users from accessing the "authenticated" part of
 * the application. If a user is not authenticated, we redirect them to the login page.
 */
@Injectable({
  providedIn: 'root'
})
class AuthGuardService {
  constructor(private authFacade: AuthFacade, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.authFacade.user$.pipe(
      map((user) =>
        user
          ? true
          : this.router.createUrlTree(['/login'], {
              queryParams: {
                redirect: state.url
              }
            })
      ),
      take(1)
    );
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(AuthGuardService).canActivate(next, state);
};
