import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminUser } from '@common';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppState } from '../app-state';
import { adminCoreActions } from './admin-core.actions';
import { adminCoreSelectors } from './admin-core.selectors';

@Injectable({
  providedIn: 'root'
})
export class AdminCoreFacade {
  public loading$ = this.store.pipe(select(adminCoreSelectors.loadingSelector));
  /**
   * The admin user logged in.
   */
  public user$ = this.store.pipe(select(adminCoreSelectors.userSelector));

  /**
   * The list of all Admin Users in an array
   */
  public users$ = this.store.pipe(select(adminCoreSelectors.usersArr));

  public usersMap$ = this.store.pipe(select(adminCoreSelectors.usersSelector));
  constructor(private store: Store<AppState>, private actions$: Actions) {}

  public getAdminUser() {
    this.store.dispatch(adminCoreActions.getAdminUser());
  }

  public getAdminList() {
    this.store.dispatch(adminCoreActions.getAdminList());
  }

  public updateAdminScopes(admin: AdminUser) {
    this.store.dispatch(adminCoreActions.updateAdminScopes({ admin }));

    return this.actions$.pipe(
      ofType(
        adminCoreActions.updateAdminScopesSuccess,
        adminCoreActions.updateAdminScopesFailed
      ),
      map((action) => {
        if (action.type === adminCoreActions.updateAdminScopesFailed.type) {
          throw new HttpErrorResponse({});
        }

        return action;
      })
    );
  }

  public createAdmin(email: string) {
    this.store.dispatch(adminCoreActions.createAdmin({ email }));
  }

  public deleteAdmin(adminId: string) {
    this.store.dispatch(adminCoreActions.deleteAdmin({ adminId }));
  }
}
