import { User } from '../user';
import { BaseUserRegistration } from './base-user-registration';
import { ServiceType } from '../event-service/service-type';
import { UserRegistration } from '../user-registration';
import { ObjectId } from 'mongodb';
import { AdminUser } from '../admin-user';
import { DbDocument } from '../db-document';

export enum UploadMimeType {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  IMAGE_JPG = 'image/jpg',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  PDF = 'pdf',
  APPLICATION_PDF = 'application/pdf'
}

export enum ProviderHealthUploadStatus {
  NOT_UPLOADED = 'not_uploaded',
  UPLOADED = 'uploaded',
  REJECTED = 'rejected',
  COMPLETED = 'completed'
}

export interface ProviderHealthUserRegistration extends BaseUserRegistration {
  serviceType: ServiceType.PROVIDER_HEALTH;
  usesTobacco?: boolean;
  /**
   * The snapshot of the user saved, this is to handle cases
   * where the user updates their name or information.
   * TODO: specify what we are actually saving
   */
  userSnapshot?: Partial<User>;

  /**
   * If the results have been uploaded for this request.
   * Provider health results are uploaded in the UI as a file.
   *
   * @deprecated this isn't used anymore, as the end-user feature isn't supported anymore.
   * **don't rely on this!**
   */
  resultsUploaded?: boolean;
  /** Fields to accommodate new built-in upload form for Provider health documents */
  uploadStatus?: ProviderHealthUploadStatus;
  uploadFiles?: HPFUploadFile[];
  notes?: HpfUploadNote[];
}

export interface HpfUploadNote extends DbDocument {
  status: ProviderHealthUploadStatus;
  notes: string;
  /** The admin who created this note
   */
  admin: {
    _id: string | ObjectId | AdminUser;
    email: string;
    firstName: string;
    lastName: string;
  };
  createdAt: Date;
  attachments?: HPFUploadFile[];
}
export interface HPFUploadFile {
  mimetype: UploadMimeType | string;
  fileId: string;
  fileName: string;
  uploadDate: string;
}

type ProviderHealthUploadPickedFilter = Partial<
  Pick<ProviderHealthUserRegistration, 'updatedAt' | 'company'>
>;

export interface ProviderHealthUploadFilter
  extends ProviderHealthUploadPickedFilter {
  firstName?: string;
  lastName?: string;
  email?: string;
  uploadStatus?: ProviderHealthUploadStatus[];
}

export interface ProviderHealthUploadForm extends ProviderHealthUploadFilter {
  pageNumber: number;
}

/**
 * Type-guard for provider-health user registration.
 */
export const isProviderHealthUserRegistration = (
  userRegistration: UserRegistration
): userRegistration is ProviderHealthUserRegistration =>
  userRegistration?.serviceType === ServiceType.PROVIDER_HEALTH;
