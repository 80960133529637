import { ServiceType } from '../models/event-service/service-type';

export type AdminListUserRegistrationsBaseParams = {
  /**
   * If we are to include the result
   */
  includeResult?: boolean;
  /**
   * If we are to include the requisition
   */
  includeReq?: boolean;
  /**
   * If we are to include the user
   */
  includeUser?: boolean;
  /**
   * The service-type that can be passed to further focus the registrations returned
   */
  serviceType?: ServiceType;
  /**
   * If we are to only include user-registrations that
   * include results.
   *
   * **note** this only works properly if the includeResult is also passed
   */
  hasResult?: boolean;
  /**
   * A new flag that can be passed to prevent the loading of pdfs in the
   * user-requisitions and user-result. This is added more for backwards compat,
   * but will become the default
   */
  excludePdfs?: boolean;
};
export type AdminListUserRegistrationsByService =
  AdminListUserRegistrationsBaseParams & {
    /**
     * The id of the event-service to match against
     */
    eventService: string;
  };
export type AdminListUserRegistrationsByEvent =
  AdminListUserRegistrationsBaseParams & {
    /**
     * The id of the event-location, used for event-location specific lookups
     */
    eventLocation: string;
    /**
     * If we are to include the event
     */
    includeEvent?: boolean;
    /**
     * If we are to include the admin-user set via releasedBy
     */
    includeReleasedBy?: boolean;
    /**
     * If we are to include the admin-user set via reviewedBy
     */
    includeReviewedBy?: boolean;
  };
export type AdminListUserRegistrationsByUser =
  AdminListUserRegistrationsBaseParams & {
    /**
     * The id of the user, used for user-specific lookups
     */
    user: string;
    /**
     * If we are to include the event
     */
    includeEvent?: boolean;
    /**
     * If we are to include the admin-user set via releasedBy
     */
    includeReleasedBy?: boolean;
    /**
     * If we are to include the admin-user set via reviewedBy
     */
    includeReviewedBy?: boolean;
  };
export type AdminListUserRegistrationsByCompany =
  AdminListUserRegistrationsBaseParams & {
    /**
     * The id of the user, used for user-specific lookups
     */
    company: string;
  };

/**
 * This type represents the query-params we could be sending to
 * the admin-list-user-registrations route.
 *
 * It also follows the same types used in the ngrx logic related
 * to this.
 *
 * **note** the previous version also supported a user property
 */
export type AdminListUserRegistrationsParams =
  | AdminListUserRegistrationsByEvent
  | AdminListUserRegistrationsByService
  | AdminListUserRegistrationsByUser
  | AdminListUserRegistrationsByCompany;

/**
 * List of type-guards to help with admin-list-user-registration requests.
 * this is wrapped in an object to save on typing ;D
 */
export const isAdminListUserRegistrations = {
  byEvent: (
    params: AdminListUserRegistrationsParams
  ): params is AdminListUserRegistrationsByEvent =>
    !!(params as AdminListUserRegistrationsByEvent).eventLocation,

  byService: (
    params: AdminListUserRegistrationsParams
  ): params is AdminListUserRegistrationsByService =>
    !!(params as AdminListUserRegistrationsByService).eventService,

  byUser: (
    params: AdminListUserRegistrationsParams
  ): params is AdminListUserRegistrationsByUser =>
    !!(params as AdminListUserRegistrationsByUser).user
};
