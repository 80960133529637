import { Injectable, inject } from '@angular/core';
import { UrlTree, Router, CanActivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthFacade } from '../../core/app-store/auth/auth.facade';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
class SignUpVerifyGuardService {
  constructor(private router: Router, private authFacade: AuthFacade) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authFacade.sentSignUpRequest$.pipe(
      map((sentSignUpRequest) =>
        sentSignUpRequest ? true : this.router.createUrlTree(['/'])
      ),
      take(1)
    );
  }
}

export const SignUpVerifyGuard: CanActivateFn = () => {
  return inject(SignUpVerifyGuardService).canActivate();
};
