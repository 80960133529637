import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserUtil } from '@common';
import { EhsPasswordResetDialogData } from './ehs-password-reset-dialog-data';

@Component({
  selector: 'ehs-password-reset-dialog',
  templateUrl: './ehs-password-reset-dialog.component.html',
  styleUrls: ['./ehs-password-reset-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsPasswordResetDialogComponent {
  public readonly passwordRegExp = UserUtil.passwordRegExp;
  /**
   * If we are to show the password field, will change
   * the input-type to `text`.
   */
  public showPassword = false;

  /**
   * If we are to show the confirm-password field, will change
   * the input-type to `text`.
   */
  public showConfirmPassword = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EhsPasswordResetDialogData,
    private dialogRef: MatDialogRef<EhsPasswordResetDialogComponent>
  ) {}

  submit(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.dialogRef.close(form.value.password);
  }
}
