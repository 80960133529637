import { Notification, CommonResponse, NotificationId } from '@common';
import { entitySelectionActionFactory } from '@ehs-ngrx/common';
import { createAction, props } from '@ngrx/store';

export const notificationActions = {
  clear: createAction('[NOTIFICATION] CLEAR'),

  list: createAction('[NOTIFICATION] LIST'),
  listSuccess: createAction(
    '[NOTIFICATION] LIST_SUCCESS',
    props<{
      entities: Notification[];
    }>()
  ),
  listFailed: createAction(
    '[NOTIFICATION] LIST_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  update: createAction(
    '[NOTIFICATION] UPDATE',
    props<{
      notifications: string[];
    }>()
  ),
  updateSuccess: createAction(
    '[NOTIFICATION] UPDATE_SUCCESS',
    props<{
      notifications: string[];
    }>()
  ),
  updateFailed: createAction(
    '[NOTIFICATION] UPDATE_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  get: createAction(
    '[NOTIFICATION] GET',
    props<{
      id: string;
    }>()
  ),
  getSuccess: createAction(
    '[NOTIFICATION] GET_SUCCESS',
    props<{
      entity: Notification;
    }>()
  ),
  getFailed: createAction(
    '[NOTIFICATION] GET_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  ...entitySelectionActionFactory<NotificationId, 'NOTIFICATION'>(
    'NOTIFICATION'
  )
};
