import { NgModule } from '@angular/core';
import { EhsBioWithVaccineReqService } from './ehs-bio-with-vaccine-req.service';
import { EhsBioReqService } from './ehs-bio-req.service';
import { EhsCovidReqService } from './ehs-covid-req.service';
import { EhsFingerstickReqService } from './ehs-fingerstick-req.service';
import { EhsFluReqService } from './ehs-flu-req.service';
import { EhsProviderReqService } from './ehs-provider-req.service';
import { FullReportService } from './full-report.service';

/**
 * This module is to be used with the ehs-pdf service generators.
 * this is required as due to the use of `EHS_BASE_URL_INJECTION_TOKEN`,
 * means providedIn: 'root' can't be used.
 */
@NgModule({
  providers: [
    EhsBioReqService,
    EhsCovidReqService,
    EhsFingerstickReqService,
    EhsFluReqService,
    EhsBioWithVaccineReqService,

    // These 2 aren't really neccessary, but can be provided.
    EhsProviderReqService,
    FullReportService
  ]
})
export class EhsPdfsModule {}
