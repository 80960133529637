import { UserRegistrationId } from '../models/user-registration/base-user-registration';

// This will include maybe requisitions later, etc...
export enum GcfPdfExportOptions {
  /**
   * The lab-requisition pdf. This is provided from the
   * lab and is part of the `user-requisition` created.
   */
  LAB_REQ = 'lab_req',
  /**
   * The EHS-report is the "main report" returned to
   * users with their result data.
   */
  EHS_REPORT = 'ehs_report'
}

/**
 * This interface represents a base gcf-pdf-export request,
 * that all other types inherit.
 */
export interface BaseGcfPdfExportRequest {
  /**
   * The task we want to run in the gcf-pdf-export. If no option is specified
   * the function will error out.
   */
  option: GcfPdfExportOptions;

  /**
   * Email of the user to share the exported files with. This is the user that will receive
   * an email. If the email is not specified, then no email will be generated.
   */
  email?: string;
}

/**
 * This type represents **all** possible gcf-pdf-export requests. Or in other-words
 * all pdf-export request types the `gcf-pdf-export` function can handle.
 */
export type GcfPdfExportRequest = GcfLabReqRequest | GcfEhsReportPdfRequest;

/**
 * Generic type that represents all the lab-req types
 * the GcfPdfExport function can handle.
 *
 * All are determined by the `GcfPdfExportOptions.LAB_REQ` `option`.
 */
export type GcfLabReqRequest =
  | GcfLabReqExportUserRegsRequest
  | GcfLabReqExportEventRequest;

/**
 * Generic type that represents all the ehs-report types
 * the GcfPdfExport function can handle.
 *
 * All are determined by the `GcfPdfExportOptions.EHS_REPORT` `option`.
 */
export type GcfEhsReportPdfRequest =
  | GcfEhsReportPdfUserRegsRequest
  | GcfEhsReportPdfEventServiceRequest
  | GcfEhsReportPdfEventLocationRequest;

export interface GcfLabReqExportUserRegsRequest
  extends BaseGcfPdfExportRequest {
  option: GcfPdfExportOptions.LAB_REQ;

  /**
   * The list of user-registration IDS we are to get user-reqs for,
   * and export.
   *
   * **note** if a given user-registration **doesn't have** a user-req, we
   * will skip it!
   */
  userRegistrationIds: UserRegistrationId[];
}

export interface GcfLabReqExportEventRequest extends BaseGcfPdfExportRequest {
  option: GcfPdfExportOptions.LAB_REQ;
  /**
   * The event-location to pull all the user-registrations, and their
   * user-requisitions from.
   */
  eventLocationId: string;
}

export interface GcfEhsReportPdfUserRegsRequest
  extends BaseGcfPdfExportRequest {
  option: GcfPdfExportOptions.EHS_REPORT;
  /**
   * The User Registration IDs that we need to generate the PDFs for.
   */
  userRegistrationIds?: UserRegistrationId[];
}

export interface GcfEhsReportPdfEventServiceRequest
  extends BaseGcfPdfExportRequest {
  option: GcfPdfExportOptions.EHS_REPORT;
  /**
   * The Event Service that we will need to pull all the registrations & results
   * tied to it, and generate the PDF reports for all the registrations.
   */
  eventServiceId?: string;
}

export interface GcfEhsReportPdfEventLocationRequest
  extends BaseGcfPdfExportRequest {
  option: GcfPdfExportOptions.EHS_REPORT;
  /**
   * The event-location to pull all the user-registrations from, and generate the report
   * for all the users registered to that event.
   */
  eventLocationId?: string;
}

/**
 * Type-guard if the given request is the generic gcf-pdf-export-request.
 *
 * **note** this isn't perfect as it only checks that `option` is passed. This is
 * done primarily to future proof.
 */
export const isGcfPdfExportRequest = (
  request: unknown
): request is GcfPdfExportRequest =>
  !!request &&
  typeof request === 'object' &&
  !!(request as GcfPdfExportRequest).option &&
  Object.values(GcfPdfExportOptions).includes(
    (request as GcfPdfExportRequest).option
  );

/**
 * Type-guard if the given request is a GcfLabReqExportUserRegsRequest
 */
export const isGcfLabReqExportUserRegsRequest = (
  request: GcfPdfExportRequest
): request is GcfLabReqExportUserRegsRequest =>
  request.option === GcfPdfExportOptions.LAB_REQ &&
  Array.isArray(
    (request as GcfLabReqExportUserRegsRequest).userRegistrationIds
  );

/**
 * Type-guard if the given request is a GcfLabReqExportEventRequest
 */
export const isGcfLabReqExportEventRequest = (
  request: GcfPdfExportRequest
): request is GcfLabReqExportEventRequest =>
  (request as GcfLabReqExportEventRequest).option ===
    GcfPdfExportOptions.LAB_REQ &&
  (request as GcfLabReqExportEventRequest).eventLocationId !== undefined;

/**
 * Type-guard if the given request is a GcfEhsReportPdfUserRegsRequest
 */
export const isGcfEhsReportPdfUserRegsRequest = (
  request: GcfPdfExportRequest
): request is GcfEhsReportPdfUserRegsRequest =>
  (request as GcfEhsReportPdfUserRegsRequest).option ===
    GcfPdfExportOptions.EHS_REPORT &&
  Array.isArray(
    (request as GcfEhsReportPdfUserRegsRequest).userRegistrationIds
  );

/**
 * Type-guard if the given request is a GcfEhsReportPdfEventServiceRequest
 */
export const isGcfEhsReportPdfEventServiceRequest = (
  request: GcfPdfExportRequest
): request is GcfEhsReportPdfEventServiceRequest =>
  (request as GcfEhsReportPdfUserRegsRequest).option ===
    GcfPdfExportOptions.EHS_REPORT &&
  (request as GcfEhsReportPdfEventServiceRequest).eventServiceId !== undefined;

/**
 * Type-guard if the given request is a GcfEhsReportPdfEventLocationRequest
 */
export const isGcfEhsReportPdfEventLocationRequest = (
  request: GcfPdfExportRequest
): request is GcfEhsReportPdfEventLocationRequest =>
  (request as GcfEhsReportPdfUserRegsRequest).option ===
    GcfPdfExportOptions.EHS_REPORT &&
  (request as GcfEhsReportPdfEventLocationRequest).eventLocationId !==
    undefined;
