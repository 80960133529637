import { PersonType } from '@common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app-state';
import { CompanyState } from './company.state';

const featureSelector = createFeatureSelector<CompanyState>(
  'company' as keyof AppState
);

const loadingSelector = createSelector(
  featureSelector,
  (state) => state.loading
);

const companySelector = createSelector(
  featureSelector,
  (state) => state.company
);

const isValidClientCodeSelector = createSelector(
  featureSelector,
  (state) => state.isValidClientCode
);

const isValidClientCodeLoadingSelector = createSelector(
  featureSelector,
  (state) => state.isValidClientCodeLoading
);

const personTypesSelector = createSelector(
  featureSelector,
  (state) => state.personTypes || ({} as Record<string, PersonType[]>)
);

export const companySelectors = {
  loadingSelector,
  companySelector,
  isValidClientCodeSelector,
  isValidClientCodeLoadingSelector,
  personTypesSelector
};
