<div fxLayout="row wrap"
     fxLayoutAlign="start start"
     fxLayoutGap="32px"
     *ngIf="_userRegistration">

  <!-- Height -->
  <div>
    <div fxLayout="row"
         fxLayoutAlign="start center">
      <div class="mat-body-2 label">Height</div>
      <button type="button"
              mat-icon-button
              [color]="declined?.height ? 'warn' : ''"
              tabindex="-1"
              title="Decline"
              (click)="toggleDeclined('height')">
        <mat-icon>block</mat-icon>
      </button>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="8px">
      <mat-form-field class="field">
        <input matInput
               name="heightFeet"
               #heightFeetInput="ngModel"
               [ngModel]="userRegistration.height?.feet"
               (change)="handleInputChange(heightFeetInput)"
               type="number"
               inputmode="numeric"
               placeholder="ft"
               [min]="4"
               [max]="9"
               [disabled]="!!declined?.height">
        <mat-hint>feet</mat-hint>

        <mat-error *ngIf="heightFeetInput.hasError('min')">
          Value is to low
        </mat-error>
        <mat-error *ngIf="heightFeetInput.hasError('max')">
          Value is to high
        </mat-error>
      </mat-form-field>
      <mat-form-field class="field">
        <input matInput
               name="heightInches"
               #heightInchesInput="ngModel"
               [ngModel]="userRegistration.height?.inches"
               (change)="handleInputChange(heightInchesInput)"
               placeholder="in"
               type="number"
               inputmode="decimal"
               [min]="0"
               [max]="heightFeetInput?.value >= 9 ? 0 : 11.9"
               [disabled]="!!declined?.height">
        <mat-hint>inches</mat-hint>
        <mat-error *ngIf="heightInchesInput.hasError('min')">
          Value is too low
        </mat-error>
        <mat-error *ngIf="heightInchesInput.hasError('max')">
          Value is too high
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <!-- Weight -->
  <div>
    <div fxLayout="row"
         fxLayoutAlign="start center">
      <div class="mat-body-2 label">Weight</div>
      <button type="button"
              mat-icon-button
              [color]="declined?.weight ? 'warn' : ''"
              tabindex="-1"
              title="Decline"
              (click)="toggleDeclined('weight')">
        <mat-icon>block</mat-icon>
      </button>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="8px">
      <mat-form-field class="field">
        <input matInput
               name="weight"
               type="number"
               inputmode="decimal"
               #weightInput="ngModel"
               [(ngModel)]="userRegistration.weight"
               [min]="50"
               [max]="600"
               placeholder="lbs"
               [disabled]="!!declined?.weight">
        <mat-hint>lbs</mat-hint>
        <mat-error *ngIf="weightInput.hasError('min')">
          Value is too low
        </mat-error>
        <mat-error *ngIf="weightInput.hasError('max')">
          Value is too high
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <!-- Blood Pressure -->
  <div>
    <div fxLayout="row"
         fxLayoutAlign="start center">
      <div class="mat-body-2 label">Blood Pressure</div>
      <button type="button"
              mat-icon-button
              [color]="declined?.bloodPressure ? 'warn' : ''"
              tabindex="-1"
              title="Decline"
              (click)="toggleDeclined('bloodPressure')">
        <mat-icon>block</mat-icon>
      </button>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="8px">
      <mat-form-field class="field">
        <input matInput
               name="bloodPressureSystolic"
               type="number"
               inputmode="numeric"
               #bloodPressureSystolicInput="ngModel"
               [ngModel]="userRegistration.bloodPressure?.systolic"
               (change)="handleInputChange(bloodPressureSystolicInput)"
               (keyup)="bloodPressureDiastolicInput.control.updateValueAndValidity()"
               placeholder="Systolic"
               [min]="60"
               [max]="370"
               [disabled]="!!declined?.bloodPressure">
        <mat-hint>systolic</mat-hint>
        <mat-error *ngIf="bloodPressureSystolicInput.hasError('min')">
          Value is too low
        </mat-error>
        <mat-error *ngIf="bloodPressureSystolicInput.hasError('max')">
          Value is too high
        </mat-error>
      </mat-form-field>
      <mat-form-field class="field">
        <input matInput
               name="bloodPressureDiastolic"
               type="number"
               inputmode="numeric"
               placeholder="Diastolic"
               #bloodPressureDiastolicInput="ngModel"
               [ngModel]="userRegistration.bloodPressure?.diastolic"
               (change)="handleInputChange(bloodPressureDiastolicInput)"
               (keyup)="bloodPressureDiastolicInput.control.updateValueAndValidity()"
               [upperBound]="bloodPressureSystolicInput"
               [min]="30"
               [max]="160"
               [disabled]="!!declined?.bloodPressure">
        <mat-hint>diastolic</mat-hint>
        <mat-error *ngIf="bloodPressureDiastolicInput.hasError('min')">
          Value is too low
        </mat-error>
        <mat-error *ngIf="bloodPressureDiastolicInput.hasError('max')">
          Value is too high
        </mat-error>
        <mat-error *ngIf="bloodPressureDiastolicInput.hasError('upperBound')">
          Value cannot be higher than systolic
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <!-- Tobacco Usage -->
  <div>
    <div fxLayout="row"
         fxLayoutAlign="start center">
      <div class="mat-body-2 label">Tobacco Usage</div>
      <button type="button"
              mat-icon-button
              [color]="declined?.tobacco ? 'warn' : ''"
              tabindex="-1"
              title="Decline"
              (click)="toggleDeclined('tobacco')">
        <mat-icon>block</mat-icon>
      </button>
    </div>
    <mat-radio-group fxLayout="row"
                     fxLayoutGap="16px"
                     name="usesTobacco"
                     style="margin: 16px 0 16px"
                     #tobaccoUsageRadioGroup="ngModel"
                     [(ngModel)]="userRegistration.usesTobacco"
                     [disabled]="!!declined?.tobacco">
      <mat-radio-button color="primary"
                        [value]="true">Yes</mat-radio-button>
      <mat-radio-button color="primary"
                        [value]="false">No</mat-radio-button>
    </mat-radio-group>
  </div>

</div>