import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsSelfReportCorrectionComponent } from './ehs-self-report-correction.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EhsGwappsActionsModule } from '../ehs-gwapps-actions/ehs-gwapps-actions.module';
import { EhsCardLabelModule } from '../ehs-card-label/ehs-card-label.module';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { UpperBoundValidatorModule } from '../../shared/upper-bound-validator/upper-bound-validator.module';

@NgModule({
  declarations: [EhsSelfReportCorrectionComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    // Angular Material
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressSpinnerModule,

    // Child modules
    EhsGwappsActionsModule,
    EhsCardLabelModule,
    UpperBoundValidatorModule
  ],
  exports: [EhsSelfReportCorrectionComponent]
})
export class EhsSelfReportCorrectionModule {}
