import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsUserCriticalsTableComponent } from './ehs-user-criticals-table.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  declarations: [EhsUserCriticalsTableComponent],
  imports: [CommonModule, ScrollingModule, MatIconModule, MatChipsModule],
  exports: [EhsUserCriticalsTableComponent]
})
export class EhsUserCriticalsTableModule {}
