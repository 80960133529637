<div fxLayout="row"
     fxLayoutAlign="space-between center"
     *ngIf="eventService">

  <!-- left element -->
  <div fxLayout="row"
       fxLayoutAlign="space-between center"
       fxLayoutGap="8px"
       fxFlex="75">
    <div fxLayoutAlign="start center"
         fxLayoutGap="8px"
         fxFlex>
      <mat-icon title="service">widgets</mat-icon>
      <span title="Service Id"
            class="mat-body-2"> {{eventService.serviceId}} - </span>
      <span title="Name of the service"> {{eventService.name}} </span>
    </div>

    <!-- "middle" sub-element-->
    <span class="mat-caption"
          title="Activation Dates"
          fxFlex>
      <span [ngStyle]="{color: eventService.startDate ? '' : 'red'}">
        {{eventService.startDate | date:'mediumDate' || 'Not Set'}}
      </span>
      <span> - </span>
      <span [ngStyle]="{color: eventService.endDate ? '' : 'red'}">
        {{eventService.endDate | date:'mediumDate' || 'Not Set'}}
      </span>
    </span>
  </div>

  <div fxShow
       fxHide.lt-md
       fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="8px">
    <div *ngIf="hasScreening">
      <span *ngIf="hasOffsiteScreening">
        <span class="mat-body-2">Offsite Lab:</span> {{offsiteLab}}
      </span>
      <span *ngIf="hasOnsiteScreening">
        <span class="mat-body-2">Onsite Lab:</span> {{onsiteLab}}
      </span>
    </div>
    <button mat-icon-button
            type="button"
            [matMenuTriggerFor]="optionMenu"
            (click)="$event.stopPropagation(); $event.preventDefault()">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <mat-menu #optionMenu="matMenu">
    <!-- actions for this service -->
    <div fxLayout="column"
         fxLayoutAlign="center center">
      <ehs-gwapps-actions entityType="eventService"
                          *ngIf="!clientAdmin"
                          [entity]="eventService"
                          (view)="view.emit(eventService)"
                          (validate)="validate.emit(eventService)">
      </ehs-gwapps-actions>
    </div>
    <mat-divider *ngIf="!clientAdmin"></mat-divider>

    <a type="button"
       mat-menu-item
       [routerLink]="companyUrl"
       *ngIf="showCompany">
      View Company
    </a>

    <a type="button"
       mat-menu-item
       [routerLink]="eventServiceUrl">
      View Event Service
    </a>

    <a type="button"
       mat-menu-item
       *ngIf="canViewEvents"
       [routerLink]="eventUrl">
      View Events
    </a>

    <button type="button"
            mat-menu-item
            (click)="sendNotification()">
      <!-- <mat-icon>send</mat-icon> -->
      Compose Notification
    </button>
  </mat-menu>
</div>

<div>
  <ehs-service-types [serviceTypes]="serviceTypes"></ehs-service-types>
</div>