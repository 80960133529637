import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { EhsCardLabelComponent } from './ehs-card-label.component';

@NgModule({
  declarations: [EhsCardLabelComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [EhsCardLabelComponent]
})
export class EhsCardLabelModule {}
