import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EventService,
  GcfHraAllExportRequest,
  GcfHraEventServiceExportRequest,
  hraDef,
  HraResult,
  HraUtil
} from '@common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { hraFormActions } from '../../../modules/hra-form/state/hra-form.actions';
import { AdminHraHttpService } from '../../http/admin/admin-hra-http.service';
import { HraResultHttpService } from '../../http/hra-result-http.service';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { eventServiceActions } from '../event-service/event-service.actions';
import { userRegistrationActions } from '../user-registration/user-registration.actions';
import { userResultActions } from '../user-result/user-result.actions';
import { hraResultActions } from './hra-result.actions';

@Injectable()
export class HraResultEffects {
  constructor(
    private actions$: Actions,
    private hraResultHttp: HraResultHttpService,
    private errorReportFacade: ErrorReportFacade,
    private adminHraHttp: AdminHraHttpService
  ) {}

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hraResultActions.list),
      mergeMap((action) =>
        this.hraResultHttp.list({ includeService: action.includeService }).pipe(
          mergeMap((entities) => this.handleEntitiesList(entities)),
          catchError((err: HttpErrorResponse) => [
            hraResultActions.getFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hraResultActions.get),
      mergeMap((action) =>
        this.hraResultHttp.get(action.id).pipe(
          mergeMap((entity) =>
            action.saveToState
              ? [
                  hraResultActions.getSuccess({ entity }),
                  hraFormActions.setRecordId({
                    recordId: entity._id.toString()
                  }),
                  hraFormActions.updateRecord({
                    record: entity
                  }),
                  hraFormActions.setCategory({
                    // TODO: the default is there to fix older records. Will be removed later
                    category:
                      entity.prevCategory ||
                      HraUtil.getFirstCategory({ form: hraDef })
                  })
                ]
              : [hraResultActions.getSuccess({ entity })]
          ),
          catchError((err: HttpErrorResponse) => [
            hraResultActions.getFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hraResultActions.create),
      mergeMap((action) =>
        this.hraResultHttp.create(action.hraResult).pipe(
          mergeMap((entity) =>
            action.saveToState
              ? [
                  hraResultActions.createSuccess({ entity }),
                  hraFormActions.setRecordId({
                    recordId: entity._id.toString()
                  })
                ]
              : [hraResultActions.createSuccess({ entity })]
          ),
          catchError((err: HttpErrorResponse) => [
            hraResultActions.createFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  getCurrent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hraResultActions.getCurrent),
      mergeMap((action) =>
        this.hraResultHttp.getCurrent().pipe(
          mergeMap((entity) =>
            action.saveToState
              ? // If we are to "re-load" the state, dispatch extra actions
                [
                  hraFormActions.setRecordId({
                    recordId: entity._id.toString()
                  }),
                  hraFormActions.updateRecord({
                    record: entity
                  }),
                  hraFormActions.setCategory({
                    // TODO: the default is there to fix older records. Will be removed later
                    category:
                      entity.prevCategory ||
                      HraUtil.getFirstCategory({ form: hraDef })
                  }),
                  hraResultActions.getCurrentSuccess({ entity })
                ]
              : [hraResultActions.getCurrentSuccess({ entity })]
          ),
          catchError((err: HttpErrorResponse) => [
            hraResultActions.getCurrentFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hraResultActions.update),
      mergeMap((action) =>
        this.hraResultHttp.update(action.hraResult).pipe(
          mergeMap((entity) =>
            action.saveToState
              ? [
                  hraResultActions.updateSuccess({ entity }),
                  hraFormActions.setRecordId({
                    recordId: entity._id.toString()
                  })
                ]
              : [hraResultActions.updateSuccess({ entity })]
          ),
          catchError((err: HttpErrorResponse) => [
            hraResultActions.updateFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hraResultActions.remove),
      mergeMap((action) =>
        this.hraResultHttp.remove(action.id).pipe(
          map((entity) => hraResultActions.removeSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            hraResultActions.removeFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  getRelatedHraData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hraResultActions.getRelatedHraData),
        mergeMap((action) =>
          this.hraResultHttp.getRelatedHraData(action.hraResult).pipe(
            map((response) =>
              hraResultActions.getRelatedHraDataSuccess(response)
            ),
            catchError((err: HttpErrorResponse) => [
              hraResultActions.getRelatedHraDataFailed({ error: err.error }),
              this.errorReportFacade.create({
                err: err.error,
                action
              })
            ])
          )
        )
      ) as any // 0 clue why I need this!
  );

  onGetRelatedHraData$ = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(hraResultActions.getRelatedHraDataSuccess),
        mergeMap(({ eventService, userRegistration, userResult }) => {
          const actions: Action[] = [
            eventServiceActions.set({
              eventService
            })
          ];

          if (userRegistration) {
            actions.push(
              userRegistrationActions.set({
                userRegistration
              })
            );
          }

          if (userResult) {
            actions.push(
              userResultActions.set({
                userResult
              })
            );
          }

          return actions;
        })
      )
  );

  exportServiceHras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hraResultActions.exportServiceHras),
      mergeMap((action) =>
        this.adminHraHttp
          .exportHras({
            eventService: action.eventService,
            lean: false
          } as GcfHraEventServiceExportRequest)
          .pipe(
            map(() => hraResultActions.exportServiceHrasSuccess()),
            catchError((err: HttpErrorResponse) => [
              hraResultActions.exportServiceHrasFailed(),
              this.errorReportFacade.create({ err: err.error, action })
            ])
          )
      )
    )
  );

  exportAllCompanyHras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hraResultActions.exportAllCompanyHras),
      mergeMap((action) =>
        this.adminHraHttp
          .exportHras({
            all: true,
            lean: false
          } as GcfHraAllExportRequest)
          .pipe(
            map(() => hraResultActions.exportAllCompanyHrasSuccess()),
            catchError((err: HttpErrorResponse) => [
              hraResultActions.exportAllCompanyHrasFailed(),
              this.errorReportFacade.create({ err: err.error, action })
            ])
          )
      )
    )
  );

  /**
   * Method that returns action(s) for a single entity
   */
  private handleEntityGet(entity: HraResult): Action[] {
    const actions: Action[] = [hraResultActions.getSuccess({ entity })];

    if (typeof entity.eventService === 'object') {
      actions.push(
        eventServiceActions.getSuccess({
          entity: entity.eventService as EventService
        })
      );
    }

    return actions;
  }

  /**
   * Method that returns action(s) for multiple entities
   */
  private handleEntitiesList(entities: HraResult[]): Action[] {
    return entities.reduce(
      (acc, entity) => [...acc, ...this.handleEntityGet(entity)],
      [hraResultActions.listSuccess({ entities })] as Action[]
    );
  }
}
