import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AdminUpdateTestsDialogData } from './admin-update-tests-dialog-data';
import { AdminUpdateTestsDialogResponse } from './admin-update-tests-dialog-response';
import { EhsAdminUpdateTestsDialogComponent } from './ehs-admin-update-tests-dialog.component';

@Injectable()
export class EhsAdminUpdateTestsDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: AdminUpdateTestsDialogData
  ): Observable<AdminUpdateTestsDialogResponse | undefined> {
    return this.matDialog
      .open(EhsAdminUpdateTestsDialogComponent, {
        data,
        width: '500px'
      })
      .afterClosed();
  }
}
