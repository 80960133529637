import { LabResultCode } from '../models/user-test/user-test';
import { ONSITE_BIO_FORM_CODE_MAP } from '../requests/onsite-result-form';

export const heightFeet = [4, 5, 6, 7, 8, 9];
export const heightInches = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const EHS_HEIGHT_RESULT_CODE = LabResultCode('4200');
/**
 * These are the Lab Result Codes for Height
 * Those are static values to get the value of the height from the userResult.tests
 */
export const LABCORP_HEIGHT_RESULT_CODE = LabResultCode('101148');

/** Quest Height Feet is it's own labResultCode */
export const QUEST_HEIGHT_FT_RESULT_CODE = LabResultCode('97014701');
/** Quest Height Inches is it's own labResultCode */
export const QUEST_HEIGHT_IN_RESULT_CODE = LabResultCode('97014702');

/**
 * Get the list of all result codes that return the user Height
 * 101148 is the Labcorp Height Result Code
 * This list does not include Quest Result Code since it is handled separately
 * as 2 tests Feet & Inches separately.
 */
export const heightResultCodes = [
  ONSITE_BIO_FORM_CODE_MAP.height,
  LABCORP_HEIGHT_RESULT_CODE
];

/**
 * Calculates to an object with feet and inches.
 */
export const inchesToFeetAndInches = (inches: number) => {
  const feet = Math.floor(inches / 12);
  const _inches = inches % 12;

  return { feet, inches: _inches };
};

export const heightObjectToInches = (height: {
  inches?: number;
  feet?: number;
}) => {
  return (height.feet || 0) * 12 + (height.inches || 0);
};
