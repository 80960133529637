/**
 * The type of graph-type for the user.
 */
export enum UserTestGraphType {
  /**
   * Show a 5 part bar graph
   */
  LINE_GRAPH = 'lineGraph',
  /**
   * Show a 3 part partial bar graph
   */
  PARTIAL_GRAPH = 'partialGraph',
  /**
   * If negative is shown as "green"
   */
  NEGATIVE = 'negative',
  /**
   * If positive is shown as "green"
   */
  POSITIVE = 'positive',
  /**
   * Show the value as-is
   */
  TEXT = 'text',
  /**
   * No Graph type should be displayed
   */
  NONE = 'none'
}
