import { Inject, Injectable } from '@angular/core';
import { EventLocation, OnsiteUserRegistration, User } from '@common';
import { combineLatest, from } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import { EHS_BASE_URL_INJECTION_TOKEN } from '../../shared/ehs-base-url-injection-token';

interface CommonParams {
  user: User;
  /**
   * The onsite user-registration with covid report, will not do anything
   * unless the registration is a covid registration.
   */
  userRegistration: OnsiteUserRegistration;
  /**
   * The event-location the event is at.
   */
  eventLocation: EventLocation;
}
@Injectable()
export class EhsCovidReqService {
  constructor(@Inject(EHS_BASE_URL_INJECTION_TOKEN) private baseUrl: string) {}
  /**
   * Generates the buffer for the pdf to generated
   */
  public generate(params: CommonParams) {
    const { user, userRegistration, eventLocation } = params;

    return combineLatest([
      from(import('@pre-covid-req-pdf')),
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ]).pipe(
      take(1),
      tap(
        ([, { default: pdfMake }, { default: pdfFonts }]) =>
          (pdfMake.vfs = pdfFonts.pdfMake.vfs)
      ),
      mergeMap(([{ generatePreCovidReqPdf }, { default: pdfMake }]) =>
        generatePreCovidReqPdf({
          userRegistrations: [{ ...userRegistration, user }],
          event: eventLocation,
          baseUrl: this.baseUrl
        }).then(
          (pdf) =>
            new Promise<Buffer>((resolve) =>
              pdfMake.createPdf(pdf).getBuffer(resolve)
            )
        )
      )
    );
  }

  /**
   * Generates and downloads the covid requisition.
   *
   * **note** this currently isn't used as widespread as possible,
   * nor is it optimized with new techniques for rendering the images
   * within the pdf.
   */
  public generateAndDownload(params: CommonParams) {
    const { user, userRegistration, eventLocation } = params;

    combineLatest([
      from(import('@pre-covid-req-pdf')),
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ])
      .pipe(take(1))
      .subscribe(
        ([
          { generatePreCovidReqPdf },
          { default: pdfMake },
          { default: pdfFonts }
        ]) => {
          pdfMake.vfs = pdfFonts.pdfMake.vfs;

          generatePreCovidReqPdf({
            userRegistrations: [{ ...userRegistration, user }],
            event: eventLocation,
            baseUrl: this.baseUrl
          }).then((pdfContent) => {
            pdfMake
              .createPdf(pdfContent)
              .download(`covid-19-req-${new Date().getTime()}.pdf`);
          });
        }
      );
  }
}
