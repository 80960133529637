import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaintenanceSetting } from '@common';

const BASE_URL = '/api/v1/platform-settings';

@Injectable({
  providedIn: 'root'
})
export class PlatformSettingsHttpService {
  constructor(private http: HttpClient) {}

  public getMaintenance() {
    return this.http.get<MaintenanceSetting | null>(`${BASE_URL}/maintenance`);
  }
}
