import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaintenanceSetting, PlatformSettings } from '@common';

const BASE_URL = '/api/v1/admin/platform-settings';

@Injectable({
  providedIn: 'root'
})
export class AdminPlatformSettingsHttpService {
  constructor(private http: HttpClient) {}

  get() {
    return this.http.get<PlatformSettings>(`${BASE_URL}`);
  }

  updateMaintenance(maintenance: MaintenanceSetting) {
    return this.http.post<PlatformSettings>(
      `${BASE_URL}/maintenance`,
      maintenance
    );
  }
}
