export enum RegisterResponseReasons {
  /**
   * Value that means the user didn't fill out the entire form.
   * This generally shouldn't be returned that often as the client-side
   * should handle client-side validation.
   */
  BAD_REQUEST = 'bad_request',
  /**
   * Value if the re-captcha was found to be invalid.
   */
  BAD_CAPTCHA = 'bad_captcha',
  /**
   * The company code provided was not found
   */
  BAD_COMPANY_CODE = 'bad_company_code',
  /**
   * The user's name is already taken. The client-side validator
   * should protect against this tho.
   */
  BAD_USERNAME = 'bad_username',
  /**
   * The user already exists within the system,
   * we don't actually pass this from the
   * backend at this time
   */
  DUPLICATE = 'duplicate'
}
