/**
 * The vaccine completion status
 *
 * RXA 20
 */
export enum VaccineCompletionStatus {
  COMPLETE = 'cp',
  NOT_ADMINISTERED = 'na',
  PARTIALLY_ADMINISTERED = 'pa',
  REFUSED = 're'
}
