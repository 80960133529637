/* eslint-disable @typescript-eslint/no-unused-vars */
import { Company, PersonType } from '@common';
import { createReducer, Action, on } from '@ngrx/store';
import { companyActions } from './company.actions';

export interface CompanyState {
  loading: boolean;
  /**
   * The user's current company
   */
  company: Company;
  /**
   * The array of person-types the given company supports.
   * Used during sign-in.
   */
  personTypes?: Record<string, PersonType[]>;
  /**
   * Stand-alone logoUrl map of data
   */
  logoUrls: Record<string, Pick<Company, '_id' | 'clientCode' | 'logoUrl'>>;
  /**
   * If the logo-url loading is loading
   */
  logoUrlsLoading: boolean;
  /**
   * If the client code called is valid
   */
  isValidClientCode?: boolean;
  /**
   * If the client code is loading
   */
  isValidClientCodeLoading?: boolean;
}

export const reducer = createReducer(
  {} as CompanyState,
  on(companyActions.get, (state: CompanyState) => ({
    ...state,
    loading: true
  })),
  on(companyActions.getSuccess, (state: CompanyState, { company }) => ({
    ...state,
    company,
    loading: false
  })),
  on(companyActions.getFailed, (state: CompanyState) => ({
    ...state,
    loading: false
  })),
  on(companyActions.getPersonTypes, (state: CompanyState) => ({
    ...state,
    loading: true
  })),
  on(
    companyActions.getPersonTypesSuccess,
    (state: CompanyState, { clientCode, personTypes }) => ({
      ...state,
      loading: false,
      personTypes: {
        ...state.personTypes,
        [clientCode]: personTypes
      }
    })
  ),
  on(companyActions.getPersonTypesFailed, (state: CompanyState) => ({
    ...state,
    loading: false
  })),
  on(companyActions.isValidClientCode, (state: CompanyState) => ({
    ...state,
    isValidClientCodeLoading: true
  })),
  on(
    companyActions.isValidClientCodeSuccess,
    (state: CompanyState, { valid }) => ({
      ...state,
      isValidClientCode: valid,
      isValidClientCodeLoading: false
    })
  ),
  on(
    companyActions.isValidClientCodeFailed,
    ({ isValidClientCode, ...state }: CompanyState) => ({
      ...state,
      isValidClientCodeLoading: false
    })
  ),
  on(
    companyActions.clearIsValidClientCode,
    ({ isValidClientCode, isValidClientCodeLoading, ...state }: CompanyState) =>
      state
  ),

  on(companyActions.getCompanyLogoUrl, (state: CompanyState) => ({
    ...state,
    logoUrlsLoading: true
  })),
  on(
    companyActions.getCompanyLogoUrlSuccess,
    (state: CompanyState, { company }) => ({
      ...state,
      logoUrlsLoading: false,
      logoUrls: company
        ? {
            ...state.logoUrls,
            [company.clientCode]: company
          }
        : state.logoUrls
    })
  ),
  on(companyActions.getCompanyLogoUrlFailed, (state: CompanyState) => ({
    ...state,
    logoUrlsLoading: false
  }))
);

export function CompanyReducer(state: CompanyState, action: Action) {
  return reducer(state, action);
}
