import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { UserResult } from '@common';
import { createReducer, Action, on } from '@ngrx/store';
import { selectId } from '../utils/select-id';
import { userResultActions } from './user-result.actions';

export interface UserResultState extends EntityState<UserResult> {
  loading?: boolean;
  faxLoading?: boolean;
}

const adapter = createEntityAdapter<UserResult>({
  selectId
});

const reducer = createReducer<UserResultState>(
  adapter.getInitialState({}),
  on(userResultActions.get, (state) => ({ ...state, loading: true })),
  on(userResultActions.getSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(userResultActions.getFailed, (state) => ({ ...state, loading: false })),

  on(userResultActions.getByUserReg, (state) => ({ ...state, loading: true })),
  on(userResultActions.getByUserRegSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(userResultActions.getByUserRegFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(userResultActions.list, (state) => ({ ...state, loading: true })),
  on(userResultActions.listSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(userResultActions.listFailed, (state) => ({ ...state, loading: false })),
  on(userResultActions.set, (state, { userResult }) =>
    adapter.upsertOne(userResult, state)
  ),
  on(userResultActions.fax, (state) => ({ ...state, faxLoading: true })),
  on(userResultActions.faxSuccess, (state) => ({
    ...state,
    faxLoading: false
  })),
  on(userResultActions.faxFailed, (state) => ({ ...state, faxLoading: false }))
);

export function UserResultReducer(state: UserResultState, action: Action) {
  return reducer(state, action);
}
