import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EventService,
  ServiceType,
  EventServiceText,
  DefaultEventServiceText
} from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventServiceHttpService {
  constructor(private http: HttpClient) {}

  public get(id: string): Observable<EventService> {
    return this.http.get<EventService>(`api/v1/service/${id}`);
  }

  public list(): Observable<EventService[]> {
    return this.http.get<EventService[]>('api/v1/service');
  }

  public getText(params: {
    eventService: string;
    serviceType: ServiceType | 'coaching';
  }): Observable<Partial<EventServiceText> | DefaultEventServiceText> {
    const { eventService, serviceType } = params;

    return this.http.get<Partial<EventServiceText> | DefaultEventServiceText>(
      `api/v1/service/${eventService}/text`,
      {
        params: new HttpParams().set('serviceType', serviceType)
      }
    );
  }
}
