import { ObjectId } from 'mongodb';
import { Company } from '../company';
import { DbDocument } from '../db-document';
import { User } from '../user';
import { UserResult } from '../user-result/user-result';

/**
 * The 2 types of reverts we can make.
 * We default to `all` by default.
 */
export enum HpnImportAuditRevertTypes {
  ALL = 'all',
  RESULTS = 'results'
}

export const isHpnImportAuditRevertTypes = (
  type: string
): type is HpnImportAuditRevertTypes =>
  Object.values(HpnImportAuditRevertTypes).includes(
    type as HpnImportAuditRevertTypes
  );
/**
 * Status of an import, if the value is
 * "success" then we saved values to the database successfully,
 * if the value is "failed", then we DIDN'T save
 * the values to the database
 */
export enum HpnImportAuditStatus {
  SUCCESS = 'success',
  FAILED = 'failed'
}
/**
 * Error that represents a failed HpnParsedData class instantiation.
 */
export class HpnParsedDataError extends Error {
  constructor(
    public message: string,
    public data: unknown,
    public err?: unknown
  ) {
    super(message);
  }
}

/**
 * "Branded-type" for hpn-import-audits. Use this instead of `string`
 * for the _id property
 */
export type HpnImportAuditId = string & { readonly brand: unique symbol };

// tslint:disable-next-line: variable-name
export const HpnImportAuditId = (hpnImportAuditId: string) =>
  hpnImportAuditId as HpnImportAuditId;
/**
 * Audit of hpn-imports, saves most of the meta-data for
 * success and failures.
 */
export interface HpnImportAudit extends DbDocument<HpnImportAuditId> {
  /**
   * Status of an import, if the value is
   * "success" then we saved values to the database successfully,
   * if the value is "failed", then we DIDN'T save
   * the values to the database.
   */
  status: HpnImportAuditStatus;
  /**
   * The company this import was for, used to keep track
   * of failed and successful imports.
   */
  company: string | ObjectId | Company;
  /**
   * Parsed list of data-errors, **could** be useful in
   * determining the issue.
   */
  parsedDataErrors: Array<Partial<HpnParsedDataError>>;
  /**
   * The list of user's imported to the system
   * will be empty if this import "failed".
   */
  users: Array<string | ObjectId | User>;
  /**
   * The list of user's updated via the import. If the user already
   * existed, we just update the address, phone & first name details
   */
  updatedUsers: Array<string | ObjectId | User>;
  /**
   * The list of user-results imported into the system,
   * will be empty if this import "failed".
   */
  userResults: Array<string | ObjectId | UserResult>;
  /**
   * The overall number of rows in the import
   */
  totalRows: number;
  /**
   * The list of unique `id` strings
   */
  uniqueIds: number;
  /**
   * The number of uniqueIds tried to import
   */
  uniqueUserIds: number;
  /**
   * The number of conflicting ids that where imported, can be used to
   * help determine duplicate records.
   */
  conflictingIds: string[];
  /**
   * The list of unique-user-ids that conflicted, can be used to
   * help determine duplicate records.
   */
  conflictingUniqueUserIds: string[];
  /**
   * Represents if this audit has been reverted. As of #3345, this represents
   * if the audit has been **fully** reverted, or "all".
   *
   */
  reverted?: boolean;
  /**
   * Represents if this audit has been "partially" reverted, this should still
   * allow for the "all" revert to be done, even if that means user-results
   * aren't going to be reverted, as they already have been.
   *
   * This will be true along with `reverted` being true if the "full revert"
   * ocurred, and can't be reverted again.
   *
   * Added as of #3345
   *
   */
  revertedResults?: boolean;
  /**
   * A general message that is displayed,
   * can be used to generally describe an error
   */
  message: string;
  /**
   * An object of unstructured data that can be used to debug imports.
   */
  meta: unknown;
  /**
   * The number of imports that only included a user, but not a user-result.
   */
  partials: number;
}
