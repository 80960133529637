import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsEditRegistrationDialogData } from './ehs-edit-registration-dialog.data';
import { EhsEditRegistrationDialogResponse } from './ehs-edit-registration-dialog.response';
import { EhsEditRegistrationDialogComponent } from './ehs-edit-registration-dialog.component';

@Injectable()
export class EhsEditRegistrationDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: EhsEditRegistrationDialogData
  ): Observable<EhsEditRegistrationDialogResponse | false> {
    return this.matDialog
      .open(EhsEditRegistrationDialogComponent, { data, maxWidth: '800px' })
      .afterClosed();
  }
}
