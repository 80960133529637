<form [formGroup]="formGroup"
      (ngSubmit)="onSubmit()">
    <h1 mat-dialog-title>Add Maintenance Message</h1>
    <mat-dialog-content fxLayout="column"
                        fxLayoutAlign="start start"
                        fxLayoutGap="32px"
                        class="mat-typography">
        <mat-slide-toggle formControlName="enableMaintenance"
                          color="primary">Enable Maintenance Mode</mat-slide-toggle>
        <mat-form-field *ngIf="formGroup.get('enableMaintenance')?.value"
                        class="full-width">
            <mat-label>Maintenance Message</mat-label>
            <textarea matInput
                      formControlName="maintenanceMessage"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="7"></textarea>
            <mat-error *ngIf="formGroup.get('maintenanceMessage').hasError('required')">Message can not be
                empty</mat-error>
        </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions align="end"
                        fxLayoutGap="16px">
        <button type="button"
                class="grey-cancel"
                mat-button
                [mat-dialog-close]="false">
            Cancel
        </button>
        <button type="submit"
                color="primary"
                mat-button
                cdkFocusInitial>
            Update
        </button>
    </mat-dialog-actions>
</form>