<ng-container *ngIf="user">
  <div fxLayoutGap="8px"
       fxLayout="column"
       fxFlex>
    <mat-form-field fxFlex>
      <mat-label>
        Last Name
      </mat-label>
      <input type="text"
             matInput
             name="lastName"
             autocomplete="off"
             [(ngModel)]="user.lastName"
             #lastNameInput="ngModel"
             [matAutocomplete]="auto"
             (ngModelChange)="searchUser($event)"
             required>
      <mat-hint>
        The user's last name
      </mat-hint>
      <mat-error *ngIf="required && lastNameInput.hasError('required')">
        This field is required
      </mat-error>

      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="selectUser($event.option.value)">
        <mat-option *ngFor="let searchedUser of usersSearch"
                    [value]="searchedUser">
          <span>{{searchedUser.firstName + ' ' + searchedUser.lastName}}</span>
          <span *ngIf="!!searchedUser.ssn">|
            <small>SSN: {{searchedUser.ssn}}</small>
          </span> |
          <small>DOB: {{searchedUser.birthDay |
            date: 'MM/dd/yyyy'}}
          </small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>
        Last 4 of SSN
      </mat-label>
      <input matInput
             type="text"
             inputmode="numeric"
             maxlength="4"
             autocomplete="off"
             name="ssn"
             [pattern]="ssnRegExp"
             #ssnField="ngModel"
             [(ngModel)]="user.ssn"
             required>
      <mat-hint>
        The last 4 digits
      </mat-hint>
      <mat-error *ngIf=" required && ssnField.hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>Birth Date</mat-label>
      <input matInput
             name="birthDay"
             (blur)="formatDate(dateBdayInput.value, user, dateBdayInput)"
             placeholder="MM/DD/YYYY"
             [(ngModel)]="user.birthDay"
             autocomplete="off"
             isOver17
             birthDate
             required
             #dateBdayInput="ngModel">
      <mat-icon style="cursor: pointer; color: gray; font-size: 16px;"
                title="Clear Birthday"
                (click)="user.birthDay = ''"
                *ngIf="user.birthDay?.length"
                matSuffix>close</mat-icon>
      <mat-hint>
        MM/DD/YYYY (example: 02/20/2020)
      </mat-hint>
      <mat-error *ngIf="dateBdayInput.hasError('isOver17')">
        The user must be over 17 to join
      </mat-error>
      <mat-error *ngIf="dateBdayInput.hasError('birthDate')">
        Date is not valid
      </mat-error>
      <mat-error *ngIf="required && dateBdayInput.hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>

    <button mat-flat-button
            type="button"
            color="primary"
            (click)="change(user)">Search</button>
  </div>
</ng-container>