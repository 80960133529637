<div fxLayout="row"
     fxLayoutGap="8px"
     *ngIf="displayActions$ && displayActions$ | async">

  <a mat-icon-button
     [href]="externalUrl"
     target="_blank"
     aria-label="View in GWApps"
     (click)="$event.stopPropagation();"
     title="View in GWApps">
    <mat-icon>link</mat-icon>
  </a>

  <button type="button"
          mat-icon-button
          aria-label="Validate Data"
          title="Validate Data"
          (click)="validate.emit(); $event.stopPropagation(); $event.preventDefault()"
          [disabled]="loading">
    <mat-icon>sync_problem</mat-icon>
  </button>

  <button type="button"
          mat-icon-button
          aria-label="View Raw Data"
          title="View Raw Data"
          (click)="view.emit(); $event.stopPropagation(); $event.preventDefault()"
          [disabled]="loading">
    <mat-icon>code</mat-icon>
  </button>

</div>
