import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EhsMaintenanceDialogService } from './ehs-maintenance-dialog.service';
import { EhsMaintenanceDialogComponent } from './ehs-maintenance-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TextFieldModule } from '@angular/cdk/text-field';
@NgModule({
  declarations: [EhsMaintenanceDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Angular Material
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    TextFieldModule
  ],
  exports: [EhsMaintenanceDialogComponent],
  providers: [EhsMaintenanceDialogService]
})
export class EhsMaintenanceDialogModule {}
