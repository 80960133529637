<div *ngIf="key">
  <p>{{customReportPageNames[key]}}</p>
  <input type="file"
         hidden
         color="primary"
         (change)="onFileUpload($any($event.target)?.files)"
         [accept]="['.jpg', '.png', '.tiff']"
         #fileInput>
  <button mat-flat-button
          type="button"
          (click)="fileInput.click()"
          color="accent">
    <mat-icon style="transform:scale(.75)">upload_file</mat-icon>
    Upload Page
  </button>
</div>
