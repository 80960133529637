import { CommonResponse, UserTest } from '@common';
import { createAction, props } from '@ngrx/store';
import { QueryList } from '../../util/query-list.service';

export const userTestActions = {
  get: createAction(
    '[USER_TEST] GET',
    props<{
      id: string;
    }>()
  ),
  getSuccess: createAction(
    '[USER_TEST] GET_SUCCESS',
    props<{
      entity: UserTest;
    }>()
  ),
  getFailed: createAction('[USER_TEST] GET_FAILED'),

  list: createAction(
    '[USER_TEST] LIST',
    props<{
      query: QueryList<UserTest>;
    }>()
  ),
  listSuccess: createAction(
    '[USER_TEST] LIST_SUCCESS',
    props<{
      entities: UserTest[];
    }>()
  ),
  listFailed: createAction('[USER_TEST] LIST_FAILED'),

  listAsAdmin: createAction(
    '[USER_TEST] LIST_AS_ADMIN',
    props<{
      query: QueryList<UserTest>;
    }>()
  ),
  listAsAdminSuccess: createAction(
    '[USER_TEST] LIST_AS_ADMIN_SUCCESS',
    props<{
      entities: UserTest[];
    }>()
  ),
  listAsAdminFailed: createAction(
    '[USER_TEST] LIST_AS_ADMIN_FAILED',
    props<{
      err: CommonResponse;
    }>()
  )
};
