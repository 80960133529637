import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EhsRegEditFormDisplayService } from './ehs-reg-edit-form-display.service';
import { EhsRegEditFormComponent } from './ehs-reg-edit-form.component';
import { ToFrPipe } from './to-fr.pipe';

@NgModule({
  declarations: [EhsRegEditFormComponent, ToFrPipe],
  exports: [EhsRegEditFormComponent],
  providers: [EhsRegEditFormDisplayService],
  imports: [
    CommonModule,
    FormsModule,
    // Angular Material
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule
  ]
})
export class EhsRegEditFormModule {}
