import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationHttpService {
  constructor(private http: HttpClient) {}

  public list(): Observable<Notification[]> {
    return this.http.get<Notification[]>('api/v1/notifications/list');
  }

  public get(id: string): Observable<Notification> {
    return this.http.get<Notification>(`api/v1/notifications/${id}`);
  }

  public update(notifications: (Notification | string)[]): Observable<string> {
    return this.http.put<string>(`api/v1/notifications`, {
      notifications
    });
  }
}
