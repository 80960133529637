import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EhsAddAdminDialogModule, EhsConfirmDialogModule } from '@ehs-ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClientAdminCoreNgrxProviderDirective } from './client-admin-core-ngrx-provider.directive';
import { ClientAdminCoreNgrxEffects } from './client-admin-core-ngrx.effects';
import {
  clientAdminCoreNgrxReducer,
  CLIENT_ADMIN_CORE_NGRX_STORE_KEY
} from './client-admin-core-ngrx.state';

@NgModule({
  declarations: [ClientAdminCoreNgrxProviderDirective],
  imports: [
    StoreModule.forFeature(
      CLIENT_ADMIN_CORE_NGRX_STORE_KEY,
      clientAdminCoreNgrxReducer
    ),
    EffectsModule.forFeature([ClientAdminCoreNgrxEffects]),

    // Angular
    MatSnackBarModule,

    // Dialogs
    EhsAddAdminDialogModule,
    EhsConfirmDialogModule
  ],
  exports: [ClientAdminCoreNgrxProviderDirective]
})
export class ClientAdminCoreNgrxModule {}
