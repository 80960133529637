import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EhsAddWaiverDialogComponent } from './ehs-add-waiver-dialog.component';
import { EhsAddWaiverialogService } from './ehs-add-waiver-dialog.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [EhsAddWaiverDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    // Angular Material,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule
  ],
  exports: [EhsAddWaiverDialogComponent],
  providers: [EhsAddWaiverialogService]
})
export class EhsAddWaiverDialogModule {}
