/**
 * The list of Ethnicities
 */
export enum Ethnicities {
  HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO',
  NOT_HISPANIC_OR_LATINO = 'NOT_HISPANIC_OR_LATINO'
  // TODO: is this even an end user option?
  // UNKNOWN = 'UNKNOWN'
}

/**
 * Spec:
 * https://docs.google.com/spreadsheets/d/11KsmQjqzBeuI_xW9hYVdGNVq_KHozYGmmLZ99nNzRy8/edit#gid=142410642
 */
export interface EthnicityDef {
  enum: Ethnicities;
  /**
   * The description of this race, shown to the end user
   */
  description: string;
  /**
   * The official hl7 code
   */
  hl7Code: string;
  /**
   * The flat code
   */
  flatCode: string;
}
/**
 * Returns a human readable version of the ethnicity
 */
export const getEthnicity = (ethnicity: Ethnicities) =>
  ((
    {
      [Ethnicities.HISPANIC_OR_LATINO]: {
        enum: Ethnicities.HISPANIC_OR_LATINO,
        description: 'Hispanic or Latino',
        hl7Code: '2135-2',
        flatCode: 'H'
      },
      [Ethnicities.NOT_HISPANIC_OR_LATINO]: {
        enum: Ethnicities.NOT_HISPANIC_OR_LATINO,
        description: 'Not Hispanic Or Latino',
        hl7Code: '2186-5',
        flatCode: 'NH'
      }
      // [Ethnicities.UNKNOWN]: {
      //   enum: Ethnicities.UNKNOWN,
      //   description: 'Unknown',
      //   hl7Code: '',
      //   flatCode: ''
      // }
    } as Record<Ethnicities, EthnicityDef>
  )[ethnicity]);
