import { ObjectId } from 'mongodb';
import { Company } from './company';
import { DbDocument } from './db-document';
import { EventLocation } from './event-location';
import { ServiceLabType } from './event-service/service-lab-type';
import { ServiceType } from './event-service/service-type';
import { Genders, User } from './user';
import { UserResult } from './user-result/user-result';
import { UserResultTestResult } from './user-result/user-result-test-result';
import { UserTest } from './user-test/user-test';
import { UserTestRange } from './user-test/user-test-range';
import { AdminNote } from './admin-note';

export enum UserCriticalStatus {
  OPEN = 'open',
  PENDING = 'pending',
  CLOSED = 'closed'
}

/**
 * The type of critical call,
 * this is currently editable, but might be automatically set later
 */
export enum UserCriticalCallType {
  EDUCATIONAL = 'educational',
  CRITICAL = 'critical'
}

export interface UserCriticalResult {
  /**
   * The corresponding user-test data that was found to be out of range.
   */
  userTest: string | ObjectId | UserTest;

  /**
   * The user test range that was found to be out of range
   * saved as-is
   */
  userTestRange: UserTestRange;

  /**
   * The corresponding test-result data that was found to be out of range.
   * Saved as-is.
   */
  userResultTestResult: UserResultTestResult;
}

type UserCriticalPickedFilter = Partial<
  Pick<
    UserCritical,
    | 'firstName'
    | 'company'
    // **Note** the client-name is just used on the client-side during searching
    // not in the server-side.
    | 'companyName'
    | 'lastName'
    | 'birthDay'
    | 'collectionDate'
    | 'receivedDate'
  >
>;

export interface UserCriticalFilter extends UserCriticalPickedFilter {
  status?: UserCriticalStatus[];
}

export interface UserCriticalForm extends UserCriticalFilter {
  pageNumber: number;
}

/**
 * A critical result is a reference of a user's result, except used
 * for admin only features.
 */
export interface UserCritical extends DbDocument {
  /**
   * The company this result belongs to
   */
  company: string | ObjectId | Company;

  /**
   * The event-location. This is only set if the
   * user-critical was done for an onsite.
   */
  eventLocation?: string | ObjectId | EventLocation;

  /**
   * The corresponding user-result
   */
  userResult: string | ObjectId | UserResult;

  /**
   * The user taken from the user result.
   */
  user: string | ObjectId | User;

  /**
   * The status of the call for this record.
   */
  status: UserCriticalStatus;

  /**
   * The type of call, represents what the call is focused on.
   * Currently we only use critical, and don't use educational.
   */
  callType: UserCriticalCallType;

  /**
   * The list of nested critical results for this user-result.
   */
  criticalResults: UserCriticalResult[];

  /**
   * The type of the user-registration for the given event.
   * IE onsite/offsite. Taken from the user-registration.
   */
  serviceType?: ServiceType;

  /**
   * The type of lab this test goes for.
   * Could be an empty string.
   */
  labType: ServiceLabType | '';

  /**
   * List of notes admins can enter, and update over time
   */
  notes: AdminNote[];

  /**
   * First name of the user and
   * taken from the user's profile as-is at the time of result creation.
   *
   * This isn't given for partial users
   */
  firstName?: string;

  /**
   * Last name of the user and
   * taken from the user's profile as-is at the time of result creation.
   */
  lastName: string;

  /**
   * Gender of the user [Male | Female]
   */
  gender: Genders;

  /**
   * User's birth-day, saved as a string, and
   * taken from the user's profile as-is at the time of result creation.
   *
   * Saved as a date-string, and carries the
   */
  birthDay: string;

  /**
   * Name of the company,
   * loaded up to be saved as-is at the time of result creation.
   */
  companyName: string;

  /**
   * The date the result was performed at. This
   * includes the time in UTC time, and is taken from the user-result directly.
   */
  collectionDate: Date;

  /**
   * The user's phone number.
   *
   * This isn't given for partial users
   */
  phone?: string;

  /**
   * The user's email address.
   *
   * This isn't given for partial users
   */
  email?: string;

  /**
   * The date the result was received by the lab.
   * Not given by the health-provider, and taken from the user-result directly.
   */
  receivedDate?: Date;

  /**
   * Was the user aware of the risk?
   */
  awareOfRisk?: boolean;
}
