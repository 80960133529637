<div fxLayout="row wrap"
     fxLayoutAlign="start start"
     fxLayoutGap="8px"
     style="margin-bottom:8px">

  <!-- First Name -->
  <mat-form-field style="width: 200px">
    <mat-label>
      First Name
    </mat-label>
    <input matInput
           autocomplete="off"
           [value]="_filters$.value.firstName || ''"
           (change)="onChange({ firstName: $any($event.target)?.value })"
           (keyup)="onKeyup({ firstName: $any($event.target)?.value })"
           type="text">
    <button mat-icon-button
            matSuffix
            *ngIf="_filters$.value.firstName"
            type="button"
            title="Clear first name"
            (click)="onChange({ firstName: undefined })"
            aria-label="Clear first name">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Last Name -->
  <mat-form-field style="width: 200px">
    <mat-label>
      Last Name
    </mat-label>
    <input matInput
           autocomplete="off"
           [value]="_filters$.value.lastName || ''"
           (change)="onChange({ lastName: $any($event.target)?.value })"
           (keyup)="onKeyup({ lastName: $any($event.target)?.value })"
           type="text">
    <button mat-icon-button
            matSuffix
            *ngIf="_filters$.value.lastName"
            type="button"
            (click)="onChange({ lastName: undefined })"
            title="Clear last name"
            aria-label="Clear last name">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Status -->
  <mat-form-field style="max-width: 160px;">
    <mat-label>Status</mat-label>
    <mat-select [value]="_filters$.value.status || []"
                [multiple]="true"
                (selectionChange)="onChange({ status: $event.value })">
      <mat-option *ngFor="let statusOption of statusOptions"
                  [value]="statusOption">
        {{ statusOption | titlecase }}
      </mat-option>
    </mat-select>
    <button mat-icon-button
            matSuffix
            (click)="$event.stopPropagation(); onChange({ status: undefined })"
            *ngIf="_filters$.value.status"
            type="button"
            title="Clear client"
            aria-label="Clear Client">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Birthday -->
  <mat-form-field>
    <mat-label>Birth Date</mat-label>
    <input matInput
           placeholder="MM/DD/YYYY"
           type="date"
           [value]="_filters$.value.birthDay || ''"
           (change)="onChange({ birthDay: $any($event.target)?.value })"
           autocomplete="off"
           birthDate>
    <button mat-icon-button
            matSuffix
            type="button"
            *ngIf="_filters$.value.birthDay"
            title="Clear Birthday"
            (click)="onChange({ birthDay: undefined })"
            aria-label="Clear birthday">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Start Date -->
  <mat-form-field>
    <mat-label>Start Date</mat-label>
    <input matInput
           placeholder="MM/DD/YYYY"
           type="date"
           [value]="_filters$.value.collectionDate || ''"
           (change)="onChange({ collectionDate: $any($event.target)?.value })"
           autocomplete="off"
           birthDate>
    <button mat-icon-button
            matSuffix
            type="button"
            *ngIf="_filters$.value.collectionDate"
            title="Clear Start Date"
            (click)="onChange({ collectionDate: undefined })"
            aria-label="Clear Start Date">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- End Date -->
  <mat-form-field>
    <mat-label>End Date</mat-label>
    <input matInput
           placeholder="MM/DD/YYYY"
           type="date"
           [value]="_filters$.value.receivedDate || ''"
           (change)="onChange({ receivedDate: $any($event.target)?.value })"
           autocomplete="off"
           birthDate>
    <button mat-icon-button
            matSuffix
            type="button"
            *ngIf="_filters$.value.receivedDate"
            title="Clear Start Date"
            (click)="onChange({ receivedDate: undefined })"
            aria-label="Clear Start Date">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Client</mat-label>
    <input type="text"
           matInput
           autocomplete="off"
           [value]="companyValue$ | async"
           (change)="onChange({ companyName: $any($event.target)?.value })"
           (keyup)="onKeyup({ companyName: $any($event.target)?.value })"
           [matAutocomplete]="auto"
           (blur)="onClientBlur()"
           #companyInput>
    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayFn"
                      (optionSelected)="onChange({
                        company: $event.option.value._id,
                        companyName: undefined
                      })">
      <mat-option *ngFor="let company of companies"
                  [value]="company">
        {{company?.name}}
      </mat-option>
    </mat-autocomplete>

    <button mat-icon-button
            matSuffix
            *ngIf="companyInput.value"
            type="button"
            title="Clear company"
            (click)="onChange({company: undefined}); companyInput.value = ''"
            aria-label="Clear company">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Search Button -->
  <div class="align-with-mat-field-with-hint-start">
    <button mat-flat-button
            type="button"
            color="primary"
            style="height: 50px;"
            (click)="search.emit()">
      <mat-icon class="search-button">search</mat-icon>
    </button>
  </div>

  <!-- Clear Button -->
  <div class="align-with-mat-field-with-hint-start">
    <button mat-flat-button
            *ngIf="hasFilters$ | async"
            title="Clear _filters$.values"
            type="button"
            color="accent"
            style="height: 50px;"
            (click)="clear.emit();">
      <mat-icon class="search-button">close</mat-icon>
    </button>
  </div>

</div>
