import { EventLocation, EventLocationUtil, EventStatus, getId } from '@common';
import {
  entitySelectorsFactory,
  entityStatsSelectorFactory,
  selectQueryParam,
  selectRouteNestedParam
} from '@ehs-ngrx/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  EventLocationNgrxState,
  EventLocationNgrxStatsTypeParams,
  EVENT_LOCATION_STORE_KEY,
  ParentEventLocationNgrxState
} from './event-location-ngrx.state';

export const eventLocationNgrxFeatureSelector =
  createFeatureSelector<EventLocationNgrxState>(EVENT_LOCATION_STORE_KEY);

const baseSelectors = entitySelectorsFactory<
  EventLocation,
  string,
  ParentEventLocationNgrxState,
  EventLocationNgrxState
>({
  featureSelector: eventLocationNgrxFeatureSelector
});

const byCompanySelector = createSelector(
  baseSelectors.entitiesArraySelector,
  (entities) =>
    entities.reduce((acc, entity) => {
      if (!acc[getId(entity.company)]) {
        acc[getId(entity.company)] = {};
      }
      acc[getId(entity.company)][getId(entity)] = entity;

      return acc;
    }, {} as Record<string, Record<string, EventLocation>>)
);
const byCompanyArrSelector = createSelector(
  baseSelectors.entitiesArraySelector,
  (entities) =>
    entities.reduce((acc, entity) => {
      if (!acc[getId(entity.company)]) {
        acc[getId(entity.company)] = [];
      }
      acc[getId(entity.company)].push(entity);

      return acc;
    }, {} as Record<string, EventLocation[]>)
);

const byEventServiceArrSelector = createSelector(
  baseSelectors.entitiesArraySelector,
  (entities) =>
    entities.reduce((acc, entity) => {
      if (!acc[getId(entity.service)]) {
        acc[getId(entity.service)] = [];
      }
      acc[getId(entity.service)].push(entity);

      return acc;
    }, {} as Record<string, EventLocation[]>)
);

const byCompanyQueryParamArrSelector = createSelector(
  selectQueryParam('company'),
  byCompanyArrSelector,
  (company, eventLocationsMap) =>
    company && getId(company) ? eventLocationsMap[company] || [] : []
);

const byCompanyRouteParamArrSelector = createSelector(
  selectRouteNestedParam('companyId'),
  byCompanyArrSelector,
  (company, eventLocationsMap) =>
    company && getId(company) ? eventLocationsMap[company] || [] : []
);

const byServiceRouteParamArrSelector = createSelector(
  selectRouteNestedParam('eventServiceId'),
  byEventServiceArrSelector,
  (eventService, eventLocationsMap) =>
    eventService && getId(eventService)
      ? eventLocationsMap[eventService] || []
      : []
);

const recentEventsSelector = createSelector(
  baseSelectors.entitiesArraySelector,
  (eventLocations) =>
    EventLocationUtil.sortEventsByDate({
      eventLocations: eventLocations.filter((eventLocation) =>
        EventLocationUtil.isRecent({
          eventLocation,
          days: 7
        })
      )
    })
);

const recentForCompanySelector = createSelector(
  byCompanyRouteParamArrSelector,
  (eventLocations) =>
    eventLocations.filter((eventLocation) =>
      // **Note** this supports a "wider net", which will probably be added
      // in the future
      EventLocationUtil.isRecent({
        eventLocation,
        days: 14
      })
    )
);

const activeForCompanySelector = createSelector(
  byCompanyRouteParamArrSelector,
  (eventLocations) =>
    eventLocations.filter(
      (eventLocation) =>
        eventLocation.status === EventStatus.ACTIVE &&
        new Date(eventLocation.eventDate) >= new Date()
    )
);

const allForCompanySelector = createSelector(
  byCompanyQueryParamArrSelector,
  (eventLocations) => eventLocations
);

const recentForServiceParamSelector = createSelector(
  byServiceRouteParamArrSelector,
  (eventLocations) =>
    eventLocations.filter((eventLocation) =>
      EventLocationUtil.isRecent({
        eventLocation,
        days: 14
      })
    )
);

const currentByRouteParamSelector = createSelector(
  selectRouteNestedParam('eventLocationId'),
  baseSelectors.entitiesSelector,
  (eventLocationId, entities) => entities[eventLocationId || '']
);

// Added selectors for walkin user reg form #3954
const eventLocationIdSelector = createSelector(
  currentByRouteParamSelector,
  (params) => getId(params)
);

const eventLocationSelector = createSelector(
  baseSelectors.entitiesSelector,
  eventLocationIdSelector,
  (entities, id) => (entities || {})[id]
);

export const eventLocationNgrxSelectors = {
  ...baseSelectors,

  ...entityStatsSelectorFactory<
    ParentEventLocationNgrxState,
    EventLocationNgrxState,
    EventLocationNgrxStatsTypeParams
  >({
    featureSelector: eventLocationNgrxFeatureSelector
  }),

  // Company selectors
  byCompanySelector,
  byCompanyArrSelector,

  // Company related query-params, not used directly yet.
  byCompanyQueryParamArrSelector,
  byCompanyRouteParamArrSelector,

  recentEventsSelector,
  recentForCompanySelector,
  activeForCompanySelector,
  recentForServiceParamSelector,
  eventLocationSelector,

  allForCompanySelector,

  currentByRouteParamSelector
};
