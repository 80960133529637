import { Injectable } from '@angular/core';
import { PhoneUtil, MimeFileExtensions } from '@common';

@Injectable({
  providedIn: 'root'
})
export class WaiverPdfService {
  public downloadFromBase64(params: {
    user: string;
    eventService: string;
    data: string;
    mimeType?: string;
  }): void {
    const { data, user, eventService, mimeType } = params;

    if (!data) {
      return;
    }

    if (PhoneUtil.isSafari()) {
      PhoneUtil.openBase64Pdf(data);

      return;
    }

    const downloadLinkElement: HTMLAnchorElement = document.createElement('a');

    downloadLinkElement.href = `data:${
      mimeType || 'application/pdf'
    };base64,${data}`;

    downloadLinkElement.download = `waiver-${user}-${eventService}.${
      MimeFileExtensions[mimeType || 'application/pdf']
    }`;

    downloadLinkElement.click();
    downloadLinkElement.remove();
  }
}
