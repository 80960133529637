import { CoachingSession } from '../models/coaching-session';
import {
  EventService,
  HealthRiskAssessmentType
} from '../models/event-service/event-service';
import {
  EventServiceCoachingPeriod,
  EventServiceCoachingType
} from '../models/event-service/event-service-coaching';
import { HraResult, HraResultStatus } from '../models/hra/hra-result';
import { DateUtil } from './date-util';
import { getId } from './get-id';
import { TimeUtil } from './time-util';

/**
 * Static utility class that provides business logic
 * for the coaching feature.
 */
export class CoachingSessionUtil {
  /**
   * Returns if there has **ever** been
   * an event-service with the coaching-session active.
   *
   * This will show the feature, but may not show that anything
   * is available.
   */
  public static hasFeatureActive(params: {
    eventServices: EventService[];
  }): boolean {
    const { eventServices } = params;

    if (!eventServices) {
      return false;
    }

    return !!eventServices.find(
      (eventService) =>
        eventService &&
        eventService.coaching &&
        eventService.coaching.types &&
        eventService.coaching.types.length
    );
  }

  /**
   * Returns the date string the coaching session can be canceled by
   */
  public static getCancelDate(coachingSession: CoachingSession): string {
    if (!coachingSession) {
      return '';
    }

    return DateUtil.convertToString(
      DateUtil.getFutureDate({
        startingDate: DateUtil.convertFromString(coachingSession.date),
        days: -1
      })
    );
  }

  /**
   * Returns a human readable coaching session type
   */
  public static getReadableSessionType(
    coachingSession: CoachingSession
  ): string {
    if (!coachingSession) {
      return '';
    }
    const { sessionType } = coachingSession;

    switch (sessionType) {
      case EventServiceCoachingType.HRA:
        return 'HRA Review';
      case EventServiceCoachingType.LAB:
        return 'Lab Review';
      default:
        return '';
    }
  }

  /**
   * Returns a list of future coaching sessions, ordered
   * by their date and time, so the soonest is first.
   *
   * This will only show coaching-sessions that meet the following
   * - Are in the future (does not consider "cancel date")
   * - Its corresponding event-service is still relevant (should be automatic from event-services being passed)
   * - Its corresponding event-service has the hra feature active (will change in future with labs)
   * - Its corresponding event-service has the hra filled out
   */
  public static getFutureCoachingSessions(params: {
    /**
     * The list of relevant event-services
     */
    eventServices: EventService[];
    coachingSessions: CoachingSession[];
    /**
     * An array of hra results or a map
     * where the key is the eventService, and value is the corresponding hraResults
     */
    hraResults: Record<string, HraResult> | HraResult[];
  }): CoachingSession[] {
    const { eventServices, coachingSessions, hraResults } = params;

    if (!eventServices || !coachingSessions || !hraResults) {
      return [];
    }

    // Map of event-services to their id
    const eventServiceMap = eventServices.reduce(
      (acc, eventService) => ({
        ...acc,
        [eventService._id.toString()]: eventService
      }),
      {} as Record<string, EventService>
    );
    const hraResultsMap = Array.isArray(hraResults)
      ? this.getHraResultsMap({ hraResults })
      : hraResults;

    return (
      coachingSessions
        .filter((coachingSession) => {
          if (!this.canRemove({ coachingSession })) {
            // The coaching session is in the past, so don't include it
            return false;
          }

          if (!coachingSession || !coachingSession.eventService) {
            return false;
          }

          const eventService =
            eventServiceMap[coachingSession.eventService.toString()];

          if (!eventService) {
            // This coaching session didn't have a corresponding event service
            return false;
          }

          const hasHraFeatureActive = [
            HealthRiskAssessmentType.OPTIONAL,
            HealthRiskAssessmentType.REQUIRED
          ].includes(eventService?.hraSettings?.hra);
          const hasHraRecord =
            eventService.coaching &&
            eventService.coaching.types &&
            eventService.coaching.types.includes(
              EventServiceCoachingType.HRA
            ) &&
            hraResultsMap[eventService._id.toString()] &&
            hraResultsMap[eventService._id.toString()].status ===
              HraResultStatus.SUBMITTED;

          if (!hasHraFeatureActive || !hasHraRecord) {
            // The event-service doesn't have this feature active anymore, thus
            // this wont be supported or no hra is available, so this coachingSession wont be shown
            return false;
          }

          return true;
        })
        // **Note** this will sort from the date furthest in the future
        // to the most recent so the opposite of what we want, thus we flip it,
        // which we want to flip
        .sort(this.sortFn.bind(this))
        .reverse()
    );
  }

  /**
   * Returns a filtered list of available times.
   */
  public static filterAvailableTimes(params: {
    /**
     * The available times, does not sort only check
     */
    availableTimes: string[];
    /**
     * Only times on or AFTER this time
     * are let thru
     */
    earliestTime?: string;
  }): string[] {
    const { availableTimes, earliestTime } = params;

    if (!availableTimes) {
      return [];
    }
    const now = earliestTime || TimeUtil.getNow();

    return availableTimes.filter((time) => time > now);
  }

  /**
   * Returns if we can remove the given coaching session.
   * Currently used to display the remove button
   *
   * **note** the logic might be updated with an offset time to prevent
   * removals too close to the session time.
   *
   * **TODO** rename to "isInFuture"
   */
  public static canRemove(params: {
    coachingSession: CoachingSession;
    /**
     * The current date, as a date string
     */
    currentDate?: string;
    /**
     * The current time, as a time string
     */
    currentTime?: string;
  }): boolean {
    const { coachingSession, currentDate, currentTime } = params;

    if (coachingSession.date === currentDate) {
      return coachingSession.time > (currentTime || TimeUtil.getNow());
    }

    return coachingSession.date > (currentDate || DateUtil.getToday());
  }

  /**
   * Sorts coaching sessions from latest, to oldest based upon
   * eventDate and eventTime.
   * This method should be passed to the `.sort` method of arrays
   */
  public static sortFn(a: CoachingSession, b: CoachingSession): number {
    if (a.date === b.date) {
      // Sort by times
      return a.time > b.time ? -1 : 1;
    }

    // Otherwise sort by date
    return a.date > b.date ? -1 : 1;
  }

  /**
   * Returns if any event-service given has "infinite" capacity
   */
  public static getIsInfinite(params: {
    eventServices: EventService[];
  }): boolean {
    const { eventServices } = params;

    if (!eventServices) {
      return false;
    }

    return !!eventServices.find(
      (eventService) =>
        eventService.coaching &&
        eventService.coaching.period === EventServiceCoachingPeriod.INFINITE
    );
  }

  /**
   * Returns the number of sessions available. Will return null
   * if any of the event-services given has coaching set as infinite.
   */
  public static getCreditsRemaining(params: {
    eventServices: EventService[];
    coachingSessions:
      | Record<string, Array<CoachingSession>>
      | CoachingSession[];
  }): number | 'infinite' {
    const { eventServices, coachingSessions } = params;

    if (!eventServices || !coachingSessions) {
      return 0;
    }

    if (this.getIsInfinite(params)) {
      // If a service is infinite, return null as there is unlimited amounts
      // of sign-ups for this user
      return 'infinite';
    }

    // This is **all** the coaching sessions, without considering relevant ones
    // based upon the event-services given
    const coachingSessionMap = Array.isArray(coachingSessions)
      ? this.getCoachingSessionMap({
          coachingSessions
        })
      : coachingSessions;
    const coachingEventServices = eventServices.filter(
      (eventService) =>
        eventService &&
        eventService._id &&
        eventService.coaching &&
        eventService.coaching.types
    );
    const availableSpaces = coachingEventServices.reduce(
      (acc, eventService) => {
        // Add up the available space. The amount **should** be defined, if not its
        // technically just 0
        return acc + (eventService.coaching.amount || 0);
      },
      0
    );
    const usedSpaces = coachingEventServices.reduce(
      (acc, eventService) =>
        acc + (coachingSessionMap[eventService._id.toString()] || []).length,
      0
    );
    const remaining = availableSpaces - usedSpaces;

    if (remaining < 0) {
      // Somehow over capacity
      return 0;
    }

    return remaining;
  }

  /**
   * Maps a list of coaching sessions to a map where
   * the key is the event-service id, and the value is the list
   * of coaching sessions for that event-service.
   */
  public static getCoachingSessionMap(params: {
    coachingSessions: CoachingSession[];
  }): Record<string, Array<CoachingSession>> {
    const { coachingSessions } = params;

    if (!coachingSessions) {
      return undefined;
    }

    return coachingSessions.reduce((acc, coachingSession) => {
      if (!acc[coachingSession.eventService.toString()]) {
        acc[coachingSession.eventService.toString()] = [];
      }
      acc[coachingSession.eventService.toString()].push(coachingSession);

      return acc;
    }, {} as Record<string, Array<CoachingSession>>);
  }

  /**
   * Maps a list of hra results to their eventService.
   */
  public static getHraResultsMap(params: {
    hraResults: HraResult[];
  }): Record<string, HraResult> {
    const { hraResults } = params;

    return (hraResults || []).reduce(
      (acc, hraResult) => ({
        ...acc,
        [getId(hraResult.eventService)]: hraResult
      }),
      {} as Record<string, HraResult>
    );
  }

  /**
   * Returns the most relevant event-service that has the coaching feature
   * enabled.
   */
  public static getCoachingEventService(params: {
    /**
     * The list of event-services that are relevant
     * that will be checked for the coaching feature.
     * If any of the event-services has this active, then
     * this feature is on.
     *
     * This should be in order of "most relevant" to least relevant via
     * sorting.
     */
    eventServices: EventService[];
    /**
     * The list of coaching sessions the user has already signed up for.
     * Will be linked with the coaching sessions to determine
     * if the user has any available coaching sessions left.
     */
    coachingSessions:
      | Record<string, Array<CoachingSession>>
      | CoachingSession[];
    /**
     * The hra results are used to determine which event-services are
     * to be shown.
     */
    hraResults: Record<string, HraResult> | HraResult[];
    /**
     * Flag that can be passed that will return the event-service that
     * is just missing the HRA, rather then has one.
     */
    isJustMissingHra?: boolean;
  }): EventService | undefined {
    const { eventServices, coachingSessions, hraResults, isJustMissingHra } =
      params;

    if (!eventServices || !coachingSessions || !hraResults) {
      return undefined;
    }

    const coachingSessionsMap = Array.isArray(coachingSessions)
      ? this.getCoachingSessionMap({
          coachingSessions
        })
      : coachingSessions;
    // Map of hra results where the key is their corresponding
    // event service.
    const hraResultsMap = Array.isArray(hraResults)
      ? this.getHraResultsMap({ hraResults })
      : hraResults;

    return eventServices.find((eventService) => {
      if (
        !eventService ||
        !eventService._id ||
        !eventService.coaching ||
        !eventService.coaching.types ||
        !eventService.coaching.types.length
      ) {
        return false;
      }

      const hasHraFeatureActive = [
        HealthRiskAssessmentType.OPTIONAL,
        HealthRiskAssessmentType.REQUIRED
      ].includes(eventService?.hraSettings?.hra);
      const hasHraRecord =
        eventService.coaching.types.includes(EventServiceCoachingType.HRA) &&
        hraResultsMap[getId(eventService)] &&
        hraResultsMap[getId(eventService)].status === HraResultStatus.SUBMITTED;

      // The lab flow isn't used yet
      // const hasLabFlow = eventService.coaching.types.includes(
      //   EventServiceCoachingType.LAB
      // );
      if (!hasHraFeatureActive) {
        // If the event service doesn't have hra act all, return false
        return false;
      }

      if (!hasHraRecord) {
        if (isJustMissingHra) {
          // If this flag is passed then this will be the
          // one to use
          return true;
        }

        // If no hra is defined, then this setting isn't given
        return false;
      }

      if (isJustMissingHra) {
        // If there is **is** an hra record, but
        // this flag was passed, then don't return this.
        return false;
      }

      if (
        eventService.coaching.period === EventServiceCoachingPeriod.INFINITE
      ) {
        // We wont check the amount if given infinite allotments
        return true;
      }

      const numCoachingSessions = coachingSessionsMap[getId(eventService)]
        ? coachingSessionsMap[getId(eventService)].length
        : 0;

      // Otherwise, only allow this event-service if there are allotments left
      return eventService.coaching.amount > numCoachingSessions;
    });
  }
}
