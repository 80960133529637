import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminUser, CommonResponse, PlatformStats } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminCoreHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the current admin-user
   */
  public getAdminUser(): Observable<AdminUser> {
    return this.http.get<AdminUser>('api/v1/admin/user');
  }

  /**
   * Returns all admins
   */
  public getAdminList(): Observable<AdminUser[]> {
    return this.http.get<AdminUser[]>('api/v1/admin/user/admin/list');
  }

  /**
   * Updates the given admin
   */
  public updateAdminScopes(admin: Partial<AdminUser>): Observable<AdminUser> {
    return this.http.put<AdminUser>('api/v1/admin/user/admin/update', admin);
  }

  /**
   * Creates a new admin
   */
  public createAdmin(email: string): Observable<AdminUser> {
    return this.http.post<AdminUser>('api/v1/admin/user/admin/create', {
      email
    });
  }

  /**
   * Removes the given admin
   */
  public deleteAdmin(adminId: string): Observable<AdminUser> {
    return this.http.delete<AdminUser>(`api/v1/admin/user/admin/${adminId}`);
  }

  /**
   * Returns platform stats for a given date range
   */
  public getStatsByDateRange(params: { startDate: string; endDate: string }) {
    const { startDate, endDate } = params;
    let httpParams = new HttpParams();

    if (startDate) {
      httpParams = httpParams.append('startDate', startDate);
    }

    if (endDate) {
      httpParams = httpParams.append('endDate', endDate);
    }

    return this.http.get<PlatformStats>(`api/v1/admin/stats/platform/range`, {
      params: httpParams
    });
  }

  /**
   * Logout the current admin user,
   * **note** this is used only for the admin to logout normally
   * and is the same as the "logout" for the normal user.
   */
  public logout(): Observable<CommonResponse> {
    return this.http.put<CommonResponse>('api/v1/admin/user/logout', {});
  }
}
