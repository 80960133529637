import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsMaintenanceDialogComponent } from './ehs-maintenance-dialog.component';
import { EhsMaintenanceDialogResponse } from './ehs-maintenance-dialog.response';
import { PlatformSettings } from '@common';

@Injectable()
export class EhsMaintenanceDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data?: PlatformSettings | null
  ): Observable<EhsMaintenanceDialogResponse> {
    return this.matDialog
      .open(EhsMaintenanceDialogComponent, {
        data,
        maxWidth: '800px'
      })
      .afterClosed();
  }
}
