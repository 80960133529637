<div class="ehs-user-critical-results-table">
  <table class="ehs-table">
    <tr>
      <th *ngFor="let col of columns;let i = index"
          [ngClass]="col.key"
          [style.--width]="i === columns.length - 1 ? '100%' : col.width">
        {{ col.title }}
      </th>
    </tr>

    <tr *ngFor="let result of criticalResults">
      <td *ngFor="let col of columns"
          [ngClass]="col.key">
        <ng-container [ngSwitch]="col.key">

          <ng-container *ngSwitchCase="'resultName'">
            {{ result.userResultTestResult.resultName }}
          </ng-container>

          <ng-container *ngSwitchCase="'value'">
            {{ result.userResultTestResult.value }}
          </ng-container>

          <ng-container *ngSwitchCase="'rangeValues'">
            {{ result | userTestRangeValues }}
          </ng-container>

          <ng-container *ngSwitchCase="'collectionDate'">
            {{ result.userResultTestResult.collectionDate | date: 'mediumDate': '-00:00' }}
          </ng-container>

        </ng-container>
      </td>
    </tr>
  </table>
</div>
