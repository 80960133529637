import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientAdminCoreHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the current admin-user
   */
  public getClientAdminUser(): Observable<User> {
    return this.http.get<User>('api/v1/client/user');
  }

  /**
   * Returns all admins
   */
  public getClientAdminList(): Observable<User[]> {
    return this.http.get<User[]>('api/v1/client/user/admin/list');
  }

  /**
   * Updates the given admin
   */
  public updateClientAdminScopes(admin: Partial<User>): Observable<User> {
    return this.http.put<User>('api/v1/client/user/admin/update', admin);
  }

  /**
   * Creates a new admin
   */
  public createClientAdmin(email: string): Observable<User> {
    return this.http.post<User>('api/v1/client/user/admin/create', {
      email
    });
  }

  /**
   * Removes the given admin
   */
  public deleteClientAdmin(adminId: string): Observable<User> {
    return this.http.delete<User>(`api/v1/client/user/admin/${adminId}`);
  }
}
