import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AdminCoreFacade } from '../app-store/admin-core/admin-core.facade';
import { clientLogger } from '../client-logger';
import { StatusHttpService } from '../http/status-http.service';

/**
 * This interceptor only applies to the ADMIN USER currently.
 * The end-user version will be added at a later time.
 */
@Injectable({
  providedIn: 'root'
})
export class KeepAliveInterceptor implements HttpInterceptor {
  /**
   * Delay is set to 5 minutes, which is half the session time
   * on the back-end.
   */
  private readonly DELAY = 5 * 60 * 1000;
  /**
   * If we are to reset the http request timer
   */
  private reset$ = new BehaviorSubject<null>(null);
  /**
   * If we are to call the back-end again ourselves as a
   * call hasn't been made by other means.
   */
  private call$ = this.reset$.pipe(
    switchMap(() => timer(this.DELAY, this.DELAY))
  );

  constructor(
    private statusHttp: StatusHttpService,
    private adminFacade: AdminCoreFacade
  ) {
    combineLatest([this.call$, this.adminFacade.user$])
      .pipe(
        // Only do this if there is an admin user
        filter(([, user]) => !!user),
        tap(() => clientLogger.log('pinging', new Date())),
        switchMap(() => this.statusHttp.get())
      )
      .subscribe(() => {});
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Anytime we make a request, just re-set the interceptor counter
    return next.handle(request).pipe(tap(() => this.reset$.next(null)));
  }
}
