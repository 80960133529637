import { OnsiteRegistrationComboType } from '../constants/onsite-registration-combo-type';
import { OnsiteRegistrationType } from '../constants/onsite-registration-type';
import { UserTestIdList } from '../constants/user-test-id-list';
import { CustomPricing } from '../models/event-service/custom-pricing';
import {
  EventService,
  EventServiceStatus
} from '../models/event-service/event-service';
import { OffsiteService } from '../models/event-service/offsite-service';
import { OnsiteService } from '../models/event-service/onsite-service';
import {
  isProviderHealthService,
  ProviderHealthService
} from '../models/event-service/provider-health-service';
import { ServiceLabType } from '../models/event-service/service-lab-type';
import { ServiceType } from '../models/event-service/service-type';
import { SiteVaccinationType } from '../models/event-service/site-service';
import { PayType } from '../models/pay-type';
import { User } from '../models/user';
import { UserInsurance } from '../models/user-insurance';
import { UserRegistration } from '../models/user-registration';
import { UserRegistrationStatus } from '../models/user-registration/base-user-registration';
import {
  isOffsiteUserRegistration,
  OffsiteUserRegistration
} from '../models/user-registration/offsite-user-registration';
import {
  isOnsiteUserRegistration,
  OnsiteUserRegistration
} from '../models/user-registration/onsite-user-registration';
import {
  isProviderHealthUserRegistration,
  ProviderHealthUserRegistration
} from '../models/user-registration/provider-health-user-registration';
import { UserResult } from '../models/user-result/user-result';
import { UserTest, UserTestId } from '../models/user-test/user-test';
import { DateUtil } from './date-util';
import { EventServiceUtil } from './event-service-util';
import { getId } from './get-id';
import { toMap } from './to-map';

/**
 * Static utility class that provides methods
 * to handle user-registrations.
 */
export class UserRegistrationUtil {
  /**
   * Returns if the given user signed up for insurance for their registrations,
   * and it is supported with the given event-service.
   *
   * Added due to expansion of logic checks related to insurance,
   * see #3117
   */
  public static usesInsurance(params: {
    /**
     * The user-registrations we are to check for the group-type.
     */
    userRegistration: OnsiteUserRegistration | OffsiteUserRegistration;
    /**
     * The event-service we are to check for the group-type.
     */
    eventService: EventService;
  }): boolean {
    const { userRegistration, eventService } = params;

    if (!userRegistration || !eventService) {
      return false;
    }

    const service = EventServiceUtil.getSelectedService({
      eventService,
      serviceType: userRegistration.serviceType
    }) as OnsiteService | OffsiteService;

    if (!service) {
      return false;
    }

    const groupTypeIndex = userRegistration.groupType;

    const groupType =
      service &&
      service.groupTypes &&
      service.groupTypes.length >= groupTypeIndex
        ? service.groupTypes[groupTypeIndex]
        : undefined;

    if (!groupType) {
      return false;
    }

    return EventServiceUtil.captureInsurance({
      groupType,
      optionalTests: userRegistration.optionalTests
    });
  }

  /**
   * Added to pull insurance information from registration records
   * since it was requested to save it separately from the
   * user's insurance record in #4103.
   */
  public static getRegistrationInsurance(
    userRegistration: UserRegistration
  ): UserInsurance {
    if (
      !isOnsiteUserRegistration(userRegistration) &&
      !isOffsiteUserRegistration(userRegistration)
    ) {
      return undefined;
    }

    if (!userRegistration.insurance) {
      return undefined;
    }

    return userRegistration.insurance as UserInsurance;
  }

  /**
   * If the given user-registration has the covid vaccine setup
   * and signed up for.
   */
  public static hasCOVIDVaccine(userRegistration: UserRegistration): boolean {
    if (
      !userRegistration ||
      userRegistration.serviceType !== ServiceType.ONSITE
    ) {
      // At this time onsites are the only place that supports covid vaccinations
      return false;
    }
    userRegistration = userRegistration as OnsiteUserRegistration;

    return !!userRegistration.vaccination?.vaccinationType?.includes(
      SiteVaccinationType.COVID_19
    );
  }

  /**
   * If the given user-registration has the flu vaccine setup
   * and signed up for.
   */
  public static hasFluVaccine(userRegistration: UserRegistration): boolean {
    if (!isOnsiteUserRegistration(userRegistration)) {
      return false;
    }
    userRegistration = userRegistration as OnsiteUserRegistration;

    return !!userRegistration.vaccination?.vaccinationType?.includes(
      SiteVaccinationType.FLU
    );
  }

  /**
   * Gets a user readable string for the given registration type,
   * or blank string if the type is invalid. Can handle single or multiple types
   * by joining each version with &
   */
  public static getOnsiteRegistrationType(
    types: OnsiteRegistrationType | OnsiteRegistrationType[]
  ): string {
    const getSingleType = (type: OnsiteRegistrationType) =>
      ({
        [OnsiteRegistrationType.SCREENING]: 'Screening',
        [OnsiteRegistrationType.FLU_VACCINE]: 'Flu Vaccine',
        [OnsiteRegistrationType.COVID_VACCINE]: 'COVID Vaccine'
      }[type] || '');

    return Array.isArray(types)
      ? types.map(getSingleType).join(' & ')
      : getSingleType(types);
  }

  /**
   * Returns the types defined within the user-registration.
   * Can be used to determine what "onsite-features" were active for
   * the given user-registrations. Should primarily be used
   * for display, as it DOESN'T take into account what the original
   * service provided which may or may not be a problem if it changes.
   */
  public static getOnsiteRegistrationTypeFromReg(
    userRegistration: Pick<OnsiteUserRegistration, 'vaccination' | 'screening'>
  ): OnsiteRegistrationType[] {
    if (!userRegistration) {
      return [];
    }
    const types: OnsiteRegistrationType[] = [];
    const vaccinationTypes =
      userRegistration.vaccination?.vaccinationType || [];

    if (vaccinationTypes.includes(SiteVaccinationType.COVID_19)) {
      types.push(OnsiteRegistrationType.COVID_VACCINE);
    }

    if (vaccinationTypes.includes(SiteVaccinationType.FLU)) {
      types.push(OnsiteRegistrationType.FLU_VACCINE);
    }

    if (userRegistration.screening) {
      types.push(OnsiteRegistrationType.SCREENING);
    }

    return types;
  }

  /**
   * Returns the combo types defined within the user-registration.
   * Should be used over the `getOnsiteComboTypesFromService` method,
   * as the user-registration can include/omit things
   * the user doesn't want to sign up for.
   *
   * Will return null if missing the user-registration
   *
   * **note** internally uses the `getOnsiteRegistrationTypeFromReg`
   * **note** unlike the `getOnsiteComboTypesFromService` method,
   * this method only returns the single combo-type that represents
   * the user-registration.
   */
  public static getOnsiteRegistrationComboTypesFromReg(
    userRegistration: Pick<OnsiteUserRegistration, 'vaccination' | 'screening'>
  ): OnsiteRegistrationComboType | null {
    if (!userRegistration) {
      return null;
    }
    const types = this.getOnsiteRegistrationTypeFromReg(userRegistration);

    const hasScreening = types.includes(OnsiteRegistrationType.SCREENING);
    const hasFlu = types.includes(OnsiteRegistrationType.FLU_VACCINE);
    const hasCOVID = types.includes(OnsiteRegistrationType.COVID_VACCINE);

    // **Not** all three are not supported at this time
    if (hasScreening && hasFlu) {
      return OnsiteRegistrationComboType.SCREENING_AND_FLU;
    }

    if (hasScreening && hasCOVID) {
      return OnsiteRegistrationComboType.SCREENING_AND_COVID;
    }

    if (hasScreening) {
      return OnsiteRegistrationComboType.SCREENING_ONLY;
    }

    if (hasFlu) {
      return OnsiteRegistrationComboType.FLU_VACCINATION_ONLY;
    }

    if (hasCOVID) {
      return OnsiteRegistrationComboType.COVID_VACCINATION_ONLY;
    }

    return null;
  }

  /**
   * Returns if the user-registration feature is active.
   * This should only be used for the user-registration widget
   */
  public static hasFeatureActive(params: {
    /**
     * Relevant event-services
     */
    eventServices: EventService[];
    /**
     * The list of user-registrations the user already
     * signed up for
     */
    userRegistrations: Array<UserRegistration>;
    /**
     * The user-results the user already signed up for
     */
    userResults: Array<UserResult>;
  }): boolean {
    const { eventServices, userRegistrations, userResults } = params;

    const activeServices = (eventServices || []).filter(
      (eventService) => eventService?.status === EventServiceStatus.ACTIVE
    );

    if (!activeServices?.length) {
      return false;
    }

    const nonAdditionalRegistrations = userRegistrations.filter(
      (reg) => !reg.isAdditional
    );

    const userRegistrationMap: Record<string, UserRegistration> = toMap({
      entities: nonAdditionalRegistrations,
      key: 'eventService'
    });

    const userResultsMap: Record<string, UserResult> = toMap({
      entities: userResults,
      key: 'userRegistration'
    });

    return !!activeServices.find((eventService) => {
      const hasServices = !!(
        eventService?.services && eventService.services.length
      );

      if (!hasServices) {
        // If it has no services, then ignore this service.
        return false;
      }

      const userRegistration = userRegistrationMap[getId(eventService)];

      if (!userRegistration) {
        // If there is no registration, then this feature should be shown.
        return true;
      }
      const hasResult = !!userResultsMap[getId(userRegistration)];

      if (hasResult) {
        // If the event-service+user-registration already has a result,
        // then we don't need to show this feature for this service.
        return false;
      }

      return true;
    });
  }

  /**
   * Returns if there is an existing user registration
   * and an additional registration is allowed.
   * Allowed if the following are true:
   * - vaccination service available
   * - additional non-onsite service available
   * - initial registration was not an onsite screening
   */
  public static isAdditionalRegAvailable(params: {
    /**
     * Relevant event-services
     */
    eventServices: EventService[];
    /**
     * The list of user-registrations the user already
     * signed up for
     */
    userRegistrations: Array<UserRegistration>;
    /**
     * The user-results the user already signed up for
     */
    userResults: Array<UserResult>;
    // Flag to disable outside of local
    isDisabled?: boolean;
  }): boolean {
    const { eventServices, userRegistrations, userResults, isDisabled } =
      params;

    // Flag to hide this widget in production
    if (isDisabled) {
      return false;
    }

    const activeServices = (eventServices || []).filter(
      (eventService) => eventService?.status === EventServiceStatus.ACTIVE
    );

    if (!activeServices?.length) {
      return false;
    }

    const additionalRegistrations: UserRegistration[] = [];

    const registrations = userRegistrations.filter((reg) => {
      // Ignore no shows and cancellations
      if (
        reg.status === UserRegistrationStatus.NO_SHOW ||
        reg.status === UserRegistrationStatus.CANCELED
      ) {
        return false;
      }

      if (reg.isAdditional) {
        additionalRegistrations.push(reg);

        return false;
      } else {
        return true;
      }
    });

    const userRegistrationMap: Record<string, UserRegistration> = toMap({
      entities: registrations,
      key: 'eventService'
    });

    const additionalRegistrationMap: Record<string, UserRegistration> = toMap({
      entities: additionalRegistrations,
      key: 'eventService'
    });

    return !!activeServices.find((eventService) => {
      const hasServices = !!(
        eventService?.services && eventService.services.length
      );

      if (!hasServices) {
        return false;
      }

      // If no vaccination options, dont show
      const hasVaccineEvents =
        EventServiceUtil.hasVaccine({
          eventService,
          vaccinationType: SiteVaccinationType.COVID_19
        }) ||
        EventServiceUtil.hasVaccine({
          eventService,
          vaccinationType: SiteVaccinationType.FLU
        });

      if (!hasVaccineEvents) {
        return false;
      }

      const serviceTypes = EventServiceUtil.getServiceTypes({
        eventService
      });

      // If only onsite services, dont show
      if (
        !serviceTypes.includes(ServiceType.OFFSITE) &&
        !serviceTypes.includes(ServiceType.PROVIDER_HEALTH)
      ) {
        return false;
      }

      const userRegistration = userRegistrationMap[getId(eventService)];
      const additionalRegistration =
        additionalRegistrationMap[getId(eventService)];

      // If they haven't registered, dont show
      if (!userRegistration && !additionalRegistration) {
        return false;
      }

      // If registered for onsite screening, dont show
      if (
        isOnsiteUserRegistration(userRegistration) &&
        userRegistration.screening
      ) {
        return false;
      }

      // If one registration, but not additional one, show
      if (!additionalRegistration) {
        return true;
      }

      const userResultsMap = toMap({
        entities: userResults,
        key: 'userRegistration'
      });

      // If additional registration doesnt have result, show tile
      const hasResult = !!userResultsMap[getId(additionalRegistration)];

      return !hasResult;
    });
  }

  /**
   * Returns if the given userRegistration is valid.
   * This requires **all** of the users eventServices.
   */
  public static isRelevantUserRegistration(params: {
    userRegistration: UserRegistration;
    /**
     * Event services are provided as a map for easier lookups.
     */
    eventServices: { [key: string]: EventService };
    /**
     * The list of user-results, used to check with the user-registrations.
     * Where the key is the user-registration, rather than the _id
     */
    userResults?: Record<string, UserResult>;
    /**
     * Used to differentiate between registration tiles
     */
    includeAdditional?: boolean;
  }): boolean {
    let { userRegistration } = params;
    const { eventServices, userResults, includeAdditional } = params;

    if (!userRegistration || !eventServices) {
      return false;
    }

    // If additional registration tile, exclude non-additional registrations
    if (includeAdditional && !userRegistration.isAdditional) {
      return false;
    }

    // If non-additional registration tile, exclude additional registrations
    if (!includeAdditional && userRegistration.isAdditional) {
      return false;
    }

    const { serviceType } = userRegistration;

    switch (serviceType) {
      case ServiceType.ONSITE:
        userRegistration = userRegistration as OnsiteUserRegistration;

        return (
          !(userResults || {})[getId(userRegistration)] &&
          userRegistration.eventDate >= DateUtil.getToday() &&
          userRegistration.status !== UserRegistrationStatus.NO_SHOW &&
          userRegistration.status !== UserRegistrationStatus.CANCELED
        );
      case ServiceType.OFFSITE:
        userRegistration = userRegistration as OffsiteUserRegistration;

        return this.isRelevantOffsiteUserRegistration({
          userRegistration,
          eventServices,
          userResults
        });
      case ServiceType.PROVIDER_HEALTH:
        userRegistration = userRegistration as ProviderHealthUserRegistration;

        return this.isRelevantProviderUserRegistration({
          userRegistration,
          eventServices,
          userResults
        });
      default:
        return true;
    }
  }

  /**
   * Returns if the offsite registration is relevant.
   * Based upon the corresponding service's screening-deadline.
   */
  private static isRelevantOffsiteUserRegistration(params: {
    userRegistration: OffsiteUserRegistration;
    /**
     * Event services are provided as a map for easier lookups.
     */
    eventServices: { [key: string]: EventService };
    /**
     * The map of user-results, used to check if the user-registration
     * already has a result, and thus isn't shown anymore.
     */
    userResults?: Record<string, UserResult>;
  }): boolean {
    const { userRegistration, eventServices, userResults } = params;
    const eventService = eventServices[getId(userRegistration.eventService)];

    if (!eventService) {
      return false;
    }

    const service = EventServiceUtil.getSelectedService({
      eventService,
      serviceType: ServiceType.OFFSITE
    }) as OffsiteService;

    if (!service) {
      return false;
    }
    const hasResult = !!userResults?.[getId(userRegistration)];

    if (hasResult) {
      return false;
    }

    // Changed from registration-deadline to screening-deadline,
    // and changed to support day-off requests.
    return service.screeningDeadline >= DateUtil.getToday();
  }

  /**
   * Returns if the provider health registration is relevant.
   * Based upon the corresponding service's returnBy date.
   */
  private static isRelevantProviderUserRegistration(params: {
    userRegistration: ProviderHealthUserRegistration;
    /**
     * The event services are provided as a map for easier lookups.
     */
    eventServices: { [key: string]: EventService };
    /**
     * The map of user-results, used to check if the user-registration
     * already has a result, and thus isn't shown anymore.
     */
    userResults?: Record<string, UserResult>;
  }): boolean {
    const { userRegistration, eventServices, userResults } = params;
    const eventService =
      eventServices[userRegistration.eventService.toString()];

    if (!eventService) {
      return false;
    }

    const service = EventServiceUtil.getSelectedService({
      eventService,
      serviceType: ServiceType.PROVIDER_HEALTH
    }) as ProviderHealthService;

    if (!service) {
      return false;
    }
    const hasResult = !!userResults?.[getId(userRegistration)];

    if (hasResult) {
      return false;
    }

    return service.returnBy >= DateUtil.getToday();
  }

  /**
   * Check to see if `UserPayment` should be created for optional tests
   *
   * Required checks:
   * 1. Service type is `OFFSITE`
   * 2. User registered with optional tests
   * 3. Selected group type from registration has `SELF_PAY`
   */
  public static shouldPayOptionalTests({
    userRegistration,
    eventService,
    userTests
  }: {
    userRegistration: UserRegistration;
    eventService: EventService;
    userTests: { [key: UserTestId]: UserTest };
  }): boolean {
    if (
      userRegistration.serviceType !== ServiceType.OFFSITE ||
      !userRegistration.optionalTests ||
      !userRegistration.optionalTests.length
    ) {
      return false;
    }

    // We need the offsiteService to get the list of the groupTypes
    const offsiteService = EventServiceUtil.getSelectedService({
      serviceType: ServiceType.OFFSITE,
      eventService
    }) as OffsiteService;

    if (!offsiteService) {
      return false;
    }

    const customPricingMap = toMap({
      key: 'test',
      entities: eventService.customPricing || []
    }) as Record<string, CustomPricing>;

    // Check if the test costs are 0
    const hasCost = userRegistration.optionalTests
      .map(getId)
      .map((testId) =>
        typeof customPricingMap[testId]?.cost === 'number'
          ? customPricingMap[testId].cost
          : userTests[testId]?.cost || 0
      )
      .find((cost) => !!cost);

    if (!hasCost) {
      return false;
    }

    // User the groupTypeIndex in userRegistration to get the groupType saved in
    // offsiteService
    const groupTypeIndex = userRegistration.groupType;
    const groupType = offsiteService.groupTypes[groupTypeIndex];

    if (!groupType) {
      return false;
    }

    // Finally check if 'SELF_PAY' exists
    return (groupType.payOptionalTests || []).includes(PayType.SELF_PAY);
  }

  /**
   * Check to see if `UserPayment` should be created for panel
   *
   * Required checks:
   * 1. Service type is `OFFSITE`
   * 2. User has panel & has cost
   * 3. Selected group type's payPackage has `SELF_PAY`
   */
  public static shouldPayPanel({
    userRegistration,
    eventService,
    userTests,
    user
  }: {
    userRegistration: UserRegistration;
    eventService: EventService;
    userTests: { [key: UserTestId]: UserTest };
    user: User;
  }): boolean {
    if (userRegistration.serviceType !== ServiceType.OFFSITE) {
      return false;
    }

    // We need the offsiteService to get the list of the groupTypes
    const offsiteService = EventServiceUtil.getSelectedService({
      serviceType: ServiceType.OFFSITE,
      eventService
    }) as OffsiteService;

    if (!offsiteService) {
      return false;
    }

    const panel = EventServiceUtil.getPanel({
      service: offsiteService,
      user,
      userTests
    });

    if (!panel) {
      return false;
    }

    if (!offsiteService.packagePrice) {
      return false;
    }

    // User the groupTypeIndex in userRegistration to get the groupType saved in
    // offsiteService
    const groupTypeIndex = userRegistration.groupType;
    const groupType = offsiteService.groupTypes[groupTypeIndex];

    if (!groupType) {
      return false;
    }

    // Finally check if 'SELF_PAY' exists
    return (groupType.payPackage || []).includes(PayType.SELF_PAY);
  }

  /**
   * Check if user needs to pay. Either the user has to pay for optionalTests or panel or both.
   */
  public static shouldPay({
    userRegistration,
    eventService,
    userTests,
    user
  }: {
    userRegistration: UserRegistration;
    eventService: EventService;
    userTests: { [key: UserTestId]: UserTest };
    user: User;
  }) {
    return (
      this.shouldPayOptionalTests({
        userRegistration,
        eventService,
        userTests
      }) ||
      this.shouldPayPanel({
        userRegistration,
        eventService,
        userTests,
        user
      })
    );
  }

  /**
   * Returns the Onsite Package price based on the user registration group type.
   * We check for the groupType package price, if it is null or undefined, then
   * we return the onsiteService.packagePrice.
   */
  public static getOnsitePackagePrice({
    eventService,
    userRegistration
  }: {
    eventService: EventService;
    userRegistration: OnsiteUserRegistration;
  }): number | undefined {
    const onsiteService = EventServiceUtil.getSelectedService({
      serviceType: ServiceType.ONSITE,
      eventService
    }) as OnsiteService;

    if (!onsiteService || !userRegistration) {
      return undefined;
    }

    const groupType = onsiteService.groupTypes[userRegistration.groupType];

    if (!groupType) {
      return undefined;
    }

    if (!(groupType.payPackage || []).includes(PayType.SELF_PAY)) {
      return undefined;
    }

    return typeof groupType.packagePrice === 'number'
      ? groupType.packagePrice
      : onsiteService.packagePrice;
  }

  /**
   * Returns the Offsite Package price based on the user registration group type.
   * We check for the groupType package price, if it is null or undefined, then
   * we return the offsiteService.packagePrice.
   * This is currently used in the billing report, as the packagePrice is needed without test costs
   */
  public static getOffsitePackagePrice({
    eventService,
    userRegistration
  }: {
    eventService: EventService;
    userRegistration: OffsiteUserRegistration;
  }): number | undefined {
    const offsiteService = EventServiceUtil.getSelectedService({
      serviceType: ServiceType.ONSITE,
      eventService
    }) as OffsiteService;

    if (
      !offsiteService ||
      !userRegistration ||
      typeof userRegistration.groupType !== 'number'
    ) {
      return undefined;
    }

    const groupType = offsiteService.groupTypes[userRegistration.groupType];

    if (!groupType) {
      return undefined;
    }

    if (!(groupType.payPackage || []).includes(PayType.SELF_PAY)) {
      return undefined;
    }

    return typeof groupType.packagePrice === 'number'
      ? groupType.packagePrice
      : offsiteService.packagePrice;
  }

  /**
   * Return the package & optional test cost array as well as the
   * total cost of all tests the user needs to pay.
   */
  public static getOffsiteUserPaymentTestCost({
    userRegistration,
    eventService,
    offsiteService,
    userTests,
    user
  }: {
    userRegistration: OffsiteUserRegistration;
    eventService: EventService;
    offsiteService: OffsiteService;
    userTests: UserTest[];
    user: User;
  }) {
    const userTestsMap = toMap({
      key: '_id',
      entities: userTests
    }) as Record<UserTestId, UserTest>;

    // Check if the user group type requires panel payment
    const shouldPayPanel = UserRegistrationUtil.shouldPayPanel({
      userRegistration,
      eventService,
      userTests: userTestsMap,
      user
    });

    const panel = EventServiceUtil.getPanel({
      service: offsiteService,
      user,
      userTests: userTests
    });

    // Check if the user has to pay for optional tests
    const shouldPayOptionalTests = UserRegistrationUtil.shouldPayOptionalTests({
      userRegistration,
      eventService,
      userTests: userTestsMap
    });

    const customPricingMap = toMap({
      key: 'test',
      entities: eventService.customPricing || []
    }) as Record<string, CustomPricing>;

    // Get all optional test costs
    const optionalTestsCost = shouldPayOptionalTests
      ? (userRegistration?.optionalTests || []).map((test) => ({
          userTest: getId(test),
          cost:
            customPricingMap[getId(test)]?.cost ||
            userTestsMap[getId(test)]?.cost ||
            0
        }))
      : [];

    // Add the Package & Optional Tests and their cost in the array
    const allTestsCost = [
      ...(shouldPayPanel
        ? [
            {
              userTest: getId(panel),
              cost: offsiteService.packagePrice
            }
          ]
        : []),
      ...optionalTestsCost
    ];

    return {
      totalCost: allTestsCost
        .map(({ cost }) => cost)
        .reduce((acc, cost) => acc + cost, 0),
      testsCost: allTestsCost
    };
  }

  /**
   * Check if registration has a colocare test (check if optionalTests, additionalTests or additionalServices have the UserTestIdList.COLOCARE_TEST_ID included).
   * Don't forget to check based on the conditional ones. Use the utilities to check all the user-tests that the user registered for
   */
  public static hasColocareTest(params: {
    userRegistration: UserRegistration;
    eventService: EventService;
    user: User;
    userTests: Record<string, UserTest> | UserTest[];
  }): boolean {
    const { userRegistration, eventService, user, userTests } = params;

    if (
      !userRegistration ||
      isProviderHealthUserRegistration(userRegistration) ||
      !userTests ||
      !eventService
    ) {
      return false;
    }

    const service = EventServiceUtil.getSelectedService({
      eventService,
      serviceType: userRegistration.serviceType
    });

    if (isProviderHealthService(service)) {
      return false;
    }

    const additionalServices = EventServiceUtil.getAdditionalServices({
      user,
      service,
      userTests
    }).map((userTest) => getId(userTest));

    const additionalTests = EventServiceUtil.getAdditionalTests({
      user,
      service,
      userTests,
      userRegistration,
      eventDate:
        (userRegistration as OnsiteUserRegistration)?.eventDate || undefined
    }).map((userTest) => getId(userTest));

    const optionalTests =
      userRegistration.optionalTests?.map((userTest) => getId(userTest)) || [];

    const panel = EventServiceUtil.getPanel({ user, service, userTests });

    // The list of ids for userTests that have colocare tests.
    const serviceTestIds = new Set([
      ...(panel ? [panel] : []),
      ...additionalServices,
      ...additionalTests,
      ...optionalTests
    ]);

    return (
      serviceTestIds.has(UserTestIdList.COLOCARE_TEST_QUEST_ID) ||
      serviceTestIds.has(UserTestIdList.COLOCARE_TEST_LABCORP_ID)
    ); // Added additional case to handle both ColoCare tests
  }

  /**
   * For the offsite registration dashboard tile, it was requested to refer to the requisition number
   * as either the Req/Control Number or the Order Number according to whether it was a Lab Corp or
   * Quest Lab respectively.
   */
  public static getReqNumberName(userRegistration: OffsiteUserRegistration) {
    if (!userRegistration?.labType) {
      return 'ID';
    }

    return userRegistration.labType === ServiceLabType.LAB_CORP
      ? 'Req/Control Number'
      : 'Order Number';
  }
}
