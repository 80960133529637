import { ActionCreator, createAction, Action } from '@ngrx/store';

/**
 * Utility function that creates 3 actions suffixed with _SUCCESS and _FAILED.
 * Useful to quickly create "http-actions"
 *
 * Taken from:
 * https://indepth.dev/posts/1451/ngrx-best-practices-new#write-helper-functions-and-custom-operators
 */
export const entityHttpActionFactory = <
  RequestPayload,
  ResponsePayload = Record<string, never>,
  ErrorPayload = Record<string, never>,
  CancelPayload = Record<string, never>
>(
  actionType: string
): {
  prompt: ActionCreator<
    string,
    (props?: RequestPayload) => {
      payload: RequestPayload;
    } & Action<string>
  >;
  req: ActionCreator<
    string,
    (props?: RequestPayload) => {
      payload: RequestPayload;
    } & Action<string>
  >;
  success: ActionCreator<
    string,
    (props?: ResponsePayload) => {
      payload: ResponsePayload;
    } & Action<string>
  >;
  failed: ActionCreator<
    string,
    (props?: ErrorPayload) => {
      payload: ErrorPayload;
    } & Action<string>
  >;
  canceled: ActionCreator<
    string,
    (props?: CancelPayload) => {
      payload: CancelPayload;
    } & Action<string>
  >;
} => ({
  prompt: createAction(`${actionType}_PROMPT`, (payload: RequestPayload) => ({
    payload
  })),
  req: createAction(actionType, (payload: RequestPayload) => ({ payload })),
  success: createAction(
    `${actionType}_SUCCESS`,
    (payload?: ResponsePayload) => ({
      payload
    })
  ),
  failed: createAction(`${actionType}_FAILED`, (payload: ErrorPayload) => ({
    payload
  })),
  canceled: createAction(
    `${actionType}_CANCELED`,
    (payload: CancelPayload) => ({
      payload
    })
  )
});
