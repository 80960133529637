import { UserResultTestStatus } from '../models/user-result/user-result-test-result';
import { PrimaryOrderCode } from '../models/user-test/user-test';

/**
 * Code of the UserResult parent Test. Anytime an admin adds a new value to a user test
 * that was not populated from the lab, we will add the test under the Data correction
 * parent test with an id of Data Correction Code.
 *
 * **note** this is used in Data correction
 */
export const DATA_CORRECTION_CODE: PrimaryOrderCode =
  PrimaryOrderCode('admin-entry');

export const DATA_CORRECTION_PANEL_DEFAULT = {
  id: DATA_CORRECTION_CODE,
  name: 'Added in Data Correction',
  results: [],
  status: UserResultTestStatus.F
};
