import { Injectable } from '@angular/core';
import { UserReimbursementUploadsColumn } from './user-reimbursement-uploads-column';

@Injectable({
  providedIn: 'root'
})
export class EhsUserReimbursementUploadsTableService {
  getColumns(): UserReimbursementUploadsColumn[] {
    return [
      {
        title: '',
        prop: 'checkbox',
        width: '20px'
      },
      {
        title: 'Status',
        prop: 'status',
        width: '40px'
      },
      {
        title: 'Name',
        prop: 'name',
        width: '230px'
      },
      {
        title: 'Company',
        prop: 'companyName',
        width: '200px'
      },
      {
        title: 'Created At',
        prop: 'createdAt',
        width: '140px'
      },
      {
        title: 'Amount',
        prop: 'amount',
        width: '150px'
      },
      {
        title: 'Purchase Date',
        prop: 'purchasedDate',
        width: '140px'
      },
      {
        title: 'Expense Type',
        prop: 'expenseType',
        width: '140px'
      },
      {
        title: 'Purchased From',
        prop: 'purchasedFrom',
        width: '140px'
      },
      {
        title: 'Files',
        prop: 'attachments',
        width: '60px'
      }
    ];
  }
}
