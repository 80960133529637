import { PrimaryOrderCode } from '../models/user-test/user-test';

/**
 * Enum of tests to "promote" in the sorting of user tests.
 *
 * All of these should match with the orderCode and *maybe* the primaryOrderCode
 * for all these tests.
 * Use `getCode` from UserTestUtil.
 *
 * TODO: not sure if this is the correct codes
 *
 * **note** this is a map to keep the order of values
 */
export const USER_TESTS_TO_PROMOTE = new Map<string, PrimaryOrderCode>()
  .set('MEASURED_HEIGHT', PrimaryOrderCode('4200'))
  .set('MEASURED_WEIGHT', PrimaryOrderCode('4201'))
  .set('BMI', PrimaryOrderCode('4205'))
  .set('BP_SYSTOLIC', PrimaryOrderCode('4208'))
  .set('BP_DIASTOLIC', PrimaryOrderCode('4209'))
  .set('CHOLESTEROL', PrimaryOrderCode('221010'))
  .set('HDL', PrimaryOrderCode('7600'))
  .set('TRIGLYCERIDES', PrimaryOrderCode('221010'))
  .set('LDL', PrimaryOrderCode('221010'))
  .set('TOTAL_CHOL_AND_HDL', PrimaryOrderCode('221010'))
  .set('NON_HDL_CHOLESTEROL', PrimaryOrderCode('011976'));
