import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NgModel,
  NG_VALIDATORS,
  Validator
} from '@angular/forms';
/**
 * Directive that checks if the password and confirm password field are the same.
 *
 * To use properly, add this to the "password" field, and pass in the
 * "atlControl" via an input.
 *
 * This directive should be placed on **both** password and confirm-password controls.
 *
 * **This varient should replace the `password-validator` module, **and**
 * the "same-password" validator. As this issue was drawn out and took a while
 * to fix, there are multiple implementations. This version is the most stable,
 * the most recent, and the best documented.
 *
 * **usage**
 * To correctly run the change detection in onPush components, be sure
 * to add the following code to the password component:
 * ```
 * (keyup)="confirmPasswordInput.control.updateValueAndValidity()"
 * ```
 * And similar code to the confirm component, this will force updates
 * to the form state and run cdr on both components when either
 * changes.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[pwdConfirm]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordValidatorDirective,
      multi: true
    }
  ]
})
export class PasswordValidatorDirective implements Validator {
  /**
   * Confirm Password field control,
   * if not given the password field will **always** return an error
   */
  @Input() altControl?: NgModel;

  public validate(control: AbstractControl): {
    pwdMatch: { value: string } | null;
  } {
    if (!this.altControl) {
      return { pwdMatch: { value: '' } };
    }
    const password = control.value;
    const altValue = this.altControl.value;

    if (password !== altValue && this.altControl.touched) {
      // We need to update the validation on both fields
      this.altControl.control.setErrors({
        ...this.altControl.control.errors,
        pwdMatch: false
      });

      return { pwdMatch: { value: password } };
    }

    return null;
  }
}
