import { AdminUser, getId, PlatformStats } from '@common';
import { selectId } from '@ehs-ngrx/common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { adminCoreNgrxActions } from './admin-core-ngrx.actions';

export const ADMIN_CORE_NGRX_STORE_KEY = 'adminCoreNgrx';

export interface ParentAdminCoreNgrxState {
  [ADMIN_CORE_NGRX_STORE_KEY]: AdminCoreNgrxState;
}

/**
 * Note this state is a simplified version of the
 * AdminGwState in the ng-portal app.
 */
export interface AdminCoreNgrxState extends EntityState<AdminUser> {
  loading?: boolean;
  /**
   * The actual admin-user currently logged in.
   *
   * **note** during the transition period, this will be
   * directly "injected" into the store from the ng-portal state. As such
   * there are some actions not yet defined, or added.
   */
  stats?: PlatformStats;
  user?: AdminUser;
  dateRange?: { startDate: string; endDate: string };
}

const adapter = createEntityAdapter<AdminUser>({ selectId });

export const adminCoreNgrxReducer = createReducer<AdminCoreNgrxState>(
  adapter.getInitialState({}),
  on(adminCoreNgrxActions.set, (state, { user }) => ({
    ...state,
    user
  })),

  on(adminCoreNgrxActions.list.req, (state) => ({ ...state, loading: true })),
  on(adminCoreNgrxActions.list.success, (state, { payload: { entities } }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(adminCoreNgrxActions.list.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(adminCoreNgrxActions.addAdmin.req, (state) => ({
    ...state,
    loading: true
  })),

  on(adminCoreNgrxActions.addAdmin.success, (state) => ({
    ...state,
    loading: false
  })),

  on(adminCoreNgrxActions.addAdmin.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(adminCoreNgrxActions.update.req, (state) => ({ ...state, loading: true })),

  on(adminCoreNgrxActions.update.success, (state, { payload: { admin } }) => {
    const updateState = adapter.updateOne(
      {
        id: getId(admin),
        changes: admin
      },
      { ...state, loading: false }
    );

    if (!state.user || getId(admin) !== getId(state.user)) {
      return updateState;
    }

    return {
      ...updateState,
      user: {
        ...state.user,
        ...admin
      }
    };
  }),

  on(adminCoreNgrxActions.update.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(adminCoreNgrxActions.removeAdmin.req, (state) => ({
    ...state,
    loading: true
  })),

  on(
    adminCoreNgrxActions.removeAdmin.success,
    (state, { payload: { adminId } }) =>
      adapter.removeOne(adminId, { ...state, loading: false })
  ),

  on(adminCoreNgrxActions.removeAdmin.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(adminCoreNgrxActions.getStats.req, (state, { payload }) => ({
    ...state,
    dateRange: { ...payload },
    loading: true
  })),

  on(adminCoreNgrxActions.getStats.success, (state, resp) => ({
    ...state,
    stats: resp.payload.stats,
    loading: false
  })),

  on(adminCoreNgrxActions.getStats.failed, (state) => ({
    ...state,
    loading: false
  }))
);
