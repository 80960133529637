<div class="ehs-user-reimbursement-uploads-table ehs-table-wrapper">
  <cdk-virtual-scroll-viewport itemSize="40"
                               minBufferPx="40"
                               maxBufferPx="500"
                               (scrolledIndexChange)="onScrolledIndexChange($event)"
                               class="viewport">
    <table class="ehs-table sticky-header">
      <tr>
        <th *ngFor="let col of columns; let i = index"
            [style.top]="headerTop"
            [style.--width]="i === columns.length - 1 ? '100%' : col.width">
          <ng-container [ngSwitch]="col.prop">
            <ng-container *ngSwitchCase="'checkbox'">
              <mat-checkbox [indeterminate]="indeterminate"
                            [checked]="!!allAreSelected"
                            (change)="toggleAll.emit()"
                            [disabled]="disabled"
                            color="primary">
              </mat-checkbox>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ col.title }}
            </ng-container>
          </ng-container>
        </th>
      </tr>

      <tr *cdkVirtualFor="let row of rows"
          class="row">
        <td *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.prop">
            <ng-container *ngSwitchCase="'checkbox'">
              <div (click)="$event.stopPropagation();">
                <mat-checkbox color="primary"
                              [disabled]="disabled"
                              [checked]="isSelected(row)"
                              (change)="toggle.emit(row)">
                </mat-checkbox>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'status'">
              <div style="text-align: center; height: 14px;">
                <mat-icon [ngStyle]="{'color':getStatusColor(row.status)}"
                          style="font-size: 14px; width: 14px; height: 14px; line-height: 14px;"
                          [matTooltip]="getStatusTooltip(row.status)">
                  circle
                </mat-icon>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'name'">
              {{ row.firstName }} {{ row.lastName }}
            </ng-container>
            <ng-container *ngSwitchCase="'companyName'">
              {{ row.company?.name || '' }}
            </ng-container>
            <ng-container *ngSwitchCase="'createdAt'">
              {{ row.createdAt | date: 'mediumDate': '-00:00' }}
            </ng-container>
            <ng-container *ngSwitchCase="'amount'">
              $ {{ row.amount }}
            </ng-container>
            <ng-container *ngSwitchCase="'expenseType'">
              {{ row.expenseType }}
            </ng-container>
            <ng-container *ngSwitchCase="'purchasedFrom'">
              {{ row.purchasedFrom }}
            </ng-container>
            <ng-container *ngSwitchCase="'purchasedDate'">
              {{ row.purchasedDate | date: 'mediumDate': '-00:00' }}
            </ng-container>
            <ng-container *ngSwitchCase="'attachments'">
              <ng-container *ngFor="let file of row.attachments || []">
                <button type="button"
                        color="primary"
                        mat-stroked-button
                        (click)="$event.stopImmediatePropagation(); handleDownloadReimbursementReceipt({userReimbursement:row,file})">
                  <mat-icon [title]="file.fileName"
                            style="margin:0"
                            color="warn">picture_as_pdf</mat-icon>
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span [title]="row[col.prop]">{{ row[col.prop] }}</span>
            </ng-container>
          </ng-container>
        </td>
      </tr>

      <tr *ngIf="hasNext">
        <button (click)="loadMore.emit()"
                style="margin: 16px 8px 24px"
                mat-flat-button
                color="primary"
                type="button">
          Load More
        </button>
      </tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>