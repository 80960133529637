import { createAction, props } from '@ngrx/store';
import { UserVaccination, CommonResponse } from '@common';

export const userVaccinationActions = {
  list: createAction('[USER_VACCINATION] LIST'),
  listSuccess: createAction(
    '[USER_VACCINATION] LIST_SUCCESS',
    props<{ entities: UserVaccination[] }>()
  ),
  listFailed: createAction(
    '[USER_VACCINATION] LIST_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),
  get: createAction(
    '[USER_VACCINATION] GET',
    props<{
      userVaccinationId: string;
    }>()
  ),
  getSuccess: createAction(
    '[USR_VACCINATION] GET_SUCCESS',
    props<{
      entity: UserVaccination;
    }>()
  ),
  getFailed: createAction(
    '[USER_VACCINATION] GET_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  getByUserReg: createAction(
    '[USER_VACCINATION] GET_BY_USER_VACC',
    props<{
      userRegId: string;
    }>()
  ),
  getByUserRegSuccess: createAction(
    '[USER_VACCINATION] GET_BY_USER_VACC_SUCCESS',
    props<{
      entity: UserVaccination;
    }>()
  ),
  getByUserRegFailed: createAction(
    '[USER_VACCINATION] GET_BY_USER_VACC_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  set: createAction(
    '[USER_VACCINATION] SET',
    props<{
      userVaccination: UserVaccination;
    }>()
  )
};
