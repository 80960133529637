import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsRegStatusLegendComponent } from './ehs-reg-status-legend.component';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [EhsRegStatusLegendComponent],
  imports: [
    CommonModule,
    // Angular material
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule
  ],
  exports: [EhsRegStatusLegendComponent]
})
export class EhsRegStatusLegendModule {}
