<div class="label-name"
     fxLayout="row"
     fxLayoutAlign="start center"
     fxLayoutGap="8px"
     [ngStyle]="labelStyles || {}">
  <div>{{label}}</div>
  <ng-container *ngIf="internalLink?.length">
    <a mat-icon-button
       [title]="'Redirect to ' + label"
       [routerLink]="internalLink"
       [target]="newTab ? '_blank' : '_self'">
      <mat-icon>
        launch</mat-icon>
    </a>
  </ng-container>
  <ng-container *ngIf="externalLink">
    <a mat-icon-button
       [title]="'Redirect to ' + label"
       [href]="externalLink"
       [target]="newTab ? '_blank' : '_self'">
      <mat-icon>link</mat-icon>
    </a>
  </ng-container>
</div>
<div class="label-value"
     [ngStyle]="valueStyles || {}">
  {{value}}
</div>
