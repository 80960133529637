import { DateTime, DurationLikeObject, Duration } from 'luxon';

export class TimeFormatError extends Error {}
/**
 * The time validator static utility class provides methods to convert and verify
 * "time strings" with the following format:
 * "HH:MM"
 */
export class TimeUtil {
  private static DEFAULT_TIMEZONE = 'America/Chicago';
  public static TIME_REGEX = /^([01]\d|2[0-3]):?([0-5]\d)$/;
  /**
   * Calculates the UTC time from the given time string and timezone.
   * This could be a future/past date.
   */
  public static getUtcTime(params: {
    /**
     * The formatted date for the given timezone
     */
    time: string;
    /**
     * The timezone the time is for.
     */
    zone: string;
  }): string {
    const { time, zone } = params;

    if (!this.isTime(time)) {
      throw new TimeFormatError('Invalid Time Format');
    }

    return DateTime.fromObject(
      {
        hour: this.getHour(time),
        minute: this.getMinutes(time)
      },
      {
        zone
      }
    )
      .setZone('utc')
      .toLocaleString(DateTime.TIME_24_SIMPLE);
  }

  /**
   * Returns a future time
   * If given a starting date, starts from said date+time, otherwise
   * its from midnight
   */
  public static getFutureTime(
    params: DurationLikeObject & { startingDate?: Date }
  ) {
    return DateTime.fromJSDate(params.startingDate || new Date())
      .plus(Duration.fromObject(params))
      .toFormat('HH:mm');
  }

  public static getNow(): string {
    return DateTime.fromJSDate(new Date()).toFormat('HH:mm');
  }

  /**
   * Returns the time now offset to CST assuming we are in GMT
   */
  public static getNowCST(): string {
    return DateTime.fromJSDate(new Date(), {
      zone: 'UTC-6'
    }).toFormat('HH:mm');
  }

  /**
   * Returns the current user time converted to a specific timezone.
   * If timezone not specified, we return the Chicago Timezone.
   */
  public static getCurrentTime(timezone?: string): string {
    return DateTime.fromJSDate(new Date(), {
      zone: timezone || this.DEFAULT_TIMEZONE
    }).toLocaleString(DateTime.TIME_24_SIMPLE);
  }

  public static isTime(time: string): boolean {
    return this.TIME_REGEX.test(time);
  }

  public static getHour(time: string): number {
    if (!this.isTime(time)) {
      throw new TimeFormatError('Invalid Time Format');
    }

    return Number(time.split(':')[0]);
  }

  public static getMinutes(time: string): number {
    if (!this.isTime(time)) {
      throw new TimeFormatError('Invalid Time Format');
    }

    return Number(time.split(':')[1]);
  }

  private static getMinutesFromMidnight(time: string): number {
    return this.getHour(time) * 60 + this.getMinutes(time); // 675
  }

  /**
   * Adds the given minutes to the given time.
   * This will throw an error if the resulting time is invalid
   */
  public static add(params: {
    /**
     * The time to start with
     */
    time: string;
    /**
     * The number of minutes to add
     */
    minutes: number;
  }): string {
    const { time, minutes } = params;

    if (!this.isTime(time)) {
      throw new TimeFormatError('Invalid given time ' + time);
    }
    // Const hoursToAdd = Math.floor(minutes / 60);
    // const minutesToAdd = minutes % 60;
    const pad = (num: number) => (('' + num) as string).padStart(2, '0');

    const minsFromMidnight = this.getMinutesFromMidnight(time);
    const calculatedMins = minsFromMidnight + minutes; // 720
    const finalHours = pad(Math.floor(calculatedMins / 60)); // 12
    const finalMinutes = pad(calculatedMins % 60); // 45
    // let finalMinutes = pad(this.getMinutes(time) + minutesToAdd);
    // if (finalMinutes === '60') {
    //   // Handle the special case where minutes is 60
    //   hoursToAdd++;
    //   finalMinutes = '00';
    // }

    // const finalHours = pad(this.getHour(time) + hoursToAdd);
    // const finalTime = `${finalHours}:${finalMinutes}`;
    const finalTime = `${finalHours}:${finalMinutes}`;

    if (!this.isTime(finalTime)) {
      throw new TimeFormatError('Invalid time ' + finalTime);
    }

    return finalTime;
  }

  /**
   * Returns the difference in time between the 2 time values.
   * Default unit is hours
   */
  public static calculateDifference(
    time1: string,
    time2: string,
    properties?: {
      /** Whether the difference will return in minutes or hours */
      unit?: 'hours' | 'minutes';
    }
  ): number | null {
    try {
      if (!this.isTime(time1) || !this.isTime(time2)) {
        return null;
      }

      const time1Date = DateTime.fromJSDate(new Date()).set({
        hour: this.getHour(time1) as number,
        minute: this.getMinutes(time1) as number,
        second: 0,
        millisecond: 0
      });
      const time2Date = DateTime.fromJSDate(new Date()).set({
        hour: this.getHour(time2) as number,
        minute: this.getMinutes(time2) as number,
        second: 0,
        millisecond: 0
      });
      const { unit } = properties || {};
      const unitLowerCase = ((unit || '').toLowerCase() || 'hours') as
        | 'hours'
        | 'minutes';

      return time2Date.diff(time1Date, unitLowerCase)[unitLowerCase];
    } catch (err) {
      throw new TimeFormatError('Error in checking time difference');
    }
  }

  /**
   * The only increment values supported are multiples of 15.
   */
  public static isValidIncrement(time: string): boolean {
    return this.isTime(time) && this.getMinutes(time) % 15 === 0;
  }

  /**
   * Returns a list of time strings between the
   * start and end times in 15 minute intervals
   *
   * 1. Count how many hours between the two times
   * 2. Use the number of hours to calculate the number of minutes
   * 3. Take the number of minutes and divide them to get the number
   *   of "increments" between the 2 times.
   * 4. For each increment add 15 to the start time to get the actual time.
   *   - If the minute is over 60, then add 1 to the hours
   *   - Otherwise keep subtracting from the increment amount
   */
  public static getIncrements(params: {
    startTime: string;
    endTime: string;
    /** Length of slots; Default is 15 mins */
    timeIncrement?: number;
  }): string[] {
    const { startTime, endTime, timeIncrement = 15 } = params;

    if (!this.isTime(startTime)) {
      throw new TimeFormatError('Invalid Start Time ' + startTime);
    }

    if (!this.isTime(endTime)) {
      throw new TimeFormatError('Invalid End Time ' + endTime);
    }

    if (!this.isValidIncrement(startTime)) {
      throw new TimeFormatError(
        'Invalid Start Time, not valid increment ' + startTime
      );
    }

    if (!this.isValidIncrement(endTime)) {
      throw new TimeFormatError(
        'Invalid End Time, not valid increment' + endTime
      );
    }

    if (startTime > endTime) {
      throw new TimeFormatError('Start Time is greater than End Time');
    }

    if (startTime === endTime) {
      return [startTime];
    }

    const startTimeMinutes = this.getMinutesFromMidnight(startTime);
    const endTimeMinutes = this.getMinutesFromMidnight(endTime);
    const diff = endTimeMinutes - startTimeMinutes;
    const numberOfIncrements = diff / timeIncrement;
    const increments = new Array(numberOfIncrements)
      .fill(null)
      .map((_, i) => this.add({ time: startTime, minutes: i * timeIncrement }));

    // As a sanity test, we verify all numbers returned are valid
    increments.forEach((increment) => {
      if (!this.isTime(increment)) {
        throw new TimeFormatError('Invalid time calculated' + increments);
      }
    });

    return increments;
  }

  public static timeToAmPm(time: string) {
    if (!time || !this.isTime(time)) {
      return '';
    }
    const hour = this.getHour(time);
    const minutes = time.split(':')[1];

    if (hour === 0) {
      return `12:${minutes} AM`;
    }

    return `${hour > 12 ? hour - 12 : hour}:${minutes} ${
      hour - 12 >= 0 ? 'PM' : 'AM'
    }`;
  }

  /**
   * Takes a time input and converts the time from the origin timezone
   * to the new timezone. For example 7:00pm in Los Angeles is 10:00pm in New York.
   * This function will take the time as 19:00 and return it as 22:00.
   */
  public static updateTimeTimezone(params: {
    time: string;
    originTimezone: string;
    newTimezone: string;
  }) {
    const { time, originTimezone, newTimezone } = params;

    if (!time || !originTimezone || !newTimezone) {
      throw new TimeFormatError('Missing required params');
    }

    return DateTime.fromObject(
      {
        hour: this.getHour(time),
        minute: this.getMinutes(time)
      },
      {
        zone: originTimezone
      }
    )
      .setZone(newTimezone)
      .toLocaleString(DateTime.TIME_24_SIMPLE);
  }

  /**
   * Takes a JS date and return only the hour in number
   */
  public static getHourFromJSDate(date: Date): number {
    return DateTime.fromJSDate(date, { zone: 'UTC-6' }).hour;
  }
}
