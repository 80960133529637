<div class="ehs-user-critical-notes-table">
  <table class="ehs-table">
    <tr>
      <th *ngFor="let col of columns;let i = index"
          [ngClass]="col.prop"
          [style.--width]="col.prop === 'remove' ? '100%' : col.width">
        {{ col.title }}
      </th>
    </tr>

    <tr *ngFor="let note of notes; trackBy:trackByFn">
      <td *ngFor="let col of columns"
          [ngClass]="col.prop">
        <ng-container [ngSwitch]="col.prop">

          <ng-container *ngSwitchCase="'str'">
            <mat-form-field class="no-label full-width">
              <textarea matInput
                        [value]="note.str"
                        (change)="onChange({ str: $any($event.target).value }, note)">
                </textarea>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'contactedAt'">
            <mat-form-field class="no-label">
              <input matInput
                     placeholder="MM/DD/YYYY"
                     type="date"
                     [value]="note.contactedAt || ''"
                     (blur)="onChange({ contactedAt: $any($event.target).value }, note)"
                     autocomplete="off">
              <button mat-icon-button
                      matSuffix
                      type="button"
                      *ngIf="note.contactedAt"
                      title="Clear Contacted At"
                      (click)="onChange({ contactedAt: undefined }, note)"
                      aria-label="Clear Contacted At">
                <mat-icon class="icon-small">close</mat-icon>
              </button>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'deliveryTypes'">
            <mat-form-field class="no-label">
              <mat-select [value]="note.deliveryTypes || []"
                          [multiple]="true"
                          #deliveryTypes
                          (openedChange)="!$event ? onChange({ deliveryTypes: deliveryTypes.value }, note) : null"
                          (selectionChange)="null">
                <mat-option *ngFor="let type of deliveryMethodTypes"
                            [value]="type">
                  {{ type | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'createdAt'">
            {{ note.createdAt | date: 'mediumDate' }}
          </ng-container>

          <ng-container *ngSwitchCase="'admin'">
            {{ note.admin?.email | emailName | titlecase }}
          </ng-container>

          <ng-container *ngSwitchCase="'remove'">
            <button mat-icon-button
                    type="button"
                    (click)="onRemove(note)">
              <mat-icon>close</mat-icon>
            </button>
          </ng-container>

        </ng-container>
      </td>
    </tr>
  </table>
</div>