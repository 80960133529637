<ng-container *ngIf="serviceTypes">
  <mat-chip-listbox aria-label="services enabled">
    @for (serviceType of serviceTypes;track 1){
    <ng-container *ngIf="isRouted(serviceType) else showNonRouted">
      <mat-chip-option [style]="{background: getColor(serviceType.serviceType)}"
                       style="cursor: inherit"
                       (click)="$event.stopPropagation(); $event.preventDefault()">
        <a [routerLink]="serviceType.route"
           [queryParams]="serviceType.queryParams"
           (click)="$event.stopPropagation(); $event.preventDefault()"
           style="text-decoration: underline;">
          {{getDisplayText(serviceType.serviceType)}}
        </a>
      </mat-chip-option>
    </ng-container>
    <ng-template #showNonRouted>
      <mat-chip-option [style]="{background: getColor($any(serviceType))}">
        {{getDisplayText($any(serviceType))}}
      </mat-chip-option>
    </ng-template>
    }

  </mat-chip-listbox>
</ng-container>