<ng-container *ngIf="user && layout">
  <ng-container *ngFor="let row of layout.rows">
    <div [gdColumns]="row.gdColumns | tofr"
         [gdColumns.sm]="row.gdColumnsSm | tofr"
         [gdColumns.xs]="row.gdColumnsXs "
         gdGap="8px">
      <ng-container *ngFor="let field of row.fields">
        <ng-container [ngSwitch]="field.fieldType">
          <!--
            **note** we will follow the user-fields order definition
          -->
          <ng-container *ngSwitchCase="fieldTypes.EMAIL">
            <!-- TODO: add email validator! -->
            <mat-form-field class="form-spacing">
              <mat-label>Email</mat-label>
              <input type="email"
                     inputmode="email"
                     matInput
                     autocomplete="email"
                     name="email"
                     #email="ngModel"
                     appEmail
                     [(ngModel)]="user.email"
                     (change)="userChange.emit(user)"
                     [required]="field.required || !user.partial">
              <mat-error *ngIf="email.hasError('required')">
                This field is required
              </mat-error>
              <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.PHONE">
            <mat-form-field class="form-spacing">
              <mat-label>Phone</mat-label>
              <input type="text"
                     inputmode="numeric"
                     matInput
                     autocomplete="tel"
                     appPhoneFormat
                     name="phone"
                     #phone="ngModel"
                     [value]="user.phone "
                     [(ngModel)]="user.phone"
                     (change)="userChange.emit(user)"
                     [required]="!!field.required">
              <mat-error *ngIf="phone.hasError('wrongPhone')"
                         [title]="phone.errors | json">
                Phone number is invalid
              </mat-error>
              <mat-error *ngIf="phone.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.ADDRESS_1">
            <mat-form-field class="form-spacing">
              <mat-label>Mailing Address 1</mat-label>
              <input type="text"
                     matInput
                     autocomplete="address-line1"
                     name="address1"
                     #address1="ngModel"
                     [(ngModel)]="user.address1"
                     (change)="userChange.emit(user)"
                     [required]="!!field.required">
              <mat-error *ngIf="address1.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.ADDRESS_2">
            <mat-form-field class="form-spacing">
              <mat-label>Mailing Address 2</mat-label>
              <input type="text"
                     matInput
                     autocomplete="address-line2"
                     name="address2"
                     #address2="ngModel"
                     [(ngModel)]="user.address2"
                     (change)="userChange.emit(user)"
                     [required]="!!field.required">
              <mat-error *ngIf="address2.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.CITY">
            <mat-form-field class="form-spacing">
              <mat-label>City</mat-label>
              <input type="text"
                     matInput
                     autocomplete="address-level2"
                     name="city"
                     #city="ngModel"
                     [(ngModel)]="user.city"
                     (change)="userChange.emit(user)"
                     [required]="!!field.required">
              <mat-error *ngIf="city.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.STATE">
            <mat-form-field class="form-spacing">
              <mat-label>State</mat-label>
              <mat-select [(ngModel)]="user.state"
                          #stateInput="ngModel"
                          name="state"
                          (change)="userChange.emit(user)"
                          [required]="!!field.required">
                <mat-option *ngFor="let state of states"
                            [value]="state"
                            autocomplete="address-state">
                  {{ getStateName($any(state))}} - {{state}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="stateInput.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.ZIP">
            <mat-form-field class="form-spacing">
              <mat-label>Zip Code</mat-label>
              <input type="text"
                     inputmode="numeric"
                     matInput
                     autocomplete="postal-code"
                     name="zip"
                     #zip="ngModel"
                     [(ngModel)]="user.zip"
                     (change)="userChange.emit(user)"
                     [required]="!!field.required">
              <mat-error *ngIf="zip.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.COUNTY">
            <mat-form-field class="form-spacing">
              <mat-label>County</mat-label>
              <mat-select [(ngModel)]="user.countyCode"
                          #countyCodeInput="ngModel"
                          name="countyCode"
                          (change)="userChange.emit(user)"
                          [required]="!!field.required">
                <mat-option *ngFor="let countyCode of countyCodes"
                            [value]="countyCode"
                            autocomplete="address-countyCode">
                  {{ countyCodesMap[$any(countyCode)]}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="countyCodeInput.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.RACE">
            <mat-form-field class="form-spacing">
              <mat-label>Race</mat-label>
              <mat-select [(ngModel)]="user.race"
                          name="race"
                          #raceInput="ngModel"
                          (change)="userChange.emit(user)"
                          [required]="!!field.required">
                <mat-option *ngFor="let race of races"
                            [value]="race">
                  {{ getRace(race)}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="raceInput.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.ETHNICITY">
            <mat-form-field class="form-spacing">
              <mat-label>Ethnicity</mat-label>
              <mat-select [(ngModel)]="user.ethnicity"
                          name="ethnicity"
                          #ethnicityInput="ngModel"
                          (change)="userChange.emit(user)"
                          [required]="!!field.required">
                <mat-option *ngFor="let ethnicity of ethnicities"
                            [value]="ethnicity">
                  {{ getEthnicity(ethnicity)}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="ethnicityInput.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.HEIGHT">
            <mat-form-field class="form-spacing">
              <mat-label>Height - Feet</mat-label>
              <mat-select [(ngModel)]="user.heightFeet"
                          #heightFeet="ngModel"
                          name="heightFeet"
                          (change)="userChange.emit(user)"
                          [required]="!!field.required">
                <mat-option *ngFor="let foot of feet"
                            [value]="foot">
                  {{foot}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="heightFeet.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
            <mat-form-field class="form-spacing">
              <mat-label>Height - Inches</mat-label>
              <mat-select [(ngModel)]="user.heightInches"
                          #heightInches="ngModel"
                          name="heightInches"
                          (change)="userChange.emit(user)"
                          [required]="!!field.required">
                <mat-option *ngFor="let inch of inches"
                            [value]="inch">
                  {{inch}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="heightInches.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.WEIGHT">
            <mat-form-field class="form-spacing">
              <mat-label>Weight</mat-label>
              <input type="number"
                     inputmode="numeric"
                     matInput
                     autocomplete="off"
                     name="weight"
                     #weight="ngModel"
                     [(ngModel)]="user.weight"
                     (change)="userChange.emit(user)"
                     [required]="!!field.required">
              <mat-error *ngIf="weight.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.LAST_NAME">
            <mat-form-field class="form-spacing">
              <mat-label>
                Last Name
              </mat-label>
              <input type="text"
                     matInput
                     name="lastName"
                     autocomplete="off"
                     [(ngModel)]="user.lastName"
                     #lastName="ngModel"
                     (change)="userChange.emit(user)"
                     [required]="!!field.required">
              <mat-hint>
                The user's last name, will be used for identification
              </mat-hint>
              <mat-error *ngIf="lastName.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.FIRST_NAME">
            <mat-form-field class="form-spacing">
              <mat-label>
                First Name
              </mat-label>
              <input type="text"
                     matInput
                     name="firstName"
                     autocomplete="off"
                     [(ngModel)]="user.firstName"
                     #firstName="ngModel"
                     (change)="userChange.emit(user)"
                     [required]="!!field.required">
              <mat-hint>The user's first name</mat-hint>
              <mat-error *ngIf="firstName.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.SSN">
            <mat-form-field class="form-spacing">
              <mat-label>
                Last 4 of SSN
              </mat-label>
              <input matInput
                     type="text"
                     inputmode="numeric"
                     maxlength="4"
                     autocomplete="off"
                     name="ssn"
                     [pattern]="ssnRegExp"
                     #ssnField="ngModel"
                     [(ngModel)]="user.ssn"
                     (change)="userChange.emit(user)"
                     [required]="!!field.required">
              <mat-hint>
                The last 4 digits of the user's social security number
              </mat-hint>
              <mat-error *ngIf="ssnField.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.BIRTH_DATE">
            <mat-form-field class="form-spacing">
              <mat-label>Birth Date</mat-label>
              <input matInput
                     name="birthDay"
                     (change)="formatDate(birthDateInput.value)"
                     placeholder="MM/DD/YYYY"
                     [(ngModel)]="user.birthDay"
                     isOver17
                     birthDate
                     #birthDateInput="ngModel"
                     (change)="userChange.emit(user)"
                     [required]="!!field.required">
              <mat-icon style="cursor: pointer; color: gray; font-size: 16px;"
                        title="Clear Birthday"
                        (click)="user.birthDay = ''"
                        *ngIf="user.birthDay?.length"
                        matSuffix>close</mat-icon>
              <mat-hint>
                MM/DD/YYYY (example: 02/20/2020)
              </mat-hint>
              <mat-error *ngIf="birthDateInput.hasError('isOver17')">
                The user must be over 17 to join
              </mat-error>
              <mat-error *ngIf="birthDateInput.hasError('birthDate')">
                Date is not valid
              </mat-error>
              <mat-error *ngIf="birthDateInput.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.GENDER">
            <mat-form-field class="form-spacing">
              <mat-label>Gender</mat-label>
              <mat-select name="gender"
                          [(ngModel)]="user.gender"
                          #gender="ngModel"
                          (selectionChange)="userChange.emit(user)"
                          [required]="!!field.required">
                <mat-option *ngFor="let gender of genders"
                            [value]="gender">
                  {{gender | titlecase}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="gender.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="fieldTypes.PERSON_TYPE">
            <mat-form-field class="form-spacing">
              <mat-label>Member Type</mat-label>
              <mat-select name="personType"
                          #personType="ngModel"
                          [(ngModel)]="user.personType"
                          (selectionChange)="userChange.emit(user); handleMemberTypeChange($event.value)"
                          required>
                <mat-option *ngFor="let personType of personTypes"
                            [value]="personType">
                  {{getPersonTypeName(personType)}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="personType.hasError('required')">
                This field is required
              </mat-error>
            </mat-form-field>
          </ng-container>

          <!-- TODO: define other fields-->
          <ng-container *ngSwitchDefault>
            <div>
              unsupported field-type: {{field.fieldType}}
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>