import { ObjectId } from 'mongodb';
import { AdminNote } from './admin-note';
import { AdminUser } from './admin-user';
import { Company } from './company';
import { DbDocument } from './db-document';
import { EventService } from './event-service/event-service';
import { Incentive, IncentiveFile } from './incentives/incentive';
import { IncentiveCategory } from './incentives/incentive-category';
import { Genders, User } from './user';
import { UserRegistration } from './user-registration';
import { UserRegistrationId } from './user-registration/base-user-registration';
import { UserTestId } from './user-test/user-test';

// Statuses will probably change later, will update accordingly
export enum UserIncentiveStatus {
  OPEN = 'open',
  PENDING = 'pending',
  CLOSED = 'closed'
}

export enum IncentivePointsReviewStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export interface IncentiveReview {
  status: IncentivePointsReviewStatus;
  reviewer?: {
    id: AdminUser | ObjectId | string;
    name: string;
  };
}

export interface UserIncentive extends DbDocument {
  /** The company user belongs to. */
  company: string | ObjectId | Company;

  /** The user */
  user: string | ObjectId | User;

  /** The event service that the user registered for */
  eventService: string | ObjectId | EventService;

  /** The user registration tied to this incentive - if any */
  userRegistration?: UserRegistrationId | ObjectId | UserRegistration;

  /** The array of rules that this incentive has **/
  incentivePoints: IncentivePoints[];

  /** Total Points - Calculated based on the total of all the points above**/
  totalPoints: number;

  /** The status */
  status: UserIncentiveStatus;

  /** List of notes admins can enter, and update over time ?? Not sure yet if this is needed*/
  notes: AdminNote[];

  /** First name of the user and taken from the user's profile as-is at the time of incentive creation. */
  firstName: string;

  /** Last name of the user and taken from the user's profile as-is at the time of incentive creation. */
  lastName: string;

  /** Gender of the user */
  gender: Genders;

  /** User's Birthday */
  birthDay: string;

  /** Age - Do we need to keep track of how old the user was at the time of the incentive? */
  age?: number;

  /** Name of the company | used to speed up the search query */
  companyName: string;

  /** Flag used to determine if a user incentive was created via retro sync script */
  script?: boolean;

  /** If any of the completed/awarded incentives are trigger type 'admin-validated, set this flag to true for easy filtering */
  adminValidation?: boolean;

  /** If any of the completed/awarded incentives are trigger type 'self-reported, set this flag to true for easy filtering */
  selfReported?: boolean;
  /** Date of collection for the associated userResult */
  collectionDate?: Date | string;
}

export interface IncentivePoints extends DbDocument {
  /** Incentive ID > Include what rule this incentive belongs to */
  incentive: string | ObjectId | Incentive;

  /** Category */
  category: IncentiveCategory;

  /** Incentive Name */
  incentiveName: string;

  /** What change triggered this incentive to calculate */
  triggerType: IncentiveTrigger;

  /** Mark if the incentive was triggered even if the points criteria was not met */
  awarded: boolean;

  /**  Points awarded for this incentive */
  points: number;

  // The uploaded file, for upload-based incentives
  uploads?: IncentiveFile[];

  /**
   * The tier these incentive points belong to, if applicable
   */
  tier?: number;

  /** Text input for admin-validated and self-reported incentives with text-box */
  textValue?: string;

  /** Value of incentive  only required on value, range and between incentiveTest types, and is smoker*/
  value?: number | string;

  /** Date completed on, admin can edit this field hence the difference between this and createdAt */
  completedDate?: Date;

  /** Specifically for activity tracker incentive points which can be completed over multiple days */
  recordDateRange?: { startDate: string; endDate: string };
  /** Whether or not there was improvement since the last result */
  improved?: boolean;

  /**Whether these incentive points were earned via the mobile app syncing */
  healthDataSync?: boolean;

  /** If there was improvement, what was the spread */
  improvementPercentage?: number;

  /** Id of test used to determine if incentive/points should be awarded, only required on value, range and between incentiveTest types */
  userTest?: string | ObjectId | UserTestId;

  /** If the admin override the points for that user */
  adminOverride?: boolean;

  review: IncentiveReview;

  /**  The date the points were awarded */
  createdAt: Date;

  /** The date the points were updated */
  updatedAt: Date;
}

/** List of trigger types that could trigger a rule to be calculated or updated */
export enum IncentiveTrigger {
  COMPLETED_LAB = 'completed_lab',
  COMPLETED_HRA = 'completed_hra',
  COMPLETED_FLU = 'completed_flu',
  LAB_TEST_VALUE = 'lab_test_value',
  LAB_TEST_BETWEEN = 'lab_test_between',
  LAB_TEST_RANGE = 'lab_test_range',
  HRA_VALUE = 'hra_value',
  CLICK_LINK = 'click_link',
  ADMIN_VALIDATE = 'admin_validated',
  SELF_REPORTED = 'self_reported',
  FAX_RESULTS = 'fax_results',
  IS_SMOKER = 'is_smoker',
  ALT_QUALIFIER = 'alt_qualifier',
  BLOOD_PRESSURE = 'blood_pressure',
  MARQUEE_COACHING = 'marquee_coaching',
  ACTIVITY_TRACKER = 'activity_tracker',
  MARQUEE_CHALLENGES = 'marquee_challenges'
}

type UserIncentivePickedFilter = Partial<
  Pick<
    UserIncentive,
    | 'firstName'
    | 'company'
    | 'companyName'
    | 'lastName'
    | 'birthDay'
    | 'adminValidation'
  >
>;

export interface UserIncentiveFilter extends UserIncentivePickedFilter {
  status?: UserIncentiveStatus[];
}

export interface UserIncentiveForm extends UserIncentiveFilter {
  pageNumber: number;
}

/**
 * Type-guard that returns if incentive is an incentivePoints object (opposed to an Incentive)
 */
export const isIncentivePoints = (
  incentive: IncentivePoints | Incentive
): incentive is IncentivePoints =>
  typeof (<IncentivePoints>incentive).awarded === 'boolean';
