import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ehs-json-dialog',
  templateUrl: './json-dialog.component.html',
  styleUrls: ['./json-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title?: string; json: unknown }
  ) {}
}
