import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

/**
 * Just tracks current url and swaps it to previous url
 * when navigating to allow dynamic backwards navigation
 */
@Injectable({
  providedIn: 'root'
})
export class RouteTrackerService implements OnDestroy {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  private takeUntil = new Subject<void>();

  constructor(private router: Router) {
    this.currentUrl = this.router.url;

    router.events.pipe(takeUntil(this.takeUntil)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  ngOnDestroy() {
    this.takeUntil.next();
    this.takeUntil.unsubscribe();
  }

  /**
   * Returns the previously navigated url as a string
   */
  public getPreviousUrl() {
    return this.previousUrl;
  }

  /**
   * Initializes route which will become the previous route

   */
  public setCurrentUrl() {
    this.currentUrl = this.router.url;
  }
}
