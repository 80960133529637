import { UserTest } from './user-test';

/**
 * These statuses are directly reflected from the ones passed by GWApps.
 * Functionally active is the only one we care about, everything else can be
 * considered the same as "inactive", but we save all of them for reference.
 */
export enum UserTestStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

/**
 * Utility function that returns if the given user-test is active.
 * Otherwise its considered "inactive", which is a conglomerate of
 * all possible user-test-status states.
 */
export const isUserTestStatusActive = (userTest: UserTest) =>
  !!(userTest && userTest.status === UserTestStatus.ACTIVE);
