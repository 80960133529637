import { HraFormCustomValue } from './hra-question';
import { FingerStickCodes } from '../../constants/finger-stick-tests';

/**
 * This handler abstracts away all
 * the logic for the user-result "impacted"
 */
export const userResultHraValueHandler =
  ({
    code,
    ranges,
    defaultValueToReturn
  }: {
    /**
     * The finger stick user-test code we are to match
     * with and get the user values/test information.
     */
    code: FingerStickCodes;
    /**
     * The list of ranges to "return by".
     * These are gone thru in order, where if the value is
     * LESS THAN the upperRange to match, then we return the value.
     */
    ranges: Array<{
      /**
       * If the value is LESS THAN this number, then we return
       * this value to return. This is checked in order.
       */
      upperRangeToMatch: number;
      /**
       * The score value to return.
       */
      valueToReturn: number;
    }>;
    /**
     * This is the default value to return, if the
     * no value in the `ranges` param is found to match,
     * then we return this number.
     *
     * This usually is the "last" value to return, so it
     * represents the "highest" value.
     *
     * **this is not the "I don't know value", that isn't supported
     * as a parsed value from the lab
     */
    defaultValueToReturn: number;
  }): HraFormCustomValue =>
  (params) => {
    const { userResult, flatUserTestMap, defaultFn } = params;

    if (!userResult || !flatUserTestMap || !flatUserTestMap[code]) {
      return defaultFn(params);
    }
    const { userResultTestResult } = flatUserTestMap[code];

    if (!userResultTestResult) {
      return defaultFn(params);
    }
    const { value } = userResultTestResult;

    if (isNaN(Number(value))) {
      // The value isn't a number;
      return defaultFn(params);
    }

    const match = ranges.find(
      ({ upperRangeToMatch }) => Number(value) <= upperRangeToMatch
    );

    if (match) {
      return match.valueToReturn;
    }

    return defaultValueToReturn;
  };
