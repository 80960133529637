import { LocationData } from '../location-data';
import { HealthProgram, HealthProgramType } from './health-program';

export enum WalkingProgramType {
  STEPS = 'steps',
  DESTINATION = 'destination'
}

/**
 * A health program that tracks walking activity.
 * The goal can be a simple number of steps or the distance in
 * steps from the starting location to the destination location.
 */
export interface WalkingProgram extends HealthProgram {
  /**
   * The method of determining the goal of the program.
   */
  walkingProgramType: WalkingProgramType;
  /**
   * For a steps program, the number of steps required.
   */
  requiredSteps?: number;
  /**
   * For a destination program, the location to reach.
   */
  destinationLocation?: LocationData;
  /**
   * For a destination program, the location to start from.
   */
  startingLocation?: LocationData;
}

export const isWalkingProgram = (
  healthProgram: HealthProgram
): healthProgram is WalkingProgram =>
  healthProgram?.type === HealthProgramType.WALKING;
