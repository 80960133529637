import { ObjectId } from 'mongodb';
import { EventService } from '../event-service/event-service';
import { IncentiveCategory } from './incentive-category';
import { IncentiveTrigger } from '../user-incentive';
import { UserTest } from '../user-test/user-test';
import { AdminUser } from '../admin-user';
import { HealthDataType } from '../user-health-data/user-health-data';

export interface Incentive {
  _id: string | ObjectId;
  eventService: string | ObjectId | EventService;
  category: IncentiveCategory;
  name: string;
  description?: string;
  buttonName?: string;
  triggerType: IncentiveTrigger;
  buttonType: ButtonType;
  createdAt: Date;
  /**  Number of times the incentive can be awarded, defaults to 1 */
  //  *Removing for now as we have the IncentiveFrequency model
  // awardLimit: number;
  // Frequency?

  /**
   * Incentive will have a button show if the `buttonUrl` is valid and it will open
   * a new tab to the URL.
   */
  buttonUrl?: string;
  /** Some incentives could be required to be completed regardless of point value to qualify */
  requiredIncentive?: boolean;
  // Used for filtering order on incentive page. 1 should be highest priority.
  position?: number;
  // Flag to determine if incentive points are enabled if there is improvement from previous year if user did not qualify for full points
  improvementEnabled?: boolean;
  // If improvement is enabled, the percentage of improvement required to earn points
  improvementPercent?: number;
  // Link for non rewarding marquee link
  customButtonUrl?: string;
  // Text to display for marquee button
  customButtonText?: string;
  // Date challenges should start
  challengesStartDate?: string;
  // Date challenges end and should display
  challengesEndDate?: string;
  // Date of last allowed sync/self-report
  lastReportDate?: string;
}

export interface IncentiveWithPoints extends Incentive {
  points: number;
}

// Incentives which can be completed multiple times
export interface IncentiveFrequency extends IncentiveWithPoints {
  /** Maximum number of times the user can trigger **/
  maxAllowed: number;
  /**
   * Delay before incentive can be triggered again.
   */
  frequency?: IncentiveFrequencies;
  /**
   * Hide points earned and awarded style changes until service or custom end date
   */
  withholdPoints?: boolean;
  /**
   * Only award points if all related frequency incentive points total
   * out to max allowed number
   */
  allOrNothing?: boolean;
  /**
   * Display frequency progress in checked off circles or progress bar form. If undefined,
   * do not display either.
   */
  progressDisplay?: FrequencyProgressGraph;
}

// Equals, greater than, less than, etc...
export interface IncentiveTest extends Incentive {
  rule: IncentiveRule;
  onsiteTestId?: string | ObjectId | UserTest;
  offsiteTestId?: string | ObjectId | UserTest;
  providerTestId?: string | ObjectId | UserTest;
}

// Equals, greater than, less than, etc...
export interface IncentiveTestValue extends IncentiveTest, IncentiveWithPoints {
  testValue: number;
}

/**
 * Incentive awarded when the user clicks the URL in the Incentive
 */
export interface IncentiveClickUrl extends IncentiveFrequency {
  url: string;
}

/**
 * Used where the operator is in between. `testValue` is the lower number to check against
 * and testMaxValue is the larger number
 */
export interface IncentiveTestBetween extends IncentiveTestValue {
  /** Higher number in between */
  testMaxValue: number;
}

// Used for reference range
export interface IncentiveTestReferenceRange extends IncentiveTest {
  /** Needed for all except reference ranges
   * Following are point values **/
  inRange: number;
  outOfRange: number;
  highRisk: number;
}
export interface IncentiveTestBloodPressure extends Incentive {
  /** Needed for all except reference ranges
   * Following are point values **/
  inRange: number;
  outOfRange: number;
  highRisk: number;
}

export interface IncentiveService extends Incentive {
  points: number;
}

export interface IncentiveUpload extends IncentiveFrequency {
  /** Whether or not the user has to upload documents for verification */

  /** Max amount of points user can get from this incentive **/
  maxPoints: number;
}

export interface IncentiveActivityTracker extends IncentiveUpload {
  dataType: HealthDataType;
  rewardAt: number;
}

export interface IncentiveAdminValidation extends IncentiveUpload {
  reviewed: boolean;
  reviewedBy?: AdminUser;
}

export enum FrequencyProgressGraph {
  CHECKED_CIRCLES = 'checked_circles',
  PROGRESS_BAR = 'progress_bar',
  NONE = 'none'
}

// Frequencies as parsed from gwapps
export enum IncentiveFrequencies {
  ONCE = 'once',
  ONE_DAY = 'one_day',
  ONE_WEEK = 'one_week',
  TWO_WEEKS = 'two_weeks',
  ONE_MONTH = 'one_month',
  QUARTERLY = 'quarterly'
}

export enum IncentiveMimeType {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  IMAGE_JPG = 'image/jpg',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  PDF = 'image/pdf',
  TIFF = 'tiff',
  APPLICATION_PDF = 'application/pdf'
}

export interface IncentiveFile {
  mimeType: IncentiveMimeType;
  fileId: string;
  fileName: string;
  uploadDate: string | Date;
}

// Frequencies mapped to numeric day values
export const IncentiveFrequenciesMap = {
  [IncentiveFrequencies.ONCE]: 0,
  [IncentiveFrequencies.ONE_DAY]: 1,
  [IncentiveFrequencies.ONE_WEEK]: 7,
  [IncentiveFrequencies.TWO_WEEKS]: 14,
  [IncentiveFrequencies.ONE_MONTH]: 30,
  [IncentiveFrequencies.QUARTERLY]: 120
} as const;

export enum IncentiveRule {
  REFERENCE_RANGES = 'reference_ranges',
  EQUALS = 'equals',
  LESS_THAN = 'less_than',
  LESS_THAN_OR_EQUAL = 'less_than_eq',
  GREATER_THAN = 'greater_than',
  GREATER_THAN_OR_EQUAL = 'greater_than_or_equal',
  BETWEEN = 'between'
}

export enum IncentiveAwardStatus {
  UNEARNED = 'unearned',
  PARTIAL = 'partial',
  FULL = 'full'
}

export enum ButtonType {
  HRA = 'hra',
  UPLOAD = 'upload',
  TEXT_BOX = 'text_box',
  BUTTON_ONLY = 'button_only',
  REGISTER = 'register',
  URL = 'url',
  MARQUEE_COACHING = 'marquee_coaching',
  FAX = 'fax',
  // Do not show the button if None
  NONE = 'none'
}

/** Type Guards */
/**
 * Type-guard that returns if service is a value ruled service
 */
export const isIncentiveValue = (
  incentive: Incentive
): incentive is IncentiveTestValue =>
  incentive?.triggerType === IncentiveTrigger.LAB_TEST_VALUE ||
  incentive?.triggerType === IncentiveTrigger.IS_SMOKER;
/**
 * Type-guard that returns if incentive is a between ruled incentive
 */
export const isIncentiveBetween = (
  incentive: Incentive
): incentive is IncentiveTestBetween =>
  incentive?.triggerType === IncentiveTrigger.LAB_TEST_BETWEEN;
/**
 * Type-guard that returns if incentive is a reference range ruled incentive
 */
export const isIncentiveReferenceRange = (
  incentive: Incentive
): incentive is IncentiveTestReferenceRange =>
  incentive?.triggerType === IncentiveTrigger.LAB_TEST_RANGE;
/**
 * Type-guard that returns if incentive is a reference range ruled incentive with a trigger type of blood pressure
 */
export const isIncentiveBloodPressure = (
  incentive: Incentive
): incentive is IncentiveTestBloodPressure =>
  incentive?.triggerType === IncentiveTrigger.BLOOD_PRESSURE;
/**
 * Type-guard that returns if incentive is an incentive service
 */
export const isIncentiveService = (
  incentive: Incentive
): incentive is IncentiveService =>
  !!incentive &&
  typeof incentive === 'object' &&
  [
    IncentiveTrigger.COMPLETED_HRA,
    IncentiveTrigger.COMPLETED_FLU,
    IncentiveTrigger.COMPLETED_LAB,
    IncentiveTrigger.FAX_RESULTS
  ].includes(incentive.triggerType);

// ToDo: find all instances where I check each test individually and replace with this function
export const isIncentiveTest = (
  incentive: Incentive
): incentive is IncentiveTest =>
  !!incentive &&
  typeof incentive === 'object' &&
  [
    IncentiveTrigger.LAB_TEST_VALUE,
    IncentiveTrigger.LAB_TEST_BETWEEN,
    IncentiveTrigger.LAB_TEST_RANGE,
    IncentiveTrigger.IS_SMOKER,
    IncentiveTrigger.BLOOD_PRESSURE
  ].includes(incentive.triggerType);

export const isIncentiveClickUrl = (
  incentive: Incentive
): incentive is IncentiveClickUrl =>
  incentive?.triggerType === IncentiveTrigger.CLICK_LINK ||
  incentive?.triggerType === IncentiveTrigger.MARQUEE_COACHING ||
  incentive?.triggerType === IncentiveTrigger.MARQUEE_CHALLENGES;

// ToDo: change the name of this function, as these can be text-box or upload
export const isIncentiveUpload = (
  incentive: Incentive
): incentive is IncentiveAdminValidation =>
  incentive?.triggerType === IncentiveTrigger.COMPLETED_FLU ||
  incentive?.triggerType === IncentiveTrigger.SELF_REPORTED ||
  incentive?.triggerType === IncentiveTrigger.ADMIN_VALIDATE ||
  incentive?.triggerType === IncentiveTrigger.ACTIVITY_TRACKER;

export const isIncentiveAdminValidation = (
  incentive: Incentive
): incentive is IncentiveAdminValidation =>
  incentive?.triggerType === IncentiveTrigger.ADMIN_VALIDATE;

export const isIncentiveFrequency = (
  incentive: Incentive
): incentive is IncentiveFrequency =>
  !!incentive &&
  typeof incentive === 'object' &&
  (incentive as IncentiveFrequency).frequency !== undefined &&
  (incentive as IncentiveFrequency).maxAllowed !== undefined;

export const isIncentiveActivityTracker = (
  incentive: Incentive
): incentive is IncentiveActivityTracker =>
  incentive?.triggerType === IncentiveTrigger.ACTIVITY_TRACKER;

export const isIncentiveWithPoints = (
  incentive: Incentive
): incentive is IncentiveWithPoints =>
  !!incentive &&
  typeof incentive === 'object' &&
  (incentive as IncentiveWithPoints).points !== undefined;
