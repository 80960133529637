import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BirthDayValidatorsModule } from '../../shared/birth-day-validators/birth-day-validators.module';
import { EhsUserUniqueFormComponent } from './ehs-user-unique-form.component';

@NgModule({
  declarations: [EhsUserUniqueFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    // App Modules
    BirthDayValidatorsModule,
    // Angular Material
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule
  ],
  exports: [EhsUserUniqueFormComponent]
})
export class EhsUserUniqueFormModule {}
