import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdminListUserRegistrationsParams,
  CommonResponse,
  EventService,
  GcfLabReqRequest,
  GcfRegExportRequest,
  getId,
  isAdminListUserRegistrations,
  ProviderHealthUploadForm,
  ProviderHealthUploadStatus,
  ProviderHealthUserRegistration,
  UserRegistration,
  UserRegistrationId,
  UserRegistrationWithResultFlag,
  UserRequisition,
  OnsiteUserRegistration,
  Company
} from '@common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminUserRegistrationHttpService {
  private baseUrl = 'api/v1/admin';
  private clientAdminUrl = 'api/v1/client';

  constructor(private http: HttpClient) {}
  /**
   * Lists the given user-registrations,
   * this is the "main" http request endpoint and method.
   */
  public listUserRegistrations(
    params: AdminListUserRegistrationsParams,
    clientAdminParams?: { clientAdmin: boolean }
  ): Observable<UserRegistration[]> {
    const {
      serviceType,
      includeReq,
      includeResult,
      includeUser,
      hasResult,
      excludePdfs
    } = params;
    let httpParams = new HttpParams();

    if (isAdminListUserRegistrations.byUser(params)) {
      httpParams = httpParams.append('user', params.user);
    }

    if (isAdminListUserRegistrations.byEvent(params)) {
      httpParams = httpParams.append('eventLocation', params.eventLocation);

      if (params.includeEvent) {
        httpParams = httpParams.append('includeEvent', 'true');
      }

      if (params.includeReleasedBy) {
        httpParams = httpParams.append('includeReleasedBy', 'true');
      }

      if (params.includeReviewedBy) {
        httpParams = httpParams.append('includeReviewedBy', 'true');
      }
    }

    if (isAdminListUserRegistrations.byService(params)) {
      httpParams = httpParams.append('eventService', params.eventService);
    }

    if (serviceType) {
      httpParams = httpParams.append('serviceType', serviceType);
    }

    if (includeReq) {
      httpParams = httpParams.append('includeReq', 'true');
    }

    if (includeResult) {
      httpParams = httpParams.append('includeResult', 'true');
    }

    if (includeUser) {
      httpParams = httpParams.append('includeUser', 'true');
    }

    if (hasResult !== undefined) {
      httpParams = httpParams.append('hasResult', '' + hasResult);
    }

    if (excludePdfs) {
      httpParams = httpParams.append('excludePdfs', 'true');
    }

    return this.http.get<UserRegistration[]>(
      `${
        clientAdminParams?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/registration`,
      {
        params: httpParams
      }
    );
  }

  /**
   * Returns registrations for the given event-location
   * TODO: merge with list-user-registration??
   * @deprecated
   */
  public listUserRegistrationsByEvent(params: {
    eventLocation: string;
    includeReq?: boolean;
  }): Observable<UserRegistrationWithResultFlag[]> {
    const { eventLocation, includeReq } = params;

    return this.http.get<UserRegistrationWithResultFlag[]>(
      'api/v1/admin/event/registration',
      {
        params: new HttpParams()
          .append('eventLocation', '' + eventLocation)
          .append('includeResult', 'true')
          .append('includeReq', '' + !!includeReq)
      }
    );
  }

  /**
   * Returns registrations for the given company
   * This is used for the client admin user table
   */
  public listByCompany(params: {
    company: Company | string;
  }): Observable<OnsiteUserRegistration[]> {
    const { company } = params;

    console.log('list by company');

    return this.http.get<OnsiteUserRegistration[]>(
      'api/v1/client/registration',
      {
        params: new HttpParams().append('company', '' + getId(company))
      }
    );
  }

  public listByFilter(params: ProviderHealthUploadForm) {
    return this.http.get<{
      healthProviderUploads: ProviderHealthUserRegistration[];
      hasNext: boolean;
    }>('api/v1/admin/registration/provider-health/filter', {
      params: Object.entries(params).reduce(
        (_params, [key, value]) =>
          value ? _params.append(key, '' + value) : _params,
        new HttpParams()
      )
    });
  }

  public updateProviderHealthUserRegistrationUploadStatus(params: {
    status: ProviderHealthUploadStatus;
    userRegistration: string | UserRegistration;
  }): Observable<UserRegistration> {
    const { userRegistration, status } = params;

    return this.http.put<UserRegistration>(
      `api/v1/admin/registration/upload-status/${getId(userRegistration)}`,
      { status }
    );
  }

  public markNoShow(params: { userRegistrations: OnsiteUserRegistration[] }) {
    const { userRegistrations } = params;

    return this.http.put(`api/v1/admin/registration/mark-no-show`, {
      userRegistrations: (userRegistrations || []).map((userRegistration) =>
        getId(userRegistration)
      )
    });
  }

  public removeVaccination(params: {
    userRegistrations: OnsiteUserRegistration[];
  }) {
    const { userRegistrations } = params;

    return this.http.put(`api/v1/admin/registration/remove-vaccination`, {
      userRegistrations: (userRegistrations || []).map((userRegistration) =>
        getId(userRegistration)
      )
    });
  }

  public markCompleted(params: {
    userRegistrations: OnsiteUserRegistration[];
  }) {
    const { userRegistrations } = params;

    return this.http.put(`api/v1/admin/registration/mark-completed`, {
      userRegistrations: (userRegistrations || []).map((userRegistration) =>
        getId(userRegistration)
      )
    });
  }

  /**
   * Updates the given user-registration with the following optional-tests
   */
  public updateOptionalTests(params: {
    id: string;
    optionalTests: Array<string>;
  }): Observable<UserRegistration> {
    const { id, optionalTests } = params;

    return this.http.put<UserRegistration>(`api/v1/admin/registration/${id}`, {
      optionalTests
    });
  }

  /**
   * Updates the given user-registration with the following additional-tests
   */
  public updateAdditionalTests(params: {
    id: string;
    additionalTests: Array<string>;
  }): Observable<UserRegistration> {
    const { id, additionalTests } = params;

    return this.http.put<UserRegistration>(`api/v1/admin/registration/${id}`, {
      additionalTests
    });
  }

  /**
   * Updates the given user-registration with the following service types
   */
  public updateServiceTypes(params: {
    userRegistrationId: string;
    serviceTypes: Array<string>;
  }): Observable<UserRegistration> {
    const { userRegistrationId, serviceTypes } = params;

    return this.http.put<UserRegistration>(
      `api/v1/admin/registration/serviceTypes/${userRegistrationId}`,
      {
        serviceTypes
      }
    );
  }

  public updateRegistrationDetails(params: {
    userRegistration: Partial<UserRegistration>;
  }) {
    const { userRegistration } = params;

    const userRegistrationId =
      getId(userRegistration as UserRegistration) || '';

    return this.http.put<UserRegistration>(
      `api/v1/admin/registration/edit/${userRegistrationId}`,
      {
        userRegistration
      }
    );
  }

  /**
   * Updates the user-registration with an admin's notes related to a user's health provider form upload
   */
  public updateNotes(params: {
    userRegistration: ProviderHealthUserRegistration;
  }): Observable<UserRegistration> {
    const { userRegistration } = params;

    return this.http.put<UserRegistration>(
      `api/v1/admin/registration/hpf-notes/${getId(userRegistration)}`,
      {
        userRegistration
      }
    );
  }

  /**
   * Updates the given onsite user-registration with the new eventLocation/time/date
   */
  public changeOnsiteEventDate(params: {
    userRegistration: Partial<OnsiteUserRegistration>;
  }): Observable<UserRegistration> {
    const { userRegistration } = params;

    return this.http.put<UserRegistration>(
      `api/v1/admin/registration/reschedule`,
      {
        userRegistration
      }
    );
  }

  /**
   * Export registrations for the given company
   */
  public exportRegs(
    params: Omit<GcfRegExportRequest, 'email'>,
    clientAdminParams?: { clientAdmin: boolean }
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${
        clientAdminParams?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/company/reg/export`,
      params
    );
  }

  /**
   * Export registrations for the given company as CSV for Client Admin
   */
  public exportRegistrationsToCsv(params: {
    company: string;
    eventLocation?: string;
  }) {
    const options = {
      responseType: 'text' as any
    };

    return this.http.post<ArrayBuffer>(
      `${this.clientAdminUrl}/csv/export`,
      params,
      options
    );
  }

  /**
   * Returns the user registration count for the given event-services
   */
  public getUserRegCount(params: {
    eventServices: Array<EventService | string>;
  }): Observable<{ count: number }> {
    const { eventServices } = params;

    return this.http.get<{ count: number }>('api/v1/admin/company/reg/count', {
      params: eventServices.reduce(
        (acc, eventService) => acc.append('eventId', getId(eventService)),
        new HttpParams()
      )
    });
  }

  /**
   * Returns the registration ids for the given user-registration
   */
  public getUserRegistrationById(params: {
    userRegistration: string;
    includeReq?: boolean;
  }): Observable<UserRegistration> {
    const { userRegistration, includeReq } = params;
    let httpParams = new HttpParams();

    if (includeReq) {
      httpParams = httpParams.append('includeReq', 'true');
    }

    return this.http.get<UserRegistration>(
      `api/v1/admin/registration/${userRegistration}`,
      {
        params: httpParams
      }
    );
  }

  /**
   * Returns the user's requisition for the given user-registration.
   * Will return an error if there isn't one.
   */
  public getUserReq(id: UserRegistrationId): Observable<UserRequisition> {
    return this.http.get<UserRequisition>(
      `api/v1/admin/registration/${id}/req`
    );
  }

  /**
   * Returns the users requisition for the given user-registrations.
   */
  public getUserReqs(
    userRegistrations: UserRegistrationId[]
  ): Observable<UserRequisition[]> {
    return this.http.get<UserRequisition[]>(
      `api/v1/admin/registration/req/list`,
      {
        params: userRegistrations.reduce(
          (params, userRegistration) => params.append('id', userRegistration),
          new HttpParams()
        )
      }
    );
  }

  /**
   * Http endpoint for user-reqs to export the user-req's pdfs (the lab-pdfs)
   * to drive.
   */
  public generateAndExportLabReqToDrive(
    request: GcfLabReqRequest
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      'api/v1/admin/registration/req/export',
      request
    );
  }

  /**
   * Releases multiple user-registrations to labs, could be onsite or offsite
   * due to changes with #2876.
   *
   * If any provider-health user-registrations are given, this errors out.
   *
   * **note** the route returns more information than just user-registrations,
   * but we will only use the user-registrations for now.
   */
  public releaseUserRegistrations(params: {
    ids: UserRegistrationId[];
  }): Observable<UserRegistration[]> {
    return this.http
      .put<{
        userRegistrations: UserRegistration[];
      }>('api/v1/admin/registration/release', {
        ids: params.ids
      })
      .pipe(map(({ userRegistrations }) => userRegistrations));
  }

  /**
   * Send Orders to Lifepoint for multiple user-registrations, either onsite or offsite.
   * This endpoint allows us to pass either the selected user-registrations or the event-location
   * to send orders for the whole event. Backend will double check for validation.
   * If any provider-health user-registrations are given, this errors out.
   */
  public sendOrdersToLab(params: {
    ids?: UserRegistrationId[];
    eventLocation?: string;
    admin?: string;
    resend?: boolean;
  }): Observable<UserRegistration[]> {
    return this.http
      .put<{
        userRegistrations: UserRegistration[];
      }>('api/v1/admin/registration/order', {
        ids: params.ids,
        eventLocation: params.eventLocation,
        admin: params.admin,
        resend: params.resend
      })
      .pipe(map(({ userRegistrations }) => userRegistrations));
  }

  public createWalkinRegistration(params: {
    userRegistration: Partial<UserRegistration>;
    clientAdmin?: boolean;
  }): Observable<UserRegistration> {
    const { userRegistration, clientAdmin } = params;

    return this.http.post<UserRegistration>(
      `${
        clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/registration/walkin/create`,
      userRegistration
    );
  }

  public removeRegistration(params: {
    id: UserRegistrationId;
    clientAdmin: boolean;
  }): Observable<UserRegistration> {
    const { id, clientAdmin } = params;

    return this.http.delete<UserRegistration>(
      `${clientAdmin ? this.clientAdminUrl : this.baseUrl}/registration/${id}`
    );
  }

  /**
   * Returns a signed url to download a provider user registration result file
   */
  public getProviderDownloadSignedUrl(params: {
    /**
     * The id of teh corresponding user-registration
     */
    id: string;
    /** The id generated for the file in google storage */
    fileId: string;
  }): Observable<{ url: string }> {
    const { id, fileId } = params;

    return this.http.get<{ url: string }>(
      `api/v1/admin/registration/${id}/download/url/${fileId}`
    );
  }

  /**
   * Returns a signed url to download a Lifepoint Lab Requisition PDF
   */
  public getLabRequisitionPdfSignedUrl(params: {
    /**
     * The id of the corresponding user-registration
     */
    id: string;
  }): Observable<{ url?: string }> {
    const { id } = params;

    return this.http.get<{ url: string }>(
      `api/v1/admin/registration/${id}/download/requisition`
    );
  }

  /**
   * Send Lifepoint Lab Requisition Fax
   */
  public sendRequisitionFax(params: { id: UserRegistrationId; phone: string }) {
    const { id, phone } = params;

    return this.http.post<CommonResponse>(
      `api/v1/admin/registration/${id}/fax/requisition`,
      { phone }
    );
  }
}
