import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsPasswordResetDialogData } from './ehs-password-reset-dialog-data';
import { EhsPasswordResetDialogComponent } from './ehs-password-reset-dialog.component';

@Injectable()
export class EhsPasswordResetDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: EhsPasswordResetDialogData): Observable<string | false> {
    return this.dialog
      .open(EhsPasswordResetDialogComponent, {
        data,
        width: '450px'
      })
      .afterClosed();
  }
}
