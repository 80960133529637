import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EventServiceId,
  User,
  UserIncentive,
  UserIncentiveForm,
  getId,
  Incentive,
  EventService,
  GcfIncentiveExportRequest,
  CommonResponse,
  IncentiveFile,
  IncentivePoints
} from '@common';
import { ObjectId } from 'mongodb';
import { Observable } from 'rxjs';
import { QueryListService } from '../../util/query-list.service';

const BASE_URL = '/api/v1/user-incentives';
const ADMIN_URL = '/api/v1/admin/user-incentives';

@Injectable({
  providedIn: 'root'
})
export class UserIncentiveHttpService {
  constructor(private http: HttpClient, private queryList: QueryListService) {}

  public list(): Observable<UserIncentive[]> {
    return this.http.get<UserIncentive[]>(BASE_URL);
  }

  public get(id: string) {
    return this.http.get<UserIncentive>(`${BASE_URL}/${id}`);
  }

  public listIncentives(eventService: EventService) {
    return this.http.get<Incentive[]>(
      `${BASE_URL}/incentives/${getId(eventService)}`
    );
  }

  // Remove IncentivePoints object from DB and array of incentive points on passed user-incentive
  public removePoints(params: {
    userIncentiveId: string;
    incentivePointsId: string;
  }) {
    return this.http.put<UserIncentive>(
      `${BASE_URL}/remove-points/${params.userIncentiveId}`,
      params
    );
  }

  /** Updates a userIncentive to add uploads, but changes nothing else */
  public update(params: {
    userIncentiveId: string;
    incentiveId: string;
    file?: IncentiveFile;
    // For text-box incentives
    textValue?: string;
    completedDate?: string;
  }) {
    return this.http.put<UserIncentive>(BASE_URL, params);
  }

  /**
   * Creates a userIncentive for the given event service
   */
  public create(params: { eventServiceId: string }) {
    return this.http.post<UserIncentive>(BASE_URL, params);
  }

  /**
   * Returns a signed url to upload for self-reported or admin-validated incentives.
   */
  public getIncentiveUploadSignedUrl(params: {
    /**
     * The id of the corresponding user-incentive
     */
    id: string;
    /** If there is no id, there is no existing user-incentive, we need to create one, which requires the eventService */
    eventService?: string;
    /**
     * The type of the file we will upload
     */
    contentType: string;
  }): Observable<{ url: string; fileId: string }> {
    const { id, contentType, eventService } = params;

    const httpParams = new HttpParams().set('contentType', contentType);

    if (!id && eventService) {
      httpParams.set('eventService', eventService);
    }

    return this.http.get<{ url: string; fileId: string }>(
      `${BASE_URL}/upload/url/${id || 404}`,
      {
        params: httpParams
      }
    );
  }

  /**
   * Returns a signed url to download an incentive upload file
   */
  public getIncentiveDownloadSignedUrl(params: {
    /**
     * The id of the corresponding user-incentive
     */
    id: string;
    /** The id generated for the file in google storage */
    fileId: string;
  }): Observable<{ url: string }> {
    const { id, fileId } = params;

    return this.http.get<{ url: string }>(
      `${BASE_URL}/${id}/download/url/${fileId}`
    );
  }

  /**
   * These endpoints are only available to admins
   */
  public adminList(params: UserIncentiveForm) {
    return this.http.get<{ userIncentives: UserIncentive[]; hasNext: boolean }>(
      `${ADMIN_URL}/list`,
      {
        params: Object.entries(params).reduce(
          (_params, [key, value]) =>
            value ? _params.append(key, '' + value) : _params,
          new HttpParams()
        )
      }
    );
  }

  public adminGet(id: string) {
    return this.http.get<{
      userIncentive: UserIncentive;
      incentives: Incentive[];
    }>(`${ADMIN_URL}/${id}`);
  }

  public adminCreate(params: {
    eventServiceId: EventServiceId;
    userId: User | ObjectId | string;
  }) {
    return this.http.post<UserIncentive>(ADMIN_URL, params);
  }

  public adminUpdate(userIncentive: UserIncentive) {
    return this.http.put<UserIncentive>(ADMIN_URL, userIncentive);
  }

  public adminReject(params: {
    userIncentiveId: string;
    incentivePoints: IncentivePoints;
  }) {
    const { userIncentiveId, incentivePoints } = params;
    const incentivePointsId = getId(incentivePoints);

    return this.http.put<UserIncentive>(`${ADMIN_URL}/reject`, {
      incentivePointsId,
      userIncentiveId
    });
  }

  public adminDelete(userIncentive: UserIncentive) {
    const userIncentiveId = getId(userIncentive);

    return this.http.delete<UserIncentive>(`${ADMIN_URL}/${userIncentiveId}`);
  }

  /**
   * Export incentives for the given company/event-service
   */
  public exportIncentives(
    params: Omit<GcfIncentiveExportRequest, 'email'>
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      'api/v1/admin/incentives/export',
      params
    );
  }
}
