import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '../../pipes/pipes.module';
import { DateValidatorModule } from '../../shared/date-validator/date-validator.module';
import { EhsAdminNotesComponent } from './ehs-admin-notes.component';

@NgModule({
  declarations: [EhsAdminNotesComponent],
  imports: [
    CommonModule,
    FormsModule,
    // App modules
    DateValidatorModule,
    // Angular Material
    FlexLayoutModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatOptionModule,
    MatSelectModule,
    PipesModule
  ],
  exports: [EhsAdminNotesComponent]
})
export class EhsAdminNotesModule {}
