import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonResponse, GcfPdfExportRequest } from '@common';

@Injectable({
  providedIn: 'root'
})
export class AdminExportPdfHttpService {
  constructor(private http: HttpClient) {}

  public export(request: GcfPdfExportRequest) {
    return this.http.post<CommonResponse>('api/v1/admin/pdf/export', request);
  }
}
