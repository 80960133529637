import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  AdminUser,
  BaseSiteUserRegistration,
  EventService,
  EventServiceUtil,
  getId,
  isOffsiteUserRegistration,
  isOnsiteUserRegistration,
  isProviderHealthUserRegistration,
  OffsiteService,
  OffsiteUserRegistration,
  OnsiteService,
  OnsiteUserRegistration,
  ProviderHealthUserRegistration,
  Rack,
  UserVaccinationRequestForm,
  User,
  UserRegistration,
  UserRequisition,
  UserResult,
  UserVaccination,
  EventLocation,
  UserTestUtil
} from '@common';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import {
  EhsRegTableColumn,
  EhsRegTableColumnAction,
  EhsRegTableColumnActionDef,
  EhsRegTableColumnProp,
  isColumnAction,
  isColumnProp
} from './ehs-reg-table-column';
import {
  AdminEventRegStatusDef,
  adminEventRegStatusDefMap,
  getRegistrationStatus
} from './ehs-reg-table-status-col';
import { OnsiteEhsRegFilters } from '../ehs-reg-filters/ehs-reg-filters';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ehs-reg-table',
  templateUrl: './ehs-reg-table.component.html',
  styleUrls: ['./ehs-reg-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsRegTableComponent<
  AbstractUserRegistration extends UserRegistration = UserRegistration,
  ActionTypes extends string = string
> {
  [x: string]: any;
  /**
   * The list of user-registrations we are to display via infinite scroll.
   */
  @Input() userRegistrations: UserRegistration[] = [];

  /**
   * Map of users, where the key is the user id.
   */
  @Input() users: Record<string, User> = {};

  /**
   * Map of user-results, where the key is the userResult's registrationId.
   */
  @Input() userResultsByRegId: Record<string, UserResult> = {};

  /**
   * Map of user-reqs where the key is the user-registration id.
   */
  @Input() userReqsByRegId: Record<string, UserRequisition> = {};

  /**
   * Map of admin-users, where the key is the _id. Used for "released by" lookups.
   *
   * releasedBy is only shown for onsites, so this only needs to be provided
   * in that scenario
   */
  @Input() adminUsers: Record<string, AdminUser> = {};

  /**
   * The company-id, used for routing
   */
  @Input() company?: string;

  /**
   * The user Vaccination record if already created
   */
  @Input() userVaccsByRegId: Record<string, UserVaccination> = {};

  /**
   * The company-id, used for routing
   */
  @Input() onsiteFilters?: OnsiteEhsRegFilters;

  @Input() changeLot?: string;

  @Input() eventLocation?: Partial<EventLocation>;

  /**
   * The racks, used for rack number admin name display
   * Need to include the undefined and null types to get around type error when passing from company-event
   */
  @Input() racks?: Rack[] | undefined | null;

  /**
   * The event service
   */
  @Input() eventService?: EventService;

  /**
   * If we are to display the checkbox in its "indeterminate" state
   */
  @Input() indeterminate?: boolean;

  /**
   * If the all are toggled
   */
  @Input() allAreSelected?: boolean;

  /** If the reg-table is being used by client-admins, we want to limit some actions */
  @Input() clientAdmin?: boolean;

  /**
   * The list of columns we are to display.
   * Usually generated from the ehs-reg-table.service.
   *
   * The same ones should be passed to the ehs-row-header component, which
   * will be used to "give context" to the list of rows.
   */
  @Input() columns: EhsRegTableColumn<AbstractUserRegistration>[] = [];

  /**
   * The pixel size of each element. This is directly passed to the infinite
   * scroll component.
   * Defaults to 50 as an example
   *
   * For more information see:
   * https://material.angular.io/cdk/scrolling/overview
   */
  @Input() itemSize?: number = 50;

  /**
   * If we are to disable all actions within the table.
   */
  @Input() disabled?: boolean;

  /**
   * Array of selected user-registration-ids. Should be handled via
   * a parent ngrx
   *
   * **note** this might be migrated to be an internal SelectionModel state
   * to make things faster/easier.
   */
  @Input() selected: string[] = [];

  @Input() formData?: Partial<UserVaccinationRequestForm>;

  @Output() formDataChange = new EventEmitter<
    Partial<UserVaccinationRequestForm> | undefined
  >();

  public formDataChangeMetered$ = new Subject<
    Partial<UserVaccinationRequestForm> | undefined
  >();

  @Input() submitState?: SubmitEvent | null;

  /**
   * Function that can be passed to override the default router-link calculation,
   * which is provided for each user-registration within the table.
   *
   * **note** the default isn't setup, which will make the routerLink go nowhere.
   */
  @Input() routerLinkFn: (
    userRegistration: AbstractUserRegistration
  ) => string[];

  /**
   * Event emitted if we are toggling the top-level select-all
   */
  @Output() toggleAll = new EventEmitter();

  /**
   * Event emitted when the checkbox is selected/de-selected for a given user-registration.
   */
  @Output() toggle = new EventEmitter<AbstractUserRegistration>();

  @Input() userVaccination: UserVaccination | null = null;

  @Output() userVaccinationChange = new EventEmitter<
    Partial<UserVaccination>
  >();

  @Output() actionChange = new EventEmitter<{
    /**
     * The action type emitted from the row.
     */
    actionType: ActionTypes;
    /**
     * The user registration that was affected
     */
    userRegistration: AbstractUserRegistration;
  }>();

  @Output() refresh = new EventEmitter();

  @Output() fluLotNumber = new EventEmitter();

  @Output() fluName = new EventEmitter();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  /**
   * To keep the header positioning:sticky inside cdk viewport
   */
  get headerTop() {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }

    const offset = this.viewPort['_renderedContentOffset'];

    return `-${offset}px`;
  }

  private takeUntil = new Subject<void>();
  constructor(private snackBar: MatSnackBar) {
    this.formDataChangeMetered$
      .pipe(debounceTime(50), takeUntil(this.takeUntil))
      .subscribe((formData) => this.formDataChange.emit(formData));
  }

  /**
   * Returns only column props
   */
  public get propColumns(): EhsRegTableColumnProp[] {
    if (this.clientAdmin) {
      this.columns = this.columns.filter(
        (column) => column.prop !== 'checkbox'
      );
    }

    return this.columns.filter(isColumnProp);
  }

  /**
   * Returns the actions column, if it exists.
   */
  public get actionColumn(): EhsRegTableColumnAction | undefined {
    return this.columns.find(isColumnAction);
  }

  /**
   * The calculated actions for this row.
   * Leverages the columns, and the this user-registration to display
   * the correct actions
   */
  public getActions(
    userRegistration: AbstractUserRegistration
  ): EhsRegTableColumnActionDef[] {
    return (
      this.actionColumn?.actions({
        company: getId(userRegistration.company) || '',
        userRegistration,
        userResult: this.getUserResult(userRegistration),
        userVaccination: this.getUserVaccination(userRegistration)
      }) || []
    );
  }

  // This works the same as the action version, but we're adding this to streamline the results editing for the registration for data entry
  public getEditResultsLink(userRegistration: UserRegistration) {
    if (isOnsiteUserRegistration(userRegistration)) {
      return [
        '/ehs/admin/company',
        getId(userRegistration.company),
        'service',
        getId(userRegistration.eventService),
        'event',
        getId(userRegistration.eventLocation),
        'results'
      ];
    }

    if (isOffsiteUserRegistration(userRegistration)) {
      //  ? Not sure if this is viable
      const userResult = this.getUserResult(
        userRegistration as AbstractUserRegistration
      );

      return [
        '/ehs/admin/company',
        getId(userRegistration.company),
        'user',
        getId(userRegistration.user),
        'result',
        'correction',
        getId(userResult)
      ];
    }

    if (isProviderHealthUserRegistration(userRegistration)) {
      return [
        '/ehs/admin/company',
        getId(userRegistration.company),
        'service',
        getId(userRegistration.eventService),
        'provider',
        'form'
      ];
    }

    return undefined;
  }

  // This works the same as the action version, but we're adding this to streamline the results editing for the registration for data entry
  getEditResultsLinkParams(userRegistration: UserRegistration) {
    if (isOnsiteUserRegistration(userRegistration)) {
      return {
        userRegistration: getId(userRegistration)
      };
    }

    if (isOffsiteUserRegistration(userRegistration)) {
      return { redirect: 'offsite' };
    }

    if (isProviderHealthUserRegistration(userRegistration)) {
      return {
        userRegistration: getId(userRegistration),
        future: true
      };
    }

    return undefined;
  }

  /**
   * If the user-registration we are display is a provider-health-user-registration,
   * otherwise we return undefined
   */
  public getProviderUserRegistration(
    reg: AbstractUserRegistration
  ): ProviderHealthUserRegistration | undefined {
    return isProviderHealthUserRegistration(reg) ? reg : undefined;
  }

  /**
   * The link that redirects to the user-registration page, which **isn't**
   * supported.
   *
   * Instead, this can be overridden by a "router-link-fn" input,
   * which will be used to determine the route to redirect
   * the admin to when the click on the row.
   */
  public getRouterLink(userRegistration: AbstractUserRegistration): string[] {
    return this.routerLinkFn && typeof this.routerLinkFn === 'function'
      ? this.routerLinkFn(userRegistration)
      : [];
  }

  /**
   * The query-params that redirect to the user-registration page.
   * We always provide the same query-params automatically **if**
   * the `routerLinkFn` is passed for backwards compat.
   *
   * Later we can remove the company, and event-service once the legacy
   * "offsite" page isn't used anymore
   *
   */
  public getQueryParams(
    userRegistration: AbstractUserRegistration
  ): Record<string, string> {
    return {
      company: getId(this.company),
      eventService: getId(userRegistration.eventService),
      userRegistration: getId(userRegistration),
      // **Note** this is used to change the "back"
      // behavior on the actual form to return to
      // this page, rather than the legacy offsite one.
      future: 'true'
    };
  }

  public getUser(userRegistration: AbstractUserRegistration): User | undefined {
    return this.users[getId(userRegistration.user)];
  }

  public getUserResult(userRegistration: AbstractUserRegistration) {
    return this.userResultsByRegId[getId(userRegistration)];
  }

  public getFailedTest(userRegistration: AbstractUserRegistration): boolean {
    return !!this.userResultsByRegId[getId(userRegistration)]?.tests.find(
      (userResultsTests) =>
        !!(userResultsTests.results || []).find((result) =>
          UserTestUtil.isNoResult({ userResultTestResult: result })
        )
    );
  }

  public getUserReq(userRegistration: AbstractUserRegistration) {
    return this.userReqsByRegId[getId(userRegistration)];
  }

  public isSelected(userRegistration: AbstractUserRegistration): boolean {
    return this.selected.includes(getId(userRegistration));
  }

  /**
   * If the user-registration we are to display is an onsite-user-registration,
   * otherwise we return undefined.
   *
   * Primarily for type-checks in the template
   */
  public getOnsiteUserRegistration(
    reg: AbstractUserRegistration
  ): OnsiteUserRegistration | undefined {
    return isOnsiteUserRegistration(reg) ? reg : undefined;
  }

  public getGroupType(reg: AbstractUserRegistration): string {
    const { groupType, serviceType } = (reg as BaseSiteUserRegistration) || {};

    if (typeof groupType !== 'number' || !serviceType) {
      return 'None';
    }

    const service = EventServiceUtil.getSelectedService({
      serviceType,
      eventService: this.eventService
    }) as OnsiteService | OffsiteService;

    return (service?.groupTypes || [])[groupType]?.name || 'None';
  }

  // Initialize to collapsed. Will limit group type text to 1 line if longer text
  public collapsed = true;

  /**
   * Toggle state of group type text
   **/
  public collapse(event: MouseEvent) {
    event.stopPropagation();

    this.collapsed = !this.collapsed;
  }

  /**
   * Get the Registration Status Icon & Title
   */
  public getRegistrationStatus(
    userRegistration: UserRegistration
  ): AdminEventRegStatusDef {
    return adminEventRegStatusDefMap[
      getRegistrationStatus({ userRegistration }) || ''
    ];
  }

  /**
   * If the user-registration we are to display is an offsite-user-registration,
   * otherwise we return undefined.
   *
   * Primarily for type-checks in the template
   */
  public getOffsiteUserRegistration(
    reg: AbstractUserRegistration
  ): OffsiteUserRegistration | undefined {
    return isOffsiteUserRegistration(reg) ? reg : undefined;
  }

  /**
   * Returns a "base-type" of "site" user-registrations,
   * useful if a column can be used for both onsite and offsite.
   */
  public getSiteUserRegistration(
    reg: AbstractUserRegistration
  ): BaseSiteUserRegistration {
    return (
      this.getOnsiteUserRegistration(reg) ||
      this.getOffsiteUserRegistration(reg)
    );
  }

  /**
   * Returns the user who released this user-registration, or if not matched/found/released
   * returns undefined
   */
  public getReleasedBy(reg: AbstractUserRegistration): AdminUser | undefined {
    if (!isOnsiteUserRegistration(reg) || !this.adminUsers || !reg.releasedBy) {
      return undefined;
    }

    return this.adminUsers[getId(reg.releasedBy)];
  }

  public trackByFn(index: number) {
    return index;
  }

  getRackEmail(rackId: string | undefined) {
    if (!rackId || !this.racks?.length) {
      return '';
    }

    return (
      this.racks.find((rack) => getId(rack) === getId(rackId || ''))
        ?.adminEmail || ''
    );
  }

  public handleRefresh() {
    this.refresh.emit();
  }

  public getUserVaccination(userRegistration: AbstractUserRegistration) {
    return this.userVaccsByRegId[getId(userRegistration)];
  }

  public getsUserResult(userRegistration: AbstractUserRegistration) {
    return this.userResultsByRegId[getId(userRegistration)];
  }

  onChange(params: {
    change: Partial<UserVaccination>;
    userRegistration: UserRegistration;
    eventService: EventService;
    eventLocation?: Partial<EventLocation>;
  }) {
    const { change, userRegistration, eventService, eventLocation } = params;

    if (
      !eventLocation.fluLot ||
      !eventLocation.fluVacExpDate ||
      !eventLocation.fluVacName
    ) {
      this.snackBar.open(
        'Oops! Make sure all vaccination info is entered in GWapps',
        'Ok'
      );

      return undefined;
    }

    if (change && userRegistration && eventService && eventLocation) {
      const vaccinationArm = {
        vaccinationArm: change,
        userRegistration,
        eventService,
        eventLocation: this.eventLocation,
        user: getId(userRegistration.user),
        company: getId(userRegistration.company),
        vaccinationExpDate: eventLocation.fluVacExpDate,
        vaccinationName: eventLocation.fluVacName,
        lotNumber: eventLocation.fluLot,
        vaccinationEventDate: eventLocation.eventDate
      } as Partial<UserVaccination>;

      this.userVaccinationChange.emit({ ...vaccinationArm });

      location.reload();
    }
  }
}
