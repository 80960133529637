import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleDetailsSnippetPipe } from './article-details-snippet.pipe';

@NgModule({
  declarations: [ArticleDetailsSnippetPipe],
  exports: [ArticleDetailsSnippetPipe],
  imports: [CommonModule]
})
export class ArticleDetailsSnippetModule {}
