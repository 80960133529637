import { InjectionToken } from '@angular/core';

/**
 * Injection token used by the PDF generators, which require the
 * base URL to generate QR codes.
 *
 * This should be a **required** injected token.
 */
export const EHS_BASE_URL_INJECTION_TOKEN = new InjectionToken(
  'EHS_BASE_URL_INJECTION_TOKEN'
);
