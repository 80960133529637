import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EventLocation,
  EventService,
  OnsiteUserRegistration,
  UserRegistration
} from '@common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { userRegActions } from '../../../modules/user-reg/user-reg-store/user-reg.actions';
import { CloudStorageService } from '../../cloud-storage/cloud-storage.service';
import { UserRegistrationHttpService } from '../../http/user-registration-http.service';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { eventLocationActions } from '../event-location/event-location.actions';
import { eventServiceActions } from '../event-service/event-service.actions';
import { userRegistrationActions } from './user-registration.actions';

@Injectable()
export class UserRegistrationEffects {
  constructor(
    private actions$: Actions,
    private errorReportFacade: ErrorReportFacade,
    private userRegistrationHttp: UserRegistrationHttpService,
    private cloudStorage: CloudStorageService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationActions.get),
      mergeMap((action) =>
        this.userRegistrationHttp.get(action.id).pipe(
          mergeMap((entity) => this.handleEntityGet(entity)),
          catchError((err) => [
            userRegistrationActions.getFailed(action),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  downloadPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationActions.downloadPdf),
      mergeMap((action) =>
        this.userRegistrationHttp.downloadProviderPdf(action.id).pipe(
          tap((responseBlob) => {
            window.open(URL.createObjectURL(responseBlob), '_blank');
          }),
          map(() => userRegistrationActions.downloadPdfSuccess()),
          catchError((err) => [
            userRegistrationActions.downloadPdfFailed(),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationActions.list),
      mergeMap((action) =>
        this.userRegistrationHttp
          .list({
            includeReq: action.includeReq,
            includeService: action.includeService,
            includeLocation: action.includeLocation
          })
          .pipe(
            mergeMap((entities) => this.handleEntitiesList(entities)),
            catchError((err) => [
              userRegistrationActions.listFailed(),
              this.errorReportFacade.create({ action, err })
            ])
          )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationActions.update),
      mergeMap((action) =>
        this.userRegistrationHttp.update(action.entity).pipe(
          map((entity) => userRegistrationActions.updateSuccess({ entity })),
          catchError((err) => [
            userRegistrationActions.updateFailed({
              entity: action.entity,
              err
            }),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  addFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationActions.addFile),
      mergeMap((action) =>
        this.userRegistrationHttp
          .addFile({ entity: action.entity, file: action.file })
          .pipe(
            map((entity) => userRegistrationActions.addFileSuccess({ entity })),
            catchError((err) => [
              userRegistrationActions.addFileFailed(action),
              this.errorReportFacade.create({ action, err })
            ])
          )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationActions.create),
      mergeMap((action) =>
        this.userRegistrationHttp.create(action.entity).pipe(
          mergeMap((entity) => [
            userRegistrationActions.createSuccess({
              entity
            }),
            userRegActions.setCreatedUserRegistration({
              entity
            })
          ]),
          catchError(({ error }: HttpErrorResponse) => [
            userRegistrationActions.createFailed({
              ...action,
              err: error
            }),
            this.errorReportFacade.create({ action, err: error })
          ])
        )
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationActions.remove),
      mergeMap((action) =>
        this.userRegistrationHttp.remove(action.id).pipe(
          map((entity) => userRegistrationActions.removeSuccess({ entity })),
          catchError((err) => [
            userRegistrationActions.removeFailed(action),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  /**
   * Method that returns action(s) for a single entity
   */
  private handleEntityGet(entity: UserRegistration): Action[] {
    const actions: Action[] = [userRegistrationActions.getSuccess({ entity })];

    if (typeof entity.eventService === 'object') {
      actions.push(
        eventServiceActions.getSuccess({
          entity: entity.eventService as EventService
        })
      );
    }

    if (
      (entity as OnsiteUserRegistration).eventLocation &&
      typeof (entity as OnsiteUserRegistration).eventLocation === 'object'
    ) {
      actions.push(
        eventLocationActions.getSuccess({
          entity: (entity as OnsiteUserRegistration)
            .eventLocation as EventLocation
        })
      );
    }

    return actions;
  }

  /**
   * Method that returns action(s) for multiple entities
   */
  private handleEntitiesList(entities: UserRegistration[]): Action[] {
    return entities.reduce(
      (acc, entity) => [...acc, ...this.handleEntityGet(entity)],
      [userRegistrationActions.listSuccess({ entities })] as Action[]
    );
  }
}
