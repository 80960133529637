/** The list types of Incentive categories */
export enum IncentiveCategory {
  // Old name = 'body',
  ANNUAL_AWARENESS = 'annual_awareness',
  // Old name = 'service',
  COMMUNITY_INVOLVEMENT = 'community_involvement',
  // Old name = 'prevention',
  ILLNESS_PREVENTION = 'illness_prevention',
  // Old name = 'mind',
  SELF_CARE = 'self_care',
  // Old name = 'movement',
  ACTIVE_LIFESTYLE = 'active_lifestyle',
  // Old name = 'nutrition',
  BALANCED_NUTRITION = 'balanced_nutrition',
  // Old name = 'money',
  MONEY_MANAGEMENT = 'money_management',
  // Old name = 'education',
  ONGOING_EDUCATION = 'ongoing_education',
  ALT_QUALIFIER = 'alt_qualifier',
  CHALLENGES = 'challenges'
}
/**
 * List of incentive types to human friendly names
 */
export const incentiveCategoryNames: {
  [key in IncentiveCategory]: string;
} = {
  [IncentiveCategory.ANNUAL_AWARENESS]: 'Annual Assessments',
  [IncentiveCategory.COMMUNITY_INVOLVEMENT]: 'Social Engagement',
  [IncentiveCategory.ILLNESS_PREVENTION]: 'Staying Healthy',
  [IncentiveCategory.SELF_CARE]: 'Self-Care & Mindfulness',
  [IncentiveCategory.ACTIVE_LIFESTYLE]: 'Active Lifestyle',
  [IncentiveCategory.BALANCED_NUTRITION]: 'Balanced Nutrition',
  [IncentiveCategory.MONEY_MANAGEMENT]: 'Money Management',
  [IncentiveCategory.ONGOING_EDUCATION]: 'Ongoing Education',
  [IncentiveCategory.ALT_QUALIFIER]: 'Alternative Qualifications',
  [IncentiveCategory.CHALLENGES]: 'Challenges'
};

/**
 * List of incentive types to icons
 */
export const incentiveCategoryIcons: {
  [key in IncentiveCategory]: string;
} = {
  [IncentiveCategory.ANNUAL_AWARENESS]: 'emoji_people',
  [IncentiveCategory.COMMUNITY_INVOLVEMENT]: 'diversity_1',
  [IncentiveCategory.ILLNESS_PREVENTION]: 'health_and_safety',
  [IncentiveCategory.SELF_CARE]: 'self_improvement',
  [IncentiveCategory.ACTIVE_LIFESTYLE]: 'fitness_center',
  [IncentiveCategory.BALANCED_NUTRITION]: 'local_dining',
  [IncentiveCategory.MONEY_MANAGEMENT]: 'payments',
  [IncentiveCategory.ONGOING_EDUCATION]: 'school',
  [IncentiveCategory.ALT_QUALIFIER]: 'note_alt',
  [IncentiveCategory.CHALLENGES]: 'sports_handball'
};
/**
 * List of incentive types to colors
 */
export const incentiveCategoryColors: {
  [key in IncentiveCategory | 'background' | 'Total Points']: string;
} = {
  [IncentiveCategory.ANNUAL_AWARENESS]: '#E91E63', //Pink
  [IncentiveCategory.SELF_CARE]: '#2196F3', //Blue
  [IncentiveCategory.ILLNESS_PREVENTION]: '#FFC107', //Amber
  [IncentiveCategory.COMMUNITY_INVOLVEMENT]: '#673AB7', //Deep Purple
  [IncentiveCategory.BALANCED_NUTRITION]: '#FFEB3B', //Yellow
  [IncentiveCategory.MONEY_MANAGEMENT]: '#4CAF50', //Green
  [IncentiveCategory.ACTIVE_LIFESTYLE]: '#E53935', //Red
  [IncentiveCategory.ALT_QUALIFIER]: '#263238', //Blue-Grey
  [IncentiveCategory.ONGOING_EDUCATION]: '#80D8FF', //Light Blue
  [IncentiveCategory.CHALLENGES]: '#18392B', //Dark Green
  background: '#e6e6e6', //Grey
  'Total Points': '#F8A827' //Gold
  // 'Total Points': '#9C27B0' //Purple
};

/**
 * List of incentive score category short descriptions
 */
export const IncentiveCategoryShortAnswer: {
  [key in IncentiveCategory]: string;
} = {
  [IncentiveCategory.ACTIVE_LIFESTYLE]: `We all want to lead a happy and healthy life. A major factor in achieving this is staying active.
    Not only will physical activity allow you to keep your body strong, but it does wonders for mental and emotional health as well.
    In fact, an active lifestyle is proven to better your overall quality of life! `,
  [IncentiveCategory.ANNUAL_AWARENESS]: `Regular checkups and vaccinations are an important part of staying healthy. Just like a car has regular maintenance intervals, so do our bodies. Staying on top of our healthcare can prevent or reduce our chances of disease or lasting damage.`,
  [IncentiveCategory.SELF_CARE]:
    "Self Care and Mindfulness are an important and often neglected aspect of our health. It is important to make sure we're making time for ourselves, and our mental wellbeing as well as our physical health.",
  [IncentiveCategory.MONEY_MANAGEMENT]:
    'Financial stress can have a huge impact on our wellbeing, learning money mangament tools and skills can be beneficial to our overall wellbeing.',
  [IncentiveCategory.BALANCED_NUTRITION]:
    'As the old saying goes, “you are what you eat.” Managing which foods you consume is vital to leading a healthy lifestyle.',
  [IncentiveCategory.ONGOING_EDUCATION]:
    "The field of medicine is constantly evolving and so are our bodies. There will always be more we don't know, but staying informed and educated is invaluable in keeping us healthy.",
  [IncentiveCategory.COMMUNITY_INVOLVEMENT]:
    'As we work on our own health, working on the health and wellbeing of our communities can have a positive affect on our mental and physical well-being as well!',
  [IncentiveCategory.ILLNESS_PREVENTION]:
    'Many illnesses and chronic diseases are caused by key risk behaviors. By making healthy choices, you can reduce your likelihood of getting a chronic disease and improve your quality of life.',
  [IncentiveCategory.ALT_QUALIFIER]: '',
  [IncentiveCategory.CHALLENGES]: ''
};
