import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RescheduleRegistrationDialogComponent } from './ehs-reschedule-registration-dialog.component';
import { RescheduleRegistrationDialogData } from './ehs-reschedule-registration-dialog.data';

@Injectable()
export class RescheduleRegistrationDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(data: RescheduleRegistrationDialogData) {
    return this.matDialog
      .open(RescheduleRegistrationDialogComponent, {
        data,
        width: '600px'
      })
      .afterClosed();
  }
}
