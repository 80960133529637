import {
  getId,
  isDbDocument,
  isOffsiteUserRegistration,
  isOnsiteUserRegistration,
  UserRegistration,
  UserRegistrationId
} from '@common';
import {
  entitySelectionSelectorFactory,
  entitySelectorsFactory
} from '@ehs-ngrx/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ParentUserRegistrationNgrxState,
  UserRegistrationNgrxState,
  USER_REGISTRATION_STORE_KEY
} from './user-registration-ngrx.state';

export const userRegistrationNgrxFeatureSelector =
  createFeatureSelector<UserRegistrationNgrxState>(USER_REGISTRATION_STORE_KEY);

const baseSelectors = entitySelectorsFactory<
  UserRegistration,
  UserRegistrationId,
  ParentUserRegistrationNgrxState,
  UserRegistrationNgrxState
>({
  featureSelector: userRegistrationNgrxFeatureSelector
});

export const userRegistrationNgrxSelector = {
  ...baseSelectors,
  ...entitySelectionSelectorFactory({
    entitiesArraySelector: baseSelectors.entitiesArraySelector,
    featureSelector: userRegistrationNgrxFeatureSelector
  }),
  getReqByRegSelector: (userRegistration: UserRegistrationId) =>
    createSelector(
      baseSelectors.entitySelectorFactory(userRegistration),
      (entity) => {
        if (
          entity &&
          (isOnsiteUserRegistration(entity) ||
            isOffsiteUserRegistration(entity)) &&
          entity.userRequisition &&
          isDbDocument(entity.userRequisition)
        ) {
          return entity.userRequisition;
        }

        return undefined;
      }
    ),
  getByUserSelector: createSelector(
    baseSelectors.entitiesArraySelector,
    (entities) =>
      entities.reduce((acc, userRegistration) => {
        if (!acc[getId(userRegistration.user)]) {
          acc[getId(userRegistration.user)] = [];
        }

        acc[getId(userRegistration.user)].push(userRegistration);

        return acc;
      }, {} as Record<string, UserRegistration[]>)
  ),
  hasNextSelector: createSelector(
    userRegistrationNgrxFeatureSelector,
    (state) => !!state.hasNext
  ),

  pageNumberSelector: createSelector(
    userRegistrationNgrxFeatureSelector,
    (state) => state.pageNumber
  )
};
