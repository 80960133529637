<div fxLayout="row"
     fxLayoutAlign="space-between center"
     *ngIf="eventService">

  <!-- left element -->
  <div fxLayout="row"
       fxLayoutAlign="space-between center"
       fxLayoutGap="8px"
       fxFlex="100">
    <a mat-button
       title="Redirects to Health Provider Form Participant List"
       [routerLink]="eventServiceUrl"
       color="primary"
       style="padding-left: 0; text-decoration: underline;">
      {{eventService.serviceId}} - {{eventService.name}}
    </a>
  </div>
</div>

<div gdColumns="1fr 1fr"
     gdColumns.sm="1fr 1fr"
     gdColumns.xs="1fr"
     gdGap="8px">

  <ehs-card-label label="Visit On or After"
                  [valueStyles]="{color: !providerHealthService?.visitAfter ? 'red' : ''}"
                  [value]="(providerHealthService?.visitAfter | date:'mediumDate') || 'Not Set'">
  </ehs-card-label>

  <ehs-card-label label="Return By"
                  [valueStyles]="{color: !providerHealthService?.returnBy ? 'red' : ''}"
                  [value]="(providerHealthService?.returnBy | date:'mediumDate') || 'Not Set'">
  </ehs-card-label>

  <ehs-card-label label="Members Registered"
                  [internalLink]="eventServiceUrl"
                  [value]="eventServiceStats?.providerHealth?.userRegistrations || 0">
  </ehs-card-label>

  <ehs-card-label label="Members Resulted"
                  [value]="eventServiceStats?.providerHealth?.userResults || 0">
  </ehs-card-label>

</div>
