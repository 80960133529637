import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { selectId } from '../utils/select-id';
import { CoachingSession } from '@common';
import { createReducer, Action, on } from '@ngrx/store';
import { coachingSessionActions } from './coaching-session.actions';

export interface CoachingSessionState extends EntityState<CoachingSession> {
  loading?: boolean;
  loadingAvailabilities?: boolean;
  /** Array of available times (military time). Example: ['10:00', '13:00'] */
  availabilities?: string[];
}

const adapter = createEntityAdapter({
  selectId
});

const reducer = createReducer(
  adapter.getInitialState({} as CoachingSessionState),
  on(coachingSessionActions.get, (state) => ({ ...state, loading: true })),
  on(coachingSessionActions.getSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(coachingSessionActions.getFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(coachingSessionActions.list, (state) => ({ ...state, loading: true })),
  on(coachingSessionActions.listSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(coachingSessionActions.listFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(coachingSessionActions.listAvailabilities, (state) => ({
    ...state,
    loadingAvailabilities: true
  })),
  on(
    coachingSessionActions.listAvailabilitiesSuccess,
    (state, { availabilities }) => ({
      ...state,
      availabilities: availabilities || [],
      loadingAvailabilities: false
    })
  ),
  on(coachingSessionActions.listAvailabilitiesFailed, (state) => ({
    ...state,
    loadingAvailabilities: false
  })),

  on(coachingSessionActions.create, (state) => ({ ...state, loading: true })),
  on(coachingSessionActions.createSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, {
      ...state,
      loading: false
    })
  ),
  on(coachingSessionActions.createFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(coachingSessionActions.remove, (state) => ({ ...state, loading: true })),
  on(coachingSessionActions.removeSuccess, (state, { entity }) =>
    adapter.removeOne(entity._id as string, { ...state, loading: false })
  ),
  on(coachingSessionActions.removeFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(coachingSessionActions.exportCompanyCs, (state) => ({
    ...state,
    loading: true
  })),
  on(coachingSessionActions.exportCompanyCsSuccess, (state) => ({
    ...state,
    loading: false
  })),
  on(coachingSessionActions.exportCompanyCsFailed, (state) => ({
    ...state,
    loading: false
  }))
);

export function CoachingSessionReducer(
  state: CoachingSessionState,
  action: Action
) {
  return reducer(state, action);
}
