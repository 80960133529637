import { ObjectId } from 'mongodb';
import { FINGER_STICK_CODE } from '../constants/finger-stick-tests';
import { OnsiteRegistrationComboType } from '../constants/onsite-registration-combo-type';
import { OnsiteRegistrationType } from '../constants/onsite-registration-type';
import { COTININE_TESTS } from '../constants/user-test-id-list';
import { Company } from '../models/company';
import { EventLocation } from '../models/event-location/event-location';
import { EventServiceText } from '../models/event-service-text/event-service-text';
import { ConditionalUserTest } from '../models/event-service/conditional-user-test';
import { CustomPricing } from '../models/event-service/custom-pricing';
import {
  EventService,
  EventServiceServiceType,
  EventServiceStatus
} from '../models/event-service/event-service';
import {
  isOffsiteService,
  OffsiteService
} from '../models/event-service/offsite-service';
import {
  isOnsiteService,
  OnsiteService
} from '../models/event-service/onsite-service';
import { ProviderHealthService } from '../models/event-service/provider-health-service';
import {
  OtherServiceType,
  ServiceType
} from '../models/event-service/service-type';
import {
  OffsiteGroupType,
  OnsiteGroupType
} from '../models/event-service/site-group-type';
import { SiteVaccinationType } from '../models/event-service/site-service';
import { PartialUser } from '../models/partial-user';
import { PayType } from '../models/pay-type';
import { PersonType } from '../models/person-type';
import { User } from '../models/user';
import { UserRegistration } from '../models/user-registration';
import { BaseSiteUserRegistration } from '../models/user-registration/base-site-user-registration';
import {
  OnsiteUserRegistration,
  isOnsiteUserRegistration
} from '../models/user-registration/onsite-user-registration';
import { UserTest, UserTestId } from '../models/user-test/user-test';
import { DateUtil } from './date-util';
import { getId } from './get-id';
import { TimeUtil } from './time-util';
import { toMap } from './to-map';
import { UserUtil } from './user-util';
import { UserRegistrationStatus } from '../models/user-registration/base-user-registration';
import { IncentiveGrouping } from '../models/event-service/event-service-incentives';

/**
 * Static utility class that has helper functions
 */
export class EventServiceUtil {
  /**
   * If we are to capture insurance information for the given service
   * and group-type. Will be true if any part of the payment is calculated
   * to be insurance.
   *
   * **note** this might be made more generic once self-pay is integrated.
   *
   */
  public static captureInsurance(params: {
    groupType: OnsiteGroupType | OffsiteGroupType;
    /**
     * The list of optional-tests the user signed up for.
     * Not actually directly used, only checked for length
     */
    optionalTests: Array<UserTest | string | ObjectId>;
  }): boolean {
    const { groupType, optionalTests } = params;
    const payPackage = !!groupType?.payPackage?.includes(PayType.INSURANCE);
    // This isn't clean, but will work
    const payVaccination = !!(
      groupType as OnsiteGroupType
    )?.payVaccinations?.includes(PayType.INSURANCE);
    const payOptionalTests =
      !!optionalTests.length &&
      !!groupType?.payOptionalTests?.includes(PayType.INSURANCE);

    return payPackage || payVaccination || payOptionalTests;
  }

  /**
   * Returns an isolated service-type, or undefined if one can't be determined
   */
  public static getIsolatedServiceType(params: {
    /**
     * The list of relevant event-services
     */
    eventServices: EventService[];
    /**
     * The list of ALL user-registrations, before or after
     */
    userRegistrations?: UserRegistration[];
  }): ServiceType | undefined {
    const { eventServices, userRegistrations } = params;
    const alreadyRegisteredToServices = (userRegistrations || []).map(
      (userRegistration) =>
        userRegistration && getId(userRegistration.eventService)
    );

    if (!eventServices) {
      return undefined;
    }

    const availableEventServices = eventServices.filter(
      (eventService) =>
        // Do not include services the user is already registered to
        !alreadyRegisteredToServices.includes(getId(eventService))
    );

    if (!availableEventServices.length) {
      // If there is nothing to sign up for, then don't do anything
      return undefined;
    }
    let isolatedServiceType: ServiceType;

    for (const availableEventService of availableEventServices) {
      if (!availableEventService.services.length) {
        // If there are no services, skip this event-service.
        continue;
      }

      const relevantServiceTypes =
        availableEventService.services?.filter(
          // If the service is onsite, include automatically as it will be checked at
          // the event level. Otherwise, verify if its relevant.
          (service) =>
            isOnsiteService(service) || this.isRelevantService(service)
        ) || [];

      if (!relevantServiceTypes.length) {
        // If there are no relevant services left, then skip this event-service.
        continue;
      }

      if (relevantServiceTypes.length > 1) {
        // If the event-service has more than 1 relevant service left, then return undefined
        // as then there is no isolated service.
        return undefined;
      }
      // Otherwise get the serviceType for the single service
      const service = relevantServiceTypes[0];
      const serviceType = service.type;

      if (!isolatedServiceType) {
        // If there is nothing set, set it
        isolatedServiceType = serviceType;
      }

      if (isolatedServiceType === serviceType) {
        // If the service type is already the same, then continue
        continue;
      }

      // Otherwise the service-type is different, which means there isn't
      // an isolated service-type
      return undefined;
    }

    // Otherwise we should have the same serviceType, or undefined.
    return isolatedServiceType;
  }

  /**
   * Returns isolated onsite-types for a single event-service, if
   * there is one.
   */
  public static getIsolatedOnsiteType(params: {
    /**
     * The list of relevant event-services
     */
    eventServices: EventService[];
    /**
     * The list of ALL user-registrations, before or after
     */
    userRegistrations?: UserRegistration[];
    // If true, searches service with existing registration
    registeringForAdditional?: boolean;
  }): OnsiteRegistrationType | undefined {
    const { eventServices, userRegistrations, registeringForAdditional } =
      params;
    const alreadyRegisteredToServices = (userRegistrations || []).map(
      (userRegistration) =>
        userRegistration && getId(userRegistration.eventService)
    );

    const onsiteRegistrationMap: Record<string, OnsiteUserRegistration[]> = {};
    const eventServiceIds = (eventServices || []).map((service) =>
      getId(service)
    );

    // If registered for onsite type, shouldn't be able to register for additional onsite types
    // This makes map to filter out services with onsite regs when registering for additional reg
    if (registeringForAdditional) {
      userRegistrations.map((registration) => {
        if (
          ![
            UserRegistrationStatus.NO_SHOW,
            UserRegistrationStatus.CANCELED
          ].includes(registration.status) &&
          isOnsiteUserRegistration(registration) &&
          eventServiceIds.includes(getId(registration.eventService))
        ) {
          onsiteRegistrationMap[getId(registration.eventService)]?.length
            ? onsiteRegistrationMap[getId(registration.eventService)].push(
                registration
              )
            : (onsiteRegistrationMap[getId(registration.eventService)] = [
                registration
              ]);
        }
      });
    }

    if (!eventServices) {
      // Missing provided properties
      return undefined;
    }

    const onsiteServices = eventServices
      .filter((eventService) => {
        // When additional registration, only get service registered to
        if (registeringForAdditional) {
          if (onsiteRegistrationMap[getId(eventService)]?.length) {
            return false;
          }

          return alreadyRegisteredToServices.includes(getId(eventService));
        }

        // Do not include services the user is already registered to
        return !alreadyRegisteredToServices.includes(getId(eventService));
      })
      .map(
        (eventService) =>
          this.getSelectedService({
            eventService,
            serviceType: ServiceType.ONSITE
          }) as OnsiteService
      )
      .filter((_) => !!_);

    if (!onsiteServices.length) {
      // If there are no onsite-services, return undefined, as this technically is an error
      return undefined;
    }

    if (onsiteServices.length > 1) {
      // If there are multiple onsite-services, then we can't automatically determine,
      // so skip
      return undefined;
    }

    // Otherwise return the onsite-types for the single onsite-service
    let onsiteTypes: OnsiteRegistrationType[];

    // If additional registration, filter out screenings
    if (registeringForAdditional) {
      onsiteTypes = this.getOnsiteTypesFromService({
        service: onsiteServices[0]
      }).filter(
        (type) =>
          type !== OnsiteRegistrationType.SCREENING &&
          type !== OnsiteRegistrationType.BOTH
      );
    } else {
      onsiteTypes = this.getOnsiteTypesFromService({
        service: onsiteServices[0]
      });
    }

    if (onsiteTypes.length !== 1) {
      // If there are multiple types, then just return one
      return undefined;
    }

    return onsiteTypes[0];
  }

  /**
   * If there is an isolated vaccination event. This is defined if the following is true:
   * 1. There is 1 relevant event-service
   * 2. There is 1 onsite-service
   * 3. There is COVID vaccination on said onsite-service.
   *
   * Will return the event-service that is isolated, or
   * undefined if there isn't one or there are multiple.
   */
  public static getIsolatedCOVIDEvent(params: {
    /**
     * The list of relevant event-services
     */
    eventServices: EventService[];
    /**
     * The list of ALL user-registrations, before or after
     */
    userRegistrations?: UserRegistration[];
  }):
    | {
        eventService: EventService;
        onsiteService: OnsiteService;
        vaccinationTypes: SiteVaccinationType[];
      }
    | undefined {
    const { eventServices, userRegistrations } = params;
    const alreadyRegisteredToServices = (userRegistrations || []).map(
      (userRegistration) =>
        userRegistration && getId(userRegistration.eventService)
    );

    if (!eventServices) {
      // No event-service
      return undefined;
    }

    if (eventServices.length > 1) {
      // If there are multiple event-services to sign up for, then
      // skip this automatically
      return undefined;
    }
    // Flag used to determine if we already found one.
    // if we find one, and this is TRUE, then there are multiple
    // and we didn't find an isolated one.
    let alreadyFoundVaccinationEvent = false;
    let eventService: EventService;
    let onsiteService: OnsiteService;
    let vaccinationTypes: SiteVaccinationType[];

    for (const _eventService of eventServices) {
      eventService = _eventService;

      const registered = alreadyRegisteredToServices.includes(
        getId(eventService)
      );

      if (registered) {
        // If we are already registered, skip
        continue;
      }

      onsiteService = this.getSelectedService({
        eventService,
        serviceType: ServiceType.ONSITE
      }) as OnsiteService;

      if (!onsiteService) {
        // If there isn't an onsite service, skip
        continue;
      }

      if (eventService.services.length > 1) {
        // There are multiple services, skip
        continue;
      }

      const hasCovid = onsiteService.vaccinationType?.includes(
        SiteVaccinationType.COVID_19
      );

      vaccinationTypes = onsiteService.vaccinationType;

      if (!hasCovid) {
        // If there is no vaccination, then skip
        continue;
      }

      // Finally, if we have hit this state AGAIN, then there are
      // multiple, and there isn't an isolated one. So return undefined.
      if (alreadyFoundVaccinationEvent) {
        return undefined;
      }
      // Otherwise set the flag
      alreadyFoundVaccinationEvent = true;
    }

    if (alreadyFoundVaccinationEvent) {
      // We will hit this if we have only seen 1 and only one, so return what we saved
      return {
        eventService,
        onsiteService,
        vaccinationTypes
      };
    }

    return undefined;
  }

  /**
   * Returns the list of supported "onsite-combo-types", which are similar
   * to the onsite-types used and saved through-out the application.
   * The difference is this type provides the distinction what goes to what.
   */
  public static getOnsiteComboTypes(params: {
    /**
     * The list of relevant event-services that
     * the user can sign up for. We will NOT include the
     * ones the user already signed up for according to
     * the list of user-registrations below.
     */
    eventServices: EventService[];
    /**
     * The list of relevant user-registrations the user
     * has already signed up for. Can be used to ignore
     * previously signed up event-services
     */
    userRegistrations?: UserRegistration[];
    /**
     * Flag for additional registration workflow.
     * Assumes already has primary registration so searches for
     * event service user is already registered to.
     */
    additionalRegistration?: boolean;
  }): OnsiteRegistrationComboType[] {
    const { eventServices, userRegistrations, additionalRegistration } = params;
    const alreadyRegisteredToServices = (userRegistrations || []).map(
      (userRegistration) =>
        userRegistration && getId(userRegistration.eventService)
    );

    return Array.from(
      eventServices
        .filter((eventService) =>
          additionalRegistration
            ? alreadyRegisteredToServices.includes(getId(eventService))
            : !alreadyRegisteredToServices.includes(getId(eventService))
        )
        .reduce((types, eventService) => {
          eventService.services
            .filter((service) => service.type === ServiceType.ONSITE)
            .map((service: OnsiteService) =>
              this.getOnsiteComboTypesFromService({
                service
              })
            )
            // The method can return null if its undetermined
            .filter((_) => !!_)
            .forEach((comboTypes) =>
              comboTypes.forEach((comboType) => types.add(comboType))
            );

          return types;
        }, new Set<OnsiteRegistrationComboType>())
        .values()
    );
  }

  /**
   * Returns the onsite-combo-type for the given event-service.
   * Can return null if none are given, or if the service is missing
   */
  public static getOnsiteComboTypesFromService(params: {
    service: OnsiteService;
  }): Array<OnsiteRegistrationComboType> | null {
    const { service } = params;

    if (!service) {
      return null;
    }
    const hasPanel = service.panel;
    const hasCovid = service.vaccinationType?.includes(
      SiteVaccinationType.COVID_19
    );
    const hasFlu = !!service.vaccinationType?.includes(SiteVaccinationType.FLU);

    if (hasCovid && hasFlu) {
      // This shouldn't be possible
      // tslint:disable-next-line: no-console
      console.warn('[onsite-types] both flu and covid are given');
      // Let this fall thru, and hopefully match with something
    }

    if (hasPanel && hasFlu) {
      return [
        OnsiteRegistrationComboType.SCREENING_ONLY,
        OnsiteRegistrationComboType.FLU_VACCINATION_ONLY,
        OnsiteRegistrationComboType.SCREENING_AND_FLU
      ];
    }

    if (hasPanel && hasCovid) {
      return [
        OnsiteRegistrationComboType.SCREENING_ONLY,
        OnsiteRegistrationComboType.COVID_VACCINATION_ONLY,
        OnsiteRegistrationComboType.SCREENING_AND_COVID
      ];
    }

    if (hasPanel) {
      return [OnsiteRegistrationComboType.SCREENING_ONLY];
    }

    if (hasCovid) {
      return [OnsiteRegistrationComboType.COVID_VACCINATION_ONLY];
    }

    if (hasFlu) {
      return [OnsiteRegistrationComboType.FLU_VACCINATION_ONLY];
    }

    // Otherwise the service has nothing in terms of onsite-stuff.
    return null;
  }

  /**
   * Returns the list of event-services, including "other-service-types",
   * which aren't used much, if at all within the platform.
   */
  public static getServiceTypes(params: {
    eventService: EventService;
    /**
     * If we are to include onsite-service types, such as screening/vaccination.
     */
    includeOnsiteTypes?: boolean;
  }): Array<ServiceType | OtherServiceType | OnsiteRegistrationType> {
    const { eventService, includeOnsiteTypes } = params;

    if (!eventService) {
      return [];
    }

    // We use a set for the very off-case there are duplicate service-types, which would be a problem
    const serviceTypes: Array<
      ServiceType | OtherServiceType | OnsiteRegistrationType
    > = [];
    // We use this in 2 places, so its saved.
    const onsiteService = this.getSelectedService({
      eventService,
      serviceType: ServiceType.ONSITE
    }) as OnsiteService | undefined;

    // If there are services, then check for ServiceType support
    if (onsiteService) {
      serviceTypes.push(ServiceType.ONSITE);

      if (includeOnsiteTypes) {
        // If we are to include onsite types
        serviceTypes.push(
          ...this.getOnsiteTypesFromService({
            service: onsiteService
          })
        );
      }
    }

    if (
      this.getSelectedService({
        eventService,
        serviceType: ServiceType.OFFSITE
      })
    ) {
      serviceTypes.push(ServiceType.OFFSITE);
      // TODO: show offsite labs?
    }

    if (
      this.getSelectedService({
        eventService,
        serviceType: ServiceType.PROVIDER_HEALTH
      })
    ) {
      serviceTypes.push(ServiceType.PROVIDER_HEALTH);
    }

    if (eventService.healthEducation) {
      serviceTypes.push(OtherServiceType.EDUCATION);

      if (eventService.marqueeCoaching?.enabled) {
        serviceTypes.push(OtherServiceType.EDUCATION_MARQUEE);
      }
    }

    if (eventService.incentivized) {
      serviceTypes.push(OtherServiceType.INCENTIVES);
    }

    // **Note** HRA legacy is also supported, but will be removed in the future
    if (eventService.hraSettings || eventService.hra) {
      serviceTypes.push(OtherServiceType.HRA);
    }

    return serviceTypes;
  }

  /**
   * Returns the list of supported onsiteTypes from
   * the list of event-services, while ignoring any event-service
   * already registered for.
   */
  public static getOnsiteTypes(params: {
    /**
     * The list of relevant event-services that
     * the user can sign up for. We will NOT include the
     * ones the user already signed up for according to
     * the list of user-registrations below.
     */
    eventServices: EventService[];
    /**
     * The list of relevant user-registrations the user
     * has already signed up for. Can be used to ignore
     * previously signed up event-services
     */
    userRegistrations?: UserRegistration[];
    vaccinationOnly?: boolean;
    isAdmin?: boolean;
  }): OnsiteRegistrationType[] {
    const { eventServices, userRegistrations, vaccinationOnly, isAdmin } =
      params;
    const alreadyRegisteredToServices = (userRegistrations || []).map(
      (userRegistration) =>
        userRegistration && getId(userRegistration.eventService)
    );

    return Array.from(
      eventServices
        .filter(
          (eventService) =>
            !alreadyRegisteredToServices.includes(getId(eventService))
        )
        .reduce((types, eventService) => {
          eventService.services
            .filter((service) => service.type === ServiceType.ONSITE)
            .forEach((service: OnsiteService) =>
              this.getOnsiteTypesFromService({ service, isAdmin }).forEach(
                (type) => {
                  if (
                    vaccinationOnly &&
                    type !== OnsiteRegistrationType.COVID_VACCINE &&
                    type !== OnsiteRegistrationType.FLU_VACCINE
                  ) {
                    return;
                  }

                  types.add(type);
                }
              )
            );

          return types;
        }, new Set<OnsiteRegistrationType>())
        .values()
    );
  }

  /**
   * Returns an "end-user" string for the list of onsite-types given
   */
  public static getReadableOnsiteTypes(params: {
    onsiteTypes: OnsiteRegistrationType[];
  }): string {
    const { onsiteTypes } = params;

    if (!onsiteTypes || !onsiteTypes.length) {
      return '';
    }

    return onsiteTypes
      .map(
        (onsiteType) =>
          ((
            {
              [OnsiteRegistrationType.FLU_VACCINE]: 'Flu',
              [OnsiteRegistrationType.COVID_VACCINE]: 'COVID',
              [OnsiteRegistrationType.SCREENING]: 'Screening'
            } as Record<OnsiteRegistrationType, string>
          )[onsiteType])
      )
      .join(' & ');
  }

  /**
   * Returns the list of onsite-registration-types from an individual service
   */
  public static getOnsiteTypesFromService(params: {
    service: OnsiteService;
    isAdmin?: boolean;
  }): OnsiteRegistrationType[] {
    const { service, isAdmin } = params;
    const types: OnsiteRegistrationType[] = [];

    if (!service) {
      return types;
    }

    /**
     * Checked if specifically false in case
     * of syncing issues returning undefined fields.
     */
    if (service.showOnsiteService === false && !isAdmin) {
      return types;
    }

    if (service.panel) {
      types.push(OnsiteRegistrationType.SCREENING);
    }

    if (
      service.vaccinationType &&
      service.vaccinationType.includes(SiteVaccinationType.FLU)
    ) {
      types.push(OnsiteRegistrationType.FLU_VACCINE);
    }

    return types;
  }

  /**
   * If the selected-service supports fingerstick form entries **and** if
   * the user user-registration signed up for screening.
   */
  public static isFingerStickForm(params: {
    /**
     * The selected service we are to check, fingerstick
     * is currently only supported for ONSITE and OFFSITE
     */
    service: EventServiceServiceType;
    /**
     * Map of all user-tests, used to check the
     * panel is fingerstick.
     */
    userTestMap: Record<UserTestId, UserTest>;
    /**
     * The user-registration the user signed up for.
     * Used to check if the user signed up for screening.
     */
    userRegistration: OnsiteUserRegistration;
  }): boolean {
    const { service, userTestMap, userRegistration } = params;

    if (!service || !userTestMap || !userRegistration) {
      return false;
    }

    if (!userRegistration.screening) {
      return false;
    }

    if (
      service.type === ServiceType.ONSITE ||
      service.type === ServiceType.OFFSITE
    ) {
      const userTest = userTestMap[UserTestId(getId(service.panel))];
      const code = userTest
        ? '' + userTest.primaryOrderCode || '' + userTest.orderCode || ''
        : '';

      return service.panel && code === FINGER_STICK_CODE;
    }

    return false;
  }

  /**
   * Returns if the given service and user-registration
   * has screening enabled.
   *
   * This was moved from the admin-result-form.selectors, to be
   * re-used.
   */
  public static isBioScreeningForm(params: {
    service: OnsiteService | OffsiteService;
    userRegistration: OnsiteUserRegistration;
  }): boolean {
    const { service, userRegistration } = params;

    if (!service || !userRegistration) {
      return false;
    }

    return !!(
      (isOnsiteService(service) || isOffsiteService(service)) &&
      service.panel &&
      userRegistration.screening
    );
  }

  /**
   * This was moved and changed from the admin-result-form.selectors,
   * primarily to keep things similar between the different parts of the form.
   * Even though COVID vaccines are not currently stable.
   *
   * This internally combines multiple checks against each together.
   */
  public static isCOVIDVaccineForm(params: {
    service: OnsiteService;
    eventLocation: EventLocation;
    userRegistration: OnsiteUserRegistration;
  }): boolean {
    const { service, eventLocation, userRegistration } = params;

    if (!service || !eventLocation || !userRegistration) {
      return false;
    }

    const serviceHasCovid = !!service.vaccinationType?.find(
      (type) => type === SiteVaccinationType.COVID_19
    );

    const registrationHasCovid =
      !!userRegistration.vaccination?.vaccinationType?.includes(
        SiteVaccinationType.COVID_19
      );

    const eventHasCovid = !!eventLocation.vaccineType;

    return serviceHasCovid && registrationHasCovid && eventHasCovid;
  }

  /**
   * Returns if the onsite service has any of the COVID vaccines set.
   * TODO: also need to check the corresponding event-location
   */
  public static hasVaccine(params: {
    eventService: EventService;
    vaccinationType: SiteVaccinationType;
  }): boolean {
    const { eventService, vaccinationType } = params;

    if (!eventService || !eventService.services || !vaccinationType) {
      // This could also be an error
      return false;
    }

    const onsiteService = this.getSelectedService({
      eventService,
      serviceType: ServiceType.ONSITE
    }) as OnsiteService;

    if (!onsiteService || !onsiteService.vaccinationType) {
      return false;
    }

    return !!onsiteService.vaccinationType.find(
      (type) => type === vaccinationType
    );
  }

  /**
   * Returns the list of user-test ids to load on page load.
   * loads conditional tests, even without conditional checks.
   */
  public static getServiceTestsIds(params: {
    /**
     * The parent event service, used JUST to get the custom pricing
     */
    eventService: EventService;
    /**
     * The service to load the conditional services from.
     */
    service: OffsiteService | OnsiteService;
  }): string[] {
    const { eventService, service } = params;

    if (!service) {
      return (eventService.customPricing || []).map(({ test }) => getId(test));
    }

    return [
      ...new Set(
        [
          ...(eventService.customPricing || []).map(({ test }) => test),
          service.panel,
          ...(service.conditionalPanels || []).map(({ userTest }) => userTest),

          ...service.additionalServices,
          ...(service.conditionalAdditionalServices || []).map(
            ({ userTest }) => userTest
          ),

          ...service.additionalTests,
          ...(service.conditionalAdditionalTests || []).map(
            ({ userTest }) => userTest
          ),

          ...service.optionalTests,
          ...(service.conditionalOptionalTests || []).map(
            ({ userTest }) => userTest
          )
        ]
          .filter((_) => !!_)
          .map(getId)
      )
    ];
  }

  /**
   * Returns the common-conditional-tests. Use one of the
   * other more specific methods instead
   * add cotinine logic
   */
  public static getCommonConditionalUserTests(params: {
    /**
     * The list of conditional-user-tests to check
     */
    conditionalUserTests: ConditionalUserTest[];
    /**
     * Map of all user-tests
     */
    userTests: Record<string, UserTest>;
    /**
     * The current user, used to calculate conditional services
     */
    user: User | PartialUser;
    /**
     * Onsite event date to determine age at time of event, otherwise defaults to age during registration
     */
    eventDate?: string;
  }): UserTest[] {
    const { conditionalUserTests, userTests, user, eventDate } = params;

    if (!conditionalUserTests || !userTests || !user) {
      return [];
    }

    return (conditionalUserTests || [])
      .filter((conditionalUserTest) =>
        this.matchesConditionalUserTests({
          conditionalUserTest,
          user,
          eventDate
        })
      )
      .map(({ userTest }) => userTests[getId(userTest)]);
  }

  /**
   * Returns the panel or the conditional panel that matches, if there
   * is one.
   *
   * Will return undefined if there is a missing parameter
   */
  public static getPanel(params: {
    service: OnsiteService | OffsiteService;
    /**
     * The current user, used to calculate conditional services
     */
    user: User | PartialUser;
    /**
     * Map of all user-tests
     */
    userTests: Record<string, UserTest> | Array<UserTest>;
  }): UserTest | undefined {
    const { service, user } = params;

    if (!user || !params.userTests) {
      return undefined;
    }

    const userTests = Array.isArray(params.userTests)
      ? (toMap({
          entities: params.userTests,
          key: '_id'
        }) as Record<string, UserTest>)
      : params.userTests;

    const { userTest: conditionalPanel } =
      (service?.conditionalPanels || []).find((conditionalUserTest) =>
        this.matchesConditionalUserTests({
          conditionalUserTest,
          user
        })
      ) || {};

    if (conditionalPanel) {
      // If a conditional panel matches, use that rather than the given
      return userTests[getId(conditionalPanel)];
    }

    // Otherwise use the service one
    if (!service?.panel) {
      return undefined;
    }

    return userTests[getId(service.panel)];
  }

  /**
   * Returns the calculated additional services available
   * for a user event-service.
   */
  public static getAdditionalServices(params: {
    service: OnsiteService | OffsiteService;
    /**
     * The current user, used to calculate conditional services
     */
    user: User;
    /**
     * Map of all user-tests, or array of the same
     */
    userTests: Record<string, UserTest> | Array<UserTest>;
  }): UserTest[] {
    const { service, user } = params;

    if (!service || !user || !params.userTests) {
      return [];
    }

    const userTests = Array.isArray(params.userTests)
      ? (toMap({
          entities: params.userTests,
          key: '_id'
        }) as Record<string, UserTest>)
      : params.userTests;

    return [
      ...service.additionalServices.map(
        (additionalService) => userTests[getId(additionalService)]
      ),
      ...this.getCommonConditionalUserTests({
        conditionalUserTests: service.conditionalAdditionalServices,
        ...params,
        userTests
      }).filter((_) => !!_)
    ];
  }

  /**
   * Returns the calculated additional tests available
   * for a user event-service
   */
  public static getAdditionalTests(params: {
    service: OnsiteService | OffsiteService;
    /**
     * The current user, used to calculate conditional services
     */
    user: User | PartialUser;

    /** User registration to check if user self-reported tobacco use */
    userRegistration?: Pick<
      BaseSiteUserRegistration,
      'usesTobacco' | 'additionalTests'
    >;

    /**
     * Map of all user-tests
     */
    userTests: Record<string, UserTest> | Array<UserTest>;
    /**
     * If true, we will skip the filter check against cotinine registration check
     */
    skipCotinineCheck?: boolean;
    /**
     * If onsite, use event date to determine age at time of event date
     */
    eventDate?: string;
  }): UserTest[] {
    const { service, user, userRegistration, skipCotinineCheck } = params;

    if (!service || !user || !params.userTests) {
      return [];
    }

    const userTests = Array.isArray(params.userTests)
      ? (toMap({
          entities: params.userTests,
          key: '_id'
        }) as Record<string, UserTest>)
      : params.userTests;

    const additionalTests = userRegistration?.additionalTests
      ? [
          ...userRegistration.additionalTests.map(
            (additionalTest) => userTests[getId(additionalTest)]
          )
        ]
      : [
          ...(service.additionalTests || []).map(
            (additionalTest) => userTests[getId(additionalTest)]
          ),
          ...this.getCommonConditionalUserTests({
            conditionalUserTests: service.conditionalAdditionalTests || [],
            ...params,
            userTests
          })
        ];

    return (
      additionalTests
        .filter((_) => !!_)
        // If the user selected uses tobacco in the registration, then we don't show the cotinine additional test
        .filter((test) => {
          if (!userRegistration?.usesTobacco || skipCotinineCheck) {
            return true;
          }

          return !COTININE_TESTS.includes(UserTestId(getId(test)));
        })
    );
  }

  /**
   * Returns the calculated optional tests available
   * for a user event-service
   */
  public static getOptionalTests(params: {
    service: OnsiteService | OffsiteService;
    /**
     * The current user, used to calculate conditional services
     */
    user: User;
    /**
     * Map of all user-tests
     */
    userTests: Record<string, UserTest>;
    userRegistration?: Pick<UserRegistration, 'usesTobacco'>;
    /**
     * If true, we will skip the filter check against cotinine registration check
     */
    skipCotinineCheck?: boolean;
    /**
     * If onsite, use event date to determine age at time of event date
     */
    eventDate?: string;
  }): UserTest[] {
    const { service, user, userTests, userRegistration, skipCotinineCheck } =
      params;

    if (!service || !user || !userTests) {
      return [];
    }

    return (
      [
        ...service.optionalTests.map(
          (optionalTest) => userTests[getId(optionalTest)]
        ),
        ...this.getCommonConditionalUserTests({
          conditionalUserTests: service.conditionalOptionalTests,
          ...params
        })
      ]
        .filter((_) => !!_)
        // If the user selected uses tobacco in the registration, then we don't show the cotinine optional test
        .filter((test) => {
          if (!userRegistration?.usesTobacco || skipCotinineCheck) {
            return true;
          }

          return !COTININE_TESTS.includes(UserTestId(getId(test)));
        })
    );
  }

  /**
   * Returns the corresponding event-location from the given user-registration.
   */
  public static getEventLocation(params: {
    userRegistration: UserRegistration;
    /**
     * The list of event locations for the given company.
     */
    eventLocationsMap: Record<string, Record<string, EventLocation>>;
  }): EventLocation {
    const { userRegistration, eventLocationsMap } = params;

    if (
      !userRegistration ||
      !eventLocationsMap ||
      userRegistration.serviceType !== ServiceType.ONSITE
    ) {
      return undefined;
    }

    const eventLocations =
      eventLocationsMap[getId(userRegistration.eventService)];

    if (!eventLocations) {
      return undefined;
    }

    return eventLocations[getId(userRegistration.eventLocation)];
  }

  /**
   * Returns if the conditional matches for the current user
   *
   * **note** the logic here is similar to the logic in isInRange from user-test-util
   */
  private static matchesConditionalUserTests(params: {
    /**
     * The conditional test to check against
     */
    conditionalUserTest: ConditionalUserTest;
    /**
     * The user to check against
     */
    user: User | PartialUser;
    /**
     * Date of registered onsite event
     */
    eventDate?: string;
  }): boolean {
    const { conditionalUserTest, user, eventDate } = params;

    if (!conditionalUserTest || !user) {
      return false;
    }

    const eventDateDate = eventDate ? new Date(eventDate) : undefined;

    const age = eventDateDate
      ? UserUtil.getAgeAtEventDate({ user, eventDate: eventDateDate })
      : UserUtil.getAge(user);

    const matchesGender =
      conditionalUserTest.gender === 'both' ||
      conditionalUserTest.gender === user.gender;

    const matchesAge =
      conditionalUserTest.ageHigh >= age && conditionalUserTest.ageLow <= age;
    // If both the high and low matches, match anything
    const matchAllAges =
      conditionalUserTest.ageHigh === conditionalUserTest.ageLow;

    return matchesGender && (matchesAge || matchAllAges);
  }

  /**
   * Returns the cost for the test.
   */
  public static getTestCost(params: {
    /**
     * The map of custom pricing for all user tests.
     */
    customPricingMap: Record<string, CustomPricing>;
    /**
     * The user test to get the test cost for, will
     * be over-ridden by the custom pricing if there is one
     */
    userTest: UserTest;
  }): number {
    const { customPricingMap, userTest } = params;
    const isInvalidCost = (cost: number) => isNaN(Number(cost)) || cost < 0;

    const customPrice = customPricingMap[getId(userTest)];

    if (customPrice) {
      // Custom price exists
      const cost = customPrice.cost;

      if (isInvalidCost(cost)) {
        // The custom cost is invalid, throw an error
        throw new Error('CustomPrice cost is invalid');
      }

      return cost;
    }

    if (isInvalidCost(userTest.cost)) {
      throw new Error('UserTest is invalid');
    }

    return userTest.cost;
  }

  /**
   * Returns the total offsite service costs, including optional tests
   * if given.
   *
   * Will return null if there is something wrong/missing
   *
   */
  public static getOffsiteServiceTotal(params: {
    /**
     * The custom pricing set for this event-service.
     * Used to override the given pricing for user-tests
     */
    customPricing?: CustomPricing[];
    /**
     * The panel of the offsite service
     */
    panel?: UserTest;
    /**
     * The list of additional services
     */
    additionalServices: UserTest[];
    /**
     * The list of additional tests
     */
    additionalTests: UserTest[];
    /**
     * The optional tests selected. Will be included if provided
     */
    optionalTests?: UserTest[];
  }): number {
    const {
      customPricing = [],
      additionalServices = [],
      additionalTests = [],
      panel,
      optionalTests
    } = params;
    const customPricingMap = toMap({
      key: 'test',
      entities: customPricing
    });

    return [
      panel,
      ...(additionalServices || []),
      ...(additionalTests || []),
      ...(optionalTests || [])
    ].reduce((total, userTest) => {
      if (!userTest) {
        return total;
      }

      try {
        const cost = this.getTestCost({ customPricingMap, userTest });

        total += cost;

        return total;
      } catch (err) {
        // Can log here if things aren't working properly
        return total;
      }
    }, 0);
  }

  /**
   * Returns a list of all the optional tests AND additional services
   * signed up for from the user-registration.
   *
   * Usually the responses is an objectId on the backend,
   * or a string on the front-end as we don't populate the user-tests.
   */
  public static getAllServices(params: {
    /**
     * The user-registration the user signed up for
     */
    userRegistration: UserRegistration;
    /**
     * The corresponding event service the user signed up for
     */
    eventService: EventService;
  }): Array<string | ObjectId | UserTest> {
    const { userRegistration, eventService } = params;

    if (!userRegistration || !eventService) {
      return [];
    }

    const service = this.getSelectedService({
      serviceType: ServiceType.ONSITE,
      eventService
    });

    if (!service) {
      return [];
    }

    if (
      service.type === ServiceType.OFFSITE ||
      service.type === ServiceType.ONSITE
    ) {
      return [
        ...(service.optionalTests || []),
        ...(service.additionalServices || [])
      ];
    }

    // If the service is a non "site" type.
    return [];
  }

  /**
   * Returns the list of supported person-types for the
   * entire event-service.
   *
   * Used for front-end logins.
   */
  public static getSupportedPersonTypes(params: {
    eventServices: EventService[];
  }): PersonType[] {
    const { eventServices } = params;

    if (!eventServices || !eventServices.length) {
      return [];
    }

    return Array.from(
      eventServices.reduce((acc, eventService) => {
        (eventService.services || []).forEach((service) => {
          if (
            service.type === ServiceType.OFFSITE ||
            service.type === ServiceType.ONSITE
          ) {
            (service.groupTypes || []).forEach((groupTypes) =>
              (groupTypes.participates || []).forEach((participant) =>
                acc.add(participant)
              )
            );

            return;
          }

          if (service.type === ServiceType.PROVIDER_HEALTH) {
            (service.personTypes || []).forEach((personType) =>
              acc.add(personType)
            );

            return;
          }
        });

        (eventService.participants || []).forEach((participant) =>
          acc.add(participant)
        );

        (eventService?.hraSettings?.participants || []).forEach((participant) =>
          acc.add(participant)
        );

        return acc;
      }, new Set<PersonType>())
    );
  }

  /**
   * Returns whether a user is eligible for an event service based on the
   * user's personType, and the participate
   */
  public static getRelevantGroupTypes(params: {
    user: User;
    service: OffsiteService | OnsiteService;
  }): (OnsiteGroupType | OffsiteGroupType)[] {
    const { user, service } = params;

    const groupTypeArray = (service?.groupTypes || []).map(
      (groupType, index) => {
        if ((groupType.participates || []).includes(user.personType)) {
          return {
            ...groupType,
            id: index
          };
        }

        return undefined;
      }
    );

    return groupTypeArray.filter((_) => !!_);
  }

  /***
   * Returns the selected service from the service type from the event service
   * This will only return the first service that matches the service type and
   * assumes that the event service only has one relevant service type
   */
  public static getSelectedService(params: {
    serviceType: ServiceType;
    eventService: EventService;
  }): EventServiceServiceType | undefined {
    const { serviceType, eventService } = params;

    return (
      eventService &&
      eventService.services &&
      eventService.services.find((service) => service.type === serviceType)
    );
  }

  /**
   * Returns if the given eventService is relevant based upon its
   * startDate and endDate and status
   */
  public static isRelevantEventService(params: {
    eventService: EventService;
    company?: Company | string;
  }): boolean {
    const { eventService, company } = params;

    if (
      !eventService ||
      !eventService.startDate ||
      !eventService.endDate ||
      !DateUtil.isValidDate(eventService.startDate) ||
      !DateUtil.isValidDate(eventService.endDate) ||
      (company && getId(company) !== getId(eventService.company))
    ) {
      return false;
    }

    if (!eventService.status) {
      // If there is no event-service status given, for backwards
      // compat, set the service as active:
      return (
        eventService.startDate <= DateUtil.getToday() &&
        eventService.endDate >= DateUtil.getToday()
      );
    }

    // Otherwise check if the status is active
    return (
      eventService.status === EventServiceStatus.ACTIVE &&
      eventService.startDate <= DateUtil.getToday() &&
      eventService.endDate >= DateUtil.getToday()
    );
  }

  /**
   * Utility function that returns if the given service is still relevant
   * today/right now. This only works with providerHealth and offsite services.
   * onsite requires event-location logic.
   */
  public static isRelevantService(
    service: OffsiteService | ProviderHealthService
  ): boolean {
    switch (service.type) {
      case ServiceType.OFFSITE: {
        /**
         * Checked if specifically false in case
         * of syncing issues returning undefined fields.
         */
        if (service.showOffsiteService === false) {
          return false;
        }

        /**
         * An offsite service is relevant if the registrationDeadline has not passed, and
         * the starting date has pased
         */
        const isBetweenDates =
          service.registrationDeadline >= DateUtil.getToday() &&
          service.registrationActive <= DateUtil.getToday();

        if (!isBetweenDates) {
          // If the offsite service isn't "active", then its not relevant./
          return false;
        }

        // Now check the "exact dates" set for the deadlines
        if (service.registrationActive === DateUtil.getToday()) {
          // If the registration is active today today, then the service is relevant
          // only AFTER midnight
          return TimeUtil.getCurrentTime() >= '00:00';
        }

        if (service.registrationDeadline === DateUtil.getToday()) {
          // If the registration deadline is today, then the service is active
          // until the last second of the day.
          return TimeUtil.getCurrentTime() <= '23:59';
        }

        return true;
      }
      case ServiceType.PROVIDER_HEALTH: {
        /**
         * Checked if specifically false in case
         * of syncing issues returning undefined fields.
         */
        if (service.showProviderService === false) {
          return false;
        }

        /**
         * A provider health service is relevant if the returnBy date has not passed
         */
        return service.returnBy >= DateUtil.getToday();
      }
      default:
        return false;
    }
  }

  /**
   * Return true if todays date is before access end date (11 months after the
   * service start date)
   */
  public static allowAfterServiceEndAccess(params: {
    eventService: EventService;
    company: Company;
  }): boolean {
    const { eventService, company } = params;

    if (
      !eventService ||
      !company ||
      !eventService.startDate ||
      !DateUtil.isValidDate(eventService.startDate) ||
      (company && getId(company) !== getId(eventService.company))
    ) {
      return false;
    }

    const accessStartDate = DateUtil.getFutureDate({
      months: 12,
      startingDate: DateUtil.convertFromString(eventService.startDate),
      zone: 'UTC-6'
    });

    return (
      accessStartDate &&
      DateUtil.convertToString(accessStartDate) >= DateUtil.getToday()
    );
  }

  /**
   * Return true if todays date is the prior to the date when
   * hra is suppose to end
   */
  public static allowHraAfterServiceEndAccess(params: {
    eventService: EventService;
    company: Company;
  }): boolean {
    const { eventService, company } = params;

    const hraDeadline = eventService?.hraSettings?.hraDeadlineDate;

    const accessStartDate = DateUtil.getFutureDate({
      months: 12,
      startingDate: DateUtil.convertFromString(eventService?.startDate),
      zone: 'UTC-6'
    });

    if (
      !eventService ||
      !eventService?.startDate ||
      !eventService?.endDate ||
      !DateUtil.isValidDate(eventService?.startDate) ||
      !DateUtil.isValidDate(eventService?.endDate) ||
      !company ||
      (company && getId(company) !== getId(eventService.company)) ||
      DateUtil.convertToString(eventService?.startDate) > DateUtil.getToday()
    ) {
      return false;
    }

    if (
      !hraDeadline ||
      !DateUtil.isValidDate(eventService.hraSettings?.hraDeadlineDate)
    ) {
      return (
        accessStartDate &&
        DateUtil.convertToString(accessStartDate) >= DateUtil.getToday()
      );
    }

    return hraDeadline && hraDeadline >= DateUtil.getToday().toString();
  }

  /**
   * Utility function that casts the service-type
   * to the property to "select" within mongodb.
   * This is needed as the enum doesn't match up with
   * the properties in an event-service-text exactly.
   */
  public static getTextSelectProperty(serviceType: ServiceType | 'coaching') {
    return (
      {
        [ServiceType.ONSITE]: 'onsite',
        [ServiceType.OFFSITE]: 'offsite',
        [ServiceType.PROVIDER_HEALTH]: 'provider',
        coaching: 'coaching'
      } as { [key in ServiceType]: keyof EventServiceText }
    )[serviceType];
  }

  /**
   * Returns whether or not the Reimbursement feature is enabled for the event service.
   * If it is enabled on the event service, we check if the User person type belongs to the
   * Person types supported for the reimbursements
   */
  public static isReimbursementEnabled(params: {
    user: User;
    eventService: EventService;
  }) {
    const { eventService, user } = params;

    if (!eventService || !user) {
      return false;
    }

    if (!eventService?.reimbursement) {
      return false;
    }

    if (
      !(eventService.reimbursement.participants || []).includes(user.personType)
    ) {
      return false;
    }

    return true;
  }

  /**
   * Gets all the latest services with incentive program,one service per incentive group
   */
  public static getLatestServiceByIncentiveGroup(
    eventServices: EventService[]
  ): EventService[] {
    if (!eventServices || !eventServices.length) {
      return [];
    }

    const challengesService = this.findMostRecentService({
      eventServices,
      incentiveGroup: IncentiveGrouping.CHALLENGES
    });

    const empowerService = this.findMostRecentService({
      eventServices,
      incentiveGroup: IncentiveGrouping.EMPOWERMENT_PLAN
    });

    const healthService =
      this.findMostRecentService({
        eventServices,
        incentiveGroup: IncentiveGrouping.HEALTHY_METRICS
      }) || eventServices[0];

    return Array.from(
      new Set([challengesService, empowerService, healthService])
    ).filter((_) => !!_);
  }

  private static findMostRecentService(params: {
    eventServices: EventService[];
    incentiveGroup: IncentiveGrouping;
  }): EventService {
    const { eventServices, incentiveGroup } = params;
    const groupServices = eventServices.filter(
      (service) =>
        service.incentives?.incentiveGroup === incentiveGroup &&
        service.incentives?.showIncentives
    );

    if (!groupServices.length) {
      return undefined;
    }

    return groupServices.reduce((mostRecentService, service) => {
      const mostRecentStartDate = DateUtil.convertFromString(
        mostRecentService.startDate
      );

      const serviceStartDate = DateUtil.convertFromString(service.startDate);

      if (serviceStartDate.getTime() > mostRecentStartDate.getTime()) {
        mostRecentService = service;
      }

      return mostRecentService;
    }, groupServices[0]);
  }
}
