import { ObjectId } from 'mongodb';
import { Company } from '../company';
import { DbDocument } from '../db-document';
import { AdminUser } from '../admin-user';
import { EventService } from '../event-service/event-service';
import { ServiceType } from '../event-service/service-type';
import { PersonType } from '../person-type';
import { Signature } from '../signature';
import { User } from '../user';

export enum UserRegistrationStatus {
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  NO_SHOW = 'NO_SHOW'
}

export type UserRegistrationId = string & { readonly brand: unique symbol };

// tslint:disable-next-line: variable-name
export const UserRegistrationId = (userRegistrationId: string) =>
  userRegistrationId as UserRegistrationId;

export interface BaseUserRegistration extends DbDocument<UserRegistrationId> {
  /**
   * The shortId that is passed to the labs instead of the
   * mongoDb id.
   *
   * It should be returned as the "requisition" number and be identified as such
   * elsewhere within the application
   */
  shortId: string;

  /**
   * The user who created the registration.
   * Do not get this confused with userProfile, which
   * is a snapshot of the user information.
   */
  user: string | ObjectId | User;

  /**
   * The eventService
   */
  eventService: string | ObjectId | EventService;
  /** The User's company, newly added field as of 10/27/23 */
  company: string | ObjectId | Company;

  isAdditional?: boolean;
  /**
   * The status of the user's registration.
   */
  status: UserRegistrationStatus;

  canceledBy?: {
    _id: AdminUser | string;
    email: string;
    firstName: string;
    lastName: string;
  };
  canceledOn?: Date;

  /**
   * The Service Type selected. This is
   * the "identifier" for the BaseUserRegistration type
   */
  serviceType: ServiceType;

  /**
   * This is can't be changed and is saved "outside" of the user's
   * profile (if saved for this registration).
   */
  personType: PersonType;

  /**
   * Whether or not the registration was done by the user or if it was
   * created by an admin as a walk-in. Meaning user showed up to an event
   * but was not scheduled in the system.
   */
  walkin?: boolean;

  /**
   * The user's self reported height information.
   * All information is optional
   */
  height?: {
    feet?: number;
    inches?: number;
  };

  /**
   * The user's self reported weight in lbs
   */
  weight?: number;

  /**
   * The user's self reported bloodPressure
   */
  bloodPressure?: {
    systolic?: number;
    diastolic?: number;
  };

  /**
   * The user's self reported tobacco usage
   */
  usesTobacco?: boolean;

  /**
   * Declined properties saved, for onsite and offsite.
   */
  declined?: {
    height?: boolean;
    weight?: boolean;
    bloodPressure?: boolean;
    tobacco?: boolean;
  };

  /**
   * The user's signature that will be saved as a signature object.
   */
  signature?: Signature;
}
