import { MarqueeHealthRedirect } from '../models/marquee-health/marquee-health-redirect';

export enum EducationCategoryCardTypes {
  MARQUEE = 'marquee',
  MEDLINE_PLUS = 'medline_plus',
  EHS = 'ehs',
  EXTERNAL = 'external'
}

export type EducationCategoryCard = {
  /** The type of the card */
  type: EducationCategoryCardTypes;
  /** Category ID */
  id: CategoryIds;
  /** Category Title */
  title: string;
  /** Category Description */
  text: string;
  /** Category Image */
  img: string;
  /** Category URL */
  url: string;
  /** Category Source (e.g. MedlinePlus) */
  source?: string;
};

export enum CategoryIds {
  // Main Categories
  HEALTHY_LIFESTYLES = 'healthy_lifestyles',
  MENTAL_EMOTIONAL_WELLBEING = 'mental_emotional_wellbeing',
  HEART_HEALTH = 'heart_health',
  DIABETES_PREVENTION = 'diabetes_prevention',
  UNDERSTANDING_LAB_TESTS = 'understanding_lab_tests',
  MEN_WOMEN_HEALTH = 'men_women_health',
  // Additional Resources
  VIDEO_LIBRARY = 'video_library',
  WEBINARS = 'webinars',
  NEWSLETTER = 'newsletter',
  BLOG = 'blog',
  CHALLENGES = 'challenges',
  HEALTH_TOPICS = 'health_topics',
  DRUGS_SUPPLEMENTS = 'drugs_supplements',
  GENETICS = 'genetics',
  MEDICAL_TESTS = 'medical_tests',
  VIDEO_TOOLS = 'video_tools',
  FINANCIAL_WELLNESS = 'financial_wellness',
  QUIT_SMOKING = 'quit_smoking',
  EHS_PERKS = 'ehs_perks'
}

export const categoryCards: EducationCategoryCard[] = [
  {
    id: CategoryIds.EHS_PERKS,
    title: 'EHS Perks',
    url: '/education/categories/' + CategoryIds.EHS_PERKS,
    text: 'Explore additional services that can take your wellness goals to the next level!',
    img: 'education/ctg-ehs-perks.svg',
    type: EducationCategoryCardTypes.EHS
  },
  {
    id: CategoryIds.HEALTHY_LIFESTYLES,
    title: 'HEALTHY LIFESTYLE',
    url: '/education/categories/' + CategoryIds.HEALTHY_LIFESTYLES,
    text: 'Explore content designed to promote healthy living - from fitness resources to nutrition tips and more.',
    img: 'education/ctg-healthy-lifestyle.svg',
    type: EducationCategoryCardTypes.EHS
  },
  {
    id: CategoryIds.MENTAL_EMOTIONAL_WELLBEING,
    title: 'MENTAL & EMOTIONAL WELLBEING',
    url: '/education/categories/' + CategoryIds.MENTAL_EMOTIONAL_WELLBEING,
    text: 'Discover healthy ways to manage stress, boost your mood, and practice self-care.',
    img: 'education/ctg-emotional-health.svg',
    type: EducationCategoryCardTypes.EHS
  },
  {
    id: CategoryIds.HEART_HEALTH,
    title: 'HEART HEALTH',
    url: '/education/categories/' + CategoryIds.HEART_HEALTH,
    text: 'From managing cholesterol to staying smoke-free, this digital library provides information on reducing the risk for heart attack or stroke.',
    img: 'education/ctg-heart-health.svg',
    type: EducationCategoryCardTypes.EHS
  },
  {
    id: CategoryIds.DIABETES_PREVENTION,
    title: 'DIABETES PREVENTION',
    url: '/education/categories/' + CategoryIds.DIABETES_PREVENTION,
    text: 'Many Americans are at risk for type 2 diabetes. Browse resources for preventing the onset of or living with diabetes.',
    img: 'education/ctg-diabetes-prevention.svg',
    type: EducationCategoryCardTypes.EHS
  },
  {
    id: CategoryIds.UNDERSTANDING_LAB_TESTS,
    title: 'UNDERSTANDING LAB TESTS',
    url: '/education/categories/' + CategoryIds.UNDERSTANDING_LAB_TESTS,
    text: 'From allergy panels to thyroid screenings, learn more about testing and what results could mean for you.',
    img: 'education/ctg-understanding-tests.svg',
    type: EducationCategoryCardTypes.EHS
  },
  {
    id: CategoryIds.MEN_WOMEN_HEALTH,
    title: 'MEN & WOMEN’S HEALTH',
    url: '/education/categories/' + CategoryIds.MEN_WOMEN_HEALTH,
    text: 'Learn more about recommended exams and health practices for adult men and women.',
    img: 'education/ctg-men-women-health.svg',
    type: EducationCategoryCardTypes.EHS
  },
  {
    id: CategoryIds.FINANCIAL_WELLNESS,
    title: 'Financial Wellness',
    url: '/education/categories/' + CategoryIds.FINANCIAL_WELLNESS,
    text: 'Do you manage money well? Test your financial smarts and discover budgeting resources.',
    img: 'education/ctg-financial-wellness.svg',
    type: EducationCategoryCardTypes.EHS
  }
];

export const marqueeHealthCards: EducationCategoryCard[] = [
  // * Not sure if Lee wanted this added in the above group, but the way the logic was previously set up it either needs to be here, or we need to rebuild how the heath-education component works
  {
    id: CategoryIds.QUIT_SMOKING,
    title: 'Quit Smoking',
    url: MarqueeHealthRedirect.QUIT_SMOKING,
    text: 'Avoiding smoking and tobacco use is one of the best ways to prevent illness. If you are considering quitting, this resource is the first step.',
    img: 'education/smoking-cessation.svg',
    type: EducationCategoryCardTypes.MARQUEE
  },
  {
    id: CategoryIds.VIDEO_LIBRARY,
    title: 'Video Library',
    source: '',
    url: MarqueeHealthRedirect.VIDEO_LIBRARY,
    text: 'Access digital content from Nationally Certified Health Coaches covering a range of wellness topics.',
    img: 'education/ctg-adt-video-library.svg',
    type: EducationCategoryCardTypes.MARQUEE
  },
  {
    id: CategoryIds.WEBINARS,
    title: 'Webinars',
    url: MarqueeHealthRedirect.WEBINARS,
    source: '',
    text: 'Learn something new from the comfort of your home! Participate in remote webinars presented by Marquee Health.',
    img: 'education/ctg-adt-webinars.svg',
    type: EducationCategoryCardTypes.MARQUEE
  },
  {
    id: CategoryIds.NEWSLETTER,
    title: 'Newsletter',
    url: MarqueeHealthRedirect.NEWSLETTER,
    source: '',
    text: 'Read up on the latest health and wellness happenings with the Marquee Health newsletter.',
    img: 'education/ctg-adt-newsletter.svg',
    type: EducationCategoryCardTypes.MARQUEE
  },
  // **Note** this is an external link, not tied to marquee.
  {
    id: CategoryIds.BLOG,
    title: 'Blog',
    url: 'https://www.wellbeing.place/',
    source: '',
    text: 'From mental health tips to delicious recipes, explore blog posts that will inspire positive lifestyle choices.',
    img: 'education/ctg-adt-blog.svg',
    type: EducationCategoryCardTypes.EXTERNAL
  },
  // **Note** this is the only one that is made not available
  // from marquee.
  {
    id: CategoryIds.CHALLENGES,
    title: 'Challenges',
    url: MarqueeHealthRedirect.CHALLENGES,
    source: '',
    text: 'Embark on a fun series of wellness quests where you can compete with yourself to achieve improvement.',
    img: 'education/ctg-adt-challenges.svg',
    type: EducationCategoryCardTypes.MARQUEE
  }
];

export const medlinePlusCards: EducationCategoryCard[] = [
  {
    id: CategoryIds.HEALTH_TOPICS,
    title: 'Health Topics',
    url: 'https://medlineplus.gov/healthtopics.html',
    source: 'U.S. National Library of Medicine',
    text: 'Gain access to the MedlinePlus library hosting credible information about symptoms, causes, treatments, and prevention for over 1,000 health conditions.',
    img: 'education/ctg-adt-health-topics.svg',
    type: EducationCategoryCardTypes.MEDLINE_PLUS
  },
  {
    id: CategoryIds.DRUGS_SUPPLEMENTS,
    title: 'Drugs & Supplements',
    url: 'https://medlineplus.gov/druginformation.html',
    source: 'U.S. National Library of Medicine',
    text: 'Search information about prescription drugs and medicines, including side effects and special precautions.',
    img: 'education/ctg-adt-drugs-supplements.svg',
    type: EducationCategoryCardTypes.MEDLINE_PLUS
  },
  {
    id: CategoryIds.GENETICS,
    title: 'Genetics',
    url: 'https://medlineplus.gov/genetics/',
    source: 'U.S. National Library of Medicine',
    text: 'Browse a user-friendly guide to genes, chromosomes, DNA, and more.',
    img: 'education/ctg-adt-genetics.svg',
    type: EducationCategoryCardTypes.MEDLINE_PLUS
  },
  {
    id: CategoryIds.MEDICAL_TESTS,
    title: 'Medical Tests',
    url: 'https://medlineplus.gov/lab-tests/',
    source: 'U.S. National Library of Medicine',
    text: 'Better understand the purpose of specific testing with this extensive lab test database.',
    img: 'education/ctg-adt-medical-tests.svg',
    type: EducationCategoryCardTypes.MEDLINE_PLUS
  },
  {
    id: CategoryIds.VIDEO_TOOLS,
    title: 'Video & Tools',
    url: 'https://medlineplus.gov/videosandcooltools.html',
    source: 'U.S. National Library of Medicine',
    text: 'Watch videos on anatomy and body function, view tutorials, and explore interactive quizzes and calculators.',
    img: 'education/ctg-adt-video-tools.svg',
    type: EducationCategoryCardTypes.MEDLINE_PLUS
  }
];

/**
 * Returns a the education category cards mapped by the id
 */
export const getCategoryMapWithImageSource = (imageSrc: string) =>
  categoryCards.reduce(
    (categoryMap, category) => ({
      ...categoryMap,
      [category.id]: { ...category, img: imageSrc + category.img }
    }),
    {} as { [categoryId in CategoryIds]: EducationCategoryCard }
  );
