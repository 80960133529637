/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Simple but configurable logger. Should be used on the client-side
 * and script situations
 */
export class CommonLogger {
  /**
   * @param hide Array of messages we are to hide.
   */
  constructor(public hide: Array<'error' | 'log' | 'warn' | 'debug'> = []) {}
  error(message?: any, ...optionalParams: any[]): void {
    if (this.hide && this.hide.includes('error')) {
      return;
    }

    if (
      message?.code?.includes('auth/user-not-found') ||
      message?.code?.includes('auth/wrong-password')
    ) {
      console.error('INVALID_ACCOUNT');
    } else {
      console.error(message, ...(optionalParams || []));
    }
  }

  log(message?: any, ...optionalParams: any[]): void {
    if (this.hide && this.hide.includes('log')) {
      return;
    }
    // tslint:disable-next-line: no-console
    console.log(message, ...optionalParams);
  }

  debug(message?: any, ...optionalParams: any[]): void {
    if (this.hide && this.hide.includes('debug')) {
      return;
    }
    // tslint:disable-next-line: no-console
    console.debug(message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]): void {
    if (this.hide && this.hide.includes('warn')) {
      return;
    }
    // tslint:disable-next-line: no-console
    console.warn(message, ...optionalParams);
  }
}
