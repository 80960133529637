import { DbDocument } from '@common';

export type SubDoc = Pick<DbDocument, '_id'>;

/**
 * Utility function that "strips" sub-documents from the
 * given entity if they are given. This will prevent
 * duplicated data from being stored in the state.
 *
 * This function should be used in conjunction with some effects
 * handlers to dispatch other actions to "save" the sub-doc
 * into their respective parts of the ngrx store.
 *
 * **note** this does not handle objectIds!
 */
export const stripSubDocs = <T>(params: {
  /**
   * The entity we are to save, and "strip" sub-docs from.
   */
  entity: T;
  /**
   * List of sub-documents to get ids from, if possible.
   */
  subDocs: Array<keyof T>;
}): T => {
  const { entity, subDocs } = params;

  return subDocs.reduce((acc, subDoc) => {
    if (typeof acc[subDoc] === 'string') {
      // If the key is already a string, then we don't need to strip it
      return acc;
    }

    if (
      typeof acc[subDoc] === 'object' &&
      !!acc[subDoc] && // This is here to prevent null from being checked
      !!(acc[subDoc] as any)._id
    ) {
      // If it really is a sub doc, remove it from the object and replace it with just the _id
      return { ...acc, [subDoc]: (acc[subDoc] as any)._id };
    }

    return acc;
  }, entity);
};

/**
 * Utility function that "strips" sub-documents over an array
 * of entities using teh stripSubDocs
 * @see stripSubDocs
 */
export const stripSubDocsFromEntities = <T>(params: {
  entities: T[];
  subDocs: Array<keyof T>;
}): T[] => {
  const { entities, subDocs } = params;

  return entities.reduce(
    (acc, entity) => [...acc, stripSubDocs({ entity, subDocs })],
    []
  );
};
