import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { EhsMergeUserDialogComponent } from './ehs-merge-user-dialog.component';
import { EhsMergeUserDialogService } from './ehs-merge-user-dialog.service';

@NgModule({
  declarations: [EhsMergeUserDialogComponent],
  imports: [
    CommonModule,
    // Angular material
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule
  ],
  providers: [EhsMergeUserDialogService],
  exports: [EhsMergeUserDialogComponent]
})
export class EhsMergeUserDialogModule {}
