import {
  CommonResponse,
  Company,
  GcfIncentiveExportRequest,
  GcfRegExportRequest
} from '@common';
import {
  entityActionFactory,
  entityHttpActionFactory,
  entityStatsActionFactory
} from '@ehs-ngrx/common';
import { QueryList } from '@http';
import { createAction, props } from '@ngrx/store';
import { CompanyNgrxStatsTypeParams } from './company-ngrx.state';
import { CompanySecurity } from '@common';

export const companyNgrxActions = {
  setCompany: createAction(
    '[COMPANY_NGRX] SET_COMPANY',
    props<{ company: Company }>()
  ),

  getCurrent: entityHttpActionFactory<
    undefined,
    {
      entity: Company;
    }
  >('[COMPANY_NGRX] GET_CURRENT'),

  getSecurity: entityHttpActionFactory<
    { id: string },
    {
      entity: CompanySecurity;
    }
  >('[COMPANY_NGRX] GET_SECURITY'),

  updateSecurity: entityHttpActionFactory<
    { companyId: string; companySecurity: Partial<CompanySecurity> },
    {
      entity: CompanySecurity;
    }
  >('[COMPANY_NGRX] UPDATE_SECURITY'),

  getCompanyNotes: entityHttpActionFactory<
    { companyId: string },
    {
      companyNotes: string;
    }
  >('[COMPANY_NGRX] GET_COMPANY_NOTES'),

  updateCompanyNotes: entityHttpActionFactory<
    {
      companyId: string;
      notes: string;
    },
    { companyNotes: string }
  >('[COMPANY_NGRX] UPDATE_COMPANY_NOTES'),

  get: entityActionFactory<Company, string>('COMPANY_NGRX').get,

  list: entityHttpActionFactory<
    {
      query?: QueryList<Document>;
      reload?: boolean;
    },
    {
      entities: Company[];
    }
  >('[COMPANY_NGRX] LIST'),

  clear: createAction('[COMPANY_NGRX] CLEAR'),

  exportUserRegistrations: entityHttpActionFactory<
    Omit<GcfRegExportRequest, 'email'>,
    CommonResponse
  >('[COMPANY_NGRX] EXPORT_USER_REGISTRATIONS'),

  exportFieldStaff: entityHttpActionFactory<
    Omit<GcfRegExportRequest, 'email'>,
    CommonResponse
  >('[COMPANY_NGRX] EXPORT_FIELD_STAFF'),

  exportUserIncentives: entityHttpActionFactory<
    Omit<GcfIncentiveExportRequest, 'email'>,
    CommonResponse
  >('[COMPANY_NGRX] EXPORT_USER_INCENTIVES'),

  exportUsers: entityHttpActionFactory<{
    company: string;
  }>('[COMPANY_NGRX] EXPORT_USERS'),

  exportCoachingSession: entityHttpActionFactory<{
    company: string;
  }>('[COMPANY_NGRX] EXPORT_COACHING_SESSION'),

  ...entityStatsActionFactory<CompanyNgrxStatsTypeParams>('COMPANY_NGRX')
};
