import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonResponse, GwApiUtilsFormMappings } from '@common';
import { AdminGwHttpService } from '@http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { adminGwNgrxActions } from './admin-gw-ngrx.actions';
import { JsonDialogService } from './shared/json-dialog/json-dialog.service';

@Injectable()
export class AdminGwNgrxEffects {
  constructor(
    private actions$: Actions,
    private adminGwHttp: AdminGwHttpService,
    private jsonDialog: JsonDialogService,
    private snackBar: MatSnackBar
  ) {}

  sync$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminGwNgrxActions.sync.req),
      mergeMap((action) => {
        if (action.payload.gcf) {
          // Sanity check as conditional-tests shouldn't be executed ever via the new way.
          if (
            action.payload.syncResource ===
            ('conditionalTest' as keyof GwApiUtilsFormMappings)
          ) {
            console.warn(
              'conditionalTest should not be synced via the new way wil be ignored!'
            );
          }

          // This is the new method, the old method is below.
          // **note** the cloud-function will handle conditional tests
          return this.adminGwHttp.syncViaGcf(action.payload.syncResource).pipe(
            tap(
              () => {
                if (action.payload.gcf) {
                  // We started the sync in the background and aren't waiting.
                  this.snackBar.open('Started sync in the background', 'Ok');
                } else {
                  // If we are not syncing to gcf, then the back-end returns
                  // when its done syncing.
                  this.snackBar.open('Successfully synced', 'Ok');
                }
              },
              () => this.snackBar.open('Failed to sync', 'Ok')
            ),
            map((commonResponse) =>
              adminGwNgrxActions.sync.success({
                syncResource: action.payload.syncResource,
                commonResponse
              })
            ),
            catchError(() => [
              adminGwNgrxActions.sync.failed({
                syncResource: action.payload.syncResource
              })
            ])
          );
        }

        // The old way calls specific back-end endpoints.
        // this will probably be removed in the future.
        const endpointsMap: Record<
          keyof GwApiUtilsFormMappings,
          () => Observable<CommonResponse>
        > = {
          userTests: this.adminGwHttp.syncUserTests.bind(this.adminGwHttp),
          userTestsRanges: this.adminGwHttp.syncUserTests.bind(
            this.adminGwHttp
          ),
          userTestsRisks: this.adminGwHttp.syncUserTests.bind(this.adminGwHttp),
          companies: this.adminGwHttp.syncCompanies.bind(this.adminGwHttp),
          vaccination: this.adminGwHttp.syncVaccination.bind(this.adminGwHttp),
          eventService: this.adminGwHttp.syncEventServices.bind(
            this.adminGwHttp
          ),
          eventLocations: this.adminGwHttp.syncEventLocations.bind(
            this.adminGwHttp
          ),
          eventServiceText: this.adminGwHttp.syncCustomConsent.bind(
            this.adminGwHttp
          ),
          healthPrograms: this.adminGwHttp.syncHealthPrograms.bind(
            this.adminGwHttp
          ),
          insuranceProviders: this.adminGwHttp.syncInsuranceProviders.bind(
            this.adminGwHttp
          ),
          customPricing: this.adminGwHttp.syncCustomPricing.bind(
            this.adminGwHttp
          ),
          articleLinks: this.adminGwHttp.syncArticleLinks.bind(
            this.adminGwHttp
          ),
          articles: this.adminGwHttp.syncArticles.bind(this.adminGwHttp),
          conditionalTest: this.adminGwHttp.syncServiceConditionalTests.bind(
            this.adminGwHttp
          ),
          incentives: this.adminGwHttp.syncIncentives.bind(this.adminGwHttp)
        };
        const http = endpointsMap[action.payload.syncResource];

        if (!http) {
          console.warn(
            '[AdminGwNgrxEffects] no sync resource found',
            action.payload.syncResource
          );

          return [];
        }

        return http().pipe(
          tap(
            () => {
              if (action.payload.gcf) {
                // We started the sync in the background and aren't waiting.
                this.snackBar.open('Started sync in the background', 'Ok');
              } else {
                // If we are not syncing to gcf, then the back-end returns
                // when its done syncing.
                this.snackBar.open('Successfully synced', 'Ok');
              }
            },
            () => this.snackBar.open('Failed to sync', 'Ok')
          ),
          mergeMap((commonResponse) => {
            if (action.payload.syncResource === 'eventService') {
              // There is 1 "compound sync" to handle an annoying edge-case
              // with how event-services+conditional-tests are synced, where
              // if event-services are synced, then we also need to sync the
              // conditional tests.
              return [
                // Return this to stop loading for this sync.
                adminGwNgrxActions.sync.success({
                  syncResource: 'eventService',
                  commonResponse
                }),
                adminGwNgrxActions.sync.req({
                  syncResource: 'conditionalTest'
                })
              ];
            }

            // Otherwise we are done.
            return [
              adminGwNgrxActions.sync.success({
                syncResource: action.payload.syncResource,
                commonResponse
              })
            ];
          }),
          catchError(() => [
            adminGwNgrxActions.sync.failed({
              syncResource: action.payload.syncResource
            })
          ])
        );
      })
    )
  );

  viewAsJson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminGwNgrxActions.viewAsJson.req),
      mergeMap((action) =>
        this.jsonDialog
          .open({
            title: action.payload.title,
            json: action.payload.json
          })
          .pipe(
            take(1),
            map(() => adminGwNgrxActions.viewAsJson.success())
          )
      )
    )
  );

  validateDate$ = createEffect(
    () => () =>
      this.actions$.pipe(
        ofType(adminGwNgrxActions.validateData.req),
        mergeMap((action) =>
          this.adminGwHttp
            .getGwappsErrors({
              type: action.payload.resource,
              id: action.payload.id
            })
            .pipe(
              mergeMap((res) => [
                adminGwNgrxActions.validateData.success({
                  res,
                  resource: action.payload.resource
                }),
                adminGwNgrxActions.viewAsJson.req({
                  title: 'Validated Data Errors',
                  json: res
                })
              ]),
              catchError((err: HttpErrorResponse) => [
                adminGwNgrxActions.validateData.success({
                  // We will display the raw error in the dialog
                  res: err.error,
                  resource: action.payload.resource
                }),
                adminGwNgrxActions.viewAsJson.req({
                  title: 'Validated Data Errors',
                  json: err.error
                })
              ])
            )
        )
      )
  );
}
