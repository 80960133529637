import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import {
  EventAlmostFullResponse,
  EventLocation,
  EventLocationUtil,
  EventLocationWithTimes,
  getId,
  LocationData,
  LocationDataUtil,
  OnsiteRegistrationComboType,
  OnsiteUserRegistration,
  SingleEventStats,
  TimeUtil,
  toMap,
  UserRegistrationUtil
} from '@common';
import { EventLocationNgrxFacade } from '@ehs-ngrx/event-location-ngrx';
import { Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { EhsRescheduleRegistrationDialogResponse } from './ehs-reschedule-registration-dialog-response';

/**
 * Dialog for rescheduling an onsite user-registration
 */
@Component({
  selector: 'ehs-reschedule-registration-dialog',
  templateUrl: './ehs-reschedule-registration-dialog.component.html',
  styleUrls: ['./ehs-reschedule-registration-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RescheduleRegistrationDialogComponent implements OnInit {
  public userRegistration: OnsiteUserRegistration;
  public eventLocation$: Observable<EventLocation>;
  public eventLocations$ = this.eventLocationFacade.recentForCompany$;
  // The updatable eventLocation model for the select
  public eventLocation: EventLocation;
  /**
   * The id of the userRegistration to reschedule
   */
  private userRegistrationId: string;
  public company: string;

  // Time Slot Variables
  public selectedEventLocationTimes$: Observable<SingleEventStats> = of(
    {} as SingleEventStats
  );

  public availableTimeSlots: { time: string; remaining: number }[] = [];
  public selectedTimeSlot: string | undefined;
  public eventsLoading$ = this.eventLocationFacade.loading$;
  public serviceType: OnsiteRegistrationComboType;

  private takeUntil = new Subject<void>();

  constructor(
    private eventLocationFacade: EventLocationNgrxFacade,
    private dialogRef: MatDialogRef<RescheduleRegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { userRegistration: OnsiteUserRegistration; company: string }
  ) {}

  ngOnInit() {
    this.userRegistration = this.data.userRegistration;
    this.company = this.data.company;
    this.eventLocation$ = this.getEventLocation$();

    this.eventLocation$
      .pipe(takeUntil(this.takeUntil))
      .subscribe((eventLocation) => {
        if (eventLocation) {
          // Set Model for eventLocation drop down
          this.eventLocation = eventLocation;
          this.updateTimes();
        }
      });

    this.selectedEventLocationTimes$ = this.eventLocationFacade
      .getSingleEventStats$(this.eventLocation)
      .pipe(
        takeUntil(this.takeUntil),
        tap((eventLocationTimes) => ({ times: eventLocationTimes?.all || 0 }))
      );

    // Populate alternative service Type from userRegistration to get available times
    this.serviceType =
      UserRegistrationUtil.getOnsiteRegistrationComboTypesFromReg(
        this.userRegistration
      );

    this.eventLocationFacade.listByService({
      eventService: getId(
        (this.userRegistration as OnsiteUserRegistration)?.eventService || ''
      ),
      company: getId(this.company || '')
    });
  }

  private getEventLocation$(): Observable<EventLocation> {
    return this.eventLocationFacade.get$(
      getId(this.userRegistration?.eventLocation)
    );
  }

  /**
   * Time Slot Functions
   */
  updateTimes() {
    // Make sure event location has times from previous ngrx update
    if (this.eventLocation && this.selectedEventLocationTimes$) {
      // Get available times based on the selected eventLocation and onsiteServiceType
      this.selectedEventLocationTimes$
        .pipe(
          take(1),
          map(
            (eventLocationTimes) =>
              ({
                ...this.eventLocation,
                times: eventLocationTimes.all
              } as EventLocationWithTimes)
          )
        )
        .subscribe((eventLocationWithTimes) =>
          this.getAvailableSpots(eventLocationWithTimes)
        );
    }
  }

  /**  Returns the available registration slots for each time increment on the current eventLocation*/
  getAvailableSpots(eventLocation: EventLocationWithTimes) {
    const increments = TimeUtil.getIncrements({
      startTime: eventLocation.startTime,
      endTime: eventLocation.endTime
    });
    //Registered times fetched from backend
    const registrationTimes = eventLocation.times || [];
    // Create a list of all available times for the event with the amount of slots taken
    const times = increments.map((time) => {
      const regTime = registrationTimes.find(
        (regTime) => regTime.time === time
      );

      return regTime
        ? { time: time, taken: regTime.taken }
        : { time: time, taken: 0 };
    });

    const timeSlots = EventLocationUtil.getTotalSpotsByTime({
      eventLocation: eventLocation,
      registrationTimes: registrationTimes
    });

    const timeSlotsMap = toMap({ key: 'time', entities: timeSlots }) as Record<
      string,
      {
        time: string;
        flu: EventAlmostFullResponse;
        screening: EventAlmostFullResponse;
      }
    >;

    const availableSpots = times.map((registrationTime) => {
      const timeStats =
        timeSlotsMap[registrationTime.time] ||
        ({} as {
          time: string;
          flu: EventAlmostFullResponse;
          screening: EventAlmostFullResponse;
        });

      const screeningRemaining = timeStats?.screening?.remaining || 0;
      const fluRemaining = timeStats?.flu?.remaining || 0;

      if (
        this.serviceType === OnsiteRegistrationComboType.FLU_VACCINATION_ONLY ||
        this.serviceType === OnsiteRegistrationComboType.COVID_VACCINATION_ONLY
      ) {
        return { time: registrationTime.time, remaining: fluRemaining };
      }

      if (this.serviceType === OnsiteRegistrationComboType.SCREENING_ONLY) {
        return { time: registrationTime.time, remaining: screeningRemaining };
      }

      if (
        this.serviceType === OnsiteRegistrationComboType.SCREENING_AND_FLU ||
        this.serviceType === OnsiteRegistrationComboType.SCREENING_AND_COVID
      ) {
        return {
          time: registrationTime.time,
          remaining: Math.min(screeningRemaining, fluRemaining)
        };
      }

      return {
        time: registrationTime.time,
        remaining: 0
      };
    });

    this.availableTimeSlots = availableSpots;

    if (availableSpots.length === 1) {
      this.selectedTimeSlot = availableSpots[0].time;
    }

    // Preselect the current registration time, and if the event is switched and the same time slot is available, select that time as default
    const existingTime = availableSpots.find(
      (spot) => spot.time === this.userRegistration.eventTime
    );

    if (existingTime) {
      this.selectedTimeSlot = existingTime.time;
    }
  }

  public handleEventChange(eventLocation: EventLocation) {
    this.eventLocation = eventLocation;

    this.eventLocationFacade.getRegTimes({ eventLocation });

    this.selectedEventLocationTimes$ = this.eventLocationFacade
      .getSingleEventStats$(eventLocation)
      .pipe(tap((eventLocationTimes) => ({ times: eventLocationTimes.all })));

    this.updateTimes();
  }

  public updateRegistration() {
    const updatedUserRegistration: OnsiteUserRegistration = {
      ...this.userRegistration,
      eventLocation: getId(this.eventLocation),
      eventDate: this.eventLocation.eventDate,
      eventTime: this.selectedTimeSlot
    };

    this.dialogRef.close({
      userRegistration: updatedUserRegistration
    } as EhsRescheduleRegistrationDialogResponse);
  }

  getRegistrationType(userRegistration: OnsiteUserRegistration) {
    return UserRegistrationUtil.getOnsiteRegistrationType(
      UserRegistrationUtil.getOnsiteRegistrationTypeFromReg(userRegistration)
    );
  }

  /**
   * Utility function that returns the location-data for an event-location
   */
  public getDisplayLocation(locationData: LocationData | EventLocation) {
    return LocationDataUtil.getDisplayLocation({ locationData });
  }

  public onTimeSlotChange(radioButtonValue: MatRadioChange) {
    const eventTime = radioButtonValue.value as string;

    this.selectedTimeSlot = eventTime;
  }

  isRadioChecked(time: string) {
    if (!this.selectedTimeSlot || !this.eventLocation) {
      return false;
    }

    return time === this.selectedTimeSlot;
  }
}
