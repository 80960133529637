import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  BaseSiteUserRegistration,
  CustomPricing,
  EventService,
  EventServiceUtil,
  getId,
  OnsiteUserRegistration,
  ServiceType,
  toMap,
  User,
  UserTest,
  UserTestUtil
} from '@common';
import { AdminUpdateAdditionalTestsDialogData } from './admin-update-additional-tests-dialog-data';
import { isProviderHealthService } from '@common';

@Component({
  selector: 'ehs-admin-update-additional-tests-dialog',
  templateUrl: './ehs-admin-update-additional-tests-dialog.component.html',
  styleUrls: ['./ehs-admin-update-additional-tests-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsAdminUpdateAdditionalTestsDialogComponent implements OnInit {
  public userRegistration: BaseSiteUserRegistration;
  public eventService: EventService;
  public userTests: Record<string, UserTest>;
  public user: User;
  public additionalUserTests: Array<UserTest>;
  public selectedAdditionalTests: Array<UserTest>;

  public get service() {
    return EventServiceUtil.getSelectedService({
      eventService: this.eventService,
      serviceType: this.userRegistration?.serviceType
    });
  }

  private customPricingMap: { [key: string]: CustomPricing };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AdminUpdateAdditionalTestsDialogData,
    private dialogRef: MatDialogRef<EhsAdminUpdateAdditionalTestsDialogComponent>
  ) {}

  ngOnInit(): void {
    this.userRegistration = this.data.userRegistration;
    this.eventService = this.data.eventService;
    this.userTests = this.data.userTests;
    this.user = this.data.user;
    this.additionalUserTests = this.getAdditionalTests();
    this.selectedAdditionalTests = this.getSelectedAdditionalTests();

    this.customPricingMap = toMap({
      key: 'test',
      entities: this.eventService.customPricing || []
    });
  }

  /**
   * The list of all additional tests available to change
   */
  private getAdditionalTests() {
    const service = this.service;

    if (service.type === ServiceType.PROVIDER_HEALTH) {
      // Provider health doesn't provide additional tests
      return [];
    }

    const additionalTests = EventServiceUtil.getAdditionalTests({
      service,
      user: this.user,
      userTests: this.userTests,
      skipCotinineCheck: true,
      eventDate:
        (this.userRegistration as OnsiteUserRegistration)?.eventDate ||
        undefined
    });

    // Make a shallow copy just in-case to prevent mutation.
    return [...additionalTests].sort((a, b) =>
      UserTestUtil.getName(a).toLowerCase() <
      UserTestUtil.getName(b).toLowerCase()
        ? -1
        : 1
    );
  }

  /**
   * The currently selected additional tests,
   * can be updated and will be returned as-is.
   */
  private getSelectedAdditionalTests(): Array<UserTest> {
    if (!this.userRegistration?.additionalTests) {
      if (isProviderHealthService(this.service)) {
        return [];
      }

      return EventServiceUtil.getAdditionalTests({
        service: this.service,
        user: this.user,
        userTests: this.userTests,
        skipCotinineCheck: !!this.userRegistration.additionalTests,
        userRegistration: this.userRegistration,
        eventDate:
          (this.userRegistration as OnsiteUserRegistration)?.eventDate ||
          undefined
      });
    }

    return (this.userRegistration.additionalTests || [])
      .map((additionalTest) => this.userTests[getId(additionalTest)])
      .filter((_) => !!_);
  }

  getPrice(additionalTest: UserTest) {
    const customPrice = this.customPricingMap[getId(additionalTest)]?.cost;

    return typeof customPrice === 'number'
      ? customPrice
      : additionalTest.cost || 0;
  }

  getTestName(userTest: UserTest): string {
    return UserTestUtil.getName(userTest);
  }

  toggleAdditionalTest(additionalTest: UserTest) {
    const selectedAdditionalTestIds = this.selectedAdditionalTests.map(getId);

    if (selectedAdditionalTestIds.includes(getId(additionalTest))) {
      this.selectedAdditionalTests = this.selectedAdditionalTests.filter(
        (selectedAdditionalTest) =>
          getId(selectedAdditionalTest) !== getId(additionalTest)
      );

      return;
    }
    this.selectedAdditionalTests.push(additionalTest);
  }

  isAdditionalChecked(additionalTest: UserTest): boolean {
    return (this.selectedAdditionalTests || [])
      .map(getId)
      .includes(getId(additionalTest));
  }

  /**
   * Returns the newly selected additional tests
   */
  close() {
    this.dialogRef.close(this.selectedAdditionalTests);
  }
}
