import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventLocation, EventService, getId, Rack } from '@common';

const BASE_URL = '/api/v1/admin/rack';

@Injectable({
  providedIn: 'root'
})
export class AdminRackHttpService {
  constructor(private http: HttpClient) {}

  list(params: { eventLocationId: string; released?: boolean }) {
    const { eventLocationId, released } = params;
    const queryString =
      released !== undefined
        ? `eventLocationId=${eventLocationId}&released=${released}`
        : `eventLocationId=${eventLocationId}`;

    return this.http.get<{ racks: Rack[] }>(`${BASE_URL}/list?${queryString}`);
  }

  get(eventLocationId: string) {
    return this.http.get<Rack>(`${BASE_URL}/${eventLocationId}`);
  }

  create(rack: { eventService: EventService; eventLocation: EventLocation }) {
    return this.http.post<Rack>(`${BASE_URL}`, rack);
  }

  update(rack: Rack) {
    return this.http.put<Rack>(`${BASE_URL}/end/${getId(rack)}`, rack);
  }
}
