import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsUserIncentiveFiltersComponent } from './ehs-user-incentive-filters.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FlexLayoutModule } from 'ng-flex-layout';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [EhsUserIncentiveFiltersComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    PipesModule
  ],
  exports: [EhsUserIncentiveFiltersComponent]
})
export class EhsUserIncentiveFiltersModule {}
