import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ehs-card-separator',
  templateUrl: './ehs-card-separator.component.html',
  styleUrls: ['./ehs-card-separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsCardSeparatorComponent {
  /**
   * The Label Name
   */
  @Input() label?: string;
}
