import { Injectable, inject } from '@angular/core';

import { take, tap } from 'rxjs/operators';
import { AuthFacade } from '../../core/app-store/auth/auth.facade';
import { CanActivateFn } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
class PasswordResetRequestedGuardService {
  constructor(private authFacade: AuthFacade) {}

  canActivate() {
    // Check if true, if so automatically set it to false.
    return this.authFacade.inPasswordResetFlow$.pipe(
      take(1),
      tap((inPasswordResetFlow) => {
        if (inPasswordResetFlow) {
          this.authFacade.resetPasswordFlowDone();
        }
      })
    );
  }
}

export const PasswordResetRequestedGuard: CanActivateFn = () => {
  return inject(PasswordResetRequestedGuardService).canActivate();
};
