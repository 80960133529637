import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UserCriticalResult } from '@common';

interface UserCriticalsResultsTableColumn {
  title: string;
  key: 'resultName' | 'value' | 'rangeValues' | 'collectionDate';
  width: string;
}

@Component({
  selector: 'ehs-user-criticals-results-table',
  templateUrl: './ehs-user-criticals-results-table.component.html',
  styleUrls: ['./ehs-user-criticals-results-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsUserCriticalsResultsTableComponent {
  @Input() criticalResults: UserCriticalResult[];

  columns: UserCriticalsResultsTableColumn[] = [
    {
      title: 'Test Name',
      key: 'resultName',
      width: '300px'
    },
    {
      title: 'Result Value',
      key: 'value',
      width: '120px'
    },
    {
      title: 'Range Values',
      key: 'rangeValues',
      width: '120px'
    },
    {
      title: 'Collection Date',
      key: 'collectionDate',
      width: '120px'
    }
  ];
}
