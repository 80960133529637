import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { VpSamlUser } from '@common';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AuthHttpService } from '../http/auth-http.service';

/**
 * Protects the login and register routes from already authenticated users.
 */
@Injectable({
  providedIn: 'root'
})
class SamlLoginGuardService {
  constructor(
    private authHttpService: AuthHttpService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authHttpService.getSamlUser().pipe(
      take(1),
      catchError((_) => of(undefined)),
      map((samUser: VpSamlUser | undefined) => {
        if (!samUser) {
          return this.router.createUrlTree(['/login']);
        }

        return true;
      })
    );
  }
}

export const SamlLoginGuard: CanActivateFn = () => {
  return inject(SamlLoginGuardService).canActivate();
};
