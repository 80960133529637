import { ObjectId } from 'mongodb';
import { AdminUser } from './admin-user';
import { DbDocument } from './db-document';

export enum UserDeliveryContactType {
  FAX = 'fax',
  EMAIL = 'email',
  MAIL = 'mail',
  ONLINE = 'online',
  PHONE = 'phone'
}

export interface AdminNote extends DbDocument {
  /**
   * The user entered note
   */
  str: string;
  /**
   * The admin who created this note
   */
  admin: {
    _id: string | ObjectId | AdminUser;
    email: string;
  };
  /**
   * The date the user was contacted, admin entered
   * Saved as a date string
   */
  contactedAt?: string;
  /**
   * The way the user was contacted
   */
  deliveryTypes?: UserDeliveryContactType[];
  /**
   * When the note was created
   */
  createdAt: Date;
  /**
   * The last time this note was updated
   */
  updatedAt: Date;
}
