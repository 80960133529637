import { ObjectId } from 'mongodb';
import { Article } from './article';
import { DbDocument } from './db-document';
import { UserTest } from './user-test/user-test';

export enum HraArticleLinkType {
  QUESTION = 'question',
  ANSWER = 'answer'
}
/**
 * An hra-article-link is a document that describes
 * the relation between articles
 */
export interface HraArticleLink extends DbDocument {
  /**
   * The article we are referencing
   */
  article: string | ObjectId | Article;
  /**
   * The user-test we are referencing
   */
  userTest: string | ObjectId | UserTest;
  /**
   * The type of article link, either question or answer.
   * If its a question, then we load
   */
  hraType?: HraArticleLinkType;
  /**
   * The question/answer code.
   */
  code?: string;
}
