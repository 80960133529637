import { ObjectId } from 'mongodb';
import { DbDocument } from '../db-document';
import { Company } from './company';

export interface CompanySecurity extends DbDocument {
  /** Reference to the Company Record */
  company: string | ObjectId | Company;
  /**
   * This is the companies unique clientCode, unique amongst all companies.
   * This should be provided when the user signs up.
   */
  clientCode: string;

  /**
   * By default the EHS username/pwd sign in options is enabled. In case there is a customer
   * that requires only signing in with their SAML, Microsoft or Google credentials then we
   * can disable this setting to users don't see that option anymore.
   */
  disableEhsSignIn?: boolean;

  /**
   * List the additional sign in options that can be used for that customer.
   * This can be Signing in with Microsoft, Google or any other SAML authentication protocol
   */
  signInOptions: CompanySignInOptions[];

  /**
   * SSO Group Code - This code is required to authenticate via SAML. This used in the `group_code` SSO Assertion
   * to log users in to the right company.
   * Currently being used by CBS to authenticate their users using SAML to their different sub-companies.
   */
  ssoGroupCode?: string;

  /**
   * List of all domains allowed for registration for this company
   * E.g. If gwapps.com is listed here, then users signing in with a @gwapps.com email will be able to
   * automatically sign in without checking for the eligibility list.
   **/
  whitelistDomains: string[];

  /** Text to override the EHS username/pwd sign in button label */
  ehsBtnLabel?: string;
  /** Text to override the EHS help text shown above the sign in button */
  ehsHelpText?: string;
  /** Text to override the Microsoft sign in button label */
  microsoftBtnLabel?: string;
  /** Text to override the Microsoft help text shown above the sign in button */
  microsoftHelpText?: string;
  /** Text to override the Microsoft sign in button label */
  googleBtnLabel?: string;
  /** Text to override the Google help text shown above the sign in button */
  googleHelpText?: string;
  /** Flag to force company users to enable one MFA option to be able to login */
  forceMFA?: boolean;
}

export type CompanySecurityLabels =
  | 'ehsBtnLabel'
  | 'ehsHelpText'
  | 'microsoftBtnLabel'
  | 'microsoftHelpText'
  | 'googleBtnLabel'
  | 'googleHelpText';

/** Used for the frontend to return these properties */
export type CompanySecurityLogin = Pick<
  CompanySecurity,
  | 'clientCode'
  | 'disableEhsSignIn'
  | 'signInOptions'
  | 'ehsBtnLabel'
  | 'ehsHelpText'
  | 'microsoftBtnLabel'
  | 'microsoftHelpText'
  | 'googleBtnLabel'
  | 'googleHelpText'
>;

/** List the sign in options that are allowed to be used by that company */
export enum CompanySignInOptions {
  MICROSOFT = 'microsoft',
  GOOGLE = 'google',
  VIRGIN_PULSE = 'vp',
  CBS = 'cbs'
}
