import { BmiRange } from '../models/hra/hra-result';
import { UserTestRangeColors } from '../models/user-test/user-test-range-colors';

/**
 * @deprecated use UserTestRangeColors
 */
export enum BmiRangeColors {
  GREEN = '#62bda4',
  ORANGE = '#e89024',
  YELLOW = '#ebd682',
  RED = '#d15858'
}

/**
 * Utility function that calculates BMI based upon the users height and weight
 * bmi = weight / (height in inches) ^ 2) * 703
 */
export const getBmi = (params: {
  /**
   * Height in inches
   */
  height: number;
  /**
   * The user's weight in pounds
   */
  weight: number;
}) => {
  const { height, weight } = params;

  if (!height || !weight) {
    return undefined;
  }

  return (weight / Math.pow(height, 2)) * 703;
};

export const getBmiRange = (bmi: number): BmiRange | '' => {
  if (!bmi) {
    return '';
  }

  if (bmi < 18.5) {
    return BmiRange.UNDERWEIGHT;
  }

  if (bmi >= 18.5 && bmi < 25) {
    return BmiRange.HEALTHY_WEIGHT;
  }

  if (bmi >= 25 && bmi < 30) {
    return BmiRange.OVER_WEIGHT;
  }

  if (bmi >= 30) {
    return BmiRange.OBESE;
  }

  return '';
};

export const getBmiRangeValues = (bmi: number): string => {
  if (!bmi) {
    return '';
  }

  if (bmi < 18.5) {
    return '< 18.5';
  }

  if (bmi >= 18.5 && bmi < 25) {
    return '18.5 - 25';
  }

  if (bmi >= 25 && bmi < 30) {
    return '25 - 30';
  }

  if (bmi >= 30) {
    return '>= 30';
  }

  return '';
};

export const getBmiColor = (bmi: number): UserTestRangeColors => {
  switch (getBmiRange(bmi)) {
    case BmiRange.UNDERWEIGHT:
      return UserTestRangeColors.RED;
    case BmiRange.HEALTHY_WEIGHT:
      return UserTestRangeColors.GREEN;
    case BmiRange.OVER_WEIGHT:
      return UserTestRangeColors.YELLOW;
    case BmiRange.OBESE:
      return UserTestRangeColors.RED;
    default:
      return UserTestRangeColors.GREEN;
  }
};
