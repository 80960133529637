import { DbDocument } from './db-document';
import { User } from './user';
import { ObjectId } from 'mongodb';
import { ServiceLabType } from './event-service/service-lab-type';
import { UserRegistration } from './user-registration';
import { isDbDocument } from '../util/is-db-document';

export interface UserRequisition extends DbDocument {
  /**
   * The user's generated shortId. NOT their mongoId
   */
  patientId: string;
  /**
   * The "found"/parsed user related to this user requisition.
   * The user should have the same shortId as the patientId
   */
  user: User | string | ObjectId;
  /**
   * The lab-type passed.
   */
  labType: ServiceLabType;
  /**
   * The requisition PDF that the user
   * should take to the lab. Saved as base64
   */
  pdf: string;
  /**
   * The requisition number, should match with the
   * shortId from the user-registration
   */
  requisition: string;
  /**
   * The corresponding user-registration.
   * The user-registration should have the same shortId
   * as the requisition number in this user-requisition.
   */
  userRegistration: string | ObjectId | UserRegistration;

  /**
   * The status of migration of the requisition,if 'FAILED' or not existing,
   * the requisition pdf is not on the cloud storage bucket
   */
  migrationStatus?: UserRequisitionMigrationStatus;
}

export enum UserRequisitionMigrationStatus {
  UPLOADED = 'UPLOADED',
  FAILED = 'FAILED'
}

/**
 * Type-guard for user-requisitions, will actually logically accept any db-document, but
 * extra type-guards will help
 */
export const isUserRequisition = (
  userRequisition: string | ObjectId | UserRequisition
): userRequisition is UserRequisition =>
  isDbDocument<UserRequisition>(userRequisition);
