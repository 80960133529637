import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CLIENT_ADMIN_CORE_INJECTION_TOKEN } from '@ehs-ngrx/common';
import {
  EhsConfirmDialogModule,
  EhsEditUserDialogModule,
  EhsMergeUserDialogModule,
  EhsMoveUserCompanyDialogModule,
  EhsPasswordResetDialogModule,
  EhsVerifyEmailDialogModule,
  EhsAdminRestrictDataDialogModule
} from '@ehs-ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserNgrxProviderDirective } from './user-ngrx-provider.directive';
import { UserNgrxEffects } from './user-ngrx.effects';
import { userNgrxReducer, USER_STORE_KEY } from './user-ngrx.state';

@NgModule({
  declarations: [UserNgrxProviderDirective],
  imports: [
    CommonModule,
    StoreModule.forFeature(USER_STORE_KEY, userNgrxReducer),
    EffectsModule.forFeature([UserNgrxEffects]),
    MatSnackBarModule,

    // Dialogs
    EhsPasswordResetDialogModule,
    EhsConfirmDialogModule,
    EhsMoveUserCompanyDialogModule,
    EhsMergeUserDialogModule,
    EhsEditUserDialogModule,
    EhsVerifyEmailDialogModule,
    EhsAdminRestrictDataDialogModule
  ],
  exports: [UserNgrxProviderDirective]
})
export class UserNgrxModule {
  public static forFeature(config?: {
    clientAdmin: boolean;
  }): ModuleWithProviders<UserNgrxModule> {
    return {
      ngModule: UserNgrxModule,
      providers: [
        {
          provide: CLIENT_ADMIN_CORE_INJECTION_TOKEN,
          useValue: config?.clientAdmin
        }
      ]
    };
  }
}
