export class YoutubeUtil {
  public static getYoutubeIdFromUrl(url: string) {
    const youtubeIdRegex =
      /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;

    const r = (url || '').match(youtubeIdRegex);

    if (r && r?.length >= 2) {
      return r[1];
    }

    return undefined;
  }
}
