import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EhsAdminCreateNotificationDialogComponent } from './ehs-admin-create-notification-dialog.component';
import { Observable } from 'rxjs';
import { Notification } from '@common';
import { AdminCreateNotificationDialogData } from './admin-create-notification-dialog-data';

@Injectable()
export class EhsAdminCreateNotificationDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: AdminCreateNotificationDialogData
  ): Observable<Partial<Notification>> {
    return this.matDialog
      .open(EhsAdminCreateNotificationDialogComponent, {
        data,
        width: '450px',
        disableClose: true
      })
      .afterClosed();
  }
}
