import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EhsToolbarConfigHttpService } from './ehs-toolbar-config-http.service';

@Component({
  selector: 'ehs-toolbar-config',
  templateUrl: './ehs-toolbar-config.component.html',
  styleUrls: ['./ehs-toolbar-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsToolbarConfigComponent {
  public config$ = this.toolbarConfigHttp.getConfig$();
  constructor(private toolbarConfigHttp: EhsToolbarConfigHttpService) {}
}
