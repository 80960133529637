/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonResponse, GwApiUtilsFormMappings } from '@common';
import { entityHttpActionFactory } from '@ehs-ngrx/common';

/**
 * **Note** these actions are simplified from the admin-gw.actions version
 * in ng-portal
 */
export const adminGwNgrxActions = {
  sync: entityHttpActionFactory<
    {
      syncResource: keyof GwApiUtilsFormMappings;
      /**
       * If we are to use the gcf function, rather than internal one.
       * This is essentially a "client-side feature flag".
       *
       * Will be removed in the future.
       */
      gcf?: boolean;
    },
    {
      syncResource: keyof GwApiUtilsFormMappings;
      commonResponse: CommonResponse;
    },
    {
      syncResource: keyof GwApiUtilsFormMappings;
    }
  >('[ADMIN_GW_NGRX] SYNC'),

  viewAsJson: entityHttpActionFactory<{ title?: string; json: unknown }>(
    '[ADMIN_GW_NGRX] VIEW_AS_JSON'
  ),

  validateData: entityHttpActionFactory<
    {
      resource: keyof GwApiUtilsFormMappings;
      id: string;
    },
    {
      resource: keyof GwApiUtilsFormMappings;
      res: any;
    },
    {
      resource: keyof GwApiUtilsFormMappings;
    }
  >('[ADMIN_GW_NGRX] VALIDATE_DATA')
};
