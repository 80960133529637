/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { GwApiUtilsFormMappings } from '@common';
import { select, Store } from '@ngrx/store';
import { adminGwNgrxActions } from './admin-gw-ngrx.actions';
import { adminGwNgrxSelectors } from './admin-gw-ngrx.selectors';
import { ParentAdminGwNgrxState } from './admin-gw-ngrx.state';

@Injectable()
export class AdminGwNgrxFacade {
  /**
   * If there is any sync, or action loading for this state.
   */
  public loading$ = this.store.pipe(
    select(adminGwNgrxSelectors.loadingSelector)
  );

  constructor(private store: Store<ParentAdminGwNgrxState>) {}

  /**
   * Syncs the given resource. Can use the old way (for now)
   * or the new gcf function sync.
   *
   * **note** conditional-tests are included with event-services in the new way.
   * So if `gcf:true`, then `conditional-tests` will be ignored!
   */
  public sync(syncResource: keyof GwApiUtilsFormMappings, gcf?: boolean) {
    console.log('syncing with gcf: ', gcf);

    this.store.dispatch(
      adminGwNgrxActions.sync.req({
        syncResource,
        gcf
      })
    );
  }

  /**
   * Dispatches an action to display the given object
   * as a JSON object within a dialog. Good for
   * debugging entities.
   */
  public viewAsJson(json: any) {
    this.store.dispatch(
      adminGwNgrxActions.viewAsJson.req({
        title: 'View Raw Data',
        json
      })
    );
  }

  /**
   * Validates the given object from gwapps,
   * and displays it in the "json" dialog
   */
  public validateData(params: {
    id: string;
    resource: keyof GwApiUtilsFormMappings;
  }) {
    const { id, resource } = params;

    this.store.dispatch(
      adminGwNgrxActions.validateData.req({
        id,
        resource
      })
    );
  }
}
