import { LabResultCode } from '../models/user-test/user-test';

/**
 * Result Codes for HDL
 */
export const EHS_HDL_RESULT_CODE = LabResultCode('0002');
export const LABCORP_HDL_RESULT_CODE = LabResultCode('011817');
export const QUEST_HDL_RESULT_CODE = LabResultCode('25015900');

/**
 * Result Codes for Cholesterol
 */
export const EHS_CHOLESTEROL_RESULT_CODE = LabResultCode('0001');
export const LABCORP_CHOLESTEROL_RESULT_CODE = LabResultCode('001065');
export const QUEST_CHOLESTEROL_RESULT_CODE = LabResultCode('25003000');

/**
 * Result codes for CHOL/HDL ratio
 */
export const EHS_CHOL_RATIO_RESULT_CODE = LabResultCode('0006');
export const LABCORP_CHOL_RATIO_RESULT_CODE = LabResultCode('100065');
export const QUEST_CHOL_RATIO_RESULT_CODE = LabResultCode('25017000');
