import { AdminUser } from '@common';
import { entitySelectorsFactory } from '@ehs-ngrx/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AdminCoreNgrxState,
  ADMIN_CORE_NGRX_STORE_KEY,
  ParentAdminCoreNgrxState
} from './admin-core-ngrx.state';

export const adminCoreNgrxFeatureSelector =
  createFeatureSelector<AdminCoreNgrxState>(ADMIN_CORE_NGRX_STORE_KEY);

const baseSelectors = entitySelectorsFactory<
  AdminUser,
  string,
  ParentAdminCoreNgrxState,
  AdminCoreNgrxState
>({
  featureSelector: adminCoreNgrxFeatureSelector
});

const adminUserSelector = createSelector(
  adminCoreNgrxFeatureSelector,
  (state) => state.user
);

const adminUserScopesSelector = createSelector(adminUserSelector, (user) =>
  user ? user.scopes : []
);

export const adminCoreNgrxSelectors = {
  ...baseSelectors,

  adminUserSelector: createSelector(
    adminCoreNgrxFeatureSelector,
    (state) => state.user
  ),
  platformStatsSelector: createSelector(
    adminCoreNgrxFeatureSelector,
    (state) => state.stats
  ),
  dateRangeSelector: createSelector(
    adminCoreNgrxFeatureSelector,
    (state) => state.dateRange
  ),
  adminUserScopesSelector
};
