import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { EhsGwappsActionsModule } from '../ehs-gwapps-actions/ehs-gwapps-actions.module';
import { EhsGwappsEventComponent } from './ehs-gwapps-event.component';
import { EhsCardLabelModule } from '../ehs-card-label/ehs-card-label.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [EhsGwappsEventComponent],
  imports: [
    CommonModule,
    RouterModule,
    // Angular Material
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,

    // Child modules
    EhsGwappsActionsModule,
    EhsCardLabelModule
  ],
  exports: [EhsGwappsEventComponent]
})
export class EhsGwappsEventModule {}
