import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  User,
  UserHomeTest,
  UserHomeTestForm,
  UserRegistrationId
} from '@common';
import { ObjectId } from 'mongodb';

const BASE_URL = '/api/v1/admin/home-test';

@Injectable({
  providedIn: 'root'
})
export class AdminUserHomeTestHttpService {
  constructor(private http: HttpClient) {}

  list(params: UserHomeTestForm) {
    return this.http.get<{ userHomeTests: UserHomeTest[]; hasNext: boolean }>(
      `${BASE_URL}/list`,
      {
        params: Object.entries(params).reduce(
          (_params, [key, value]) =>
            value ? _params.append(key, '' + value) : _params,
          new HttpParams()
        )
      }
    );
  }

  get(id: string) {
    return this.http.get<UserHomeTest>(`${BASE_URL}/${id}`);
  }

  create(params: {
    userRegistrationId: UserRegistrationId;
    userId: User | ObjectId | string;
  }) {
    return this.http.post<UserHomeTest>(`${BASE_URL}`, params);
  }

  update(userHomeTest: UserHomeTest) {
    return this.http.put<UserHomeTest>(`${BASE_URL}`, userHomeTest);
  }
}
