import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsUserReimbursementUploadsTableComponent } from './ehs-user-reimbursement-uploads-table.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [EhsUserReimbursementUploadsTableComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ScrollingModule,
    MatIconModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule
  ],
  exports: [EhsUserReimbursementUploadsTableComponent]
})
export class EhsUserReimbursementUploadsTableModule {}
