import { DbDocument, getId } from '@common';
import { EntityState } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';

/**
 * Factory function used to generate a number of useful
 * selectors for an entity+loading state.
 */
export const entitySelectorsFactory = <
  /**
   * The document type, which must have an `id` property
   */
  Document extends DbDocument<DocumentKey>,
  DocumentKey extends string,
  AppState,
  DocumentState extends EntityState<Document> & { loading?: boolean }
>({
  featureSelector
}: {
  featureSelector: MemoizedSelector<AppState, DocumentState>;
}) => {
  const entitiesSelector = createSelector(
    featureSelector,
    (state) => state.entities as Record<DocumentKey, Document>
  );
  const entitiesArraySelector = createSelector(
    entitiesSelector,
    (entities) => Object.values(entities || {}) as Array<Document>
  );

  return {
    /**
     * The feature selector... for this feature
     */
    featureSelector,
    /**
     * Selector that returns the ids
     */
    idsSelector: createSelector(featureSelector, (state) => state.ids),
    /**
     * Selector that returns all the entities in the state
     */
    entitiesSelector,
    /**
     * Selector that returns if entities are loading in the state
     */
    loadingSelector: createSelector(
      featureSelector,
      (state) => !!state.loading
    ),
    /**
     * Selector that returns all entities as an array.
     * Useful for filtering/resorting
     */
    entitiesArraySelector,
    /**
     * Selector that returns a selector factory that can be used to
     * get a single entity by its _id from the state
     */
    entitySelectorFactory: (entityId: DocumentKey) =>
      createSelector(
        entitiesSelector,
        (entities) => entities[getId(entityId)] as Document
      )
  };
};
