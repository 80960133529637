import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailName'
})
export class EmailNamePipe implements PipeTransform {
  /**
   * Return the name part of the email.
   * For example: john.smith@example.com will return John Smith
   */
  transform(email: string | undefined): string {
    if (!email) {
      return '';
    }

    return (email || '').substring(0, email.indexOf('@')).split('.').join(' ');
  }
}
