import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsEditUserDialogComponent } from './ehs-edit-user-dialog.component';
import { EhsEditUserDialogData } from './ehs-edit-user-dialog.data';
import { EhsEditUserDialogResponse } from './ehs-edit-user-dialog.response';

@Injectable()
export class EhsEditUserDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: EhsEditUserDialogData
  ): Observable<EhsEditUserDialogResponse | false> {
    return this.matDialog
      .open(EhsEditUserDialogComponent, { data, maxWidth: '800px' })
      .afterClosed();
  }
}
