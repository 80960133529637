<form [formGroup]="faxForm"
      (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Send Fax</h1>
  <mat-dialog-content fxLayout="column"
                      fxLayoutGap="12px"
                      class="mat-typography dialog-content">
    <mat-form-field>
      <mat-label>Fax Number</mat-label>
      <input type="tel"
             matInput
             required
             formControlName="phone"
             placeholder="555-555-1234">
      <mat-error *ngIf="faxForm.get('phone').hasError('invalidNumber')">
        Phone number is invalid
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Confirm Fax Number</mat-label>
      <input type="tel"
             matInput
             required
             formControlName="confirmPhone"
             placeholder="555-555-1234">
      <mat-error *ngIf="faxForm.get('confirmPhone').hasError('invalidNumber')">
        Phone number is invalid
      </mat-error>
      <mat-error *ngIf="faxForm.get('confirmPhone').hasError('matchValidator')">
        Phone number does not match
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end"
                      fxLayoutGap="16px">
    <button type="button"
            class="grey-cancel"
            mat-button
            [mat-dialog-close]="false">
      Cancel
    </button>
    <button type="submit"
            color="primary"
            mat-button
            cdkFocusInitial>
      Send
    </button>
  </mat-dialog-actions>
</form>