import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { EhsToolbarConfigHttpService } from './ehs-toolbar-config-http.service';
import { EhsToolbarConfigComponent } from './ehs-toolbar-config.component';

@NgModule({
  declarations: [EhsToolbarConfigComponent],
  imports: [CommonModule, FlexLayoutModule, MatChipsModule],
  exports: [EhsToolbarConfigComponent],
  providers: [EhsToolbarConfigHttpService]
})
export class EhsToolbarConfigModule {}
