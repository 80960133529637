import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { initializeApp } from 'firebase/app';
// We only use firebase auth
import { initializeAuth } from 'firebase/auth';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const app = initializeApp({
  apiKey: environment.firebaseApiKey,
  authDomain: environment.firebaseAuthDomain
});

initializeAuth(app);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
