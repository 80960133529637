import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsUserCriticalsResultsTableComponent } from './ehs-user-criticals-results-table.component';
import { UserTestRangeValuesModule } from '../../pipes/user-test-range-values/user-test-range-values.module';

@NgModule({
  declarations: [EhsUserCriticalsResultsTableComponent],
  imports: [CommonModule, UserTestRangeValuesModule],
  exports: [EhsUserCriticalsResultsTableComponent]
})
export class EhsUserCriticalsResultsTableModule {}
