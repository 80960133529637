import { NewUserFingerStickFormRequest } from './new-user-finger-stick-form.request';
import { NormalFingerStickFormRequest } from './normal-finger-stick-form.request';
import { WalkInFingerStickFormRequest } from './walk-in-finger-stick-form.request';
import { ObjectId } from 'mongodb';
import { UserResult } from '../../models/user-result/user-result';

export enum FingerStickFormFlow {
  /**
   * A normal flow includes the following
   * - existing user
   * - existing user-registration
   */
  NORMAL = 'normal',
  /**
   * This flow is decided if the admin selects
   * the "new user" option
   */
  NEW_USER = 'new_user',
  /**
   * A walk in is defined as a user who **exists**
   * but doesn't have a user-registration. This is
   * decided if the admin selects "none" as the user-registration.
   */
  WALK_IN = 'walk_in'
}
/**
 * This is a few different form requests depending on the `flow` property.
 *
 * **note** this is almost 100% tied only to the last few finger-stick-test form properties
 */
export type FingerStickFormRequest =
  | NormalFingerStickFormRequest
  | NewUserFingerStickFormRequest
  | WalkInFingerStickFormRequest;

/**
 * This is the base form data required to create a
 * "finger-stick" user-result.
 */
export interface BaseFingerStickFormRequest {
  /**
   * The flow determines which kind of form request is being used.
   */
  flow: FingerStickFormFlow;
  /**
   * The user-result that we want to edit.
   * This can be added/provided and we will update
   * this result instead of creating a new one
   */
  userResult?: string | ObjectId | UserResult;
  /**
   * The date of the collection
   */
  collectionDate: string;
  /**
   * The time of the collection
   */
  collectionTime: string;
  /**
   * The date and time the result was reported
   */
  reportedDate: string;
  /**
   * The time of the reported
   */
  reportedTime: string;
  /**
   * Finger-stick serial number
   */
  serialNumber: string;
  /**
   * The Finger-stick lot number - alphanumeric
   */
  lotNumber: string;
  /**
   * Machine number of the Finger-stick - alphanumeric
   */
  machineNumber: string;
  /**
   * TODO: is this actually saved anywhere on the user-registration? Or should we add it to a special place?
   */
  hoursFasted: number;
  /**
   * The total cholesterol
   * TODO: what is the difference between this and the totalCholesterolRatio?
   */
  totalCholesterol: number;
  /**
   * High-density lipoprotein
   */
  hdl: number;
  /**
   * TODO: what unit is this in?
   */
  triglycerides: number;
  /**
   * TODO: what unit is this in?
   */
  glucose: number;
  /**
   * Low Density lipoprotine
   */
  ldl: number;
  /**
   * TODO: what is the difference between this and totalCholesterol?
   */
  totalCholesterolRatio: number;
  /**
   * NonHDL Cholesterol
   */
  nonHdlCholesterol: number;
  /**
   * Height in inches
   * This should be calculated to just `height` later.
   */
  heightFeet: number;
  /**
   * Height in inches. 0-11
   * This should be calculated to just `height` later.
   */
  heightInches: number;
  /**
   * Weight in pounds
   */
  weight: number;
  /**
   * This is **asked for** and **not** automated
   */
  bmi: number;
  /**
   * Body Composition Analysis (BCA)
   */
  bca: number;
  /**
   * Inches
   */
  waist: number;
  /**
   * Inches
   */
  hip: number;
  /**
   * "Top" number
   */
  bloodPressureSystolic: number;
  /**
   * "Bottom" number
   */
  bloodPressureDiastolic: number;
  /**
   * Self reported tobacco usage
   */
  usesTobacco: boolean;
}
