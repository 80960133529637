import { UserTest } from '@common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { selectId } from '../utils/select-id';
import { userTestActions } from './user-test.actions';

export interface UserTestState extends EntityState<UserTest> {
  loading?: boolean;
}

const adapter = createEntityAdapter<UserTest>({
  selectId
});

const reducer = createReducer<UserTestState>(
  adapter.getInitialState({} as UserTestState),
  on(userTestActions.get, (state) => ({ ...state, loading: true })),
  on(userTestActions.getSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(userTestActions.getFailed, (state) => ({ ...state, loading: false })),

  on(userTestActions.list, (state) => ({ ...state, loading: true })),
  on(userTestActions.listSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(userTestActions.listFailed, (state) => ({ ...state, loading: false })),

  on(userTestActions.listAsAdmin, (state) => ({ ...state, loading: true })),
  on(userTestActions.listAsAdminSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(userTestActions.listAsAdminFailed, (state) => ({
    ...state,
    loading: false
  }))
);

export function UserTestReducer(state: UserTestState, action: Action) {
  return reducer(state, action);
}
