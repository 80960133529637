import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  AdminUser,
  getId,
  OnsiteService,
  OnsiteUserRegistration,
  Rack,
  User,
  UserRegistration,
  UserVaccination,
  Company,
  EventLocation
} from '@common';
import cloneDeep from 'clone-deep';
import { EhsRegTableColumn } from '../ehs-reg-table/ehs-reg-table-column';
import {
  EhsRegFilters,
  OffsiteEhsRegFilters,
  OnsiteEhsRegFilters,
  ProviderEhsRegFilters
} from './ehs-reg-filters';

@Component({
  selector: 'ehs-reg-filters',
  templateUrl: './ehs-reg-filters.component.html',
  styleUrls: ['./ehs-reg-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsRegFiltersComponent<
  AbstractUserRegistration extends UserRegistration = UserRegistration
> {
  /**
   * The columns that are displayed in the table, which will also
   * be used to filter against the data, using the same columns.
   *
   * Internally these are used to calculate properties to show
   * in the template.
   */
  @Input() columns: Array<EhsRegTableColumn<AbstractUserRegistration>> = [];
  /**
   * The list of admin-users we will display in the "admin-search", selection box.
   * This is currently used just for onsite-events, but could be re-used
   * for other situations
   */
  @Input() admins: Array<AdminUser> = [];
  /**
   * The list of racks we will display in the "rack-search", selection box.
   * These are determined by current admin-user and eventLocation. Need to add usability options for selection between released, not-released, active (if there is a currentRack)
   * This is currently used just for onsite-events, but could be re-used
   * for other situations
   */
  @Input() racks: Array<Rack> = [];

  /** Change filters if called from client admin library */
  @Input() clientAdmin?: boolean;
  /**
   * The filters we are to use as a basis.
   */
  @Input() set filters(filters: Partial<EhsRegFilters>) {
    if (this.clientAdmin) {
      this.baseFilters = {};
    } else {
      this.baseFilters = cloneDeep(filters);
    }
  }

  public service: OnsiteService;

  /**
   * The actual internal filters we manipulate.
   * Done as to prevent issues with manipulating passed filters from ngrx/query-params.
   *
   * **note** this is the abstract base type, use the more specific versions for
   * typed varients on the use-case basis
   */
  public baseFilters: Partial<EhsRegFilters> = {};
  /**
   * Type-casted onsite-filters
   */
  public get onsiteFilters() {
    return this.baseFilters as Partial<OnsiteEhsRegFilters>;
  }

  /**
   * Type-casted offsite-filters
   */
  public get offsiteFilters() {
    return this.baseFilters as Partial<OffsiteEhsRegFilters>;
  }

  /**
   * Type-casted offsite-filters
   */
  public get providerFilters() {
    return this.baseFilters as Partial<ProviderEhsRegFilters>;
  }

  /**
   * The Company
   */
  @Input() company?: Company;

  /**
   * The Event
   */
  @Input() eventLocation?: Partial<EventLocation>;

  /**
   * The User
   */
  @Input() user?: User;

  @Input() userVaccination: UserVaccination | null = null;

  /**
   * Event emitted on any filter (change) event, IE, usually
   * when the admin clicks away on the filter.
   */
  @Output() filtersChange = new EventEmitter<Partial<EhsRegFilters>>();
  /**
   * Event emitted on any filter
   */
  @Output() filtersKeyup = new EventEmitter<Partial<EhsRegFilters>>();
  /**
   * Event emitted on any filter
   */
  @Output() refresh = new EventEmitter();

  @Output() fluLotNumber = new EventEmitter();

  @Output() fluName = new EventEmitter();
  /**
   * Event emitter to add vaccination information
   */
  @Output() editingVaccination = new EventEmitter<string | undefined>();

  @Output() userVaccinationChange = new EventEmitter<
    Partial<UserVaccination>
  >();

  /**
   * We show the search, if any fields related to searching are
   * shown.
   *
   * **note** currently only searches against users, may change in the future to
   * include more fields.
   */
  public get showSearch(): boolean {
    return !!this.columns.find((column) =>
      (
        ['firstName', 'lastName', 'ssn', 'email'] as Array<
          EhsRegTableColumn['prop']
        >
      )
        // **Note** not sure why I need to re-case this?
        .includes(column.prop as EhsRegTableColumn['prop'])
    );
  }

  /**
   * If we are to show "date-fields", of `startDate` and `endDate`.
   * These are applied against the user-registration's `eventDate` property
   */
  public get showDateFields(): boolean {
    return !!this.columns.find((column) =>
      (
        ['eventDate'] as Array<
          EhsRegTableColumn<OnsiteUserRegistration>['prop']
        >
      ).includes(
        column.prop as EhsRegTableColumn<OnsiteUserRegistration>['prop']
      )
    );
  }

  /**
   * If we are to show the admin-search field,
   * these are applied against the user-registrations' reviewedBy and releasedBy properties.
   */
  public get showAdminSearch(): boolean {
    return !!this.columns.find((column) =>
      (
        [
          'releasedAt',
          'reviewedAt',
          'releasedBy',
          'reviewedBy',
          'fluReviewedAt'
        ] as Array<EhsRegTableColumn<OnsiteUserRegistration>['prop']>
      ).includes(
        column.prop as EhsRegTableColumn<OnsiteUserRegistration>['prop']
      )
    );
  }

  /**
   * Check if event is both screening and a vaccination
   */
  public get showServiceFilter(): boolean {
    return !!this.columns.find((column) =>
      (
        ['vaccination', 'screening'] as Array<
          EhsRegTableColumn<OnsiteUserRegistration>['prop']
        >
      ).includes(
        column.prop as EhsRegTableColumn<OnsiteUserRegistration>['prop']
      )
    );
  }

  /**
   * If we are to show the rack-search field, using the rackNumber property, options should be filtered by eventLocation, current adminUser, and released
   * ? Is this all I need?
   */
  public get showRackSearch(): boolean {
    return !!this.columns.find((column) => {
      return column.prop === 'rackNumber';
    });
  }

  /**
   * Wrapper around `filtersChange` emits. Will take updates, and
   * merge them with `this._filters` then emit.
   *
   * **note** this doesn't change the `_filters`, as that should be done
   * from the parent component
   */
  public onFiltersChange(updates: Partial<EhsRegFilters>): void {
    if (updates.released === 'all') {
      updates.released = undefined;
    }

    this.filtersChange.emit({
      ...this.baseFilters,
      ...updates
    });
  }

  public handleRefresh() {
    this.refresh.emit();
  }

  /**
   * Wrapper around `filtersKeyup` emits. Will take updates, and
   * merge them with `this._filters` then emit.
   *
   * **note** this doesn't change the `_filters`, as that should be done
   * from the parent component
   */
  public onFiltersKeyup(updates: Partial<EhsRegFilters>): void {
    this.filtersKeyup.emit({
      ...this.baseFilters,
      ...updates
    });
  }

  public compareAdminFn(a: AdminUser | string, b: AdminUser | string) {
    return getId(a) === getId(b);
  }

  public compareRackFn(a: Rack | string, b: Rack | string) {
    return getId(a) === getId(b);
  }

  public onVaccinationChange(params: {
    change?: UserVaccination;
    company: Company;
    user: User;
    eventLocation: Partial<EventLocation>;
  }) {
    const { company, user, eventLocation } = params;

    if (company && user && eventLocation) {
      const vaccinationArm = {
        company,
        user,
        vaccinationLotNumber: eventLocation.fluLot,
        vaccinationExpDate: eventLocation.fluVacExpDate,
        vaccinationName: eventLocation.fluVacName,
        vaccinationEventDate: eventLocation.eventDate
      } as Partial<UserVaccination>;

      this.userVaccinationChange.emit({ ...vaccinationArm });
    }
  }
}
