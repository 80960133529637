import { Injectable } from '@angular/core';
import { PhoneUtil, UserRequisition } from '@common';

@Injectable({
  providedIn: 'root'
})
export class UserReqPdfService {
  /**
   * Generates and downloads the data from the user-req.
   * This is more of an abstraction around the same pdf rendering methods.
   */
  public generateAndDownload(params: {
    userRequisition: UserRequisition;
  }): void {
    const { userRequisition } = params;

    if (!userRequisition || !userRequisition?.pdf) {
      return;
    }

    if (PhoneUtil.isSafari()) {
      PhoneUtil.openBase64Pdf(userRequisition.pdf);

      return;
    }

    const downloadLinkElement: HTMLAnchorElement = document.createElement('a');

    downloadLinkElement.href = `data:application/pdf;base64,${userRequisition.pdf}`;
    downloadLinkElement.download = `lab-doc-${new Date().getTime()}.pdf`;
    downloadLinkElement.click();
    downloadLinkElement.remove();
  }

  public downloadFromBase64(params: { data: string }): void {
    const { data } = params;

    if (!data) {
      return;
    }

    if (PhoneUtil.isSafari()) {
      PhoneUtil.openBase64Pdf(data);

      return;
    }

    const downloadLinkElement: HTMLAnchorElement = document.createElement('a');

    downloadLinkElement.href = `data:application/pdf;base64,${data}`;
    downloadLinkElement.download = `lab-doc-${new Date().getTime()}.pdf`;
    downloadLinkElement.click();
    downloadLinkElement.remove();
  }

  /**
   * Returns a buffer of the base64 string.
   * This primarily exists for API compatibility with the merge-pdf service.
   */
  public generate(params: { userRequisition: UserRequisition }) {
    const { userRequisition } = params;

    return Uint8Array.from(atob(userRequisition.pdf), (c) => c.charCodeAt(0));
  }
}
