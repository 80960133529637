import {
  getId,
  isOffsiteUserRegistration,
  isOnsiteUserRegistration,
  UserRegistration,
  UserRegistrationStatus
} from '@common';

/**
 * The enum passed around for a user-registration. Which are available change depending
 * on the service
 *
 * 1. pending-review - if an admin hasn't reviewed the record at all, should create a user-result
 * 2. pending-release - if an admin has released this record to lifepoint
 *   - only applies to onsite-services with lab-type set
 * 3. pending-req - waiting for the user-requsition to be returned by lifepoint
 *   - only applies to onsite-services with lab-type set
 * 4. complete - once user-result is created **and** if lab-data is set in user-result.
 *   - this has different behavior depending on if there is an onsite-lab or not.
 * 5. pending_payment - In offsite when someone registered and has to pay, then it is pending payment
 *    until the user pays and the lab is released.
 */
export enum AdminEventRegStatus {
  PENDING_REVIEW = 'pending_review',
  PENDING_PAYMENT = 'pending_payment',
  PENDING_RELEASE = 'pending_release',
  PENDING_REQ = 'pending_req',
  COMPLETE = 'complete',
  NO_SHOW = 'no_show',
  CANCELED = 'canceled'
}

export interface AdminEventRegStatusDef {
  /**
   * The icon to display in the table
   */
  icon: string;
  /**
   * The color of the icon to apply
   */
  color: string;
  /**
   * The title, or "end user" friendly text
   */
  title: string;
}
/**
 * Map of admin-event-reg-status to defs
 */
export const adminEventRegStatusDefMap: Record<
  AdminEventRegStatus,
  AdminEventRegStatusDef
> = {
  [AdminEventRegStatus.PENDING_REVIEW]: {
    icon: 'pending_actions',
    color: 'grey',
    title: 'Pending Review'
  },
  [AdminEventRegStatus.PENDING_PAYMENT]: {
    icon: 'payment',
    color: 'orange',
    title: 'Pending Payment'
  },
  [AdminEventRegStatus.PENDING_RELEASE]: {
    icon: 'send',
    color: 'orange',
    title: 'Pending Release'
  },
  [AdminEventRegStatus.PENDING_REQ]: {
    icon: 'description',
    color: 'orange',
    title: 'Pending Requisition'
  },
  [AdminEventRegStatus.COMPLETE]: {
    icon: 'done',
    color: 'green',
    title: 'Complete'
  },
  [AdminEventRegStatus.NO_SHOW]: {
    icon: 'no_accounts',
    color: '#f44336',
    title: 'No Show'
  },
  [AdminEventRegStatus.CANCELED]: {
    icon: 'cancel',
    color: '#f44336',
    title: 'Canceled'
  }
};

/**
 * Returns the calculated status to show in the table depending
 * on the user-registrations factors.
 *
 */
export const getRegistrationStatus = (params: {
  userRegistration: UserRegistration;
}): AdminEventRegStatus | undefined => {
  const { userRegistration } = params;

  if (!userRegistration) {
    return undefined;
  }

  if (isOnsiteUserRegistration(userRegistration)) {
    if (userRegistration.status === UserRegistrationStatus.NO_SHOW) {
      return AdminEventRegStatus.NO_SHOW;
    }

    if (userRegistration.status === UserRegistrationStatus.CANCELED) {
      return AdminEventRegStatus.CANCELED;
    }

    if (!userRegistration.reviewedAt) {
      return AdminEventRegStatus.PENDING_REVIEW;
    }

    if (!userRegistration.releasedAt) {
      return AdminEventRegStatus.PENDING_REQ;
    }

    if (!getId(userRegistration.userRequisition)) {
      return AdminEventRegStatus.PENDING_RELEASE;
    }

    return AdminEventRegStatus.COMPLETE;
  }

  if (isOffsiteUserRegistration(userRegistration)) {
    if (userRegistration.status === UserRegistrationStatus.PENDING) {
      return AdminEventRegStatus.PENDING_PAYMENT;
    }

    return AdminEventRegStatus.COMPLETE;
  }

  return undefined;
};
