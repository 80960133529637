import { UserRegistrationId } from '../user-registration/base-user-registration';
import { HpnImportAuditId } from './hpn-import-audit';

/**
 * Represents a "check" data/request that can be used to display/warn
 * against reverting an hpn-import.
 * This isn't saved anywhere, and is just calculated on the fly before
 * an actual revert occurs to warn/prompt the admin before an action.
 * This is again checked during the revert, to prevent any accidental
 * reverts after the check was made.
 */
export interface HpnImportCheck {
  /**
   * The reference to the audit, used to update the client-side more easily on response,
   * and for debugging.
   */
  audit: HpnImportAuditId;

  /**
   * The list of user-registrations that have been created "against"
   * this import. Usually if there is any, then we **can't** revert,
   * as that would mean losing user data.
   */
  userRegistrations: UserRegistrationId[];

  /**
   * The ids of users that have been created "against" this import,
   * this includes partial and full users.
   *
   * See the other attributes.
   */
  users: string[];

  /**
   * The list of partial users that have been created by this import.
   * By default all users imported are "partial".
   * But if a user signs up through the portal and their account is "matched"
   * with an existing partial user, this user will no longer be "partial"
   */
  partialUsers: string[];

  /**
   * The list of "full-users", or user's that aren't partial.
   *
   * If there are any of these, then we **can't** revert, as
   * that would mean user accounts would be lost.
   */
  fullUsers: string[];

  /**
   * The ids of the user-results that would be removed.
   *
   * These can be removed directly, assuming an import can be made again, but
   * this isn't the platforms problem to deal with.
   */
  userResults: string[];
}

/**
 * Helper function that can be used to quickly determine if the hpn-import-check
 * data is "valid" or not.
 */
export const isValidHpnImportCheck = (check: HpnImportCheck): boolean =>
  check && !check.userRegistrations.length && !check.fullUsers.length;
