export enum DashboardWidgetEnum {
  USER_REGISTRATION = 'user-reg',
  ADDED_USER_REGISTRATION = 'added-user-reg',
  INCENTIVES = 'incentives',
  INCENTIVES_CHALLENGES = 'incentives_challenges',
  INCENTIVES_EMPOWERMENT_PLAN = 'incentives_empowerment_plan',
  REIMBURSEMENTS = 'reimbursements',
  PROFILE = 'profile',
  TEST_RESULTS = 'test-results',
  FLU_VACCINATION = 'flu',
  COVID_VACCINATION_CARD = 'covid-vaccination-card',
  HEALTH_PROGRAMS = 'health_programs',
  HRA = 'hra',
  COACHING = 'coaching',
  EMMY = 'emmy',
  RESOURCES = 'resources'
}
