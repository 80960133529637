/**
 * List of all states
 */
export enum CountyCodes {
  MN001 = 'MN001',
  MN003 = 'MN003',
  MN005 = 'MN005',
  MN007 = 'MN007',
  MN009 = 'MN009',
  MN011 = 'MN011',
  MN013 = 'MN013',
  MN015 = 'MN015',
  MN017 = 'MN017',
  MN019 = 'MN019',
  MN021 = 'MN021',
  MN023 = 'MN023',
  MN025 = 'MN025',
  MN027 = 'MN027',
  MN029 = 'MN029',
  MN031 = 'MN031',
  MN033 = 'MN033',
  MN035 = 'MN035',
  MN037 = 'MN037',
  MN039 = 'MN039',
  MN041 = 'MN041',
  MN043 = 'MN043',
  MN045 = 'MN045',
  MN047 = 'MN047',
  MN049 = 'MN049',
  MN051 = 'MN051',
  MN053 = 'MN053',
  MN055 = 'MN055',
  MN057 = 'MN057',
  MN059 = 'MN059',
  MN061 = 'MN061',
  MN063 = 'MN063',
  MN065 = 'MN065',
  MN067 = 'MN067',
  MN069 = 'MN069',
  MN071 = 'MN071',
  MN073 = 'MN073',
  MN075 = 'MN075',
  MN077 = 'MN077',
  MN079 = 'MN079',
  MN081 = 'MN081',
  MN083 = 'MN083',
  MN085 = 'MN085',
  MN087 = 'MN087',
  MN089 = 'MN089',
  MN091 = 'MN091',
  MN093 = 'MN093',
  MN095 = 'MN095',
  MN097 = 'MN097',
  MN099 = 'MN099',
  MN101 = 'MN101',
  MN103 = 'MN103',
  MN105 = 'MN105',
  MN107 = 'MN107',
  MN109 = 'MN109',
  MN111 = 'MN111',
  MN113 = 'MN113',
  MN115 = 'MN115',
  MN117 = 'MN117',
  MN119 = 'MN119',
  MN121 = 'MN121',
  MN123 = 'MN123',
  MN125 = 'MN125',
  MN127 = 'MN127',
  MN129 = 'MN129',
  MN131 = 'MN131',
  MN133 = 'MN133',
  MN135 = 'MN135',
  MN137 = 'MN137',
  MN139 = 'MN139',
  MN141 = 'MN141',
  MN143 = 'MN143',
  MN145 = 'MN145',
  MN147 = 'MN147',
  MN149 = 'MN149',
  MN151 = 'MN151',
  MN153 = 'MN153',
  MN155 = 'MN155',
  MN157 = 'MN157',
  MN159 = 'MN159',
  MN161 = 'MN161',
  MN163 = 'MN163',
  MN165 = 'MN165',
  MN167 = 'MN167',
  MN169 = 'MN169',
  MN171 = 'MN171',
  MN173 = 'MN173'
}

export const COUNTY_CODES = {
  MN001: 'Aitkin',
  MN003: 'Anoka',
  MN005: 'Becker',
  MN007: 'Beltrami',
  MN009: 'Benton',
  MN011: 'Big Stone',
  MN013: 'Blue Earth',
  MN015: 'Brown',
  MN017: 'Carlton',
  MN019: 'Carver',
  MN021: 'Cass',
  MN023: 'Chippewa',
  MN025: 'Chisago',
  MN027: 'Clay',
  MN029: 'Clearwater',
  MN031: 'Cook',
  MN033: 'Cottonwood',
  MN035: 'Crow Wing',
  MN037: 'Dakota',
  MN039: 'Dodge',
  MN041: 'Douglas',
  MN043: 'Faribault',
  MN045: 'Fillmore',
  MN047: 'Freeborn',
  MN049: 'Goodhue',
  MN051: 'Grant',
  MN053: 'Hennepin',
  MN055: 'Houston',
  MN057: 'Hubbard',
  MN059: 'Isanti',
  MN061: 'Itasca',
  MN063: 'Jackson',
  MN065: 'Kanabec',
  MN067: 'Kandiyohi',
  MN069: 'Kittson',
  MN071: 'Koochiching',
  MN073: 'Lac qui Parle',
  MN075: 'Lake',
  MN077: 'Lake of the Woods',
  MN079: 'Le Sueur',
  MN081: 'Lincoln',
  MN083: 'Lyon',
  MN085: 'McLeod',
  MN087: 'Mahnomen',
  MN089: 'Marshall',
  MN091: 'Martin',
  MN093: 'Meeker',
  MN095: 'Mille Lacs',
  MN097: 'Morrison',
  MN099: 'Mower',
  MN101: 'Murray',
  MN103: 'Nicollet',
  MN105: 'Nobles',
  MN107: 'Norman',
  MN109: 'Olmsted',
  MN111: 'Otter Tail',
  MN113: 'Pennington',
  MN115: 'Pine',
  MN117: 'Pipestone',
  MN119: 'Polk',
  MN121: 'Pope',
  MN123: 'Ramsey',
  MN125: 'Red Lake',
  MN127: 'Redwood',
  MN129: 'Renville',
  MN131: 'Rice',
  MN133: 'Rock',
  MN135: 'Roseau',
  MN137: 'St. Louis',
  MN139: 'Scott',
  MN141: 'Sherburne',
  MN143: 'Sibley',
  MN145: 'Stearns',
  MN147: 'Steele',
  MN149: 'Stevens',
  MN151: 'Swift',
  MN153: 'Todd',
  MN155: 'Traverse',
  MN157: 'Wabasha',
  MN159: 'Wadena',
  MN161: 'Waseca',
  MN163: 'Washington',
  MN165: 'Watonwan',
  MN167: 'Wilkin',
  MN169: 'Winona',
  MN171: 'Wright',
  MN173: 'Yellow Medicine'
};
