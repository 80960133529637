<div fxLayout="row wrap"
     fxLayoutAlign="start start"
     fxLayoutGap="8px"
     style="margin-bottom:8px">

  <!-- First Name -->
  <mat-form-field style="width: 200px">
    <mat-label>
      First Name
    </mat-label>
    <input matInput
           autocomplete="off"
           [value]="filters.firstName || ''"
           (change)="onChange({ firstName: $any($event.target)?.value })"
           (keyup)="onKeyup({ firstName: $any($event.target)?.value })"
           type="text">
    <button mat-icon-button
            matSuffix
            *ngIf="filters.firstName"
            type="button"
            title="Clear first name"
            (click)="onChange({ firstName: undefined })"
            aria-label="Clear first name">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Last Name -->
  <mat-form-field style="width: 200px">
    <mat-label>
      Last Name
    </mat-label>
    <input matInput
           autocomplete="off"
           [value]="filters.lastName || ''"
           (change)="onChange({ lastName: $any($event.target)?.value })"
           (keyup)="onKeyup({ lastName: $any($event.target)?.value })"
           type="text">
    <button mat-icon-button
            matSuffix
            *ngIf="filters.lastName"
            type="button"
            (click)="onChange({ lastName: undefined })"
            title="Clear last name"
            aria-label="Clear last name">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Status -->
  <mat-form-field style="max-width: 160px;">
    <mat-label>Status</mat-label>
    <mat-select [value]="filters.status || []"
                [multiple]="true"
                (selectionChange)="onChange({ status: $event.value })">
      <mat-option *ngFor="let statusOption of statusOptions"
                  [value]="statusOption">
        {{ statusOption | titlecase }}
      </mat-option>
    </mat-select>
    <button mat-icon-button
            matSuffix
            (click)="$event.stopPropagation(); onChange({ status: undefined })"
            *ngIf="filters.status"
            type="button"
            title="Clear client"
            aria-label="Clear Client">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Birthday -->
  <mat-form-field>
    <mat-label>Birth Date</mat-label>
    <input matInput
           placeholder="MM/DD/YYYY"
           type="date"
           [value]="filters.birthDay || ''"
           (change)="onChange({ birthDay: $any($event.target)?.value })"
           autocomplete="off"
           birthDate>
    <button mat-icon-button
            matSuffix
            type="button"
            *ngIf="filters.birthDay"
            title="Clear Birthday"
            (click)="onChange({ birthDay: undefined })"
            aria-label="Clear birthday">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Registered At -->
  <mat-form-field>
    <mat-label>Registered At</mat-label>
    <input matInput
           placeholder="MM/DD/YYYY"
           type="date"
           [value]="filters.createdAt || ''"
           (change)="onChange({ createdAt: $any($event.target)?.value })"
           autocomplete="off"
           birthDate>
    <button mat-icon-button
            matSuffix
            type="button"
            *ngIf="filters.createdAt"
            title="Clear Start Date"
            (click)="onChange({ createdAt: undefined })"
            aria-label="Clear Start Date">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Company -->
  <mat-form-field>
    <mat-label>Company</mat-label>
    <input type="text"
           [value]="company"
           (keyup)="onKeyup({ company: $any($event.target).value })"
           placeholder="Company"
           autocomplete="off"
           [matAutocomplete]="companyAuto"
           matInput>
    <button mat-icon-button
            matSuffix
            (click)="onChange({ company: undefined })"
            *ngIf="filters.company"
            type="button"
            title="Clear Company"
            aria-label="Clear Company">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
    <mat-autocomplete #companyAuto="matAutocomplete"
                      (optionSelected)="onChange({ company: $event.option.value })">
      <mat-option *ngFor="let company of companiesArr"
                  [value]="company">
        {{ company.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- Search Button -->
  <div class="align-with-mat-field-with-hint-start">
    <button mat-flat-button
            type="button"
            color="primary"
            style="height: 50px;"
            (click)="search.emit()">
      <mat-icon class="search-button">search</mat-icon>
    </button>
  </div>

  <!-- Clear Button -->
  <div class="align-with-mat-field-with-hint-start">
    <button mat-flat-button
            *ngIf="hasFilters()"
            title="Clear"
            type="button"
            color="accent"
            style="height: 50px;"
            (click)="clear.emit();">
      <mat-icon class="search-button">close</mat-icon>
    </button>
  </div>

</div>
