import { ObjectId } from 'mongodb';
import { DbDocument } from './db-document';

export enum NotificationType {
  COMPANY = 'company',
  EVENT = 'event',
  SERVICE = 'service',
  USER = 'user'
}

export interface NotificationUser {
  firstName: string;
  lastName: string;
  email?: string; // Some accounts dont have an email
  id: string | ObjectId;
  accountType: 'user' | 'admin';
}

export type NotificationId = string & { readonly brand: unique symbol };

/**
 * Notification for admin to communicate information to the user
 * Will eventually be two way, allowing user to communicate with admin via reply
 */
export interface Notification extends DbDocument {
  _id: string | ObjectId;
  recipient: NotificationUser;

  subject: string;
  message: string;
  messageType: NotificationType;

  // Used to group and track bulk messages
  messageId: string | ObjectId;

  // If NotificationType is Company, include companyId
  companyId: string | ObjectId;
  // If NotificationType is Event, include eventLocationId
  eventId?: string | ObjectId;
  // If NotificationType is service, include eventServiceId
  serviceId?: string | ObjectId;
  adminId: string | ObjectId;

  // If the notification has replies, we will need to keep track of the parent notification
  threadId?: string | ObjectId;

  unread: boolean;
  readAt: Date;
  createdBy: NotificationUser;
  createdAt: Date;
}

// ? Not sure if needed at this stage, but including for NGRX
type NotificationPickedFilter = Pick<Notification, 'subject' | 'message'>;

export interface NotificationFilter extends NotificationPickedFilter {
  notificationType?: NotificationType[];
}

export interface NotificationForm extends NotificationFilter {
  pageNumber: number;
}
