import { IncentiveCategory } from '../incentives/incentive-category';
import { PersonType } from '../person-type';

export enum IncentiveType {
  PARTICIPATION = 'participation',
  OUTCOMES = 'outcomes',
  EHS = 'ehs',
  NONE = 'none'
}

export enum IncentiveGrouping {
  EMPOWERMENT_PLAN = 'Empowerment Plan',
  CHALLENGES = 'Challenges',
  HEALTHY_METRICS = 'Healthy Metrics'
}

/**
 * Represents the different ways that incentive points are divided over the course of the service.
 */
export enum IncentiveRewardMethod {
  OVERALL = 'overall',
  TIERED = 'tiered'
}

/**
 * Represents a tier in a tiered incentive program.
 * Includes the tier's name, goal point total, and end date.
 */
export interface IncentiveTier {
  /**
   * The tier number this definition represents.
   */
  tier: number;
  /**
   * The name of the tier.
   */
  name: string;
  /**
   * The total number of points required for this tier to be considered complete.
   */
  requiredPoints: number;
  /**
   * The last date that points can be earned for this tier.
   */
  endDate: string;
}

/**
 * Defines settings for the incentives feature.
 */
export interface EventServiceIncentives {
  /**
   * Incentive Program types.
   * Lee's explanation of the different types:
   * Participation: do we reward based on attendance
   * Outcome: do we reward based on health result
   * None: no reward
   */
  incentiveType: IncentiveType;
  /**
   * Description of the Incentive
   */
  description?: string;

  /**
   * How incentive points should be rewarded and managed over the
   * course of the service.
   */
  rewardMethod: IncentiveRewardMethod;

  /**
   * List of the incentive tiers the incentive service is segmented
   * into. This is only used when the reward method is set to 'tiered'.
   */
  incentiveTiers?: IncentiveTier[];
  /**
   * The list of person types supported by the Incentives - Who is allowed to participate in the incentive program.
   * This is combined with the person-types within the event-service groups later.
   */
  participants: PersonType[];

  /**
   * AQF Deadline - Date string 'YYYY-MM-DD'
   * AQF: Alternative Qualification Form.
   * Lee's description: If your goal was to complete 10k steps and your in a wheelchair,
   * you need to have an “alternative Qualification form”. (Aqf)
   */
  aqfDeadline: string;

  /**
   * AQF Description - Optional
   */
  aqfDescription?: string;

  /** Max Points for categories */
  categoryMaxPoints: Record<IncentiveCategory, number>;

  /** Sum of all category max point amounts */
  maxPoints: number;

  /**
   * URL of the Incentive PDF that will be displayed to users. The PDF will show all the activities & Points that users
   * can get within the program.
   */
  incentivePdfUrl?: string;

  showIncentives?: boolean;

  /**
   * Flag to determine if we need to send an email reminder to users pending to complete the incentive program
   */
  sendEmailReminder?: boolean;

  /**
   * Date to send the reminder emails
   * Date string 'YYYY-MM-DD'
   */
  emailReminderDate?: string;

  emailReminderCustomText?: string;

  /**
   * The incetnive group this incentive its part of.
   * Used mainly to identify in which widget to display
   * (for now we show 4 widgets,1 for the main event incentives,and other 3 per each incentive grouping)
   */
  incentiveGroup?: IncentiveGrouping;

  /**
   * Name to show when displaying this incentive when using multiple incentives at the same time
   * Will also be used when showing the title of the incentive widget in the user dashboard
   */
  incentiveName?: string;

  /**
   * Text to display in the dashboard button of the incentive,will default to: Full Report Card
   */
  incentiveDashboardButtonText?: string;

  /**
   * Text to display in the incentive details button,will default to: Incentive Details
   */
  incentiveDetailsButtonText?: string;

  /**
   * Short text to display with the incentive plan options on mobile only
   */
  mobileIncentivePlanHeadline?: string;
  /**
   * Non-Rich text description to display within a selected user incentive
   * for that program for mobile only
   */
  mobileIncentivePlanDescription?: string;
}
