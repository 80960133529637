import { Injectable } from '@angular/core';
import { AdminCoreHttpService } from '@http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { adminCoreNgrxActions } from './admin-core-ngrx.actions';
import { EhsAddAdminDialogService, EhsConfirmDialogService } from '@ehs-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getId } from '@common';

@Injectable()
export class AdminCoreNgrxEffects {
  constructor(
    private actions$: Actions,
    private adminCoreHttp: AdminCoreHttpService,
    private snackBar: MatSnackBar,

    // Dialogs
    private addAdminDialog: EhsAddAdminDialogService,
    private confirmDialog: EhsConfirmDialogService
  ) {}

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCoreNgrxActions.list.req),
      mergeMap(() =>
        this.adminCoreHttp.getAdminList().pipe(
          map(
            (entities) => adminCoreNgrxActions.list.success({ entities }),
            catchError(() => [adminCoreNgrxActions.list.failed()])
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCoreNgrxActions.update.req),
      mergeMap(({ payload: { admin } }) =>
        this.adminCoreHttp.updateAdminScopes(admin).pipe(
          tap({
            next: () =>
              this.snackBar.open(`${admin?.email} updated successfully`, 'Ok'),
            error: () =>
              this.snackBar.open(
                `Oops! Something went wrong updating admin`,
                'Ok'
              )
          }),
          map(() => adminCoreNgrxActions.update.success({ admin })),
          catchError((err: HttpErrorResponse) => [
            adminCoreNgrxActions.update.failed({ error: err.error })
          ])
        )
      )
    )
  );

  addAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCoreNgrxActions.addAdmin.req),
      mergeMap(() => this.addAdminDialog.open()),
      filter((email) => !!email),
      mergeMap((email) =>
        this.adminCoreHttp.createAdmin(email).pipe(
          tap({
            next: () => this.snackBar.open(`Added ${email} as admin`, 'Ok'),
            error: () =>
              this.snackBar.open(
                `Oops! Something went wrong adding admin`,
                'Ok'
              )
          }),
          mergeMap(() => [
            adminCoreNgrxActions.addAdmin.success(),
            adminCoreNgrxActions.list.req()
          ]),
          catchError((err: HttpErrorResponse) => [
            adminCoreNgrxActions.addAdmin.failed({ error: err.error })
          ])
        )
      )
    )
  );

  removeAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCoreNgrxActions.removeAdmin.req),
      mergeMap(({ payload }) =>
        this.confirmDialog
          .open({
            title: 'Removing Admin',
            message: `Are you sure you want to remove ${payload?.email}?`
          })
          .pipe(
            filter((_) => !!_),
            mergeMap(() =>
              this.adminCoreHttp.deleteAdmin(getId(payload)).pipe(
                tap({
                  next: () =>
                    this.snackBar.open(
                      `Admin ${payload.email} removed successfully`,
                      'Ok'
                    ),
                  error: () =>
                    this.snackBar.open(
                      `Oops! Something went wrong removing admin`,
                      'Ok'
                    )
                }),
                map(() =>
                  adminCoreNgrxActions.removeAdmin.success({
                    adminId: getId(payload)
                  })
                ),
                catchError((err: HttpErrorResponse) => [
                  adminCoreNgrxActions.removeAdmin.failed({ error: err.error })
                ])
              )
            )
          )
      )
    )
  );

  getStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCoreNgrxActions.getStats.req),
      mergeMap((action) =>
        this.adminCoreHttp
          .getStatsByDateRange({
            startDate: action.payload.startDate,
            endDate: action.payload.endDate
          })
          .pipe(
            map((stats) =>
              adminCoreNgrxActions.getStats.success({
                stats
              })
            ),
            catchError(() => [adminCoreNgrxActions.getStats.failed()])
          )
      )
    )
  );
}
