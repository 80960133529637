import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import {
  ClientAdminScopes,
  Company,
  UserWithRegistrations,
  isOnsiteUserRegistration,
  isUserWithRegistrations,
  OnsiteUserRegistration,
  User,
  EventLocation,
  EventLocationUtil,
  getId,
  UserRegistration,
  ServiceType
} from '@common';
import {
  ClientAdminUserActionColumn,
  ClientAdminUserActionType,
  ClientAdminUserColumn
} from './user-table-column';

@Component({
  selector: 'ehsc-user-table',
  templateUrl: './ehsc-user-table.component.html',
  styleUrls: ['./ehsc-user-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhscUserTableComponent {
  /**
   * The list of users being displayed in the table
   */
  @Input() users: (User | UserWithRegistrations)[] = [];

  /** The map of eventLocations to reference to get the eventLocation name */
  @Input() eventLocationMap: Record<string, EventLocation>;
  // Active event services

  /**
   * The columns being displayed in the table
   */
  @Input() columns: ClientAdminUserColumn[] = [];

  /**
   * The base actions being displayed for a given user within the three dot menu
   * Will be ran through `getUserSpecificActions` method to get the list of
   * actions for the given user, taking into account the user and mergeUsers
   */
  @Input() userActions?: ClientAdminUserActionColumn[] = [];

  /**
   * Flag to display `Load More` button
   */
  @Input() hasNext?: boolean;

  /**
   * The page we are loading and displaying to the user. Used to determine
   * what "pageIndex" offset that is calculated
   */
  @Input() pageNumber?: number;

  /**
   * Unlike most components, this component can handle internal loading by displaying a
   * loading bar at the top of the header, this is less disruptive
   * than the full-page loading spinner we usually use
   */
  @Input() loading?: boolean;

  /**
   * Flag to display spinner on action column header
   */
  @Input() actionLoading?: boolean;
  /**
   * The map of companies, used for lookups for
   * user displays.
   */
  @Input() companies?: Record<string, Company>;

  /**
   * Array of ids for users to merge together.
   * The actual act of merging is done within another component/service,
   * this table only requires the list of users to merge to update
   * the menu options for each user
   */
  @Input() usersToMerge?: string[];

  /**
   *  Emits on action trigger for user row
   */
  @Output() actionChange = new EventEmitter<{
    /**
     * The action type emitted from the row.
     */
    type: ClientAdminUserActionType;
    /**
     * The user that was affected
     */
    user: User | UserWithRegistrations;
  }>();

  @Output() loadMore = new EventEmitter();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  public adminUserScopes = ClientAdminScopes;

  /**
   * The index of the item where we will load more for. This is based on the pageNumber that is
   * passed from the ngrx state for users, which represents the page we last requested for.
   *
   *
   * Initially the loadMoreIndex will automatically load up the next chunk of results after the first element is scrolled past.
   *
   * After this point, then the "last" element of the "second to last" chunk will be the point at which we load
   * the next chunk. This should keep a buffer of 25 elements below the last element allowing for a smooth scroll experience,
   * as long as the data comes back before the final element is scrolled to.
   */
  get loadMoreIndex() {
    return (this.pageNumber - 1) * 25;
  }

  /**
   * To keep the header positioning:sticky inside cdk viewport
   */
  get headerTop() {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }

    const offset = this.viewPort['_renderedContentOffset'];

    return `-${offset}px`;
  }

  public trackByFn(index: number) {
    return index;
  }

  /**
   * Returns the registration of the user, this uses the `registration` map if provided
   * otherwise it won't be able to find a registration as this is a 'dumb' component
   */
  public getUserRegistration(
    user: User | UserWithRegistrations
  ): OnsiteUserRegistration | undefined {
    if (isUserWithRegistrations(user)) {
      const registration = user.userRegistrations;

      return isOnsiteUserRegistration(registration) &&
        this.eventLocationMap[getId(registration.eventLocation)] &&
        EventLocationUtil.isFutureActive({
          eventLocation: this.eventLocationMap[
            getId(registration.eventLocation)
          ] as EventLocation
        })
        ? registration
        : undefined;
    }

    return undefined;
  }

  public getRegistrationType(user: User | UserWithRegistrations) {
    if (isUserWithRegistrations(user)) {
      const registration = user.userRegistrations as UserRegistration;

      if (registration.serviceType === ServiceType.ONSITE) {
        return 'Onsite Registration';
      }

      if (registration.serviceType === ServiceType.OFFSITE) {
        return 'Offsite Registration';
      }

      if (registration.serviceType === ServiceType.PROVIDER_HEALTH) {
        return 'Health Provider Form Registration';
      }
    }

    return '';
  }

  public getUserRegistrationEventLocation(
    user: User | UserWithRegistrations
  ): string | undefined {
    if (isUserWithRegistrations(user)) {
      if (isOnsiteUserRegistration(user.userRegistrations)) {
        const eventLocationId = String(user.userRegistrations?.eventLocation);

        if (
          this.eventLocationMap[eventLocationId] &&
          EventLocationUtil.isFutureActive({
            eventLocation: this.eventLocationMap[
              eventLocationId
            ] as EventLocation
          })
        ) {
          return (
            this.eventLocationMap[eventLocationId].address1 +
            ' ' +
            this.eventLocationMap[eventLocationId].city +
            ' ' +
            this.eventLocationMap[eventLocationId].state
          );
        }
      }
    }

    return undefined;
  }

  /**
   * Returns the list of actions for a given user, taking into account
   * the base actions available to the admin, and the merge-users currently
   * selected.
   *
   * **note** this could be a pipe, but it doesn't emit
   * too often
   */
  public getUserSpecificActions(
    user: User | UserWithRegistrations
  ): ClientAdminUserActionColumn[] {
    if (isUserWithRegistrations(user)) {
      return this.userActions.filter(
        (column) => column.scope !== ClientAdminScopes.CREATE_REGISTRATION
      );
    }

    return this.userActions.filter(
      (column) => column.scope !== ClientAdminScopes.REMOVE_REGISTRATION
    );
  }

  /**
   * Callback on scroll, testing replacements for loading
   */
  public onScrolledIndexChange(index: number) {
    if (index > this.loadMoreIndex) {
      this.loadMore.emit();
    }
  }
}
