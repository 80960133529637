import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserCritical, UserCriticalForm } from '@common';

const BASE_URL = '/api/v1/admin/critical';

@Injectable({
  providedIn: 'root'
})
export class AdminUserCriticalHttpService {
  constructor(private http: HttpClient) {}

  list(params: UserCriticalForm) {
    return this.http.get<{ userCriticals: UserCritical[]; hasNext: boolean }>(
      `${BASE_URL}/list`,
      {
        params: Object.entries(params).reduce(
          (_params, [key, value]) =>
            value ? _params.append(key, '' + value) : _params,
          new HttpParams()
        )
      }
    );
  }

  listByUser(userId: string) {
    return this.http.get<{ userCriticals: UserCritical[] }>(
      `${BASE_URL}/list/user/${userId}`
    );
  }

  get(id: string) {
    return this.http.get<UserCritical>(`${BASE_URL}/${id}`);
  }

  update(userCritical: UserCritical) {
    return this.http.put<UserCritical>(`${BASE_URL}`, userCritical);
  }
}
