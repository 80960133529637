import { getId, Notification } from '@common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { selectId } from '../utils/select-id';
import { notificationActions } from './notification.actions';

export interface NotificationState extends EntityState<Notification> {
  loading?: boolean;
}

const adapter = createEntityAdapter<Notification>({
  selectId
});

const reducer = createReducer<NotificationState>(
  adapter.getInitialState({}),
  on(notificationActions.clear, () => adapter.getInitialState({})),
  on(notificationActions.list, (state) => ({
    ...state,
    loading: true
  })),
  on(notificationActions.listSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(notificationActions.listFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(notificationActions.update, (state) => ({
    ...state,
    loading: true
  })),
  on(notificationActions.updateSuccess, (state, { notifications }) => {
    const entities = notifications.map((notification) => {
      const entity = state.entities[getId(notification)];

      return {
        ...entity,
        unread: !entity.unread
      };
    });

    return adapter.upsertMany(entities, { ...state, loading: false });
  }),
  on(notificationActions.updateFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(notificationActions.get, (state) => ({
    ...state,
    loading: true
  })),
  on(notificationActions.getSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, {
      ...state,
      loading: false
    })
  ),
  on(notificationActions.getFailed, (state) => ({ ...state, loading: false }))
);

export function NotificationReducer(state: NotificationState, action: Action) {
  return reducer(state, action);
}
