import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsUserPaymentsTestsCostTableComponent } from './ehs-user-payments-tests-cost-table.component';
import { MatChipsModule } from '@angular/material/chips';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [EhsUserPaymentsTestsCostTableComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatChipsModule,
    MatProgressSpinnerModule
  ],
  exports: [EhsUserPaymentsTestsCostTableComponent]
})
export class EhsUserPaymentsTestsCostTableModule {}
