import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsUserResultsTableComponent } from './ehs-user-results-table.component';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule } from 'ng-flex-layout';

@NgModule({
  declarations: [EhsUserResultsTableComponent],
  imports: [CommonModule, MatTableModule, FlexLayoutModule],
  exports: [EhsUserResultsTableComponent]
})
export class EhsUserResultsTableModule {}
