import { Injectable } from '@angular/core';
import { DateUtil, EventLocation, OnsiteUserRegistration, User } from '@common';
import { PDFDocument } from 'pdf-lib';
import { from, Observable, zip } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MergePdfService {
  /**
   * Merges pdf-buffers into 1 pdf, returns
   * 1 pdf document that can be used to download.
   */
  public merge(buffers: Buffer[]): Observable<PDFDocument> {
    return from([buffers]).pipe(
      mergeMap((arrayBuffers) =>
        zip(...arrayBuffers.map((ab) => PDFDocument.load(ab)))
      ),
      mergeMap((pdfDocs) =>
        pdfDocs.reduce(
          (acc$, pdfDoc) =>
            acc$.then((acc) =>
              acc
                .copyPages(pdfDoc, pdfDoc.getPageIndices())
                .then((copiedPages) => {
                  copiedPages.forEach((copiedPage) => acc.addPage(copiedPage));

                  return acc;
                })
            ),
          Promise.resolve(PDFDocument.create())
        )
      )
    );
  }

  /**
   * Download the pdf-document for an onsite
   *
   * File naming convention:
   * eventID_appointmentDate_AppointmentTime_lastName
   */
  public async downloadOnsite(params: {
    pdfDocument: PDFDocument;
    eventLocation: EventLocation;
    userRegistration: OnsiteUserRegistration;
    user: User;
  }): Promise<void> {
    const { pdfDocument, eventLocation, userRegistration, user } = params;
    const pdfBase64 = await pdfDocument.saveAsBase64();
    const downloadLinkElement: HTMLAnchorElement = document.createElement('a');

    downloadLinkElement.href = `data:application/pdf;base64,${pdfBase64}`;

    downloadLinkElement.download = `${
      eventLocation.eventId
    }_${DateUtil.formatDate(userRegistration.eventDate)}_${
      userRegistration.eventTime
    }_${user.lastName}.pdf`;
    downloadLinkElement.click();
    downloadLinkElement.remove();
  }

  /**
   * Download the pdf-document for an onsite event's lab-req.
   *
   * The file generated is statically named, this may change in the future
   */
  public async downloadOnsiteLabReqs(params: {
    pdfDocument: PDFDocument;
    eventLocation: Pick<EventLocation, 'eventId'>;
  }): Promise<void> {
    const { pdfDocument, eventLocation } = params;
    const pdfBase64 = await pdfDocument.saveAsBase64();
    const downloadLinkElement: HTMLAnchorElement = document.createElement('a');

    downloadLinkElement.href = `data:application/pdf;base64,${pdfBase64}`;

    downloadLinkElement.download = `${
      eventLocation?.eventId || 'onsite-event'
    }.pdf`;
    downloadLinkElement.click();
    downloadLinkElement.remove();
  }
}
