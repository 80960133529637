<ng-container *ngIf="data">

  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'nav'">
      <a mat-flat-button
         class="left-justify-button"
         [ehsButtonAnimation]
         [animationColor]="'white'"
         [routerLink]="route"
         [queryParams]="queryParams"
         [queryParamsHandling]="queryParamsHandling"
         fxFlex
         color="primary">
        <div fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap=8px>
          <mat-icon>{{data.icon}}</mat-icon>
          <span fxFlex
                class="text-align-left">{{data.title}}</span>
        </div>

      </a>
    </ng-container>

    <ng-container *ngSwitchCase="'nested'">
      <button type="button"
              [ehsButtonAnimation]
              [animationColor]="'white'"
              fxFlex
              mat-flat-button
              (click)="$event.stopPropagation();"
              [matMenuTriggerFor]="subMenu"
              color="primary">
        <div fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap=8px>
          <mat-icon>{{data.icon}}</mat-icon>
          <span fxFlex
                class="text-align-left">{{data.title}}</span>
          <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
      </button>
      <mat-menu #subMenu="matMenu"
                xPosition="after"
                class="mat-toolbar background-mat-primary no-min-height no-max-width">
        <div fxLayout="column"
             fxLayoutAlign="start"
             fxLayoutGap="8px">
          <ng-container *ngFor="let nestedElement of nestedData">
            <ehs-header-element [data]="nestedElement"
                                (action)="emitAction($event)"></ehs-header-element>
          </ng-container>
        </div>
      </mat-menu>
    </ng-container>

    <ng-container *ngSwitchCase="'action'">
      <button type="button"
              class="left-justify-button"
              fxFlex
              [ehsButtonAnimation]
              [animationColor]="'white'"
              mat-flat-button
              (click)="emitAction(data, $event)"
              color="primary">
        <div fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap=8px>
          <mat-icon>{{data.icon}}</mat-icon>
          <span fxFlex
                class="text-align-left">{{data.title}}</span>
        </div>
      </button>
    </ng-container>

  </ng-container>
