import { Injectable } from '@angular/core';
import {
  EhsFormFieldLayout,
  EhsFormRow
} from '../ehs-form-field-layout/ehs-form-field-layout';
import {
  EhsRegEditForm,
  EhsRegEditFormFieldTypes
} from '../ehs-form-field-layout/ehs-form-field-types';
@Injectable()
export class EhsRegEditFormDisplayService {
  /**
   * Form fields definition for registration edits form
   */
  public getAdminEditLayout(): EhsFormFieldLayout<EhsRegEditForm> {
    const commonRowSpec: Omit<EhsFormRow<EhsRegEditForm>, 'fields'> = {
      gdColumns: 2,
      gdColumnsSm: 1,
      gdColumnsXs: 1
    };

    return {
      rows: [
        {
          gdColumns: 1,
          gdColumnsSm: 1,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsRegEditFormFieldTypes.STATUS,
              required: false,
              disabled: true
            }
          ]
        },
        {
          ...commonRowSpec,
          fields: [
            {
              fieldType: EhsRegEditFormFieldTypes.PERSON_TYPE,
              required: false,
              disabled: true
            },
            {
              fieldType: EhsRegEditFormFieldTypes.SERVICE_TYPE,
              required: false,
              disabled: true
            }
          ]
        },
        {
          gdColumns: 1,
          gdColumnsSm: 1,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsRegEditFormFieldTypes.GROUP_TYPE,
              required: true,
              disabled: false
            }
          ]
        },
        {
          gdColumns: 1,
          gdColumnsSm: 1,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsRegEditFormFieldTypes.INSURANCE_PROVIDER,
              required: true,
              disabled: false
            }
          ]
        },
        {
          gdColumns: 1,
          gdColumnsSm: 1,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsRegEditFormFieldTypes.INSURANCE_TYPE,
              required: false,
              disabled: false
            }
          ]
        },
        {
          gdColumns: 1,
          gdColumnsSm: 1,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsRegEditFormFieldTypes.INSURANCE_GROUP,
              required: false,
              disabled: false
            }
          ]
        },
        {
          gdColumns: 1,
          gdColumnsSm: 1,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsRegEditFormFieldTypes.INSURANCE_POLICY,
              required: false,
              disabled: false
            }
          ]
        }
      ]
    };
  }
}
