import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoachingSession } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoachingSessionHttpService {
  constructor(private http: HttpClient) {}

  public get(id: string): Observable<CoachingSession> {
    return this.http.get<CoachingSession>(`api/v1/coaching/${id}`);
  }

  public list(): Observable<CoachingSession[]> {
    return this.http.get<CoachingSession[]>('api/v1/coaching');
  }

  public create(entity: Partial<CoachingSession>): Observable<CoachingSession> {
    return this.http.post<CoachingSession>('api/v1/coaching', entity);
  }

  public remove(id: string): Observable<CoachingSession> {
    return this.http.delete<CoachingSession>(`api/v1/coaching/${id}`);
  }

  public listAvailabilities(
    date: string,
    timezone: string
  ): Observable<string[]> {
    return this.http.get<string[]>(`api/v1/coaching/times`, {
      params: new HttpParams().set('date', date).set('timezone', timezone)
    });
  }
}
