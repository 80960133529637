import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomReportImages, getId, Company } from '@common';
import { Observable } from 'rxjs';

const BASE_URL = '/api/v1/custom-reports';

@Injectable({
  providedIn: 'root'
})
export class UserCustomReportHttpService {
  constructor(private http: HttpClient) {}

  public get(id: string) {
    return this.http.get<CustomReportImages>(`${BASE_URL}/${id}`);
  }

  public listByCompany(company: Company | string) {
    return this.http.get<CustomReportImages[]>(
      `${BASE_URL}/company/${getId(company)}`
    );
  }

  /**
   * Returns a signed url to download a CustomReport page image
   */
  public getCustomReportDownloadSignedUrl(params: {
    /**
     * The id of the corresponding CustomReport object
     */
    id: string;
    /** The id generated for the file in google storage */
    fileId: string;
  }): Observable<{ url: string }> {
    const { id, fileId } = params;

    return this.http.get<{ url: string }>(
      `${BASE_URL}/${id}/download/url/${fileId}`
    );
  }
}
