import { User } from '../models/user';

/**
 * Static utility class that can be used for minor
 * helper utility functions for  for user "business" logic
 * related to google's identity platform.
 */
export class IdentityPlatformUtil {
  /**
   * Returns the email to be used as a "unique" email address
   * within the google identity platform.
   */
  public static getEmailFromUser(params: {
    user: Pick<User, 'username'>;
  }): string {
    const { user } = params;
    const { username } = user;

    return `${username}@ehs-member.com`;
  }

  /**
   * Returns the display name for the user, this is a
   * combination of the user's first and last names
   */
  public static getDisplayName(params: {
    user: Pick<User, 'firstName' | 'lastName'>;
  }): string {
    const { user } = params;

    return `${user.firstName || ''} ${user.lastName || ''}`;
  }
}
