import { HraAnswerColors } from './hra-answer';

/**
 * The list of Hra scoring categories within the form.
 */
export enum HraScoreCategoryTypes {
  ACTIVE_LIFESTYLE = 'active_lifestyle',
  PERSONAL_CARE = 'personal_care',
  EMOTIONAL_HEALTH = 'emotional_health',
  NUTRITION = 'nutrition',
  TOBACCO_USE = 'tobacco_use',
  CURRENT_HEALTH = 'current_health',
  ALCOHOL_USE = 'alcohol_use',
  SLEEP = 'sleep',
  HEART_HEALTH = 'heart_health', // Tied with heart health
  DIABETES_PREVENTION = 'diabetes_prevention'
}

/**
 * List of hra score categories to human friendly names
 */
export const hraScoreCategoryNames: {
  [key in HraScoreCategoryTypes]: string;
} = {
  [HraScoreCategoryTypes.ACTIVE_LIFESTYLE]: 'Active Lifestyle',
  [HraScoreCategoryTypes.PERSONAL_CARE]: 'Personal Care', // Was living easy
  [HraScoreCategoryTypes.EMOTIONAL_HEALTH]: 'Emotional Health',
  [HraScoreCategoryTypes.NUTRITION]: 'Nutrition',
  [HraScoreCategoryTypes.TOBACCO_USE]: 'Tobacco Use',
  [HraScoreCategoryTypes.CURRENT_HEALTH]: 'Current Health',
  [HraScoreCategoryTypes.ALCOHOL_USE]: 'Alcohol Use',
  [HraScoreCategoryTypes.SLEEP]: 'Sleep',
  [HraScoreCategoryTypes.HEART_HEALTH]: 'Heart Health',
  [HraScoreCategoryTypes.DIABETES_PREVENTION]: 'Diabetes Prevention'
};

/**
 * List of hra score categories to icons
 */
export const hraScoreCategoryIcons: {
  [key in HraScoreCategoryTypes]: string;
} = {
  [HraScoreCategoryTypes.ACTIVE_LIFESTYLE]: 'fitness_center',
  [HraScoreCategoryTypes.PERSONAL_CARE]: 'emoji_people', // Was living easy
  [HraScoreCategoryTypes.EMOTIONAL_HEALTH]: 'sentiment_satisfied',
  [HraScoreCategoryTypes.NUTRITION]: 'local_dining',
  [HraScoreCategoryTypes.TOBACCO_USE]: 'smoke_free',
  [HraScoreCategoryTypes.CURRENT_HEALTH]: 'healing',
  [HraScoreCategoryTypes.ALCOHOL_USE]: 'local_drink',
  [HraScoreCategoryTypes.SLEEP]: 'single_bed',
  [HraScoreCategoryTypes.HEART_HEALTH]: 'favorite',
  [HraScoreCategoryTypes.DIABETES_PREVENTION]: 'scatter_plot'
};

/**
 * List of hra score categories to human friendly names
 */
export const hraScoreCategoryShortAnswer: {
  [key in HraScoreCategoryTypes]: string;
} = {
  [HraScoreCategoryTypes.ACTIVE_LIFESTYLE]: `We all want to lead a happy and healthy life. A major factor in achieving this is staying active.
    Not only will physical activity allow you to keep your body strong, but it does wonders for mental and emotional health as well.
    In fact, an active lifestyle is proven to better your overall quality of life! `,
  [HraScoreCategoryTypes.PERSONAL_CARE]:
    'From practicing good hygiene to staying mindful of your safety and the safety of those around you, personal care is important to a long and prosperous life.',
  [HraScoreCategoryTypes.EMOTIONAL_HEALTH]:
    'Your mental health is just as important as your physical health. Stress is a natural part of everyday life. It’s how you choose to react and manage the stress that matters most!',
  [HraScoreCategoryTypes.NUTRITION]:
    'As the old saying goes, “you are what you eat.” Managing which foods you consume is vital to leading a healthy lifestyle. ',
  [HraScoreCategoryTypes.TOBACCO_USE]:
    'One of the easiest ways to lead a healthy life is to avoid the use of tobacco. In the United States, tobacco is the leading cause of preventable disease, disability, and death.',
  [HraScoreCategoryTypes.CURRENT_HEALTH]:
    'It is important for you to take charge of your own health by making conscious decisions to be healthy on a daily basis.',
  [HraScoreCategoryTypes.ALCOHOL_USE]:
    'While you may be aware that moderate alcohol consumption may provide some health benefits, it is far more important to understand the risks and harmful effects of heavy drinking.',
  [HraScoreCategoryTypes.SLEEP]: `Sleep is a vital, often neglected, component of your overall health and well-being. If you aren't sleeping well, or you're spending your days exhausted,
    you may need to adjust your habits in order to have a better quality of sleep.`,
  [HraScoreCategoryTypes.HEART_HEALTH]:
    'Keeping your heart healthy is simple when you look at the big picture.',
  [HraScoreCategoryTypes.DIABETES_PREVENTION]:
    'In many cases, diabetes is a preventable disease. Unfortunately, many Americans are at risk for the most common form of this condition, type 2 diabetes. Your chances of being a ' +
    'type 2 diabetic depend on a combination of risk factors, including genetics and lifestyle. Understanding how to lower your risk of type 2 diabetes is important.'
};

/**
 * Defines the high and low percentage
 */
export interface HraScoreCategoryColorDef {
  high: number;
  low: number;
}
/**
 * The different "colors" categories that
 * are returned by the hra scoring utilities.
 */
export enum HraScoreCategoryColors {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}
/**
 * This is the enum for the actual hex values for each score category color
 *
 * **note** these were changes to be the same as the HraAnswerColors
 */
export const hraScoreCategoryColorsHex = {
  [HraScoreCategoryColors.HIGH]: HraAnswerColors.GREEN,
  [HraScoreCategoryColors.MEDIUM]: HraAnswerColors.YELLOW,
  [HraScoreCategoryColors.LOW]: HraAnswerColors.RED
};
/**
 * Default score used for all categories as of now.
 */
export const defaultHraScoreColorDefs: Pick<
  HraScoreCategory,
  'goodScore' | 'mediumScore' | 'lowScore'
> = {
  goodScore: {
    high: 1,
    low: 75 / 100
  },
  mediumScore: {
    high: 75 / 100,
    low: 50 / 100
  },
  lowScore: {
    high: 50 / 100,
    low: 0
  }
};
/**
 * An Hra score category represents a scoring category
 * for each of the questions.
 */
export interface HraScoreCategory {
  /**
   * The scoring category to provide settings for
   * all questions within the form.
   */
  type: HraScoreCategoryTypes;
  /**
   * The total number score for this category
   */
  total: number;
  /**
   * The minimum score for this category
   */
  min: number;
  /**
   * High score definition
   */
  goodScore: HraScoreCategoryColorDef;
  /**
   * Medium score definition
   */
  mediumScore: HraScoreCategoryColorDef;
  /**
   * Low score definition
   */
  lowScore: HraScoreCategoryColorDef;
}
