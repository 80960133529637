import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserResultHttpService } from '../../http/user-result-http.service';
import { userResultActions } from './user-result.actions';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorReportFacade } from '../error-report/error-report.facade';

@Injectable()
export class UserResultEffects {
  constructor(
    private actions$: Actions,
    private userResultHttp: UserResultHttpService,
    private errorReportFacade: ErrorReportFacade
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userResultActions.get),
      mergeMap((action) =>
        this.userResultHttp.get(action.userResultId).pipe(
          map((entity) => userResultActions.getSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            userResultActions.getFailed({ error: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  getByUserReg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userResultActions.getByUserReg),
      mergeMap((action) =>
        this.userResultHttp.getByUserRegId(action.userRegId).pipe(
          map((entity) =>
            userResultActions.getByUserRegSuccess({
              entity
            })
          ),
          catchError((err: HttpErrorResponse) => [
            userResultActions.getByUserRegFailed({ error: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userResultActions.list),
      mergeMap((action) =>
        this.userResultHttp.list().pipe(
          map((entities) =>
            userResultActions.listSuccess({
              entities
            })
          ),
          catchError((err: HttpErrorResponse) => [
            userResultActions.listFailed({ error: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  fax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userResultActions.fax),
      mergeMap((action) =>
        this.userResultHttp.fax(action).pipe(
          map((res) => userResultActions.faxSuccess({ res })),
          catchError((err: HttpErrorResponse) => [
            userResultActions.faxFailed({ err: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );
}
