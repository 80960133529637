import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EhsGwappsActionsComponent } from './ehs-gwapps-actions.component';

@NgModule({
  declarations: [EhsGwappsActionsComponent],
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, MatIconModule],
  exports: [EhsGwappsActionsComponent]
})
export class EhsGwappsActionsModule {}
