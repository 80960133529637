import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserVaccination } from '@common';
import { Observable } from 'rxjs';

const BASE_URL = '/api/v1/admin/user-vaccination';

@Injectable({
  providedIn: 'root'
})
export class AdminUserVaccinationHttpService {
  constructor(private http: HttpClient) {}

  listByUser(userId: string) {
    return this.http.get<{ userVaccinations: UserVaccination[] }>(
      `${BASE_URL}/list/user/${userId}`
    );
  }

  listByLocationId(eventLocationId: string) {
    return this.http.get<{ userVaccinations: UserVaccination[] }>(
      `${BASE_URL}/list?eventLocationId=${eventLocationId}`
    );
  }

  get(id: string) {
    return this.http.get<UserVaccination>(`${BASE_URL}/${id}`);
  }

  create(userVaccination: UserVaccination) {
    return this.http.post<UserVaccination>(
      `${BASE_URL}/create`,
      userVaccination
    );
  }

  update(userVaccination: UserVaccination) {
    return this.http.put<UserVaccination>(
      `${BASE_URL}/update`,
      userVaccination
    );
  }

  public getByUserRegistration(params: {
    userRegistrationId: string;
  }): Observable<UserVaccination> {
    const { userRegistrationId } = params;

    return this.http.get<UserVaccination>(
      `api/v1/admin/user-vaccination/registration/${userRegistrationId}`
    );
  }

  public removeUserVaccination(params: {
    userRegistration: string;
  }): Observable<UserVaccination> {
    const { userRegistration } = params;

    return this.http.delete<UserVaccination>(
      `api/v1/admin/user-vaccination/registration/${userRegistration}`
    );
  }

  public removeUserVaccinationAndRegistration(params: {
    userRegistration: string;
    removeRegistration?: boolean;
  }): Observable<UserVaccination> {
    const { userRegistration, removeRegistration } = params;

    return this.http.delete<UserVaccination>(
      `api/v1/admin/user-vaccination/registration/${userRegistration}`,
      {
        params: removeRegistration
          ? new HttpParams().append('removeRegistration', 'true')
          : undefined
      }
    );
  }
}
