<h1 mat-dialog-title>Add Admin User</h1>
<form #addAdminForm="ngForm"
      novalidate
      (submit)="addAdmin(addAdminForm)">
  <mat-dialog-content>
    <div fxLayout="column"
         fxLayoutGap="8px">
      <mat-form-field>
        <mat-label>Admin Email</mat-label>
        <input matInput
               #emailField="ngModel"
               [(ngModel)]="emailAddress"
               name="email"
               type="email"
               required>
        <mat-error *ngIf="emailField.hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
            type="button"
            [mat-dialog-close]="null">
      Cancel</button>
    <button mat-flat-button
            color="primary"
            cdkFocusInitial>
      Add Admin
    </button>
  </mat-dialog-actions>
</form>
