import { Pipe, PipeTransform } from '@angular/core';
import { TimeUtil } from '@common';

@Pipe({
  name: 'ampm'
})
export class TimeAmPmPipe implements PipeTransform {
  transform(time: string): string {
    return TimeUtil.timeToAmPm(time);
  }
}
