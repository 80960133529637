import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { EhsGwappsActionsModule } from '../ehs-gwapps-actions/ehs-gwapps-actions.module';
import { EhsServiceTypesModule } from '../ehs-service-types/ehs-service-types.module';
import { EhsGwappsProviderHealthFormsComponent } from './ehs-gwapps-provider-health-forms.component';
import { EhsCardLabelModule } from '../ehs-card-label/ehs-card-label.module';

@NgModule({
  declarations: [EhsGwappsProviderHealthFormsComponent],
  imports: [
    CommonModule,
    RouterModule,

    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    // Child components
    EhsGwappsActionsModule,
    EhsServiceTypesModule,
    EhsCardLabelModule
  ],
  exports: [EhsGwappsProviderHealthFormsComponent]
})
export class EhsGwappsProviderHealthFormsModule {}
