import {
  EventLocation,
  EventLocationCustomTime,
  EventLocationCustomTimeBase,
  EventLocationCustomTimeFlu,
  EventLocationCustomTimeScreening,
  getId,
  isEventLocationCustomTimeFlu,
  isEventLocationCustomTimeScreening,
  SingleEventStats
} from '@common';
import {
  EntityStatsState,
  EntityStatsTypeParams,
  selectId
} from '@ehs-ngrx/common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { eventLocationNgrxActions } from './event-location-ngrx.actions';

export const EVENT_LOCATION_STORE_KEY = 'eventLocationNgrx';

export interface ParentEventLocationNgrxState {
  [EVENT_LOCATION_STORE_KEY]: EventLocationNgrxState;
}

export type EventLocationNgrxStatsTypeParams = EntityStatsTypeParams<
  string,
  'EVENT_LOCATION_NGRX',
  SingleEventStats
>;
export interface EventLocationNgrxState
  extends EntityState<EventLocation>,
    EntityStatsState<EventLocationNgrxStatsTypeParams> {
  loading?: boolean;
}

const adapter = createEntityAdapter<EventLocation>({ selectId });

export const eventLocationReducer = createReducer<EventLocationNgrxState>(
  adapter.getInitialState({}),
  on(eventLocationNgrxActions.set, (state, { entity }) =>
    adapter.upsertOne(entity, state)
  ),

  on(eventLocationNgrxActions.listRecent.req, (state) => ({
    ...state,
    loading: true
  })),
  on(
    eventLocationNgrxActions.listRecent.success,
    (state, { payload: { entities } }) =>
      adapter.upsertMany(entities, {
        ...state,
        loading: false,
        loaded: true
      })
  ),
  on(eventLocationNgrxActions.listRecent.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(eventLocationNgrxActions.listByCompany.req, (state) => ({
    ...state,
    loading: true
  })),
  on(
    eventLocationNgrxActions.listByCompany.success,
    (state, { payload: { entities } }) =>
      adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(eventLocationNgrxActions.listByCompany.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(eventLocationNgrxActions.listByService.req, (state) => ({
    ...state,
    loading: true
  })),
  on(
    eventLocationNgrxActions.listByService.success,
    (state, { payload: { entities } }) =>
      adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(eventLocationNgrxActions.listByService.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(eventLocationNgrxActions.release.req, (state) => ({
    ...state,
    loading: true
  })),
  on(eventLocationNgrxActions.release.success, (state) => ({
    ...state,
    loading: false
  })),
  on(eventLocationNgrxActions.release.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(eventLocationNgrxActions.cancelEvent.req, (state) => ({
    ...state,
    loading: true
  })),
  on(eventLocationNgrxActions.cancelEvent.success, (state) => ({
    ...state,
    loading: false
  })),
  on(eventLocationNgrxActions.cancelEvent.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(
    eventLocationNgrxActions.getStats.success,
    (state, { payload: { stats } }) => ({
      ...state,
      stats: {
        ...state.stats,
        ...stats
      }
    })
  ),

  // **Note** stats aren't being leveraged correctly for this
  // ngrx state, as there isn't any use of the `entityStatsReducerFactory`
  // probably due to the actions used to get stats not being setup
  // for 1-to-1 mapping that the entities-stats utilities are designed for.
  // the `getRegTimes` was designed to build on-top of this but wont
  // since its not setup.
  on(eventLocationNgrxActions.getRegTimes.req, (state) => ({
    ...state,
    loading: true
  })),
  on(
    eventLocationNgrxActions.getRegTimes.success,
    (state, { payload: { eventLocation, flu, screening, all } }) => ({
      ...state,
      stats: {
        ...(state.stats || {}),
        [getId(eventLocation)]: {
          ...(state.stats || {})[getId(eventLocation)],
          flu,
          screening,
          all
        }
      },
      loading: false
    })
  ),
  on(eventLocationNgrxActions.getRegTimes.failed, (state) => ({
    ...state,
    loading: false
  })),
  on(
    eventLocationNgrxActions.removeCustomTime,
    (state, { eventLocation: eventLocationId, time }) => {
      const eventLocation = state.entities[eventLocationId];

      if (!eventLocation) {
        return state;
      }

      const newTimes = (eventLocation.customTimes || []).filter(
        (customTime) => customTime.time !== time
      );

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { customTimes, ...eventLocationWithoutCustomTime } = eventLocation;

      if (!newTimes.length) {
        return {
          ...state,
          entities: {
            ...state.entities,
            [eventLocationId]: {
              ...eventLocationWithoutCustomTime
            }
          }
        };
      }

      return adapter.updateOne(
        {
          id: eventLocationId,
          changes: {
            customTimes: newTimes
          }
        },
        state
      );
    }
  ),

  on(
    eventLocationNgrxActions.updateCustomTime,
    (state, customTimeWithEvent) => {
      const { eventLocation: eventLocationId } = customTimeWithEvent;
      const eventLocation = state.entities[eventLocationId];

      if (!eventLocation) {
        return state;
      }

      const newTimes = (eventLocation.customTimes || []).map((customTime) =>
        customTime.time === customTimeWithEvent.time
          ? {
              ...customTime,
              // Only update these properties
              flu: (customTimeWithEvent as EventLocationCustomTimeFlu).flu,
              screening: (
                customTimeWithEvent as EventLocationCustomTimeScreening
              ).screening
            }
          : customTime
      );

      if (newTimes.length === (eventLocation.customTimes || []).length) {
        // If the length is the same, then we didn't actually find the custom-time,
        // so this is an ADD.
        const newTime: EventLocationCustomTimeBase = {
          time: customTimeWithEvent.time
        };

        if (isEventLocationCustomTimeScreening(customTimeWithEvent)) {
          (newTime as EventLocationCustomTimeScreening).screening =
            customTimeWithEvent.screening;
        }

        if (isEventLocationCustomTimeFlu(customTimeWithEvent)) {
          (newTime as EventLocationCustomTimeFlu).flu = customTimeWithEvent.flu;
        }

        newTimes.push(newTime as EventLocationCustomTime); // This can mutate as its a new reference
      }

      // Otherwise the `.map` would of created a new reference and updated the corresponding
      // time already.
      return adapter.updateOne(
        {
          id: eventLocationId,
          changes: {
            customTimes: newTimes
          }
        },
        state
      );
    }
  ),

  on(eventLocationNgrxActions.updateEventLocationCustomTimes.req, (state) => ({
    ...state,
    loading: true
  })),
  on(
    eventLocationNgrxActions.updateEventLocationCustomTimes.success,
    (state) => ({
      ...state,
      loading: false
    })
  ),
  on(
    eventLocationNgrxActions.updateEventLocationCustomTimes.failed,
    (state) => ({
      ...state,
      loading: false
    })
  )
);
