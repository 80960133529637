import { DbDocument } from './db-document';
import { AdminUser } from './admin-user';
import { ObjectId } from 'mongodb';
import { Company } from './company';
import { User } from './user';
import { UserResult } from './user-result/user-result';
import { UserRegistration } from './user-registration';

export enum AdminAuditAction {
  // Whitespace change actual
  /**
   * When the admin logged in.
   */
  LOGIN = 'login',
  /**
   * If the admin logged in as a specific user
   *   will include `user` in the `meta` attributes
   */
  LOGIN_AS_USER = 'login_as_user',
  /**
   * If the admin reset the password of a user, then we will
   * log that action
   */
  PASSWORD_RESET = 'password_reset',

  /**
   * If the admin removes the mfa of a user, then we will
   * log that action
   */
  MFA_DELETE = 'mfa_delete',

  /**
   * If the admin disables the mfa of a user, then we will
   * log that action
   */
  MFA_DISABLE = 'mfa_disable',

  /**
   * If the admin emables the mfa of a user, then we will
   * log that action
   */
  MFA_ENABLE = 'mfa_enable',

  /**
   * If the admin verified the email of a user, then we will
   * log that action
   */
  VERIFY_EMAIL = 'verify_email',
  /**
   * If the admin exported the hra.
   * will include `company` in the `meta` attributes, if there was one
   */
  EXPORT_HRA = 'export_hra',
  /**
   * If the admin exported the user data.
   * Will include `company` in the `meta` attributes, if there was one
   */
  EXPORT_USER = 'export_user',
  /**
   * If the admin exported the coaching session data
   * Will include `company` in the `meta` attributes
   */
  EXPORT_CS = 'export_cs',
  /**
   * If the admin exported the registrations.
   * will include `company` in the `meta` attributes, if there was one
   */
  EXPORT_REG = 'export_reg',
  /**
   * If the admin imported user results.
   * will include `company` and `sheetId` in the `meta` attributes
   */
  IMPORT_RESULTS = 'import_results',
  /**
   * If the admin created a user registration, includes fingerstick
   * registrations
   */
  CREATE_REGISTRATION = 'create_registration',
  /**
   * If the admin created a partial-user via the fingerstick form, or other means
   * (only fingerstick is )
   */
  CREATE_PARTIAL_USER = 'create_partial_user',
  /**
   * If the admin created a user result via the fingerstick form,
   * or other means
   */
  CREATE_USER_RESULT = 'create_user_result',
  /**
   * If the admin updated the user result via the fingerstick form,
   * or other means
   */
  UPDATE_USER_RESULT = 'update_user_result',

  /**
   * If the admin exported reports to Google Drive
   */
  EXPORT_PDF_TO_DRIVE = 'export_pdf_to_drive',

  /**
   * If the admin created an insurance claim record
   */
  CREATE_INSURANCE_CLAIM = 'create_insurance_claim',
  /** If the PDF insurance claim was generated successfully */
  PDF_INSURANCE_CLAIM = 'pdf_insurance_claim',
  /** If the PDF insurance claim was uploaded to Google Drive successfully */
  UPLOAD_INSURANCE_CLAIM = 'upload_insurance_claim',
  /**
   * Merged another user into this user.
   */
  MERGE_USER_PRIMARY = 'merge_user_primary',
  /**
   * Merged this user into another user, and deleted this user
   */
  MERGE_USER_ORPHANED = 'merge_user_orphaned',
  /**
   * Moved the user to another company
   */
  MOVE_USER_COMPANY = 'move_user_company',
  /**
   * De-synced the previous companies from the user
   */
  DESYNC_USER_DATA = 'desync_user_data',
  /**
   * Unlocked the user's account, after to many re-try attempts
   */
  UNLOCK_USER_ACCOUNT = 'unlock_user_account',
  /**
   * Remove the user's account from the system
   */
  REMOVE_USER = 'remove_user',
  /**
   * If the admin exported to marquee, of any kind.
   */
  MARQUEE_EXPORT = 'marquee_export',
  /**
   * Update user vaccination record
   */
  UPDATE_USER_VACCINATION = 'update_user_vaccination',
  /**
   * Create user vaccination record
   */
  CREATE_USER_VACCINATION = 'create_user_vaccination'
}

export enum AdminAuditActionGroups {
  LOGIN = 'login',
  LOGIN_AS_USER = 'login_as_user',
  PASSWORD_RESET = 'password_reset',
  USER_MANAGEMENT = 'user_management',
  RESULTS = 'results'
}

export const AdminAuditActionGroupMap: {
  [key in AdminAuditActionGroups]: AdminAuditAction[];
} = {
  [AdminAuditActionGroups.LOGIN]: [AdminAuditAction.LOGIN],
  [AdminAuditActionGroups.LOGIN_AS_USER]: [AdminAuditAction.LOGIN_AS_USER],
  [AdminAuditActionGroups.PASSWORD_RESET]: [AdminAuditAction.PASSWORD_RESET],
  [AdminAuditActionGroups.USER_MANAGEMENT]: [
    AdminAuditAction.CREATE_PARTIAL_USER,
    AdminAuditAction.CREATE_REGISTRATION,
    AdminAuditAction.CREATE_INSURANCE_CLAIM,
    AdminAuditAction.MERGE_USER_PRIMARY,
    AdminAuditAction.MERGE_USER_ORPHANED,
    AdminAuditAction.MOVE_USER_COMPANY,
    AdminAuditAction.UNLOCK_USER_ACCOUNT,
    AdminAuditAction.MFA_DELETE,
    AdminAuditAction.VERIFY_EMAIL
  ],
  [AdminAuditActionGroups.RESULTS]: [
    AdminAuditAction.CREATE_USER_RESULT,
    AdminAuditAction.UPDATE_USER_RESULT,
    AdminAuditAction.UPDATE_USER_VACCINATION,
    AdminAuditAction.CREATE_USER_VACCINATION
  ]
};

export interface AdminAudit extends DbDocument {
  /**
   * The admin user who performed this action
   */
  admin: ObjectId | string | AdminUser;
  /**
   * The action performed by the admin
   */
  action: AdminAuditAction;
  /**
   * Extra meta data for the given action type.
   */
  meta: {
    company?: string | ObjectId | Company;
    user?: string | ObjectId | User;
    /**
     * The corresponding user-result, only currently for fingerstick
     */
    userResult?: string | ObjectId | UserResult;
    /**
     * The corresponding user-registration, only currently for fingerstick
     */
    userRegistration?: string | ObjectId | UserRegistration;
    /**
     * Google Drive File ID > Used for Drive uploads
     */
    driveFileId?: string;
  };
}
