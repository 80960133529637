import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  EhsNavActionData,
  EhsNavData,
  EhsNavDataNestedId,
  EhsNavDataType,
  getEhsNavDataType,
  isEhsNavActionData,
  isEhsNavElementData,
  isEhsNavNestedData
} from '../../../models/ehs-nav-data';

@Component({
  selector: 'ehs-side-nav-element',
  templateUrl: './ehs-side-nav-element.component.html',
  styleUrls: ['./ehs-side-nav-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsSideNavElementComponent {
  /**
   * The individual nav data for this element.
   */
  @Input() data?: EhsNavData;

  /**
   * The list of all expanded elements, wil be checked if
   * this element is expanded **If** this element is a nested element.
   *
   * Otherwise, this data is passed into all the children.
   */
  @Input() expandedElements: EhsNavDataNestedId[] = [];

  /**
   * Action emitted from ehs-nav-action elements
   */
  @Output() action = new EventEmitter<EhsNavActionData>();

  /**
   * Event emitted when an element is expaned, so the parent component
   * can manage the expanded state.
   * This could include this element, or nested elements.
   */
  @Output() expandedChange = new EventEmitter<EhsNavData>();

  public get type(): EhsNavDataType {
    return getEhsNavDataType(this.data);
  }

  /**
   * The route from the data, will be an empty array
   * if not a route element.
   */
  public get route(): string | string[] {
    return this.data && isEhsNavElementData(this.data) ? this.data.route : [];
  }

  /**
   * The nested data elements to display, if there are any
   */
  public get nestedData(): EhsNavData[] {
    return this.data && isEhsNavNestedData(this.data) ? this.data.data : [];
  }

  /**
   * If this is a nested element **and** it is expanded, according to the `expandedElements` property
   */
  public get expanded(): boolean {
    return (
      isEhsNavNestedData(this.data) &&
      this.expandedElements.includes(this.data.id)
    );
  }

  public emitAction(data: EhsNavData, event: MouseEvent) {
    event.stopPropagation();

    if (isEhsNavActionData(data)) {
      this.action.emit(data);
    }
  }
}
