import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { EhsUserInfoComponent } from './ehs-user-info.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [EhsUserInfoComponent],
  imports: [
    CommonModule,
    PipesModule,
    // Angular Material
    FlexLayoutModule
  ],
  exports: [EhsUserInfoComponent],
  providers: [DatePipe]
})
export class EhsUserInfoModule {}
