import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HpnImportAudit,
  HpnImportAuditRevertTypes,
  HpnImportCheck
} from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminHpnImportHttpService {
  constructor(private http: HttpClient) {}

  public list(company: string): Observable<HpnImportAudit[]> {
    return this.http.get<HpnImportAudit[]>('api/v1/admin/hpn', {
      params: new HttpParams().append('company', company)
    });
  }

  public create(params: {
    company: string;
    file: File;
  }): Observable<HttpEvent<unknown>> {
    const { company, file } = params;
    const formData = new FormData();

    formData.append('file', file, file.name);

    return this.http.request(
      new HttpRequest('POST', `api/v1/admin/hpn/company/${company}`, formData, {
        reportProgress: true
      })
    );
  }

  /**
   * Reverts the hpn-import by id.
   *
   * the type can be provided to specify `all` or just `results`. If neither are
   * given, then we default to `all`, which was the original endpoint's behavior.
   */
  public revert(
    id: string,
    params?: {
      type?: HpnImportAuditRevertTypes;
    }
  ): Observable<HpnImportAudit> {
    return this.http.delete<HpnImportAudit>(`api/v1/admin/hpn/${id}`, {
      params: new HttpParams().set(
        'type',
        params?.type || HpnImportAuditRevertTypes.ALL
      )
    });
  }

  /**
   * Gets the "check" data for an hpn-import.
   */
  public checkRevert(id: string): Observable<HpnImportCheck> {
    return this.http.get<HpnImportCheck>(`api/v1/admin/hpn/${id}/check`);
  }
}
