import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InsuranceProvider } from '@common';

@Injectable({
  providedIn: 'root'
})
export class AdminInsuranceProviderHttp {
  constructor(private http: HttpClient) {}

  /**
   * Returns all insurance providers as an end user
   */
  public list() {
    return this.http.get<Array<InsuranceProvider>>('api/v1/insurance/list');
  }

  /**
   * Returns all insurance providers as an admin.
   */
  public adminList() {
    return this.http.get<Array<InsuranceProvider>>(
      'api/v1/admin/insurance/list'
    );
  }
}
