import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginErrorResponse, notAuthenticatedResponse } from '@common';
import { combineLatest, Observable } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import { AdminCoreFacade } from '../app-store/admin-core/admin-core.facade';
import { AuthFacade } from '../app-store/auth/auth.facade';
import { clientLogger } from '../client-logger';

@Injectable({
  providedIn: 'root'
})
export class HttpUnauthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authFacade: AuthFacade,
    private adminFacade: AdminCoreFacade
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return combineLatest([this.authFacade.user$, this.adminFacade.user$]).pipe(
      take(1),
      mergeMap(([user, adminUser]) =>
        next.handle(request).pipe(
          tap(
            () => {},
            (err: HttpErrorResponse) => {
              // If the response is a 401 error we redirect UNLESS we are getting a login error
              if (
                err.status === notAuthenticatedResponse.code &&
                !(err.error as LoginErrorResponse).type
              ) {
                if (adminUser) {
                  // If the user was an admin, redirect them to the admin login page
                  this.router.navigate(['/admin/ehs/login']);
                }

                if (user) {
                  // If the user was already logged in, redirect them to login
                  this.router.navigate(['/login']);
                }

                clientLogger.log(
                  'got not authenticated response, clearing state'
                );
                this.authFacade.clear();
                // TODO: Add logic to redirect user to desired route once authenticated
              }
            }
          )
        )
      )
    );
  }
}
