import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EhsEditRegistrationDialogComponent } from './ehs-edit-registration-dialog.component';
import { EhsEditRegistrationDialogService } from './ehs-edit-registration-dialog.service';
import { EhsRegEditFormModule } from '../../components/ehs-reg-edit-form/ehs-reg-edit-form.module';

@NgModule({
  declarations: [EhsEditRegistrationDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    // Angular Material,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    //Sub modules
    EhsRegEditFormModule
  ],
  exports: [EhsEditRegistrationDialogComponent],
  providers: [EhsEditRegistrationDialogService]
})
export class EhsEditRegistrationDialogModule {}
