import { PersonType } from '../person-type';
import { EventServiceServiceType } from './event-service';
import { ProviderHealthTestsRequested } from './provider-health-test-requested';
import { ServiceType } from './service-type';

/**
 * **Note** this does not share anything with site-services
 */
export interface ProviderHealthService {
  type: ServiceType.PROVIDER_HEALTH;
  /**
   * The list of person-types this provider health service
   * supports. Used primarily to define who can sign-up
   * for a given event-service.
   * Added with #704
   */
  personTypes: PersonType[];
  /**
   * The date the user can visit the doctor after.
   * Cannot be earlier.
   * For display only, saved as a date string
   */
  visitAfter: string;
  /**
   * The user can upload the doctors results before this date.
   * After this date, they will no longer be able to upload.
   * saved as a date string.
   */
  returnBy: string;

  /**
   * Definition used to generate PDF
   * @see ProviderHealthTestsRequested
   */
  testsRequested: ProviderHealthTestsRequested;
  showProviderService?: boolean;
}

/**
 * Type-guard that returns if the service is a provider-health-service
 */
export const isProviderHealthService = (
  service: EventServiceServiceType
): service is ProviderHealthService =>
  service?.type === ServiceType.PROVIDER_HEALTH;
