import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayWeight'
})
export class DisplayWeightPipe implements PipeTransform {
  transform(weight?: number): string {
    return weight ? weight + ' lbs.' : 'Not Specified';
  }
}
