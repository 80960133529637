import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import {
  Company,
  getId,
  ProviderHealthUploadFilter,
  toMap,
  ProviderHealthUploadStatus
} from '@common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ehs-provider-health-uploads-filters',
  templateUrl: './ehs-provider-health-uploads-filters.component.html',
  styleUrls: ['./ehs-provider-health-uploads-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsProviderHealthUploadsFiltersComponent {
  @Input() filters: ProviderHealthUploadFilter = {};

  @Input() set companies(companies: Company[]) {
    this.companiesArr = companies.slice(0, 10);

    this.companies$.next(
      toMap({
        key: '_id',
        entities: companies
      })
    );
  }

  /**
   * If the filter changes,
   * **does not include keyup changes** such as firstName, lastName, and company
   */
  @Output() filterChange = new EventEmitter<ProviderHealthUploadFilter>();

  @Output() keyupChange = new EventEmitter<ProviderHealthUploadFilter>();
  @Output() clear = new EventEmitter();
  @Output() search = new EventEmitter();

  public statusOptions = Object.values(ProviderHealthUploadStatus);

  public companiesArr: Company[] = [];

  private companies$ = new BehaviorSubject<Record<string, Company>>({});

  get company() {
    if (!this.filters.company) {
      return '';
    }

    return this.companies$.value[getId(this.filters.company)]?.name || '';
  }

  public onChange(params: ProviderHealthUploadFilter) {
    this.filterChange.emit({ ...this.filters, ...params });
  }

  public onKeyup(params: ProviderHealthUploadFilter) {
    this.keyupChange.emit({ ...this.filters, ...params });
  }

  public displayFn(company: Company | string) {
    return typeof company === 'string' ? company : company?.name || '';
  }

  hasFilters() {
    return !!Object.values(this.filters).filter((_) => !!_).length;
  }
}
