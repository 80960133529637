import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonResponse, UserResult } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserResultHttpService {
  constructor(private http: HttpClient) {}

  public get(id: string): Observable<UserResult> {
    return this.http.get<UserResult>(`api/v1/results/${id}`);
  }

  public getByUserRegId(userRegId: string): Observable<UserResult> {
    return this.http.get<UserResult>(
      `api/v1/results/registrations/${userRegId}`
    );
  }

  public list(): Observable<UserResult[]> {
    return this.http.get<UserResult[]>('api/v1/results');
  }

  public fax(params: {
    userResult: string;
    phone: string;
    physicianName: string;
  }): Observable<CommonResponse> {
    const { userResult, phone, physicianName } = params;

    return this.http.post<CommonResponse>('api/v1/results/fax', {
      userResult,
      phone,
      physicianName
    });
  }
}
