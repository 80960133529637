import { EventLocation } from '@common';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { selectId } from '../utils/select-id';
import { createReducer, Action, on } from '@ngrx/store';
import { eventLocationActions } from './event-location.actions';

export interface EventLocationState extends EntityState<EventLocation> {
  loading: boolean;
}

const adapter = createEntityAdapter<EventLocation>({
  selectId
});

const reducer = createReducer(
  adapter.getInitialState({} as EventLocationState),
  on(eventLocationActions.get, (state: EventLocationState) => ({
    ...state,
    loading: true
  })),
  on(eventLocationActions.set, (state, { entity }) =>
    adapter.upsertOne(entity, state)
  ),
  on(eventLocationActions.setMultiple, (state, { entities }) =>
    adapter.upsertMany(entities, state)
  ),
  on(eventLocationActions.getSuccess, (state: EventLocationState, { entity }) =>
    adapter.addOne(entity, { ...state, loading: false })
  ),
  on(eventLocationActions.getFailed, (state: EventLocationState) => ({
    ...state,
    loading: false
  })),

  on(eventLocationActions.list, (state: EventLocationState) => ({
    ...state,
    loading: true
  })),
  on(
    eventLocationActions.listSuccess,
    (state: EventLocationState, { entities }) =>
      adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(eventLocationActions.listFailed, (state: EventLocationState) => ({
    ...state,
    loading: false
  })),

  on(
    eventLocationActions.listEventLocationByCompany,
    (state: EventLocationState) => ({
      ...state,
      loading: true
    })
  ),
  on(
    eventLocationActions.listEventLocationByCompanySuccess,
    (state: EventLocationState, { entities }) =>
      adapter.upsertMany(entities, {
        ...state,
        loading: false
      })
  ),
  on(
    eventLocationActions.listEventLocationByCompanyFailed,
    (state: EventLocationState) => ({
      ...state,
      loading: false
    })
  ),

  on(eventLocationActions.getAsAdmin, (state) => ({ ...state, loading: true })),
  on(eventLocationActions.getAsAdminSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(eventLocationActions.getAsAdminFailed, (state) => ({
    ...state,
    loading: false
  }))
);

export function EventLocationReducer(
  state: EventLocationState,
  action: Action
) {
  return reducer(state, action);
}
