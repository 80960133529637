import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AdminCoreNgrxFacade } from '@ehs-ngrx/admin-core-ngrx';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { AdminCoreFacade } from '../../core/app-store/admin-core/admin-core.facade';
@Injectable({
  providedIn: 'root'
})
class AdminGuardService {
  constructor(
    private adminFacade: AdminCoreFacade,
    private adminCoreNgrxFacade: AdminCoreNgrxFacade,
    private router: Router,
    private location: Location
  ) {}

  canActivate() {
    this.adminFacade.getAdminUser();

    // This must be setup here initially due to updates with
    // #3742, its still "watched" within the `admin.component` later too
    // **Note** this keeps watching, to keep things in sync
    this.adminFacade.user$
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .subscribe((user) => this.adminCoreNgrxFacade.setAdminUser(user));

    return combineLatest([
      this.adminFacade.loading$,
      this.adminFacade.user$
    ]).pipe(
      // Required for wait for user to load in reducer
      debounceTime(0),
      filter(([loading]) => !loading),
      map(([, user]) => !!user),
      tap((isAdmin) => {
        if (!isAdmin) {
          this.router.navigate(['/ehs/admin/login'], {
            queryParams: {
              redirectUrl: this.location.path()
            }
          });
        }
      })
    );
  }
}

export const AdminGuard: CanActivateFn = () => {
  return inject(AdminGuardService).canActivate();
};
