<div fxLayout.gt-sm="row"
     fxLayout.lt-md="column"
     fxLayoutAlign="start start"
     fxLayoutGap="8px"
     fxFlex
     style="margin-bottom:8px">

  <!--
        TODO: add search elements, and responsive-ness support
        1. User search filtering [X]
        2. Start/End date filtering
          - (against what?)
        3. Has Results/no Results?
      -->
  <ng-container *ngIf="showSearch">
    <mat-form-field fxFlex>
      <mat-label>
        Search
      </mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input matInput
             type="text"
             autocomplete="off"
             #searchInput
             [value]="baseFilters.search || ''"
             (change)="onFiltersChange({search: searchInput.value})"
             (keyup)="onFiltersKeyup({search: searchInput.value})">
    </mat-form-field>
  </ng-container>


  <!-- ONSITE only filters -->
  <ng-container *ngIf="showDateFields">
    <mat-form-field fxFlex
                    *ngIf="!clientAdmin">
      <mat-label>Event Date</mat-label>
      <input matInput
             type="date"
             autocomplete="off"
             #eventDateInput
             placeholder="MM/DD/YYYY"
             [value]="onsiteFilters.eventDate || ''"
             (change)="onFiltersChange({eventDate: eventDateInput.value})"
             (keyup)="onFiltersKeyup({eventDate: eventDateInput.value})">
      <mat-hint>
        The date of the event(s)
      </mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="showDateFields">
    <mat-form-field fxFlex
                    *ngIf="!clientAdmin">
      <mat-label>Start Date</mat-label>
      <input matInput
             type="date"
             autocomplete="off"
             #startDateInput
             placeholder="MM/DD/YYYY"
             [value]="onsiteFilters.startDate || ''"
             (change)="onFiltersChange({startDate: startDateInput.value})"
             (keyup)="onFiltersKeyup({startDate: startDateInput.value})">
      <mat-hint>
        Furthest back to consider
      </mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="showDateFields">
    <mat-form-field fxFlex
                    *ngIf="!clientAdmin">
      <mat-label>End Date</mat-label>
      <input matInput
             type="date"
             autocomplete="off"
             #endDateInput
             placeholder="MM/DD/YYYY"
             [value]="onsiteFilters.startDate || ''"
             (change)="onFiltersChange({startDate: endDateInput.value})"
             (keyup)="onFiltersKeyup({startDate: endDateInput.value})">
      <mat-hint>
        Last date to consider
      </mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!clientAdmin">
    <mat-form-field *ngIf="onsiteFilters.screeningFilter !== 'flu'"
                    fxFlex>
      <mat-label>Admin Search</mat-label>
      <mat-select matInput
                  #adminMatSelect
                  [value]="onsiteFilters.admin || ''"
                  (selectionChange)="onFiltersChange({admin: $any(adminMatSelect.selected).value._id}); onFiltersKeyup({admin: $any(adminMatSelect.selected).value._id})"
                  [compareWith]="compareAdminFn">
        <mat-option *ngFor="let admin of admins"
                    [value]="admin">
          {{admin?.email}}
        </mat-option>
      </mat-select>
      <button *ngIf="adminMatSelect.selected"
              type="button"
              matSuffix
              mat-icon-button
              (click)="$event.stopPropagation(); ({admin: undefined}); onFiltersKeyup({admin: undefined})">
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>
        Search by Admin
      </mat-hint>
    </mat-form-field>
  </ng-container>



  <ng-container *ngIf="!clientAdmin">
    <mat-form-field fxFlex>
      <mat-label>Service Filter</mat-label>
      <mat-select matInput
                  #fluFilterMatSelect
                  [value]="onsiteFilters.screeningFilter || ''"
                  (selectionChange)="onFiltersChange({screeningFilter: $any(fluFilterMatSelect.selected).value}); onFiltersKeyup({screeningFilter: $any(fluFilterMatSelect.selected).value})"
                  (blur)="handleRefresh()"
                  [compareWith]="compareAdminFn">
        <mat-option value="screening">
          Screening
        </mat-option>
        <mat-option value="flu">
          Flu
        </mat-option>
      </mat-select>
      <button *ngIf="fluFilterMatSelect.selected"
              type="button"
              matSuffix
              mat-icon-button
              (click)="$event.stopPropagation(); ({screeningFilter: undefined}); onFiltersKeyup({screeningFilter: undefined})">
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>
        Search by Service
      </mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="showRackSearch">
    <mat-form-field *ngIf="onsiteFilters.screeningFilter !== 'flu'"
                    fxFlex>
      <mat-label>Rack Number Select</mat-label>
      <mat-select matInput
                  #rackMatSelect
                  [value]="onsiteFilters.rack || ''"
                  (selectionChange)="onFiltersChange({rack: $any(rackMatSelect.selected)?.value?._id}); onFiltersKeyup({rack: $any(rackMatSelect.selected)?.value?._id})"
                  [compareWith]="compareRackFn">
        <mat-option *ngFor="let rack of racks"
                    [value]="rack">
          Rack # {{rack?.rackNumber}} - {{rack?.adminEmail || '' | emailName | titlecase}}
        </mat-option>
      </mat-select>
      <button *ngIf="rackMatSelect.selected"
              type="button"
              matSuffix
              mat-icon-button
              (click)="$event.stopPropagation(); ({rack: undefined}); onFiltersKeyup({rack: undefined})">
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>
        Search by Rack Number
      </mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="onsiteFilters.screeningFilter !== 'flu'"
                    fxFlex>
      <mat-label>Released Status</mat-label>
      <mat-select matInput
                  #releasedRackMatSelect
                  [value]="onsiteFilters.released || ''"
                  (selectionChange)="onFiltersChange({released: $any(releasedRackMatSelect.selected).value}); onFiltersKeyup({released: $any(releasedRackMatSelect.selected).value})"
                  [compareWith]="compareAdminFn">
        <mat-option value="true">
          Released
        </mat-option>
        <mat-option value="false">
          Unreleased
        </mat-option>
        <mat-option value="all">
          All
        </mat-option>
      </mat-select>
      <button *ngIf="releasedRackMatSelect.selected"
              type="button"
              matSuffix
              mat-icon-button
              (click)="$event.stopPropagation(); ({released: undefined}); onFiltersKeyup({released: undefined})">
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>
        Search by Rack Status
      </mat-hint>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="onsiteFilters.screeningFilter === 'flu'">
    <div fxFlex>
      <mat-label>Vaccine Lot Number</mat-label>
      <br>
      <mat-hint>{{eventLocation.fluLot}}</mat-hint>
    </div>
  </ng-container>

  <ng-container *ngIf="onsiteFilters.screeningFilter === 'flu'">
    <div fxFlex>
      <mat-label>Vaccine Name</mat-label>
      <br>
      <mat-hint>{{eventLocation.fluVacName}}</mat-hint>
    </div>
  </ng-container>

  <ng-container *ngIf="onsiteFilters.screeningFilter === 'flu'">
    <div fxFlex>
      <mat-label>Vaccine Expiration Date</mat-label>
      <br>
      <mat-hint>{{eventLocation.fluVacExpDate}}</mat-hint>
    </div>
  </ng-container>

  <ng-container *ngIf="onsiteFilters.screeningFilter === 'flu' && onsiteFilters.vaccinationAction !== 'enable'">
    <div fxFlex>
      <button mat-flat-button
              color="primary"
              title="Begin Flu Data Entry"
              (click)="onFiltersChange({vaccinationAction: 'enable'}); onFiltersKeyup({vaccinationAction: 'enable'})"
              (blur)="handleRefresh()">Begin
        Vaccinations</button>
    </div>
  </ng-container>

  <ng-container *ngIf="onsiteFilters.screeningFilter === 'flu' && onsiteFilters.vaccinationAction === 'enable'">
    <div fxFlex>
      <button mat-flat-button
              color="primary"
              title="End Flu Data Entry"
              (click)="onFiltersChange({vaccinationAction: 'disable'}); onFiltersKeyup({vaccinationAction: 'disable'})"
              (blur)="handleRefresh()">End
        Vaccinations</button>
    </div>
  </ng-container>

</div>