import {
  EventService,
  OnsiteRegistrationType,
  ServiceType,
  UserInsurance,
  UserRegistration
} from '@common';
import { createAction, props } from '@ngrx/store';
import { UserRegState } from './user-reg.state';

export const userRegActions = {
  clear: createAction('[USER_REG] CLEAR'),
  setCoachingId: createAction(
    '[USER_REG] SET_COACHING_ID',
    props<{
      coachingId: string;
    }>()
  ),
  setServiceType: createAction(
    '[USER_REG] SET_SERVICE_TYPE',
    props<{
      serviceType: ServiceType;
    }>()
  ),
  setAdditionalRegistrationFlag: createAction(
    '[USER_REG] SET_ADDITIONAL_REGISTRATION_FLAG',
    props<{
      isAdditional: boolean;
    }>()
  ),
  setInsuranceInformation: createAction(
    '[USER_REG] SET_INSURANCE_INFORMATION',
    props<{
      insurance: Partial<UserInsurance>;
    }>()
  ),
  setCoaching: createAction(
    '[USER_REG] SET_COACHING',
    props<{
      coaching: boolean;
    }>()
  ),
  setEventService: createAction(
    '[USER_REG] SET_EVENT_SERVICE',
    props<{
      eventService: EventService;
    }>()
  ),
  setOnsiteTypes: createAction(
    '[USER_REG] SET_ONSITE_SERVICES',
    props<{
      onsiteTypes: OnsiteRegistrationType[];
    }>()
  ),
  clearEventService: createAction('[USER_REG] CLEAR_EVENT_SERVICE'),
  setState: createAction(
    '[USER_REG] SET_STATE',
    props<{
      state: UserRegState;
    }>()
  ),
  setEventLocation: createAction(
    '[USER_REG] SET_EVENT_LOCATION',
    props<{
      eventLocation: string;
      eventTime: string;
      eventService: string;
      eventDate: string;
    }>()
  ),
  setEventDateTime: createAction(
    '[USER_REG] SET_EVENT_DATE_TIME',
    props<{
      eventTime: string;
      eventDate: string;
    }>()
  ),
  clearEventLocation: createAction('[USER_REG] CLEAR_EVENT_LOCATION'),
  setCreatedUserRegistration: createAction(
    '[USER_REG] SET_CREATED_USER_REGISTRATION',
    props<{ entity: UserRegistration }>()
  ),
  setRedirectToOnsiteSummary: createAction(
    '[USER_REG] SET_REDIRECT_TO_ONSITE_SUMMARY',
    props<{
      redirectToOnsiteSummary: boolean;
    }>()
  )
};
