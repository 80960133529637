import {
  EventServiceUtil,
  getId,
  IncentiveUtil,
  MarqueeIncentiveTypes
} from '@common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app-state';
import { companySelectors } from '../company/company.selectors';
import { EventServiceState } from './event-service.state';
import { authSelectors } from '../auth/auth.selectors';

const featureSelector = createFeatureSelector<EventServiceState>(
  'eventService' as keyof AppState
);

const entitiesSelector = createSelector(
  featureSelector,
  (state) => state.entities
);
const idsSelector = createSelector(featureSelector, (state) => state.ids);
const entitiesArrSelector = createSelector(
  idsSelector,
  entitiesSelector,
  (ids, entities) => ((ids || []) as string[]).map((id) => (entities || {})[id])
);
const loadingSelector = createSelector(
  featureSelector,
  (state) => state.loading
);

/**
 * Selector for services that are still applied to the user's account
 */
const relevantServicesSelector = createSelector(
  entitiesArrSelector,
  (eventServices) =>
    eventServices.filter((eventService) =>
      EventServiceUtil.isRelevantEventService({ eventService })
    )
);

const currentCompanyRelevantServicesSelector = createSelector(
  entitiesArrSelector,
  companySelectors.companySelector,
  (eventServices, company) =>
    eventServices.filter((eventService) =>
      EventServiceUtil.isRelevantEventService({ eventService, company })
    )
);

const afterServiceEndAccessSelector = createSelector(
  entitiesArrSelector,
  companySelectors.companySelector,
  (eventServices, company) =>
    eventServices.filter((eventService) =>
      EventServiceUtil.allowAfterServiceEndAccess({ eventService, company })
    )
);

const afterServiceHraSelector = createSelector(
  entitiesArrSelector,
  companySelectors.companySelector,
  (eventServices, company) =>
    eventServices.filter((eventService) =>
      EventServiceUtil.allowHraAfterServiceEndAccess({ eventService, company })
    )
);

const hasEmmySelector = createSelector(
  afterServiceEndAccessSelector,
  (eventServices) =>
    !!eventServices.find(
      (eventService) =>
        eventService.healthEducation &&
        eventService.showHealthEducation !== false
    )
);

const hasMarqueeChallengesSelector = createSelector(
  afterServiceEndAccessSelector,
  (eventServices) =>
    !!eventServices.find(
      (eventService) => eventService.marqueeChallenges?.enabled
    )
);

const hasMarqueeChallengesRunnerSelector = createSelector(
  afterServiceEndAccessSelector,
  (eventServices) =>
    !!eventServices.find(
      (eventService) => eventService.marqueeChallengeRunner?.enabled
    )
);

const getMarqueeIncentivesSelector = createSelector(
  afterServiceEndAccessSelector,
  (eventServices) =>
    eventServices.reduce((incentiveTypes, eventService) => {
      if (!eventService.marqueeIncentives?.enabled) {
        return incentiveTypes;
      }

      if (!eventService.marqueeIncentives?.incentiveTypes?.length) {
        return incentiveTypes;
      }

      incentiveTypes.push(
        ...(eventService.marqueeIncentives.incentiveTypes || [])
      );

      return incentiveTypes;
    }, [] as MarqueeIncentiveTypes[] | undefined)
);

const hasMarqueeCoachingSelector = createSelector(
  afterServiceEndAccessSelector,
  (eventServices) =>
    !!eventServices.find(
      (eventService) => eventService.marqueeCoaching?.enabled
    )?.marqueeCoaching
);

/** Need to get all for company, as we want to continue showing incentives even if the end date has passed for the event service */
const allForCompanySelector = createSelector(
  entitiesArrSelector,
  companySelectors.companySelector,
  (eventServices, company) =>
    eventServices.filter(
      (eventService) => getId(eventService.company) === getId(company)
    )
);

const hasIncentivesEnabledSelector = createSelector(
  allForCompanySelector,
  authSelectors.userSelector,
  (eventServices, user) =>
    !!(eventServices || []).filter((eventService) =>
      IncentiveUtil.isIncentiveEnabled({ eventService, user })
    ).length
);

const hasReimbursementsEnabledSelector = createSelector(
  relevantServicesSelector,
  authSelectors.userSelector,
  (eventServices, user) =>
    !!(eventServices || []).filter((eventService) =>
      EventServiceUtil.isReimbursementEnabled({ eventService, user })
    ).length
);

const getIncentivesEnabledSelector = createSelector(
  allForCompanySelector,
  authSelectors.userSelector,
  (eventServices, user) =>
    (eventServices || [])
      .filter((eventService) =>
        IncentiveUtil.isIncentiveEnabled({ eventService, user })
      )
      .sort((a, b) => {
        if (a.startDate > b.startDate) {
          return -1;
        }

        if (a.startDate < b.startDate) {
          return 1;
        }

        return 0;
      })
);

const getReimbursementsEnabledSelector = createSelector(
  relevantServicesSelector,
  authSelectors.userSelector,
  (eventServices, user) =>
    (eventServices || [])
      .filter((eventService) =>
        EventServiceUtil.isReimbursementEnabled({ eventService, user })
      )
      .sort((a, b) => {
        if (a.startDate > b.startDate) {
          return -1;
        }

        if (a.startDate < b.startDate) {
          return 1;
        }

        return 0;
      })
);

export const eventServiceSelectors = {
  // Core selectors
  entitiesSelector,
  idsSelector,
  entitiesArrSelector,
  loadingSelector,

  // Calculated selectors
  relevantServicesSelector,
  currentCompanyRelevantServicesSelector,
  afterServiceEndAccessSelector,
  afterServiceHraSelector,
  hasEmmySelector,
  hasMarqueeChallengesSelector,
  hasMarqueeChallengesRunnerSelector,
  hasMarqueeCoachingSelector,
  getMarqueeIncentivesSelector,
  hasIncentivesEnabledSelector,
  getIncentivesEnabledSelector,
  getReimbursementsEnabledSelector,
  hasReimbursementsEnabledSelector
};
