import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import {
  CountyCodes,
  COUNTY_CODES,
  DateUtil,
  deepClone,
  Ethnicities,
  Genders,
  getEthnicity,
  getRace,
  heightFeet,
  heightInches,
  isNonPartialUser,
  PartialUser,
  PersonType,
  personTypeNames,
  Races,
  States,
  STATE_NAMES,
  User,
  UserUtil,
  UserWithWeight
} from '@common';
import {
  EhsUserForm,
  EhsUserFormFieldTypes
} from '../ehs-form-field-layout/ehs-form-field-types';
import { EhsFormFieldLayout } from '../ehs-form-field-layout/ehs-form-field-layout';

/**
 * This component is a re-usable, configurable dynamic form that can be used
 * to edit end-users within the application.
 */
@Component({
  selector: 'ehs-user-form',
  templateUrl: './ehs-user-form.component.html',
  styleUrls: ['./ehs-user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class EhsUserFormComponent<
  UserType extends Partial<
    UserWithWeight | User | PartialUser
  > = Partial<PartialUser>
> {
  public readonly fieldTypes = EhsUserFormFieldTypes;
  public readonly feet = heightFeet;
  public readonly inches = heightInches;
  public readonly stateNames = STATE_NAMES;
  public readonly countyCodesMap = COUNTY_CODES;
  public readonly races = Object.values(Races);
  public readonly ethnicities = Object.values(Ethnicities);
  public readonly states = Object.keys(States);
  public readonly countyCodes = Object.keys(CountyCodes);
  public readonly ssnRegExp = UserUtil.ssnRegExp;
  public readonly genders = Object.values(Genders);
  public readonly personTypes = Object.values(PersonType);
  /**
   * The user object we are to edit, which we will deep clone internally
   * to prevent any external state changes.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('user') set _user(user: UserType | null) {
    this.user = deepClone({
      ...(user
        ? user
        : {
            lastName: '',
            ssn: '',
            partial: true
          }),
      birthDay: DateUtil.formatDate(user?.birthDay)
    } as UserType);
  }

  /**
   * Event that is emitted when the component changes the user data internally.
   * This can emit often as it fires when the user changes any field within
   * the form.
   *
   * **note** not fully supported yet
   */
  @Output() userChange = new EventEmitter<UserType>();
  /**
   * The layout object of the field in the form for the user.
   */
  @Input() layout: EhsFormFieldLayout<EhsUserForm> | null;
  /**
   * Internal state of the user used within the form.
   */

  @Output() memberTypeChange = new EventEmitter<PersonType>();
  public user?: UserType;

  /**
   * Type-guard used for the template to know
   * if a given user is a non-partial user. Required for specific fields.
   *
   * We internally assume the user has weight.
   */
  public get nonPartialUser() {
    return isNonPartialUser(this.user)
      ? (this.user as UserWithWeight)
      : undefined;
  }

  // TODO: move to pipe
  public getRace(race: Races) {
    return getRace(race)?.description || '';
  }

  // TODO: move to pipe
  public getEthnicity(ethnicity: Ethnicities) {
    return getEthnicity(ethnicity)?.description || '';
  }

  public formatDate(value: string) {
    this.user.birthDay = DateUtil.formatDate(value);
  }

  getPersonTypeName(personType: PersonType): string {
    return personTypeNames[personType] || '';
  }

  public getStateName(state: States) {
    return this.stateNames[state];
  }

  public handleMemberTypeChange(personType: PersonType) {
    this.memberTypeChange.emit(personType);
  }
}
