import { COVIDAdministrationNotes } from '../constants/covid-administration-note';
import { COVIDObservationValues } from '../constants/covid-observation-values';
import { ShotLocations } from '../constants/shot-locations';
import { COVIDVaccineDefMap, COVIDVaccines } from '../constants/vaccines';
import { VaccineAction } from '../models/vaccine/vaccine-action';
import { VaccineCompletionStatus } from '../models/vaccine/vaccine-completion-status';

/**
 * Static utility class to help interact with the different types of vaccine.
 */
export class VaccineUtil {
  /**
   * Returns an end user readable version of the manufacturer
   */
  public static getManufacturer(vaccine: COVIDVaccines): string {
    if (!vaccine) {
      return '';
    }
    const def = COVIDVaccineDefMap[vaccine];

    if (!def) {
      return '';
    }

    return def.manufacturer;
  }

  /**
   * Returns human readable completion statuses
   * Default to CP
   *  CP for Complete
   *  NA for Not Administered
   *  PA for Partially Administered
   *  RE for Refused
   */
  public static getCompletionStatus(status: VaccineCompletionStatus) {
    return (
      (
        {
          [VaccineCompletionStatus.COMPLETE]: 'Complete',
          [VaccineCompletionStatus.NOT_ADMINISTERED]: 'Not Administered',
          [VaccineCompletionStatus.PARTIALLY_ADMINISTERED]:
            'Partial Administered',
          [VaccineCompletionStatus.REFUSED]: 'Refused'
        } as Record<VaccineCompletionStatus, string>
      )[status] || ''
    );
  }

  /**
   * Returns an end user readable version of the vaccine action code.
   * Will return "Add" by default
   */
  public static getVaccineAction(action: VaccineAction): string {
    switch (action) {
      case VaccineAction.DELETE:
        return 'Delete';
      case VaccineAction.UPDATE:
        return 'Update';
      default:
      case VaccineAction.ADD:
        return 'Add';
    }
  }

  public static getShotLocation(shotLocation: ShotLocations): string {
    return (
      (
        {
          [ShotLocations.LEFT_ARM]: 'Left Arm',
          // [ShotLocations.LEFT_DELTOID]: 'Left Deltoid',
          // [ShotLocations.LEFT_GLUTEOUS_MEDIUS]: 'Left Gluteous Medius',
          // [ShotLocations.LEFT_LOWER_FOREARM]: 'Left Lower Forearm',
          // [ShotLocations.LEFT_THIGHT]: 'Left Thigh',
          // [ShotLocations.LEFT_VASTUS_LATERALIS]: 'Left Vastus Lateralis',

          [ShotLocations.RIGHT_ARM]: 'Right Arm'
          // [ShotLocations.RIGHT_DELTOID]: 'Right Deltoid',
          // [ShotLocations.RIGHT_GLUTEOUS_MEDIUS]: 'Right Gluteous Medius',
          // [ShotLocations.RIGHT_LOWER_FOREARM]: 'Right Lower Forearm',
          // [ShotLocations.RIGHT_THIGHT]: 'Right Thigh',
          // [ShotLocations.RIGHT_VASTUS_LATERALIS]: 'Right Vastus Lateralis'
        } as Record<ShotLocations, string>
      )[shotLocation] || ''
    );
  }

  /**
   * Returns human readable administration note
   * * Source:
   * https://docs.google.com/spreadsheets/d/1_ovR3xbrUhAirJ4vSQ1LDtVQonWCihDjgJxBoYxHQVA/edit#gid=909717084
   */
  public static getAdministrationNote(
    administrationNote: COVIDAdministrationNotes
  ): string {
    return (
      {
        [COVIDAdministrationNotes['00']]: 'Administered Vaccine',
        [COVIDAdministrationNotes['01']]:
          'Historical Vaccination from Source Unspecified',
        [COVIDAdministrationNotes['02']]:
          'Historical information - From other provider',
        [COVIDAdministrationNotes['03']]:
          // tslint:disable-next-line: quotemark
          "Historical information - From parent's written record",
        [COVIDAdministrationNotes['04']]:
          'Historical information - From parents',
        [COVIDAdministrationNotes['05']]:
          'Historical information - From other registry',
        [COVIDAdministrationNotes['06']]:
          'Historical information - From other birth certificate',
        [COVIDAdministrationNotes['07']]:
          'Historical information - From school record',
        [COVIDAdministrationNotes['08']]:
          'Historical information - From public agency'
      }[administrationNote] || ''
    );
  }

  public static getObservationValue(
    observationValue: COVIDObservationValues
  ): string {
    return (
      (
        {
          [COVIDObservationValues.V01]: 'Not VFC eligible',
          [COVIDObservationValues.V02]: 'Minnesota Health Care Program (MHCP)',
          [COVIDObservationValues.V03]: 'Uninsured',
          [COVIDObservationValues.V04]: 'American Indian/ Alaskan Native',
          [COVIDObservationValues.V05]:
            'Underinsured (Patients with private insurance that does not cover the vaccine itself or has a cap)'
        } as Record<COVIDObservationValues, string>
      )[observationValue] || ''
    );
  }
}
