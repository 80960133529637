import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company, CompanySecurity, CompanyStats } from '@common';
import { Observable } from 'rxjs';
import { QueryList, QueryListService } from '../../util/query-list.service';

@Injectable({
  providedIn: 'root'
})
export class AdminCompanyHttpService {
  private baseUrl = 'api/v1/admin';
  private clientAdminUrl = 'api/v1/client';

  constructor(private http: HttpClient, private queryList: QueryListService) {}

  /**
   * Lists the companies for the given query
   */
  public listCompanies(query: QueryList<Company>): Observable<Company[]> {
    // Check if user is admin or client-admin

    return this.http.get<Company[]>('api/v1/admin/company/list', {
      params: this.queryList.build(query)
    });
  }

  /**
   * Searches companies from the given query
   */
  public searchCompanies(query: string): Observable<Company[]> {
    return this.http.get<Company[]>('api/v1/admin/company/search', {
      params: new HttpParams().append('query', query)
    });
  }

  /**
   * Returns the company for the given id
   */
  public getCompany(
    companyId: string,
    params?: { clientAdmin: boolean }
  ): Observable<Company> {
    return this.http.get<Company>(
      `${
        params?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/company/${companyId}`
    );
  }

  /**
   * Returns the company security for the given company
   */
  public getCompanySecurity({
    companyId
  }: {
    companyId: string;
  }): Observable<CompanySecurity> {
    return this.http.get<CompanySecurity>(
      `${this.baseUrl}/company/${companyId}/security`
    );
  }

  /**
   * Searches for the company by the given id and returns the notes
   * in the company's record.
   */
  public getCompanyNotes({
    companyId
  }: {
    companyId: string;
  }): Observable<{ companyNotes: string }> {
    return this.http.get<{ companyNotes: string }>(
      `${this.baseUrl}/company/${companyId}/notes`
    );
  }

  /**
   * Returns the company security for the given company after it is updated
   */
  public updateCompanySecurity({
    companyId,
    companySecurity
  }: {
    companyId: string;
    companySecurity: Partial<CompanySecurity>;
  }): Observable<CompanySecurity> {
    return this.http.post<CompanySecurity>(
      `${this.baseUrl}/company/${companyId}/security`,
      companySecurity
    );
  }

  /**
   * Searches for the company by the given id and updates the notes
   */
  public updateCompanyNotes({
    companyId,
    notes
  }: {
    companyId: string;
    notes: string;
  }): Observable<{ companyNotes: string }> {
    return this.http.post<{ companyNotes: string }>(
      `${this.baseUrl}/company/${companyId}/notes`,
      { notes }
    );
  }

  /**
   * Returns the company stats for the given id.
   */
  public getStats(
    companyId: string,
    params?: { clientAdmin: boolean }
  ): Observable<CompanyStats> {
    return this.http.get<CompanyStats>(
      `${
        params?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/company/${companyId}/stats`
    );
  }
}
