import { User, UserWithRegistrations } from '@common';
import {
  entitySelectionSelectorFactory,
  entitySelectorsFactory
} from '@ehs-ngrx/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ParentUserNgrxState,
  UserNgrxState,
  USER_STORE_KEY
} from './user-ngrx.state';

export const userNgrxFeatureSelector =
  createFeatureSelector<UserNgrxState>(USER_STORE_KEY);

const baseSelectors = entitySelectorsFactory<
  User | UserWithRegistrations,
  string,
  ParentUserNgrxState,
  UserNgrxState
>({
  featureSelector: userNgrxFeatureSelector
});

export const userNgrxSelectors = {
  ...baseSelectors,

  hasNextSelector: createSelector(
    userNgrxFeatureSelector,
    (state) => !!state.hasNext
  ),

  pageNumberSelector: createSelector(
    userNgrxFeatureSelector,
    (state) => state.pageNumber
  ),

  actionLoadingSelector: createSelector(
    userNgrxFeatureSelector,
    (state) => !!state.actionLoading
  ),

  ...entitySelectionSelectorFactory({
    entitiesArraySelector: baseSelectors.entitiesArraySelector,
    featureSelector: userNgrxFeatureSelector
  })
};
