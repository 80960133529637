<h1 mat-dialog-title>
  Change Service Types
</h1>

<p style="color:red">{{validationError}}</p>

<mat-dialog-content>
  <div class="mat-body-1"
       *ngIf="serviceTypes">
    <ng-container *ngIf="!serviceTypes.length">
      <div>
        No service types to change
      </div>
    </ng-container>
    <div fxLayout="column"
         fxLayoutAlign="start start"
         fxLayoutGap="16px">
      <div *ngFor="let serviceType of serviceTypes">
        <mat-checkbox #additionalTestCheckbox
                      (change)="toggleServiceTypes(serviceType)"
                      [checked]="isServiceChecked(serviceType)"
                      color="primary">
          {{serviceType.replace('_',' ') | titlecase}}
        </mat-checkbox>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end"
                    fxLayoutGap="16px">
  <button type="button"
          class="grey-cancel"
          mat-button
          [mat-dialog-close]="undefined">
    Cancel
  </button>
  <button type="button"
          color="primary"
          mat-button
          (click)="close()"
          cdkFocusInitial>
    Confirm
  </button>
</mat-dialog-actions>
