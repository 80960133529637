import { ObjectId } from 'mongodb';
import { UserTest } from '../user-test/user-test';
import { ConditionalUserTest } from './conditional-user-test';
import { ServiceType } from './service-type';

/**
 * @deprecated
 */
export enum ScreeningPackageType {
  /**
   * Represents a list of 7 tests/services.
   * reference on-site package field in GWApps
   */
  PANEL_C = 'panelC',

  /**
   * Added as part of issue #72 example data, could
   * change in the future
   */
  LIPID_AND_GLUCOSE = 'Lipid & Glucose'
  // TODO: add other screening package types
}

/**
 * The type of service. This is not to be confused
 * with the onsite-event-service, which is more
 * like a "service-package".
 */
export enum SiteServiceTypes {
  SCREENING = 'screening',
  ADDITIONAL_SERVICES = 'additionalServices',
  ADDITIONAL_TESTS = 'additionalTests',
  OPTIONAL_TESTS = 'optionalTests',
  VACCINATIONS = 'vaccinations',
  OTHER = 'other'
}

/**
 * The types of vaccination types
 */
export enum SiteVaccinationType {
  FLU = 'flu',
  /**
   * **Note** this only indicates that we are to check the event-location
   * for a covid-vaccination support for that location.
   */
  COVID_19 = 'covid_19'
}

/**
 * The site-conditional-self-report data
 */
export interface SiteConditionalSelfReported {
  height: SiteServiceConditionalSelfReportedVisibility;
  weight: SiteServiceConditionalSelfReportedVisibility;
  bloodPressure: SiteServiceConditionalSelfReportedVisibility;
  usesTobacco: SiteServiceConditionalSelfReportedVisibility;
}
/**
 * Enum representing the different visibility statuses the self-reported
 * for site-services's conditional self-reported visibilities.
 */
export enum SiteServiceConditionalSelfReportedVisibility {
  OFFERED = 'offered',
  NOT_OFFERED = 'not_offered',
  REQUIRED = 'required'
}
/**
 * A site service can be onsite, or offsite.
 * This is a base type that represents the common information
 * between the onsite and offsite services.
 *
 * Both of these services are "services" that are defined
 * within an event-service, which acts as a general "package"
 * of services and other information.
 */
export interface SiteService {
  type: ServiceType;
  /**
   * Panel for this service. Only 1 supported at a time
   */
  panel?: string | ObjectId | UserTest;
  /**
   * Array of conditional tests that are applied depending on the user
   */
  conditionalPanels: Array<ConditionalUserTest>;
  /**
   * List of additional services/tests always provided to the user.
   * Additional services to be included with lab panel
   */
  additionalServices: Array<string | ObjectId | UserTest>;
  /**
   * Array of conditional additional services that are applied depending on
   * the user.
   */
  conditionalAdditionalServices: Array<ConditionalUserTest>;
  /**
   * List of optional services/tests available to the user to personally choose
   * note: this has been slightly changed after #1125
   */
  optionalTests: Array<string | ObjectId | UserTest>;
  /**
   * Array of conditional optional tests that are shown depending on
   * the user. The user will still be able to "opt-in".
   */
  conditionalOptionalTests: Array<ConditionalUserTest>;
  /**
   * Additional tests to be included with lab panel
   */
  additionalTests: Array<string | ObjectId | UserTest>;
  /**
   * Array of conditional tests that are applied depending on the
   * user.
   */
  conditionalAdditionalTests: Array<ConditionalUserTest>;
  /**
   * The type of Vaccination that will be administered,
   * Could be a covid vaccine type, OR flu
   */
  vaccinationType?: SiteVaccinationType[];
  /**
   * Flags that determine the display conditions for conditional self-reported data.
   */
  conditionalSelfReported: SiteConditionalSelfReported;
}
