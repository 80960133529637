<div class="ehs-provider-health-uploads-table ehs-table-wrapper">
  <cdk-virtual-scroll-viewport itemSize="40"
                               minBufferPx="40"
                               maxBufferPx="500"
                               class="viewport">
    <table class="ehs-table sticky-header">
      <tr>
        <th *ngFor="let col of columns; let i = index"
            [style.top]="headerTop"
            [style.--width]="i === columns.length - 1 ? '100%' : col.width">
          {{ col.title }}
        </th>
      </tr>

      <tr *cdkVirtualFor="let row of rows"
          (click)="rowClicked.emit({userRegistration:row._id,company:row?.company?._id || '', eventService:row.eventService})"
          class="row">
        <td *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.prop">
            <ng-container *ngSwitchCase="'name'">
              {{ row.userSnapshot?.firstName }} {{ row.userSnapshot?.lastName }}
            </ng-container>
            <ng-container *ngSwitchCase="'email'">
              {{ row.userSnapshot?.email }}
            </ng-container>
            <ng-container *ngSwitchCase="'companyName'">
              {{ row.company?.name || '' }}
            </ng-container>
            <ng-container *ngSwitchCase="'returnBy'">
              {{ getReturnBy(row) | date: 'mediumDate': '-00:00' }}
            </ng-container>
            <ng-container *ngSwitchCase="'updatedAt'">
              {{ row.updatedAt | date: 'mediumDate': '-00:00' }}
            </ng-container>
            <ng-container *ngSwitchCase="'status'">
              <mat-chip-listbox>
                <mat-chip-option [ngStyle]="{background:getChipColor(row.uploadStatus)}"
                                 selected
                                 style="font-size:12px">
                  {{( row.uploadStatus.replace('_', ' ') | titlecase ) || 'Not
                  Uploaded'}}
                </mat-chip-option>
              </mat-chip-listbox>
            </ng-container>
            <ng-container *ngSwitchCase="'uploadFiles'">
              <ng-container *ngFor="let file of row.uploadFiles || []">
                <button type="button"
                        color="primary"
                        mat-stroked-button
                        (click)="$event.stopImmediatePropagation(); handleDownloadProviderHealthForm({userRegistration:row,file})">
                  <mat-icon [title]="file.fileName"
                            style="margin:0"
                            color="warn">picture_as_pdf</mat-icon>
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span [title]="row[col.prop]">{{ row[col.prop] }}</span>
            </ng-container>
          </ng-container>
        </td>
      </tr>

      <tr *ngIf="hasNext">
        <button (click)="loadMore.emit()"
                style="margin: 16px 8px 24px"
                mat-flat-button
                color="primary"
                type="button">
          Load More
        </button>
      </tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>