import { Pipe, PipeTransform } from '@angular/core';
import { UserCriticalResult, UserTestUtil } from '@common';

@Pipe({
  name: 'userTestRangeValues'
})
export class UserTestRangeValuesPipe implements PipeTransform {
  transform(criticalResult?: UserCriticalResult): string {
    if (!criticalResult) {
      return '';
    }

    return UserTestUtil.getNormalRangeNumbers(criticalResult);
  }
}
