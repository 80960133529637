import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../pipes/pipes.module';
import { EhsRegTableComponent } from './ehs-reg-table.component';
import { EhsRegFiltersModule } from '../ehs-reg-filters/ehs-reg-filters.module';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [EhsRegTableComponent],
  imports: [
    PipesModule,

    // Angular
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ScrollingModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    EhsRegFiltersModule,
    MatRadioModule
  ],
  exports: [EhsRegTableComponent]
})
export class EhsRegTableModule {}
