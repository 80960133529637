<div fxLayout="row wrap"
     fxLayoutAlign="start start"
     fxLayoutGap="8px"
     style="margin-bottom:8px">

  <!-- First Name -->
  <mat-form-field style="width: 200px">
    <mat-label>
      First Name
    </mat-label>
    <input matInput
           autocomplete="off"
           [value]="filters.firstName || ''"
           (change)="onChange({ firstName: $any($event.target)?.value })"
           type="text">
    <button mat-icon-button
            matSuffix
            *ngIf="filters.firstName"
            type="button"
            title="Clear first name"
            (click)="onChange({ firstName: undefined })"
            aria-label="Clear first name">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Last Name -->
  <mat-form-field style="width: 200px">
    <mat-label>
      Last Name
    </mat-label>
    <input matInput
           autocomplete="off"
           [value]="filters.lastName || ''"
           (change)="onChange({ lastName: $any($event.target)?.value })"
           type="text">
    <button mat-icon-button
            matSuffix
            *ngIf="filters.lastName"
            type="button"
            (click)="onChange({ lastName: undefined })"
            title="Clear last name"
            aria-label="Clear last name">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Status -->
  <mat-form-field style="max-width: 160px;">
    <mat-label>Status</mat-label>
    <mat-select [value]="filters.uploadStatus || []"
                [multiple]="true"
                (selectionChange)="onChange({ uploadStatus: $event.value })">
      <mat-option *ngFor="let statusOption of statusOptions"
                  [value]="statusOption">
        {{ statusOption.replace('_',' ') | titlecase }}
      </mat-option>
    </mat-select>
    <button mat-icon-button
            matSuffix
            (click)="$event.stopPropagation(); onChange({ uploadStatus: undefined })"
            *ngIf="filters.uploadStatus"
            type="button"
            title="Clear Status"
            aria-label="Clear Status">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Company</mat-label>
    <input type="text"
           matInput
           autocomplete="off"
           [value]="company"
           (keyup)="onKeyup({ company: $any($event.target).value })"
           [matAutocomplete]="companyAuto"
           #companyInput>
    <mat-autocomplete #companyAuto="matAutocomplete"
                      [displayWith]="displayFn"
                      (optionSelected)="onChange({
                        company: $event.option.value
                      })">
      <mat-option *ngFor="let comp of companiesArr"
                  [value]="comp">
        {{comp?.name}}
      </mat-option>
    </mat-autocomplete>

    <button mat-icon-button
            matSuffix
            *ngIf="companyInput.value"
            type="button"
            title="Clear company"
            (click)="onChange({company: undefined}); companyInput.value = ''"
            aria-label="Clear company">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Search Button -->
  <div class="align-with-mat-field-with-hint-start">
    <button mat-flat-button
            type="button"
            color="primary"
            style="height: 50px;"
            (click)="search.emit()">
      <mat-icon class="search-button">search</mat-icon>
    </button>
  </div>

  <!-- Clear Button -->
  <div class="align-with-mat-field-with-hint-start">
    <button mat-flat-button
            *ngIf="filters"
            title="Clear filterss"
            type="button"
            color="accent"
            style="height: 50px;"
            (click)="clear.emit();">
      <mat-icon class="search-button">close</mat-icon>
    </button>
  </div>

</div>
