import { LabResultCode } from '../models/user-test/user-test';
import { ONSITE_BIO_FORM_CODE_MAP } from '../requests/onsite-result-form';

/**
 * The min weight supported across the platform
 */
export const MIN_WEIGHT = 50;
/**
 * The max weight supported across the platform
 */
export const MAX_WEIGHT = 600;

/**
 * These are the Lab Result Codes for Weight
 * Those are static values to get the value of the Weight from the userResult.tests
 */
export const EHS_WEIGHT_RESULT_CODE = LabResultCode('4201');
export const QUEST_WEIGHT_RESULT_CODE = LabResultCode('97014703');
export const LABCORP_WEIGHT_RESULT_CODE = LabResultCode('101149');

/**
 * Get the list of all result codes that return the user Weight
 * 101149 is the Labcorp Weight Result Code
 * 97014703 is the Quest Weight Result Code
 */
export const weightResultCodes = [
  ONSITE_BIO_FORM_CODE_MAP.weight,
  LABCORP_WEIGHT_RESULT_CODE,
  QUEST_WEIGHT_RESULT_CODE
];
