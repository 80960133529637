import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EhsServiceWaiverListComponent } from './ehs-service-waivers-list.component';
import { EhsCallToActionModule } from '../ehs-call-to-action/ehs-call-to-action.module';

@NgModule({
  declarations: [EhsServiceWaiverListComponent],
  imports: [
    CommonModule,
    RouterModule,
    // Angular Material
    FlexLayoutModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    MatProgressSpinnerModule,

    //Ehs ui
    EhsCallToActionModule
  ],
  exports: [EhsServiceWaiverListComponent]
})
export class EhsServiceWaiverListModule {}
