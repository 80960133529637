import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, createSelector, select, Store } from '@ngrx/store';
import { merge, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppState } from '../app-state';
import { companyActions } from './company.actions';
import { companySelectors } from './company.selectors';
import { eventServiceSelectors } from '../event-service/event-service.selectors';

@Injectable({
  providedIn: 'root'
})
export class CompanyFacade {
  public loading$ = this.store.pipe(select(companySelectors.loadingSelector));
  public company$ = this.store.pipe(select(companySelectors.companySelector));
  public isValidClientCode$ = this.store.pipe(
    select(companySelectors.isValidClientCodeSelector)
  );

  public isValidClientCodeLoading$ = this.store.pipe(
    select(companySelectors.isValidClientCodeLoadingSelector)
  );

  /**
   * Map of all person-types, could be more specific
   * in the future for usability.
   * Will return an empty object if none are defined yet
   */
  public personTypes$ = this.store.pipe(
    select(companySelectors.personTypesSelector)
  );

  /** Returns whether or not to display resources card on dashboard */
  public hasResources$ = this.store.pipe(
    select(
      createSelector(
        (state: AppState) => state.company.company,
        eventServiceSelectors.afterServiceEndAccessSelector,
        (company) =>
          company?.resources &&
          company.resources.showCard &&
          !!company.resources.cardText
      )
    ),
    map((resources) => resources)
  );

  constructor(private store: Store<AppState>, private actions$: Actions) {}

  public get() {
    this.store.dispatch(companyActions.get());
  }

  /**
   * Returns an observable if the code is valid
   */
  public getIsValidClientCode(params: {
    clientCode: string;
  }): Observable<boolean> {
    const { clientCode } = params;

    this.store.dispatch(companyActions.isValidClientCode({ clientCode }));

    return merge(
      this.actions$.pipe(
        ofType(companyActions.isValidClientCodeSuccess),
        map(({ valid }) => valid)
      ),
      this.actions$.pipe(
        ofType(companyActions.isValidClientCodeFailed),
        map(() => false)
      )
    ).pipe(take(1));
  }

  public clearIsValidClientCode() {
    this.store.dispatch(companyActions.clearIsValidClientCode());
  }

  /**
   * Gets the list of person-types for the given client-code
   */
  public getPersonTypes(params: { clientCode: string }) {
    const { clientCode } = params;

    this.store.dispatch(companyActions.getPersonTypes({ clientCode }));
  }

  /**
   * Gets the company url from the client-code
   */
  public getCompanyLogoUrl(params: { clientCode: string }): Observable<Action> {
    const { clientCode } = params;

    this.store.dispatch(companyActions.getCompanyLogoUrl({ clientCode }));

    return merge(
      this.actions$.pipe(ofType(companyActions.getCompanyLogoUrlSuccess)),
      this.actions$.pipe(ofType(companyActions.getCompanyLogoUrlFailed))
    );
  }

  /**
   * Returns the logo url from the client-code.
   */
  public getCompanyLogoUrl$(params: { clientCode: string }): Observable<any> {
    const { clientCode } = params;

    return this.store.pipe(
      select(
        createSelector(
          (state: AppState) => state.company.logoUrls,
          (logoUrls) => logoUrls && logoUrls[clientCode]
        )
      ),
      filter((_) => !!_),
      map((company) => company.logoUrl || '')
    );
  }
}
