import { CommonResponse, HealthProgram } from '@common';
import { createAction, props } from '@ngrx/store';

export const healthProgramActions = {
  list: createAction('[HEALTH_PROGRAM] LIST'),
  listSuccess: createAction(
    '[HEALTH_PROGRAM] LIST_SUCCESS',
    props<{
      entities: HealthProgram[];
    }>()
  ),
  listFailed: createAction(
    '[HEALTH_PROGRAM] LIST_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),
  get: createAction(
    '[HEALTH_PROGRAM] GET',
    props<{
      id: string;
      saveToState?: boolean;
    }>()
  ),
  getSuccess: createAction(
    '[HEALTH_PROGRAM] GET_SUCCESS',
    props<{
      entity: HealthProgram;
    }>()
  ),
  getFailed: createAction(
    '[HEALTH_PROGRAM] GET_FAILED',
    props<{
      error: CommonResponse;
    }>()
  )
};
