import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  OnsiteRegistrationType,
  OtherServiceType,
  ServiceLabType,
  ServiceType
} from '@common';

export type CombinedServiceType =
  | ServiceType
  | OtherServiceType
  | OnsiteRegistrationType
  | ServiceLabType;

/**
 * This is a "wrapper" definition around
 * the CombinedServiceType, which can be used
 * to create a "route" version of the chip.
 */
export interface RoutedCombinedServiceType {
  /**
   * The actual service type to render as usual.
   */
  serviceType: CombinedServiceType;
  /**
   * The route to route the user to
   */
  route: string[];
  /**
   * Optional query-params that can be passed for this chip.
   */
  queryParams?: Record<string, string>;
}
@Component({
  selector: 'ehs-service-types',
  templateUrl: './ehs-service-types.component.html',
  styleUrls: ['./ehs-service-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsServiceTypesComponent implements OnChanges {
  /**
   * The list of service-types, onsite-registration-types, and lab-types
   */
  @Input() serviceTypes?: Array<
    CombinedServiceType | RoutedCombinedServiceType
  >;

  /**
   * Type-guard for RoutedCombinedServiceType
   */
  public isRouted(
    serviceType: CombinedServiceType | RoutedCombinedServiceType
  ): serviceType is RoutedCombinedServiceType {
    return (serviceType as RoutedCombinedServiceType).route !== undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  public getDisplayText(serviceType: CombinedServiceType): string {
    return {
      [ServiceType.PROVIDER_HEALTH]: 'Health Provider',
      [ServiceType.ONSITE]: 'Onsite',
      [ServiceType.OFFSITE]: 'Offsite',

      [OtherServiceType.COACHING]: 'Coaching',
      [OtherServiceType.HRA]: 'HRA',
      [OtherServiceType.EDUCATION]: 'Education',
      [OtherServiceType.EDUCATION_MARQUEE]: 'Education | Marquee',
      // **Note** these aren't supported features yet
      [OtherServiceType.INCENTIVES]: 'Incentives',
      [OtherServiceType.TRACKERS]: 'Trackers',

      // Onsite-registration-types
      [OnsiteRegistrationType.SCREENING]: 'Onsite | Screening',
      [OnsiteRegistrationType.FLU_VACCINE]: 'Onsite | Flu',
      [OnsiteRegistrationType.COVID_VACCINE]: 'Onsite | COVID',

      // Lab-types
      [ServiceLabType.EHS]: 'Lab | EHS',
      [ServiceLabType.HELENA_LABS]: 'Lab | Helena',
      [ServiceLabType.LAB_CORP]: 'Lab | Labcorp',
      [ServiceLabType.QUEST]: 'Lab | Quest'
    }[serviceType];
  }

  /**
   * Returns the service-type corresponding color.
   * Can be moved into common, if required elsewhere, but this
   * component should be used instead.
   */
  public getColor(serviceType: CombinedServiceType): string {
    return (
      {
        [ServiceType.PROVIDER_HEALTH]: '#ce93d8',
        [ServiceType.ONSITE]: '#42a5f5',
        [ServiceType.OFFSITE]: '#8bc34a',

        [OtherServiceType.COACHING]: '#fff176',
        [OtherServiceType.HRA]: '#ff9800',
        [OtherServiceType.EDUCATION]: '#7986cb',
        [OtherServiceType.EDUCATION_MARQUEE]: '#7986cb',
        // **Note** these aren't supported features yet
        [OtherServiceType.INCENTIVES]: '',
        [OtherServiceType.TRACKERS]: '',
        [OtherServiceType.REIMBURSEMENTS]: '',
        [OtherServiceType.HEALTH_PROGRAM]: '',

        // Onsite-registration-types
        // TODO: need colors, same as onsite for now
        [OnsiteRegistrationType.SCREENING]: '#42a5f5',
        [OnsiteRegistrationType.FLU_VACCINE]: '#42a5f5',
        [OnsiteRegistrationType.COVID_VACCINE]: '#42a5f5',

        // Lab-types
        [ServiceLabType.EHS]: '#223e47', // Verify
        [ServiceLabType.HELENA_LABS]: '#2b5aac',
        [ServiceLabType.LAB_CORP]: '#1a2188',
        [ServiceLabType.QUEST]: '#307328'
      } as Record<ServiceType | OtherServiceType, string>
    )[serviceType];
  }
}
