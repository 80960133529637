import { ObjectId } from 'mongodb';
import { DbDocument } from '../db-document';

/**
 * Potential health risks associated with a user test
 */
export enum HealthRisk {
  METABOLIC_SYNDROME = 'metabolic_syndrome',
  ANEMIA = 'anemia',
  LEUKEMIA = 'leukemia',
  NONE = 'none'
}

/**
 * The collective risk scores required to trigger a health risk
 * from a set of user results
 */
export const HealthRiskThresholds = {
  [HealthRisk.METABOLIC_SYNDROME]: 2.5,
  [HealthRisk.ANEMIA]: 2.5,
  [HealthRisk.LEUKEMIA]: 2.5
};

/**
 * Trigger for the health risk
 */
export enum HealthRiskTrigger {
  OUT_OF_RANGE_HIGH = 'out_of_range_high',
  OUT_OF_RANGE_LOW = 'out_of_range_low',
  CUSTOM_VALUE = 'custom_value'
}

/**
 * Comparison operators for the custom value
 */
export enum CustomValueComparison {
  GREATER_THAN = '>',
  LESS_THAN = '<',
  GREATER_THAN_OR_EQUAL = '>=',
  LESS_THAN_OR_EQUAL = '<='
}

/**
 * A record representing the health risk associated with a user test.
 * Used to calculate whether a set of user tests are considered to
 * have a certain health risk.
 */
export interface UserTestRisk extends Pick<DbDocument, '_id'> {
  /**
   * The test-id related to the parent userTest.
   * This is passed from gw-apps due to user-test-risks being its own form.
   * should be an id
   */
  testId: string | ObjectId;
  /**
   * The health risk associated with this user test
   */
  healthRisk: HealthRisk;
  /**
   * The risk score associated with the health risk
   */
  riskScore: number;
  /**
   * Test range trigger for the health risk
   */
  riskTrigger: HealthRiskTrigger[];
  /**
   * Custom value to compare against the user test result
   * to determine if the health risk is triggered
   */
  customValue?: number;
  /**
   * Comparison operator for the custom value
   */
  customValueComparison?: CustomValueComparison;
}
