import { QueryParamsHandling } from '@angular/router';

/**
 * Id that is used for nested elements to know if its
 * collapsed or not.
 */
export type EhsNavDataNestedId = string & { readonly brand: unique symbol };

export const EhsNavDataNestedId = (elementKey: string) =>
  elementKey as EhsNavDataNestedId;

/**
 * A nav-data object represents navigation data displayed in both the
 * ehs-nav-data and the ehs-side-nav.
 *
 * Both of which can be used to represent the same routing data but
 * in different UI formats.
 */
export type EhsNavData =
  | EhsNavElementData
  | EhsNavNestedData
  | EhsNavActionData;
export interface EhsNavElementBase {
  /**
   * The icon of the element
   */
  icon: string;
  /**
   * The title to display for the route
   */
  title: string;
  /**
   * The position the element is displayed on. If not provided,
   * the value will be defaulted to start
   * ehs-header
   *   - start - left-side
   *   - end - right-side
   * ehs-side-nav
   *   - start - top
   *   - end - bottom
   */
  side?: 'start' | 'end';
}
export interface EhsNavElementData extends EhsNavElementBase {
  /**
   * The route meta-data passed directly into a `routerLink`
   */
  route: string | string[];
  /**
   * Optional params that are passed
   */
  queryParams?: Record<string, string>;
  /**
   * Optional handling of the route
   */
  queryParamsHandling?: QueryParamsHandling;
}

export interface EhsNavNestedData extends EhsNavElementBase {
  /**
   * Unique identifier to know if this element is collapsed or not.
   */
  id: EhsNavDataNestedId;
  /**
   * Nested nav-data objects, will automatically create a nested "select"
   * options
   */
  data: EhsNavData[];
}

export interface EhsNavActionData<T = string> extends EhsNavElementBase {
  /**
   * The action-data we are to emit. Will be emitted
   * from the `action` event-emitter.
   */
  action: T;
}

export const isEhsNavElementData = (
  data: EhsNavData
): data is EhsNavElementData => !!(data as EhsNavElementData)?.route;

export const isEhsNavNestedData = (
  data: EhsNavData
): data is EhsNavNestedData => !!(data as EhsNavNestedData)?.data;

export const isEhsNavActionData = (
  data: EhsNavData
): data is EhsNavActionData => !!(data as EhsNavActionData).action;

/**
 * The types of header-element,
 */
export type EhsNavDataType = 'nav' | 'nested' | 'action';

/**
 * Returns the type of ehs-nav-data
 */
export const getEhsNavDataType = (data: EhsNavData): EhsNavDataType => {
  if (isEhsNavElementData(data)) {
    return 'nav';
  } else if (isEhsNavNestedData(data)) {
    return 'nested';
  } else if (isEhsNavActionData(data)) {
    return 'action';
  }

  return 'nav';
};

/**
 * Returns a "flatten" in-ordered list of all the nested elements.
 * This means "nested" elements will be in the order they are nested.
 * non-nested elements are left-as-is.
 */
export const flattenNavData = (datas: EhsNavData[]): EhsNavData[] =>
  datas.reduce(
    (acc, data) =>
      isEhsNavNestedData(data)
        ? [...acc, data, ...flattenNavData(data.data)]
        : [...acc, data],
    [] as EhsNavData[]
  );
