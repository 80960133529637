import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserRegistrationNgrxProviderDirective } from './user-registration-ngrx-provider.directive';
import { UserRegistrationNgrxEffects } from './user-registration-ngrx.effects';
import {
  userRegistrationReducer,
  USER_REGISTRATION_STORE_KEY
} from './user-registration-ngrx.state';
import {
  EhsConfirmDialogModule,
  EhsEditRegistrationDialogModule
} from '@ehs-ui';
import { CLIENT_ADMIN_CORE_INJECTION_TOKEN } from '@ehs-ngrx/common';

@NgModule({
  declarations: [UserRegistrationNgrxProviderDirective],
  imports: [
    // Angular
    CommonModule,
    MatSnackBarModule,

    // State
    StoreModule.forFeature(
      USER_REGISTRATION_STORE_KEY,
      userRegistrationReducer
    ),
    EffectsModule.forFeature([UserRegistrationNgrxEffects]),

    // EHS
    EhsConfirmDialogModule,
    EhsEditRegistrationDialogModule
  ],
  exports: [UserRegistrationNgrxProviderDirective]
})
export class UserRegistrationNgrxModule {
  public static forFeature(config?: {
    clientAdmin: boolean;
  }): ModuleWithProviders<UserRegistrationNgrxModule> {
    return {
      ngModule: UserRegistrationNgrxModule,
      providers: [
        {
          provide: CLIENT_ADMIN_CORE_INJECTION_TOKEN,
          useValue: config?.clientAdmin
        }
      ]
    };
  }
}
