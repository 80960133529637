import { Injectable, Inject, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScriptParams } from './script-params';
import {
  SCRIPT_LOADER_INIT_SCRIPTS,
  ScriptLoaderInitScripts
} from './script-loader-init-scripts';
import { SCRIPT_LOADER_LOADED } from './script-loader-loaded';
import { ReplaySubject } from 'rxjs';
import {
  COOKIE_DATA_DOMAIN_SCRIPT,
  COOKIE_LAW_SCRIPT_URL,
  COOKIE_LAW_SCRIPT_BANNER_URL,
  ONE_TRUST_STYLE_ID,
  ONE_TRUST_CONSENT_ID
} from '@common';

@Injectable()
export class ScriptLoaderService {
  constructor(
    @Inject(DOCUMENT) private document: Document,

    @Inject(SCRIPT_LOADER_LOADED)
    scriptLoaderLoaded: ReplaySubject<boolean>,

    @Inject(SCRIPT_LOADER_INIT_SCRIPTS)
    @Optional()
    initScripts: ScriptLoaderInitScripts
  ) {
    if (initScripts) {
      this.loadScripts(initScripts).then(() => scriptLoaderLoaded.next(true));
    } else {
      scriptLoaderLoaded.next(true);
    }
  }

  /**
   * Loads the list of scripts.
   * **Does not currently check to see if they are already loaded!**
   */
  public async loadScripts(params: {
    /**
     * The list of script sources
     */
    scripts: ScriptParams[];
  }): Promise<HTMLScriptElement[]> {
    const { scripts } = params;

    if (!scripts) {
      return [];
    }

    return scripts.map(this.load.bind(this)) as any;
  }

  /**
   * Loads the individual scripts
   */
  private async load(script: {
    src: string;
    async?: boolean;
    charset?: string;
    attributes?: { key: string; value: string }[];
  }): Promise<HTMLScriptElement> {
    const { src, async, charset, attributes } = script;

    const scriptElement = this.document.createElement('script');

    scriptElement.src = src;
    scriptElement.async = async as any;

    if (charset) {
      scriptElement.charset = charset;
    }

    if (attributes?.length) {
      attributes.forEach((attribute) => {
        scriptElement.setAttribute(attribute.key, attribute.value);
      });
    }

    this.document.body.appendChild(scriptElement);

    return scriptElement;
  }

  /**
   * Removes the Cookie Script when leaving the Login or Signup Pages.
   **/
  public removeCookieScript({ cookieKey }: { cookieKey: string }) {
    const allScripts = document.getElementsByTagName('script');

    for (let i = 0; i < allScripts.length; i++) {
      const script = allScripts[i];

      if (!script) {
        continue;
      }

      const srcUrl = script?.getAttribute('src');
      const dataDomainScript = script.getAttribute(COOKIE_DATA_DOMAIN_SCRIPT);

      if (!srcUrl) {
        continue;
      }

      if (script && script.parentNode) {
        // Banner script has no data-domain attribute
        if (srcUrl === COOKIE_LAW_SCRIPT_BANNER_URL) {
          script.parentNode.removeChild(script);
        }

        if (
          srcUrl === COOKIE_LAW_SCRIPT_URL &&
          dataDomainScript === cookieKey
        ) {
          script.parentNode.removeChild(script);
        }
      }
    }

    // Remove the OneTrust Styles
    const oneTrustCookieStyle = document.getElementById(ONE_TRUST_STYLE_ID);

    if (oneTrustCookieStyle && oneTrustCookieStyle.parentNode) {
      oneTrustCookieStyle.parentNode.removeChild(oneTrustCookieStyle);
    }

    // Remove the OneTrust Consent element. On Safari, element displays after login if not removed
    const oneTrustBanner = document.getElementById(ONE_TRUST_CONSENT_ID);

    if (oneTrustBanner && oneTrustBanner.parentNode) {
      oneTrustBanner.parentNode.removeChild(oneTrustBanner);
    }
  }
}
