<h1 mat-dialog-title>Reschedule Registration</h1>
<mat-dialog-content *ngIf="userRegistration">
  <div fxLayout="column"
       fxLayoutGap="8px">
    <div class="value">Reschedule
      {{userRegistration.serviceType}} registration:</div>
    <div class="value"><span class="label">Event Date:</span>
      {{userRegistration.eventDate | date: 'fullDate'}}</div>
    <div class="value"><span class="label">Event Time:</span>
      {{userRegistration.eventTime | ampm}}</div>
    <ng-container *ngIf="eventLocation$ | async as eventLocation">
      <div class="value"><span class="label">Location:</span>
        {{getDisplayLocation(eventLocation)}}</div>
    </ng-container>
    <div class="value"><span class="label">Type:</span>
      {{getRegistrationType(userRegistration)}}</div>
  </div>
  <!-- Select new date -->
  <div fxLayout="row"
       fxLayoutAlign="center center"
       fxLayoutGap="16px"
       style="margin-top:16px"
       *ngIf="(eventLocations$ | async) as eventLocations">
    <ng-container *ngIf="eventLocations?.length > 0">
      <mat-form-field style="width:100%">
        <mat-label>Select a new Event</mat-label>
        <mat-select matInput
                    (selectionChange)="handleEventChange($event.value)"
                    required
                    [value]="eventLocation">
          <mat-option *ngFor="let location of eventLocations"
                      [value]="location">
            {{location?.locationCompanyName || ''}} -
            {{location?.city || ''}}, {{location?.state || ''}} -
            {{location?.eventDate | date:'mediumDate' || 'Not Set'}}
            ( {{location?.startTime | ampm}} -
            {{location?.endTime | ampm}} )</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>

  <!-- Select Time-slot -->
  <div fxLayout="column"
       class="onsite-reg-panel"
       *ngIf="(eventLocations$ | async)?.length">
    <div>Select the time for the event registration</div>
    <div fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="16px">
      <div class="mat-body-2 label">Select a Time</div>
    </div>
    <div
         *ngIf="availableTimeSlots?.length && (eventLocations$ | async)?.length else noTimeSlots">
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="8px"
           fxLayout.lt-md="column">
        <mat-radio-button color="primary"
                          *ngFor="let registrationTime of availableTimeSlots;"
                          style="min-width: 200px"
                          [value]="registrationTime.time"
                          (change)="onTimeSlotChange($event)"
                          [checked]="isRadioChecked(registrationTime.time)"
                          [disabled]="registrationTime.remaining <= 0">
          <div>
            {{registrationTime?.time | ampm}}
            <span class="round-available mat-caption">
              {{ registrationTime.remaining >= 0 ? registrationTime.remaining :
              0 }}
            </span>
          </div>
        </mat-radio-button>
        <mat-spinner diameter="24"
                     matSuffix
                     *ngIf="eventsLoading$ | async">
        </mat-spinner>
      </div>
    </div>
    <ng-template #noTimeSlots>
      <p>
        There are no remaining time slots for this event.
      </p>
    </ng-template>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container
                *ngIf="(!selectedTimeSlot || !eventLocation) else showRescheduleButton">
    <p class="mat-typography"
       style="margin-right:16px">No Alternative Events or Times for this Service
    </p>
  </ng-container>
  <button mat-button
          style="color:green; margin-right:16px"
          [mat-dialog-close]="false">Keep Event</button>
  <ng-template #showRescheduleButton>
    <button mat-flat-button
            color="accent"
            (click)="updateRegistration()"
            cdkFocusInitial>Reschedule Event</button>
  </ng-template>
</mat-dialog-actions>
