import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { UserTestUtil } from '@common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[minBound]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinBoundValidatorDirective,
      multi: true
    }
  ]
})
export class MinBoundValidatorDirective {
  /**
   * Similar to min max validators, but not limited to numeric input fields,
   * ignores estimation values, and checks values which are passed in as strings.
   * Returns an error if the value is less than the minBound.
   */
  @Input() minBound?: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public validate(control: AbstractControl): { [key: string]: unknown } | null {
    let { value } = control;

    if (this.minBound === undefined) {
      return null;
    }

    const minBound = Number(this.minBound);

    if (UserTestUtil.isEstimationValue(value)) {
      return null;
    }

    value = Number(control.value);

    if (isNaN(value) || isNaN(minBound)) {
      // If neither are numbers, then don't do anything
      return null;
    }

    if (value < minBound) {
      // Return the "error"
      return {
        minBound: {
          value
        }
      };
    }

    return null;
  }
}
