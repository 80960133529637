import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartialUser, User, UserFilter, getId, isDbDocument } from '@common';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { EhsAddWaiverDialogData } from './ehs-add-waiver-dialog.data';
import { NgForm } from '@angular/forms';
import { EhsAddWaiverDialogResponse } from './ehs-add-waiver-dialog.response';

@Component({
  selector: 'ehs-add-waiver-dialog',
  templateUrl: './ehs-add-waiver-dialog.component.html',
  styleUrls: ['./ehs-add-waiver-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsAddWaiverDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EhsAddWaiverDialogData,
    private dialogRef: MatDialogRef<EhsAddWaiverDialogComponent>
  ) {}

  public users = this.data.users;
  public filteredUsers = this.data.users;
  public userSearch$ = new BehaviorSubject<string>('');
  public user?: User;
  public file?: File;

  public showFileError = false;

  public selectedUser$ = new BehaviorSubject<Partial<PartialUser>>({
    partial: true
  });

  public selectedUserName$ = this.selectedUser$.pipe(
    map((user) => {
      if (!user || !this.users?.length) {
        // If nothing is selected then return nothing.
        return '';
      }
      const userObj = this.users.find((u) => getId(u) === user.shortId);

      return userObj ? userObj.firstName + ' ' + userObj.lastName : '';
    })
  );

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    if (!this.file) {
      this.showFileError = true;

      return;
    }

    this.showFileError = false;

    const waiverToAdd: EhsAddWaiverDialogResponse = {
      user: form.value.user,
      dateSubmitted: form.value.dateSubmitted,
      file: this.file,
      eventService: this.data.eventService
    };

    this.dialogRef.close(waiverToAdd);
  }

  onFileUpload(files: FileList) {
    if (files?.length) {
      this.file = files[0];
    }
  }

  clearFile(input: HTMLInputElement) {
    input.value = '';
    this.file = undefined;
  }

  async onUserChange(user: User | string) {
    if (!isDbDocument(user)) {
      this.userSearch$.next(user);
    }

    // Search Users only when string bigger than 3 characters
    if (typeof user === 'string' && user?.length > 2) {
      const names = user.split(' ');
      const first = names[0];

      if (names.length > 1 && names[1].length > 2) {
        const last = names[1];

        this.listUsers({ firstName: first, lastName: last });
      } else {
        this.listUsers({ firstName: first });
      }

      return;
    }

    this.listUsers({});
  }

  /**
   * List the users with given user filter & the current company
   */
  private listUsers(filter: UserFilter) {
    this.filteredUsers = (this.users || []).filter((user) => {
      let isValid = true;

      if (filter.firstName) {
        isValid = user.firstName.includes(filter.firstName);
      }

      if (filter.lastName) {
        isValid = user.lastName.includes(filter.lastName);
      }

      return isValid;
    });
  }

  onUserSelected(user: User) {
    this.onUserChange(user);
    this.user = user;

    this.selectedUser$.next({
      ...this.selectedUser$.value,
      shortId: getId(user),
      firstName: user.firstName,
      lastName: user.lastName
    });
  }

  onClearUser() {
    this.onUserChange('');
    this.listUsers({});

    this.selectedUser$.next({
      ...this.selectedUser$.value,
      shortId: undefined,
      firstName: undefined,
      lastName: undefined
    });
  }
}
