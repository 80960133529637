import { ObjectId } from 'mongodb';
import { AdminNote } from './admin-note';
import { Company } from './company';
import { DbDocument } from './db-document';
import { EventService } from './event-service/event-service';
import { User } from './user';

export type UserReimbursementId = string & { readonly brand: unique symbol };

export const ConvertToUserReimbursementId = (userReimbursementId: string) =>
  userReimbursementId as UserReimbursementId;

export interface UserReimbursement extends DbDocument {
  /** Text field i.e. gym membership, fitness equipment */
  expenseType: string;
  /**  Text field i.e. Best Buy, Planet Fitness */
  purchasedFrom: string;
  /** Date should be a string of YYYY-MM-DD **/
  purchasedDate: string;
  /** Cost of item to be reimbursed, up to limit */
  amount: number;
  attachments: [ReimbursementFile];
  status: ReimbursementStatus;

  company: string | ObjectId | Company;
  eventService: string | ObjectId | EventService;
  user: string | ObjectId | User;

  firstName: string;
  lastName: string;
  companyName: string;

  notes: AdminNote[];
}

export enum ReimbursementMimeType {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  IMAGE_JPG = 'image/jpg',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  PDF = 'image/pdf',
  TIFF = 'tiff',
  APPLICATION_PDF = 'application/pdf'
}

export interface ReimbursementFile {
  mimetype: ReimbursementMimeType;
  fileId: string;
  fileName: string;
  uploadDate: string;
}

export enum ReimbursementStatus {
  REJECTED = 'rejected',
  UPLOADED = 'uploaded',
  APPROVED = 'approved'
}

type UserReimbursementPickedFilter = Partial<
  Pick<UserReimbursement, 'firstName' | 'company' | 'lastName'>
>;

export interface UserReimbursementFilter extends UserReimbursementPickedFilter {
  status?: ReimbursementStatus[];
}

export interface UserReimbursementForm extends UserReimbursementFilter {
  pageNumber: number;
}
