import { UserRegistration, OnsiteUserRegistration, getId } from '@common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { selectId } from '../utils/select-id';
import {
  stripSubDocs,
  stripSubDocsFromEntities
} from '../utils/strip-sub-docs';
import { userRegistrationActions } from './user-registration.actions';

export interface UserRegistrationState extends EntityState<UserRegistration> {
  loading: boolean;
  pdfLoading: boolean;
  providerResultsLoading: boolean;
}

const adapter = createEntityAdapter<UserRegistration>({
  selectId
});

const reducer = createReducer(
  adapter.getInitialState({} as UserRegistrationState),
  on(userRegistrationActions.get, (state: UserRegistrationState) => ({
    ...state,
    loading: true
  })),
  on(
    userRegistrationActions.getSuccess,
    (state: UserRegistrationState, { entity }) =>
      adapter.addOne(
        stripSubDocs<OnsiteUserRegistration | any>({
          entity,
          subDocs: ['eventLocation', 'eventService']
        }),
        { ...state, loading: false }
      )
  ),
  on(userRegistrationActions.getFailed, (state: UserRegistrationState) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationActions.downloadPdf, (state: UserRegistrationState) => ({
    ...state,
    pdfLoading: true
  })),
  on(
    userRegistrationActions.downloadPdfSuccess,
    (state: UserRegistrationState) => ({
      ...state,
      pdfLoading: false
    })
  ),
  on(
    userRegistrationActions.downloadPdfFailed,
    (state: UserRegistrationState) => ({
      ...state,
      pdfLoading: false
    })
  ),

  on(userRegistrationActions.addFile, (state: UserRegistrationState) => ({
    ...state,
    providerResultsLoading: true
  })),
  on(
    userRegistrationActions.addFileSuccess,

    (state: UserRegistrationState, { entity }) =>
      adapter.upsertOne(entity, {
        ...state,
        loading: false,
        providerResultsLoading: false
      })
  ),
  on(userRegistrationActions.addFileFailed, (state: UserRegistrationState) => ({
    ...state,
    providerResultsLoading: false
  })),

  on(userRegistrationActions.list, (state: UserRegistrationState) => ({
    ...state,
    loading: true
  })),

  on(
    userRegistrationActions.listSuccess,
    (state: UserRegistrationState, { entities }) =>
      adapter.upsertMany(
        stripSubDocsFromEntities<OnsiteUserRegistration | any>({
          entities,
          subDocs: ['eventLocation', 'eventService']
        }),
        {
          ...state,
          loading: false
        }
      )
  ),

  on(userRegistrationActions.listFailed, (state: UserRegistrationState) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationActions.remove, (state: UserRegistrationState) => ({
    ...state,
    loading: true
  })),
  on(
    userRegistrationActions.removeSuccess,
    (state: UserRegistrationState, { entity }) =>
      adapter.removeOne(getId(entity), { ...state, loading: false })
  ),
  on(userRegistrationActions.removeFailed, (state: UserRegistrationState) => ({
    ...state,
    loading: false
  })),

  on(userRegistrationActions.update, (state: UserRegistrationState) => ({
    ...state,
    loading: true
  })),
  on(
    userRegistrationActions.updateSuccess,
    (state: UserRegistrationState, { entity }) =>
      adapter.setAll([entity], { ...state, loading: false })
  ),
  on(userRegistrationActions.updateFailed, (state: UserRegistrationState) => ({
    ...state,
    loading: false
  })),
  on(userRegistrationActions.create, (state: UserRegistrationState) => ({
    ...state,
    loading: true
  })),
  on(
    userRegistrationActions.createSuccess,
    (state: UserRegistrationState, { entity }) =>
      adapter.addOne(entity, { ...state, loading: false })
  ),
  on(userRegistrationActions.createFailed, (state: UserRegistrationState) => ({
    ...state,
    loading: false
  })),
  on(
    userRegistrationActions.set,
    (state: UserRegistrationState, { userRegistration }) =>
      adapter.upsertOne(userRegistration, state)
  )
);

export function UserRegistrationReducer(
  state: UserRegistrationState,
  action: Action
) {
  return reducer(state, action);
}
