import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { AppState } from '../app-state';
import { errorReportActions } from './error-report.actions';

export interface CommonErrorReportParams {
  action: Action;
  err: Error | any;
  /**
   * The message we are to show to the end user, it not given
   * we defaults to the DEFAULT_ERROR_MESSAGE injectable.
   */
  message?: string;
  /**
   * If we are to dispatch a "retry" prompt.
   * TODO: not supported yet
   */
  retry?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class ErrorReportFacade {
  constructor(private store: Store<AppState>) {}

  private getError(params: { err: Error | any }): Error | any {
    const { err } = params;

    if (!err) {
      return {};
    }
    const { error } = err;
    const message = err.message || (error && error.message);
    const name = err.name || (error && error.name);
    const stack = err.stack || (error && error.stack);

    return {
      message,
      name,
      stack
    };
  }

  /**
   * Creates and returns an error report action.
   * This should be used instead of directly creating the
   * report action, as we try to prevent serialization errors
   * for passed error information.
   */
  public create(params: CommonErrorReportParams): Action {
    return errorReportActions.report({
      ...params,
      err: this.getError(params)
    });
  }

  /**
   * Dispatches an error report action
   */
  public report(params: CommonErrorReportParams) {
    this.store.dispatch(this.create(params));
  }
}
