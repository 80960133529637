import { AdminUser, CommonResponse } from '@common';
import { createAction, props } from '@ngrx/store';

export const adminCoreActions = {
  setMultipleAdminUsers: createAction(
    '[ADMIN_CORE] SET_MULTIPLE_ADMIN_USERS',
    props<{
      users: AdminUser[];
    }>()
  ),
  getAdminUser: createAction('[ADMIN_CORE] GET_ADMIN_USER'),
  getAdminUserSuccess: createAction(
    '[ADMIN_CORE] GET_ADMIN_USER_SUCCESS',
    props<{
      user: AdminUser;
    }>()
  ),
  getAdminUserFailed: createAction(
    '[ADMIN_CORE] GET_ADMIN_USER_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),
  getAdminList: createAction('[ADMIN_CORE] GET_ADMIN_LIST'),
  getAdminListSuccess: createAction(
    '[ADMIN_CORE] GET_ADMIN_LIST_SUCCESS',
    props<{
      users: AdminUser[];
    }>()
  ),
  getAdminListFailed: createAction(
    '[ADMIN_CORE] GET_ADMIN_LIST_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),
  createAdmin: createAction(
    '[ADMIN_CORE] CREATE_ADMIN',
    props<{
      email: string;
    }>()
  ),
  createAdminSuccess: createAction('[ADMIN_CORE] CREATE_ADMIN_SUCCESS'),
  createAdminFailed: createAction(
    '[ADMIN_CORE] CREATE_ADMIN_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),
  updateAdminScopes: createAction(
    '[ADMIN_CORE] UPDATE_ADMIN_SCOPES',
    props<{
      admin: AdminUser;
    }>()
  ),
  updateAdminScopesSuccess: createAction(
    '[ADMIN_CORE] UPDATE_ADMIN_SCOPES_SUCCESS'
  ),
  updateAdminScopesFailed: createAction(
    '[ADMIN_CORE] UPDATE_ADMIN_SCOPES_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),
  deleteAdmin: createAction(
    '[ADMIN_CORE] DELETE_ADMIN',
    props<{
      adminId: string;
    }>()
  ),
  deleteAdminSuccess: createAction('[ADMIN_CORE] DELETE_ADMIN_SUCCESS'),
  deleteAdminFailed: createAction(
    '[ADMIN_CORE] DELETE_ADMIN_FAILED',
    props<{
      err: CommonResponse;
    }>()
  )
};
