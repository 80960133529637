import { Injectable } from '@angular/core';
import {
  Company,
  GcfIncentiveExportRequest,
  GcfRegExportRequest,
  getId
} from '@common';
import {
  EntityFacade,
  selectQueryParam,
  selectRouteNestedParam
} from '@ehs-ngrx/common';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { companyNgrxActions } from './company-ngrx.actions';
import {
  companyNgrxFeatureSelector,
  companyNgrxSelectors
} from './company-ngrx.selectors';
import { CompanyNgrxState, ParentCompanyNgrxState } from './company-ngrx.state';
import { CompanySecurity } from '@common';

@Injectable({
  providedIn: 'root'
})
export class CompanyNgrxFacade extends EntityFacade<
  Company,
  string,
  ParentCompanyNgrxState,
  CompanyNgrxState
> {
  public currCompanyRouteParam$ = this.store.pipe(
    select(selectRouteNestedParam('companyId'))
  ) as Observable<string>;

  public currCompanyQueryParam$ = this.store.pipe(
    select(selectQueryParam('company'))
  );

  /**
   * If any stats are loading
   */
  public statsLoading$ = this.store.pipe(
    select(companyNgrxSelectors.statsLoadingSelector)
  );

  /**
   * If company security is loaded
   */
  public securityLoading$ = this.store.pipe(
    select(companyNgrxSelectors.securityLoadingSelector)
  );

  /**
   * If company notes are loaded
   */
  public companyNotesLoading$ = this.store.pipe(
    select(companyNgrxSelectors.companyNotesLoadingSelector)
  );

  constructor(store: Store<ParentCompanyNgrxState>) {
    super(store, companyNgrxFeatureSelector);
  }

  public get(id: string) {
    this.store.dispatch(companyNgrxActions.get.req({ id }));
  }

  /**
   * Returns the stats for the given company
   */
  public getStats$(company: string | Company) {
    return this.store.pipe(
      select(companyNgrxSelectors.entityStatsSelectorFactory(getId(company)))
    );
  }

  /**
   * Sets the company notes for the given company
   */
  public updateCompanyNotes({
    companyId,
    notes
  }: {
    companyId: string;
    notes: string;
  }) {
    this.store.dispatch(
      companyNgrxActions.updateCompanyNotes.req({ companyId, notes })
    );
  }

  /**
   * Returns the stats for the given company
   */
  public getCompanySecurity$(company: string | Company) {
    return this.store.pipe(
      select(companyNgrxSelectors.companySecurity(getId(company)))
    );
  }

  /**
   * Returns the loaded company notes
   */
  public getCompanyNotes$() {
    return this.store.pipe(select(companyNgrxSelectors.companyNotes()));
  }

  /**
   * Returns the stats for the given stats, if it isn't already loaded.
   */
  public getStats(params: { reload?: boolean; id: string | Company }) {
    const { id, reload } = params;

    if (reload) {
      // If reload is true, then force a reload
      return this.store.dispatch(
        companyNgrxActions.getStats({
          id: getId(id),
          reload
        })
      );
    }
    // Otherwise check if its already loading

    this.store
      .pipe(
        select(
          companyNgrxSelectors.isEntityStatsLoadedSelectorFactory(getId(id))
        ),
        take(1),
        filter((loaded) => !loaded)
      )
      .subscribe(() =>
        this.store.dispatch(
          companyNgrxActions.getStats({
            id: getId(id),
            reload: false
          })
        )
      );
  }

  /**
   * Will list the companies, which can be cached.
   */
  public list(params?: { reload?: boolean }) {
    if (params?.reload) {
      // If reload is true, then force a reload
      return this.store.dispatch(companyNgrxActions.list.req({ reload: true }));
    }

    // Otherwise, skip
    this.store
      .pipe(select(companyNgrxSelectors.loadedSelector))
      .pipe(
        take(1),
        filter((loaded) => !loaded)
      )
      .subscribe(() => this.store.dispatch(companyNgrxActions.list.req()));
  }

  /**
   * Exports user-registration data for a given company
   *
   * **or** event-service
   * **or** event
   *
   * TODO: Migrate this to other ngrx facades, and change this to be the
   * specific version
   */
  public exportUserRegistrations(params: Omit<GcfRegExportRequest, 'email'>) {
    this.store.dispatch(companyNgrxActions.exportUserRegistrations.req(params));
  }

  public exportFieldStaff(params: Omit<GcfRegExportRequest, 'email'>) {
    this.store.dispatch(companyNgrxActions.exportFieldStaff.req(params));
  }

  /**
   * Exports user-incentives data for a given company/event-service
   */
  public exportUserIncentives(
    params: Omit<GcfIncentiveExportRequest, 'email'>
  ) {
    this.store.dispatch(companyNgrxActions.exportUserIncentives.req(params));
  }

  /**
   * Exports coaching session
   */
  public exportCoachingSession(company: Company | string) {
    this.store.dispatch(
      companyNgrxActions.exportUsers.req({ company: getId(company) })
    );
  }

  /**
   * Exports all users for a given company
   */
  public exportUsers(company: Company | string) {
    this.store.dispatch(
      companyNgrxActions.exportUsers.req({ company: getId(company) })
    );
  }

  /**
   * Get company security
   */
  public getCompanySecurity(company: Company | string) {
    this.store.dispatch(
      companyNgrxActions.getSecurity.req({ id: getId(company) })
    );
  }

  /**
   * Get company notes for the given company
   */
  public getCompanyNotes(company: Company | string) {
    this.store.dispatch(
      companyNgrxActions.getCompanyNotes.req({ companyId: getId(company) })
    );
  }

  /**
   * Update company security
   */
  public updateCompanySecurity({
    companyId,
    companySecurity
  }: {
    companyId: string;
    companySecurity: Partial<CompanySecurity>;
  }) {
    this.store.dispatch(
      companyNgrxActions.updateSecurity.req({ companyId, companySecurity })
    );
  }

  public clear() {
    this.store.dispatch(companyNgrxActions.clear());
  }
}
