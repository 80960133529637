/**
 * Locations for shot administered.
 */
export enum ShotLocations {
  LEFT_ARM = 'LA',
  // LEFT_DELTOID = 'LD',
  // LEFT_GLUTEOUS_MEDIUS = 'LG',
  // LEFT_LOWER_FOREARM = 'LLFA',
  // LEFT_THIGHT = 'LT',
  // LEFT_VASTUS_LATERALIS = 'LVL',

  RIGHT_ARM = 'RA'
  // RIGHT_DELTOID = 'RD',
  // RIGHT_GLUTEOUS_MEDIUS = 'RG',
  // RIGHT_LOWER_FOREARM = 'RLFA',
  // RIGHT_THIGHT = 'RT',
  // RIGHT_VASTUS_LATERALIS = 'RVL'
}
