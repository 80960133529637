import { OnsiteService } from '../../models/event-service/onsite-service';
import { SiteServiceConditionalSelfReportedVisibility } from '../../models/event-service/site-service';
import { LabResultCode } from '../../models/user-test/user-test';

/**
 * This const will act as a "hard-value" of which fields need to be DB or double-blind
 * entered by admins in regards to the OnsiteBioForm. This will be used on the client-side
 * to verify if the given field needs to be double blind checked or disabled
 * after the first "submit" is fired and the form state changes.
 *
 * In the case of the `height` fields, if one is not entered correctly, we should
 * **redo both**. Its possible the same logic will apply to the blood pressure fields
 * as well.
 */
export const ONSITE_BIO_FORM_DB_FIELDS = [
  'heightFeet',
  'heightInches',
  'weight',
  'waist',
  'hip',
  'bloodPressureSystolic',
  'bloodPressureDiastolic',
  'bca'
] as const;

/**
 * List of fields that can be conditionally, required in the
 * data-entry form.
 */
export const ONSITE_BIO_FORM_REQUIRED_FIELDS = [
  'height',
  'weight',
  'bloodPressure',
  'fasting',
  'waist',
  'hip',
  'bca',
  'usesTobacco'
] as const;

/**
 * This type represents the list of types of fields that need to be double-blind.
 *
 * This provides a strict TS level check against checking the 2 non-double-blind fields,
 * `usesTobacco` and `fasting`. Where-as the `ONSITE_BIO_FORM_DB_FIELDS` const
 * provides the runtime level check.
 */
export type OnsiteBioFormDbField = typeof ONSITE_BIO_FORM_DB_FIELDS[number];

/**
 * Type-Guard to help check if the given OnsiteBioForm field requires double-blind
 * entry. This will "duck-type" the data.
 *
 * This should be combined with "declined" and "available" checks if this
 * matters or not.
 */
export const isOnsiteBioFormDbField = (
  field: keyof OnsiteBioForm | string
): field is OnsiteBioFormDbField =>
  ONSITE_BIO_FORM_DB_FIELDS.includes(field as OnsiteBioFormDbField);

/**
 * This map provides the lab-result-code (should be the same as primary-order-code)
 * for the bio-form fields. These just so happen to overlap with the "double-blind"
 * entry fields, but they don't have to.
 *
 * The exception to this is `height`, which is a single user-test, but within
 * the `OnsiteBioForm` is split into 2 different types.
 *
 * This will force most pieces of code that use this to contend with the 2 heights
 * together when trying to save the result.
 */
export const ONSITE_BIO_FORM_CODE_MAP = {
  height: LabResultCode('4200'),
  weight: LabResultCode('4201'),
  bloodPressureSystolic: LabResultCode('4208'),
  bloodPressureDiastolic: LabResultCode('4209'),
  waist: LabResultCode('4202'),
  hip: LabResultCode('4203'),
  bca: LabResultCode('4207'),
  // This isn't a field that is double blind, its only generated on the
  // back-end if height and weight are provided together for onsite-data-entry
  // see: #2725
  bmi: LabResultCode('4205')
};
/**
 * This is an alternate lab-result-code for weight. Part of mitigation updates
 * with #3829
 */
export const ALT_WEIGHT_CODE = LabResultCode('4201dupe');

/**
 * This type represents the list of bio-form fields that map to actual user-tests
 * within the platform.
 *
 * Key things of note is `heightFeet` and `heightInches` are not included but
 * the calculated `height` is.
 */
export type OnsiteBioFormFieldWithTest = keyof typeof ONSITE_BIO_FORM_CODE_MAP;

/**
 * Type-guard that returns if the given OnsiteBioForm field is a field that has
 * a user-test as according to the `ONSITE_BIO_FORM_CODE_MAP`.
 *
 * **note** this type-guard allows for fields not included in the OnsiteBioForm,
 * primarily to support `hight`, but will return true for the correct fields
 * that have tests.
 */
export const isOnsiteBioFormFieldWithTest = (
  field: keyof OnsiteBioForm | string
): field is OnsiteBioFormFieldWithTest =>
  typeof field === 'string' &&
  (
    Object.keys(ONSITE_BIO_FORM_CODE_MAP) as Array<OnsiteBioFormFieldWithTest>
  ).includes(field as OnsiteBioFormFieldWithTest);

/**
 * List of fields that are declinable within the onsite-bio-form.
 * **note** both height and bloodPressure are combined here.
 */
export const ONSITE_BIO_FORM_DECLINABLE_FIELDS = [
  'height',
  'weight',
  'waist',
  'hip',
  'bloodPressure',
  'bca',
  'usesTobacco',
  'fasting'
] as const;

/**
 * List of fields that are self-reported, and thus are
 * "static" within the form, that are always shown regardless of
 * other factors. However, if these fields are auto-populated with self-reported
 * fields, auto-declined, or even how/where these are saved, as "self-reported"
 * versions or "measured" variants on the back-end depends on the circumstances.
 */
export const ONSITE_BIO_FORM_SELF_REPORTED_FIELDS = [
  'height',
  'weight',
  'bloodPressureSystolic',
  'bloodPressureDiastolic',
  'bmi'
] as const;

/**
 * Represents the "self-reported" fields of the biometric form.
 */
export type OnsiteBioFormSelfReportedFields =
  typeof ONSITE_BIO_FORM_SELF_REPORTED_FIELDS[number];

/**
 * Represents the list of fields that can be declined within the bio-form.
 * This mirrors the fields that have user-tests.
 *
 * There is no type-guard available, as what is declinable and not declinable
 * should be defined within the template.
 *
 * **note** both height and bloodPressure are combined here
 */
export type OnsiteBioFormDeclinableField =
  typeof ONSITE_BIO_FORM_DECLINABLE_FIELDS[number];

/**
 * Returns the list of onsite-bio-form-fields-with-tests that are available,
 * based on the onsite-service's self-reported tests.
 *
 * This should be used as somewhat of an "override" for if a field is not
 * provided by the user-test, but is available in the service via self-reported.
 *
 * TODO: does this need to consider bloodPressure?
 */
export const getOnsiteBioFormFromSelfReported = (
  service: OnsiteService
): Array<OnsiteBioFormFieldWithTest> => {
  if (!service) {
    return [];
  }

  // Utility function that returns if the given field is self-reported.
  const hasSelfReported = (
    field: keyof OnsiteService['conditionalSelfReported']
  ) =>
    service.conditionalSelfReported?.[field] ===
      SiteServiceConditionalSelfReportedVisibility.OFFERED ||
    service.conditionalSelfReported?.[field] ===
      SiteServiceConditionalSelfReportedVisibility.REQUIRED;

  const hasHeight = hasSelfReported('height');
  const hasWeight = hasSelfReported('height');

  const fields: Array<OnsiteBioFormFieldWithTest> = [];

  if (hasHeight) {
    fields.push('height');
  }

  if (hasWeight) {
    fields.push('height');
  }

  return fields;
};

/**
 * List of lab-result-codes that are to be ignored for creating user-criticals. This is
 * only defined here for clarity.
 */
export const ONSITE_BIO_FORM_USER_CRITICAL_IGNORE_CODES = [
  ONSITE_BIO_FORM_CODE_MAP.bca,
  // TODO: re-add later
  // ONSITE_BIO_FORM_CODE_MAP.bmi,
  ONSITE_BIO_FORM_CODE_MAP.bloodPressureSystolic,
  ONSITE_BIO_FORM_CODE_MAP.bloodPressureDiastolic
] as const;

/**
 * This type represents the onsite-bio-form data. These part of the form is used
 * for onsite events that take biometric information onsite by EHS Staff.
 *
 * These values can be entered onsite for a fingerstick event **or** an onsite
 * where the user has to later go to a lab to do blood work.
 *
 * Most of these parts of the form are double blind, and can be declined.
 */
export interface OnsiteBioForm {
  /**
   * The height of the user in inches.
   *
   * This is consolidated with `heightFeet` on the back-end, or parsed
   * from the user-result on the client-side when the form is loaded.
   *
   * Combined with weight to calculate BMI.
   *
   * **note** more docs might be required to describe when this is shown
   * to represent "self-reported height".
   *
   * **note** we could force validation for 3-8,
   * see @heightFeet in `height.ts`.
   *
   * LabResultCode: `4200`
   */
  heightFeet: number;

  /**
   * The height of the user in inches.
   *
   * This is consolidated with `heightFeet` on the back-end, or parsed
   * from the user-result on the client-side when the form is loaded.
   *
   * Combined with weight to calculate BMI.
   *
   * **note** more docs might be required to describe when this is shown
   * to represent "self-reported height".
   *
   * **note** we could force validation for 3-8,
   * see @heightInches in `height.ts`.
   *
   * LabResultCode: `4200`
   */
  heightInches: number;

  /**
   * The weight of the user in pounds.
   *
   * Combined with height to calculate BMI.
   *
   * LabResultCode: `4201`
   */
  weight: number;

  /**
   * The top number of the user's blood-pressure.
   * This number must be higher than systolic.
   *
   * LabResultCode: `4208`
   */
  bloodPressureSystolic: number;

  /**
   * The bottom number of the user's blood-pressure.
   * This number must be lower than systolic.
   *
   * LabResultCode: `4209`
   */
  bloodPressureDiastolic: number;

  /**
   * The user's waist measurement in inches.
   *
   * LabResultCode: `4202`
   */
  waist: number;

  /**
   * The user's hip measurement in inches.
   *
   * LabResultCode: `4203`
   */
  hip: number;

  /**
   * The Body Composition Analysis (BCA) of the user. A percentage
   *
   * LabResultCode: `4207`
   */
  bca: number;

  /**
   * If the user uses tobacco or not.
   *
   * This field is **not** double-blind checked, as its a radio button, but can
   * be declined.
   */
  usesTobacco: boolean;

  /**
   * If the user fasted or not, usually means 8 or more hours of not eating.
   *
   * This field is **not** double-blind checked, as its a radio button, but can
   * be declined.
   */
  fasting: boolean;
}
