/**
 * Test-types aren't used in many places at this time, but are handled
 * during the GWApps parsing proccess
 */
export enum UserTestType {
  THE_WORKS = 'theWorks',
  PART_OF_PANEL = 'partOfPanel',
  ADDITIONAL_TESTS = 'additionalTests',
  SPECIAL_TESTS = 'specialTests',
  // AKA AdditionalServices, this was changed to match our app
  OPTIONAL_TESTS = 'optionalTests',
  PANEL = 'panel',
  FAX_REPORT = 'faxReport',
  FINGER_STICK_RESULT = 'fingerStickResult',
  FINGER_STICK = 'fingerStick'
}
