<div fxLayout="row wrap"
     fxLayoutAlign="start start"
     fxLayoutGap="8px"
     style="margin-bottom:8px">

  <!-- Status -->
  <mat-form-field>
    <mat-label>Status</mat-label>
    <mat-select name="statusFilter"
                [value]="filter.status"
                (selectionChange)="onChange({ status: $event.value })">
      <mat-option>All Statuses</mat-option>
      <mat-option *ngFor="let status of statuses"
                  [value]="status">
        {{status | titlecase}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Action -->
  <mat-form-field>
    <mat-label>Type</mat-label>
    <mat-select name="actionFilter"
                [value]="filter.action"
                #actionTypeInput
                (selectionChange)="onChange({ action: $event.value })">
      <mat-option>All Types</mat-option>
      <mat-option *ngFor="let actionType of actionTypes"
                  [value]="actionType">
        {{ getActionTypeName(actionType) }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Username -->
  <mat-form-field>
    <mat-label>Username</mat-label>
    <input matInput
           name="username"
           [value]="filter.username || ''"
           (change)="onChange({ username: $any($event.target).value })"
           autocomplete="off"
           #usernameInput>
    <button *ngIf="!!filter.username"
            style="min-height: 24px; height: 24px;"
            matSuffix
            type="button"
            mat-icon-button
            (click)="onChange({ username: undefined })">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Date From -->
  <mat-form-field>
    <mat-label>Date From</mat-label>
    <input matInput
           type="date"
           name="startDate"
           placeholder="MM/DD/YYYY"
           [value]="filter.start"
           (change)="onChange({ start: $any($event.target).value })"
           autocomplete="off"
           date>
  </mat-form-field>

  <!-- Date To -->
  <mat-form-field>
    <mat-label>Date To</mat-label>
    <input matInput
           type="date"
           name="endDate"
           placeholder="MM/DD/YYYY"
           [value]="filter.end"
           #endDateInput
           (change)="onChange({ end: $any($event.target).value })"
           autocomplete="off"
           date>
  </mat-form-field>

  <!-- Search Button -->
  <div class="align-with-mat-field-with-hint-start"><button mat-flat-button
            type="button"
            color="primary"
            style="height: 50px;"
            (click)="search.emit()">
      <mat-icon class="search-button">search</mat-icon>
    </button></div>

  <!-- Clear Button -->
  <div class="align-with-mat-field-with-hint-start"><button mat-flat-button
            *ngIf="hasFilters"
            title="Clear Filters"
            type="button"
            color="accent"
            style="height: 50px;"
            (click)="clear.emit();">
      <mat-icon class="search-button">close</mat-icon>
    </button></div>

</div>
