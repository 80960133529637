import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { EhsAdminUpdateTestsDialogComponent } from './ehs-admin-update-tests-dialog.component';
import { EhsAdminUpdateTestsDialogService } from './ehs-admin-update-tests-dialog.service';

@NgModule({
  declarations: [EhsAdminUpdateTestsDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    // Angular Material
    MatDialogModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  exports: [EhsAdminUpdateTestsDialogComponent],
  providers: [EhsAdminUpdateTestsDialogService]
})
export class EhsAdminUpdateTestsDialogModule {}
