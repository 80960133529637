import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  EventService,
  EventServiceUtil,
  isOnsiteService,
  isOnsiteUserRegistration,
  OnsiteRegistrationType,
  User,
  UserRegistration,
  UserRegistrationUtil,
  UserTest
} from '@common';
import { AdminUpdateServiceTypesDialogData } from './admin-update-service-types-dialog-data';

@Component({
  selector: 'ehs-admin-update-service-types-dialog',
  templateUrl: './ehs-admin-update-service-types-dialog.component.html',
  styleUrls: ['./ehs-admin-update-service-types-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsAdminUpdateServiceTypesDialogComponent implements OnInit {
  public userRegistration: UserRegistration;
  public eventService: EventService;
  public userTests: Record<string, UserTest>;
  public user: User;
  public serviceTypes: Array<OnsiteRegistrationType>;
  public selectedServiceTypes: Array<OnsiteRegistrationType>;
  public validationError = '';

  public get service() {
    return EventServiceUtil.getSelectedService({
      eventService: this.eventService,
      serviceType: this.userRegistration?.serviceType
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AdminUpdateServiceTypesDialogData,
    private dialogRef: MatDialogRef<EhsAdminUpdateServiceTypesDialogComponent>
  ) {}

  ngOnInit(): void {
    this.userRegistration = this.data.userRegistration;
    this.eventService = this.data.eventService;
    this.user = this.data.user;
    this.serviceTypes = this.getServiceTypes();
    this.selectedServiceTypes = this.getSelectedServiceTypes();
    this.validationError = '';
  }

  /**
   * The list of all available service types for event service
   */
  private getServiceTypes() {
    const service = this.service;

    if (!isOnsiteService(service)) {
      return [];
    }

    const serviceTypes = EventServiceUtil.getOnsiteTypesFromService({
      service
    });

    // Make a shallow copy just in-case to prevent mutation.
    return [...serviceTypes];
  }

  /**
   * The currently selected serviceTypes,
   * can be updated and will be returned as-is.
   */
  private getSelectedServiceTypes(): Array<OnsiteRegistrationType> {
    if (this.userRegistration) {
      if (!isOnsiteUserRegistration(this.userRegistration)) {
        return [];
      }

      return UserRegistrationUtil.getOnsiteRegistrationTypeFromReg(
        this.userRegistration
      );
    }

    return this.serviceTypes || [];
  }

  toggleServiceTypes(serviceType: OnsiteRegistrationType) {
    if (this.selectedServiceTypes.includes(serviceType)) {
      this.selectedServiceTypes = this.selectedServiceTypes.filter(
        (selectedServiceType) => selectedServiceType !== serviceType
      );

      return;
    }
    this.selectedServiceTypes.push(serviceType);
  }

  isServiceChecked(service: OnsiteRegistrationType): boolean {
    return !!this.selectedServiceTypes.includes(service);
  }

  /**
   * Returns the newly selected service types
   */
  close() {
    if (!this.selectedServiceTypes?.length) {
      this.validationError = 'Registrations require at least one service type';

      return;
    }

    if (
      this.selectedServiceTypes.includes(
        OnsiteRegistrationType.COVID_VACCINE
      ) &&
      this.selectedServiceTypes.includes(OnsiteRegistrationType.FLU_VACCINE)
    ) {
      this.validationError =
        'Flu and Covid vaccinations currently not supported. Please select flu or covid vaccination to continue';

      return;
    }

    this.dialogRef.close(this.selectedServiceTypes);
  }
}
