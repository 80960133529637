/* eslint-disable @typescript-eslint/no-explicit-any */

import { AdminUser, CommonResponse, PlatformStats } from '@common';
import { entityHttpActionFactory } from '@ehs-ngrx/common';
import { createAction, props } from '@ngrx/store';

/**
 * **Note** these actions are simplified from the admin-gw.actions version
 * in ng-portal
 */
export const adminCoreNgrxActions = {
  set: createAction(
    '[ADMIN_CORE_NGRX] SET',
    props<{
      user: AdminUser;
    }>()
  ),

  list: entityHttpActionFactory<
    undefined,
    {
      entities: AdminUser[];
    }
  >('[ADMIN_CORE_NGRX] LIST'),

  addAdmin: entityHttpActionFactory<
    string,
    undefined,
    { error: CommonResponse }
  >('[ADMIN_CORE_NGRX] ADD_ADMIN'),

  removeAdmin: entityHttpActionFactory<
    AdminUser,
    { adminId: string },
    { error: CommonResponse }
  >('[ADMIN_CORE_NGRX] REMOVE_ADMIN'),

  update: entityHttpActionFactory<
    { admin: AdminUser },
    { admin: AdminUser },
    { error: CommonResponse }
  >('[ADMIN_CORE_NGRX] UPDATE'),

  getStats: entityHttpActionFactory<
    {
      startDate: string;
      endDate: string;
    },
    {
      stats: PlatformStats;
    }
  >('[ADMIN_CORE_NGRX] GET_STATS')
};
