import {
  Company,
  EventLocation,
  EventLocationCustomTime,
  EventLocationRegistrationTimesByType,
  EventService,
  EventServiceIncentivesStats,
  SingleEventStats,
  UserRegistration
} from '@common';
import { entityHttpActionFactory } from '@ehs-ngrx/common';
import { createAction, props } from '@ngrx/store';

export const eventLocationNgrxActions = {
  set: createAction(
    '[EVENT_LOCATION_NGRX] SET',
    props<{
      entity: EventLocation;
    }>()
  ),
  get: entityHttpActionFactory<
    {
      id: string;
    },
    {
      entity: EventLocation;
    }
  >('[EVENT_LOCATION_NGRX] GET'),

  // Set selected Event Location, used in walk-in user reg form #3954
  setEventLocation: createAction(
    '[ADMIN_EVENT_REG] SET_EVENT_LOCATION',
    props<{
      eventLocation: string;
    }>()
  ),

  listRecent: entityHttpActionFactory<
    {
      reload?: boolean;
    },
    {
      entities: EventLocation[];
    }
  >('[EVENT_LOCATION_NGRX] LIST_RECENT'),
  listByCompany: entityHttpActionFactory<
    {
      company: string;
      // TODO: add extra filtering
    },
    { entities: EventLocation[] }
  >('[EVENT_LOCATION_NGRX] LIST_BY_COMPANY'),
  listByService: entityHttpActionFactory<
    {
      company: string;
      eventService: string;
    },
    { entities: EventLocation[] }
  >('[EVENT_LOCATION_NGRX] LIST_BY_SERVICE'),
  release: entityHttpActionFactory<{
    eventLocation: string;
    users?: string[];
  }>('[EVENT_LOCATION_NGRX] RELEASE'),
  cancelEvent: entityHttpActionFactory<{
    eventLocation: EventLocation | string;
  }>('[EVENT_LOCATION_NGRX] CANCEL'),
  sendToLab: entityHttpActionFactory<
    {
      eventLocation: string;
      admin?: string;
    },
    {
      entities: UserRegistration[];
    }
  >('[EVENT_LOCATION_NGRX] SEND_TO_LAB'),

  getStats: entityHttpActionFactory<
    {
      company?: string | Company;
      eventService?: string | EventService;
    },
    {
      stats: Record<string, SingleEventStats>;
    }
  >('[EVENT_LOCATION_NGRX] GET_STATS'),

  getRegTimes: entityHttpActionFactory<
    {
      eventLocation: string;
    },
    EventLocationRegistrationTimesByType
  >('[EVENT_LOCATION_NGRX] GET_REG_TIMES'),
  removeCustomTime: createAction(
    '[EVENT_LOCATION_NGRX] REMOVE_CUSTOM_TIME',
    props<Pick<EventLocationCustomTime, 'time'> & { eventLocation: string }>()
  ),
  updateCustomTime: createAction(
    '[EVENT_LOCATION_NGRX] UPDATE_CUSTOM_TIME',
    props<EventLocationCustomTime & { eventLocation: string }>()
  ),
  updateEventLocationCustomTimes: entityHttpActionFactory<
    {
      eventLocation: Pick<EventLocation, 'customTimes' | '_id'>;
    },
    {
      eventLocation: EventLocation;
    }
  >('[EVENT_LOCATION_NGRX] UPDATE_EVENT_LOCATION_CUSTOM_TIMES')
};
