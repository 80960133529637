import { MarqueeService } from './marquee-service';
import { ObjectId } from 'bson';
import { Company } from '../company';
import { DbDocument } from '../db-document';
import { PersonType } from '../person-type';
import { CustomPricing } from './custom-pricing';
import { EventServiceCoaching } from './event-service-coaching';
import { EventServiceHraSettings } from './event-service-hra-settings';
import { EventServiceIncentives } from './event-service-incentives';
import { OffsiteService } from './offsite-service';
import { OnsiteService } from './onsite-service';
import { ProviderHealthService } from './provider-health-service';
import { MarqueeIncentiveService } from './marquee-service';
import { EventServiceReimbursements } from './event-service-reimbursements';

/**
 * The three supported "services" for an event-service (package).
 * Use type-guards to check for the type of service.
 */
export type EventServiceServiceType =
  | OnsiteService
  | OffsiteService
  | ProviderHealthService;

export enum HealthRiskAssessmentType {
  OPTIONAL = 'optional',
  REQUIRED = 'required',
  NONE = 'none'
}

export enum EventServiceStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active'
}

export type EventServiceId = string & { readonly brand: unique symbol };

// tslint:disable-next-line: variable-name
export const EventServiceId = (eventServiceId: string) =>
  eventServiceId as EventServiceId;

/**
 * Represents the service year for the event-service.
 * Will not exceed 5 years because of data retention policies.
 */
export enum ServiceYear {
  SERVICE_YEAR_1 = 'service_year_1',
  SERVICE_YEAR_2 = 'service_year_2',
  SERVICE_YEAR_3 = 'service_year_3',
  SERVICE_YEAR_4 = 'service_year_4',
  SERVICE_YEAR_5 = 'service_year_5'
}

/**
 * Represents the event-options for user registrations.
 * Should be considered more like an "EventServicePackage",
 * where this EventService defines all the services
 * a user will have access to.
 */
export interface EventService extends DbDocument<EventServiceId> {
  /**
   * EventService GW Apps Record ID (example: P1711, P1712...)
   */
  serviceId: string;
  /**
   * The company this service goes to
   */
  company: string | ObjectId | Company;

  /**
   * The name of the service,
   * **note** reference package-name from gw-apps
   *
   * Will only show to end users, when they select the package.
   * EX:
   * Q-1, Q-2
   */
  name: string;

  /**
   * The event options the company has enabled for their employees.
   * This can be a list of different types such as onsite, and offsite, and
   * also hold the relevant information for that given service provide from this
   * event service.
   */
  services: EventServiceServiceType[];

  /**
   * The service year identifier for the event-service.
   * Used in data reporting to distinguish and compare event
   * services over time.
   */
  serviceYearIdentifier: ServiceYear;

  /**
   * @deprecated - This will be replaced by hraSettings.hra
   * HealthRiskAssessment
   * Bunch of questionaries, after they register, they have to fill out.
   * This defines what sort of behavior we should have when it
   * comes to these extra forms.
   */
  hra: HealthRiskAssessmentType;

  /**
   * Health Risk Assessment Settings
   * Includes the participant types that are allowed to participate, as well as the HRA property
   * of 'required' | 'available' | 'none' and the tiles visibility.
   */
  hraSettings: EventServiceHraSettings;

  /**
   * Settings for the coaching settings feature.
   */
  coaching: EventServiceCoaching;

  /**
   * Incentive Program details
   */
  incentives?: EventServiceIncentives;

  /**
   * If eventServiceScreening is present,when fetching incentives for the eventServiceScreening service,include this eventservice incentives
   */
  eventServiceScreening?: string | ObjectId | EventService;

  /**
   * Reimbursement details
   */
  reimbursement?: EventServiceReimbursements;

  /**
   * The list of person types supported by this event-service, this is
   * combined with the person-types within the event-service groups later.
   */
  participants: PersonType[];

  /**
   * If the customer incentivized programs, like health care awards
   */
  incentivized: boolean;

  /**
   * If we are to allow employees to get printed reports.
   */
  printedReports: boolean;

  /**
   * If Health education is supported. Currently called the "Emmy"
   * feature.
   */
  healthEducation?: boolean;

  /**
   * Enable no show emails for the service
   */
  sendNoShowEmail?: boolean;

  /**
   * Flag for marquee support for this service. Should only apply if
   * healthEducation is also true. This should be within another property,
   * and migrated, if we are to be forward thinking.
   *
   * See #2786
   * @deprecated - Replaced with `marqueeChallenges`, `marqueeCoaching` & `marqueeIncentives`.
   */
  marquee?: boolean;

  /**
   * Whether the Marquee Challenges feature is enabled / disabled for that event-service
   */
  marqueeChallenges?: MarqueeService;

  /**
   * Whether the Marquee Challenges runner feature is enabled / disabled for that event-service
   */
  marqueeChallengeRunner?: MarqueeService;

  /**
   * Whether the Marquee Coaching feature is enabled / disabled for that event-service.
   */
  marqueeCoaching?: MarqueeService;

  /**
   * Whether the Marquee Incentives feature is enabled / disabled for that event-service.
   * There are 2 types of incentive programs: rewards or outcomes.
   */
  marqueeIncentives?: MarqueeIncentiveService;

  /**
   * Date string that represents when the services within this event-service
   * are "relevant". The services within this event-service
   * will **not** be applied until this date, regardless of the settings
   * within the service itself.
   */
  startDate: string;

  /**
   * Date string that represents when the services within this event-service
   * are no longer "relevant". The services within this event-service
   * will no longer be considered until this date, regardless of
   * the settings within the service itself.
   */
  endDate: string;

  /**
   * This is an array of all user-test custom pricing. If EHS wants to give a
   * customer on a given a service a custom price vs the actual user-test.cost
   * they will create entries to override the default cost.
   */
  customPricing?: CustomPricing[];

  /**
   * The status of the event-service, can be used to
   * activated/de-activate a service in regards to
   * registering to the service
   */
  status: EventServiceStatus;

  // Toggle for education service
  showHealthEducation?: boolean;
}
