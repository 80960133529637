import {
  EHS_BP_DIA_RESULT_CODE,
  EHS_BP_SYS_RESULT_CODE,
  LABCORP_BP_DIA_RESULT_CODE,
  LABCORP_BP_SYS_RESULT_CODE,
  QUEST_BP_DIA_RESULT_CODE,
  QUEST_BP_SYS_RESULT_CODE
} from '../../constants/blood-pressure';
import { BaseUserRegistration } from '../../models/user-registration/base-user-registration';
import { UserResult } from '../../models/user-result/user-result';
import { UserResultTestResult } from '../../models/user-result/user-result-test-result';
import { LabResultCode } from '../../models/user-test/user-test';
import { UserTestUtil } from './user-test-util';

export class UserTestBloodPressureUtil {
  /**
   * Blood Pressure can be captured as a Lab Result, Data entered by admins or self reported by the user
   * We first check if the lab results include blood pressure
   * Otherwise we check if the admin added the blood pressure on the userResult in data entry
   * Otherwise we check if the user checked the blood pressure on user-registration
   * Otherwise we leave the value null
   * @returns a generated UserResultTestResult, using test codes 4208,4209. This is because the getRange function takes UserResultTestResult
   */
  public static getBloodPressureResults({
    userResult,
    userRegistration
  }: {
    userResult: Partial<UserResult>;
    userRegistration: BaseUserRegistration;
  }): {
    systolic: UserResultTestResult;
    diastolic: UserResultTestResult;
  } | null {
    if (!userResult) {
      return null;
    }

    // Flatten the userResult.tests to check against the different tests
    const userTestResultMap = UserTestUtil.getFlatUserResultTests({
      userResult: userResult as Pick<UserResult, 'tests'>
    });
    /**
     * First check if lab test has a value
     */
    const bpSysTest = this.getTestResult({
      resultCodes: [
        EHS_BP_SYS_RESULT_CODE,
        QUEST_BP_SYS_RESULT_CODE,
        LABCORP_BP_SYS_RESULT_CODE
      ],
      userTestResultMap
    });

    const bpDiaTest = this.getTestResult({
      resultCodes: [
        EHS_BP_DIA_RESULT_CODE,
        QUEST_BP_DIA_RESULT_CODE,
        LABCORP_BP_DIA_RESULT_CODE
      ],
      userTestResultMap
    });

    if (bpSysTest && bpDiaTest) {
      return {
        diastolic: bpDiaTest || undefined,
        systolic: bpSysTest || undefined
      };
    }

    // Check if Admin Data entered the blood pressure
    if (
      userResult.bloodPressureDia !== undefined &&
      userResult.bloodPressureSys !== undefined
    ) {
      return {
        systolic: {
          value: userResult.bloodPressureSys,
          resultCode: EHS_BP_SYS_RESULT_CODE
        } as UserResultTestResult,
        diastolic: {
          value: userResult.bloodPressureDia,
          resultCode: EHS_BP_DIA_RESULT_CODE
        } as UserResultTestResult
      };
    }

    // Check if user self reported the blood pressure on registration
    if (
      userRegistration?.bloodPressure?.diastolic !== undefined &&
      userRegistration?.bloodPressure?.systolic
    ) {
      return {
        systolic: {
          value: userRegistration.bloodPressure.systolic,
          resultCode: EHS_BP_SYS_RESULT_CODE
        } as UserResultTestResult,
        diastolic: {
          value: userRegistration.bloodPressure.diastolic,
          resultCode: EHS_BP_DIA_RESULT_CODE
        } as UserResultTestResult
      };
    }

    // Otherwise we leave it blank, which will cause incentive to be unearned
    return null;
  }

  /**
   * Get value from the User Result Test Map
   */
  private static getTestResult = ({
    resultCodes,
    userTestResultMap
  }: {
    resultCodes: Array<LabResultCode | string>;
    userTestResultMap: Record<LabResultCode, UserResultTestResult>;
  }): UserResultTestResult | undefined => {
    const labCode = resultCodes.find(
      (resultCode) => !!userTestResultMap[LabResultCode(resultCode)]
    );

    if (!labCode) {
      return undefined;
    }

    const userResultTestResult = userTestResultMap[LabResultCode(labCode)];

    return !UserTestUtil.isNoResult({ userResultTestResult })
      ? userResultTestResult
      : undefined;
  };
}
