/**
 * Where to redirect user after they sign in to Marquee Health
 * Options are: /challenges, /health-tools/webinars, /health-tools/video-library, /health-tools/newsletter,
 * /coaching, /rewards, /health-status
 */
export enum MarqueeHealthRedirect {
  LANDING = '/',
  CHALLENGES = '/challenges',
  CHALLENGES_RUNNER = '/cr-challenges',
  WEBINARS = '/health-tools/webinars',
  VIDEO_LIBRARY = '/health-tools/video-library',
  NEWSLETTER = '/health-tools/newsletter',
  COACHING = '/coaching',
  INCENTIVES_REWARDS = '/rewards',
  INCENTIVES_OUTCOMES = '/health-status',
  QUIT_SMOKING = '/courses/ubreathe-tobacco-cessation'
}
