<h1 mat-dialog-title>Create Notification</h1>
<form #notificationForm="ngForm"
      novalidate
      (submit)="createNotification(notificationForm)">
  <mat-dialog-content>
    <div fxLayout="column"
         fxLayoutGap="8px"
         style="font-size: 14px">
      <mat-form-field>
        <mat-label>Subject</mat-label>
        <input matInput
               #subjectField="ngModel"
               [(ngModel)]="notification.subject"
               name="subject"
               type="text"
               required>
        <mat-error *ngIf="subjectField.hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>
      <div>
        <mat-label>Message</mat-label>
        <div class="NgxEditor__Wrapper">
          <ngx-editor-menu [toolbar]="toolbar"
                           [editor]="editor"></ngx-editor-menu>
          <ngx-editor [editor]="editor"
                      name="message"
                      #messageField="ngModel"
                      [(ngModel)]="notification.message"
                      (ngModelChange)="onEditorContentChange($event)"
                      [disabled]="false"
                      required
                      [placeholder]="'Type here...'"></ngx-editor>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
            type="button"
            [mat-dialog-close]="null">
      Cancel</button>
    <button mat-flat-button
            color="primary"
            cdkFocusInitial>
      Send
    </button>
  </mat-dialog-actions>
</form>
