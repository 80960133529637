import { CommonResponse } from './common.response';

/**
 * Response that represents a response for users
 * who are not authenticated within the application.
 */
export const notAuthenticatedResponse: CommonResponse = {
  code: 403,
  message: 'Not Authenticated'
};
