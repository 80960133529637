import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  UserReimbursement,
  getId,
  GcfReimbursementExportRequest,
  CommonResponse,
  ReimbursementFile,
  UserReimbursementForm
} from '@common';
import { Observable, of } from 'rxjs';
import { QueryListService } from '../../util/query-list.service';

const BASE_URL = '/api/v1/user-reimbursements';
const ADMIN_URL = '/api/v1/admin/user-reimbursements';

@Injectable({
  providedIn: 'root'
})
export class UserReimbursementHttpService {
  constructor(private http: HttpClient, private queryList: QueryListService) {}

  public list(): Observable<UserReimbursement[]> {
    return this.http.get<UserReimbursement[]>(BASE_URL);
  }

  public get(id: string) {
    return this.http.get<UserReimbursement>(`${BASE_URL}/${id}`);
  }

  public create(params: {
    reimbursement: Partial<UserReimbursement>;
    files: ReimbursementFile[];
  }) {
    return this.http.post<UserReimbursement>(BASE_URL, params);
  }

  /**
   * Returns a signed url to upload a provider user registration result file.
   */
  public getReimbursementUploadSignedUrl(params: {
    /**
     * The type of the file we will upload
     */
    contentType: string;
  }): Observable<{ url: string; fileId: string }> {
    const { contentType } = params;

    return this.http.get<{ url: string; fileId: string }>(
      `api/v1/user-reimbursements/upload/url`,
      {
        params: new HttpParams().set('contentType', contentType)
      }
    );
  }

  /**
   * Returns a signed url to download a reimbursement receipt/file
   */
  public getReimbursementDownloadSignedUrl(params: {
    /**
     * The id of the corresponding user-reimbursement
     */
    id: string;
    /** The id generated for the file in google storage */
    fileId: string;
    /** Whether the admin is calling the API or not */
    admin?: boolean;
  }): Observable<{ url: string }> {
    const { id, fileId, admin } = params;

    return this.http.get<{ url: string }>(
      `${
        admin ? ADMIN_URL : 'api/v1/user-reimbursements'
      }/${id}/download/url/${fileId}`
    );
  }

  /**
   * These endpoints are only available to admins
   */
  public adminList(params: UserReimbursementForm) {
    return this.http.get<{
      userReimbursements: UserReimbursement[];
      hasNext: boolean;
    }>(`${ADMIN_URL}/list`, {
      params: Object.entries(params).reduce(
        (_params, [key, value]) =>
          value ? _params.append(key, '' + value) : _params,
        new HttpParams()
      )
    });
  }

  public adminGet(id: string) {
    return this.http.get<{
      userReimbursement: UserReimbursement;
    }>(`${ADMIN_URL}/${id}`);
  }

  public adminUpdate(userReimbursement: UserReimbursement) {
    return this.http.put<UserReimbursement>(ADMIN_URL, userReimbursement);
  }

  public adminUpdateStatus(userReimbursements: UserReimbursement[]) {
    const reimbursements = (userReimbursements || []).map((userReimbursement) =>
      getId(userReimbursement)
    );
    const status = (userReimbursements || [])[0]?.status;

    if (!status) {
      return of([] as UserReimbursement[]);
    }

    return this.http.put<UserReimbursement[]>(`${ADMIN_URL}/update-status`, {
      userReimbursements: reimbursements,
      status
    });
  }

  public adminDelete(userReimbursement: UserReimbursement) {
    const userReimbursementId = getId(userReimbursement);

    return this.http.delete<UserReimbursement>(
      `${ADMIN_URL}/${userReimbursementId}`
    );
  }

  /**
   * Export selected reimbursements
   */
  public exportReimbursements(
    params: Omit<GcfReimbursementExportRequest, 'email'>
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      'api/v1/admin/user-reimbursements/export',
      params
    );
  }
}
