import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CompanyNgrxProviderDirective } from './admin-gw-ngrx-provider.directive';
import { AdminGwNgrxEffects } from './admin-gw-ngrx.effects';
import { AdminGwNgrxFacade } from './admin-gw-ngrx.facade';
import {
  adminGwNgrxReducer,
  ADMIN_GW_NGRX_STORE_KEY
} from './admin-gw-ngrx.state';
import { JsonDialogModule } from './shared/json-dialog/json-dialog.module';

@NgModule({
  declarations: [CompanyNgrxProviderDirective],
  imports: [
    CommonModule,
    // Dialog module, used in effects
    JsonDialogModule,
    // Ngrx modules
    StoreModule.forFeature(ADMIN_GW_NGRX_STORE_KEY, adminGwNgrxReducer),
    EffectsModule.forFeature([AdminGwNgrxEffects]),

    // Angular Material
    MatSnackBarModule
  ],
  exports: [CompanyNgrxProviderDirective],
  providers: [AdminGwNgrxFacade]
})
export class AdminGwNgrxModule {}
