import { EventServiceServiceType } from './event-service';
import { ServiceLabType } from './service-lab-type';
import { ServiceType } from './service-type';
import { OnsiteGroupType } from './site-group-type';
import { SiteService } from './site-service';

/**
 * An onsite service is performed on the user's location.
 */
export interface OnsiteService extends SiteService {
  type: ServiceType.ONSITE;
  /**
   * The type of lab this service goes to.
   * Offsite lab-type is **always** required.
   * Onsite lab-types could be "none"
   */
  labType: ServiceLabType | 'none';
  /**
   * The groups of users who can participate.
   */
  groupTypes: OnsiteGroupType[];
  /**
   * The price of the package that includes Panel + Additional Test + Additional Services
   * This is shown for admins in the user-payment if payPackage was enabled for their group
   * type.
   */
  packagePrice?: number;
  /**
   * The price of the Flu / Vaccination package
   * This is NOT shown anywhere yet but might be supported later
   */
  vaccinationPrice?: number;
  /**
   * Toggled in service record to hide services if available
   */
  showOnsiteService?: boolean;
}

/**
 * Type-guard for an onsite-service
 */
export const isOnsiteService = (
  service: EventServiceServiceType
): service is OnsiteService => service?.type === ServiceType.ONSITE;
