import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ApiError, apiErrorActionNames, ApiErrorStatus } from '@common';

interface ApiErrorTableColumn {
  title: string;
  key: keyof Pick<
    ApiError,
    | 'action'
    | 'status'
    | 'username'
    | 'statusCode'
    | 'companyName'
    | 'createdAt'
    | 'notes'
  >;
  width: string;
}

@Component({
  selector: 'ehs-api-error-table',
  templateUrl: './ehs-api-error-table.component.html',
  styleUrls: ['./ehs-api-error-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsApiErrorTableComponent {
  @Input() errors: ApiError[] | null = [];
  @Input() hasNext?: boolean;
  @Output() loadMore = new EventEmitter();

  @Output() statusChange = new EventEmitter<{
    error: ApiError;
    status: ApiErrorStatus;
  }>();

  @Output() notesChange = new EventEmitter<{
    error: ApiError;
    notes: string;
  }>();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  /**
   * Status being edited to display status selector
   */
  editStatusId?: string | null = null;

  /**
   * The columns being displayed in the table
   */
  columns: ApiErrorTableColumn[] = [
    {
      title: 'Action',
      key: 'action',
      width: '200px'
    },
    {
      title: 'Company',
      key: 'companyName',
      width: '200px'
    },
    {
      title: 'Username',
      key: 'username',
      width: '200px'
    },
    {
      title: 'HTTP Code',
      key: 'statusCode',
      width: '200px'
    },
    {
      title: 'Date Occured',
      key: 'createdAt',
      width: '200px'
    },
    {
      title: 'Status',
      key: 'status',
      width: '200px'
    },
    {
      title: 'Notes',
      key: 'notes',
      width: '200px'
    }
  ];

  /**
   * The types of statuses available to select
   */
  statuses = Object.values(ApiErrorStatus);

  /**
   * Change back the field to non-editable if the user clicked outside
   */
  onStatusSelectClosed(opened: boolean) {
    if (!opened) {
      this.editStatusId = null;
    }
  }

  /**
   * To keep the header positioning:sticky inside cdk viewport
   */
  get headerTop() {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    } else {
      const offset = this.viewPort['_renderedContentOffset'];

      return `-${offset}px`;
    }
  }

  getStatusCodeColor(statusCode?: number) {
    if (statusCode >= 200 && statusCode < 300) {
      return 'green';
    } else if (statusCode >= 400 && statusCode < 500) {
      return '#1a237e';
    } else {
      return '#ef5350';
    }
  }

  getStatusColor(status: ApiErrorStatus) {
    switch (status) {
      case ApiErrorStatus.OPENED:
        return '#ef5350';
      case ApiErrorStatus.RECEIVED:
        return '';
      default:
        return 'green';
    }
  }

  getActionName(apiError: ApiError) {
    return apiErrorActionNames[apiError?.action || ''] || '';
  }
}
