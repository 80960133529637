import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ehs-add-admin-dialog',
  templateUrl: './ehs-add-admin-dialog.component.html',
  styleUrls: ['./ehs-add-admin-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsAddAdminDialogComponent {
  constructor(private dialogRef: MatDialogRef<EhsAddAdminDialogComponent>) {}

  public emailAddress: string;

  addAdmin(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const { email } = form.value;

    this.dialogRef.close(email);
  }
}
