import { createAction, props } from '@ngrx/store';
import { HraForm, HraResult, HraCategoryTypes } from '@common';

export const hraFormActions = {
  setForm: createAction(
    '[HRA_FORM] SET_FORM',
    props<{
      form: HraForm;
    }>()
  ),
  setCategory: createAction(
    '[HRA_FORM] SET_CATEGORY',
    props<{
      category: HraCategoryTypes;
      noAutoSave?: boolean;
    }>()
  ),
  setEventService: createAction(
    '[HRA_FORM] SET_EVENT_SERVICE',
    props<{
      eventService: string;
    }>()
  ),
  setHeight: createAction(
    '[HRA_FORM] SET_HEIGHT',
    props<{
      heightFeet: number;
      heightInches: number;
    }>()
  ),
  updateRecord: createAction(
    '[HRA_FORM] UPDATE_RECORD',
    props<{
      record: Partial<HraResult>;
    }>()
  ),
  clearRecordProps: createAction(
    '[HRA_FORM] CLEAR_RECORD_PROPS',
    props<{
      recordKeys: string[];
    }>()
  ),
  clear: createAction('[HRA_FORM] CLEAR'),
  setRecordId: createAction(
    '[HRA_FORM] SET_RECORD_ID',
    props<{
      recordId: string;
    }>()
  ),
  submitRecord: createAction('[HRA_FORM] SUBMIT_RECORD')
};
