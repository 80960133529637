<ng-container *ngIf="data">
  <div fxLayout="row"
       [fxLayoutAlign]="hasSplitData ? 'space-between' : ''"
       fxFlex>
    <div fxLayout="row"
         fxLayoutGap="8px"
         fxLayoutAlign="center center">
      <ng-container *ngFor="let nestedElement of leftData">
        <div>
          <ehs-header-element [data]="nestedElement"
                              (action)="action.emit($event)">
          </ehs-header-element>
        </div>
      </ng-container>
    </div>

    <div *ngIf="rightData.length"
         fxLayout="row"
         fxLayoutGap="8px"
         fxLayoutAlign="center center">
      <ng-container *ngFor="let nestedElement of rightData">
        <div>
          <ehs-header-element [data]="nestedElement"
                              (action)="action.emit($event)">
          </ehs-header-element>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
