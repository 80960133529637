import {
  CommonResponseWithReason,
  HPFUploadFile,
  UserRegistration
} from '@common';
import { createAction, props } from '@ngrx/store';

export const userRegistrationActions = {
  get: createAction(
    '[USER_REGISTRATION] GET',
    props<{ id: string; includeReq?: boolean }>()
  ),
  getSuccess: createAction(
    '[USER_REGISTRATION] GET_SUCCESS',
    props<{ entity: UserRegistration }>()
  ),
  getFailed: createAction(
    '[USER_REGISTRATION] GET_FAILED',
    props<{ id: string }>()
  ),
  downloadPdf: createAction(
    '[USER_REGISTRATION] DOWNLOAD_PDF',
    props<{ id: string }>()
  ),
  downloadPdfSuccess: createAction('[USER_REGISTRATION] DOWNLOAD_PDF_SUCCESS'),
  downloadPdfFailed: createAction('[USER_REGISTRATION] DOWNLOAD_PDF_FAILED'),

  list: createAction(
    '[USER_REGISTRATION] LIST',
    props<{
      includeReq?: boolean;
      includeService?: boolean;
      includeLocation?: boolean;
    }>()
  ),
  listSuccess: createAction(
    '[USER_REGISTRATION] LIST_SUCCESS',
    props<{ entities: UserRegistration[] }>()
  ),
  listFailed: createAction('[USER_REGISTRATION] LIST_FAILED'),

  update: createAction(
    '[USER_REGISTRATION] UPDATE',
    props<{ entity: Partial<UserRegistration> }>()
  ),
  updateSuccess: createAction(
    '[USER_REGISTRATION] UPDATE_SUCCESS',
    props<{ entity: UserRegistration }>()
  ),
  updateFailed: createAction(
    '[USER_REGISTRATION] UPDATE_FAILED',
    props<{
      entity: Partial<UserRegistration>;
      err: CommonResponseWithReason;
    }>()
  ),

  addFile: createAction(
    '[USER_REGISTRATION] ADD_FILE',
    props<{ entity: UserRegistration | string; file: Partial<HPFUploadFile> }>()
  ),
  addFileSuccess: createAction(
    '[USER_REGISTRATION] ADD_FILE_SUCCESS',
    props<{ entity: UserRegistration }>()
  ),
  addFileFailed: createAction(
    '[USER_REGISTRATION] ADD_FILE_FAILED',
    props<{ entity: string | UserRegistration }>()
  ),

  create: createAction(
    '[USER_REGISTRATION] CREATE',
    props<{
      entity: Partial<UserRegistration>;
    }>()
  ),
  createSuccess: createAction(
    '[USER_REGISTRATION] CREATE_SUCCESS',
    props<{ entity: UserRegistration }>()
  ),
  createFailed: createAction(
    '[USER_REGISTRATION] CREATE_FAILED',
    props<{
      entity: Partial<UserRegistration>;
      err: CommonResponseWithReason;
    }>()
  ),

  remove: createAction('[USER_REGISTRATION] REMOVE', props<{ id: string }>()),
  removeSuccess: createAction(
    '[USER_REGISTRATION] REMOVE_SUCCESS',
    props<{ entity: UserRegistration }>()
  ),
  removeFailed: createAction(
    '[USER_REGISTRATION] REMOVE_FAILED',
    props<{ id: string }>()
  ),
  set: createAction(
    '[USER_REGISTRATION] SET',
    props<{
      userRegistration: UserRegistration;
    }>()
  )
};
