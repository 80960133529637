<ng-container *ngIf="data">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'nav'">
      <a mat-flat-button
         [ehsButtonAnimation]
         [animationColor]="'black'"
         fxLayoutAlign="start center"
         [routerLink]="route"
         fxFlex>
        <div fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="8px">
          <mat-icon>{{data.icon}}</mat-icon>
          <span fxFlex
                class="text-align-left">{{data.title}}</span>
        </div>
      </a>
    </ng-container>
    <ng-container *ngSwitchCase="'nested'">
      <div fxLayout="column">
        <button type="button"
                [ehsButtonAnimation]
                [animationColor]="'black'"
                fxLayoutAlign="start center"
                mat-flat-button
                (click)="$event.stopPropagation(); expandedChange.emit(data)"
                fxFlex>
          <div fxLayout="row"
               fxLayoutAlign="space-between center"
               fxLayoutGap="8px">
            <mat-icon>{{data.icon}}</mat-icon>
            <div fxFlex
                 class="text-align-left"
                 [title]="data.title">{{data.title}}</div>
            <mat-icon>{{expanded ? 'keyboard_arrow_up' :
              'keyboard_arrow_down'}}</mat-icon>
          </div>

        </button>
        <ng-container *ngIf="expanded">
          <div fxLayout="column"
               fxLayoutAlign="start start"
               style="margin-left: 8px">
            <ng-container *ngFor="let nestedElement of nestedData;">
              <ehs-side-nav-element [data]="nestedElement"
                                    (action)="action.emit($event)"
                                    [expandedElements]="expandedElements"
                                    (expandedChange)="expandedChange.emit($event)">
              </ehs-side-nav-element>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'action'">
      <button type="button"
              mat-flat-button
              [ehsButtonAnimation]
              [animationColor]="'black'"
              (click)="emitAction(data, $event)"
              fxFlex
              fxLayoutAlign="start">
        <div fxLayout="row"
             fxLayoutAlign="space-between center"
             fxLayoutGap="8px">
          <mat-icon>{{data.icon}}</mat-icon>
          <span fxFlex
                class="text-align-left">{{data.title}}</span>
        </div>
      </button>
    </ng-container>
  </ng-container>

</ng-container>
