<ng-container *ngIf="noToggle else showToggle">
  <div fxLayout="row"
       fxLayoutGap="8px">
    <ng-container *ngTemplateOutlet="legend"></ng-container>
  </div>
</ng-container>

<ng-template #showToggle>
  <button mat-button
          aria-label="toggle status legend"
          [matMenuTriggerFor]="menu">
    <mat-icon>info</mat-icon>
    Legend
  </button>
  <mat-menu #menu="matMenu"
            [xPosition]="xPosition"
            [yPosition]="yPosition">
    <div gdColumns="1fr 1fr"
         gdGap="8px"
         style="margin: 8px">
      <ng-container *ngTemplateOutlet="legend"></ng-container>
    </div>
  </mat-menu>

</ng-template>


<ng-template #legend>
  <ng-container *ngFor="let regStatus of regStatuses">
    <div>
      <div class="mat-body-2">
        {{regStatus.title}}
      </div>

      <mat-icon [title]="regStatus.title"
                [ngStyle]="{color: regStatus.color}">
        {{ regStatus.icon}}
      </mat-icon>
    </div>
  </ng-container>
</ng-template>
