/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { User } from '@common';
import { EntityFacade } from '@ehs-ngrx/common';
import { select, Store } from '@ngrx/store';
import { clientAdminCoreNgrxActions } from './client-admin-core-ngrx.actions';
import {
  clientAdminCoreNgrxFeatureSelector,
  clientAdminCoreNgrxSelectors
} from './client-admin-core-ngrx.selectors';
import {
  ClientAdminCoreNgrxState,
  ParentClientAdminCoreNgrxState
} from './client-admin-core-ngrx.state';

@Injectable({
  providedIn: 'root'
})
export class ClientAdminCoreNgrxFacade extends EntityFacade<
  User,
  string,
  ParentClientAdminCoreNgrxState,
  ClientAdminCoreNgrxState
> {
  /**
   * The current admin-user
   */
  public user$ = this.store.pipe(
    select(clientAdminCoreNgrxSelectors.clientAdminUserSelector)
  );

  /**
   * The current admin-user's scopes
   */
  public adminScopes$ = this.store.pipe(
    select(clientAdminCoreNgrxSelectors.clientAdminUserScopesSelector)
  );

  /**
   * Start and End Dates from previous platformStats query
   */
  public dateRange$ = this.store.pipe(
    select(clientAdminCoreNgrxSelectors.dateRangeSelector)
  );

  constructor(store: Store<ParentClientAdminCoreNgrxState>) {
    super(store, clientAdminCoreNgrxFeatureSelector);
  }

  public setClientAdminUser(user: User) {
    this.store.dispatch(
      clientAdminCoreNgrxActions.set({
        user
      })
    );
  }

  /**
   * Lists all admin-users, automatically includes scopes.
   */
  public list() {
    this.store.dispatch(clientAdminCoreNgrxActions.list.req());
  }

  /**
   * Add a new admin, prompting a dialog to get the email
   */
  public addAdmin() {
    this.store.dispatch(clientAdminCoreNgrxActions.addClientAdmin.req());
  }

  public removeAdmin(admin: User) {
    this.store.dispatch(
      clientAdminCoreNgrxActions.removeClientAdmin.req(admin)
    );
  }

  /**
   * Updates the given user
   */
  public update(admin: User) {
    this.store.dispatch(clientAdminCoreNgrxActions.update.req({ admin }));
  }
}
