import { Injectable } from '@angular/core';
import { ClientAdminScopes } from '@common';
import {
  ClientAdminUserActionColumn,
  ClientAdminUserColumn
} from './user-table-column';

@Injectable({
  providedIn: 'root'
})
export class EhscUserTableService {
  getColumns(): ClientAdminUserColumn[] {
    return [
      {
        columnTitle: 'Name',
        prop: 'user',
        width: '230px'
      },
      {
        columnTitle: 'DOB',
        prop: 'birthDay',
        width: '120px'
      }
    ];
  }

  getRegistrationColumns(): ClientAdminUserColumn[] {
    return [
      {
        columnTitle: 'Registration Location',
        prop: 'eventLocation',
        width: '250px'
      },
      {
        columnTitle: 'Registration Date',
        prop: 'eventDate',
        width: '150px'
      },
      {
        columnTitle: 'Registration Time',
        prop: 'eventTime',
        width: '100px'
      },
      {
        columnTitle: 'Registration Type',
        prop: 'serviceType',
        width: '100px'
      }
    ];
  }

  getActionColumns(scopes: ClientAdminScopes[]): ClientAdminUserActionColumn[] {
    return (
      (
        [
          {
            type: ClientAdminScopes.CREATE_REGISTRATION,
            scope: ClientAdminScopes.CREATE_REGISTRATION,
            name: 'Register',
            icon: 'add'
          },
          {
            type: ClientAdminScopes.REMOVE_REGISTRATION,
            scope: ClientAdminScopes.REMOVE_REGISTRATION,
            name: 'Cancel',
            icon: 'delete'
          }
        ] as Array<ClientAdminUserActionColumn>
      )
        // If no scopes are provided at all, then we consider it "unscoped", and
        // thus let through
        .filter(({ scope }) => (scope ? scopes.includes(scope) : true))
    );
  }
}
