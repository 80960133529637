import { LabResultCode, PrimaryOrderCode } from '../models/user-test/user-test';
import { FingerStickFormRequest } from '../requests/finger-stick-form/finger-stick-form.request';
import { ResultFormRequest } from '../requests/result-form-request';

/**
 * Code of the actual finger stick "test".
 * If this is available within the service, then everything should be active
 *
 * **note** this is re-used for the provider-health tests!
 * TODO: rename to be more generic
 */
export const FINGER_STICK_CODE: PrimaryOrderCode = PrimaryOrderCode('0000');
export interface FingerStickTestDef {
  /**
   * Property of the finger stick form request
   * **note** height is separate as this needs to be calculated
   * from the heightFeet and heightInches
   */
  prop: keyof ResultFormRequest;
  /**
   * The result code for the given test.
   */
  resultCode: FingerStickCodes;
  /**
   * The loincId
   */
  loincId: string;
  /**
   * If this is a user-test/field we are to ignore for critical-call/critical-result
   * reporting. If true we will **not** make a result flag for this value
   */
  ignoreCriticalResult?: boolean;
}
/**
 * Enum of finger-stick test names to their codes.
 * Useful to "match" in other parts of the app with
 * the user-test code elsewhere.
 *
 * **note** each value is a `PrimaryOrderCode`
 */
export enum FingerStickCodes {
  TOTAL_CHOLESTEROL = '0001',
  HDL = '0002',
  TRIGLYCERIDES = '0003',
  GLUCOSE = '0004',
  LDL = '0005',
  TOTAL_CHOLESTEROL_RATIO = '0006',
  NON_HDL_CHOLESTEROL = '0007',
  HEIGHT = '4200',
  WEIGHT = '4201',
  BMI = '4205',
  BCA = '4207',
  WAIST = '4202',
  HIP = '4203',
  BLOOD_PRESSURE_SYSTOLIC = '4208',
  BLOOD_PRESSURE_DIASTOLIC = '4209',
  HEMOGLOBIN_A1C = '4211'
}
/**
 * This mapping is for finger stick tests.
 * Should be used in conjunction with the finger stick form request
 */
export const FINGER_STICK_TESTS: FingerStickTestDef[] = [
  {
    prop: 'totalCholesterol',
    loincId: '2093-3',
    resultCode: FingerStickCodes.TOTAL_CHOLESTEROL
  },
  {
    prop: 'hdl',
    loincId: '2085-9',
    resultCode: FingerStickCodes.HDL
  },
  {
    prop: 'triglycerides',
    loincId: '2571-8',
    resultCode: FingerStickCodes.TRIGLYCERIDES
  },
  {
    prop: 'glucose',
    loincId: '2345-7',
    resultCode: FingerStickCodes.GLUCOSE
  },
  {
    prop: 'ldl',
    loincId: '13457-7',
    resultCode: FingerStickCodes.LDL
  },
  {
    prop: 'totalCholesterolRatio',
    loincId: '9830-1',
    resultCode: FingerStickCodes.TOTAL_CHOLESTEROL_RATIO
  },
  {
    prop: 'nonHdlCholesterol',
    loincId: '', // TODO: ???
    resultCode: FingerStickCodes.NON_HDL_CHOLESTEROL
  },
  {
    prop: 'height',
    loincId: '3137-7',
    resultCode: FingerStickCodes.HEIGHT
  },
  {
    prop: 'weight',
    loincId: '3141-9',
    resultCode: FingerStickCodes.WEIGHT
  },
  {
    prop: 'bmi',
    loincId: '56114-2', // TODO: FIX! #1106
    resultCode: FingerStickCodes.BMI,
    ignoreCriticalResult: true
  },
  {
    prop: 'bca',
    loincId: '56114-2', // TODO: FIX! #1106
    resultCode: FingerStickCodes.BCA,
    ignoreCriticalResult: true
  },
  {
    prop: 'waist',
    loincId: '56114-2', // TODO: FIX! #1106
    resultCode: FingerStickCodes.WAIST
  },
  {
    prop: 'hip',
    loincId: '', // TODO: unknown??
    resultCode: FingerStickCodes.HIP
  },
  {
    prop: 'bloodPressureSystolic',
    loincId: '8480-6',
    resultCode: FingerStickCodes.BLOOD_PRESSURE_SYSTOLIC, // Was '101144'
    ignoreCriticalResult: true
  },
  {
    prop: 'bloodPressureDiastolic',
    loincId: '8462-4',
    resultCode: FingerStickCodes.BLOOD_PRESSURE_DIASTOLIC, // Was '101145'
    ignoreCriticalResult: true
  }
];
/**
 * Map of tests where the code is the prop.
 */
export const FINGER_STICK_TESTS_PROP_MAP = FINGER_STICK_TESTS.reduce(
  (acc, test) => ({ ...acc, [test.prop]: test }),
  {} as Record<keyof FingerStickFormRequest, FingerStickTestDef>
);

export const HEIGHT_FINGER_STICK_CODE = FINGER_STICK_TESTS.find(
  ({ prop }) => prop === 'height'
);

/**
 * List of codes for fields we are to ignore critical calls for
 *
 * **note** all finger-stick-test codes are automatically typed to lab-result-codes
 */
export const CRITICAL_RESULT_IGNORE_CODES = FINGER_STICK_TESTS.filter(
  ({ ignoreCriticalResult }) => !!ignoreCriticalResult
).map(({ resultCode }) => LabResultCode(resultCode));
