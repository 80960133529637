import { USER_EMAIL_AUDIT_TYPES } from '@email-types';
import { UserEmailAuditListActionColumn } from './user-email-audit-list-table-column';

// Columns to display by type of userAudit
export const displayedColumnsMap = {
  all: ['createdAt', 'emailTemplate', 'email', 'actions'],
  [USER_EMAIL_AUDIT_TYPES.NO_SHOW]: [
    'createdAt',
    'emailTemplate',
    'email',
    'actions'
  ],
  [USER_EMAIL_AUDIT_TYPES.EVENT_CANCELLED]: [
    'createdAt',
    'emailTemplate',
    'email',
    'actions'
  ],
  [USER_EMAIL_AUDIT_TYPES.HRA_REMINDER]: [
    'createdAt',
    'emailTemplate',
    'email',
    'actions'
  ]
};
// Actions to show depending of the type of user audit
export const actionColumnsMap: {
  [key in USER_EMAIL_AUDIT_TYPES]?: UserEmailAuditListActionColumn[];
} = {
  [USER_EMAIL_AUDIT_TYPES.NO_SHOW]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<UserEmailAuditListActionColumn>,
  [USER_EMAIL_AUDIT_TYPES.EVENT_CANCELLED]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<UserEmailAuditListActionColumn>,
  [USER_EMAIL_AUDIT_TYPES.HRA_REMINDER]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<UserEmailAuditListActionColumn>
};
