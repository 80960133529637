import { createAction, props } from '@ngrx/store';
import { CoachingSession, CommonResponse } from '@common';

export const coachingSessionActions = {
  get: createAction(
    '[COACHING_SESSION] GET',
    props<{
      id: string;
    }>()
  ),
  getSuccess: createAction(
    '[COACHING_SESSION] GET_SUCCESS',
    props<{
      entity: CoachingSession;
    }>()
  ),
  getFailed: createAction(
    '[COACHING_SESSION] GET_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  list: createAction('[COACHING_SESSION] LIST'),
  listSuccess: createAction(
    '[COACHING_SESSION] LIST_SUCCESS',
    props<{
      entities: CoachingSession[];
    }>()
  ),
  listFailed: createAction(
    '[COACHING_SESSION] LIST_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  listAvailabilities: createAction(
    '[COACHING_SESSION] LIST_AVAILABILITIES',
    props<{
      date: string;
      timezone: string;
    }>()
  ),
  listAvailabilitiesSuccess: createAction(
    '[COACHING_SESSION] LIST_AVAILABILITIES_SUCCESS',
    props<{
      availabilities: string[];
    }>()
  ),
  listAvailabilitiesFailed: createAction(
    '[COACHING_SESSION] LIST_AVAILABILITIES_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  create: createAction(
    '[COACHING_SESSION] CREATE',
    props<{
      entity: Partial<CoachingSession>;
    }>()
  ),
  createSuccess: createAction(
    '[COACHING_SESSION] CREATE_SUCCESS',
    props<{
      entity: CoachingSession;
    }>()
  ),
  createFailed: createAction(
    '[COACHING_SESSION] CREATE_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  remove: createAction(
    '[COACHING_SESSION] REMOVE',
    props<{
      id: string;
    }>()
  ),
  removeSuccess: createAction(
    '[COACHING_SESSION] REMOVE_SUCCESS',
    props<{
      entity: CoachingSession;
    }>()
  ),
  removeFailed: createAction(
    '[COACHING_SESSION] REMOVE_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),
  exportCompanyCs: createAction(
    '[COACHING_SESSION] EXPORT_COMPANY_CS',
    props<{
      company: string;
    }>()
  ),
  exportCompanyCsSuccess: createAction(
    '[COACHING_SESSION] EXPORT_COMPANY_CS_SUCCESS'
  ),
  exportCompanyCsFailed: createAction(
    '[COACHING_SESSION] EXPORT_COMPANY_CS_FAILED'
  )
};
