import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { EhsCardLabelModule } from '../ehs-card-label/ehs-card-label.module';
import { EhsPlatformMetricsComponent } from './ehs-platform-metrics.component';
import { EhsCardSeparatorModule } from '../ehs-card-separator/ehs-card-separator.module';
import { EhsCardModule } from '../ehs-card/ehs-card.module';

@NgModule({
  declarations: [EhsPlatformMetricsComponent],
  imports: [
    CommonModule,
    FormsModule,

    // Ehs-ui
    EhsCardModule,
    EhsCardSeparatorModule,
    EhsCardLabelModule,

    // Angular Material
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule
  ],
  exports: [EhsPlatformMetricsComponent]
})
export class EhsPlatformMetricsModule {}
