import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Inject,
  OnDestroy
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification, NotificationType, getId } from '@common';
import { AdminCreateNotificationDialogData } from './admin-create-notification-dialog-data';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'ehs-admin-create-notification-dialog',
  templateUrl: './ehs-admin-create-notification-dialog.component.html',
  styleUrls: ['./ehs-admin-create-notification-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsAdminCreateNotificationDialogComponent
  implements OnInit, OnDestroy
{
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AdminCreateNotificationDialogData,
    private dialogRef: MatDialogRef<EhsAdminCreateNotificationDialogComponent>
  ) {}

  public notification: Partial<Notification>;
  public editor: Editor;
  public editorConfig = { height: '10rem', minHeight: '5rem' };
  public toolbar: Toolbar = [
    ['bold', 'italic', 'underline'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    ['link', 'image'],
    ['text_color', 'background_color']
  ];

  onEditorContentChange(event: string) {
    this.notification.message = event.replace(
      /<a([^>]*)>/g,
      '<a$1 style="color:blue;text-decoration:underline">'
    );
  }

  ngOnInit(): void {
    this.editor = new Editor();
    this.notification = { message: '', subject: '' };

    if (this.data.eventService) {
      this.notification = {
        ...this.notification,
        messageType: NotificationType.SERVICE,
        serviceId: getId(this.data.eventService)
      };
    } else if (this.data.eventLocation) {
      this.notification = {
        ...this.notification,
        messageType: NotificationType.EVENT,
        eventId: getId(this.data.eventLocation)
      };
    } else if (this.data.company) {
      this.notification = {
        ...this.notification,
        messageType: NotificationType.COMPANY,
        companyId: getId(this.data.company)
      };
    } else if (this.data.user) {
      // If message is direct admin-to-user, we can pass user here, otherwise we will have to fetch all related users to company/eventService in ngrx
      const user = this.data.user;

      this.notification = {
        ...this.notification,
        recipient: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          id: getId(user),
          accountType: 'user'
        },
        companyId: getId(user.company),
        messageType: NotificationType.USER
      };
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  createNotification(form: NgForm) {
    if (!form.valid) {
      return;
    }

    this.dialogRef.close(this.notification);
  }
}
