import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  convertEhsReportImagesToObject,
  CustomReportImages,
  EHS_REPORT_IMAGE_KEYS,
  EHS_REPORT_PDF_IMAGES,
  GenerateResultsReportImagesConfig,
  getId,
  getSpanishFileName,
  PhoneUtil,
  User,
  UserResult,
  UserTest,
  UserTestId
} from '@common';
import { UserCustomReportHttpService } from '@http';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take
} from 'rxjs/operators';
import { getCustomReport } from './ehs-report-utils';

type GenerateParams = {
  /**
   * The user-result to generate from
   */
  userResult: UserResult;
  /**
   * The previous user-results, these are sorted and can include
   * the user-result passed as its automatically ignored.
   */
  previousUserResults?: UserResult[];
  /**
   * All user-tests in the platform
   */
  userTests: UserTest[] | Record<UserTestId, UserTest>;
  /**
   * The user from the user-registration
   */
  user: User;
  /**
   * The language used for the report. By default we assume english.
   * current references:
   * https://www.loc.gov/standards/iso639-2/php/code_list.php
   *
   * **note** this reference document will probably move into `common` in the future
   * once more of the application supports spanish.
   */
  language?: 'en' | 'es';
};
/**
 * This service is responsible for generating the full report for
 * the user. This report includes the ehs-report, and the lab-reported
 * merged using pdf-lib.
 */
@Injectable({
  providedIn: 'root'
})
export class FullReportService {
  /**
   * Observable of images as base64 strings. Loaded and saved the first time.
   */
  public englishImages$: Observable<GenerateResultsReportImagesConfig>;
  /**
   * Observable of the spanish background images as base64 strings. Loaded and saved the first time.
   */
  public spanishImages$: Observable<GenerateResultsReportImagesConfig>;
  constructor(
    private http: HttpClient,
    private customReportHttp: UserCustomReportHttpService
  ) {
    this.englishImages$ = this.getImages$();
    this.spanishImages$ = this.getImages$(true);
  }

  public getImages$(
    spanish?: boolean
  ): Observable<GenerateResultsReportImagesConfig> {
    const getPath = (filename: string) =>
      spanish
        ? `assets/img/report-es/${filename}`
        : `assets/img/report/${filename}`;

    return forkJoin(
      // Load all images
      EHS_REPORT_PDF_IMAGES.map((page) =>
        this.http.get(getPath(spanish ? getSpanishFileName(page) : page), {
          responseType: 'blob'
        })
      )
    ).pipe(
      // Convert every image to base64
      mergeMap((blobs) =>
        forkJoin(
          blobs.map((blob) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            return new Promise<string>(
              (resolve) =>
                (reader.onloadend = () => resolve(reader.result as string))
            );
          })
        )
      ),
      map(convertEhsReportImagesToObject),
      take(1),
      // Only execute this 1 time, the first time.
      shareReplay(1)
    );
  }

  /**
   * Same as generate and download, except we return a buffer of
   * the final pdf. Should be used for "mass downloads" of reports.
   */
  public generate(params: GenerateParams): Observable<Buffer> {
    const { userResult, previousUserResults, userTests, user } = params;
    const useSpanish = params.language === 'es';

    return combineLatest([
      from(import('@results-report-pdf')),
      useSpanish ? this.spanishImages$ : this.englishImages$
    ]).pipe(
      take(1),
      mergeMap(
        ([{ generateResultsWithLab }, images]) =>
          generateResultsWithLab({
            images,
            userTests,
            user,
            userResult,
            previousUserResults,
            buffer: true,
            language: params.language
          }) as Promise<Buffer>
      )
    );
  }

  public getCustomPages$(
    customReports: CustomReportImages[],
    user: User,
    spanish?: boolean
  ) {
    if (!customReports?.length) {
      return of(undefined);
    }

    const customReport = getCustomReport({
      user,
      customReports,
      spanish
    });

    if (!customReport) {
      return of(undefined);
    }

    // Filter for only the image properties
    const customPageKeys = Object.keys(customReport).filter(
      (key: keyof GenerateResultsReportImagesConfig) =>
        EHS_REPORT_IMAGE_KEYS.includes(key)
    );

    return forkJoin(
      customPageKeys.map((key) =>
        this.customReportHttp
          .getCustomReportDownloadSignedUrl({
            fileId: customReport[key],
            id: getId(customReport)
          })
          .pipe(
            take(1),
            switchMap((res) => {
              if (!res || !res.url) {
                return of(undefined);
              }

              return this.http
                .get(res.url, {
                  responseType: 'blob'
                })
                .pipe(
                  // We handle the error otherwise the forkJoin will not return any of the pages (in case a page did not load)
                  catchError((_) => {
                    console.error('File could not be loaded', _);

                    return of(undefined);
                  })
                );
            }),
            // Convert the Blob image to base64 since pdf-make requires images to be in base64
            map((blob) => {
              // If the image is not loaded, we return undefined so we skip the page
              if (!blob) {
                return undefined;
              }

              const reader = new FileReader();

              reader.readAsDataURL(blob);

              return new Promise<string>(
                (resolve) =>
                  (reader.onloadend = () => resolve(reader.result as string))
              );
            }),
            take(1)
          )
          .toPromise()
      )
    ).pipe(
      // Convert the array of base64 images into an object of images where the key is the page image key
      map((base64Pages) =>
        customPageKeys.reduce((map, key, index) => {
          const base64Image = base64Pages[index];

          if (base64Image) {
            map[key] = base64Image;
          }

          return map;
        }, {})
      ),
      take(1),
      shareReplay(1)
    );
  }

  /**
   * Generates the user's report.
   *
   * **note** this is a promise, as its executed
   */
  public generateAndDownload(params: GenerateParams): void {
    const { userResult, previousUserResults, userTests, user } = params;
    const useSpanish = params.language === 'es';

    combineLatest([
      from(import('@results-report-pdf')),
      useSpanish ? this.spanishImages$ : this.englishImages$,
      this.customReportHttp.listByCompany(getId(user?.company)).pipe(
        catchError((err) => {
          console.warn('Report has no custom pages');

          return of([]);
        }),
        switchMap((customReports) =>
          this.getCustomPages$(customReports, user, useSpanish)
        )
      )
    ])
      .pipe(
        take(1),
        mergeMap(([{ generateResultsWithLab }, images, customPages]) => {
          const customImages = { ...images, ...(customPages || {}) };

          return generateResultsWithLab({
            images: customImages,
            userTests,
            user,
            userResult,
            previousUserResults,
            language: params.language
          });
        })
      )
      .subscribe((pdfBase64) => {
        if (PhoneUtil.isSafari()) {
          PhoneUtil.openBase64Pdf(pdfBase64);

          return;
        }

        const downloadLinkElement: HTMLAnchorElement =
          document.createElement('a');

        downloadLinkElement.href = `data:application/pdf;base64,${pdfBase64}`;
        downloadLinkElement.download = `health-report-${userResult.collectionDate}.pdf`;
        downloadLinkElement.click();
        downloadLinkElement.remove();
      });
  }
}
