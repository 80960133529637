import { NgModule } from '@angular/core';
import { IsOver17Directive } from './is-over-17-validator.directive';
import { IsPast1900Directive } from './is-past-1900-validator.directive';
import { ValidBirthDateDirective } from './valid-birthdate-validator.directive';

@NgModule({
  declarations: [
    ValidBirthDateDirective,
    IsOver17Directive,
    IsPast1900Directive
  ],
  exports: [ValidBirthDateDirective, IsOver17Directive, IsPast1900Directive]
})
export class BirthDayValidatorsModule {}
