import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  LoginErrorResponse,
  notAuthenticatedResponse,
  notAuthorizedResponse
} from '@common';
import { userNgrxActions } from '@ehs-ngrx/user-ngrx';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { clientLogger } from '../../client-logger';
import { FirebaseAuthService } from '../../firebase/firebase-auth.service';
import { AuthHttpService } from '../../http/auth-http.service';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { authActions } from './auth.actions';
import { AuthFacade } from './auth.facade';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private errorReportFacade: ErrorReportFacade,
    private firebaseAuth: FirebaseAuthService,
    private authHttp: AuthHttpService,
    private router: Router,
    private authFacade: AuthFacade
  ) {}

  private user$ = this.authFacade.user$;

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getUser),
      mergeMap((action) =>
        this.authHttp.getUser(action).pipe(
          map(({ user, clientAdmin }) =>
            authActions.getUserSuccess({ user, clientAdmin })
          ),
          catchError((err: HttpErrorResponse) => {
            if (err.status === notAuthenticatedResponse.code) {
              return [authActions.getUserFailed()];
            }

            return [
              authActions.getUserFailed(),
              this.errorReportFacade.create({ err: err.error, action })
            ];
          })
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      mergeMap((action) =>
        this.authHttp.login(action.loginRequest).pipe(
          map(({ user, clientAdmin }) =>
            authActions.loginSuccess({ user, clientAdmin })
          ),
          tap(() => {
            const redirect = action.loginRequest.redirect || '/';

            if (redirect === '/') {
              this.router.navigate(['/']);
            } else {
              this.router.navigateByUrl(redirect);
            }
            this.firebaseAuth.signOut();
          }),
          catchError((err: HttpErrorResponse) =>
            err.status === notAuthorizedResponse.code
              ? [
                  authActions.loginFailed({
                    err: err.error as LoginErrorResponse
                  })
                ]
              : [
                  authActions.loginFailed({}),
                  this.errorReportFacade.create({ err: err.error, action })
                ]
          )
        )
      )
    )
  );

  /**
   * When the login fails, we check its code and update the back-end with
   * the failed attempt.
   */
  firebaseLoginFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.firebaseLoginFailed),
      filter(({ err: { code } }) => code === 'auth/too-many-requests'),
      map(({ username }) =>
        authActions.lockAccount({
          username
        })
      )
    )
  );

  isLocked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getIsLocked),
      mergeMap((action) =>
        this.authHttp.getIsLocked(action).pipe(
          map(({ locked }) => authActions.getIsLockedSuccess({ locked })),
          catchError((err: HttpErrorResponse) => [
            authActions.getIsLockedFailed({
              err: err.error
            })
          ])
        )
      )
    )
  );

  lockAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.lockAccount),
      mergeMap((action) =>
        this.authHttp.lockAccount(action).pipe(
          map(() => authActions.lockAccountSuccess()),
          catchError((err: HttpErrorResponse) => [
            authActions.lockAccountFailed({
              err: err.error
            })
          ])
        )
      )
    )
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signUp),
      mergeMap((action) =>
        this.authHttp.register(action).pipe(
          map((response) => authActions.signUpSuccess({ response })),
          catchError((err: HttpErrorResponse) => [
            authActions.signUpFailed({ error: err.error }),
            this.errorReportFacade.create({ err: err.error, action })
          ])
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logout),
      mergeMap((action) =>
        this.authHttp.logout().pipe(
          // If no company/client code found, 'login' is returned instead
          tap(({ clientCode }) => this.router.navigate([`/${clientCode}`])),
          map(({ clientCode }) => {
            return authActions.logoutSuccess({ clientCode });
          }),
          catchError((err) => {
            this.router.navigate(['/login']);

            return [
              authActions.logoutFailed(),
              this.errorReportFacade.create({ err: err.error, action })
            ];
          })
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updateUser),
      mergeMap((action) =>
        this.authHttp.updateUser(action).pipe(
          map((user) => authActions.updateUserSuccess({ user })),
          catchError((err: HttpErrorResponse) => [
            authActions.updateUserFailed(),
            this.errorReportFacade.create({ err: err.error, action })
          ])
        )
      )
    )
  );

  isValidUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.isValidUsername),
      // We use switchMap, as we don't care about previous
      // requests if call this endpoint is called again
      switchMap((action) =>
        this.authHttp.isValidUsername(action).pipe(
          map((valid) => authActions.isValidUsernameSuccess({ valid })),
          catchError(() => [authActions.isValidUsernameFailed()])
        )
      )
    )
  );

  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.verifyEmail),
      mergeMap((action) =>
        this.authHttp.verifyEmail(action).pipe(
          map(() => authActions.verifyEmailSuccess()),
          catchError((err: HttpErrorResponse) => [
            authActions.verifyEmailFailed(),
            this.errorReportFacade.create({ err: err.error, action })
          ])
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resetPassword),
      mergeMap((action) =>
        this.authHttp.passwordReset(action).pipe(
          map(() => authActions.resetPasswordSuccess()),
          catchError((err: HttpErrorResponse) => [
            authActions.resetPasswordFailed(),
            this.errorReportFacade.create({ err: err.error, action })
          ])
        )
      )
    )
  );

  forgotUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.forgotUsername),
      mergeMap((action) =>
        this.authHttp.forgotUsername(action).pipe(
          map(() => authActions.forgotUsernameSuccess()),
          catchError((err: HttpErrorResponse) => [
            authActions.forgotUsernameFailed(),
            this.errorReportFacade.create({ err: err.error, action })
          ])
        )
      )
    )
  );

  reAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.reAuth),
      withLatestFrom(this.user$),
      filter(([, user]) => !!user),
      mergeMap(([action, user]) =>
        this.firebaseAuth.reauthenticate({ ...action, user }).pipe(
          map(() => authActions.reAuthSuccess()),
          catchError(
            (err: { a: any; code: string; message: string; stack: string }) => {
              clientLogger.error(err);
              const { code, message } = err;

              return [
                authActions.reAuthFailed({
                  err: {
                    code,
                    message
                  }
                }),
                this.errorReportFacade.create({
                  err: { code, message },
                  action
                })
              ];
            }
          )
        )
      )
    )
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updatePassword),
      mergeMap((action) =>
        this.firebaseAuth.updatePassword(action).pipe(
          map(() => authActions.updatePasswordSuccess()),
          catchError(
            (err: { a: any; code: string; message: string; stack: string }) => {
              clientLogger.error(err);
              const { code, message } = err;

              return [
                authActions.updatePasswordFailed({
                  err: {
                    code,
                    message
                  }
                }),
                this.errorReportFacade.create({
                  err: { code, message },
                  action
                })
              ];
            }
          )
        )
      )
    )
  );

  /**
   * Interim solution to update auth state's user on user ngrx loginAsUser success.
   * TODO: Check if this is still needed after admin page has been established.
   */
  userNgrxLoginAsUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.loginAsUser.success),
      map(({ payload: { user } }) => authActions.loginSuccess({ user }))
    )
  );
}
