/**
 * Possible values for administration notes passed to the state.
 */
export enum COVIDAdministrationNotes {
  '00' = '00',
  '01' = '01',
  '02' = '02',
  '03' = '03',
  '04' = '04',
  '05' = '05',
  '06' = '06',
  '07' = '07',
  '08' = '08'
}
