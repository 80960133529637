import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsConfirmDialogComponent } from './ehs-confirm-dialog.component';
import { EhsConfirmDialogData } from './ehs-confirm-dialog-data';

@Injectable()
export class EhsConfirmDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: EhsConfirmDialogData
  ): Observable<boolean | { value: boolean }> {
    return this.matDialog
      .open(EhsConfirmDialogComponent, {
        data,
        maxWidth: '450px'
      })
      .afterClosed();
  }
}
