import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { adminEventRegStatusDefMap } from '../ehs-reg-table/ehs-reg-table-status-col';

@Component({
  selector: 'ehs-ehs-reg-status-legend',
  templateUrl: './ehs-reg-status-legend.component.html',
  styleUrls: ['./ehs-reg-status-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsRegStatusLegendComponent {
  /**
   * The list of all reg-statuses available.
   * This is defined explicitly so we can manage the order.
   */
  public readonly regStatuses = [
    adminEventRegStatusDefMap.pending_review,
    adminEventRegStatusDefMap.pending_payment,
    adminEventRegStatusDefMap.pending_release,
    adminEventRegStatusDefMap.pending_req,
    adminEventRegStatusDefMap.complete
  ];

  /**
   * If we are to display the legend without the toggle button.
   * Will for the legend to always be "opened"
   */
  @Input() noToggle?: boolean;

  /**
   * Change the menu x position, defaults to before,
   * as often the button is on the "right side" of the UI.
   */
  @Input() xPosition: MenuPositionX = 'before';

  /**
   * Change the menu y position, defaults to after
   */
  @Input() yPosition: MenuPositionY = 'below';
}
