import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { EhsGwappsActionsModule } from '../ehs-gwapps-actions/ehs-gwapps-actions.module';
import { EhsServiceTypesModule } from '../ehs-service-types/ehs-service-types.module';
import { EhsGwappsServiceComponent } from './ehs-gwapps-service.component';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [EhsGwappsServiceComponent],
  imports: [
    CommonModule,
    RouterModule,

    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    // Child components
    EhsGwappsActionsModule,
    EhsServiceTypesModule
  ],
  exports: [EhsGwappsServiceComponent]
})
export class EhsGwappsServiceModule {}
