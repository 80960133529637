/**
 * Onsite registration error response reasons
 *
 * **note** this might change to be more generic or more specific in the future
 */
export enum OnsiteResponseReasons {
  /**
   * If the registration location is now full, could occur if
   * locations are changed quickly.
   */
  LOCATION_FULL = 'location_full'
}
