import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { EhsConfig, EHS_CONFIG_TOKEN } from './ehs-config';
/**
 * This service interfaces to get the `config.json` file that should
 * be generated in production environments. If it isn't given, then
 * we can use an injected default value.
 *
 * This can be provided locally to prevent bad http requests
 * from being executed.
 */
@Injectable()
export class EhsToolbarConfigHttpService {
  constructor(
    @Inject(EHS_CONFIG_TOKEN)
    @Optional()
    private ehsConfigToken: Partial<EhsConfig>,
    private http: HttpClient
  ) {}

  /**
   * Returns the config object, **or** if it isn't found, then we will return
   * the injected default config.
   *
   * If the config is found **and** the injected version is provided, we will
   * map the `config.json` **over** the ehsConfigToken injected version.
   */
  public getConfig$() {
    return this.http.get<EhsConfig>('/config.json').pipe(
      catchError((err: HttpErrorResponse) => {
        if (this.ehsConfigToken) {
          return of(this.ehsConfigToken);
        }
        console.log('no');
        throw err;
      }),
      map((fileConfig) => {
        if (!this.ehsConfigToken) {
          // If there is no injected config, then just return the default.
          return fileConfig;
        }

        // Otherwise map them
        return {
          ...this.ehsConfigToken,
          ...fileConfig
        };
      }),
      tap(
        (val) => console.log('test with get-config', val),
        (val) => console.log('test with get-config error:', val)
      )
    );
  }
}
