/**
 * Enum that represents the different types of sign-ups for
 * the onsite registration flow.
 *
 * Replaces the largely uneven previous implementation.
 *
 * In the new flow the user can select the options they want.
 */
export enum OnsiteRegistrationType {
  SCREENING = 'screening',
  FLU_VACCINE = 'flu_vaccine',
  COVID_VACCINE = 'covid_vaccine',
  BOTH = 'both'
}
