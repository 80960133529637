import { UserTestId } from '../models/user-test/user-test';

/**
 * EHS wants the ability for a very select clients to give their employees the option to upgrade their panels.
 * To do that, they have selected a few panels that can be listed as an optional test but will act as a panel
 * when selected.
 * Reference: Github issue #3641
 */
export enum UpgradePanels {
  PANEL_C_UPGRADE = '620ff0fe2c1f4a001b4e5e4d'
}

export const UPGRADE_PANELS = [UserTestId(UpgradePanels.PANEL_C_UPGRADE)];
