import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { EhsUserTableComponent } from './ehs-user-table.component';
import { RouteTrackerService } from './route-tracker.service';

@NgModule({
  declarations: [EhsUserTableComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    RouterModule,
    // Angular Material
    FlexLayoutModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatMenuModule,
    MatIconModule
  ],
  exports: [EhsUserTableComponent],
  providers: [RouteTrackerService]
})
export class EhsUserTableModule {}
