import { NgModule } from '@angular/core';
import { EhsButtonAnimationDirective } from './button-hover-animation.directive';
import { EhsElevateCardAnimationDirective } from './elevate-card-animation.directive';
import { EhsFadeInAnimationDirective } from './fade-in-animation.directive';
import { EhsFillProgressBarAnimationDirective } from './fill-progress-bar-animation.directive';

@NgModule({
  declarations: [
    EhsButtonAnimationDirective,
    EhsElevateCardAnimationDirective,
    EhsFadeInAnimationDirective,
    EhsFillProgressBarAnimationDirective
  ],
  exports: [
    EhsButtonAnimationDirective,
    EhsElevateCardAnimationDirective,
    EhsFadeInAnimationDirective,
    EhsFillProgressBarAnimationDirective
  ]
})
export class EhsAnimationsModule {}
