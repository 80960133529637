import { ObjectId } from 'mongodb';
import { Languages } from '../constants/languages';
import { GenerateResultsReportImagesConfig } from '../constants/pdf-images/ehs-report-images';
import { Company } from './company';
import { DbDocument } from './db-document';
import { EventService } from './event-service/event-service';
import { States } from '../constants/states';

export interface CustomReportImages
  extends GenerateResultsReportImagesConfig,
    DbDocument {
  company: ObjectId | Company | string;
  eventService: ObjectId | EventService | string;
  language: Languages;
  states?: States[];
  name?: string;
  status: CustomReportStatus;
  reportType: CustomReportType;
}

export enum CustomReportType {
  RESULT_PDF = 'result_pdf'
}
export enum CustomReportStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum CustomReportStatusColor {
  INACTIVE = '#F23A2E',
  ACTIVE = '#43A446'
}

export const getCustomReportStatusColor = (status: CustomReportStatus) => {
  switch (status) {
    case CustomReportStatus.INACTIVE:
      return CustomReportStatusColor.INACTIVE;
    case CustomReportStatus.ACTIVE:
      return CustomReportStatusColor.ACTIVE;
    default:
      return CustomReportStatusColor.INACTIVE;
  }
};

export const customReportPageNames = {
  coverPage: 'Cover Page',
  bpAndBmiPage: 'Biometrics Page',
  overviewAndHowToReadPage: 'Understanding Your Results',
  firstResultPage: 'Detailed Results',
  secondaryResultPage: 'Details Results Continued',
  previousResultPage: 'Year Over Year Results',
  earlyDetectionIsKey: 'Fluff Page 1',
  tipsToSucceed: 'Spread Page 1 (Live Well With Healthy Habits)',
  exerciseSleepAndRest: 'Spread Page 2 (Live Well Part 2)',
  doctorVisit: 'Fluff Page 2 (Doctor Visit)',
  participationPage: 'Fluff Page 3 (Participation)',
  femaleHealthChecklist: 'Back Panel (Female)',
  maleHealthChecklist: 'Back Panel (Male)',
  crosswordPage: 'Inside Front Cover (biometrics)'
};
