import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserTestRangeValuesPipe } from './user-test-range-values.pipe';

@NgModule({
  declarations: [UserTestRangeValuesPipe],
  imports: [CommonModule],
  exports: [UserTestRangeValuesPipe]
})
export class UserTestRangeValuesModule {}
