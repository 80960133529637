import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { DateUtil } from '@common';
import { DateTime } from 'luxon';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[isOver17]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: IsOver17Directive,
      multi: true
    }
  ]
})
export class IsOver17Directive implements Validator {
  /**
   * Returns if the given date is over 17 as of today.
   * Can handle date-formatted strings and js dates.
   */
  public validate(control: AbstractControl): { [key: string]: unknown | null } {
    const { value } = control;

    // If nothing is given then the date is "valid" in the sense we wont error out
    // The require dpipe should handle it
    if (!value) {
      return null;
    }
    // **Note** the birth date should be in format:
    // MM/dd/yyyy rather than the other types;
    const values = value.split('/').map(Number) as number[];
    const validParams =
      values.length === 3 &&
      values.every((num) => num !== undefined && Number.isInteger(num));

    if (!validParams) {
      // If the params are invalid, then don't check
      return null;
    }

    const dateTime = DateTime.fromFormat(value, 'MM/dd/yyyy');

    if (!dateTime.isValid) {
      // If the date isn't valid, another validator should handle it
      return null;
    }

    if (DateUtil.isOver17(dateTime.toFormat('yyyy-MM-dd'))) {
      return null;
    }

    return {
      isOver17: {
        value
      }
    };
  }
}
