import { HraFormShow, HraQuestion } from './hra-question';
import { HraResult } from './hra-result';
import { HraScoreCategoryTypes } from './hra-score-category';

/**
 * An Hra answer defines an answer for the user.
 */
export interface HraAnswer {
  /**
   * The unique code for the hra-answer. Usually is a
   * string of similar shape/value as: `1a`
   */
  code: string;
  /**
   * The questions description that is shown to the user.
   */
  description: string;
  /**
   * The number value this question provides to the overall
   * score for the given score-category
   *
   * This can only be undefined in specific situations where
   * there is no value.
   */
  value?: number;
  /**
   * Report: Text shown on the report that displays the user's answer.
   * This is equivalent to the description just worded differently for
   * the report.
   */
  answer?: string;
  /**
   * Report: Text shown to users based on what answer they had selected.
   * It is either congrats or encouragement text.
   */
  feedback?: string;
  /**
   * Report: Color that will show on the report for the user's selected value.
   */
  color?: HraAnswerColors;
  /**
   * Report: Some answers will need to be shown as icons in the report instead
   * of test.
   */
  icon?: string;
  /**
   * The score category this answer will be calculated under. This is currently
   * only used in some specific questions that use the customScoreCalc definition
   * on the given question.
   *
   * Added for #761
   */
  scoreCategory?: HraScoreCategoryTypes;
  /**
   * Function that we can define to show the answer or not.
   * If this is not given, then the answer is always shown.
   */
  show?: HraFormShow;
  /**
   * Function that returns if the given answer should be disabled.
   */
  disable?: HraAnswerDisableFunc;
  /**
   * If the given checkbox is required.
   * Will be used **only** for the checkbox
   */
  isCheckboxRequired?: HraIsCheckboxRequired | boolean;
  /**
   * If this option "clears" the other selected values for this question.
   * This only does something for MULTI type questions
   */
  clear?: boolean;
}

export type HraAnswerDisableFunc = (params: {
  record: Partial<HraResult>;
  question: HraQuestion;
  answer: HraAnswer;
}) => boolean;

export type HraIsCheckboxRequired = (params: {
  record: Partial<HraResult>;
  question: HraQuestion;
  answer: HraAnswer;
}) => boolean;
/**
 * A map where the key is the question-code,
 * and the value is a map of all the answers,
 * where the key is the answer code and value is the answer.
 */
export interface HraAnswerMap {
  /**
   * Map where the key is the question code
   */
  [key: string]: {
    /**
     * Map where the key is the answer code
     */
    [key: string]: HraAnswer;
  };
}

/**
 * The different hex colors answers that
 * are shown on the hra report
 */
export enum HraAnswerColors {
  GREEN = '#62bda4',
  YELLOW = '#ebd682',
  RED = '#d15858'
}

/**
 * Feedback answers of encouragement or congrats. This is useful since
 * most answers will have repeated feedback.
 */
export const hraAnswerFeedback = {
  Q1_CONGRATS:
    'You are up and moving 5+ days per week for 30 minutes or more. This is the recommended amount of exercise. Keep up the good work staying active!',
  Q1_ENCOURAGEMENT: `You're off to a good start but there is always room to improve. Make it your goal to be active 5+ days per week for 30 minutes or more.
    You may find you have more energy the more you move!`,
  Q2_CONGRATS: `Your physical activity likely includes cardio, weight training, brisk walking, participating in a sport, etc.
    Stay consistent with the intensity of your exercise as it is in the ideal range! By exercising at the correct intensity level,
    you can get the most out of your workout.`,
  Q2_ENCOURAGEMENT: `In order to receive the maximum benefit of your exercise, your intensity must be at a moderate or vigorous level.
    Start at a light intensity if you're new to exercising. Gradually build up to a moderate or vigorous intensity.`,
  Q2A_CONGRATS:
    'Great job on beginning a program to increase your level of exercise! Staying motivated can help to continue your exercise program.',
  Q2A_ENCOURAGEMENT:
    'Consider your reasons for exercising. Do you want to improve your fitness, lose weight, train for a competition or do a combination of these? ' +
    'It is important for you to create an exercise program that will fit within your lifestyle.',
  Q3_CONGRATS:
    'You are practicing good safety habits by always wearing your seatbelt when you are in a car or moving vehicle. ' +
    'Continue to always wear your seatbelt as we know it can save your life and others!',
  Q3_ENCOURAGEMENT: `This is an easy change for you to make! Practice better safety protocols by fastening your seatbelt every time you ride in a car or motor vehicle.
    Safety devices are very important in our everyday life, as many accidents happen every single day. Proper use of safety restraints can save many lives.`,
  Q4_CONGRATS:
    'You are practicing good safety habits by always following the rules and laws of the road while driving. Be sure to continue these behaviors to help prevent the risk of an accident.',
  Q4_ENCOURAGEMENT: `It’s important to follow the rules of the road while driving. These laws are designed to protect you and others and are
    pivotal in preventing serious transportation-related injury.`,
  Q5_CONGRATS:
    'Continue in your quest to feel calm or peaceful at least most of the time. This can allow you to efficiently manage stress and enjoy your day-to-day life.',
  Q5_ENCOURAGEMENT: `While our lives can feel chaotic, it's important to know how to manage the feelings of being overwhelmed. Calming techniques can include exercising, deep breathing,
    eating a nutritious meal, prayer, talking with loved ones, and more. Find the techniques that work best for you and strive to make them part of your routine.`,
  Q6_CONGRATS:
    'Stay motivated to continue your emotional health practices! Your emotional health is an important factor in your overall well-being.',
  Q6_ENCOURAGEMENT: `Considering asking for help managing these feelings. Asking for help does not make you a weak person; in fact, it makes you the opposite.
    If depression is impacting your quality of life, the best thing you can do is seek support. A conversation with your primary care physician is a great place
    to start!`,
  Q7_CONGRATS:
    'Keep up the great effort in learning to handle the pressures of daily life. Knowing how to manage and reduce stress can help you stay happy and healthy.',
  Q7_ENCOURAGEMENT: `While life will always have stressors, stress does not have to rule your life. Much of the time, there are steps we can take to improve our levels of stress.
    If your stress levels don't seem to improve, managing these feelings may require lifestyle changes or help from others.`,
  Q8_CONGRATS:
    'Keep up the great effort when managing your finances! For many people, money can be a source of stress or tension. Continue to manage your stress using healthy techniques that work for you.',
  Q8_ENCOURAGEMENT: `For most, thinking about finances can be stressful. To help reduce money stress, try tracking your spending, creating a budget that caters to short or
    long-term goals, identify stressors and make a plan, work to improve unhealthy spending areas, and/or seek financial guidance and support.`,

  Q9_CONGRATS:
    'Continue managing your stress in a healthy and productive way! This can improve your mental and emotional well-being.',
  Q9_ENCOURAGEMENT: `High stress can negatively affect our mental, emotional, and even social health. If you are looking to reduce stress levels, try the following calming techniques:
    exercise, deep breathing, preparing and eating a nutritious meal, prayer, talking with loved ones, etc. Find the techniques that work best for you and strive to make them part of your routine.`,

  Q10_CONGRATS:
    'You are eating the recommended amount of high fiber and whole-grain foods! These foods have the potential to lower blood sugar and cholesterol levels, as well as reduce the risk of heart disease.',
  Q10_ENCOURAGEMENT: `Work on incorporating more fiber and whole-grain foods into your daily meals. It is recommended that you eat 5 to 6 servings per day. These powerhouse foods can reduce the risk
    of chronic disease by improving digestion and lowering cholesterol and blood sugar levels.`,

  Q11_CONGRATS: `Great job limiting those sugary beverages! By monitoring your sugar intake, you will have an easier time maintaining a healthy weight and nutritious diet.
    This is a great way to prevent the risk of type 2 diabetes. `,
  Q11_ENCOURAGEMENT: `Try and reduce your consumption of sugar-sweetened beverages. When you find yourself craving a sugary drink, choose a healthier alternative such as sparkling water,
  lemon or lime-infused water, or unsweetened tea.`,

  Q12_CONGRATS:
    'Excellent job staying hydrated! You are drinking the recommended amount of water per day. Drinking enough water is a key factor in your overall health.',
  Q12_ENCOURAGEMENT: `Drinking enough water throughout your day is vital to good health, but for many, this can be easy to overlook. Consider bringing a water
    bottle to work or keeping one nearby at home as a constant reminder to stay hydrated. While appropriate water intake can vary person to person, health authorities
    widely recommend drinking eight 8-ounce glasses per day (which equals about 2 liters or half a gallon). `,

  Q13_CONGRATS:
    'Awesome job limiting your consumption of high-fat foods. While indulging in deep-fried dishes or sweet treats can be okay once in a while, eating these foods ' +
    'regularly (and in excess) can be detrimental to your health. You are on your way to a balanced diet! ',
  Q13_ENCOURAGEMENT: `When high-fat foods are a frequent part of your diet, you risk raising your “bad” cholesterol and increasing your chances of heart disease.
    Aim to have only 5% of your daily calories allocated towards high-fat foods.`,

  Q14_CONGRATS:
    'Success! You are a master at including fruits and vegetables in your daily snacks or meals. These foods are an essential part of keeping a well-balanced diet.',
  Q14_ENCOURAGEMENT: `In general, you can't go wrong with eating more fruits and vegetables. It is recommended that you include 4 - 5 servings per day.
    You can include more of these foods in your diet by sneaking berries into your breakfast, or snacking on carrots and celery throughout the day.`,

  Q15_CONGRATS:
    'Nice job! Your healthy diet can fuel you with energy, focus, and boost your overall wellness. Keep doing what you are doing.',
  Q15_ENCOURAGEMENT: `A healthy diet does more than help you maintain a healthy weight. It can fuel you with energy, help reduce sickness and disease, and can boost your overall wellness.
    Make a goal to improve your eating habits and visit cdc.gov/nutrition for helpful tools and resources.`,

  Q16_CONGRATS:
    'Keep up the good work by continuing to lead a tobacco-free lifestyle! This is a great way to take care of your body and avoid health complications.',
  Q16_ENCOURAGEMENT: `A tobacco-free lifestyle is one of the best ways to take care of your body and avoid health complications. The U.S. Department of Health
    and Human Services has established a national toll-free quitline (1-800-QUIT-NOW) for anyone seeking help in quitting smoking. Behavior treatments and
    medications are typically used in combination for the best results.`,

  Q16A_CONGRATS:
    'Fantastic! Keep up the good work in continuing your efforts to quit tobacco.',
  Q16A_ENCOURAGEMENT:
    'Committing to a tobacco-free lifestyle can greatly improve your health and quality of life. Consider quitting with the assistance of a tobacco-free program.',

  Q17_CONGRATS: `Great news! You feel that you are in good health. How you perceive yourself can be an important indicator of your overall wellness.
    Continue to practice healthy habits that help you feel your best.`,
  Q17_ENCOURAGEMENT: `When it comes to your health, you feel there is room for improvement. Create attainable goals that will begin to boost your exercise
    habits, emotional health, daily nutrition, and sleep quality. We recommend that you work with your primary care physician to create the best game plan
    moving forward!  `,

  Q19_CONGRATS:
    'Well done! Continue to visit your primary care physician at least once every 12 months. Why is this so important? An annual appointment will help you ' +
    'prevent disease and monitor your overall health.',
  Q19_ENCOURAGEMENT:
    'An annual appointment with your primary care physician is one of the most important things you can do for your health. Visiting your doctor at least ' +
    'once every 12 months helps prevent serious disease and allows you to monitor your overall health.',

  Q20_CONGRATS: `Congratulations on following the CDC's guidelines to receive a yearly flu shot!  As you may already know,
    getting vaccinated not only protects you from the virus but can help protect people around you, including those who are more
    vulnerable to serious flu illness. Thank you for doing your part!`,
  Q20_ENCOURAGEMENT: `Consider following the CDC’s recommendation to receive a yearly flu shot. Getting vaccinated not only helps
    protect you but can also keep those around you safe - especially those who are more vulnerable to serious illness.
    It is important for you to reach out to your doctor to hear their recommendations for you. `,

  Q21_CONGRATS:
    'Great job fighting those germs! Continue to stay diligent about washing your hands after visiting the restroom, ' +
    'handling food, and before you eat. Handwashing is one of the best ways (and the easiest way) to protect yourself and your family from getting sick.',
  Q21_ENCOURAGEMENT: `Consider improving your efforts to always wash your hands after visiting the restroom, handling food, and before you eat.
    Handwashing is one of the best ways to protect yourself and your family from getting sick. Germs and bacteria that can make us very sick are
    often carried by our hands. Clean hands can save your life and others!`,

  Q22_CONGRATS: `Like most Americans, you may drink alcohol at least occasionally. Most of the time, a glass of wine with dinner or a beer with friends is safe,
    but overindulging in alcoholic beverages can be harmful and addictive. To stay healthy, drink in moderation, monitor how alcohol affects your body, and know
    how much is too much.`,

  Q22A_CONGRATS:
    'Great job! By limiting your alcohol intake (1 drink per day for women and 2 drinks per day for men), you are drinking in moderation and keeping to ' +
    'the recommended beverage limit. Continue to maintain this practice when you choose to enjoy a drink.',
  Q22A_ENCOURAGEMENT: `Consider monitoring the amount of alcohol you're consuming. Binge drinking is usually defined as 4 or more drinks at a time
    for women and 5 or more drinks at a time for men. Drinking less can reduce the chance of several health risks.`,

  Q22B_CONGRATS:
    'Congratulations on choosing to decrease your alcohol intake! You rock! Stay motivated to continue your journey towards a healthier lifestyle.',
  Q22B_ENCOURAGEMENT: `If you have decided to change your drinking habits, there are plenty of programs and support systems that can help you
    achieve this goal. Once you have made this commitment, you will be on your way to a healthier lifestyle.`,

  Q23_CONGRATS:
    'Your sleep habits are a dream. Great work! You are getting the recommended amount of sleep at night, which can help lower health risks, reduce stress, and improve focus.',
  Q23_ENCOURAGEMENT: `If you're having trouble sleeping, try adjusting your routine or speaking with your healthcare provider. A good night’s sleep is important.
    Aim for 7-8 hours of sleep each night to reduce your chances of getting sick, decrease your levels of stress, and improve your focus and overall health.`,

  Q24_CONGRATS:
    'You are on the right path! Sleep helps keep your mind and body healthy.',
  Q24_ENCOURAGEMENT: `Even if you're getting a full night’s sleep, it’s important to understand your sleep quality. How well you sleep can impact your
    productivity, mood, health, and general well-being. Signs of a poor sleep quality include exhaustion, repeatedly waking up throughout the night,
    and experiencing symptoms of sleep disorders (such as snoring or gasping for air). Improved sleep quality may be helped by better habits or being
    diagnosed and treated for a sleep disorder. `,

  Q24A_CONGRATS: `Improving your sleep begins with identifying factors of poor sleep and a commitment to make a change. Consider keeping a sleep diary
    to discuss with your physician, or begin a program that will assist you in developing better sleep habits. Following these steps can put you on the
    right track to a healthier and more enjoyable life! `,
  Q24A_ENCOURAGEMENT: `Forming positive sleep habits is crucial to your physical and emotional health. Consider a program that can assist you with
    understanding the importance of sleep and how you can improve your rest.`,

  BIO1_ENCOURAGEMENT: `Regular exercise helps make your heart stronger and more efficient at pumping blood, which lowers the pressure in
    your arteries. Dietary or lifestyle changes may assist with lowering your systolic BP. Discuss with your physician other recommendations
    that may be available for your. `
};
