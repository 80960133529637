import { Company, CompanySecurity, CompanyStats, getId } from '@common';
import {
  entityStatsReducerFactory,
  EntityStatsState,
  EntityStatsTypeParams,
  selectId
} from '@ehs-ngrx/common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { companyNgrxActions } from './company-ngrx.actions';

export const COMPANY_NGRX_STORE_KEY = 'companyNgrx';
export interface ParentCompanyNgrxState {
  [COMPANY_NGRX_STORE_KEY]: CompanyNgrxState;
}

export type CompanyNgrxStatsTypeParams = EntityStatsTypeParams<
  string,
  'COMPANY_NGRX',
  CompanyStats
>;

export interface CompanyNgrxState
  extends EntityState<Company>,
    EntityStatsState<CompanyNgrxStatsTypeParams> {
  /**
   * If anything in the state is loading
   */
  loading?: boolean;

  /**
   * Flag that is flipped to true the first time companies are loaded,
   * and thus we will save them "cached".
   */
  loaded?: boolean;

  companySecurity?: Record<string, CompanySecurity>;
  /**
   * The current company notes last fetched
   */
  companyNotes?: string;
  /**
   * If company notes are loading. Need to track apart from the company
   * being loaded.
   */
  companyNotesLoading?: boolean;

  companySecurityLoading?: boolean;
}

const adapter = createEntityAdapter<Company>({ selectId });

export const companyReducer = createReducer<CompanyNgrxState>(
  adapter.getInitialState({}),

  on(companyNgrxActions.clear, (state) => adapter.removeAll(state)),

  on(companyNgrxActions.setCompany, (state, { company }) =>
    adapter.upsertOne(company, state)
  ),
  on(companyNgrxActions.getCurrent.req, (state) => ({
    ...state,
    loading: true
  })),
  on(companyNgrxActions.getCurrent.success, (state, { payload: { entity } }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(companyNgrxActions.getCurrent.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(companyNgrxActions.getSecurity.req, (state) => ({
    ...state,
    companySecurityLoading: true
  })),
  on(
    companyNgrxActions.getSecurity.success,
    (state, { payload: { entity } }) => ({
      ...state,
      companySecurityLoading: false,
      companySecurity: {
        ...(state.companySecurity || {}),
        [getId(entity.company)]: entity
      }
    })
  ),
  on(companyNgrxActions.getSecurity.failed, (state) => ({
    ...state,
    companySecurityLoading: false
  })),

  on(companyNgrxActions.getCompanyNotes.req, (state) => ({
    ...state,
    companyNotesLoading: true
  })),
  on(
    companyNgrxActions.getCompanyNotes.success,
    (state, { payload: { companyNotes } }) => ({
      ...state,
      companyNotes: companyNotes || '',
      companyNotesLoading: false
    })
  ),
  on(companyNgrxActions.getCompanyNotes.failed, (state) => ({
    ...state,
    companyNotesLoading: false
  })),

  on(companyNgrxActions.updateSecurity.req, (state) => ({
    ...state,
    companySecurityLoading: true
  })),
  on(
    companyNgrxActions.updateSecurity.success,
    (state, { payload: { entity } }) => ({
      ...state,
      companySecurityLoading: false,
      companySecurity: {
        ...(state.companySecurity || {}),
        [getId(entity.company)]: entity
      }
    })
  ),
  on(companyNgrxActions.updateSecurity.failed, (state) => ({
    ...state,
    companySecurityLoading: false
  })),

  on(companyNgrxActions.updateCompanyNotes.req, (state) => ({
    ...state,
    companyNotesLoading: true
  })),
  on(
    companyNgrxActions.updateCompanyNotes.success,
    (state, { payload: { companyNotes } }) => ({
      ...state,
      companyNotes: companyNotes || '',
      companyNotesLoading: false
    })
  ),
  on(companyNgrxActions.updateCompanyNotes.failed, (state) => ({
    ...state,
    companyNotesLoading: false
  })),

  on(companyNgrxActions.get.req, (state) => ({
    ...state,
    loading: true
  })),
  on(companyNgrxActions.get.success, (state, { payload: { entity } }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(companyNgrxActions.get.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(companyNgrxActions.list.req, (state) => ({
    ...state,
    loading: true
  })),
  on(companyNgrxActions.list.success, (state, { payload: { entities } }) =>
    adapter.upsertMany(entities, {
      ...state,
      loading: false,
      loaded: true
    })
  ),
  on(companyNgrxActions.list.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(companyNgrxActions.exportUserRegistrations.req, (state) => ({
    ...state,
    loading: true
  })),
  on(companyNgrxActions.exportUserRegistrations.success, (state) => ({
    ...state,
    loading: false
  })),
  on(companyNgrxActions.exportUserRegistrations.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(companyNgrxActions.exportUserIncentives.req, (state) => ({
    ...state,
    loading: true
  })),
  on(companyNgrxActions.exportUserIncentives.success, (state) => ({
    ...state,
    loading: false
  })),
  on(companyNgrxActions.exportUserIncentives.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(companyNgrxActions.exportUsers.req, (state) => ({
    ...state,
    loading: true
  })),
  on(companyNgrxActions.exportUsers.success, (state) => ({
    ...state,
    loading: false
  })),
  on(companyNgrxActions.exportUsers.failed, (state) => ({
    ...state,
    loading: false
  })),

  ...entityStatsReducerFactory<CompanyNgrxStatsTypeParams, CompanyNgrxState>({
    entityStatsActions: companyNgrxActions
  })
);
