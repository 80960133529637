<form #userForm="ngForm"
      (submit)="onSubmit()">
  <h1 mat-dialog-title>Restrict User Data?</h1>
  <ng-container *ngIf="user.restrictData else noRestriction">
    <div mat-dialog-content
         class="restrictionText">Last Requested: {{getRequestDate(user)}}</div>
    <div mat-dialog-content
         class="restrictionText">Expiration Date: {{getExpirationDate(user)}}</div>
  </ng-container>
  <ng-template #noRestriction>
    <div mat-dialog-content
         class="restrictionText"
         fxLayoutAlign="center center">No current restriction</div>
  </ng-template>
  <mat-dialog-content class="mat-typography">
    <mat-radio-group name="restrictData"
                     [value]="restrictData">
      <mat-radio-button (change)="restrictData = true">Restrict</mat-radio-button>
      <mat-radio-button (change)="restrictData = false">Unrestrict</mat-radio-button>
    </mat-radio-group>
  </mat-dialog-content>
  <mat-dialog-actions align="end"
                      fxLayoutGap="16px">
    <button type="button"
            class="grey-cancel"
            mat-button
            [mat-dialog-close]="false">
      Cancel
    </button>
    <button type="submit"
            color="primary"
            mat-button
            cdkFocusInitial>
      Update
    </button>
  </mat-dialog-actions>
</form>
