<div fxLayout="row"
     fxLayoutAlign="space-between center"
     *ngIf="company">
  <div gdColumns="minmax(0, 50px) auto"
       gdAlignColumns="start center"
       gdGap="16px">
    <div fxLayout="row"
         fxLayoutAlign="center center"
         style="min-width: 50px; min-height: 50px;">
      <img *ngIf="logoUrl; else iconPlaceholder"
           [src]="logoUrl"
           style="max-width: 50px; max-height: 50px;">
      <ng-template #iconPlaceholder>
        <mat-icon style="font-size: 32px; height: 32px; width: 32px">business
        </mat-icon>
      </ng-template>
    </div>

    <div>
      <div style="padding-top: 4px">
        <span title="Name of the company"> {{company.name}} </span>

        <span title="Company ShortCode"
              *ngIf="!simple"> | {{company.clientCode}} </span>

        <span title="Account Manager"
              *ngIf="!simple"> {{company.accountManager}} </span>
      </div>
      <div class="mat-caption"
           *ngIf="!simple">
        {{location}}
      </div>
    </div>
  </div>

  <button mat-icon-button
          type="button"
          [matMenuTriggerFor]="optionMenu"
          (click)="$event.stopPropagation(); $event.preventDefault()">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #optionMenu="matMenu">
    <div fxLayout="column"
         fxLayoutAlign="center center">
      <ehs-gwapps-actions entityType="company"
                          [entity]="company"
                          (view)="view.emit(company)"
                          (validate)="validate.emit(company)">
      </ehs-gwapps-actions>
    </div>
    <!-- actions for this service -->
    <mat-divider></mat-divider>
    <a type="button"
       mat-menu-item
       [routerLink]="serviceUrl">
      View Services
    </a>

    <a type="button"
       mat-menu-item
       [routerLink]="userUrl">
      View Users
    </a>

  </mat-menu>

</div>
