import { UserUtil } from '@common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app-state';
import { AuthState } from './auth.state';

const featureSelector = createFeatureSelector<AuthState>(
  'auth' as keyof AppState
);
// Core selectors
const loadingSelector = createSelector(
  featureSelector,
  (state) => state.loading
);
const userSelector = createSelector(featureSelector, (state) => state?.user);
const clientAdminSelector = createSelector(
  featureSelector,
  (state) => state?.clientAdmin
);
const isAuthenticatedSelector = createSelector(userSelector, (user) => !!user);
const ageSelector = createSelector(
  userSelector,
  (user) => user && UserUtil.getAge(user)
);
const genderSelector = createSelector(userSelector, (user) => user?.gender);

// Auth-proccess specific
const lastSentVerificationEmailSelector = createSelector(
  featureSelector,
  (state) => state.lastSentVerificationEmail
);
const sentSignUpRequestSelector = createSelector(
  featureSelector,
  (state) => state.sentSignUpRequest
);
const tempUserSelector = createSelector(
  featureSelector,
  (state) => state.tempSignUp
);
const invalidSignUpSelector = createSelector(
  featureSelector,
  (state) => state.invalidSignUp
);
const loginErrorTypeSelector = createSelector(
  featureSelector,
  (state) => state.loginErrorType
);
const isValidUsernameSelector = createSelector(
  featureSelector,
  (state) => state.isValidUsername
);

// Auth-flow loading
const isValidUsernameLoadingSelector = createSelector(
  featureSelector,
  (state) => state.isValidUsernameLoading
);
const verifyEmailLoadingSelector = createSelector(
  featureSelector,
  (state) => state.verifyEmailLoading
);
const reAuthLoadingSelector = createSelector(
  featureSelector,
  (state) => state.reAuthLoading
);
const updatePasswordLoadingSelector = createSelector(
  featureSelector,
  (state) => state.updatePasswordLoading
);

// Auth-flows
const inUpdateSensitiveInfoFlowSelector = createSelector(
  featureSelector,
  (state) => state.inUpdateSensitiveInfoFlow
);
const inPasswordResetFlowSelector = createSelector(
  featureSelector,
  (state) => state.inPasswordResetFlow
);
const inVerifyMFAFlowSelector = createSelector(
  featureSelector,
  (state) => state.inMFAFlow
);

export const authSelectors = {
  // Core selectors
  loadingSelector,
  userSelector,
  clientAdminSelector,
  isAuthenticatedSelector,
  ageSelector,
  genderSelector,

  // Auth-process specific
  lastSentVerificationEmailSelector,
  sentSignUpRequestSelector,
  tempUserSelector,
  invalidSignUpSelector,
  loginErrorTypeSelector,
  isValidUsernameSelector,

  // Auth-flow loading
  isValidUsernameLoadingSelector,
  verifyEmailLoadingSelector,
  reAuthLoadingSelector,
  updatePasswordLoadingSelector,

  // Auth-flows
  inUpdateSensitiveInfoFlowSelector,
  inPasswordResetFlowSelector,
  inVerifyMFAFlowSelector
};
