/* eslint-disable @angular-eslint/no-input-rename */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  EventService,
  EventServiceUtil,
  getId,
  isOffsiteService,
  isOffsiteUserRegistration,
  isOnsiteService,
  isOnsiteUserRegistration,
  isProviderHealthService,
  OffsiteGroupType,
  OnsiteGroupType,
  ServiceType,
  TimeUtil,
  UserRegistration,
  UserTest
} from '@common';
import { MatAccordion } from '@angular/material/expansion';
import {
  UserRegistrationListActionColumn,
  UserRegistrationListActionType
} from './user-registration-list-table-column';

@Component({
  selector: 'ehs-user-registration-list',
  templateUrl: './ehs-user-registration-list.component.html',
  styleUrls: ['./ehs-user-registration-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsUserRegistrationListComponent {
  /**
   * Registration Type: Onsite, Offsite or Provider Health.
   * Date user registered (createdAt) | For Onsite (Only) show Date + Time as well of the registration
   * Group Type of the User (read from the event-service based on the groupType number) -> eventServices.groupTypes
   * Self reported info (height, weight + blood pressure if listed)
   * Person Type (employee, spouse, etc...)
   * Uses Tobacco
   * Optional Tests > List them with bullets maybe. (need to fetch optionalTests by ID) -> userTests
   */
  public readonly displayedColumns = [
    'serviceType',
    'createdAt',
    'groupType',
    'selfReportedInfo',
    'personType',
    'usesTobacco',
    'optionalTests',
    'actions'
  ];

  /**
   * The company-id, required for the edit-url.
   * If not given this wont be shown
   */
  @Input() companyId?: string;

  /**
   * The list of user-registrations to display within this list component.
   */
  @Input() userRegistrations: UserRegistration[] = [];

  /**
   * The list of actions available for a registration through the menu button.
   */
  @Input() userRegistrationListActions?: UserRegistrationListActionColumn[] =
    [];

  /**
   * EventServices of the list of event-services mapped to their _id.
   */
  @Input() eventServices: Record<string, EventService>;

  @Input() userTests: Record<string, UserTest> = {};

  @Output() actionChange = new EventEmitter<{
    actionType: UserRegistrationListActionType;
    userRegistration: UserRegistration;
  }>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  public getServiceDate(userRegistration: UserRegistration) {
    if (isOnsiteUserRegistration(userRegistration)) {
      return userRegistration.eventDate;
    }

    const eventService =
      this.eventServices[getId(userRegistration.eventService)];

    const selectedService = EventServiceUtil.getSelectedService({
      serviceType: userRegistration.serviceType,
      eventService
    });

    if (isOffsiteService(selectedService)) {
      return selectedService.screeningDeadline;
    }

    if (isProviderHealthService(selectedService)) {
      return selectedService.returnBy;
    }

    return '';
  }

  public getEventTime(userRegistration: UserRegistration) {
    if (isOnsiteUserRegistration(userRegistration)) {
      return TimeUtil.timeToAmPm(userRegistration.eventTime);
    }

    return '';
  }

  /**
   * Returns the eventService for the user-registration
   */
  public getEventServiceGroupType(
    userRegistration: UserRegistration
  ): OnsiteGroupType | OffsiteGroupType | undefined {
    const eventService =
      this.eventServices[getId(userRegistration.eventService)];
    const selectedService = EventServiceUtil.getSelectedService({
      serviceType: userRegistration.serviceType,
      eventService
    });

    if (
      isOnsiteService(selectedService) &&
      isOnsiteUserRegistration(userRegistration)
    ) {
      return selectedService.groupTypes[userRegistration.groupType];
    }

    if (
      isOffsiteService(selectedService) &&
      isOffsiteUserRegistration(userRegistration)
    ) {
      return selectedService.groupTypes[userRegistration.groupType];
    }

    return undefined;
  }

  /**
   * Returns the optional test values for the passed user-registration optional tests
   * Each User Registration could have several test ids, we need to create a list of tests that match the ids
   *
   */
  public getOptionalTests(userRegistration: UserRegistration) {
    if (
      isOnsiteUserRegistration(userRegistration) ||
      isOffsiteUserRegistration(userRegistration)
    ) {
      return (
        userRegistration.optionalTests.map(
          (test) => this.userTests[getId(test)]
        ) || []
      );
    }

    return [];
  }

  public trackByFn(index: number) {
    return index;
  }

  public getUserRegistrationSpecificActions(
    userRegistration: UserRegistration
  ): UserRegistrationListActionColumn[] {
    return this.userRegistrationListActions.filter((column) =>
      column.displayFn ? column.displayFn({ userRegistration }) : true
    );
  }

  public isOnsiteUserRegistration(userRegistration: UserRegistration) {
    return isOnsiteUserRegistration(userRegistration);
  }

  /**
   * Provides redirect url to event or service page for
   * the specific registration.
   */
  public getViewLink(userReg: UserRegistration) {
    const eventServiceId = getId(userReg?.eventService);

    if (!eventServiceId) {
      return [];
    }

    switch (userReg.serviceType) {
      case ServiceType.ONSITE:
        return [
          `/ehs/admin/company/${
            this.companyId
          }/service/${eventServiceId}/event/${getId(userReg?.eventLocation)}`
        ];
      case ServiceType.OFFSITE:
        return [
          `/ehs/admin/company/${this.companyId}/service/${eventServiceId}/offsite`
        ];
      case ServiceType.PROVIDER_HEALTH:
        return [
          `/ehs/admin/company/${this.companyId}/service/${eventServiceId}/provider`
        ];
      default:
        return [];
    }
  }
}
