import { EventServiceServiceType } from './event-service';
import { ServiceLabType } from './service-lab-type';
import { ServiceType } from './service-type';
import { OffsiteGroupType } from './site-group-type';
import { SiteService } from './site-service';

export interface OffsiteService extends SiteService {
  type: ServiceType.OFFSITE;
  /**
   * The date the user has to go to a lab prior to
   */
  screeningDeadline: string;
  /**
   * The date the user can start registering after.
   *
   * **note** this was added with #3177.
   */
  registrationActive: string;
  /**
   * The date the user must register before.
   */
  registrationDeadline: string;
  /**
   * The price of the package that includes Panel + Additional Test + Additional Services
   * This is shown for admins in the user-payment if payPackage was enabled for their group
   * type.
   */
  packagePrice: number;
  /**
   * The type of lab this service goes to.
   * Offsite lab-type is **always** required.
   * Onsite lab-types could be undefined
   */
  labType: ServiceLabType;
  /**
   * The groups of users who can participate.
   */
  groupTypes: OffsiteGroupType[];

  showOffsiteService?: boolean;
}

/**
 * Type-guard that returns if service is an offsite service
 */
export const isOffsiteService = (
  service: EventServiceServiceType
): service is OffsiteService => service?.type === ServiceType.OFFSITE;
