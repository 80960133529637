import { createAction, props } from '@ngrx/store';
import { CommonResponse, EventLocation, OnsiteRegistrationType } from '@common';
import { QueryList } from '../../util/query-list.service';

export const eventLocationActions = {
  get: createAction('[EVENT_LOCATION] GET', props<{ id: string }>()),
  set: createAction(
    '[EVENT_LOCATION] SET',
    props<{
      entity: EventLocation;
    }>()
  ),
  setMultiple: createAction(
    '[EVENT_LOCATION] SET_MULTIPLE',
    props<{ entities: EventLocation[] }>()
  ),
  getSuccess: createAction(
    '[EVENT_LOCATION] GET_SUCCESS',
    props<{ entity: EventLocation }>()
  ),
  getFailed: createAction(
    '[EVENT_LOCATION] GET_FAILED',
    props<{ id: string }>()
  ),
  list: createAction(
    '[EVENT_LOCATION] LIST',
    props<{
      query?: QueryList<EventLocation> & {
        onsiteType?: OnsiteRegistrationType;
      };
    }>()
  ),

  listSuccess: createAction(
    '[EVENT_LOCATION] LIST_SUCCESS',
    props<{ entities: EventLocation[] }>()
  ),
  listFailed: createAction('[EVENT_LOCATION] LIST_FAILED'),

  // Admin-specific actions:
  listEventLocationByCompany: createAction(
    '[EVENT_LOCATION] LIST_EVENT_LOCATIONS_BY_COMPANY',
    props<{
      companyId: string;
    }>()
  ),
  listEventLocationByCompanySuccess: createAction(
    '[EVENT_LOCATION] LIST_EVENT_LOCATIONS_BY_COMPANY_SUCCESS',
    props<{
      entities: EventLocation[];
    }>()
  ),
  listEventLocationByCompanyFailed: createAction(
    '[EVENT_LOCATION] LIST_EVENT_LOCATIONS_BY_COMPANY_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),

  getAsAdmin: createAction(
    '[EVENT_LOCATION] GET_AS_ADMIN',
    props<{
      id: string;
    }>()
  ),
  getAsAdminSuccess: createAction(
    '[EVENT_LOCATION] GET_AS_ADMIN_SUCCESS',
    props<{
      entity: EventLocation;
    }>()
  ),
  getAsAdminFailed: createAction(
    '[EVENT_LOCATION] GET_AS_ADMIN_FAILED',
    props<{
      err: CommonResponse;
    }>()
  )
};
