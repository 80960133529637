import { Directive } from '@angular/core';
import { UserNgrxFacade } from './user-ngrx.facade';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[userNgrx]',
  exportAs: 'userNgrx'
})
export class UserNgrxProviderDirective {
  constructor(public store: UserNgrxFacade) {}
}
