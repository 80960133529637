import { createAction, Action, props } from '@ngrx/store';

/**
 * Do not directly call these actions, due to potential
 * issues with serializing errors.
 */
export const errorReportActions = {
  report: createAction(
    '[ERROR_REPORT] REPORT',
    props<{
      /**
       * The original action that caused an error,
       * or a mocked error
       */
      action: Action;
      /**
       * The action error
       */
      err: {
        name: string;
        message: string;
        stack: string;
      };
      /**
       * The message we are to show to the end user, it not given
       * we defaults to the DEFAULT_ERROR_MESSAGE injectable.
       */
      message?: string;
      /**
       * If we are to dispatch a "retry" prompt.
       */
      retry?: boolean;
    }>()
  ),

  reportSuccess: createAction('[ERROR_REPORT] REPORT_SUCCESS'),
  reportFailed: createAction('[ERROR_REPORT] REPORT_FAILED')
};
