import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsMoveUserCompanyDialogData } from './ehs-move-user-company-dialog-data';
import { EhsMoveUserCompanyDialogResponse } from './ehs-move-user-company-dialog-response';
import { EhsMoveUserCompanyDialogComponent } from './ehs-move-user-company-dialog.component';

@Injectable()
export class EhsMoveUserCompanyDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: EhsMoveUserCompanyDialogData
  ): Observable<EhsMoveUserCompanyDialogResponse | undefined> {
    return this.matDialog
      .open(EhsMoveUserCompanyDialogComponent, {
        data,
        maxWidth: '800px'
      })
      .afterClosed();
  }
}
