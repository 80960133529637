export interface GwApiUtilsFormMappings {
  /**
   * The formId of the user-tests form
   */
  userTests: string;
  /**
   * The formId of the user-test-ranges-form
   */
  userTestsRanges: string;
  /**
   * The formId of the user-test-risk-form
   */
  userTestsRisks: string;
  /**
   * The formId of the company form
   */
  companies: string;
  /**
   * The formId of the custom-pricing form
   *
   * **note** No longer directly sync-able, nested under event-services
   */
  customPricing: string;
  /**
   * The formId of the event-service form
   */
  eventService: string;
  /**
   * The formId of the event-location form
   */
  eventLocations: string;
  /**
   * The formId of the event-service-text form
   */
  eventServiceText: string;
  /**
   * The formId of the health programs form
   */
  healthPrograms: string;
  /**
   * The formId of the insurance providers form
   */
  insuranceProviders: string;
  /**
   * Form id for the article links form
   */
  articleLinks: string;
  /**
   * Form id for the articles
   */
  articles: string;
  /**
   * Form id for the articles
   */
  incentives: string;
  /**
   * Form id for the conditional-test
   *
   * **note** No longer directly sync-able, nested under event-services
   */
  conditionalTest: string;
  vaccination: string;
}

const gwappsBaseUrl = 'https://app.gwapps.com/c/empowerhealthservices.com';

export const gwappsAdminEmail = 'dev@empowerhealthservices.com';
export const gwappsCustomerId = 'empow_d646a';
export const gwappsApiUrl = 'https://api.gwapps.com';

/**
 * These are the GW Apps URL Links to the different resources.
 * It is used to link an event, company, event service etc.. to the correct
 * resource in GW Apps.
 */
export const gwappsUrls = {
  company: `${gwappsBaseUrl}/5cdf77e01785700019591c41/render/form/5cdf77e01785700019591c3e/view/`,
  eventService: `${gwappsBaseUrl}/5cdf77e01785700019591c41/render/form/5ce01c4fd602e10012bbad1f/view/`,
  eventLocation: `${gwappsBaseUrl}/5cdf77e01785700019591c41/render/form/5d0011a517e5230011956e27/view/`,
  userTest: `${gwappsBaseUrl}/5cdc29d417857000195919b7/render/form/5ed5117c0d7eab001a9a3455/view/`,
  article: `${gwappsBaseUrl}/5cdc29d417857000195919b7/render/form/5ea6ea85e124ca0019826126/view/`,
  incentive: `${gwappsBaseUrl}/5cdf77e01785700019591c41/render/form/625f0aefcc9d4c0013b7723c/view/`,
  vaccination: `${gwappsBaseUrl}/5cdc29d417857000195919b7/render/form/6007218c7bc2760019f4d76b/view/`,
  healthProgram: `${gwappsBaseUrl}/5cdf77e01785700019591c41/render/form/66b0fb4e794c84a0b6e94f2e/view/`
};

export const gwappsFormIds: GwApiUtilsFormMappings = {
  userTests: '5ed5117c0d7eab001a9a3455',
  userTestsRanges: '5ed66a380d7eab001a9a34be',
  userTestsRisks: '66462ab997c44de061d10c6c',
  companies: '5cdf77e01785700019591c3e',
  eventService: '5ce01c4fd602e10012bbad1f',
  customPricing: '5f933141e25f230013c42d99',
  eventLocations: '5d0011a517e5230011956e27',
  eventServiceText: '5e5807cae8f0ef0013ded0b4',
  insuranceProviders: '5e3337162aa9ec001b8661bc',
  articleLinks: '5fadab28141d660013409f8b',
  articles: '5ea6ea85e124ca0019826126',
  conditionalTest: '5fd134df5bbcf400122f526d',
  incentives: '625f0aefcc9d4c0013b7723c',
  vaccination: '6007218c7bc2760019f4d76b',
  healthPrograms: '66b0fb4e794c84a0b6e94f2e'
};

export enum GwappsAuthKey {
  TEST_AUTH_KEY = 'testKey',
  SERVICE_AUTH_KEY = 'serviceKey'
}

export const gwappsAuthKey: GwApiUtilsFormMappings = {
  userTests: GwappsAuthKey.TEST_AUTH_KEY,
  userTestsRanges: GwappsAuthKey.TEST_AUTH_KEY,
  userTestsRisks: GwappsAuthKey.TEST_AUTH_KEY,
  companies: GwappsAuthKey.SERVICE_AUTH_KEY,
  eventService: GwappsAuthKey.SERVICE_AUTH_KEY,
  customPricing: GwappsAuthKey.SERVICE_AUTH_KEY,
  eventLocations: GwappsAuthKey.SERVICE_AUTH_KEY,
  eventServiceText: GwappsAuthKey.SERVICE_AUTH_KEY,
  insuranceProviders: GwappsAuthKey.TEST_AUTH_KEY,
  articleLinks: GwappsAuthKey.TEST_AUTH_KEY,
  articles: GwappsAuthKey.TEST_AUTH_KEY,
  conditionalTest: GwappsAuthKey.SERVICE_AUTH_KEY,
  incentives: GwappsAuthKey.SERVICE_AUTH_KEY,
  vaccination: GwappsAuthKey.TEST_AUTH_KEY,
  healthPrograms: GwappsAuthKey.SERVICE_AUTH_KEY
};
