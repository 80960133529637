import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { VerifyEmailDialogComponent } from './ehs-verify-email-dialog.component';
import { VerifyEmailDialogData } from './ehs-verify-email-dialog-data';

@Injectable()
export class EhsVerifyEmailDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(data: VerifyEmailDialogData): Observable<boolean> {
    return this.matDialog
      .open(VerifyEmailDialogComponent, {
        data,
        maxWidth: '450px'
      })
      .afterClosed();
  }
}
