export class TextUtil {
  /**
   * Capitalize the first character of every word.
   */
  public static capitalizeWords(str: string) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }
}
