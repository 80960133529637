import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PasswordValidatorModule } from '../../shared/password-validator/password-validator.module';
import { EhsPasswordResetDialogComponent } from './ehs-password-reset-dialog.component';
import { EhsPasswordResetDialogService } from './ehs-password-reset-dialog.service';

@NgModule({
  declarations: [EhsPasswordResetDialogComponent],
  exports: [EhsPasswordResetDialogComponent],
  imports: [
    CommonModule,
    FormsModule,

    // Angular Material
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,

    // Validators
    PasswordValidatorModule
  ],
  providers: [EhsPasswordResetDialogService]
})
export class EhsPasswordResetDialogModule {}
