import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CLIENT_ADMIN_CORE_INJECTION_TOKEN } from '@ehs-ngrx/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EventLocationNgrxProviderDirective } from './event-location-ngrx-provider.directive';
import { EventLocationNgrxEffects } from './event-location-ngrx.effects';
import {
  eventLocationReducer,
  EVENT_LOCATION_STORE_KEY
} from './event-location-ngrx.state';

@NgModule({
  declarations: [EventLocationNgrxProviderDirective],
  imports: [
    CommonModule,
    StoreModule.forFeature(EVENT_LOCATION_STORE_KEY, eventLocationReducer),
    EffectsModule.forFeature([EventLocationNgrxEffects]),

    // Material
    MatSnackBarModule
  ],
  exports: [EventLocationNgrxProviderDirective]
})
export class EventLocationNgrxModule {
  public static forFeature(config?: {
    clientAdmin: boolean;
  }): ModuleWithProviders<EventLocationNgrxModule> {
    return {
      ngModule: EventLocationNgrxModule,
      providers: [
        {
          provide: CLIENT_ADMIN_CORE_INJECTION_TOKEN,
          useValue: config?.clientAdmin
        }
      ]
    };
  }
}
