import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { EhsConfirmDialogModule } from '../../dialogs/ehs-confirmation-dialog';
import { EhsCardLabelModule } from '../ehs-card-label/ehs-card-label.module';
import { EhsCompanySecurityComponent } from './ehs-company-security.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [EhsCompanySecurityComponent],
  imports: [
    CommonModule,
    // Angular Material
    MatButtonModule,
    FlexLayoutModule,
    RouterModule,
    EhsCardLabelModule,
    MatSlideToggleModule,
    EhsConfirmDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [EhsCompanySecurityComponent]
})
export class EhsCompanySecurityModule {}
