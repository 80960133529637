import { ObjectId } from 'mongodb';
import { Company } from './company';
import { DbDocument } from './db-document';
import { EventLocation } from './event-location';
import { ServiceType } from './event-service/service-type';
import { User } from './user';
import { UserResult } from './user-result/user-result';
import { UserResultTestResult } from './user-result/user-result-test-result';
import { UserTest } from './user-test/user-test';
import { UserTestRange } from './user-test/user-test-range';
import { CriticalResultNote } from './critical-result-note';

export enum CriticalResultStatus {
  OPEN = 'open',
  PENDING = 'pending',
  CLOSED = 'closed'
}

/**
 * The type of critical call,
 * this is currently editable, but might be automatically set later
 */
export enum CriticalResultCallType {
  EDUCATIONAL = 'educational',
  CRITICAL = 'critical'
}
/**
 * A critical result is a reference of a user's result, except used
 * for admin only features.
 */
export interface CriticalResult extends DbDocument {
  /**
   * List of notes admins enter
   */
  notes: Array<CriticalResultNote>;
  /**
   * The company this result belongs to
   */
  company: string | ObjectId | Company;
  /**
   * The event-location, if the user-result was onsite
   */
  eventLocation?: string | ObjectId | EventLocation;
  /**
   * The date of the collection.
   */
  collectionDate: Date;
  /**
   * The date the result was received by the lab
   */
  receivedDate: Date;
  /**
   * The corresponding user-result
   */
  userResult: string | ObjectId | UserResult;
  /**
   * The corresponding test-result data that was found to be out of range.
   * Saved as-is.
   */
  userResultTestResult: UserResultTestResult;
  /**
   * The user test range that was found to be out of range
   * saved as-is
   */
  userTestRange: UserTestRange;
  /**
   * The corresponding user-test data that was found to be out of range.
   */
  userTest: string | ObjectId | UserTest;
  /**
   * The type of the user-registration for the given event.
   */
  serviceType: ServiceType;
  /**
   * The user taken from the user result.
   */
  user: string | ObjectId | User;
  /**
   * The status of the call for this record.
   */
  status: CriticalResultStatus;
  /**
   * The type of call
   */
  callType: CriticalResultCallType;
  /**
   * Was the user aware of the risk?
   */
  awareOfRisk?: boolean;
}
