import { Directive } from '@angular/core';
import { AdminGwNgrxFacade } from './admin-gw-ngrx.facade';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[adminGwNgrx]',
  exportAs: 'adminGwNgrx'
})
export class CompanyNgrxProviderDirective {
  constructor(public store: AdminGwNgrxFacade) {}
}
