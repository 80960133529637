import { ObjectId } from 'mongodb';
import { States } from '../constants/states';
import { PartialUser } from './partial-user';

export enum RelationToInsured {
  SELF = 'self',
  SPOUSE = 'spouse',
  CHILD = 'child',
  OTHER = 'other'
}

export const getRelationshipToInsuredName = (relationship: RelationToInsured) =>
  ({
    [RelationToInsured.SELF]: 'Self',
    [RelationToInsured.SPOUSE]: 'Spouse',
    [RelationToInsured.CHILD]: 'Child',
    [RelationToInsured.OTHER]: 'Other'
  }[relationship] || '');

export enum PatientStatus {
  SINGLE = 'single',
  MARRIED = 'married',
  OTHER = 'other'
}

export const getPatientStatusName = (patientStatus: PatientStatus) =>
  ({
    [PatientStatus.SINGLE]: 'Single',
    [PatientStatus.MARRIED]: 'Married',
    [PatientStatus.OTHER]: 'Other'
  }[patientStatus] || '');

export enum EmploymentStatus {
  EMPLOYED = 'employed',
  FULL_TIME_STUDENT = 'full_time_student',
  PART_TIME_STUDENT = 'part_time_student'
}

export const getEmploymentStatusName = (employmentStatus: EmploymentStatus) =>
  ({
    [EmploymentStatus.EMPLOYED]: 'Employed',
    [EmploymentStatus.FULL_TIME_STUDENT]: 'Full-Time Student',
    [EmploymentStatus.PART_TIME_STUDENT]: 'Part-Time Student'
  }[employmentStatus] || '');

export enum ClaimPlanType {
  MEDICARE = 'medicare',
  MEDICAID = 'medicaid',
  TRICARE_CHAMPUS = 'tricare_champus',
  CHAMPVA = 'champva',
  GROUP_HEALTH_PLAN = 'group_health_plan',
  FECA_BLK_LUNG = 'feca_blk_lung',
  OTHER = 'other'
}

export const getClaimTypeName = (planType: ClaimPlanType) =>
  ({
    [ClaimPlanType.MEDICARE]: 'Medicare',
    [ClaimPlanType.MEDICAID]: 'Medicaid',
    [ClaimPlanType.TRICARE_CHAMPUS]: 'Tricare Champus',
    [ClaimPlanType.CHAMPVA]: 'Champva',
    [ClaimPlanType.GROUP_HEALTH_PLAN]: 'Group Health Plan',
    [ClaimPlanType.FECA_BLK_LUNG]: 'Feca BLK LUNG',
    [ClaimPlanType.OTHER]: 'Other'
  }[planType] || '');

export enum TaxIdType {
  SSN = 'ssn',
  // Employer Identification Number
  EIN = 'ein'
}

export interface ClaimService {
  // 24.A. Date of Service From
  dateFrom: string | Date;
  // 24.A. Date of Service To
  dateTo: string | Date;
  // 24.B. Place of Service
  placeOfService: string;
  // 24.D. CPT / HCPCS
  cptHcpcs: string;
  // 24.E. Diagnosis Pointer
  diagnosisPointer: string;
  // 24.F. Charges
  charges: number;
  // 24.G. Days of Units
  daysOrUnits: number;
  // 24.J. Rendering Provider ID Number
  providerId: string;
}

/**
 * The properties saved for a given insurance claim
 */
export interface InsuranceClaim {
  // 1. Claim Type
  planType: ClaimPlanType;
  // 6. Relation to Insured
  relationToInsured: RelationToInsured;
  // 4 & 7. This only exists if the relationToInsured is Spouse
  insuredInfo?: {
    // 4. Insured's Name (first name, last name, mi)
    firstName: string;
    lastName: string;
    middleInitial: string;
    // 7. Insured's address
    address1: string;
    address2: string;
    city: string;
    state: States;
    zip: string;
    phone: string;
  };
  // 8. Patient Status
  patientStatus: PatientStatus;
  // 8. Employment Status
  employmentStatus: EmploymentStatus;
  // 10.a. Employment Yes / No
  employment: boolean;
  // 10.b. Auto Accident
  autoAccident: boolean;
  // 10.c. Other Accident
  otherAccident: boolean;
  // 1.a. Insured ID Number
  insuranceId: string;
  // 9.c. This could be employer name or school name
  employerName: string;
  // 21. Diagnosis or Nature of illness or injury
  // e.g. Z00000
  diagnosis: string;
  // 24. Services
  services: ClaimService[];
  // 25. Type of identification used
  idType?: TaxIdType;
  // 25. Federal Tax ID Number e.g. 33-1231234
  // ID is either EIN or SSN
  idNumber?: string;
  // 26. Patient Account Number
  patientAccount: string;
  // 27. Accept Assignment
  assignment?: boolean;
  // 28. Total Charge
  totalCharge: number;
  // 29. Amount Paid
  amountPaid: number;
  // 30. Balance Due
  balanceDue: number;
  user: string | ObjectId | PartialUser;
}
