import { LabResultCode } from '../models/user-test/user-test';
import { FingerStickCodes } from './finger-stick-tests';

/**
 * These are the Lab Result Codes for Blood Pressure Diastolic & Systolic
 * Those are static values to get the value of the Diastolic & Systolic
 * from the userResult.tests
 */
export const EHS_BP_SYS_RESULT_CODE = LabResultCode('4208');
export const EHS_BP_DIA_RESULT_CODE = LabResultCode('4209');
export const QUEST_BP_SYS_RESULT_CODE = LabResultCode('97014704');
export const QUEST_BP_DIA_RESULT_CODE = LabResultCode('97014705');
export const LABCORP_BP_DIA_RESULT_CODE = LabResultCode('101145');
export const LABCORP_BP_SYS_RESULT_CODE = LabResultCode('101144');

/**
 * Get the list of all result codes that return the user blood pressure diastolic
 */
export const bpDiasResultCodes = [
  FingerStickCodes.BLOOD_PRESSURE_DIASTOLIC,
  QUEST_BP_DIA_RESULT_CODE,
  LABCORP_BP_DIA_RESULT_CODE
];
/**
 * Get the list of all result codes that return the user blood pressure systolic
 */
export const bpSysResultCodes = [
  FingerStickCodes.BLOOD_PRESSURE_SYSTOLIC,
  QUEST_BP_SYS_RESULT_CODE,
  LABCORP_BP_SYS_RESULT_CODE
];
