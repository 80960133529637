<mat-accordion style="width: 100%"
               multi>
  <ng-container *ngFor="let userRegistration of userRegistrations">
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div *ngIf="userRegistration.serviceType === 'onsite'">Onsite - Event
            Date</div>
          <div *ngIf="userRegistration.serviceType === 'offsite'">Offsite -
            Screening Deadline</div>
          <div *ngIf="userRegistration.serviceType === 'providerHealth'">HPF -
            Return By</div>
        </mat-panel-title>
        <mat-panel-description fxLayoutAlign="center">
          <span>
            {{getServiceDate(userRegistration) | date:'mediumDate'}}
          </span>
          <span style="padding-left:4px">
            {{getEventTime(userRegistration)}}
          </span>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div gdColumns="4fr 4fr 4fr 1fr"
           gdColumns.sm="3fr 3fr 3fr 1fr"
           gdColumns.xs="2fr 2fr 2fr 1fr"
           gdGap="8px">

        <ehs-card-label label="Service Type"
                        [value]="(userRegistration.serviceType | titlecase) || 'No Data'">
        </ehs-card-label>

        <ehs-card-label label="Group Type"
                        [value]="(getEventServiceGroupType(userRegistration)?.name | titlecase) || 'No Data'">
        </ehs-card-label>

        <ehs-card-label label="Person Type"
                        [value]="(userRegistration.personType | titlecase )|| 'No Data'">
        </ehs-card-label>

        <ng-container matColumnDef="actions">
          <button mat-icon-button
                  type="button"
                  aria-label="Menu"
                  [matMenuTriggerFor]="registrationActionMenu"
                  [matMenuTriggerData]="{userRegistration}">
            <mat-icon>more_vert</mat-icon>
          </button>
        </ng-container>

        <ehs-card-label label="Height"
                        [value]="(userRegistration.height | displayHeight)">
        </ehs-card-label>

        <ehs-card-label label="Weight"
                        [value]="(userRegistration?.weight | displayWeight)">
        </ehs-card-label>

        <ehs-card-label label="Tobacco Use"
                        [value]="(userRegistration?.usesTobacco | yesNo)">
        </ehs-card-label>

        <ehs-card-label label="BloodPressure"
                        [value]="(userRegistration.bloodPressure | displayBloodPressure)">
        </ehs-card-label>

        <mat-menu #registrationActionMenu="matMenu">
          <ng-template matMenuContent
                       let-userRegistration="userRegistration">
            <ng-container
                          *ngFor="let action of getUserRegistrationSpecificActions(userRegistration); trackBy: trackByFn">
              <ng-container
                            *ngIf="action.type === 'viewEventService' else otherActions">
                <a mat-menu-item
                   title="View Event Service"
                   [routerLink]="getViewLink(userRegistration)">
                  <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
                  View Event Service
                </a>
              </ng-container>
              <ng-template #otherActions>
                <ng-container
                              *ngIf="action.type !== 'reschedule' || (action.type === 'reschedule' && isOnsiteUserRegistration(userRegistration))">
                  <button mat-menu-item
                          (click)="actionChange.emit({
                                actionType: $any(action.type), userRegistration})"
                          [title]="action.name"
                          type="button">
                    <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
                    {{action.name}}
                  </button>
                </ng-container>
              </ng-template>
            </ng-container>
          </ng-template>
        </mat-menu>

      </div>
      <ng-container *ngIf="getOptionalTests(userRegistration).length">


        <ehs-card-separator style="width: 100%"
                            label="Optional Tests">

        </ehs-card-separator>
        <ul *ngFor="let test of getOptionalTests(userRegistration)">
          &#8226; <li gdColumns="1fr"
              gdColumns.sm="1fr"
              gdColumns.xs="1fr"
              gdColumn="span 3"
              gdGap="8px"
              fxFlex>
            {{test?.name | titlecase}}
          </li>
        </ul>
      </ng-container>

    </mat-expansion-panel>
  </ng-container>
</mat-accordion>
