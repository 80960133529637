<div fxLayout="row"
     fxLayoutAlign="space-between center"
     *ngIf="eventService">

  <!-- left element -->
  <div fxLayout="row"
       fxLayoutAlign="space-between center"
       fxLayoutGap="8px"
       fxFlex="100">
    <a mat-button
       title="Redirects to Offsite Participant List"
       [routerLink]="eventServiceUrl"
       color="primary"
       style="padding-left: 0; text-decoration: underline;">
      {{eventService.serviceId}} - {{eventService.name}}
    </a>
  </div>
</div>

<div gdColumns="1fr 1fr 1fr"
     gdColumns.sm="1fr 1fr"
     gdColumns.xs="1fr"
     gdGap="8px">

  <ehs-card-label label="Active On"
                  [valueStyles]="{color: !offsiteService?.registrationActive ? 'red' : ''}"
                  [value]="(offsiteService?.registrationActive | date:'mediumDate') || 'Not Set'">
  </ehs-card-label>

  <ehs-card-label label="Registration Deadline"
                  [valueStyles]="{color: !offsiteService?.registrationDeadline ? 'red' : ''}"
                  [value]="(offsiteService?.registrationDeadline | date:'mediumDate') || 'Not Set'">
  </ehs-card-label>

  <ehs-card-label label="Screening Deadline"
                  [valueStyles]="{color: !offsiteService?.screeningDeadline ? 'red' : ''}"
                  [value]="(offsiteService?.screeningDeadline | date: 'fullDate') || 'Not Set'">
  </ehs-card-label>

  <ehs-card-label label="Members Registered"
                  [internalLink]="eventServiceUrl"
                  [value]="eventServiceStats.offsite?.userRegistrations || 0">
  </ehs-card-label>

  <ehs-card-label label="Members Results"
                  [value]="eventServiceStats.offsite?.userResults || 0">
  </ehs-card-label>

  <ehs-card-label label="Lab"
                  [valueStyles]="{color: !offsiteService?.labType ? 'red' : ''}"
                  [value]="(offsiteService?.labType || 'Not Set') | titlecase ">
  </ehs-card-label>

</div>
