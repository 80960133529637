import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AuthFacade } from './app-store/auth/auth.facade';

/**
 * App-init factory we used to get if the user is already authenticated
 * with the app and has a session.
 *
 * If the user DOES have a session,
 * then it will automatically be added to the state.
 *
 * If the user does not have a session, we will get a 401 error or similar
 * and redirect the user to the login page.
 */
export function appInitFactory(authFacade: AuthFacade) {
  return () => {
    if (window.location.pathname.startsWith('/ehs/admin')) {
      // The user is going to the "admin", then let them thru,
      // the admin guard/modules on that route will handle loading the data
      return;
    }
    authFacade.getUser();

    return combineLatest([authFacade.user$, authFacade.loading$])
      .pipe(
        filter(([, loading]) => !loading),
        take(1)
      )
      .toPromise();
  };
}
