<div class="ehs-user-table ehs-table-wrapper"
     *ngIf="users?.length">
  <div>
    <mat-progress-bar mode="indeterminate"
                      *ngIf="loading"></mat-progress-bar>
  </div>
  <cdk-virtual-scroll-viewport itemSize="40"
                               minBufferPx="40"
                               maxBufferPx="500"
                               (scrolledIndexChange)="onScrolledIndexChange($event)"
                               class="viewport">
    <table class="ehs-table sticky-header sticky-first">
      <tr>
        <th style.width="100%">
          <div fxLayout="row"
               fxLayoutAlign="end">
            <mat-spinner *ngIf="userActions?.length && actionLoading"
                         [diameter]="22"></mat-spinner>
          </div>
        </th>
        <th *ngFor="let col of columns; let i = index"
            [style.top]="headerTop"
            [style.width]="col.width"
            [style.min-width]="col.width"
            [ngClass]="col.prop">
          {{ col.columnTitle }}
        </th>

      </tr>
      <tr *cdkVirtualFor="let user of users">
        <td *ngIf="userActions?.length"
            [style.width]="'40px'"
            [style.min-width]="'40px'">
          <button mat-icon-button
                  class="small-icon-button"
                  type="button"
                  aria-label="Menu"
                  class="small-icon-button"
                  [disabled]="actionLoading"
                  [matMenuTriggerFor]="userActionMenu"
                  [matMenuTriggerData]="{ user: user }"
                  (click)="$event.stopPropagation(); $event.preventDefault()">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
        <td *ngFor="let col of columns; let i = index"
            [ngClass]="col.prop"
            [style.width]="i === columns.length - 1 ? '100%' : col.width"
            [style.min-width]="i === columns.length - 1 ? '100%' : col.width">
          <ng-container [ngSwitch]="col.prop">

            <ng-container *ngSwitchCase="'user'">
              <div fxLayout="row"
                   fxLayoutAlign="start center"
                   fxLayoutGap="4px">

                <span>{{user.firstName }} {{
                  user.lastName }}</span>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'eventDate'">
              {{getUserRegistration(user)?.eventDate || ''}}
            </ng-container>
            <ng-container *ngSwitchCase="'eventLocation'">
              {{getUserRegistrationEventLocation(user)|| ''}}
            </ng-container>
            <ng-container *ngSwitchCase="'eventTime'">
              {{getUserRegistration(user)?.eventTime || '' | ampm}}
            </ng-container>
            <ng-container *ngSwitchCase="'serviceType'">
              {{getRegistrationType(user) || ''}}
            </ng-container>

            <ng-container *ngSwitchDefault>
              {{ user[col.prop] }}
            </ng-container>

          </ng-container>
        </td>
      </tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>

<mat-menu #userActionMenu="matMenu">
  <ng-template matMenuContent
               let-user="user">
    <ng-container *ngFor="let action of getUserSpecificActions(user); trackBy: trackByFn">
      <button mat-menu-item
              (click)="actionChange.emit({ type: action.type, user })"
              [title]="action.name"
              type="button">
        <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
        {{action.name}}
      </button>

    </ng-container>
  </ng-template>
</mat-menu>
