import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output
} from '@angular/core';
import { Company, getId, LocationDataUtil } from '@common';
import { EHS_COMPANY_LOGO_BASE_URL } from '../../shared/ehs-company-logo-base-url';

@Component({
  selector: 'ehs-gwapps-company',
  templateUrl: './ehs-gwapps-company.component.html',
  styleUrls: ['./ehs-gwapps-company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsGwappsCompanyComponent {
  /**
   * The base URL.
   *
   * **note** don't use, use the injectable.
   *
   * @deprecated
   *
   * example:
   * ```
   * https://storage.googleapis.com/ct-ehs-customer-logos/
   * ```
   */
  @Input() baseLogoUrl?: string;
  /**
   * The relevant company we are display data for
   */
  @Input() company?: Company;
  /**
   * If simple, we only show the name and logo.
   */
  @Input() simple?: boolean;
  /**
   * Event emitted when the user clicks on the view button
   */
  @Output() view = new EventEmitter<Company>();
  /**
   * Event emitted when the user selects the validate button
   */
  @Output() validate = new EventEmitter<Company>();

  public get logoUrl(): string {
    const normalize = (baseUrl: string) => {
      if (baseUrl && typeof baseUrl === 'string') {
        if (baseUrl.endsWith('/')) {
          return baseUrl;
        }

        return baseUrl + '/';
      }

      return '';
    };

    if (this.baseLogoUrl && this.company?.logoUrl) {
      return normalize(this.baseLogoUrl) + this.company.logoUrl;
    }

    if (this.baseLogoUrlInject && this.company?.logoUrl) {
      return normalize(this.baseLogoUrlInject) + this.company.logoUrl;
    }

    return '';
  }

  public get serviceUrl(): string {
    return `/ehs/admin/company/${getId(this.company)}/service`;
  }

  public get userUrl(): string {
    return `/ehs/admin/company/${getId(this.company)}/user`;
  }

  public get location(): string {
    return (
      this.company &&
      LocationDataUtil.getDisplayLocation({ locationData: this.company })
    );
  }

  constructor(
    @Inject(EHS_COMPANY_LOGO_BASE_URL)
    @Optional()
    private baseLogoUrlInject?: string
  ) {}
}
