<div>
  <mat-chip-listbox [value]="auditType"
                    hideSingleSelectionIndicator
                    defaultTabIndex="0"
                    (change)="onTypeChange($event)"
                    aria-label="Admin Audit Type">
    <mat-chip-option value="all">All</mat-chip-option>
    <mat-chip-option value="login">Login</mat-chip-option>
    <mat-chip-option value="login_as_user">Login as User</mat-chip-option>
    <mat-chip-option value="password_reset">Password Reset</mat-chip-option>
    <mat-chip-option value="user_management">User Management</mat-chip-option>
    <mat-chip-option value="results">Results</mat-chip-option>
  </mat-chip-listbox>
  <ng-container *ngIf="!adminAudits?.length && !loading; else showTable">
    <p>No matching audits</p>
  </ng-container>
  <ng-template #showTable>
    <!-- Load Spinner-->
    <div *ngIf="loading else showAudits"
         class="center-spinner"
         [style.margin]="'75px'">
      <mat-spinner class="circle-center"
                   diameter="50"></mat-spinner>
    </div>
    <ng-template #showAudits>
      <table mat-table
             [dataSource]="adminAudits"
             style="width: 100%">
        <ng-container matColumnDef="createdAt">
          <div>
            <th mat-header-cell
                class="audit-header-cell"
                [ngStyle]="{'padding-left': '0px;'}"
                *matHeaderCellDef> Audit Time </th>
            <td mat-cell
                class="audit-cell"
                [ngStyle]="{'padding-left': '0px;'}"
                *matCellDef="let adminAudit">
              <span>
                {{adminAudit.createdAt | date:'medium'}}
              </span>
            </td>
          </div>
        </ng-container>

        <ng-container matColumnDef="type">
          <div>
            <th mat-header-cell
                class="audit-header-cell"
                *matHeaderCellDef> Type </th>
            <td mat-cell
                class="audit-cell"
                *matCellDef="let adminAudit">
              {{ adminAudit.action.replaceAll('_', ' ') | titlecase}}
            </td>
          </div>
        </ng-container>

        <ng-container matColumnDef="actions">
          <div>
            <th mat-header-cell
                class="audit-header-cell"
                *matHeaderCellDef>Actions</th>
            <td mat-cell
                class="audit-cell"
                [ngStyle]="{'padding-right': '1px;'}"
                *matCellDef="let adminAudit">

              <button mat-icon-button
                      type="button"
                      aria-label="Menu"
                      [matMenuTriggerFor]="adminActionMenu"
                      [matMenuTriggerFor]="adminActionMenu"
                      [matMenuTriggerData]="{ adminAudit: adminAudit }">
                <mat-icon>
                  more_vert
                </mat-icon>
              </button>

              <mat-menu #adminActionMenu="matMenu">
                <ng-template matMenuContent
                             let-adminAudit="adminAudit">
                  <ng-container *ngFor="let action of getAdminAuditSpecificActions(adminAudit); trackBy: trackByFn">
                    <button mat-menu-item
                            (click)="actionChange.emit({ actionType: $any(action.type), adminAudit})"
                            [title]="action.name"
                            type="button">
                      <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
                      {{action.name}}
                    </button>
                  </ng-container>
                </ng-template>
              </mat-menu>
            </td>
          </div>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let adminAudit; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row"
           [fxLayoutAlign]="currentPageNumber !== 1 ? 'space-between center' : 'end center'">
        <button *ngIf="currentPageNumber !== 1"
                (click)="changePageNumber(currentPageNumber - 1)"
                mat-button>
          < Previous
            </button>
            <button *ngIf="hasNext || (pageNumber-currentPageNumber) !== 0"
                    mat-button
                    (click)="onNext()">Next ></button>
      </div>
    </ng-template>

  </ng-template>
</div>