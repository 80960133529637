import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { USER_AUDIT_TYPES, UserAudit } from '@common';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AdminUserAuditHttpService {
  private baseUrl = 'api/v1/admin/user-audits';

  constructor(private http: HttpClient) {}

  public list(params: {
    userId: string;
    type?: USER_AUDIT_TYPES;
    pageNumber: number;
    maxResults?: number;
  }): Observable<{ userAudits: UserAudit[]; hasNext: boolean }> {
    const { userId, type, pageNumber, maxResults } = params;
    let httpParams = new HttpParams()
      .append('user', userId)
      .append('pageNumber', pageNumber);

    if (type) {
      httpParams = httpParams.append('type', type);
    }

    if (maxResults) {
      httpParams = httpParams.append('maxResults', maxResults);
    }

    return this.http.get<{
      userAudits: UserAudit[];
      hasNext: boolean;
    }>(`${this.baseUrl}/list`, {
      params: httpParams
    });
  }
}
