import { ObjectId } from 'mongodb';
import {
  ONSITE_BIO_FORM_USER_CRITICAL_IGNORE_CODES,
  ONSITE_FS_FORM_FIELDS
} from '.';
import { OnsiteUserRegistration } from '../../models/user-registration/onsite-user-registration';
import {
  OnsiteBioForm,
  OnsiteBioFormDeclinableField,
  ONSITE_BIO_FORM_CODE_MAP,
  ONSITE_BIO_FORM_DB_FIELDS,
  ONSITE_BIO_FORM_REQUIRED_FIELDS
} from './onsite-bio-form';
import {
  OnsiteFsForm,
  OnsiteFsFormNotReportableField,
  ONSITE_FS_FORM_CODE_MAP
} from './onsite-fs-form';

/**
 * This is the "top-level" generalized type for the form.
 * It can be used for "general" use, but will be assumed to be
 * "partially" filled out at any time.
 *
 * To get specific "parts" of the form out of this type use the type specific
 * type-guards. These will not only provide TS a way to understand
 * what is within this type, but also a way for JS to do what is traditionally
 * called "duck-type" checking. This will create nearly bulletproof type checking,
 * and thus make testing and coding easier.
 *
 * TODO: adding more form types.
 */
export type OnsiteResultForm = OnsiteBioForm & OnsiteFsForm;

/**
 * The list of declinable fields. Currently includes **just** the onsite-bio-form
 * fields and if the admin declined fingerstick as a whole.
 */
export type OnsiteResultFormDeclinableField =
  | OnsiteBioFormDeclinableField
  | 'fingerstick';

/**
 * The list of not-reportable fields. Currently includes **just** the onsite-fs-form
 * fields.
 *
 * This could be done "cleaner" and at the fingerstick only level, but it might
 * get messy.
 */
export type OnsiteResultFormNotReportableField = OnsiteFsFormNotReportableField;

/**
 * This is the "combined" data-type that is sent to the back-end to create
 * or update a user-result. This currently includes all possible properties
 * that are included.
 */
export interface OnsiteResultFormRequest extends OnsiteResultForm {
  /**
   * The user-registration this result will go to.
   */
  userRegistration: string | ObjectId | OnsiteUserRegistration;

  /**
   * The list of declined fields, or if fingerstick is declined as-is.
   * This is included if bio or fingerstick is active.
   */
  declined?: OnsiteResultFormDeclinableField[];

  /**
   * The list of not-reportable fields, which are tied to fingerstick fields,
   * and only relevant for fingerstick forms.
   */
  notReportable?: OnsiteResultFormNotReportableField[];
  // TODO: what about timestamps?
}

/**
 * The "hard-value" for fields that need to be double blind entered.
 */
export const ONSITE_RESULT_FORM_DB_FIELDS = [
  ...ONSITE_BIO_FORM_DB_FIELDS,
  ...ONSITE_FS_FORM_FIELDS
] as const;

/**
 * The type that represents the fields that are double-blind.
 *
 * These are the fields that can be removed during double-blind.
 */
export type OnsiteResultFormDbField =
  typeof ONSITE_RESULT_FORM_DB_FIELDS[number];

/**
 * The map of the the result-form fields to their lab-result-codes.
 *
 * This is used to generate the list of services available in the form.
 * @see getAvailableOnsiteResultFormFields
 */
export const ONSITE_RESULT_FORM_CODE_MAP = {
  ...ONSITE_BIO_FORM_CODE_MAP,
  ...ONSITE_FS_FORM_CODE_MAP
};

/**
 * The list of onsite-result-form-fields that have DB tests. This type should
 * be used in conjunction with `getAvailableOnsiteResultFormFields`
 */
export type OnsiteResultFormFieldWithTest =
  keyof typeof ONSITE_RESULT_FORM_CODE_MAP;

/**
 * The list of fields that can be "required" conditionally.
 * This is **very** similar to the onsite-result-form-code-map, except
 * it includes fields that don't necessarily have a test, such as tobacco, and
 * blood-pressure is considered 1 field (like height)
 *
 * **note** the type def below is not the cleanest but will work.
 *
 */
export const ONSITE_RESULT_FORM_REQUIRED_FIELDS = [
  ...ONSITE_BIO_FORM_REQUIRED_FIELDS,
  ...ONSITE_FS_FORM_FIELDS
] as const;

/**
 * Type representing if a given field is required conditionally.
 */
export type OnsiteResultFormRequiredField =
  typeof ONSITE_RESULT_FORM_REQUIRED_FIELDS[number];

/**
 * List of lab-result-codes we are to ignore for creating user-criticals.
 *
 * **note** this has the same value as the `CRITICAL_RESULT_IGNORE_CODES` constant
 */
export const ONSITE_RESULT_FORM_USER_CRITICAL_IGNORE_CODES = [
  ...ONSITE_BIO_FORM_USER_CRITICAL_IGNORE_CODES
] as const;
