import {
  HraAnswer,
  HraAnswerColors,
  HraAnswerMap
} from '../models/hra/hra-answer';
import {
  HraCategory,
  hraCategoryNames,
  HraCategoryTypes
} from '../models/hra/hra-category';
import { HraForm } from '../models/hra/hra-form';
import { HraQuestion, HraQuestionType } from '../models/hra/hra-question';
import { HraResult } from '../models/hra/hra-result';
import {
  HraScoreCategory,
  HraScoreCategoryColors,
  HraScoreCategoryTypes
} from '../models/hra/hra-score-category';
import { HraScores } from '../models/hra/hra-scores';
import { UserResult } from '../models/user-result/user-result';
import { UserResultTestResult } from '../models/user-result/user-result-test-result';
import { LabResultCode, UserTest } from '../models/user-test/user-test';

/**
 * Represents the data that can be passed to some
 * hra-utilities to "apply" the user-result calculations
 * on-top of the hra calculations
 */
export interface UserResultHraInfo {
  flatUserTestMap: Record<
    LabResultCode,
    { userTest: UserTest; userResultTestResult: UserResultTestResult }
  >;
  userResult: UserResult;
}
/**
 * Static utility class that provides utility functions to manage
 * the hra form and hra-record
 */
export class HraUtil {
  /**
   * Returns the list of headers that are to be
   * placed in the first row of the sheet export.
   *
   * This includes all questions defined, and extra headers for
   * other properties defined on the hra-result itself.
   */
  public static getHraExportHeaders(params: {
    form: HraForm;
    /** List of questions to exclude */
    exclude?: string[];
    /**
     * List of questions with checkboxes that will need to be split
     * into spreadsheet columns one column for each checkbox answer
     */
    splitCols?: string[];
  }): {
    /**
     * All the question titles defined within the hra-def.
     * These are the questions AS IS, and should match 1 to 1 with the
     * question properties
     */
    questionHeaders: string[];
    /**
     * The list of properties to iterate over
     * for a given hra-result. These should match
     * with the question properties
     */
    questionProperties: Array<keyof HraResult>;
  } {
    const { form, exclude, splitCols } = params;

    // **Note** if we are to limit which fields are exported, add a filter here
    const { questionHeaders, questionProperties } = form.questions.reduce(
      (acc, { code, description, answers }) => {
        if ((exclude || []).includes(code)) {
          return acc;
        }

        if ((splitCols || []).includes(code)) {
          let prefix = '';

          // Append to the column header Dx or Rx based on the question
          if (['25', '26'].includes(code)) {
            prefix = code === '25' ? 'Dx' : 'Rx';
          }

          (answers || []).forEach((answer) => {
            acc.questionHeaders.push(prefix + answer.description);
            // Push the question code and the answer code
            acc.questionProperties.push(code + '|' + answer.code);
          });

          return acc;
        }
        acc.questionHeaders.push(description);
        acc.questionProperties.push(code);

        return acc;
      },
      {
        questionHeaders: [],
        questionProperties: []
      } as {
        questionHeaders: string[];
        questionProperties: Array<keyof HraResult>;
      }
    );

    return {
      questionHeaders,
      questionProperties
    };
  }

  /**
   * Returns the score category color for the given
   * score category
   */
  public static getScoreCategoryColors(params: {
    /**
     * The calculated hra-score results, values should be in
     * percentages/decimals IE .5 for 50%
     */
    results: HraScores;
    form: HraForm;
  }): Record<HraScoreCategoryTypes, HraScoreCategoryColors> {
    const { results, form } = params;
    const scoreCategoryMap = this.getScoreCategoryMap(form);

    if (!results) {
      return {} as Record<HraScoreCategoryTypes, HraScoreCategoryColors>;
    }

    const getColor = ({
      scoreCategoryType,
      score
    }: {
      scoreCategoryType: HraScoreCategoryTypes;
      score: number;
    }): HraScoreCategoryColors => {
      const scoreCategory = scoreCategoryMap[
        scoreCategoryType
      ] as HraScoreCategory;

      if (!scoreCategory || !score) {
        return HraScoreCategoryColors.LOW;
      }

      if (score >= scoreCategory.goodScore.low) {
        // We don't consider the high here
        return HraScoreCategoryColors.HIGH;
      }

      if (
        scoreCategory.mediumScore.high >= score &&
        score >= scoreCategory.mediumScore.low
      ) {
        return HraScoreCategoryColors.MEDIUM;
      }

      if (scoreCategory.lowScore.high >= score) {
        // **Note** we don't care about the low end score
        return HraScoreCategoryColors.LOW;
      }

      // Otherwise just return low, as its out of the range, or negative;
      return HraScoreCategoryColors.LOW;
    };

    return Object.entries(results).reduce(
      (acc, [scoreCategoryType, score]: [HraScoreCategoryTypes, number]) => ({
        ...acc,
        [scoreCategoryType]: getColor({ scoreCategoryType, score })
      }),
      {} as Record<HraScoreCategoryTypes, HraScoreCategoryColors>
    );
  }

  /**
   * Utility function that returns a map where the scoreCategory is the key
   * and all questions that belong in that score category is the value.
   */
  public static getScoreCategoryQuestionsMap(params: {
    form: HraForm;
  }): { [key in HraScoreCategoryTypes]?: HraQuestion[] } {
    const { form } = params;

    if (!form || !form.questions) {
      return {};
    }

    return form.questions.reduce(
      (scoreMap: { [scoreCategory: string]: HraQuestion[] }, question) =>
        Array.isArray(question.scoreCategory)
          ? {
              ...scoreMap,
              ...question.scoreCategory.reduce(
                (acc, scoreCategory) => ({
                  ...acc,
                  [scoreCategory]: scoreMap[scoreCategory]
                    ? [...scoreMap[scoreCategory], question]
                    : [question]
                }),
                scoreMap
              )
            }
          : {
              ...scoreMap,
              [question.scoreCategory]: scoreMap[question.scoreCategory]
                ? [...scoreMap[question.scoreCategory], question]
                : [question]
            },
      {}
    );
  }

  /**
   * Utility function that returns the hra-questions
   * on the given score category within the form.
   */
  public static getQuestions(params: {
    category: HraCategoryTypes;
    form: HraForm;
  }): HraQuestion[] {
    const { category, form } = params;

    if (!form || !form.questions) {
      return [];
    }

    return form.questions.filter((question) => question.category === category);
  }

  /**
   * Utility function that returns the hra-questions
   * on the given category within the form via a map
   * where the key is the questionCode, and value is the corresponding question.
   */
  public static getQuestionsMap(params: {
    category: HraCategoryTypes;
    form: HraForm;
  }): { [key: string]: HraQuestion } {
    const { category, form } = params;

    if (!form || !form.questions) {
      return {};
    }

    return form.questions
      .filter((question) => question.category === category)
      .reduce((acc, question) => ({ ...acc, [question.code]: question }), {});
  }

  /**
   * Returns the first category, which should be the category
   * without a previous category type.
   */
  public static getFirstCategory(params: {
    form: HraForm;
  }): HraCategoryTypes | undefined {
    const { form } = params;

    if (!form) {
      return undefined;
    }
    const { categories } = form;

    if (!categories) {
      return undefined;
    }
    const startCategory = categories.find(({ previous }) => !previous);

    return startCategory.type;
  }

  /**
   * Returns the category from the category type
   */
  public static getCurrentCategory(params: {
    form: HraForm;
    category: HraCategoryTypes;
  }): HraCategory | undefined {
    const { form, category } = params;

    if (!form || !category) {
      return undefined;
    }
    const { categories } = form;

    if (!categories) {
      return undefined;
    }

    const current = categories.find(
      (formCategory) => formCategory.type === category
    );

    if (!current) {
      return undefined;
    }

    return current;
  }

  /**
   * Returns a map of all the questions that are shown/hidden.
   */
  public static getQuestionsShown(params: {
    questions: HraQuestion[];
    record: HraResult;
  }): { [key: string]: boolean } {
    const { questions } = params;

    return (questions || []).reduce(
      (acc, question) => ({
        ...acc,
        [question.code]: this.isQuestionShown({
          ...params,
          question
        })
      }),
      {}
    );
  }

  /**
   * Utility function that returns if the given
   * field is to be shown or not.
   */
  public static isQuestionShown(params: {
    record: HraResult;
    question: HraQuestion;
  }): boolean {
    const { question } = params;

    if (!question) {
      return false;
    }

    if (!question.show || typeof question.show !== 'function') {
      // If no show property function is provided on the question
      // this field is always shown
      return true;
    }

    return question.show(params);
  }

  /**
   * Returns readable version of the category
   */
  public static getCategoryName(category: HraCategoryTypes): string {
    return hraCategoryNames[category];
  }

  /**
   * Returns the percentage out of 100% for each score
   * category.
   * **note** this returns a float value between 0 and 1.
   * Pass thru the percentage pipe or times by 100 to get
   * the actual percentage
   *
   * The formula for calculating the percentage for a given category is:
   * ( ${value} - ${minPoints} ) / ${maxPoints - minPoints}
   */
  public static getCategoryPercentages(params: {
    /**
     * The form we are to use, usually static
     */
    form: HraForm;
    /**
     * The result to calculate the percentages from
     */
    record: HraResult;
    /**
     * The corresponding user-result for this hra-result.
     */
    userResultInfo?: UserResultHraInfo;
  }): HraScores {
    const { form } = params;
    // The base scores will be used to calculate the resulting
    const scoreCategoryMap = this.getScoreCategoryMap(form);
    const baseScores = this.getCategoryScores({
      ...params,
      scoreCategoryMap,
      answerMap: this.getAnswerMap({ form })
    });
    const getPercentage = ({
      scoreCategoryType,
      score
    }: {
      scoreCategoryType: HraScoreCategoryTypes;
      score: number;
    }) => {
      if (!scoreCategoryMap[scoreCategoryType]) {
        // This category doesn't exist somehow
        return 0;
      }

      const scoreCategory = scoreCategoryMap[
        scoreCategoryType
      ] as HraScoreCategory;
      const percentage =
        (score - scoreCategory.min) / (scoreCategory.total - scoreCategory.min);

      if (percentage > 1) {
        // tslint:disable-next-line: no-console
        console.warn('[HraUtil] percentage over 100', scoreCategoryType);

        return 1;
      }

      if (percentage < 0) {
        // We do not support percentages under 0
        return 0;
      }

      return percentage;
    };

    return Object.entries(baseScores).reduce(
      (acc, [scoreCategoryType, score]: [HraScoreCategoryTypes, number]) => ({
        ...acc,
        [scoreCategoryType]: getPercentage({
          scoreCategoryType,
          score
        })
      }),
      {} as HraScores
    );
  }

  /**
   * Returns the average percentage between all
   * the scores.
   */
  public static getCategoryPercentageTotal(params: {
    /**
     * The form we are to use, usually static
     */
    form: HraForm;
    /**
     * The result to calculate the percentages from
     */
    record: HraResult;
    /**
     * The corresponding user-result for this hra-result.
     */
    userResultInfo?: UserResultHraInfo;
  }): number {
    const categoryScores = Object.values(this.getCategoryPercentages(params));

    if (!categoryScores.length) {
      // If there are no values, we can't divide by zero
      return 0;
    }

    return (
      categoryScores.reduce((sum, score) => sum + score, 0) /
      categoryScores.length
    );
  }

  /**
   * Returns the total for each category for every
   * question within the form.
   */
  public static getCategoryScores(params: {
    form: HraForm;
    record: HraResult;
    scoreCategoryMap: { [key in HraCategoryTypes]?: HraScoreCategory };
    answerMap: HraAnswerMap;
    /**
     * The corresponding user-result for this hra-result.
     */
    userResultInfo?: UserResultHraInfo;
  }): HraScores {
    const { form, record, scoreCategoryMap, userResultInfo } = params;

    if (!form || !record) {
      return {};
    }
    const { questions } = form;

    return questions.reduce((acc, question) => {
      // Const { scoreCategory } = question;
      const scoreCategories = Array.isArray(question.scoreCategory)
        ? question.scoreCategory
        : [question.scoreCategory];

      for (const scoreCategory of scoreCategories) {
        if (acc[scoreCategory] === undefined) {
          acc[scoreCategory] = 0;
        }
        const categoryDef: HraScoreCategory = scoreCategoryMap[scoreCategory];

        if (!categoryDef) {
          // This technically is an error state, log it later
          // tslint:disable-next-line: no-console
          console.warn('[HraUtil][getCategoryScores] no score-category found', {
            scoreCategory
          });
          continue;
        }

        if (acc[scoreCategory] >= categoryDef.total) {
          // If we are already at the limit, just skip.
          // return acc;
          continue;
        }

        const hasCustomScoreCalc =
          question.customScoreCalc &&
          typeof question.customScoreCalc === 'function';

        if (hasCustomScoreCalc) {
          // If there is a customScoreCalc function, then we
          return question.customScoreCalc({
            ...params,
            ...userResultInfo,
            question,
            scores: acc
          });
        }

        const hasCustomValue =
          question.customValue && typeof question.customValue === 'function';
        // If we defined a custom value function, we use this to get the score

        const questionScore = hasCustomValue
          ? question.customValue({
              ...params,
              ...userResultInfo,
              defaultFn: this.getQuestionScore.bind(this),
              question
            })
          : this.getQuestionScore({
              ...params,
              ...userResultInfo,
              question
            });

        if (questionScore === undefined) {
          // This question hasn't been answered yet, skip it
          continue;
        }
        const newValue = acc[scoreCategory] + questionScore;

        acc[scoreCategory] = newValue;
        continue;
      }

      return acc;
    }, {} as HraScores);
  }

  /**
   * The answer map is used to get question scores later.
   * This should be done 1 time to cut down
   * on iterations later.
   */
  public static getAnswerMap(params: { form: HraForm }): HraAnswerMap {
    const { form } = params;

    if (!form) {
      return {};
    }
    const { questions } = form;

    if (!questions) {
      return {};
    }

    return questions.reduce(
      (acc, question) => ({
        ...acc,
        [question.code]: question.answers.reduce(
          (acc2, answer) => ({
            ...acc2,
            [answer.code]: answer
          }),
          {}
        )
      }),
      {}
    );
  }

  /**
   * Returns the value of the question from the record,
   * will combine multi-select values.
   * Will return undefined if question has not been answered yet
   */
  private static getQuestionScore(params: {
    record: HraResult;
    question: HraQuestion;
    answerMap: HraAnswerMap;
  }): number | undefined {
    const { record, question, answerMap } = params;

    if (!record || !question || !answerMap) {
      return undefined;
    }
    const { code: questionCode } = question;

    if (record[questionCode] === undefined) {
      // No answer yet for this question
      return undefined;
    }
    const value = record[questionCode];

    if (Array.isArray(value) && question.type === HraQuestionType.MULTI) {
      // Combine the values according to the answerMap
      const questionScore = value.reduce((sum, valueCode) => {
        const answer = this.getAnswer({
          questionCode,
          valueCode,
          answerMap
        });

        if (!answer || !answer.value) {
          return sum;
        }

        return answer.value + sum;
      }, 0);

      return questionScore;
    } else if (typeof value === 'string') {
      // Using else to prevent scope error
      const answer = this.getAnswer({
        questionCode,
        valueCode: value,
        answerMap
      });

      if (!answer) {
        // This is technically an error
        return undefined;
      }

      return answer.value;
    }

    // The question didn't have a value
    return undefined;
  }

  /**
   * Utility function that safely navigates the answer map
   * to get the hra-answer for the given question code
   * and value code
   */
  private static getAnswer(params: {
    questionCode: string;
    valueCode: string;
    answerMap: HraAnswerMap;
  }): HraAnswer | undefined {
    const { questionCode, valueCode, answerMap } = params;

    if (!questionCode || !valueCode || !answerMap) {
      return undefined;
    }

    return answerMap[questionCode] && answerMap[questionCode][valueCode];
  }

  /**
   * Utility function that returns the HRA Question Answer.
   * If the question is a Multi Value (Checkbox) then we return the value
   * as comma separated.
   */
  public static getAnswerDescription({
    answerMap,
    question,
    code
  }: {
    answerMap: HraAnswerMap;
    question: HraQuestion;
    code?: string | string[];
  }): string | undefined {
    if (!code) {
      return undefined;
    }

    const questionAnswers = answerMap[question.code];

    if (Array.isArray(code)) {
      if (question.type === HraQuestionType.MULTI) {
        const codeValues = code;

        return codeValues
          .map((value) => questionAnswers[value]?.description)
          .filter((_) => !!_)
          .join(', ');
      }

      // Otherwise, the value is an array, but the type isn't, so we return the first value
      return questionAnswers[code[0]]?.description;
    }
    const answer = questionAnswers[code];

    // If there is no matching answer somehow, just skip it
    if (!answer) {
      return undefined;
    }

    return answer.description;
  }

  /**
   * Returns the Answer Code. If it is a multi-select we return the codes comma separated
   * This should only be used for display purposes, as a coma separated code isn't
   * very useful outside of this context.
   */
  public static getAnswerCode({
    question,
    code
  }: {
    question: HraQuestion;
    code?: string | string[];
  }): string | undefined {
    if (!code) {
      return undefined;
    }

    if (Array.isArray(code)) {
      if (question.type === HraQuestionType.MULTI) {
        return code.join(', ');
      }

      // If the code is an array, but the type is single,
      // return the first value
      return code[0];
    }

    // Otherwise its singular, so just return it.
    return code;
  }

  /**
   * Returns a map of category-types to category definitions
   */
  public static getCategoryMap(
    form: HraForm
  ): { [key in HraCategoryTypes]?: HraCategory } {
    if (!form) {
      return {};
    }
    const { categories } = form;

    if (!categories) {
      return {};
    }

    return categories.reduce(
      (map, category) => ({ ...map, [category.type]: category }),
      {}
    );
  }

  /**
   * Returns a map of score-category-types to category definitions
   */
  public static getScoreCategoryMap(
    form: HraForm
  ): { [key in HraCategoryTypes]?: HraScoreCategory } {
    if (!form) {
      return {};
    }
    const { scoreCategory } = form;

    if (!scoreCategory) {
      return {};
    }

    return scoreCategory.reduce(
      (map, category) => ({ ...map, [category.type]: category }),
      {}
    );
  }

  /**
   * Returns the color for the given percentage.
   * the percentage should be between 1 and 0.
   * The color a value that can be passed to the `ngStyle`
   * directive
   */
  public static getPercentageColor(percentage: number): string {
    if (!percentage || percentage > 1 || percentage < 0) {
      return HraAnswerColors.RED;
    }
    percentage = percentage * 100;

    if (percentage < 60) {
      return HraAnswerColors.RED;
    }

    if (percentage < 80) {
      return HraAnswerColors.YELLOW;
    }

    return HraAnswerColors.GREEN;
  }

  /**
   * Returns the total score for all questions
   */
  public static getTotalScore(params: { scores: HraScores }): number {
    const { scores } = params;

    return Object.values(scores).reduce(
      (acc, score) => (score ? acc + score : acc),
      0
    );
  }
  // TODO: add other methods as needed
}
