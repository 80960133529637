<div>
  <!-- Title -->
  <h1 mat-dialog-title>Move User To Another Company</h1>

  <!-- Content -->
  <mat-dialog-content class="mat-body-1"
                      fxLayout="column"
                      fxLayoutAlign="start center"
                      fxLayoutGap="8px">
    <div class="mat-body-2">Previous User's Company:</div>
    <div> {{ (userCompany$ | async)?.name || '' }} </div>
    <mat-form-field style="width: 300px">
      <input type="text"
             matInput
             #searchInput
             [value]="search$ | async"
             (keyup)="search$.next(searchInput.value)"
             autocomplete="off" />
    </mat-form-field>
    <div>
      <ng-container *ngIf="loading$ | async; else showCompanies">
        <div class="center-spinner margin">
          <mat-spinner class="circle-center"
                       diameter="50"></mat-spinner>
        </div>
      </ng-container>
      <ng-template #showCompanies>
        <div fxLayout="column"
             fxLayoutGap="8px">
          <ng-container *ngFor="let company of companies$ | async">
            <div (click)="companyToMoveTo = company"
                 [style.background]="company === companyToMoveTo ? '#e8eaf6' : ''"
                 style="cursor: pointer; padding: 8px 16px;">
              <ehs-gwapps-company *ngIf="company._id !== (userCompany$ | async)._id"
                                  [company]="company"
                                  adminGwNgrx
                                  #adminGwProvider="adminGwNgrx"
                                  (view)="adminGwProvider.store.viewAsJson(company)"
                                  (validate)="
                                      adminGwProvider.store.validateData({
                                      id: $any(company._id),
                                      resource: 'companies'
                                    })">
              </ehs-gwapps-company>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end"
                      fxLayoutGap="16px">
    <button type="button"
            mat-button
            [mat-dialog-close]="undefined">
      Cancel
    </button>
    <button type="button"
            color="primary"
            mat-button
            (click)="onSubmit()"
            [disabled]="submitDisabled">
      Confirm
    </button>
  </mat-dialog-actions>
</div>
