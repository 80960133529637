import { ONSITE_BIO_FORM_REQUIRED_FIELDS } from '.';
import { OnsiteService } from '../../models/event-service/onsite-service';
import { SiteServiceConditionalSelfReportedVisibility } from '../../models/event-service/site-service';
import { UserTest } from '../../models/user-test/user-test';
import { getId } from '../../util/get-id';
import { isOnsiteFsFormFieldWithTest } from './onsite-fs-form';
import {
  OnsiteResultFormDeclinableField,
  OnsiteResultFormFieldWithTest,
  OnsiteResultFormNotReportableField,
  OnsiteResultFormRequiredField
} from './onsite-result-form';

/**
 * Returns the fields that are marked as required in the UI.
 *
 * Most fields that are supported are automatically required by default,
 * except for the self-reported biometric fields.
 *
 * Will return the fields within the form that are required.
 * We only will care about fields that have the corresponding
 * test provided.
 */
export const getRequiredOnsiteResultFormFields = (params: {
  /**
   * The onsite-service we are to map user-tests for. We will also
   * leverage this service to check against conditionalSelfReported tests
   */
  onsiteService: OnsiteService;
  /**
   * The list of fields that are available, and their corresponding tests.
   *
   * The test will be used for a reverse lookup compare against the service
   * to determine if its a "measured" version or not.
   * @see `getAvailableOnsiteResultFormFields`
   */
  availableFields: Array<{
    field: OnsiteResultFormFieldWithTest;
    userTest: UserTest;
  }>;
  /**
   * The list of fields that are not reportable.
   * These will be removed from being required.
   */
  notReportedFields?: OnsiteResultFormNotReportableField[];
  /**
   * The list of fields that are declined.
   * These will be removed from being required.
   */
  declinedFields?: OnsiteResultFormDeclinableField[];
}): Array<OnsiteResultFormRequiredField> => [
  ...getRequiredBioFields(params),
  ...getRequiredFsFields(params)
];

/**
 * Returns the list of bio fields that are required.
 *
 * This is the "primary" logic, as biometric fields represent self-reported
 * and possibly measured versions. Because of this, their validation logic
 * is handled differently.
 */
export const getRequiredBioFields = ({
  onsiteService,
  availableFields,
  declinedFields
}: {
  onsiteService: OnsiteService;
  availableFields: Array<{
    field: OnsiteResultFormFieldWithTest;
    userTest: UserTest;
  }>;
  declinedFields?: OnsiteResultFormDeclinableField[];
}): Array<OnsiteResultFormRequiredField> => {
  if (!onsiteService) {
    // This is more inline with the data not loading, fail graciously
    return [];
  }

  // The list of ids for userTests that are explicitly set as available.
  const serviceTestIds = Array.from(
    new Set(
      [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onsiteService.panel!,
        ...onsiteService.additionalServices,
        ...onsiteService.additionalTests,
        ...onsiteService.optionalTests
      ]
        .filter(Boolean)
        .map(getId)
    ).values()
  );

  // Measured tests are always set as required, except
  // fingerstick, which we ignore
  const measuredTests = availableFields
    .filter(
      ({ field, userTest }) =>
        serviceTestIds.includes(getId(userTest)) && field !== 'fingerstick'
    )
    .map(({ field }) => field);

  return ONSITE_BIO_FORM_REQUIRED_FIELDS.reduce((acc, field) => {
    if (
      (
        ['bloodPressure', 'height', 'usesTobacco', 'weight'] as Array<
          keyof OnsiteService['conditionalSelfReported']
        >
      ).includes(field as keyof OnsiteService['conditionalSelfReported'])
    ) {
      if (declinedFields?.includes(field)) {
        // If the field is declined, skip it, as it can't be required
        return acc;
      }

      // If its any of these, then we can automatically check if its required
      if (
        onsiteService.conditionalSelfReported &&
        onsiteService.conditionalSelfReported[field] ===
          SiteServiceConditionalSelfReportedVisibility.REQUIRED
      ) {
        acc.push(field);

        return acc;
      }
    }

    if (field === 'usesTobacco' || field === 'fasting') {
      // If this isn't required for self-reported, then it isn't required at all.
      // as it can't be a measured test.

      return acc;
    }

    if (field === 'bloodPressure') {
      // This is a compound field, so both need to be measured to make sense.
      if (
        measuredTests.includes('bloodPressureSystolic') &&
        measuredTests.includes('bloodPressureDiastolic')
      ) {
        acc.push('bloodPressure');
      }

      return acc;
    }

    if (measuredTests.includes(field)) {
      acc.push(field);

      return acc;
    }

    return acc;
  }, [] as Array<OnsiteResultFormRequiredField>);
};

/**
 * Returns the fingerstick fields. This doesn't currently do anything, other
 * than return **only** fingerstick fields that are already specified, and
 * are fingerstick fields.
 *
 * **note** the `fingerstick` field represents the overall panel, which is a test itself,
 * and thus is returned as a possible "required" field, but it **can't** be
 * not reported.
 */
export const getRequiredFsFields = ({
  availableFields,
  notReportedFields
}: {
  availableFields: Array<{
    field: OnsiteResultFormFieldWithTest;
    userTest: UserTest;
  }>;
  notReportedFields?: OnsiteResultFormNotReportableField[];
}): Array<OnsiteResultFormRequiredField> =>
  availableFields
    .map(({ field }) => field)
    .filter((field) => {
      if (!isOnsiteFsFormFieldWithTest(field)) {
        return false;
      }

      if (field === 'fingerstick') {
        return false;
      }

      if (notReportedFields?.includes(field)) {
        return false;
      }

      return true;
    }) as Array<OnsiteResultFormRequiredField>;
