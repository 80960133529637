import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import {
  deepClone,
  PersonType,
  personTypeNames,
  isOnsiteUserRegistration,
  isOffsiteUserRegistration,
  UserRegistration,
  UserRegistrationStatus,
  OnsiteUserRegistration,
  OffsiteUserRegistration,
  EventService,
  ServiceType,
  EventServiceUtil,
  EventServiceServiceType,
  isOnsiteService,
  isOffsiteService,
  OnsiteGroupType,
  BaseGroupType,
  UserInsurance,
  InsuranceProvider,
  ProviderHealthUserRegistration,
  PayType,
  InsuranceFields,
  UserInsuranceType
} from '@common';
import {
  EhsRegEditForm,
  EhsRegEditFormFieldTypes
} from '../ehs-form-field-layout/ehs-form-field-types';
import { EhsFormFieldLayout } from '../ehs-form-field-layout/ehs-form-field-layout';

/**
 * This component is a re-usable, configurable dynamic form that can be used
 * to edit end-users within the application.
 */
@Component({
  selector: 'ehs-reg-edit-form',
  templateUrl: './ehs-reg-edit-form.component.html',
  styleUrls: ['./ehs-reg-edit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class EhsRegEditFormComponent<
  UserRegistrationType extends
    | OnsiteUserRegistration
    | OffsiteUserRegistration
    | ProviderHealthUserRegistration,
  EventServiceType extends EventService
> {
  public readonly fieldTypes = EhsRegEditFormFieldTypes;
  public readonly personTypes = Object.values(PersonType);
  public readonly serviceTypes = Object.values(ServiceType);
  public readonly registrationStatuses = Object.values(UserRegistrationStatus);
  public readonly insuranceTypes = Object.values(UserInsuranceType);

  // Input fields
  public userReg?: UserRegistrationType;
  public service?: EventServiceType;
  public insuranceProviders?: Record<string, InsuranceProvider>;

  // Fields states
  public hasInsurance: boolean;
  public newInsurance: boolean;

  public insurance: Partial<UserInsurance>;
  public groupTypes?: OnsiteGroupType[] | BaseGroupType[];
  public insuranceFields: InsuranceFields[];
  public insuranceProviderIds: string[];
  public selectedGroupType?: number;
  private selectedPayPackages: PayType[];
  public selectedService?: EventServiceServiceType;

  public typeDisplayed = false;
  public policyDisplayed = false;
  public groupDisplayed = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('userReg') set _userReg(userReg: UserRegistration | null) {
    this.userReg = deepClone({
      ...(userReg ? userReg : {})
    } as UserRegistrationType);

    if (
      isOnsiteUserRegistration(this.userReg) ||
      isOffsiteUserRegistration(this.userReg)
    ) {
      this.selectedGroupType = this.userReg.groupType;

      if (this.userReg.insurance) {
        this.hasInsurance = true;
        this.insurance = this.userReg.insurance || {};
      }
    }
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('service') set _service(service: EventService | null) {
    this.service = deepClone({
      ...(service ? service : {})
    } as EventServiceType);

    if (this.userReg?.serviceType) {
      this.selectedService = EventServiceUtil.getSelectedService({
        serviceType: this.userReg.serviceType,
        eventService: service
      });

      if (
        isOnsiteService(this.selectedService) ||
        isOffsiteService(this.selectedService)
      ) {
        this.groupTypes = this.selectedService.groupTypes || [];

        const group = this.groupTypes[this.selectedGroupType];

        this.selectedPayPackages = (group?.payPackage || []).concat(
          group?.payOptionalTests || []
        );

        this.hasInsurance =
          this.selectedPayPackages.includes(PayType.INSURANCE) &&
          group?.insuranceProviders?.length > 0;

        if (
          this.hasInsurance &&
          (isOffsiteUserRegistration(this.userReg) ||
            isOnsiteUserRegistration(this.userReg))
        ) {
          this.loadInsuranceData({
            groupType: group,
            siteRegistration: this.userReg
          });
        }
      }
    }
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('insuranceProviders') set _insuranceProviders(
    insuranceProviders: Record<string, InsuranceProvider> | null
  ) {
    this.insuranceProviders = insuranceProviders;

    this.insuranceProviderIds = Object.keys(this.insuranceProviders);
  }

  @Output() userRegChange = new EventEmitter<UserRegistrationType>();
  /**
   * The layout object of the field in the form for the user.
   */
  @Input() layout: EhsFormFieldLayout<EhsRegEditForm> | null;

  /**
   * Apply updates to the form when admin selects different
   * eligibility group.
   */
  onGroupTypeChange(groupType: number): void {
    const group = this.groupTypes[groupType];

    if (!group) {
      return;
    }

    // Only onsite or offsite registration allow insurance
    if (
      isOnsiteUserRegistration(this.userReg) ||
      isOffsiteUserRegistration(this.userReg)
    ) {
      this.selectedPayPackages = (group?.payPackage || []).concat(
        group?.payOptionalTests || []
      );

      if (this.selectedPayPackages.includes(PayType.INSURANCE)) {
        this.loadInsuranceData({
          groupType: group,
          siteRegistration: this.userReg
        });
      } else {
        this.clearInsuranceData();
      }
    } else {
      this.clearInsuranceData();
    }
  }

  /**
   * Sets the hasInsurance flag to true, and checks the given registration and
   * selected group type for insurance field settings and data to display
   */
  loadInsuranceData(params: {
    groupType: OnsiteGroupType | BaseGroupType;
    siteRegistration: OnsiteUserRegistration | OffsiteUserRegistration;
  }) {
    const { groupType, siteRegistration } = params;

    if (
      !groupType?.insuranceFields?.length ||
      !groupType?.insuranceProviders?.length
    ) {
      this.hasInsurance = false;
      this.newInsurance = false;

      return;
    }

    // If registration previously held insurance, try to reenter it
    const existingInsurance = siteRegistration?.insurance;

    if (existingInsurance) {
      this.hasInsurance = true;
      this.typeDisplayed = false;
      this.policyDisplayed = true;
      this.groupDisplayed = false;
      this.insurance = existingInsurance;

      return;
    }

    // If group type takes insurance but no existing insurance registration info
    // This reveals the remaining insurance fields to enter information for
    this.insurance = {};
    this.newInsurance = true;
    this.typeDisplayed = false;
    this.policyDisplayed = false;
    this.groupDisplayed = false;

    const typeDefault = UserInsuranceType.NA;
    const groupDefault = 'not applicable';
    const policyDefault = 'not applicable';

    groupType.insuranceFields.forEach((field) => {
      switch (field) {
        case InsuranceFields.INSURANCE_TYPE:
          this.typeDisplayed = true;
          break;
        case InsuranceFields.GROUP_NUMBER:
          this.groupDisplayed = true;
          this.insurance.groupNumber = '';
          break;
        case InsuranceFields.POLICY_NUMBER:
          this.policyDisplayed = true;
          this.insurance.policyNumber = '';
          break;
        default:
          break;
      }
    });

    if (!this.typeDisplayed) {
      this.insurance.insuranceType = typeDefault;
    }

    if (!this.policyDisplayed) {
      this.insurance.policyNumber = policyDefault;
    }

    if (!this.groupDisplayed) {
      this.insurance.groupNumber = groupDefault;
    }
  }

  /**
   * Group type selected does not accept insurance so this clears insurance
   * fields and sets insurance fields visibilities to false;
   */
  clearInsuranceData() {
    this.hasInsurance = false;
    this.newInsurance = false;
    this.typeDisplayed = false;
    this.policyDisplayed = false;
    this.groupDisplayed = false;
    this.insurance = undefined;
  }

  getPersonTypeName(personType: PersonType): string {
    return personTypeNames[personType] || '';
  }

  /**
   * Return the string name of the selected group type from the selected
   * service according to the group type index in the registration
   */
  getGroupTypeName(index: number): string {
    return this.groupTypes[index]?.name || '';
  }
}
