import { CommonResponse } from './common.response';
import { notAuthorizedResponse } from './not-authorized.response';

/**
 * The different kind of login errors return from the backend
 */
export enum LoginErrorResponseTypes {
  /**
   * If the email has not been verified by the user.
   */
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  /**
   * This is the more generic error that is returned
   * if the user doesn't exist, or their code is invalid
   */
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  /**
   * Error to show if there was an internal error, shown
   * for unknown firebase errors, and back-end errors
   */
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  /**
   * The company code passed does not match the user's client code
   */
  INVALID_CLIENT_CODE = 'INVALID_CLIENT_CODE',
  /**
   * If the user's account is locked due to to many
   * logins.
   */
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  /**
   * If company requires one MFA option,but user has none
   */
  REQUIRES_MFA = 'REQUIRES_MFA'
}

/**
 * The login error
 */
export interface LoginErrorResponse extends CommonResponse {
  type: LoginErrorResponseTypes;
}

export const loginErrorResponse = (type: LoginErrorResponseTypes) => ({
  ...notAuthorizedResponse,
  type
});
