import { createAction } from '@ngrx/store';
import { EntityStatsActions } from './entity-stats-actions';
import { EntityStatsTypeParams } from './entity-stats-arg-type';

/**
 * Returns an object of common "stats" actions,
 * namely `get` for a single entity.
 */
export const entityStatsActionFactory = <Params extends EntityStatsTypeParams>(
  prefix: Params['prefix']
): EntityStatsActions<Params> => ({
  getStats: createAction(`[${prefix}] GET_STATS`, ({ id, reload }) => ({
    id,
    reload
  })),
  getStatsSuccess: createAction(
    `[${prefix}] GET_STATS_SUCCESS`,
    ({ id, stats }) => ({ id, stats })
  ),
  getStatsFailed: createAction(`[${prefix}] GET_STATS_FAILED`, ({ id }) => ({
    id
  })),

  statsClear: createAction(`[${prefix}] STATS_CLEAR`, ({ id }) => ({ id }))
});
