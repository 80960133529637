/**
 * Enum of vaccine types.
 * Currently not available in all places in the app, but can
 * be used as a lookup against other mappings for more info
 *
 * List from here:
 * https://www2a.cdc.gov/vaccines/iis/iisstandards/vaccines.asp?rpt=cvx
 */
export enum COVIDVaccines {
  ASTRA_ZENECA = 'astraZeneca',
  MODERNA = 'moderna',
  PFIZER_BIO_N_TECH = 'pfizerBioNTech',
  JANSSEN_JOHNSON_AND_JOHNSON = 'janssenJohnsonAndJohnson'
}

/**
 * Definition for the Vaccine
 */
export interface COVIDVaccineDef {
  /**
   * CDC product name
   * RXA 5
   */
  name: string;
  /**
   * Short description
   */
  shortDesc: string;
  /**
   * Full Vaccine name
   */
  fullName: string;
  /**
   * The CVX Code,
   * part of RXA 5 in hl7-util
   */
  cvx: number;
  /**
   * The NDC code,
   * part of RXA 5
   */
  ndcFirstDose: string;
  /**
   * The NDC code for the second dose
   */
  ndcSecondDose?: string;
  /**
   * Number in days between doses
   */
  interval?: number;
  /**
   * Name of manufacturer
   */
  manufacturer: string;
  /**
   * ???
   * used in RXA 17
   */
  mvxCode: string;
  /**
   * If active or not
   * not sure what the "invactive" is, not going to deal with those anyways
   */
  mvxStatus: 'active' | string;
  // TODO: might add other info, but not now
}

/**
 * Map of vaccine definitions, for more data from a given vaccine enum
 * data from here:
 * https://www2a.cdc.gov/vaccines/iis/iisstandards/vaccines.asp?rpt=cvx
 */
// tslint:disable-next-line: variable-name
export const COVIDVaccineDefMap: Record<COVIDVaccines, COVIDVaccineDef> = {
  [COVIDVaccines.MODERNA]: {
    name: 'Moderna COVID-19 Vaccine',
    shortDesc: 'COVID-19, mRNA, LNP-S, PF, 100 mcg/0.5 mL dose',
    fullName:
      'SARS-COV-2 (COVID-19) vaccine, mRNA, spike protein, LNP, preservative free, 100 mcg/0.5mL dose',
    cvx: 207,
    ndcFirstDose: '80777-273-10',
    ndcSecondDose: '80777-0273-10',
    interval: 28,
    manufacturer: 'Moderna US, Inc.',
    mvxCode: 'MOD',
    mvxStatus: 'active'
  },
  [COVIDVaccines.PFIZER_BIO_N_TECH]: {
    name: 'Pfizer-BioNTech COVID-19 Vaccine',
    shortDesc: 'COVID-19, mRNA, LNP-S, PF, 30 mcg/0.3 mL dose',
    fullName:
      'SARS-COV-2 (COVID-19) vaccine, mRNA, spike protein, LNP, preservative free, 30 mcg/0.3mL dose',
    cvx: 208,
    ndcFirstDose: '59267-1000-1',
    ndcSecondDose: '59267-1000-01', // Note these are the same 21 days apart
    interval: 21,
    manufacturer: 'Pfizer, Inc',
    mvxCode: 'PFR',
    mvxStatus: 'Active'
  },
  [COVIDVaccines.ASTRA_ZENECA]: {
    name: 'AstraZeneca COVID-19 Vaccine',
    shortDesc: 'COVID-19 vaccine, vector-nr, rS-ChAdOx1, PF, 0.5 mL',
    fullName:
      'SARS-COV-2 (COVID-19) vaccine, vector non-replicating, recombinant spike protein-ChAdOx1, preservative free, 0.5 mL',
    cvx: 210,
    ndcFirstDose: '0310-1222-10',
    ndcSecondDose: '00310-1222-10',
    interval: 28,
    manufacturer: 'AstraZeneca',
    mvxCode: 'ASZ',
    mvxStatus: 'active'
  },
  [COVIDVaccines.JANSSEN_JOHNSON_AND_JOHNSON]: {
    name: 'Janssen (J&J) COVID-19 Vaccine',
    shortDesc: 'COVID-19 vaccine, vector-nr, rS-Ad26, PF, 0.5 mL',
    fullName:
      'SARS-COV-2 (COVID-19) vaccine, vector non-replicating, recombinant spike protein-Ad26, preservative free, 0.5 mL',
    cvx: 212,
    ndcFirstDose: '59676-580-15',
    ndcSecondDose: undefined, // There is no second dose!
    interval: undefined, // There is no interval, as there is no second dose!
    manufacturer: 'Janssen',
    mvxCode: 'JSN',
    mvxStatus: 'active'
  }
};

// MvxCode^Name^MVX
