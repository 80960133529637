import { ObjectId } from 'mongodb';
import { User } from '../user';
import { DbDocument } from '../db-document';
import { HraCategoryTypes } from './hra-category';
import { EventService } from '../event-service/event-service';

/**
 * Enum used for bmi ranges that are displayed to end users
 * TODO: move to common/constants
 */
export enum BmiRange {
  UNDERWEIGHT = 'Underweight',
  HEALTHY_WEIGHT = 'Healthy Weight',
  OVER_WEIGHT = 'Over Weight',
  OBESE = 'Obese',
  /**
   * @deprecated
   */
  CRITICAL = 'Critical'
}

/**
 * Enum used for blood pressure ranges that are displayed to end users
 * TODO: move to common/constants
 */
export enum BloodPressureRange {
  NORMAL = 'Normal',
  PREHYPERTENSIVE = 'Prehypertensive',
  HIGH = 'High'
}

export enum HraResultStatus {
  PENDING = 'pending',
  SUBMITTED = 'submitted'
}
/**
 * This is the base type "under" the full hra-result.
 * This does not include any of the extra potential properties.
 * Should only be used for typing against the "core" hra-result
 * properties
 */
export interface CoreHraResult extends DbDocument {
  // TODO: we can add versioning and other meta data later
  /**
   * The user who created the hra-result.
   * **note** for now each hra-result will be unique to the user
   */
  user: string | ObjectId | User;
  /**
   * The event service this hra-result is tied to.
   */
  eventService: string | ObjectId | EventService;

  /** The Company for the hra-result, newly added as of 10/27/22 */
  company: string | ObjectId | EventService;
  /**
   * The status of the record, as once a record status is
   * set as "submitted", we will show its calculated results,
   * until then we wont show the result.s
   */
  status: HraResultStatus;

  /** The date the HRA was submitted, this is used to compare against deadline for user visibility */
  completedAt?: Date | string;

  /**
   * The category the user was last one. This is just the "last saved position"
   * to redirect the user back to this page later.
   * This shouldn't be given if status is submitted.
   */
  prevCategory?: HraCategoryTypes;
  /**
   * The user's self reported height information.
   * Asked in "question 27a and 27b", which is a dropdown and input
   * like the offsite-user registration page.
   */
  heightFeet?: number;
  heightInches?: number;
  /**
   * The user's weight in pounds. Corresponds with
   * question 28
   */
  weight?: number;
  /**
   * Denotes hra as part of a user account that was merged, but conflicting data prevented
   * successfully merging. Originally from the orphaned user.
   */
  orphanedHra?: boolean;
}
/**
 * An hra record is the user's options selected for the
 * hra form.
 */
export interface HraResult
  extends CoreHraResult,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<string, string | string[] | null | any> {}
