import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { EhscUserTableComponent } from './ehsc-user-table.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [EhscUserTableComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    RouterModule,
    // Angular Material
    FlexLayoutModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatMenuModule,
    MatIconModule,
    PipesModule
  ],
  exports: [EhscUserTableComponent]
})
export class EhscUserTableModule {}
