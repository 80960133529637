import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { userRegActions } from '../../../modules/user-reg/user-reg-store/user-reg.actions';
import { AdminCoachingSessionHttpService } from '../../http/admin/admin-coaching-session-http.service';
import { CoachingSessionHttpService } from '../../http/coaching-session-http.service';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { coachingSessionActions } from './coaching-session.actions';

@Injectable()
export class CoachingSessionEffects {
  constructor(
    private actions$: Actions,
    private coachingSessionHttp: CoachingSessionHttpService,
    private errorReportFacade: ErrorReportFacade,
    private adminCoachingSessionHttpService: AdminCoachingSessionHttpService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coachingSessionActions.get),
      mergeMap((action) =>
        this.coachingSessionHttp.get(action.id).pipe(
          map((entity) => coachingSessionActions.getSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            coachingSessionActions.getFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coachingSessionActions.list),
      mergeMap((action) =>
        this.coachingSessionHttp.list().pipe(
          map((entities) => coachingSessionActions.listSuccess({ entities })),
          catchError((err: HttpErrorResponse) => [
            coachingSessionActions.listFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coachingSessionActions.create),
      mergeMap((action) =>
        this.coachingSessionHttp.create(action.entity).pipe(
          mergeMap((entity) => [
            userRegActions.setCoachingId({ coachingId: entity._id as string }),
            coachingSessionActions.createSuccess({ entity })
          ]),
          catchError((err: HttpErrorResponse) => [
            coachingSessionActions.createFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coachingSessionActions.remove),
      mergeMap((action) =>
        this.coachingSessionHttp.remove(action.id).pipe(
          map((entity) => coachingSessionActions.removeSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            coachingSessionActions.removeFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  listAvailabilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coachingSessionActions.listAvailabilities),
      mergeMap((action) =>
        this.coachingSessionHttp
          .listAvailabilities(action.date, action.timezone)
          .pipe(
            map((availabilities) =>
              coachingSessionActions.listAvailabilitiesSuccess({
                availabilities
              })
            ),
            catchError((err: HttpErrorResponse) => [
              coachingSessionActions.listAvailabilitiesFailed({
                error: err.error
              }),
              this.errorReportFacade.create({
                err: err.error,
                action
              })
            ])
          )
      )
    )
  );

  exportCs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coachingSessionActions.exportCompanyCs),
      mergeMap((action) =>
        this.adminCoachingSessionHttpService
          .exportCs({ company: action.company })
          .pipe(
            map(() => coachingSessionActions.exportCompanyCsSuccess()),
            catchError((err: HttpErrorResponse) => [
              coachingSessionActions.exportCompanyCsFailed(),
              this.errorReportFacade.create({ err: err.error, action })
            ])
          )
      )
    )
  );
}
