import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EhsAddAdminDialogModule, EhsConfirmDialogModule } from '@ehs-ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AdminCoreNgrxProviderDirective } from './admin-core-ngrx-provider.directive';
import { AdminCoreNgrxEffects } from './admin-core-ngrx.effects';
import {
  adminCoreNgrxReducer,
  ADMIN_CORE_NGRX_STORE_KEY
} from './admin-core-ngrx.state';

@NgModule({
  declarations: [AdminCoreNgrxProviderDirective],
  imports: [
    StoreModule.forFeature(ADMIN_CORE_NGRX_STORE_KEY, adminCoreNgrxReducer),
    EffectsModule.forFeature([AdminCoreNgrxEffects]),

    // Angular
    MatSnackBarModule,

    // Dialogs
    EhsAddAdminDialogModule,
    EhsConfirmDialogModule
  ],
  exports: [AdminCoreNgrxProviderDirective]
})
export class AdminCoreNgrxModule {}
