import {
  CommonResponse,
  CommonResponseWithReason,
  ForgotUsernameRequest,
  LoginErrorResponse,
  LoginRequest,
  PasswordResetRequest,
  SignUpRequest,
  User
} from '@common';
import { createAction, props } from '@ngrx/store';
import { AuthError, MultiFactorError } from 'firebase/auth';
import { TempSignUp } from './auth.state';

export const authActions = {
  getUser: createAction(
    '[AUTH] GET_USER',
    props<{
      insurance: boolean;
    }>()
  ),
  getUserSuccess: createAction(
    '[AUTH] GET_USER_SUCCESS',
    props<{ user: User; clientAdmin?: User }>()
  ),
  getUserFailed: createAction('[AUTH] GET_USER_FAILED'),
  login: createAction(
    '[AUTH] LOGIN',
    props<{
      loginRequest: LoginRequest;
    }>()
  ),
  loginSuccess: createAction(
    '[AUTH] LOGIN_SUCCESS',
    props<{
      user: User;
      clientAdmin?: User;
    }>()
  ),
  loginFailed: createAction(
    '[AUTH] LOGIN_FAILED',
    props<{
      err?: LoginErrorResponse;
    }>()
  ),
  firebaseLoginFailed: createAction(
    '[AUTH] FIREBASE_LOGIN_FAILED',
    props<{
      err: Pick<AuthError, 'code' | 'message'>;
      username: string;
    }>()
  ),
  getIsLocked: createAction(
    '[AUTH] GET_IS_LOCKED',
    props<{
      username: string;
    }>()
  ),
  getIsLockedSuccess: createAction(
    '[AUTH] GET_IS_LOCKED_SUCCESS',
    props<{
      locked: boolean;
    }>()
  ),
  getIsLockedFailed: createAction(
    '[AUTH] GET_IS_LOCKED_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),
  lockAccount: createAction(
    '[AUTH] LOCK_ACCOUNT',
    props<{
      username: string;
    }>()
  ),
  lockAccountSuccess: createAction('[AUTH] LOCK_ACCOUNT_SUCCESS'),
  lockAccountFailed: createAction(
    '[AUTH] LOCK_ACCOUNT_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),
  clearLoginError: createAction('[AUTH] CLEAR_LOGIN_ERROR'),

  signUp: createAction(
    '[AUTH] SIGN_UP',
    props<{
      signUpRequest: SignUpRequest;
    }>()
  ),
  signUpSuccess: createAction(
    '[AUTH] SIGN_UP_SUCCESS',
    props<{
      response: CommonResponse;
    }>()
  ),
  signUpFailed: createAction(
    '[AUTH] SIGN_UP_FAILED',
    props<{
      error: CommonResponseWithReason;
    }>()
  ),
  tempSignUp: createAction(
    '[AUTH] TEMP_SIGN_UP',
    props<{
      tempSignUp: TempSignUp;
    }>()
  ),
  tempSignUpClear: createAction('[AUTH] TEMP_CLEAR'),
  logout: createAction('[AUTH] LOGOUT'),
  logoutSuccess: createAction(
    '[AUTH] LOGOUT_SUCCESS',
    props<{
      clientCode: string;
    }>()
  ),
  logoutFailed: createAction('[AUTH] LOGOUT_FAILED'),

  setSentSignUpRequest: createAction(
    '[AUTH] SET_SENT_SIGN_UP_REQUEST',
    props<{
      sentSignUpRequest: SignUpRequest;
    }>()
  ),
  clearSentSignUpRequest: createAction('[AUTH] CLEAR_SENT_SIGN_UP_REQUEST'),

  clear: createAction('[AUTH] CLEAR'),
  updateUser: createAction(
    '[AUTH] UPDATE_USER',
    props<{ user: Partial<User>; includeInsurance?: boolean }>()
  ),
  updateUserSuccess: createAction(
    '[AUTH] UPDATE_USER_SUCCESS',
    props<{ user: Partial<User> }>()
  ),
  updateUserFailed: createAction('[AUTH] UPDATE_USER_FAILED'),

  isValidUsername: createAction(
    '[AUTH] IS_VALID_USERNAME',
    props<{
      username: string;
    }>()
  ),
  isValidUsernameSuccess: createAction(
    '[AUTH] IS_VALID_USERNAME_SUCCESS',
    props<{
      valid: boolean;
    }>()
  ),
  isValidUsernameFailed: createAction('[AUTH] IS_VALID_USERNAME_FAILED'),

  verifyEmail: createAction(
    '[AUTH] VERIFIED_EMAIL',
    props<{
      captcha: string;
      username: string;
    }>()
  ),
  verifyEmailSuccess: createAction('[AUTH] VERIFIED_EMAIL_SUCCESS'),
  verifyEmailFailed: createAction('[AUTH] VERIFIED_EMAIL_FAILED'),

  forgotUsername: createAction(
    '[AUTH] FORGOT_USERNAME',
    props<ForgotUsernameRequest>()
  ),
  forgotUsernameSuccess: createAction('[AUTH] FORGOT_USERNAME_SUCCESS'),
  forgotUsernameFailed: createAction('[AUTH] FORGOT_USERNAME_FAILED'),

  resetPassword: createAction(
    '[AUTH] RESET_PASSWORD',
    props<PasswordResetRequest>()
  ),
  resetPasswordSuccess: createAction('[AUTH] RESET_PASSWORD_SUCCESS'),
  resetPasswordFailed: createAction('[AUTH] RESET_PASSWORD_FAILED'),
  resetPasswordFlowDone: createAction('[AUTH] RESET_PASSWORD_FLOW_DONE'),

  verifyMFA: createAction('[AUTH] VERIFY_MFA'),
  verifyMFASuccess: createAction('[AUTH] VERIFY_MFA_SUCCESS'),
  verifyMFAFlowDone: createAction('[AUTH] VERIFY_MFA_FLOW_DONE'),
  reAuth: createAction(
    '[AUTH] RE_AUTH',
    props<{
      password: string;
    }>()
  ),
  reAuthSuccess: createAction('[AUTH] RE_AUTH_SUCCESS'),
  reAuthFailed: createAction(
    '[AUTH] RE_AUTH_FAILED',
    props<{
      err: { code: string; message: string };
    }>()
  ),

  updatePassword: createAction(
    '[AUTH] UPDATE_PASSWORD',
    props<{
      newPassword: string;
    }>()
  ),
  updatePasswordSuccess: createAction('[AUTH] UPDATE_PASSWORD_SUCCESS'),
  updatePasswordFailed: createAction(
    '[AUTH] UPDATE_PASSWORD_FAILED',
    props<{
      err: { code: string; message: string };
    }>()
  ),
  clearInUpdateSensitiveInfoFlow: createAction(
    '[AUTH] CLEAR_IN_UPDATE_PASSWORD_FLOW'
  )
};
