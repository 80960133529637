import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { AuthFacade } from '../app-store/auth/auth.facade';

/**
 * Protects the login and register routes from already authenticated users.
 */
@Injectable({
  providedIn: 'root'
})
class LoginRegisterGuardService {
  constructor(private authFacade: AuthFacade, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authFacade.user$.pipe(
      withLatestFrom(this.authFacade.inVerifyMFAFlow$),
      map(([user, inMFA]) => {
        if (inMFA) {
          return this.router.createUrlTree(['/verify-mfa']);
        }

        return user ? this.router.createUrlTree(['/']) : true;
      }),
      take(1)
    );
  }
}

export const LoginRegisterGuard: CanActivateFn = () => {
  return inject(LoginRegisterGuardService).canActivate();
};
