import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EhsCallToActionComponent } from './ehs-call-to-action.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [EhsCallToActionComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [EhsCallToActionComponent]
})
export class EhsCallToActionModule {}
