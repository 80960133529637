import { Injectable } from '@angular/core';
import { AdminUserScopes } from '@common';
import { UserResultListActionColumn } from './user-result-list-table-column';

@Injectable({
  providedIn: 'root'
})
export class EhsUserResultListTableService {
  public getActionColumns(
    scopes: AdminUserScopes[]
  ): UserResultListActionColumn[] {
    return (
      [
        {
          type: AdminUserScopes.REMOVE_RESULT,
          scope: AdminUserScopes.REMOVE_RESULT,
          name: 'Remove Result'
        },
        {
          type: 'view',
          name: 'View Raw Data'
        },
        {
          type: 'viewEventService',
          name: 'View Event Service'
        }
      ] as Array<UserResultListActionColumn>
    ).filter(({ scope }) => (scope ? scopes.includes(scope) : true));
  }
}
