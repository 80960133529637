import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommonResponse,
  EventService,
  getId,
  OnsiteResultFormRequest,
  ProviderResultFormRequest,
  ResultFormRequest,
  SelfReportedUserRegData,
  UserRegistration,
  UserResult
} from '@common';
import { Observable } from 'rxjs';
import { QueryList, QueryListService } from '../../util/query-list.service';

@Injectable({
  providedIn: 'root'
})
export class AdminUserResultHttpService {
  private baseUrl = 'api/v1/admin';
  private clientAdminUrl = 'api/v1/client';

  constructor(private http: HttpClient, private queryList: QueryListService) {}

  public importUserResults(params: {
    company: string;
    spreadsheetId: string;
    collectionDate: string;
  }): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      'api/v1/admin/company/import/results',
      params
    );
  }

  /**
   * Returns the user registration count for the given event-services
   */
  public getUserRegCount(params: {
    eventServices: Array<EventService | string>;
  }): Observable<{ count: number }> {
    const { eventServices } = params;

    return this.http.get<{ count: number }>('api/v1/admin/company/reg/count', {
      params: eventServices.reduce(
        (acc, eventService) => acc.append('eventId', getId(eventService)),
        new HttpParams()
      )
    });
  }

  /**
   * Gets a single user-result
   */
  public get(params: { id: string }): Observable<UserResult> {
    const { id } = params;

    return this.http.get<UserResult>(`api/v1/admin/result/${id}`);
  }

  public getByUserRegistration(params: {
    userRegistrationId: string;
  }): Observable<UserResult> {
    const { userRegistrationId } = params;

    return this.http.get<UserResult>(
      `api/v1/admin/result/registration/${userRegistrationId}`
    );
  }

  public list(params: {
    query: QueryList<UserResult>;
  }): Observable<UserResult[]> {
    const { query } = params;

    return this.http.get<UserResult[]>('api/v1/admin/result/list', {
      params: this.queryList.build(query)
    });
  }

  /**
   * Creates a user-result from the partial
   */
  public createUserResult(params: {
    userResult: Partial<UserResult>;
  }): Observable<UserResult> {
    const { userResult } = params;

    return this.http.post<UserResult>('api/v1/admin/result/create', userResult);
  }

  /**
   * Creates a user-result for a provider-health data-entry.
   */
  public createProviderUserResult(params: {
    request: ProviderResultFormRequest;
  }): Observable<UserResult> {
    const { request } = params;

    return this.http.post<UserResult>(
      'api/v1/admin/result/provider/create',
      request
    );
  }

  public removeUserResult(params: {
    userRegistration: string;
    removeRegistration?: boolean;
  }): Observable<UserResult> {
    const { userRegistration, removeRegistration } = params;

    return this.http.delete<UserResult>(
      `api/v1/admin/result/registration/${userRegistration}`,
      {
        params: removeRegistration
          ? new HttpParams().append('removeRegistration', 'true')
          : undefined
      }
    );
  }

  /**
   * Removes user result using result id for cases when
   * result has no registration.
   */
  public removeUserResultById(params: {
    userResult: string;
  }): Observable<UserResult> {
    const { userResult } = params;

    return this.http.delete<UserResult>(
      `api/v1/admin/result/${getId(userResult)}`
    );
  }

  public createResultFromForm(params: {
    request: ResultFormRequest;
  }): Observable<CommonResponse> {
    const { request } = params;

    return this.http.post<CommonResponse>(
      `api/v1/admin/result/create`,
      request
    );
  }

  /**
   * This is the "new" version that uses the new onsite-result-form
   * request. Its not compatible with the old version.
   */
  public createOnsiteResultFromForm(params: {
    request: OnsiteResultFormRequest;
  }): Observable<UserResult> {
    const { request } = params;

    return this.http.post<UserResult>(
      `api/v1/admin/result/onsite/create`,
      request
    );
  }

  /**
   * Updates the entity, only the tests properties is cared about
   * currently.
   */
  public update(entity: {
    userResult: Pick<UserResult, '_id' | 'tests'>;
    userRegistration?: SelfReportedUserRegData;
  }): Observable<{
    userResult: UserResult;
    userRegistration: UserRegistration;
  }> {
    return this.http.put<{
      userResult: UserResult;
      userRegistration: UserRegistration;
    }>(`api/v1/admin/result/update/${getId(entity.userResult)}`, entity);
  }
}
