<div>
    <mat-chip-listbox [value]="templateType"
                      hideSingleSelectionIndicator
                      defaultTabIndex="0"
                      (change)="onTemplateChange($event)"
                      aria-label="User Email Audit Template">
        <mat-chip-option value="all">All</mat-chip-option>
        <mat-chip-option value="no_show">No Show</mat-chip-option>
        <mat-chip-option value="event_cancelled">Event canceled </mat-chip-option>
        <mat-chip-option value="hra_reminder">HRA reminder </mat-chip-option>
    </mat-chip-listbox>
    <ng-container *ngIf="!userEmailAudits?.length && !loading; else showTable">
        <p>User has no matching audits</p>
    </ng-container>
    <ng-template #showTable>
        <!-- Load Spinner-->
        <div *ngIf="loading else showAudits"
             class="center-spinner"
             [style.margin]="'75px'">
            <mat-spinner class="circle-center"
                         diameter="50"></mat-spinner>
        </div>
        <ng-template #showAudits>
            <table mat-table
                   [dataSource]="userEmailAudits"
                   style="width: 100%">
                <ng-container matColumnDef="createdAt">
                    <div>
                        <th mat-header-cell
                            class="audit-header-cell"
                            [ngStyle]="{'padding-left': '0px;'}"
                            *matHeaderCellDef> Audit Time </th>
                        <td mat-cell
                            class="audit-cell"
                            [ngStyle]="{'padding-left': '0px;'}"
                            *matCellDef="let userAudit">
                            <span>
                                {{userAudit.createdAt | date:'medium'}}
                            </span>
                        </td>
                    </div>
                </ng-container>

                <ng-container matColumnDef="emailTemplate">
                    <div>
                        <th mat-header-cell
                            class="audit-header-cell"
                            *matHeaderCellDef> Email Template </th>
                        <td mat-cell
                            class="audit-cell"
                            *matCellDef="let userAudit">
                            {{ userAudit.emailTemplate.replace('_', ' ') | titlecase}}
                        </td>
                    </div>
                </ng-container>

                <ng-container matColumnDef="email">
                    <div>
                        <th mat-header-cell
                            class="audit-header-cell"
                            *matHeaderCellDef> Reciving Email </th>
                        <td mat-cell
                            class="audit-cell"
                            *matCellDef="let userAudit">
                            {{ userAudit.email}}
                        </td>
                    </div>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <div>
                        <th mat-header-cell
                            class="audit-header-cell"
                            *matHeaderCellDef> </th>
                        <td mat-cell
                            class="audit-cell"
                            [ngStyle]="{'padding-right': '1px;'}"
                            *matCellDef="let userAudit">

                            <button mat-icon-button
                                    type="button"
                                    aria-label="Menu"
                                    [matMenuTriggerFor]="userActionMenu"
                                    [matMenuTriggerFor]="userActionMenu"
                                    [matMenuTriggerData]="{ userAudit: userAudit }">
                                <mat-icon>
                                    more_vert
                                </mat-icon>
                            </button>

                            <mat-menu #userActionMenu="matMenu">
                                <ng-template matMenuContent
                                             let-userAudit="userAudit">
                                    <ng-container
                                                  *ngFor="let action of getUserAuditSpecificActions(userAudit); trackBy: trackByFn">
                                        <button mat-menu-item
                                                (click)="actionChange.emit({ actionType: $any(action.type), userAudit})"
                                                [title]="action.name"
                                                type="button">
                                            <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
                                            {{action.name}}
                                        </button>
                                    </ng-container>
                                </ng-template>
                            </mat-menu>
                        </td>
                    </div>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    *matRowDef="let userAudit; columns: displayedColumns;"></tr>
            </table>
            <div fxLayout="row"
                 [fxLayoutAlign]="currentPageNumber !== 1 ? 'space-between center' : 'end center'">
                <button *ngIf="currentPageNumber !== 1"
                        (click)="changePageNumber(currentPageNumber - 1)"
                        mat-button>
                    < Previous
                      </button>
                        <button *ngIf="hasNext || (pageNumber-currentPageNumber) !== 0"
                                mat-button
                                (click)="onNext()">Next ></button>
            </div>
        </ng-template>

    </ng-template>
</div>