import { DbDocument, getId } from '@common';

/**
 * Reusable selector for mongodb entities.
 */
export const selectId = <
  T extends DbDocument<DocumentKey>,
  DocumentKey extends string
>(
  model: T
): string => {
  if (!model) {
    throw new Error(`No model given, can not get _id`);
  }

  if (!model._id) {
    throw new Error('Model._id does not exist');
  }

  return getId(model._id);
};
