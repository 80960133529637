import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { EntityStatsActions } from './entity-stats-actions';
import { EntityStatsTypeParams } from './entity-stats-arg-type';
import { EntityStatsState } from './entity-stats-state';

export const entityStatsReducerFactory = <
  Params extends EntityStatsTypeParams,
  State extends EntityStatsState<Params> = EntityStatsState<Params>
>({
  entityStatsActions
}: {
  entityStatsActions: EntityStatsActions<Params>;
}): Array<ReducerTypes<State, ActionCreator[]>> => [
  on(entityStatsActions.getStats, (state, { id }) => ({
    ...state,
    statsLoading: [...(state.statsLoading || []), id]
  })),
  on(entityStatsActions.getStatsSuccess, (state, { id, stats }) => ({
    ...state,
    stats: {
      ...state.stats,
      [id]: stats
    },
    statsLoading: (() => {
      const newStatsLoading = (state.statsLoading || []).filter(
        (key) => key !== id
      );

      if (!newStatsLoading.length) {
        // If there are no more in the list of loading, remove the array
        return undefined;
      }

      return newStatsLoading;
    })()
  })),
  on(entityStatsActions.getStatsFailed, (state, { id }) => ({
    ...state,
    statsLoading: (state.statsLoading || []).filter((key) => key !== id)
  })),

  on(entityStatsActions.statsClear, (state, { id }) =>
    id
      ? { ...state, stats: { ...state.stats, [id]: undefined } }
      : { ...state, stats: undefined }
  )
];
