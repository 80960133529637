import { User, getId } from '@common';
import { selectId } from '@ehs-ngrx/common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { clientAdminCoreNgrxActions } from './client-admin-core-ngrx.actions';

export const CLIENT_ADMIN_CORE_NGRX_STORE_KEY = 'clientAdminCoreNgrx';

export interface ParentClientAdminCoreNgrxState {
  [CLIENT_ADMIN_CORE_NGRX_STORE_KEY]: ClientAdminCoreNgrxState;
}

/**
 * Note this state is a simplified version of the
 * AdminGwState in the ng-portal app.
 */
export interface ClientAdminCoreNgrxState extends EntityState<User> {
  loading?: boolean;
  /**
   * The actual admin-user currently logged in.
   *
   * **note** during the transition period, this will be
   * directly "injected" into the store from the ng-portal state. As such
   * there are some actions not yet defined, or added.
   */
  user?: User;
  dateRange?: { startDate: string; endDate: string };
}

const adapter = createEntityAdapter<User>({ selectId });

export const clientAdminCoreNgrxReducer =
  createReducer<ClientAdminCoreNgrxState>(
    adapter.getInitialState({}),
    on(clientAdminCoreNgrxActions.set, (state, { user }) => ({
      ...state,
      user
    })),

    on(clientAdminCoreNgrxActions.list.req, (state) => ({
      ...state,
      loading: true
    })),
    on(
      clientAdminCoreNgrxActions.list.success,
      (state, { payload: { entities } }) =>
        adapter.upsertMany(entities, { ...state, loading: false })
    ),
    on(clientAdminCoreNgrxActions.list.failed, (state) => ({
      ...state,
      loading: false
    })),

    on(clientAdminCoreNgrxActions.addClientAdmin.req, (state) => ({
      ...state,
      loading: true
    })),

    on(clientAdminCoreNgrxActions.addClientAdmin.success, (state) => ({
      ...state,
      loading: false
    })),

    on(clientAdminCoreNgrxActions.addClientAdmin.failed, (state) => ({
      ...state,
      loading: false
    })),

    on(clientAdminCoreNgrxActions.update.req, (state) => ({
      ...state,
      loading: true
    })),

    on(
      clientAdminCoreNgrxActions.update.success,
      (state, { payload: { admin } }) => {
        const updateState = adapter.updateOne(
          {
            id: getId(admin),
            changes: admin
          },
          { ...state, loading: false }
        );

        if (!state.user || getId(admin) !== getId(state.user)) {
          return updateState;
        }

        return {
          ...updateState,
          user: {
            ...state.user,
            ...admin
          }
        };
      }
    ),

    on(clientAdminCoreNgrxActions.update.failed, (state) => ({
      ...state,
      loading: false
    })),

    on(clientAdminCoreNgrxActions.removeClientAdmin.req, (state) => ({
      ...state,
      loading: true
    })),

    on(
      clientAdminCoreNgrxActions.removeClientAdmin.success,
      (state, { payload: { adminId } }) =>
        adapter.removeOne(adminId, { ...state, loading: false })
    ),

    on(clientAdminCoreNgrxActions.removeClientAdmin.failed, (state) => ({
      ...state,
      loading: false
    }))
  );
