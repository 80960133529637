import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import {
  getId,
  ReimbursementFile,
  ReimbursementStatus,
  UserReimbursement
} from '@common';
import { UserReimbursementUploadsColumn } from './user-reimbursement-uploads-column';

@Component({
  selector: 'ehs-user-reimbursement-uploads-table',
  templateUrl: './ehs-user-reimbursement-uploads-table.component.html',
  styleUrls: ['./ehs-user-reimbursement-uploads-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsUserReimbursementUploadsTableComponent {
  @Input() columns: UserReimbursementUploadsColumn[] = [];
  @Input() rows: UserReimbursement[] = [];
  @Input() hasNext?: boolean;
  @Input() pageNumber?: number;
  @Output() loadMore = new EventEmitter();

  @Output() downloadReimbursementReceipt = new EventEmitter<{
    userReimbursementId: string;
    file: ReimbursementFile;
  }>();

  /**
   * If we are to display the checkbox in its "indeterminate" state
   */
  @Input() indeterminate?: boolean;

  /**
   * If the all are toggled
   */
  @Input() allAreSelected?: boolean;
  /**
   * If we are to disable all actions within the table.
   */
  @Input() disabled?: boolean;
  /**
   * Array of selected user-reimbursement-ids. Should be handled via
   * a parent ngrx
   */
  @Input() selected: string[] = [];
  /**
   * Event emitted if we are toggling the top-level select-all
   */
  @Output() toggleAll = new EventEmitter();

  /**
   * Event emitted when the checkbox is selected/de-selected for a given user-reimbursement.
   */
  @Output() toggle = new EventEmitter<UserReimbursement>();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  private pageSize = 25;

  private get loadMoreIndex() {
    return (this.pageNumber - 1) * this.pageSize;
  }

  public onScrolledIndexChange(index: number) {
    if (index > this.loadMoreIndex) {
      this.loadMore.emit();
    }
  }

  /**
   * To keep the header positioning:sticky inside cdk viewport
   */
  get headerTop() {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }

    const offset = this.viewPort['_renderedContentOffset'];

    return `-${offset}px`;
  }

  trackByFn(index: number) {
    return index;
  }

  handleDownloadReimbursementReceipt(params: {
    userReimbursement: UserReimbursement;
    file: ReimbursementFile;
  }) {
    const { userReimbursement, file } = params;

    this.downloadReimbursementReceipt.emit({
      file,
      userReimbursementId: getId(userReimbursement)
    });
  }

  public getStatusColor(status: ReimbursementStatus) {
    switch (status) {
      case ReimbursementStatus.UPLOADED:
        return '#FEB80F';
      case ReimbursementStatus.REJECTED:
        return '#F23A2E';
      case ReimbursementStatus.APPROVED:
        return '#43A446';
      default:
        return '#FEB80F';
    }
  }

  public getStatusTooltip(status: ReimbursementStatus) {
    switch (status) {
      case ReimbursementStatus.UPLOADED:
        return 'Uploaded';
      case ReimbursementStatus.REJECTED:
        return 'Rejected';
      case ReimbursementStatus.APPROVED:
        return 'Approved';
      default:
        return '';
    }
  }

  public isSelected(userReimbursement: UserReimbursement): boolean {
    return this.selected.includes(getId(userReimbursement));
  }
}
