<div fxLayout="column"
     fxLayoutAlign="center center"
     fxLayoutGap="8px">
  <div>
    <img *ngIf="logoUrl"
         [src]="logoUrl"
         style="max-width: 50px;">
  </div>
  <div>
    <span class="mat-subheading-1"> {{company?.name}} </span>
  </div>
  <div>
    <span class="mat-caption"> {{company?.clientCode}} </span>
  </div>
</div>
