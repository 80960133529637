import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsUserPaymentsTableComponent } from './ehs-user-payments-table.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatChipsModule } from '@angular/material/chips';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [EhsUserPaymentsTableComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ScrollingModule,
    MatChipsModule,
    MatButtonModule
  ],
  exports: [EhsUserPaymentsTableComponent]
})
export class EhsUserPaymentsTableModule {}
