import {
  ChangeDetectionStrategy,
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit
} from '@angular/core';
import {
  MatSlideToggle,
  MatSlideToggleChange
} from '@angular/material/slide-toggle';
import {
  Company,
  CompanySecurity,
  CompanySecurityLabels,
  CompanySignInOptions,
  toTitleCase
} from '@common';
import { EhsConfirmDialogService } from '../../dialogs/ehs-confirmation-dialog';
import { lastValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'ehs-company-security',
  templateUrl: './ehs-company-security.component.html',
  styleUrls: ['./ehs-company-security.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsCompanySecurityComponent {
  /**
   * The company security to display
   */
  @Input() companySecurity?: CompanySecurity;

  /**
   * The current company, used for routing.
   */
  @Input() company?: Company | null;

  @Input() showForceMFA?: boolean;

  /**
   * Emits when the user changes the value of the EHS Login
   */
  @Output() toggleEhsLogin = new EventEmitter<boolean>();

  /**
   * Emits when the user changes the value of the SSO Login options
   */
  @Output() toggleSsoLogin = new EventEmitter<CompanySignInOptions[]>();

  /**
   * Emits when the admin changed the list of domains
   */
  @Output() updateDomains = new EventEmitter<string[]>();

  /**
   * Emits when the admin changed the button label or help text
   */
  @Output() updateLabels = new EventEmitter<{
    key: CompanySecurityLabels;
    value: string;
  }>();

  /**
   * Emits when the user changes the value of force MFA
   */
  @Output() toggleForceMFA = new EventEmitter<boolean>();

  public companySignInOptions = CompanySignInOptions;

  googleHelpText?: string;
  constructor(
    private confirmDialog: EhsConfirmDialogService,
    private snackbar: MatSnackBar
  ) {}

  async updateLabel({
    property,
    input
  }: {
    property: CompanySecurityLabels;
    input: HTMLInputElement;
  }) {
    const hasConfirmed = await lastValueFrom(
      this.confirmDialog.open({
        message: `Are you sure you want to update the label to ${input.value}?`,
        title: `Updating Label`
      })
    );

    if (!hasConfirmed) {
      input.value = this.companySecurity[property] || '';
      this.snackbar.open('Canceled', 'OK');

      return;
    }

    this.updateLabels.emit({
      key: property,
      value: input.value
    });
  }

  async toggleEhsSignIn(
    event: MatSlideToggleChange,
    ehsLoginToggle: MatSlideToggle
  ) {
    const hasConfirmed = await lastValueFrom(
      this.confirmDialog.open({
        message: `Are you sure you want to ${
          event.checked ? 'enable' : 'disable'
        } EHS log in for ${this.company?.name}?\nUsers will ${
          event.checked ? 'now' : 'no longer'
        } be able to log in using their username and password.`,
        title: `${event.checked ? 'Enable' : 'Disable'} EHS log in`
      })
    );

    if (!hasConfirmed) {
      ehsLoginToggle.checked = !event.checked;
      this.snackbar.open('Canceled', 'OK');

      return;
    }

    this.toggleEhsLogin.emit(!event.checked);
  }

  async toggleForceMFADialog(
    event: MatSlideToggleChange,
    ehsMFAToggle: MatSlideToggle
  ) {
    const hasConfirmed = await lastValueFrom(
      this.confirmDialog.open({
        message: `Are you sure you want to ${
          event.checked ? 'enable' : 'disable'
        } force MFA for ${this.company?.name}?\nUsers will ${
          event.checked ? 'need' : 'no longer need'
        } one valid MFA option to log in`,
        title: `${event.checked ? 'Enable' : 'Disable'} Force MFA`
      })
    );

    if (!hasConfirmed) {
      ehsMFAToggle.checked = !event.checked;
      this.snackbar.open('Canceled', 'OK');

      return;
    }

    this.toggleForceMFA.emit(event.checked);
  }

  async toggleSsoSignIn({
    event,
    option,
    ehsToggle
  }: {
    event: MatSlideToggleChange;
    option: CompanySignInOptions;
    ehsToggle: MatSlideToggle;
  }) {
    const hasConfirmed = await lastValueFrom(
      this.confirmDialog.open({
        message: `Are you sure you want to ${
          event.checked ? 'enable' : 'disable'
        } ${toTitleCase(option)} log in for ${
          this.company?.name
        }?\nUsers will ${
          event.checked ? 'now' : 'no longer'
        } be able to log in using their ${toTitleCase(option)} account.`,
        title: `${
          event.checked ? 'Enable' : 'Disable'
        } Sign in with ${toTitleCase(option)}`
      })
    );

    if (!hasConfirmed) {
      ehsToggle.checked = !event.checked;
      this.snackbar.open('Canceled', 'OK');

      return;
    }

    const ssoOptions = (this.companySecurity?.signInOptions || []).filter(
      (ssoOption) => ssoOption !== option
    );

    if (event.checked) {
      ssoOptions.push(option);
    }

    this.toggleSsoLogin.emit(ssoOptions);
  }

  addDomain(domain: string) {
    const domains = (this.companySecurity?.whitelistDomains || []).filter(
      (d) => d !== domain
    );

    domains.push(domain);

    this.updateDomains.emit(domains);
  }

  async deleteDomain(domain: string) {
    const hasConfirmed = await lastValueFrom(
      this.confirmDialog.open({
        message: `Are you sure you want to delete ${domain} from the whitelist domains?`,
        title: `Delete Domain`
      })
    );

    if (!hasConfirmed) {
      this.snackbar.open('Canceled', 'OK');

      return;
    }

    const domains = (this.companySecurity?.whitelistDomains || []).filter(
      (d) => d !== domain
    );

    this.updateDomains.emit(domains);
  }
}
