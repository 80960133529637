<form #waiverForm="ngForm"
      (submit)="onSubmit(waiverForm)">
    <h1 mat-dialog-title>Add Waiver</h1>
    <mat-dialog-content style="padding: 8px;">
        <mat-form-field class="full-width">
            <mat-label>Date Submitted</mat-label>
            <input matInput
                   name="dateSubmitted"
                   ngModel
                   #dateInput="ngModel"
                   required
                   [matDatepicker]="picker">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error style="margin-top: 4px;"
                       *ngIf="dateInput.hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
            <mat-label>User</mat-label>
            <input type="text"
                   [value]="selectedUserName$ | async"
                   (keyup)="onUserChange($any($event.target).value)"
                   placeholder="User"
                   autocomplete="off"
                   [matAutocomplete]="userAuto"
                   matInput
                   ngModel
                   #userInput="ngModel"
                   name="user"
                   required>
            <button mat-icon-button
                    matSuffix
                    (click)="onClearUser()"
                    *ngIf="selectedUserName$ | async"
                    type="button"
                    title="Clear User"
                    aria-label="Clear User">
                <mat-icon class="icon-small">close</mat-icon>
            </button>
            <mat-autocomplete #userAuto="matAutocomplete"
                              #userInput
                              #userDropdown
                              (optionSelected)="onUserSelected($any($event.option).value)">
                <mat-option *ngFor="let user of filteredUsers"
                            [value]="user">
                    {{user.firstName}} {{user.lastName}} {{user.username ? ' - '+ user.username : ''}}
                </mat-option>
            </mat-autocomplete>
            <mat-error style="margin-top: 4px;"
                       *ngIf="userInput.hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <div style="margin-top: 8px;">
            <input type="file"
                   hidden
                   color="primary"
                   name="file"
                   #fileInput
                   (change)="onFileUpload($any($event.target)?.files)"
                   [accept]="['.pdf','image/*']">
            <button mat-flat-button
                    type="button"
                    (click)="fileInput.click()"
                    color="accent">
                <mat-icon style="transform:scale(.75)">upload_file</mat-icon>
                Upload File
            </button>
            <div class="mat-error"
                 *ngIf="showFileError">
                A file is Required
            </div>
            <div *ngIf="file">{{file?.name || ""}} <button mat-icon-button
                        class="small-icon-button"
                        color="warn"
                        (click)="clearFile(fileInput)"
                        aria-label="Example icon button with a home icon">
                    <mat-icon>cancel</mat-icon>
                </button></div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end"
                        fxLayoutGap="16px">
        <button type="button"
                class="grey-cancel"
                mat-button
                [mat-dialog-close]="false">
            Cancel
        </button>
        <button type="submit"
                color="primary"
                mat-button
                cdkFocusInitial>
            Add
        </button>
    </mat-dialog-actions>
</form>