import { CategoryIds } from '../constants/education-categories';
import { DbDocument } from './db-document';

export enum ArticleTypes {
  ARTICLE = 'article',
  VIDEO = 'video',
  CALCULATOR = 'calculator',
  QUIZ = 'quiz',
  GAME = 'game'
}

export enum ArticleStatus {
  DRAFT = 'draft',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  ACTIVE = 'active'
}
/**
 * Represents a health resource that can be shown along
 * with medline resources.
 */
export interface Article extends DbDocument {
  status: ArticleStatus;
  /**
   * The name of the article
   */
  name: string;
  /**
   * The full content of the article.
   * Note: This is a Rich Text Editor field so the content is an HTML content
   * and not just text.
   */
  details: string;
  /**
   * The type of article
   */
  type: ArticleTypes;
  /**
   * List of categories that the article belongs to
   */
  categories?: CategoryIds[];
  /**
   * The name of the source
   */
  source: string;
  /**
   * @deprecated - Use new field details
   * The full content of the result
   */
  summary: string;
  /**
   * The source url.
   * This means different things for different types (I think)
   */
  url: string;
  /**
   * The url fo the video, only applies to video urls right now
   */
  videoUrl?: string;
  /**
   * The list of tags for this article
   *
   */
  tags: string[];

  /**
   * Banner Image url in the bucket
   */
  bannerUrl?: string;

  /**
   * The date the article was published,
   * which is manually set in GWApps and used to order articles
   * within the results.
   *
   * I **assume** this means newer articles are shown "first", so this
   * field should be set with a descending sort.
   */
  publishedOn?: Date;
}
