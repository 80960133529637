import { getId } from '@common';
import {
  entitySelectorsFactory,
  entityStatsSelectorFactory
} from '@ehs-ngrx/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyNgrxState,
  CompanyNgrxStatsTypeParams,
  COMPANY_NGRX_STORE_KEY,
  ParentCompanyNgrxState
} from './company-ngrx.state';

export const companyNgrxFeatureSelector =
  createFeatureSelector<CompanyNgrxState>(COMPANY_NGRX_STORE_KEY);

export const companyNgrxSelectors = {
  ...entitySelectorsFactory({ featureSelector: companyNgrxFeatureSelector }),
  loadedSelector: createSelector(
    companyNgrxFeatureSelector,
    (state) => state.loaded
  ),
  companySecurity: (id: string) =>
    createSelector(
      companyNgrxFeatureSelector,
      (state) =>
        (state.companySecurity || {})[id] || {
          company: getId(id)
        }
    ),
  companyNotes: () =>
    createSelector(
      companyNgrxFeatureSelector,
      (state) => state.companyNotes || ''
    ),
  securityLoadingSelector: createSelector(
    companyNgrxFeatureSelector,
    (state) => !!state.companySecurityLoading
  ),
  companyNotesLoadingSelector: createSelector(
    companyNgrxFeatureSelector,
    (state) => !!state.companyNotesLoading
  ),
  ...entityStatsSelectorFactory<
    ParentCompanyNgrxState,
    CompanyNgrxState,
    CompanyNgrxStatsTypeParams
  >({
    featureSelector: companyNgrxFeatureSelector
  })
};
