/**
 * Regex to validate emails, see isValidEmail for
 * extra sanity checks
 */
// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Returns if the given string is a valid email
 */
export const isValidEmail = (
  email: string,
  params?: { allowEmpty?: boolean }
): boolean => {
  if (params?.allowEmpty && !email) {
    return true;
  }

  return email && EMAIL_REGEX.test(email);
};
