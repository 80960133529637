/* eslint-disable @typescript-eslint/no-non-null-assertion */
/**
 * **Note** this is all wrapped to prevent global name collisions
 */
import { EventService } from '../models/event-service/event-service';
import { OnsiteService } from '../models/event-service/onsite-service';
import { ServiceType } from '../models/event-service/service-type';
import { SiteServiceConditionalSelfReportedVisibility } from '../models/event-service/site-service';
import {
  LoincCode,
  PrimaryOrderCode,
  UserTest
} from '../models/user-test/user-test';
import { ResultFormRequest } from '../requests/result-form-request';
import { EventServiceUtil } from '../util/event-service-util';
import { getId } from '../util/get-id';
import { UserTestUtil } from '../util/user-test-util/user-test-util';
import { FingerStickCodes, FINGER_STICK_CODE } from './finger-stick-tests';

export interface ResultFormRequestDef {
  /**
   * Property of the finger stick form request
   * **note** height is separate as this needs to be calculated
   * from the heightFeet and heightInches
   */
  prop: keyof ResultFormRequest;
  /**
   * The result code for the given test.
   * should be lab-result-code?
   */
  resultCode: FingerStickCodes;
  /**
   * The loincId
   */
  loincId: LoincCode;
  /**
   * If this test should be included if the fingerstick
   * panel is added.
   *
   * If fingerstick is NOT added, then this isn't included
   */
  fingerStick?: boolean;
  /**
   * If this test is a "biometric" field in the form.
   */
  biometric?: boolean;
}

/**
 * List of fields that can be disabled and need to be re-verified
 */
export const RESULT_FIELDS_TO_VERIFY: Array<keyof ResultFormRequest> = [
  'totalCholesterol',
  'hdl',
  'triglycerides',
  'glucose',
  'ldl',
  'totalCholesterolRatio',
  'nonHdlCholesterol',
  'height',
  'weight',
  'bmi',
  'bca',
  'waist',
  'hip',
  'bloodPressureSystolic',
  'bloodPressureDiastolic',
  'selfReportedHeight',
  'selfReportedWeight',
  'hoursFasted',
  'fasting'
];

/**
 * These fields we always verify
 */
export const RESULT_STATIC_FIELDS_TO_VERIFY: Array<keyof ResultFormRequest> = [
  'selfReportedHeight',
  'selfReportedWeight',
  'hoursFasted'
];
/**
 * The list of properties we verify if covid.
 */
export const RESULT_COVID_FIELDS_TO_VERIFY: Array<keyof ResultFormRequest> = [
  'timeOfVaccination',
  'shotLocation'
];
/**
 * This mapping is for finger stick tests.
 * Should be used in conjunction with the finger stick form request
 */
export const RESULT_FORM_REQUEST_DEFS: ResultFormRequestDef[] = [
  {
    prop: 'totalCholesterol',
    loincId: LoincCode('2093-3'),
    resultCode: FingerStickCodes.TOTAL_CHOLESTEROL,
    fingerStick: true
  },
  {
    prop: 'hdl',
    loincId: LoincCode('2085-9'),
    resultCode: FingerStickCodes.HDL,
    fingerStick: true
  },
  {
    prop: 'triglycerides',
    loincId: LoincCode('2571-8'),
    resultCode: FingerStickCodes.TRIGLYCERIDES,
    fingerStick: true
  },
  {
    prop: 'glucose',
    loincId: LoincCode('2345-7'),
    resultCode: FingerStickCodes.GLUCOSE,
    fingerStick: true
  },
  {
    prop: 'ldl',
    loincId: LoincCode('13457-7'),
    resultCode: FingerStickCodes.LDL,
    fingerStick: true
  },
  {
    prop: 'totalCholesterolRatio',
    loincId: LoincCode('9830-1'),
    resultCode: FingerStickCodes.TOTAL_CHOLESTEROL_RATIO,
    fingerStick: true
  },
  {
    prop: 'nonHdlCholesterol',
    loincId: LoincCode(''), // TODO: ???
    resultCode: FingerStickCodes.NON_HDL_CHOLESTEROL,
    fingerStick: true
  },
  {
    prop: 'height',
    loincId: LoincCode('3137-7'),
    resultCode: FingerStickCodes.HEIGHT,
    biometric: true
  },
  {
    prop: 'weight',
    loincId: LoincCode('3141-9'),
    resultCode: FingerStickCodes.WEIGHT,
    biometric: true
  },
  {
    prop: 'bmi',
    loincId: LoincCode('56114-2'), // TODO: FIX! #1106
    resultCode: FingerStickCodes.BMI,
    biometric: true
  },
  {
    prop: 'bca',
    loincId: LoincCode('56114-2'), // TODO: FIX! #1106
    resultCode: FingerStickCodes.BCA,
    biometric: true
  },
  {
    prop: 'waist',
    loincId: LoincCode('56114-2'), // TODO: FIX! #1106
    resultCode: FingerStickCodes.WAIST,
    biometric: true
  },
  {
    prop: 'hip',
    loincId: LoincCode(''), // TODO: unknown??
    resultCode: FingerStickCodes.HIP,
    biometric: true
  },
  {
    prop: 'bloodPressureSystolic',
    loincId: LoincCode('8480-6'),
    resultCode: FingerStickCodes.BLOOD_PRESSURE_SYSTOLIC, // Was '101144'
    biometric: true
  },
  {
    prop: 'bloodPressureDiastolic',
    loincId: LoincCode('8462-4'),
    resultCode: FingerStickCodes.BLOOD_PRESSURE_DIASTOLIC,
    biometric: true // Was '101145'
  }
];

/**
 * Returns the primary code for the given test-def.
 * The test-def needs to find the corresponding user-test by primary-code.
 * Then from that user-test, it checks to see if that user-test is available
 * in the service. If it is, then it returns the user-test
 */
const getUserTest = (params: {
  testDef: ResultFormRequestDef;
  /**
   * Map of user-test codes where the key
   * is the user-test code, rather than _id
   */
  userTestsMap: Record<string, UserTest>;
}): UserTest | undefined => {
  const { testDef, userTestsMap } = params;
  const userTest = userTestsMap[testDef.resultCode];

  if (!userTest) {
    return undefined;
  }

  return userTest;
};
const hasFingerstick = (params: {
  eventService: EventService;
  /**
   * Map of user-test codes where the key
   * is the user-test code, rather than _id
   */
  userTests: UserTest[];
}): boolean => {
  const { userTests, eventService } = params;

  if (!userTests || !eventService) {
    return false;
  }
  const userTestsMap = UserTestUtil.getUserTestsMap({ userTests });
  const fingerStickTest = userTestsMap[FINGER_STICK_CODE];

  if (!fingerStickTest) {
    return false;
  }

  const onsiteService = EventServiceUtil.getSelectedService({
    eventService,
    serviceType: ServiceType.ONSITE
  }) as OnsiteService;

  if (!onsiteService || !onsiteService.panel) {
    return false;
  }

  return getId(onsiteService.panel) === getId(fingerStickTest);
};

/**
 * Returns the available fingerstick services for the event-service.
 *
 * **note** this will handle a special case, as specified within #2533,
 * to add the height and weight services **if** self-reported height and weight
 * are required, or offered.
 */
export const getAvailableServices = (params: {
  eventService: EventService;
  userTests: UserTest[];
  /**
   * The optional request, will be checked if the height and weight are given.
   * If this is **not** given, the existing behavior will be applied.
   *
   * **note** this is more of a workaround to deal with the fact the back-end doesn't
   * know if it was given the height and weight, and thus could add them when
   * there aren't actually any values passed from the form.
   */
  request?: ResultFormRequest;
}): Record<
  PrimaryOrderCode,
  {
    userTest: UserTest;
    fingerStickTest: ResultFormRequestDef;
  }
> => {
  const { eventService, userTests, request } = params;

  if (!eventService || !userTests) {
    return {};
  }
  const userTestsMap = UserTestUtil.getUserTestsMap({ userTests });
  const onsiteService = EventServiceUtil.getSelectedService({
    eventService,
    serviceType: ServiceType.ONSITE
  }) as OnsiteService;

  if (!onsiteService) {
    return {};
  }

  const serviceUserTestIds = new Set(
    [
      onsiteService.panel!,
      ...onsiteService.additionalServices,
      ...onsiteService.additionalTests,
      ...onsiteService.optionalTests
    ]
      .filter(Boolean)
      .map(getId)
  );

  // Alias type, used to save on typing
  type RecordValue = {
    userTest: UserTest;
    fingerStickTest: ResultFormRequestDef;
  };

  const availableServices = RESULT_FORM_REQUEST_DEFS.reduce((acc, testDef) => {
    const userTest = getUserTest({ testDef, userTestsMap });
    // **Note** what happens when no test def is passed?
    const includeFromFingerStick =
      hasFingerstick(params) && !!testDef.fingerStick;

    if (!serviceUserTestIds.has(getId(userTest!)) && !includeFromFingerStick) {
      // If the does not have the user test **and** its
      // not to be included due to the include from fingerstick
      return acc;
    }

    if (userTest) {
      acc[UserTestUtil.getCode(userTest)] = {
        userTest,
        fingerStickTest: testDef
      };
    }

    return acc;
  }, {} as Record<PrimaryOrderCode, RecordValue>);

  // Perform extra fallback checks in regards to the weight and height fields
  // will check if they are conditionally asked for.
  const hasHeight = onsiteService.conditionalSelfReported
    ? onsiteService.conditionalSelfReported?.height !==
      SiteServiceConditionalSelfReportedVisibility.NOT_OFFERED
    : false;
  const hasWeight = onsiteService.conditionalSelfReported
    ? onsiteService.conditionalSelfReported?.weight !==
      SiteServiceConditionalSelfReportedVisibility.NOT_OFFERED
    : false;

  // Workaround check to prevent trying to add the height service
  // if there is no height given.
  // same logic applies to weight
  const requestHeightCheck =
    !request || (request.height !== undefined && request.height !== null);
  const requestWeightCheck =
    !request || (request.weight !== undefined && request.weight !== null);

  if (
    hasHeight &&
    !availableServices[PrimaryOrderCode(FingerStickCodes.HEIGHT)] &&
    requestHeightCheck
  ) {
    // If there is no height, then we add it manually IF it was given in the request.
    // otherwise if it isn't then we can't create it as the request has no value.
    availableServices[PrimaryOrderCode(FingerStickCodes.HEIGHT)] = {
      userTest: userTestsMap[PrimaryOrderCode(FingerStickCodes.HEIGHT)],
      fingerStickTest: RESULT_FORM_REQUEST_DEFS.find(
        ({ resultCode }) => resultCode === FingerStickCodes.HEIGHT
      )
    } as RecordValue;
  }

  if (
    hasWeight &&
    !availableServices[PrimaryOrderCode(FingerStickCodes.WEIGHT)] &&
    requestWeightCheck
  ) {
    // If there is no height, then we add it manually IF it was given in the request.
    // otherwise if it isn't, then we can't create it as the request has no value
    availableServices[PrimaryOrderCode(FingerStickCodes.WEIGHT)] = {
      userTest: userTestsMap[PrimaryOrderCode(FingerStickCodes.WEIGHT)],
      fingerStickTest: RESULT_FORM_REQUEST_DEFS.find(
        ({ resultCode }) => resultCode === FingerStickCodes.WEIGHT
      )
    } as RecordValue;
  }

  return availableServices;
};
