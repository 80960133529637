import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginRegisterGuard } from './core/guards/login-register.guard';
import { ClientAdminGuard } from './core/guards/client-admin.guard';
import { SamlLoginGuard } from './core/guards/saml-login.guard';
import { AdminGuard } from './modules/admin/admin.guard';
import { PasswordResetRequestedGuard } from './modules/password-reset-requested/password-reset-requested.guard';
import { SignUpVerifyGuard } from './modules/sign-up-verify/sign-up-verify.guard';
import { InMFAGuard } from './core/guards/in-mfa.guard';
import { AddMFAGuard } from './core/guards/add-mfa.guard';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    canActivate: [LoginRegisterGuard],
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'signup',
    pathMatch: 'full',
    canActivate: [LoginRegisterGuard],
    loadChildren: () =>
      import('./modules/sign-up-temp/sign-up-temp.module').then(
        (m) => m.SignUpTempModule
      )
  },
  {
    path: 'forgot-username',
    pathMatch: 'full',
    canActivate: [LoginRegisterGuard],
    loadChildren: () =>
      import(
        './modules/login-forgot-username/login-forgot-username.module'
      ).then((m) => m.LoginForgotUsernameModule)
  },
  {
    path: 'password-reset',
    pathMatch: 'full',
    canActivate: [LoginRegisterGuard],
    loadChildren: () =>
      import('./modules/login-password-reset/login-password-reset.module').then(
        (m) => m.LoginPasswordResetModule
      )
  },
  {
    path: 'verify-mfa',
    pathMatch: 'full',
    canActivate: [InMFAGuard],
    loadChildren: () =>
      import('./modules/verify-mfa/verify-mfa.module').then(
        (m) => m.VerifyMFAModule
      )
  },
  {
    path: 'add-mfa',
    pathMatch: 'full',
    canActivate: [AddMFAGuard],
    loadChildren: () =>
      import('./modules/add-mfa/add-mfa.module').then((m) => m.AddMFAModule)
  },
  {
    path: 'saml/register/:samlType',
    pathMatch: 'full',
    canActivate: [SamlLoginGuard],
    loadChildren: () =>
      import('./modules/login-saml-details/login-saml-details.module').then(
        (m) => m.LoginSamlDetailsModule
      )
  },
  {
    path: 'reset-requested',
    pathMatch: 'full',
    canActivate: [LoginRegisterGuard, PasswordResetRequestedGuard],
    loadChildren: () =>
      import(
        './modules/password-reset-requested/password-reset-requested.module'
      ).then((m) => m.PasswordResetRequestedModule)
  },
  {
    path: 'verify',
    pathMatch: 'full',
    canActivate: [SignUpVerifyGuard],
    loadChildren: () =>
      import('./modules/sign-up-verify/sign-up-verify.module').then(
        (m) => m.SignUpVerifyModule
      )
  },
  {
    path: 'ehs/admin/login',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/admin-login/admin-login.module').then(
        (m) => m.AdminLoginModule
      )
  },
  {
    path: 'ehs/admin/unauthorized',
    pathMatch: 'full',
    // **Note** this page can always be accessed. Shouldn't be a big deal
    loadChildren: () =>
      import('./modules/admin-no-auth/admin-no-auth.module').then(
        (m) => m.AdminNoAuthModule
      )
  },
  {
    path: 'ehs/admin',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: 'ehs/client',
    canActivate: [ClientAdminGuard],
    loadChildren: () =>
      import('./modules/client-admin/client-admin.module').then(
        (m) => m.ClientAdminModule
      )
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/main/main.module').then((m) => m.MainModule)
  },
  {
    path: ':clientCode',
    pathMatch: 'full',
    canActivate: [LoginRegisterGuard],
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: ':clientCode/login',
    pathMatch: 'full',
    canActivate: [LoginRegisterGuard],
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: ':clientCode/signup',
    pathMatch: 'full',
    canActivate: [LoginRegisterGuard],
    loadChildren: () =>
      import('./modules/sign-up/sign-up.module').then((m) => m.SignUpModule)
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      // Outdate as of v15 update
      // relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
