import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HealthProgram } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HealthProgramHttpService {
  constructor(private http: HttpClient) {}

  public list(): Observable<HealthProgram[]> {
    return this.http.get<HealthProgram[]>('/api/v1/health-program');
  }

  public get(id: string): Observable<HealthProgram> {
    return this.http.get<HealthProgram>(`/api/v1/health-program/${id}`);
  }
}
