import { User } from '@common';
import { entitySelectorsFactory } from '@ehs-ngrx/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ClientAdminCoreNgrxState,
  CLIENT_ADMIN_CORE_NGRX_STORE_KEY,
  ParentClientAdminCoreNgrxState
} from './client-admin-core-ngrx.state';

export const clientAdminCoreNgrxFeatureSelector =
  createFeatureSelector<ClientAdminCoreNgrxState>(
    CLIENT_ADMIN_CORE_NGRX_STORE_KEY
  );

const baseSelectors = entitySelectorsFactory<
  User,
  string,
  ParentClientAdminCoreNgrxState,
  ClientAdminCoreNgrxState
>({
  featureSelector: clientAdminCoreNgrxFeatureSelector
});

const clientAdminUserSelector = createSelector(
  clientAdminCoreNgrxFeatureSelector,
  (state) => state.user
);

const clientAdminUserScopesSelector = createSelector(
  clientAdminUserSelector,
  (user) => (user ? user.clientAdminScopes : [])
);

export const clientAdminCoreNgrxSelectors = {
  ...baseSelectors,

  clientAdminUserSelector: createSelector(
    clientAdminCoreNgrxFeatureSelector,
    (state) => state.user
  ),
  // Don't know if I need this one
  dateRangeSelector: createSelector(
    clientAdminCoreNgrxFeatureSelector,
    (state) => state.dateRange
  ),
  clientAdminUserScopesSelector
};
