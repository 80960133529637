import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { errorReportActions } from './error-report.actions';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { ErrorReportService } from '../../http/error-report.service';
import { clientLogger } from '../../client-logger';
import { userNgrxActions } from '@ehs-ngrx/user-ngrx';
import { ErrorReportFacade } from './error-report.facade';
import { adminCoreNgrxActions } from '@ehs-ngrx/admin-core-ngrx';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportEffects {
  constructor(
    private actions$: Actions,
    private errorReportService: ErrorReportService,
    private errorReportFacade: ErrorReportFacade
  ) {}

  // TODO: Add action/prompt for re-try
  reportError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(errorReportActions.report),
      mergeMap((action) => {
        return this.errorReportService.reportError(action).pipe(
          map(() => errorReportActions.reportSuccess()),
          catchError(() => [errorReportActions.reportFailed()])
        );
      })
    )
  );

  logError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(errorReportActions.report),
        tap((action) => clientLogger.error('[ReportError]', action))
      ),
    { dispatch: false }
  );

  /**
   * Trigger this effect when userNgrx.loginAsUser returns failed.
   * TODO: Remove when admin page has been created
   */
  userNgrxLoginAsUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          userNgrxActions.loginAsUser.failed,
          userNgrxActions.resetPassword.failed,
          userNgrxActions.verifyEmail.failed,
          userNgrxActions.unlockAccount.failed,
          userNgrxActions.moveUserCompany.failed,
          userNgrxActions.removeUser.failed,
          userNgrxActions.flagRemoveUser.failed,
          userNgrxActions.createWalkin.failed,
          adminCoreNgrxActions.addAdmin.failed,
          adminCoreNgrxActions.removeAdmin.failed,
          adminCoreNgrxActions.update.failed
        ),
        tap((action) =>
          this.errorReportFacade.create({ err: action.payload.error, action })
        )
      ),
    { dispatch: false }
  );
}
