import { ErrorHandler, Injectable } from '@angular/core';
import { clientLogger } from './client-logger';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(err: any) {
    clientLogger.error('[GlobalErrorHandler] caught global error', err);
  }
}
