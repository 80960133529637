import {
  AdminUser,
  AdminUserSearchForm,
  CommonResponse,
  OnsiteRegistrationType,
  PartialUser,
  User,
  UserRegistration,
  UserWithVerified
} from '@common';
import { createAction, props } from '@ngrx/store';

export const adminUserActions = {
  setUser: createAction(
    '[ADMIN_USER] SET_USER',
    props<{
      user: User;
    }>()
  ),
  setMultipleUsers: createAction(
    '[ADMIN_USER] SET_MULTIPLE_USERS',
    props<{
      users: User[];
    }>()
  ),
  listUsers: createAction(
    '[ADMIN_USER] LIST_USERS',
    props<{
      search: AdminUserSearchForm;
    }>()
  ),
  listUsersSuccess: createAction(
    '[ADMIN_USER] LIST_USERS_SUCCESS',
    props<{
      users: User[];
    }>()
  ),
  listUsersFailed: createAction(
    '[ADMIN_USER] LIST_USERS_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),
  clearUsers: createAction('[ADMIN_USER] CLEAR_USERS'),
  getUserFromUnique: createAction(
    '[ADMIN_USER] GET_USER_FROM_UNIQUE',
    props<{
      user: Pick<User, 'lastName' | 'birthDay' | 'ssn'>;
    }>()
  ),
  getUserFromUniqueSuccess: createAction(
    '[ADMIN_USER] GET_USER_FROM_UNIQUE_SUCCESS',
    props<{
      user: User;
    }>()
  ),
  getUserFromUniqueFailed: createAction(
    '[ADMIN_USER] GET_USER_FROM_UNIQUE_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),
  loginAsUser: createAction(
    '[ADMIN_USER] LOGIN_AS_USER',
    props<{
      /**
       * The id of the user
       */
      user: string;
    }>()
  ),
  loginAsUserSuccess: createAction(
    '[ADMIN_USER] LOGIN_AS_USER_SUCCESS',
    props<{
      user: User;
    }>()
  ),
  loginAsUserFailed: createAction(
    '[ADMIN_USER] LOGIN_AS_USER_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),
  passwordReset: createAction(
    '[ADMIN_USER] PASSWORD_RESET',
    props<{
      /**
       * The _id of the user
       */
      userId: string;
      pwd: string;
    }>()
  ),
  passwordResetSuccess: createAction('[ADMIN_USER] PASSWORD_RESET_SUCCESS'),
  passwordResetFailed: createAction('[ADMIN_USER] PASSWORD_RESET_FAILED'),

  createWalkin: createAction(
    '[ADMIN_USER] CREATE_WALKIN',
    props<{
      user: PartialUser;
      eventLocation: string;
      onsiteTypes: OnsiteRegistrationType[];
    }>()
  ),
  createWalkinSuccess: createAction(
    '[ADMIN_USER] CREATE_WALKIN_SUCCESS',
    props<{
      user: User;
      userRegistration: UserRegistration;
    }>()
  ),
  createWalkinFailed: createAction('[ADMIN_USER] CREATE_WALKIN_FAILED'),

  verifyEmail: createAction(
    '[ADMIN_USER] VERIFY_EMAIL',
    props<{
      /**
       * The _id of the user
       */
      userId: string;
    }>()
  ),
  verifyEmailSuccess: createAction('[ADMIN_USER] VERIFY_EMAIL_SUCCESS'),
  verifyEmailFailed: createAction('[ADMIN_USER] VERIFY_EMAIL_FAILED'),

  updateUser: createAction(
    '[ADMIN_USER] UPDATE_USER',
    props<{
      user: Partial<User>;
    }>()
  ),
  updateUserSuccess: createAction('[ADMIN_USER] UPDATE_USER_SUCCESS'),
  updateUserFailed: createAction('[ADMIN_USER] UPDATE_USER_FAILED'),

  getUserById: createAction(
    '[ADMIN_USER] GET_USER_BY_ID',
    props<{
      /**
       * The _id of the user
       */
      userId: string;
    }>()
  ),
  getUserByIdSuccess: createAction(
    '[ADMIN_USER] GET_USER_BY_ID_SUCCESS',
    props<{
      user: UserWithVerified;
    }>()
  ),
  getUserByIdFailed: createAction(
    '[ADMIN_USER] GET_USER_BY_ID_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),
  getUserByUsername: createAction(
    '[ADMIN_USER] GET_USER_BY_USERNAME',
    props<{
      /**
       * The username of the user
       */
      username: string;
    }>()
  ),
  getUserByUsernameSuccess: createAction(
    '[ADMIN_USER] GET_USER_BY_USERNAME_SUCCESS',
    props<{
      user: User;
    }>()
  ),
  getUserByUsernameFailed: createAction(
    '[ADMIN_USER] GET_USER_BY_USERNAME_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),

  logoutUser: createAction(
    '[ADMIN_USER] LOGOUT_USER',
    props<{
      /**
       * This is passed along just so we can re-apply it in the state
       */
      user: AdminUser;
    }>()
  ),
  logoutUserSuccess: createAction(
    '[ADMIN_USER] LOGOUT_USER_SUCCESS',
    props<{
      user: AdminUser;
    }>()
  ),
  logoutUserFailed: createAction('[ADMIN_USER] LOGOUT_USER_FAILED'),

  unlockAccount: createAction(
    '[ADMIN_USER] UNLOCK_ACCOUNT',
    props<{
      user: string;
    }>()
  ),
  unlockAccountSuccess: createAction(
    '[ADMIN_USER] UNLOCK_ACCOUNT_SUCCESS',
    props<{
      user: User;
    }>()
  ),
  unlockAccountFailed: createAction(
    '[ADMIN_USER] UNLOCK_ACCOUNT_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),

  flagRemoveUser: createAction(
    '[ADMIN_USER] FLAG_REMOVE_USER',
    props<{
      user: User;
    }>()
  ),
  flagRemoveUserSuccess: createAction(
    '[ADMIN_USER] FLAG_REMOVE_USER_SUCCESS',
    props<{
      user: User;
    }>()
  ),
  flagRemoveUserFailed: createAction(
    '[ADMIN_USER] FLAG_REMOVE_USER_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),

  removeUser: createAction(
    '[ADMIN_USER] REMOVE_USER',
    props<{
      user: User;
    }>()
  ),
  removeUserSuccess: createAction(
    '[ADMIN_USER] REMOVE_USER_SUCCESS',
    props<{
      user: User;
    }>()
  ),
  removeUserFailed: createAction(
    '[ADMIN_USER] REMOVE_USER_FAILED',
    props<{
      err: CommonResponse;
    }>()
  ),

  mergeUser: createAction(
    '[ADMIN_USER] MERGE_USER',
    props<{
      mergePrimaryUser: User | PartialUser;
      mergeOrphanedUser: User | PartialUser;
    }>()
  ),
  mergeUserSuccess: createAction(
    '[ADMIN_USER] MERGE_USER_SUCCESS',
    props<{
      user: User;
    }>()
  ),
  mergeUserFailed: createAction('[ADMIN_USER] MERGE_USER_FAILED'),

  moveUserCompany: createAction(
    '[ADMIN_USER] MERGE_USER_COMPANY',
    props<{
      user: User;
      company: string;
    }>()
  ),
  moveUserCompanySuccess: createAction(
    '[ADMIN_USER] MERGE_USER_COMPANY_SUCCESS',
    props<{
      user: User;
    }>()
  ),
  moveUserCompanyFailed: createAction(
    '[ADMIN_USER] MERGE_USER_COMPANY_FAILED',
    props<{
      err: CommonResponse;
    }>()
  )
};
