import { DashboardWidgetEnum } from '../constants/dashboard-widget-types';
import { Company } from '../models/company';

export class DashboardUtil {
  /**
   * Pushes widgets with position values in a new order before
   * the rest are pushed in the default order. Returns updated
   * set of flags to avoid duplicate tiles.
   */
  public static organizeTilesByCompanySettings(params: {
    company: Company;
    flags: Partial<{
      isMobile: boolean;
      showIncentives: boolean;
      showUserReg: boolean;
      showAddedReg: boolean;
      showTestResults: boolean;
      showCOVIDVaccinationCard: boolean;
      showFluVaccination: boolean;
      showHra: boolean;
      showHealthPrograms: boolean;
      showEmmy: boolean;
      showCoaching: boolean;
      showResources: boolean;
      showReimbursements: boolean;
    }>;
  }): {
    updatedFlags: Partial<{
      isMobile: boolean;
      showIncentives: boolean;
      showUserReg: boolean;
      showAddedReg: boolean;
      showTestResults: boolean;
      showCOVIDVaccinationCard: boolean;
      showFluVaccination: boolean;
      showHra: boolean;
      showHealthPrograms: boolean;
      showEmmy: boolean;
      showCoaching: boolean;
      showResources: boolean;
      showReimbursements: boolean;
      showProfile: boolean;
    }>;
    shownWidgets: DashboardWidgetEnum[];
  } {
    const { company, flags } = params;
    // Always show the profile widget...

    const shownWidgets: DashboardWidgetEnum[] = [];

    if (!flags) {
      return { updatedFlags: { showProfile: true }, shownWidgets };
    }

    const updatedFlags = {
      ...flags,
      showProfile: true
    };

    if (!company?.dashboardWidgetOrder?.length) {
      return { updatedFlags, shownWidgets };
    }

    const order = [...company.dashboardWidgetOrder];

    // Sorting in case admin doesn't do so during setup
    order.sort(
      (currentWidget, nextWidget) =>
        currentWidget.position - nextWidget.position
    );

    order.forEach((widget) => {
      switch (widget.widget) {
        case DashboardWidgetEnum.USER_REGISTRATION: {
          if (updatedFlags.showUserReg) {
            shownWidgets.push(DashboardWidgetEnum.USER_REGISTRATION);
            updatedFlags.showUserReg = false;
          }
          break;
        }
        case DashboardWidgetEnum.ADDED_USER_REGISTRATION: {
          if (updatedFlags.showAddedReg) {
            shownWidgets.push(DashboardWidgetEnum.ADDED_USER_REGISTRATION);
            updatedFlags.showAddedReg = false;
          }
          break;
        }
        case DashboardWidgetEnum.TEST_RESULTS: {
          if (updatedFlags.showTestResults) {
            shownWidgets.push(DashboardWidgetEnum.TEST_RESULTS);
            updatedFlags.showTestResults = false;
          }
          break;
        }
        case DashboardWidgetEnum.INCENTIVES: {
          if (updatedFlags.showIncentives) {
            shownWidgets.push(DashboardWidgetEnum.INCENTIVES);
            shownWidgets.push(DashboardWidgetEnum.INCENTIVES_EMPOWERMENT_PLAN);
            shownWidgets.push(DashboardWidgetEnum.INCENTIVES_CHALLENGES);
            updatedFlags.showIncentives = false;
          }
          break;
        }
        case DashboardWidgetEnum.PROFILE: {
          shownWidgets.push(DashboardWidgetEnum.PROFILE);
          updatedFlags.showProfile = false;
          break;
        }
        case DashboardWidgetEnum.FLU_VACCINATION: {
          if (updatedFlags.showFluVaccination) {
            shownWidgets.push(DashboardWidgetEnum.FLU_VACCINATION);
            updatedFlags.showFluVaccination = false;
          }
          break;
        }
        case DashboardWidgetEnum.COVID_VACCINATION_CARD: {
          if (updatedFlags.showCOVIDVaccinationCard) {
            shownWidgets.push(DashboardWidgetEnum.COVID_VACCINATION_CARD);
            updatedFlags.showCOVIDVaccinationCard = false;
          }
          break;
        }
        case DashboardWidgetEnum.HRA: {
          if (updatedFlags.showHra) {
            shownWidgets.push(DashboardWidgetEnum.HRA);
            updatedFlags.showHra = false;
          }
          break;
        }
        case DashboardWidgetEnum.HEALTH_PROGRAMS: {
          if (updatedFlags.showHealthPrograms) {
            shownWidgets.push(DashboardWidgetEnum.HEALTH_PROGRAMS);
            updatedFlags.showHealthPrograms = false;
          }
          break;
        }
        case DashboardWidgetEnum.COACHING: {
          if (updatedFlags.showCoaching) {
            shownWidgets.push(DashboardWidgetEnum.COACHING);
            updatedFlags.showCoaching = false;
          }
          break;
        }
        case DashboardWidgetEnum.EMMY: {
          if (updatedFlags.showEmmy) {
            shownWidgets.push(DashboardWidgetEnum.EMMY);
            updatedFlags.showEmmy = false;
          }
          break;
        }
        case DashboardWidgetEnum.RESOURCES: {
          if (updatedFlags.showResources) {
            shownWidgets.push(DashboardWidgetEnum.RESOURCES);
            updatedFlags.showResources = false;
          }
          break;
        }
        case DashboardWidgetEnum.REIMBURSEMENTS: {
          if (updatedFlags.showReimbursements) {
            shownWidgets.push(DashboardWidgetEnum.REIMBURSEMENTS);
            updatedFlags.showReimbursements = false;
          }
          break;
        }
        default:
          break;
      }
    });

    return { updatedFlags, shownWidgets };
  }
}
