import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsConfirmDialogComponent } from './ehs-confirm-dialog.component';
import { EhsConfirmDialogService } from './ehs-confirm-dialog.service';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [EhsConfirmDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    // Angular material
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  exports: [EhsConfirmDialogComponent],
  providers: [EhsConfirmDialogService]
})
export class EhsConfirmDialogModule {}
