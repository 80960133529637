<div fxLayout="column"
     fxLayoutGap="8px"
     *ngIf="companySecurity && company">

  <div fxLayout="row"
       fxLayoutAlign="space-between start">
    <div>
      <div>EHS Default Login</div>
      <div style="color: #666"
           class="mat-caption">Enable Login with EHS Username/Password
      </div>
    </div>

    <mat-slide-toggle color="primary"
                      #ehsLoginToggle
                      [checked]="!companySecurity?.disableEhsSignIn"
                      (change)="toggleEhsSignIn($event, ehsLoginToggle)"></mat-slide-toggle>
  </div>

  <ng-container *ngIf="!companySecurity?.disableEhsSignIn">
    <div fxLayout="row"
         fxLayoutAlign="space-between center"
         fxLayoutGap="8px">
      <div style="width: 100%;">
        <div>Sign in Button Label</div>
        <input matInput
               class="domain-field"
               [value]="companySecurity?.ehsBtnLabel || ''"
               (change)="updateLabel({input: ehsBtnLabel, property: 'ehsBtnLabel'})"
               #ehsBtnLabel
               style="width: 90%;"
               type="text"
               placeholder="Sign in">
      </div>
      <div style="width: 100%;">
        <div>Help Text (shown on top of Sign in button)</div>
        <input matInput
               [value]="companySecurity?.ehsHelpText || ''"
               (change)="updateLabel({input: ehsHelpText, property: 'ehsHelpText'})"
               class="domain-field"
               style="width: 90%;"
               #ehsHelpText
               type="text"
               placeholder="Please sign in with your credentials">
      </div>

    </div>
  </ng-container>

  <div fxLayout="row"
       fxLayoutAlign="space-between center">
    <div>
      <div>Microsoft Login</div>
      <div style="color: #666"
           class="mat-caption">Enable Signing in with Microsoft
      </div>
    </div>
    <mat-slide-toggle color="primary"
                      #ehsMsToggle
                      [checked]="(companySecurity?.signInOptions || []).includes(companySignInOptions.MICROSOFT)"
                      (change)="toggleSsoSignIn({event: $event, option: companySignInOptions.MICROSOFT, ehsToggle: ehsMsToggle})"></mat-slide-toggle>
  </div>

  <ng-container *ngIf="(companySecurity?.signInOptions || []).includes(companySignInOptions.MICROSOFT)">
    <div fxLayout="row"
         fxLayoutAlign="space-between center"
         fxLayoutGap="8px">
      <div style="width: 100%;">
        <div>Microsoft Button Label</div>
        <input matInput
               class="domain-field"
               [value]="companySecurity?.microsoftBtnLabel || ''"
               (change)="updateLabel({input: microsoftBtnLabel, property: 'microsoftBtnLabel'})"
               #microsoftBtnLabel
               style="width: 90%;"
               type="text"
               placeholder="Sign in with Microsoft">
      </div>
      <div style="width: 100%;">
        <div>Microsoft Help Text (shown on top of field)</div>
        <input matInput
               [value]="companySecurity?.microsoftHelpText || ''"
               (change)="updateLabel({input: microsoftHelpText, property: 'microsoftHelpText'})"
               class="domain-field"
               style="width: 90%;"
               #microsoftHelpText
               type="text"
               placeholder="Please sign in with your company credentials">
      </div>

    </div>
  </ng-container>

  <div fxLayout="row"
       fxLayoutAlign="space-between center">
    <div>
      <div>Google Login</div>
      <div style="color: #666"
           class="mat-caption">Enable Signing in with Google
      </div>
    </div>
    <mat-slide-toggle color="primary"
                      #ehsGoogleToggle
                      [checked]="(companySecurity?.signInOptions || []).includes(companySignInOptions.GOOGLE)"
                      (change)="toggleSsoSignIn({event: $event, option: companySignInOptions.GOOGLE, ehsToggle: ehsGoogleToggle})"></mat-slide-toggle>
  </div>
  <ng-container *ngIf="(companySecurity?.signInOptions || []).includes(companySignInOptions.GOOGLE)">
    <div fxLayout="row"
         fxLayoutAlign="space-between center"
         fxLayoutGap="8px">
      <div style="width: 100%;">
        <div>Google Button Label</div>
        <input matInput
               class="domain-field"
               [value]="companySecurity?.googleBtnLabel || ''"
               (change)="updateLabel({input: googleBtnLabel, property: 'googleBtnLabel'})"
               #googleBtnLabel
               style="width: 90%;"
               type="text"
               placeholder="Sign in with Google">
      </div>
      <div style="width: 100%;">
        <div>Google Help Text (shown on top of field)</div>
        <input matInput
               class="domain-field"
               [value]="companySecurity?.googleHelpText || ''"
               (change)="updateLabel({input: googleHelpText, property: 'googleHelpText'})"
               style="width: 90%;"
               #googleHelpText
               type="text"
               placeholder="Please sign in with your company credentials">
      </div>

    </div>
  </ng-container>

  <div>
    <div>Whitelist Domains</div>
    <div style="color: #666"
         class="mat-caption">The list of domains that are allowed to log in
      using the SSO options
    </div>
  </div>

  <div *ngFor="let domain of companySecurity?.whitelistDomains || []">
    <div fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="8px">
      <button type="button"
              style="max-height: 40px;"
              mat-icon-button
              (click)="deleteDomain(domain)">
        <mat-icon style="font-size: 18px;">
          remove_circle_outline</mat-icon>
      </button>
      <div style="padding: 4px 0;">{{domain}}</div>
    </div>
  </div>

  <div fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="8px">
    <input matInput
           class="domain-field"
           #domainField
           type="text"
           placeholder="New Domain">
    <button type="button"
            mat-button
            color="primary"
            (click)="addDomain(domainField.value); domainField.value = ''">+ Add
      Domain</button>
  </div>

  <div *ngIf="showForceMFA"
       fxLayout="row"
       fxLayoutAlign="space-between start">
    <div>
      <div>Force User MFA</div>
      <div style="color: #666"
           class="mat-caption">Force users in company to have atleast one MFA option
      </div>
    </div>

    <mat-slide-toggle color="primary"
                      #ehsForceMFAToggle
                      [checked]="companySecurity?.forceMFA"
                      (change)="toggleForceMFADialog($event, ehsForceMFAToggle)"></mat-slide-toggle>
  </div>
</div>