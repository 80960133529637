import { AbstractControl, ValidatorFn } from '@angular/forms';

export function matchValidator(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (abstractControl: AbstractControl, errorMessage?: string) => {
    const control = abstractControl.get(controlName);
    const matchingControl = abstractControl.get(matchingControlName);

    if (
      matchingControl.pristine ||
      (matchingControl?.errors && matchingControl.errors?.['matchValidator'])
    ) {
      return null;
    }

    if (control?.value !== matchingControl?.value) {
      const error = { matchValidator: errorMessage || 'Fields do not match' };

      matchingControl!.setErrors(error);

      return error;
    } else {
      matchingControl!.setErrors(null);

      return null;
    }
  };
}
