<h1 mat-dialog-title
    class="warning">Warning!</h1>
<mat-dialog-content>
  <p>Disabling two-step verification significantly increases your risk of unauthorized account access.</p>
  <p>Are you sure you want to proceed and remove this important security measure?</p>
</mat-dialog-content>
<mat-dialog-actions fxLayoutGap="16px"
                    fxLayoutAlign="space-around">
  <button type="button"
          mat-button
          [mat-dialog-close]="true">Yes</button>
  <button type="button"
          mat-button
          [mat-dialog-close]="false">Cancel</button>
</mat-dialog-actions>
<mat-dialog-content>
  <p style="font-size: 12px;">Still need assistance? 866.367.6974 | support&#64;empowerhealthservices.com</p>
</mat-dialog-content>