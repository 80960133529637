/**
 * Different pay types, for who pays.
 *
 * Employer means the Employer will pay.
 * Insurance means the user's insurance will cover.
 * Self pay means the end user will need to enter their insurance information and pay
 * using square.
 *
 * Generally:
 * Employer will MAYBE be selected with INSURANCE, so Employer doesn't have to pay.
 * If insurance is selected, we "show" square
 *
 */
export enum PayType {
  EMPLOYER = 'employer',
  INSURANCE = 'insurance',
  SELF_PAY = 'self_pay'
}

/**
 * Map of pay-type to user friendly names.
 */
export const payTypeNames: { [key in PayType]: string } = {
  [PayType.EMPLOYER]: 'Employer',
  [PayType.INSURANCE]: 'Insurance',
  [PayType.SELF_PAY]: 'Self Pay'
};
