import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { eventLocationActions } from './event-location.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { EventLocationHttpService } from '../../http/event-location-http.service';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { AdminEventLocationHttpService } from '../../http/admin/admin-event-location-http.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class EventLocationEffects {
  constructor(
    private actions$: Actions,
    private errorReportFacade: ErrorReportFacade,
    private eventLocationHttp: EventLocationHttpService,

    private adminEventLocationHttp: AdminEventLocationHttpService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationActions.get),
      mergeMap((action) =>
        this.eventLocationHttp.get(action.id).pipe(
          map((entity) => eventLocationActions.getSuccess({ entity })),
          catchError((err) => [
            eventLocationActions.getFailed(action),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationActions.list),
      mergeMap((action) =>
        this.eventLocationHttp.list(action.query).pipe(
          map((entities) => eventLocationActions.listSuccess({ entities })),
          catchError((err) => [
            eventLocationActions.listFailed(),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  listEventLocationsByCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationActions.listEventLocationByCompany),
      mergeMap((action) =>
        this.adminEventLocationHttp.listEventLocations(action.companyId).pipe(
          map((entities) =>
            eventLocationActions.listEventLocationByCompanySuccess({ entities })
          ),
          catchError((err: HttpErrorResponse) => [
            eventLocationActions.listEventLocationByCompanyFailed({
              err: err.error
            }),
            this.errorReportFacade.create({ err: err.error, action })
          ])
        )
      )
    )
  );

  getAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationActions.getAsAdmin),
      mergeMap((action) =>
        this.adminEventLocationHttp.get(action.id).pipe(
          map((entity) => eventLocationActions.getAsAdminSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            eventLocationActions.getAsAdminFailed({ err: err.error }),
            this.errorReportFacade.create({ err: err.error, action })
          ])
        )
      )
    )
  );
}
