<div gdColumns="1fr"
     gdColumns.md="1fr"
     gdColumns.xs="1fr"
     gdGap="8px"
     [ngModelGroup]="modelGroup">
  <mat-form-field *ngIf="insuranceProviders">

    <mat-label>Insurance Provider</mat-label>
    <mat-select name="insuranceProvider"
                #insuranceProviderInput="ngModel"
                (change)="onChange()"
                [(ngModel)]="userInsuranceFormData.insuranceProvider"
                [compareWith]="compareInsuranceProvidersFn"
                [required]="required">
      <mat-option *ngIf="!required"
                  [value]="undefined">
        <!-- a blank option is available only if the field isn't required-->
      </mat-option>
      <mat-option *ngFor="let insuranceProvider of insuranceProviders"
                  [value]="insuranceProvider">
        {{ insuranceProvider.shortName || insuranceProvider.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="insuranceProviderInput.hasError('required')">
      This field is required
    </mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="showInsuranceTypes">

    <mat-label>Insurance Type</mat-label>
    <mat-select name="insuranceType"
                #insuranceTypeInput="ngModel"
                (change)="onChange()"
                [(ngModel)]="userInsuranceFormData.insuranceType"
                [required]="required">
      <mat-option *ngIf="!required"
                  [value]="undefined">
        <!-- a blank option is available only if the field isn't required-->
      </mat-option>
      <mat-option *ngFor="let insuranceType of insuranceTypes"
                  [value]="insuranceType">
        {{getInsuranceName(insuranceType)}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="insuranceTypeInput.hasError('required')">
      This field is required
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="showPolicyNumber">
    <mat-label>Identification / Policy Number</mat-label>
    <input type="text"
           matInput
           name="policyNumber"
           #policyNumberInput="ngModel"
           (change)="onChange()"
           [(ngModel)]="userInsuranceFormData.policyNumber"
           [required]="required" />
    <mat-error *ngIf="policyNumberInput.hasError('required')">
      This field is required
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="showGroupNumber">
    <mat-label>Group Number</mat-label>
    <input type="text"
           matInput
           name="groupNumber"
           #groupNumberInput="ngModel"
           (change)="onChange()"
           [(ngModel)]="userInsuranceFormData.groupNumber"
           [required]="required" />
    <mat-error *ngIf="groupNumberInput.hasError('required')">
      This field is required
    </mat-error>
  </mat-form-field>
</div>