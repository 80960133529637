import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { EntitySelectionActions } from './entity-selection-actions';
import { EntitySelectionState } from './entity-selection-state';

export const entitySelectionReducerFactory = <
  State extends EntitySelectionState<DocumentKey>,
  DocumentKey extends string,
  Prefix extends string
>({
  entitySelectionActions
}: {
  entitySelectionActions: EntitySelectionActions<DocumentKey, Prefix>;
}): Array<ReducerTypes<State, ActionCreator[]>> => [
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(entitySelectionActions.selectionClear, (state) => ({
    ...state,
    selected: undefined
  })),
  on(entitySelectionActions.select, (state, { id }) => ({
    ...state,
    selected: state.selected ? [...state.selected, id] : [id]
  })),
  on(entitySelectionActions.unselect, (state, { id }) => ({
    ...state,
    selected: state.selected ? state.selected.filter((x) => x !== id) : []
  })),
  on(entitySelectionActions.selectMany, (state, { ids }) => ({
    ...state,
    selected: state.selected
      ? ids.reduce(
          (acc, id) => (acc.includes(id) ? acc : [...acc, id]),
          [] as DocumentKey[]
        )
      : ids
  })),
  on(entitySelectionActions.unselectMany, (state, { ids }) => ({
    ...state,
    selected: state.selected
      ? state.selected.filter((x) => !ids.includes(x))
      : []
  })),
  on(entitySelectionActions.selectionToggle, (state, { id }) => ({
    ...state,
    selected: state.selected
      ? state.selected.includes(id)
        ? state.selected.filter((x) => x !== id)
        : [...state.selected, id]
      : [id]
  }))
];
