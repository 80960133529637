import { Injectable } from '@angular/core';
import { ProviderHealthUploadsColumn } from './provider-health-uploads-column';

@Injectable({
  providedIn: 'root'
})
export class EhsProviderHealthUploadsTableService {
  getColumns(): ProviderHealthUploadsColumn[] {
    return [
      {
        title: 'Name',
        prop: 'name',
        width: '230px'
      },
      {
        title: 'Email',
        prop: 'email',
        width: '150px'
      },
      {
        title: 'Company',
        prop: 'companyName',
        width: '200px'
      },
      {
        title: 'Upload Date',
        prop: 'updatedAt',
        width: '140px'
      },
      {
        title: 'Deadline',
        prop: 'returnBy',
        width: '140px'
      },
      {
        title: 'Files',
        prop: 'uploadFiles',
        width: '60px'
      },
      {
        title: 'Status',
        prop: 'status',
        width: '140px'
      }
    ];
  }
}
