import { DbDocument, getId } from '@common';
import { EntityState } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { EntitySelectionState } from './entity-selection-state';

/**
 * Factory function used to generate selectors related to
 * "entity-selection" state.
 */
export const entitySelectionSelectorFactory = <
  Document extends DbDocument<DocumentKey>,
  DocumentKey extends string,
  AppState,
  DocumentState extends EntityState<Document> &
    EntitySelectionState<DocumentKey>
>({
  featureSelector,
  entitiesArraySelector
}: {
  featureSelector: MemoizedSelector<AppState, DocumentState>;
  entitiesArraySelector: MemoizedSelector<AppState, Document[]>;
}) => {
  const selectedSelector = createSelector(
    featureSelector,
    (state) => state.selected || []
  );

  return {
    /**
     * Selector that will return the list of currently selected entities.
     */
    selectedSelector,
    /**
     * Selector that returns if there are any selected at all.
     */
    hasSelectedSelector: createSelector(
      selectedSelector,
      (selected) => !!(selected && selected.length > 0)
    ),
    /**
     * Selector that builds off of the entity-state, and returns the entities
     * currently selected.
     */
    selectedEntitiesArrSelector: createSelector(
      entitiesArraySelector,
      selectedSelector,
      (entities, selected) =>
        selected
          ? entities.filter((entity) =>
              (selected || []).includes(getId(entity) as DocumentKey)
            )
          : []
    ),
    /**
     * The amount of items selected. This can be used as a basis elsewhere to
     * determine if "everything" is selected or not.
     */
    selectedLengthSelector: createSelector(
      selectedSelector,
      (selected) => selected?.length || 0
    ),
    /**
     * Selector factory that can be used elsewhere to know
     * if a given element is selected.
     */
    isSelectedSelectorFactory: (id: DocumentKey) =>
      createSelector(selectedSelector, (selected) => !!selected?.includes(id))
  };
};
