import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EhsUserMergeComponent } from './ehs-user-merge.component';

@NgModule({
  declarations: [EhsUserMergeComponent],
  imports: [
    CommonModule,

    // Angular Material
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [EhsUserMergeComponent]
})
export class EhsUserMergeModule {}
