<h1 mat-dialog-title>Verify Email: {{user?.email}}</h1>
<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="!user.emailVerified; else verified">
    <div>Are you sure you want to verify the email for
      the following user?</div>
  </ng-container>
  <ng-template #verified>
    <div style="background-color: #b3e5fc; padding: 4px; margin-bottom: 8px;">
      <span style="font-weight: 500; padding-right: 4px;">Note:</span>This
      email has already been verified.</div>
  </ng-template>
  <div style="width: 100%;"
       gdColumns="1fr 3fr">
    <div class="label">First Name</div>
    <div>{{user?.firstName}}</div>
    <div class="label">Last Name</div>
    <div>{{user?.lastName}}</div>
    <div class="label">Email</div>
    <div>{{user?.email}}</div>
    <div class="label">Birthday</div>
    <div>{{user?.birthDay | date: 'mediumDate'}}</div>
    <div class="label">SSN</div>
    <div>###-##-{{user?.ssn}}</div>
    <div class="label">Verified</div>
    <div>
      <ng-container *ngIf="user.emailVerified; else notVerified">
        <mat-icon style="color: green;">mark_email_read</mat-icon>
      </ng-container>
      <ng-template #notVerified>
        <mat-icon style="color: red;">error</mat-icon>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end"
                    fxLayoutGap="16px">
  <button type="button"
          class="grey-cancel"
          mat-button
          [mat-dialog-close]="false">
    {{ user.emailVerified ?  'Ok' : 'Cancel'}}
  </button>
  <ng-container *ngIf="!user?.emailVerified">
    <button type="button"
            color="primary"
            mat-button
            [mat-dialog-close]="true"
            cdkFocusInitial>
      Verify
    </button>
  </ng-container>
</mat-dialog-actions>
