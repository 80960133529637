/**
 * The list of races to show in the "race"
 * dropdown.
 */
export enum Races {
  AMERICAN_INDIAN_OR_ALASKAN_NATIVE = 'AMERICAN_INDIAN_OR_ALASKAN_NATIVE',
  ASIAN = 'ASAIN',
  BLACK_OR_AFRICAN_AMERICAN = 'BLACK_OR_AFRICAN_AMERICAN',
  // **Note** this isn't an option
  // HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  OTHER_RACE = 'OTHER_RACE',
  WHITE = 'WHITE',
  UNKNOWN = 'UNKNOWN'
}

/**
 * Spec:
 * https://docs.google.com/spreadsheets/d/11KsmQjqzBeuI_xW9hYVdGNVq_KHozYGmmLZ99nNzRy8/edit#gid=1198202020
 */
export interface RaceDef {
  /**
   * The enum code we use.
   */
  code: Races;
  /**
   * The description of this race, shown to the end user
   */
  description: string;
  /**
   * The official hl7 code
   */
  hl7Code: string;
  /**
   * The flat code
   */
  flatCode: string;
}
export const getRace = (race: Races) =>
  ((
    {
      [Races.AMERICAN_INDIAN_OR_ALASKAN_NATIVE]: {
        code: Races.AMERICAN_INDIAN_OR_ALASKAN_NATIVE,
        description: 'American Indian or Alaskan Native',
        hl7Code: '1002-5',
        flatCode: 'I'
      },
      [Races.ASIAN]: {
        code: Races.ASIAN,
        description: 'Asian',
        hl7Code: '2028-9',
        flatCode: 'A'
      },
      [Races.BLACK_OR_AFRICAN_AMERICAN]: {
        code: Races.BLACK_OR_AFRICAN_AMERICAN,
        description: 'Black or African American',
        hl7Code: '2054-5',
        flatCode: 'B'
      },
      // Not an option for some reason
      // [Races.HISPANIC_OR_LATINO]: {
      //   code: Races.HISPANIC_OR_LATINO,
      //   description: 'Hispanic or Latino',
      //   hl7Code: '',
      //   flatCode: ''
      // },
      [Races.NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER]: {
        code: Races.NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER,
        description: 'Native Hawaiian or Other Pacific Islander',
        hl7Code: '2076-8',
        flatCode: 'A'
      },
      [Races.OTHER_RACE]: {
        code: Races.OTHER_RACE,
        description: 'Other Race',
        hl7Code: '2131-1',
        flatCode: 'O'
      },
      [Races.WHITE]: {
        code: Races.WHITE,
        description: 'White',
        hl7Code: '2106-3',
        flatCode: 'W'
      },
      [Races.UNKNOWN]: {
        code: Races.UNKNOWN,
        description: 'Unknown',
        hl7Code: '',
        flatCode: 'U'
      }
    } as Record<Races, RaceDef>
  )[race]);
