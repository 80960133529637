import { createAction, props } from '@ngrx/store';
import { UserResult, CommonResponse } from '@common';

export const userResultActions = {
  list: createAction('[USER_REG] LIST'),
  listSuccess: createAction(
    '[USER_REG] LIST_SUCCESS',
    props<{ entities: UserResult[] }>()
  ),
  listFailed: createAction(
    '[USER_REG] LIST_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),
  get: createAction(
    '[USER_RESULT] GET',
    props<{
      userResultId: string;
    }>()
  ),
  getSuccess: createAction(
    '[USR_RESULT] GET_SUCCESS',
    props<{
      entity: UserResult;
    }>()
  ),
  getFailed: createAction(
    '[USER_RESULT] GET_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  getByUserReg: createAction(
    '[USER_RESULT] GET_BY_USER_REG',
    props<{
      userRegId: string;
    }>()
  ),
  getByUserRegSuccess: createAction(
    '[USER_RESULT] GET_BY_USER_REG_SUCCESS',
    props<{
      entity: UserResult;
    }>()
  ),
  getByUserRegFailed: createAction(
    '[USER_RESULT] GET_BY_USER_REG_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  set: createAction(
    '[USER_RESULT] SET',
    props<{
      userResult: UserResult;
    }>()
  ),

  fax: createAction(
    '[USER_RESULT] FAX',
    props<{
      userResult: string;
      phone: string;
      physicianName: string;
    }>()
  ),
  faxSuccess: createAction(
    '[USER_RESULT] FAX_SUCCESS',
    props<{
      res: CommonResponse;
    }>()
  ),
  faxFailed: createAction(
    '[USER_RESULT] FAX_FAILED',
    props<{
      err: CommonResponse;
    }>()
  )
};
