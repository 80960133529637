import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsOptOutMFADialogComponent } from './ehs-opt-out-mfa-dialog.component';

@Injectable()
export class EhsOptOutMFADialogService {
  constructor(private matDialog: MatDialog) {}

  public open(): Observable<boolean> {
    return this.matDialog
      .open(EhsOptOutMFADialogComponent, {
        width: '550px'
      })
      .afterClosed();
  }
}
