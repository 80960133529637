import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EventService,
  HraResult,
  OnsiteUserRegistration,
  UserResult
} from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HraResultHttpService {
  constructor(private http: HttpClient) {}

  public list(params: { includeService?: boolean }): Observable<HraResult[]> {
    const includeService = !!params?.includeService;
    let queryParams = new HttpParams();

    if (includeService) {
      queryParams = queryParams.append('includeService', 'true');
    }

    return this.http.get<HraResult[]>('/api/v1/hra', {
      params: queryParams
    });
  }

  public get(id: string): Observable<HraResult> {
    return this.http.get<HraResult>(`/api/v1/hra/${id}`);
  }

  public create(hraResult: Partial<HraResult>): Observable<HraResult> {
    return this.http.post<HraResult>(`/api/v1/hra`, hraResult);
  }

  public getCurrent(): Observable<HraResult> {
    return this.http.get<HraResult>('/api/v1/hra/current');
  }

  public update(hraResult: HraResult): Observable<HraResult> {
    return this.http.put<HraResult>(
      `/api/v1/hra/${hraResult._id.toString()}`,
      hraResult
    );
  }

  public remove(id: string): Observable<HraResult> {
    return this.http.delete<HraResult>(`/api/v1/hra/${id}`);
  }

  public getRelatedHraData(userResultId: string): Observable<{
    eventService: EventService;
    userRegistration?: OnsiteUserRegistration;
    userResult?: UserResult;
  }> {
    return this.http.get<{
      eventService: EventService;
      userRegistration?: OnsiteUserRegistration;
      userResult?: UserResult;
    }>(`/api/v1/hra/${userResultId}/related`);
  }
}
