import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Optional
} from '@angular/core';
import { Company } from '@common';
import { EHS_COMPANY_LOGO_BASE_URL } from '../../shared/ehs-company-logo-base-url';

/**
 * This widget should be used in some situations where you just want an overview
 *
 */
@Component({
  selector: 'ehs-company-overview',
  templateUrl: './ehs-company-overview.component.html',
  styleUrls: ['./ehs-company-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsCompanyOverviewComponent {
  /**
   * The company we are to show.
   */
  @Input() company?: Company;

  /**
   * The calculated logo-url, leveraging the base url provided
   * by the injection token.
   * If not given we return an empty string
   */
  public get logoUrl() {
    const normalize = (baseUrl: string) => {
      if (baseUrl && typeof baseUrl === 'string') {
        if (baseUrl.endsWith('/')) {
          return baseUrl;
        }

        return baseUrl + '/';
      }

      return '';
    };

    if (!this.baseLogoUrlInject || !this.company?.logoUrl) {
      return '';
    }

    return normalize(this.baseLogoUrlInject) + this.company?.logoUrl;
  }

  constructor(
    @Inject(EHS_COMPANY_LOGO_BASE_URL)
    @Optional()
    private baseLogoUrlInject?: string
  ) {}
}
