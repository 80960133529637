import { User, UserRegistration, UserResult, UserVaccination } from '@common';

/**
 * The column type can be split into 2 different types. Props, which
 * can be used to represent properties between the user-registration,
 * user, and user-result. And actions, which can be used to manage
 * individual actions on a given user-registration
 */
export type EhsRegTableColumn<
  AbstractUserRegistration extends UserRegistration = UserRegistration
> = EhsRegTableColumnProp<AbstractUserRegistration> | EhsRegTableColumnAction;

/**
 * A column is used between the following components:
 * - ehs-reg-table
 * - ehs-reg-row
 * - ehs-reg-header
 *
 * Each to define a column for a given user-registration.
 */
export interface EhsRegTableColumnProp<
  AbstractUserRegistration extends UserRegistration = UserRegistration
> {
  /**
   * The width size of this column. Defaults to '200px'
   */
  width?: string;

  /**
   * The title of the column, displayed in the ehs-reg-header component.
   */
  columnTitle?: string;

  /**
   * The property we are to display for this user-registration.
   * Will be managed by the dedicated internal handler, if there is one.
   *
   * **note** the checkbox and actions properties are template
   * columns, used to manage the actual user-registration.
   *
   * **note** I'm realizing this is rather limiting, when it comes to
   * some specific types that overlap. Might re-think about this is handled.
   */
  prop:
    | keyof AbstractUserRegistration
    | keyof User
    | keyof UserResult
    | 'checkbox'
    // This connects back to the createdAt for the user-req.
    | 'userReqCreatedAt';
}

/**
 * Type-guard for column-props
 */
export const isColumnProp = <
  AbstractUserRegistration extends UserRegistration = UserRegistration
>(
  column: EhsRegTableColumn<AbstractUserRegistration>
): column is EhsRegTableColumnProp => column.prop !== 'actions';

/**
 * The "action" column can be defined with more information.
 * This doesn't have some of the properties for simplicity, such as flex, or
 * name. As these should never be given.
 */
export interface EhsRegTableColumnAction<
  AbstractUserRegistration extends UserRegistration = UserRegistration,
  ActionTypes extends string = string
> {
  /**
   * Providing action, means this column supports extra information,
   * to manage how the "actions" for the component work.
   */
  prop: 'actions';
  /**
   * The list of actions that can be shown for each user-registration.
   */
  actions: (params: {
    /**
     * The company id, this is provided for routing.
     */
    company: string;
    /**
     * The current user-registration
     */
    userRegistration: AbstractUserRegistration;
    /**
     * The relevant user-result, if there is one
     * for the current user-registration.
     */
    userResult?: UserResult;
    /**
     * The relevant user-vaccination, if there is one
     * for the current user-registration.
     */
    userVaccination?: UserVaccination;
  }) => Array<EhsRegTableColumnActionDef<ActionTypes>>;
}

/**
 * Type-guard for column-actions
 */
export const isColumnAction = <
  AbstractUserRegistration extends UserRegistration = UserRegistration
>(
  column: EhsRegTableColumn<AbstractUserRegistration>
): column is EhsRegTableColumnAction => column.prop === 'actions';

export interface EhsRegTableColumnActionDef<
  ActionTypes extends string = string
> {
  /**
   * The name of the action shown in the menu.
   */
  name: string;
  /**
   * The type of action that is to be emitted. This
   * will be emitted with the user-registration as a reference.
   * TODO: add support for a link instead
   */
  actionType: ActionTypes | 'link';

  /**
   * Route provided if the action type is link
   * **note** this could be made more type-safe but I'm lazy.
   */
  route?: unknown[];
  /**
   * Query params provided if the route type is link
   * **note** this could be made more type-safe but I'm lazy.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  routeQueryParams?: object;
}
