import { Action, ActionReducer } from '@ngrx/store';
import { authActions } from '../auth/auth.actions';
import { adminUserActions } from '../admin-user/admin-user.actions';

/**
 * Meta-reducer that removes all of the applications state within
 * the application when the user logs outs.
 * This clears **all** state if we see the logout action type
 */
export function logoutMetaReducer(reducer: ActionReducer<any>) {
  return (state: any, action: Action) => {
    const isLogoutAction = (
      [authActions.logout.type, adminUserActions.logoutUser.type] as string[]
    ).includes(action.type);

    if (isLogoutAction) {
      return reducer({}, action);
    }

    return reducer(state, action);
  };
}
