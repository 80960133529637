import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsUserFiltersComponent } from './ehs-user-filters.component';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BirthDayValidatorsModule } from '../../shared/birth-day-validators/birth-day-validators.module';

@NgModule({
  declarations: [EhsUserFiltersComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    BirthDayValidatorsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatAutocompleteModule
  ],
  exports: [EhsUserFiltersComponent]
})
export class EhsUserFiltersModule {}
