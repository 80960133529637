import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventService } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminEventServiceHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Returns the event-services for the given company
   * @param companyId the company to get the event-services for
   */
  public listEventServices(companyId: string): Observable<EventService[]> {
    return this.http.get<EventService[]>('api/v1/admin/service/list', {
      params: new HttpParams().append('company', companyId)
    });
  }

  /**
   * Release the event
   * TODO: move to event-location service
   */
  public releaseEvent(params: {
    /**
     * The event-location we are to release events
     * for
     */
    eventLocation: string;
    /**
     * The list of user ids to
     * release records for, or nothing
     * to release all records related to the given event location
     */
    users?: string[];
  }) {
    const { eventLocation, users } = params;

    // TODO: Update url maybe?
    return this.http.post<any>(`api/v1/admin/event/release/${eventLocation}`, {
      users
    });
  }

  /**
   * Gets a single event-service, for any company
   */
  public getAny(id: string): Observable<EventService> {
    return this.http.get<EventService>(`api/v1/admin/service/${id}`);
  }
}
