import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { EhsGwappsActionsModule } from '../ehs-gwapps-actions/ehs-gwapps-actions.module';
import { EhsGwappsCompanyComponent } from './ehs-gwapps-company.component';

@NgModule({
  declarations: [EhsGwappsCompanyComponent],
  imports: [
    CommonModule,
    RouterModule,

    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    // Child components
    EhsGwappsActionsModule
  ],
  exports: [EhsGwappsCompanyComponent]
})
export class EhsGwappsCompanyModule {}
