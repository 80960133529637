/**
 * Colors for display ranges, and graphs.
 * Defined within:
 * https://github.com/citent/ehs-customer-portal/issues/1227
 *
 * **note** this is the same as HraAnswerColors
 */
export enum UserTestRangeColors {
  GREEN = '#62bda4',
  YELLOW = '#ebd682',
  RED = '#d15858',
  // **Note** blue is only shown for "non-values"
  BLUE = '#25408f'
}
