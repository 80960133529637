export enum ServiceLabType {
  LAB_CORP = 'labcorp',
  QUEST = 'quest',
  // **Note** this actually has a space to match with
  // the gw-apps value
  HELENA_LABS = 'helena labs',
  EHS = 'ehs'
}

/**
 * Type-guard for service-lab-type
 */
export const isServiceLabType = (
  serviceLabType: ServiceLabType | string
): serviceLabType is ServiceLabType =>
  Object.values(ServiceLabType).includes(serviceLabType as ServiceLabType);
