import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tofr'
})
export class ToFrPipe implements PipeTransform {
  transform(columns: number): string {
    return new Array(columns || 1).fill('1fr').join(' ');
  }
}
