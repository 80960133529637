import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AdminGwNgrxState,
  ADMIN_GW_NGRX_STORE_KEY
} from './admin-gw-ngrx.state';

export const adminGwNgrxFeatureSelector =
  createFeatureSelector<AdminGwNgrxState>(ADMIN_GW_NGRX_STORE_KEY);

const loadingSelector = createSelector(
  adminGwNgrxFeatureSelector,
  (state) => !!state.loading?.length
);

export const adminGwNgrxSelectors = {
  loadingSelector
};
