<div gdColumns.xs="1fr"
     gdColumns.sm="1fr 1fr"
     gdColumns="1fr 1fr 1fr"
     gdGap="16px"
     *ngIf="user && columns && columns.length">
  <ng-container *ngFor="let column of columns">
    <div>
      <div class="label">{{column.title}}</div>

      <!-- code-base override -->
      <ng-container *ngIf="isOverride(column)">
        <div>
          <ng-container *ngIf="isFunction(column.override) else isValue">
            {{$any(column.override)({user, hideFalsy: this.hideFalsy})}}
          </ng-container>
          <ng-template #isValue>
            {{column.override}}
          </ng-template>
        </div>
      </ng-container>

      <!-- prop based -->
      <ng-container *ngIf="isProp(column)">
        <!-- template overrides -->
        <ng-container *ngIf="column.templateOverride else basicPropDisplay">
          <ng-container [ngSwitch]="column.prop">

            <ng-container *ngSwitchCase="'email'">
              <a [href]="'mailto:' + user[$any(column.prop)]"
                 target="_blank">
                {{ user[column.prop] }}
              </a>
            </ng-container>

          </ng-container>
          <!-- default is to display nothing-->
        </ng-container>

        <!-- otherwise display as-is. -->
        <ng-template #basicPropDisplay>
          <div>
            <div [ngSwitch]="column">
              <div *ngSwitchCase="column.titleCase">{{user[column.prop] |
                titlecase}}</div>
              <div *ngSwitchCase="column.nameCase">{{user[column.prop] |
                namecase}}</div>
              <div *ngSwitchDefault>{{user[column.prop]}}</div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</div>
