import { Injectable } from '@angular/core';
import { AdminNote } from '@common';

export interface UserCriticalNotesTableColumn {
  title: string;
  width: string;
  prop: keyof AdminNote | 'remove';
}

@Injectable({
  providedIn: 'root'
})
export class EhsUserCriticalsNotesTableService {
  getColumns(): UserCriticalNotesTableColumn[] {
    return [
      {
        title: 'Note',
        prop: 'str',
        width: '400px'
      },
      {
        title: 'Contacted At',
        prop: 'contactedAt',
        width: '180px'
      },
      {
        title: 'Delivery Types',
        prop: 'deliveryTypes',
        width: '155px'
      },
      {
        title: 'Admin',
        prop: 'admin',
        width: '325px'
      },
      {
        title: 'Created At',
        prop: 'createdAt',
        width: '100px'
      },
      {
        title: '',
        prop: 'remove',
        width: '0'
      }
    ];
  }
}
