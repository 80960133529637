import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { EhsCardSeparatorComponent } from './ehs-card-separator.component';

@NgModule({
  declarations: [EhsCardSeparatorComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  exports: [EhsCardSeparatorComponent]
})
export class EhsCardSeparatorModule {}
