<h1 mat-dialog-title>Merge Users</h1>
<mat-dialog-content>
  <div class="mat-body-1">
    <ng-container *ngIf="isMixed else showNonMixed">
      <div>
        The currently selected account is the primary, as the other account
        is a walk-in user, and will be merged. This cannot be changed.
      </div>
    </ng-container>
    <ng-template #showNonMixed>
      <div>
        Select the account you want to <span class="mat-body-2">keep</span>,
        and be used as the primary. The other account will be removed from the system, while
        merging all the user data to the primary account.
      </div>
    </ng-template>
  </div>
  <div fxLayout="row"
       fxLayoutAlign="center center">
    <mat-radio-group [value]="primaryIndex">
      <ng-container *ngFor="let userToMerge of data.usersToMerge; let index = index">
        <mat-radio-button (change)="primaryIndex = index"
                          color="primary"
                          [value]="index"
                          [disabled]="isMixed">
          <div fxFlex>
            <div fxLayout="column"
                 fxLayoutGap="4px">
              <div>
                <span class="mat-body-2">Name:</span> {{userToMerge.firstName}} {{userToMerge.lastName}}
              </div>
              <div>
                <span class="mat-body-2">Walk-In:</span> {{userToMerge.partial ? 'yes' : 'no'}}
              </div>
              <div>
                <span class="mat-body-2">DOB:</span> {{userToMerge.birthDay | date: 'mediumDate'}}
              </div>
              <div>
                <span class="mat-body-2">SSN:</span> {{userToMerge.ssn}}
              </div>
              <div>
                <span class="mat-body-2">Username:</span> {{$any(userToMerge)?.username}}
              </div>
              <!-- <div>
                <span class="mat-body-2">Company:</span> {{userToMerge?.company.name || '???'}}
              </div> -->
            </div>
          </div>
        </mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end"
                    fxLayoutGap="16px">
  <button type="button"
          class="grey-cancel"
          mat-button
          [mat-dialog-close]="undefined">
    Cancel
  </button>
  <button type="button"
          color="primary"
          mat-button
          (click)="close()"
          cdkFocusInitial>
    Confirm
  </button>
</mat-dialog-actions>
