import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../pipes/pipes.module';
import { EhsRegFiltersComponent } from './ehs-reg-filters.component';

@NgModule({
  declarations: [EhsRegFiltersComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PipesModule,
    // Angular Material
    FlexLayoutModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule
  ],
  exports: [EhsRegFiltersComponent]
})
export class EhsRegFiltersModule {}
