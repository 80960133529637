import { Injectable } from '@angular/core';
import { getId } from '@common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom
} from 'rxjs/operators';
import { AdminEventServiceHttpService } from '../../http/admin/admin-event-service-http.service';
import { EventServiceHttpService } from '../../http/event-service-http.service';
import { CompanyFacade } from '../company/company.facade';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { userRegistrationActions } from '../user-registration/user-registration.actions';
import { eventServiceActions } from './event-service.actions';
import { EventServiceFacade } from './event-service.facade';

@Injectable()
export class EventServiceEffect {
  constructor(
    private actions$: Actions,
    private errorReportFacade: ErrorReportFacade,
    private companyFacade: CompanyFacade,
    private eventServiceFacade: EventServiceFacade,
    private eventServiceHttp: EventServiceHttpService,
    private adminEventServiceHttp: AdminEventServiceHttpService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventServiceActions.get),
      mergeMap((action) =>
        this.eventServiceHttp.get(action.id).pipe(
          map(
            (entity) => eventServiceActions.getSuccess({ entity }),
            catchError((err) => [
              eventServiceActions.getFailed(action),
              this.errorReportFacade.create({ action, err })
            ])
          )
        )
      )
    )
  );

  getAny$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventServiceActions.getAny),
      mergeMap((action) =>
        this.adminEventServiceHttp.getAny(action.id).pipe(
          map(
            (entity) => eventServiceActions.getAnySuccess({ entity }),
            catchError((err) => [
              eventServiceActions.getAnyFailed(),
              this.errorReportFacade.create({ action, err })
            ])
          )
        )
      )
    )
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventServiceActions.list),
      mergeMap((action) =>
        this.eventServiceHttp.list().pipe(
          map((eventServices) =>
            eventServiceActions.listSuccess({ eventServices })
          ),
          catchError((err) => [
            eventServiceActions.listFailed(),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  /**
   * When a registration is removed, we need to remove the
   * event-serivce IF its not part of the same
   * company we are in.
   *
   * This covers a single case where a user has an old user-registration
   * from a previous company, and they cancel their registration from the old
   * company. In this scenario we need to remove the loaded event-service
   * from the previous company.
   */
  removeUserRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationActions.removeSuccess),
      withLatestFrom(
        this.companyFacade.company$,
        this.eventServiceFacade.servicesMap$
      ),
      filter(([{ entity: userRegistration }, company, eventServices]) => {
        if (!userRegistration || !company || !eventServices) {
          // This technically is an error
          return false;
        }

        const registrationEventService =
          eventServices[getId(userRegistration.eventService)];

        if (!registrationEventService) {
          // This is technically an error
          return false;
        }

        // If the event-serivce isn't part of the company, we need to remove it
        return getId(registrationEventService.company) !== getId(company);
      }),
      map(([{ entity: userRegistration }]) =>
        eventServiceActions.remove({
          eventService: getId(userRegistration.eventService)
        })
      )
    )
  );
}
