import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getId } from '@common';
import { CLIENT_ADMIN_CORE_INJECTION_TOKEN } from '@ehs-ngrx/common';
import {
  AdminCoachingSessionHttpService,
  AdminCompanyHttpService,
  AdminUserHttpService,
  AdminUserRegistrationHttpService,
  CompanyHttpService,
  UserIncentiveHttpService
} from '@http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { companyNgrxActions } from './company-ngrx.actions';

@Injectable()
export class CompanyNgrxEffects {
  constructor(
    @Inject(CLIENT_ADMIN_CORE_INJECTION_TOKEN)
    private clientAdmin: boolean,
    private actions$: Actions,
    private adminCompanyHttp: AdminCompanyHttpService,
    private adminUserHttp: AdminUserHttpService,
    private adminUserRegistrationHttp: AdminUserRegistrationHttpService,
    private adminCoachingSessionHttp: AdminCoachingSessionHttpService,
    private adminUserIncentiveHttp: UserIncentiveHttpService,

    // This is for "normal users", might be removed in the future
    private companyHttp: CompanyHttpService,

    // Angular Material
    private snackBar: MatSnackBar
  ) {}

  getCurrent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.getCurrent.req),
      mergeMap(() =>
        this.companyHttp.get().pipe(
          map((entity) => companyNgrxActions.getCurrent.success({ entity })),
          catchError(() => [companyNgrxActions.getCurrent.failed()])
        )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.get.req),
      mergeMap((action) =>
        this.adminCompanyHttp
          .getCompany(action.payload.id, { clientAdmin: this.clientAdmin })
          .pipe(
            map((entity) => companyNgrxActions.get.success({ entity })),
            catchError(() => [companyNgrxActions.get.failed()])
          )
      )
    )
  );

  getSecurity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.getSecurity.req),
      mergeMap((action) =>
        this.adminCompanyHttp
          .getCompanySecurity({ companyId: getId(action?.payload?.id) })
          .pipe(
            map((entity) => companyNgrxActions.getSecurity.success({ entity })),
            catchError(() => [companyNgrxActions.getSecurity.failed()])
          )
      )
    )
  );

  updateSecurity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.updateSecurity.req),
      mergeMap((action) =>
        this.adminCompanyHttp
          .updateCompanySecurity({
            companyId: action.payload.companyId,
            companySecurity: action.payload.companySecurity
          })
          .pipe(
            map((entity) =>
              companyNgrxActions.updateSecurity.success({ entity })
            ),
            catchError(() => [companyNgrxActions.updateSecurity.failed()])
          )
      )
    )
  );

  getCompanyNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.getCompanyNotes.req),
      mergeMap((action) =>
        this.adminCompanyHttp.getCompanyNotes(action.payload).pipe(
          map((notes) => companyNgrxActions.getCompanyNotes.success(notes)),
          catchError(() => [companyNgrxActions.getCompanyNotes.failed()])
        )
      )
    )
  );

  updateCompanyNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.updateCompanyNotes.req),
      mergeMap((action) =>
        this.adminCompanyHttp
          .updateCompanyNotes({
            companyId: action.payload.companyId,
            notes: action.payload.notes
          })
          .pipe(
            map((notes) =>
              companyNgrxActions.updateCompanyNotes.success(notes)
            ),
            tap(
              () => this.snackBar.open('Notes Updated', 'Ok'),
              () =>
                this.snackBar.open(
                  'Oops, there was an error saving your changes...'
                )
            ),
            catchError(() => [companyNgrxActions.updateCompanyNotes.failed()])
          )
      )
    )
  );

  getStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.getStats),
      mergeMap((action) =>
        this.adminCompanyHttp
          .getStats(action.id, { clientAdmin: this.clientAdmin })
          .pipe(
            map((stats) =>
              companyNgrxActions.getStatsSuccess({
                id: action.id,
                stats
              })
            ),
            catchError(() => [
              companyNgrxActions.getStatsFailed({ id: action.id })
            ])
          )
      )
    )
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.list.req),
      mergeMap((action) =>
        // Get **all** companies in the platform
        this.adminCompanyHttp.listCompanies(action.payload?.query || {}).pipe(
          map((entities) => companyNgrxActions.list.success({ entities })),
          catchError(() => [companyNgrxActions.list.failed()])
        )
      )
    )
  );

  exportUserRegistrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.exportUserRegistrations.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .exportRegs(action.payload, { clientAdmin: this.clientAdmin })
          .pipe(
            map(() => companyNgrxActions.exportUserRegistrations.success()),
            tap(
              () =>
                this.snackBar.open('Started export, check your inbox', 'Ok'),
              () =>
                this.snackBar.open('Oops, there was an error with the export')
            ),
            catchError(() => [
              companyNgrxActions.exportUserRegistrations.failed()
            ])
          )
      )
    )
  );

  exportFieldStaff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.exportFieldStaff.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .exportRegs(action.payload, { clientAdmin: this.clientAdmin })
          .pipe(
            map(() => companyNgrxActions.exportFieldStaff.success()),
            tap(
              () =>
                this.snackBar.open('Started export, check your inbox', 'Ok'),
              () =>
                this.snackBar.open('Oops, there was an error with the export')
            ),
            catchError(() => [companyNgrxActions.exportFieldStaff.failed()])
          )
      )
    )
  );

  exportUserIncentives$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.exportUserIncentives.req),
      mergeMap((action) =>
        this.adminUserIncentiveHttp.exportIncentives(action.payload).pipe(
          map(() => companyNgrxActions.exportUserIncentives.success()),
          tap(
            () => this.snackBar.open('Started export, check your inbox', 'Ok'),
            () => this.snackBar.open('Oops, there was an error with the export')
          ),
          catchError(() => [companyNgrxActions.exportUserIncentives.failed()])
        )
      )
    )
  );

  exportCoachingSession = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.exportCoachingSession.req),
      mergeMap((action) =>
        this.adminCoachingSessionHttp.exportCs(action.payload).pipe(
          map(() => companyNgrxActions.exportCoachingSession.success()),
          tap(
            () => this.snackBar.open('Started export, check your inbox', 'Ok'),
            () => this.snackBar.open('Oops, there was an error with the export')
          ),
          catchError(() => [companyNgrxActions.exportCoachingSession.failed()])
        )
      )
    )
  );

  exportUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyNgrxActions.exportUsers.req),
      mergeMap((action) =>
        this.adminUserHttp
          .exportUsers({ ...action.payload, clientAdmin: this.clientAdmin })
          .pipe(
            map(() => companyNgrxActions.exportUsers.success()),
            tap(
              () =>
                this.snackBar.open('Started export, check your inbox', 'Ok'),
              () =>
                this.snackBar.open('Oops, there was an error with the export')
            ),
            catchError(() => [companyNgrxActions.exportUsers.failed()])
          )
      )
    )
  );
}
