import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsProviderHealthUploadsTableComponent } from './ehs-provider-health-uploads-table.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  declarations: [EhsProviderHealthUploadsTableComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ScrollingModule,
    MatIconModule,
    MatChipsModule
  ],
  exports: [EhsProviderHealthUploadsTableComponent]
})
export class EhsProviderHealthUploadsTableModule {}
