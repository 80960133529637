<div *ngIf="config$ | async as config">
  <mat-chip-listbox>
    <mat-chip-option color="primary"
                     [title]="config | json">
      <ng-container *ngIf="config.env">
        {{config.env}}
      </ng-container>
      {{config.tag}}
    </mat-chip-option>
  </mat-chip-listbox>
</div>