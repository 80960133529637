/**
 * The type of service. This is not to be confused
 * with the onsite-event-service, which is more
 * like a "service-package".
 */
export enum ServiceType {
  ONSITE = 'onsite',
  OFFSITE = 'offsite',
  PROVIDER_HEALTH = 'providerHealth'
}

/**
 * The type of services that EHS can enable for a given `event-service`.
 * These are additional to the `ServiceType` registration types.
 */
export enum OtherServiceType {
  COACHING = 'coaching',
  HRA = 'hra',
  EDUCATION = 'education',
  /**
   * **Note** this is used slightly differently, as it depends
   * on EDUCATION being active as well.
   */
  EDUCATION_MARQUEE = 'education_marquee',
  INCENTIVES = 'incentives',
  TRACKERS = 'trackers',
  REIMBURSEMENTS = 'reimbursements',
  HEALTH_PROGRAM = 'healthProgram'
}

/**
 * Type-guard for service-type.
 */
export const isServiceType = (
  serviceType: string
): serviceType is ServiceType =>
  Object.values(ServiceType).includes(serviceType as ServiceType);
