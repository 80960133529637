import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Company, getId, toMap, UserFilter } from '@common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ehs-user-filters',
  templateUrl: './ehs-user-filters.component.html',
  styleUrls: ['./ehs-user-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsUserFiltersComponent {
  @Input() userFilter: UserFilter = {};
  @Input() hideCompany?: boolean;
  /** Flag passed when called from client-admin, used for routes */
  @Input() clientAdmin?: boolean;

  @Input() set companies(companies: Company[]) {
    this.companiesArr = companies.slice(0, 10);

    this.companies$.next(
      toMap({
        key: '_id',
        entities: companies
      })
    );
  }

  @Output() userFilterChange = new EventEmitter<UserFilter>();
  @Output() keyupChange = new EventEmitter<UserFilter>();
  @Output() search = new EventEmitter();
  @Output() clear = new EventEmitter();

  companiesArr: Company[] = [];

  private companies$ = new BehaviorSubject<Record<string, Company>>({});

  get company() {
    if (!this.userFilter.company) {
      return '';
    }

    if (typeof this.userFilter.company === 'string') {
      return this.companies$.value[getId(this.userFilter.company)]?.name || '';
    }

    return this.userFilter.company.name;
  }

  onChange(params: UserFilter) {
    this.userFilterChange.emit({ ...this.userFilter, ...params });
  }

  onKeyupChange(params: UserFilter) {
    this.keyupChange.emit({ ...this.userFilter, ...params });
  }

  /**
   * Check to see if there are any filters. We subtract 1 to exclude `UserFilter.company`
   */
  hasFilters() {
    return !!(Object.keys(this.userFilter).length - (this.hideCompany ? 1 : 0));
  }
}
