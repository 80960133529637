import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BirthDayValidatorsModule } from '../../shared/birth-day-validators/birth-day-validators.module';
import { EmailValidatorModule } from '../../shared/email-validator/email-validator.module';
import { PhoneFormatModule } from '../../shared/phone-format/phone-format.module';
import { EhsUserFormDisplayService } from './ehs-user-form-display.service';
import { EhsUserFormComponent } from './ehs-user-form.component';
import { ToFrPipe } from './to-fr.pipe';

@NgModule({
  declarations: [EhsUserFormComponent, ToFrPipe],
  imports: [
    CommonModule,
    FormsModule,
    // Validators
    PhoneFormatModule,
    BirthDayValidatorsModule,
    EmailValidatorModule,
    // Angular Material
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule
  ],
  exports: [EhsUserFormComponent],
  providers: [EhsUserFormDisplayService]
})
export class EhsUserFormModule {}
