import { calendarUrls } from '../constants/calendar';
import { EventLocation } from '../models/event-location';
import { OnsiteUserRegistration } from '../models/user-registration/onsite-user-registration';
import { EventLocationUtil } from './event-location-util';
import { TimeUtil } from './time-util';

export class CalendarUtil {
  /**
   * Generates a google calendar link as described by the following docs:
   * https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/master/services/google.md
   *
   * Not sure why/where there is official docs on the format tho
   */
  public static getGoogleLink(params: {
    title: string;
    location?: string;
    eventDate: string;
    eventTime: string;
  }): string {
    const { title, location, eventDate, eventTime } = params;
    const date = eventDate.replace(/-/g, '');

    const endTime = TimeUtil.add({
      time: eventTime,
      minutes: 15
    });

    const url = new URL(calendarUrls.GOOGLE);

    url.searchParams.set('action', 'TEMPLATE');
    url.searchParams.set('text', title);

    if (location) {
      url.searchParams.set('location', location);
    }

    const start = `${date}T${eventTime.replace(':', '')}00`;
    const end = `${date}T${endTime.replace(':', '')}00`;
    const calendarTime = `${start}/${end}`;

    url.searchParams.set('dates', calendarTime);

    return url.href;
  }

  /**
   * For Documentation see:
   * https://add-to-calendar-button.com/configuration
   */
  public static getCalendar(
    userRegistration: OnsiteUserRegistration,
    eventLocation: EventLocation
  ) {
    return {
      name: 'EHS Wellness Event',
      startDate: `${userRegistration.eventDate}`,
      startTime: `${userRegistration.eventTime}`,
      endTime: TimeUtil.add({
        time: userRegistration.eventTime,
        minutes: 15
      }),
      location: EventLocationUtil.getLocation({
        eventLocation
      }),
      /** If we ever update to add timezones to eventLocations
       *  we should update this to use the actual timezone
       *  and not try to look at the users browser for timezone
       * */
      timeZone: `currentBrowser`,
      options: [
        'Apple',
        'Google',
        'Yahoo',
        'Outlook.com',
        'Microsoft365',
        'iCal'
      ] as (
        | 'Apple'
        | 'Google'
        | 'iCal'
        | 'Microsoft365'
        | 'MicrosoftTeams'
        | 'Outlook.com'
        | 'Yahoo'
      )[]
    };
  }
}
