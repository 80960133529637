import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DateUtil, User, getId } from '@common';
import { EhsAdminRestrictDataDialogData } from './ehs-admin-restrict-data-dialog.data';

@Component({
  selector: 'ehs-restrict-data-dialog',
  templateUrl: './ehs-admin-restrict-data-dialog.component.html',
  styleUrls: ['./ehs-admin-restrict-data-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsAdminRestrictDataDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EhsAdminRestrictDataDialogData,
    private dialogRef: MatDialogRef<EhsAdminRestrictDataDialogComponent>
  ) {}

  // Selected radio button value
  public restrictData: boolean | null;

  // Used to display current restriction info
  public user = this.data.user || undefined;

  /**
   * Date functions used to display request and
   * request expiration dates to admin
   **/
  public getExpirationDate(user: User) {
    if (!user?.dateOfDataRestrictionExpires) {
      return 'None';
    }

    const expiration = DateUtil.convertToString(
      user.dateOfDataRestrictionExpires
    );

    return DateUtil.getFullDate(expiration);
  }

  public getRequestDate(user: User) {
    if (!user?.dateOfDataRestrictionRequest) {
      return 'None';
    }

    const request = DateUtil.convertToString(user.dateOfDataRestrictionRequest);

    return DateUtil.getFullDate(request);
  }

  public onSubmit() {
    if (
      this.restrictData === undefined ||
      this.restrictData === null ||
      !this.user?._id
    ) {
      return;
    }

    const user: Partial<User> = {
      _id: getId(this.user),
      restrictData: this.restrictData
    };

    this.dialogRef.close(user);
  }
}
