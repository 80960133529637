import { getId, User, UserUtil, UserWithRegistrations } from '@common';
import {
  entitySelectionReducerFactory,
  EntitySelectionState,
  selectId
} from '@ehs-ngrx/common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { userNgrxActions } from './user-ngrx.actions';

export const USER_STORE_KEY = 'userNgrx';

export interface ParentUserNgrxState {
  [USER_STORE_KEY]: UserNgrxState;
}

export interface UserNgrxState
  extends EntityState<User | UserWithRegistrations>,
    EntitySelectionState<string> {
  loading?: boolean;
  actionLoading?: boolean;
  hasNext?: boolean;
  pageNumber: number;

  /**
   * Map of unique users where the key is their "unique properties".
   */
  uniqueUsers?: Record<string, User>;
}

const adapter = createEntityAdapter<User | UserWithRegistrations>({ selectId });

export const userNgrxReducer = createReducer<UserNgrxState, Action>(
  adapter.getInitialState({
    pageNumber: 1
  }),
  on(userNgrxActions.set, (state, { entity }) =>
    adapter.upsertOne(entity, state)
  ),
  on(userNgrxActions.setMany, (state, { entities }) =>
    adapter.upsertMany(entities, state)
  ),
  on(userNgrxActions.upsertOne, (state, { entity }) =>
    adapter.upsertOne(entity, state)
  ),
  on(userNgrxActions.updateUser.req, (state) => ({ ...state, loading: true })),
  on(userNgrxActions.updateUser.success, (state, { payload: { entity } }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(userNgrxActions.updateUser.failed, (state) => ({
    ...state,
    loading: false
  })),
  on(userNgrxActions.list.req, (state) => ({ ...state, loading: true })),
  on(userNgrxActions.list.success, (state, { payload: { users, hasNext } }) =>
    adapter.upsertMany(users, { ...state, hasNext, loading: false })
  ),
  on(userNgrxActions.list.failed, (state) => ({ ...state, loading: false })),
  on(userNgrxActions.clearUsers, (state) => adapter.removeAll(state)),
  on(userNgrxActions.setPageNumber, (state, { pageNumber }) => ({
    ...state,
    pageNumber
  })),
  on(userNgrxActions.loadMore, (state) => ({
    ...state,
    loading: true,
    pageNumber: state.pageNumber + 1
  })),
  on(userNgrxActions.get.req, (state) => ({ ...state, loading: true })),
  on(userNgrxActions.get.success, (state, { payload: { entity } }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(userNgrxActions.get.failed, (state) => ({ ...state, loading: false })),
  on(userNgrxActions.getUserWithUnique.req, (state) => ({
    ...state,
    loading: true
  })),
  on(
    userNgrxActions.getUserWithUnique.success,
    (state, { payload: { entity } }) => ({
      ...state,
      loading: false,
      uniqueUsers: {
        ...state.uniqueUsers,
        [UserUtil.getLookupString(entity)]: entity
      }
    })
  ),
  on(userNgrxActions.getUserWithUnique.failed, (state) => ({
    ...state,
    loading: false
  })),
  on(userNgrxActions.loginAsUser.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.loginAsUser.success, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.loginAsUser.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.loginAsUser.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.resetPassword.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.resetPassword.success, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.resetPassword.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.resetPassword.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.removeMFA.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.removeMFA.success, (state, { payload: { user } }) =>
    adapter.upsertOne(user, { ...state, actionLoading: false })
  ),
  on(userNgrxActions.removeMFA.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.removeMFA.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.disableMFA.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.disableMFA.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.disableMFA.success, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.disableMFA.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.enableMFA.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.enableMFA.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.enableMFA.success, (state, { payload: { user } }) =>
    adapter.upsertOne(user, { ...state, actionLoading: false })
  ),
  on(userNgrxActions.enableMFA.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.verifyEmail.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.verifyEmail.success, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.verifyEmail.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.verifyEmail.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.unlockAccount.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  // Update the stored user locked status to reflect action result
  on(userNgrxActions.unlockAccount.success, (state, { payload: { user } }) =>
    adapter.upsertOne(user, { ...state, actionLoading: false })
  ),
  on(userNgrxActions.unlockAccount.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.unlockAccount.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.removeUser.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.removeUser.success, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.removeUser.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.removeUser.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.moveUserCompany.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.moveUserCompany.success, (state, { payload: { user } }) =>
    adapter.upsertOne(user, { ...state, actionLoading: false })
  ),
  on(userNgrxActions.moveUserCompany.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.moveUserCompany.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.flagRemoveUser.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.flagRemoveUser.success, (state, { payload: { user } }) =>
    adapter.removeOne(getId(user), { ...state, actionLoading: false })
  ),
  on(userNgrxActions.flagRemoveUser.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.flagRemoveUser.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.flagRemoveUser.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.mergeUsers.req, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(
    userNgrxActions.mergeUsers.success,
    (state, { payload: { mergedUser, userRemoved } }) =>
      adapter.upsertOne(
        mergedUser,
        adapter.removeOne(getId(userRemoved), {
          ...state,
          actionLoading: false
        })
      )
  ),
  on(userNgrxActions.mergeUsers.failed, (state) => ({
    ...state,
    actionLoading: false
  })),
  on(userNgrxActions.mergeUsers.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),

  on(userNgrxActions.editUser.success, (state, { payload: { user } }) =>
    adapter.upsertOne(user, { ...state, actionLoading: false })
  ),
  on(userNgrxActions.editUser.failed, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.editUser.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),

  on(userNgrxActions.restrictUserData.success, (state, { payload: { user } }) =>
    adapter.upsertOne(user, { ...state, actionLoading: false })
  ),
  on(userNgrxActions.restrictUserData.failed, (state) => ({
    ...state,
    actionLoading: true
  })),
  on(userNgrxActions.restrictUserData.canceled, (state) => ({
    ...state,
    actionLoading: false
  })),

  on(userNgrxActions.promoteUser.req, (state) => ({
    ...state,
    loading: true,
    actionLoading: true
  })),
  on(userNgrxActions.promoteUser.success, (state, { payload: { user } }) =>
    adapter.upsertOne(user, { ...state, loading: false, actionLoading: false })
  ),
  on(userNgrxActions.promoteUser.failed, (state) => ({
    ...state,
    actionLoading: true
  })),
  // Add when adding dialog to user-table-action
  // on(userNgrxActions.promoteUser.canceled, (state) => ({
  //   ...state,
  //   actionLoading: false
  // })),

  on(userNgrxActions.demoteUser.req, (state) => ({
    ...state,
    loading: true,
    actionLoading: true
  })),
  on(userNgrxActions.demoteUser.success, (state, { payload: { user } }) =>
    adapter.upsertOne(user, { ...state, loading: false, actionLoading: false })
  ),
  on(userNgrxActions.demoteUser.failed, (state) => ({
    ...state,
    actionLoading: true
  })),

  on(userNgrxActions.desyncUser.req, (state) => ({ ...state, loading: true })),
  on(userNgrxActions.desyncUser.success, (state, { payload: { user } }) =>
    adapter.upsertOne(user, { ...state, loading: false })
  ),
  on(userNgrxActions.desyncUser.failed, (state) => ({
    ...state,
    loading: false
  })),

  on(userNgrxActions.createPartialUser.req, (state) => ({
    ...state,
    loading: true
  })),
  // **Note** the type here is mis-leading but it should be ok
  on(
    userNgrxActions.createPartialUser.success,
    (state, { payload: { user } }) =>
      adapter.upsertOne(user as User, { ...state, loading: false })
  ),
  on(userNgrxActions.createPartialUser.failed, (state) => ({
    ...state,
    loading: false
  })),

  ...entitySelectionReducerFactory<UserNgrxState, string, 'USER_NGRX'>({
    entitySelectionActions: userNgrxActions
  })
);
