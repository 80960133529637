import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { AdminUserScopes, Company, getId, isDbDocument, User } from '@common';
import {
  UserActionColumn,
  UserActionType,
  UserColumn
} from './user-table-column';

@Component({
  selector: 'ehs-user-table',
  templateUrl: './ehs-user-table.component.html',
  styleUrls: ['./ehs-user-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsUserTableComponent {
  /**
   * The list of users being displayed in the table
   */
  @Input() users: User[] = [];

  /**
   * The columns being displayed in the table
   */
  @Input() columns: UserColumn[] = [];

  /**
   * The base actions being displayed for a given user within the three dot menu
   * Will be ran through `getUserSpecificActions` method to get the list of
   * actions for the given user, taking into account the user and mergeUsers
   */
  @Input() userActions?: UserActionColumn[] = [];

  /**
   * Flag to display `Load More` button
   */
  @Input() hasNext?: boolean;

  /**
   * The page we are loading and displaying to the user. Used to determine
   * what "pageIndex" offset that is calculated
   */
  @Input() pageNumber?: number;

  /**
   * Unlike most components, this component can handle internal loading by displaying a
   * loading bar at the top of the header, this is less disruptive
   * than the full-page loading spinner we usually use
   */
  @Input() loading?: boolean;

  /**
   * Flag to display spinner on action column header
   */
  @Input() actionLoading?: boolean;
  /**
   * The map of companies, used for lookups for
   * user displays.
   */
  @Input() companies?: Record<string, Company>;

  /**
   * Array of ids for users to merge together.
   * The actual act of merging is done within another component/service,
   * this table only requires the list of users to merge to update
   * the menu options for each user
   */
  @Input() usersToMerge?: string[];

  /** If this table is being used in the client-admin module, we need to change some of the functionality/redirects, this flag will allow us to make updates */
  @Input() clientAdmin?: boolean;

  /**
   *  Emits on action trigger for user row
   */
  @Output() actionChange = new EventEmitter<{
    /**
     * The action type emitted from the row.
     */
    type: UserActionType;
    /**
     * The user that was affected
     */
    user: User;
  }>();

  @Output() loadMore = new EventEmitter();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  public adminUserScopes = AdminUserScopes;

  get showLoginAsUser() {
    return !!this.userActions.find(
      (action) => action.type === AdminUserScopes.USER_LOGIN
    );
  }

  /**
   * The index of the item where we will load more for. This is based on the pageNumber that is
   * passed from the ngrx state for users, which represents the page we last requested for.
   *
   *
   * Initially the loadMoreIndex will automatically load up the next chunk of results after the first element is scrolled past.
   *
   * After this point, then the "last" element of the "second to last" chunk will be the point at which we load
   * the next chunk. This should keep a buffer of 25 elements below the last element allowing for a smooth scroll experience,
   * as long as the data comes back before the final element is scrolled to.
   */
  get loadMoreIndex() {
    return (this.pageNumber - 1) * 25;
  }

  /**
   * To keep the header positioning:sticky inside cdk viewport
   */
  get headerTop() {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }

    const offset = this.viewPort['_renderedContentOffset'];

    return `-${offset}px`;
  }

  public trackByFn(index: number) {
    return index;
  }

  /**
   * Returns the list of actions for a given user, taking into account
   * the base actions available to the admin, and the merge-users currently
   * selected.
   *
   * **note** this could be a pipe, but it doesn't emit
   * too often
   */
  public getUserSpecificActions(user: User): UserActionColumn[] {
    return this.userActions.filter((column) =>
      column.displayFn
        ? column.displayFn({
            user,
            usersToMerge: this.usersToMerge
          })
        : true
    );
  }

  /**
   * Returns the company of the user, this uses the `companies` map if provided
   * otherwise it tries to fallback on the passed user itself, incase it was
   * passed with the user.company being the actual company object.
   */
  public getCompany(user: User): Company | undefined {
    if (this.companies && this.companies[getId(user.company)]) {
      return this.companies[getId(user.company)];
    }

    return isDbDocument(user.company) ? user.company : undefined;
  }

  /**
   * Callback on scroll, testing replacements for loading
   */
  public onScrolledIndexChange(index: number) {
    if (index > this.loadMoreIndex) {
      this.loadMore.emit();
    }
  }

  /**
   * Returns the link to the user's "page" for admins.
   */
  public getViewLink(user: User) {
    return ['/ehs/admin/company/', getId(user.company), 'user', getId(user)];
  }
}
