import { EventService } from '@common';
import { createAction, props } from '@ngrx/store';

export const eventServiceActions = {
  get: createAction(
    '[EVENT_SERVICE] GET',
    props<{
      id: string;
    }>()
  ),
  getSuccess: createAction(
    '[EVENT_SERVICE] GET_SUCCESS',
    props<{
      entity: EventService;
    }>()
  ),
  getFailed: createAction(
    '[EVENT_SERVICE] GET_FAILED',
    props<{ id: string }>()
  ),
  getAny: createAction(
    '[EVENT_SERVICE] GET_ANY',
    props<{
      id: string;
    }>()
  ),
  getAnySuccess: createAction(
    '[EVENT_SERVICE] GET_ANY_SUCCESS',
    props<{
      entity: EventService;
    }>()
  ),
  getAnyFailed: createAction('[EVENT_SERVICE] GET_ANY_FAILED'),
  list: createAction('[EVENT_SERVICE] LIST'),
  listSuccess: createAction(
    '[EVENT_SERVICE] LIST_SUCCESS',
    props<{ eventServices: EventService[] }>()
  ),
  listFailed: createAction('[EVENT_SERVICE] LIST_FAILED'),
  set: createAction(
    '[EVENT_SERVICE] SET',
    props<{
      eventService: EventService;
    }>()
  ),
  // **Note** this only updates the state
  remove: createAction(
    '[EVENT_SERVICE] REMOVE',
    props<{
      eventService: string;
    }>()
  )
};
