import { DbDocument } from './db-document';

/**
 * A scope means this admin can perform this task.
 * All admins can at least log in.
 */
export enum AdminUserScopes {
  /**
   * If the admin can login as another user within the platform.
   */
  USER_LOGIN = 'user_login',
  /**
   * If the admin can reset password of users
   */
  PASSWORD_RESET = 'password_reset',
  /**
   * If the admin can reset mfa of users
   */
  MFA_DELETE = 'mfa_delete',
  /**
   * If the admin can disable mfa for users */
  MFA_DISABLE = 'mfa_disable',
  /**
   * If the admin can update user account info (first name, last name, ssn, dob, etc...)
   */
  UPDATE_PROFILE = 'update_profile',
  /**
   * If the admin can verify emails on behalf of the user.
   * Mostly used if verification emails are not received.
   */
  VERIFY_EMAIL = 'verify_email',
  /**
   * If the admin can export HRA data for both all companies
   * and specific companies
   */
  HRA_EXPORT = 'hra_export',
  /**
   * If the admin can export SESSION data for companies
   */
  SESSION_EXPORT = 'session_export',
  /**
   * If the admin can export USER data for
   * specific companies
   */
  USER_EXPORT = 'user_export',
  /**
   * If the admin can export Registrations for a given company
   */
  REG_EXPORT = 'reg_export',
  /**
   * If the admin can export user registration attendance ()
   */
  REG_EXPORT_FULL = 'reg_export_full',
  /**
   * If the admin can export Registrations for a given company
   */
  IMPORT_RESULTS = 'import_results',
  /**
   * If the admin can create OR UPDATE user-result data
   * for any user.
   */
  CREATE_RESULT = 'create_result',
  /**
   * If the admin can create notifications
   * for any user.
   */
  CREATE_NOTIFICATION = 'create_notification',
  /**
   * If the admin can remove user-result data
   * for any user.
   */
  REMOVE_RESULT = 'remove_result',
  /**
   * If the admin can update user registrations.
   * Currently used only for flu
   */
  UPDATE_REG = 'update_reg',
  /**
   * Role that gives access to add / remove admin scopes. As well
   * as add remove admins
   */
  ACCOUNT_SECURITY = 'account_security',
  /**
   * If the admin can sync with the GWApps platform
   */
  SYNC_GWAPPS = 'sync_gwapps',
  /**
   * If the admin can view the GW Apps lists
   */
  LIST_GWAPPS = 'list_gwapps',
  /**
   * If the admin has permissions to unlock a locked user account
   */
  UNLOCK_ACCOUNT = 'unlock_account',
  /**
   * If the admin has permissions to the critical result table and
   * feature. AKA "Critical Calls"
   */
  CRITICAL_RESULT = 'critical_result',
  /**
   * If the admin has permissions to the api errors table and
   * feature.
   */
  API_ERRORS = 'api_errors',

  /**
   * If the admin has permissions to export PDF Reports or Requisitions
   * in bulk.
   */
  EXPORT_PDF_REPORTS = 'export_pdf_reports',

  /**
   * Ability to submit insurance claims, and generate Insurance
   * Claim PDF reports that get uploaded to Drive.
   */
  INSURANCE_CLAIM = 'insurance_claim',
  /**
   * If the admin has the permissions to FLAG the removal of a user.
   * This will appear to admins who have the ability to remove
   * user's later.
   */
  FLAG_REMOVE_USER = 'flag_remove_user',
  /**
   * If the admin has the permissions to completely remove a user.
   */
  REMOVE_USER = 'remove_user',
  /**
   * If the admin has the permissions to merge users together.
   */
  MERGE_USER = 'merge_user',
  /**
   * If the admin has the permissions to create a partial-user
   */
  CREATE_USER = 'create_user',
  /**
   * If the admin has permission to restrict and/or unrestrict user data
   */
  RESTRICT_USER_DATA = 'restrict_user_data',
  /**
   * If the admin has the permissions to move the user to another company.
   */
  MOVE_USER_COMPANY = 'move_user_company',
  /**
   * De-syncs the user's previous company data
   */
  DESYNC_USER_DATA = 'desync_user_data',
  /**
   * If the admin can view the platform statistics
   */
  PLATFORM_STATS = 'platform_stats',
  /**
   * If the admin has permissions to release to labs for onsite and offsite
   * registrations.
   *
   * **note** this is new, and added with offsite registrations, and thus
   * isn't fully implemented in all scenarios of releasing to labs.
   */
  RELEASE_TO_LAB = 'release_to_lab',
  /**
   * If the admin has permission to trigger a Marquee export to CSV
   */
  MARQUEE_HEALTH_EXPORT = 'marquee_export',

  /**
   * If the admin has permission to update company security settings
   */
  COMPANY_SECURITY_SETTINGS = 'company_security_settings',
  /**
   * Permission to access user payments
   */
  PAYMENT = 'payment',

  /** Flag to allow admin to manage uploaded provider health form actions */
  PROVIDER_HEALTH_UPLOAD = 'provider_health_upload',
  /**
   * If the admin has permissions to add/edit/remove custom-times for event-locations.
   * This will **not** prevent them from getting what the current custom times are, only
   * affect their ability to edit them.
   */
  CUSTOM_TIMES = 'custom_times',
  /**
   * Permission to get/add/edit user home tests
   */
  HOME_TEST = 'home_test',

  USER_REIMBURSEMENT = 'user_reimbursement',

  USER_INCENTIVE = 'user_incentive',
  DELETE_USER_INCENTIVE = 'delete_user_incentive',
  EXPORT_USER_INCENTIVES = 'export_user_incentives',
  CREATE_USER_VACCINATION = 'create_user_vaccination',
  CANCEL_EVENT = 'cancel_event',
  CLIENT_PROMOTION = 'client_promotion',
  MAINTENANCE = 'maintenance',
  CREATE_USER_WAIVER = 'create_user_waiver'
}

/**
 * List of admin user scopes to human friendly names
 */
export const adminUserScopesNames: {
  [key in AdminUserScopes]: string;
} = {
  [AdminUserScopes.ACCOUNT_SECURITY]: 'Admin Security',
  [AdminUserScopes.CREATE_RESULT]: 'Data Entry/Data Correction',
  [AdminUserScopes.REMOVE_RESULT]: 'Remove Results',
  [AdminUserScopes.HRA_EXPORT]: 'Export HRA',
  [AdminUserScopes.IMPORT_RESULTS]: 'Import Results',
  [AdminUserScopes.PASSWORD_RESET]: 'Password Reset',
  [AdminUserScopes.MFA_DELETE]: 'MFA Reset',
  [AdminUserScopes.MFA_DISABLE]: 'MFA Disable',
  [AdminUserScopes.UPDATE_PROFILE]: 'Update User Profile',
  [AdminUserScopes.CLIENT_PROMOTION]: 'Client Promotion',
  [AdminUserScopes.VERIFY_EMAIL]: 'Verify Email',
  [AdminUserScopes.REG_EXPORT]: 'Export Registrations',
  [AdminUserScopes.REG_EXPORT_FULL]: 'Export Registrations (Full)',
  [AdminUserScopes.SESSION_EXPORT]: 'Export Health Coaching',
  [AdminUserScopes.UPDATE_REG]: 'Update Registrations',
  [AdminUserScopes.USER_EXPORT]: 'Export Users',
  [AdminUserScopes.USER_LOGIN]: 'Login As User',
  [AdminUserScopes.SYNC_GWAPPS]: 'Sync GWApps',
  [AdminUserScopes.UNLOCK_ACCOUNT]: 'Unlock Accounts',
  [AdminUserScopes.CRITICAL_RESULT]: 'Critical Calls',
  [AdminUserScopes.API_ERRORS]: 'API Errors',
  [AdminUserScopes.INSURANCE_CLAIM]: 'Insurance Claim',
  [AdminUserScopes.EXPORT_PDF_REPORTS]: 'Export PDF to Google Drive',
  [AdminUserScopes.FLAG_REMOVE_USER]: 'Flag Remove User',
  [AdminUserScopes.REMOVE_USER]: 'Remove User',
  [AdminUserScopes.CREATE_USER]: 'Create User',
  [AdminUserScopes.RESTRICT_USER_DATA]: 'Restrict User Data',
  [AdminUserScopes.MERGE_USER]: 'Merge Users',
  [AdminUserScopes.MOVE_USER_COMPANY]: 'Move Users company',
  [AdminUserScopes.DESYNC_USER_DATA]: 'Desync previous user company data',
  [AdminUserScopes.PLATFORM_STATS]: 'Platform Statistics',
  [AdminUserScopes.LIST_GWAPPS]: 'GW Apps Lists',
  [AdminUserScopes.RELEASE_TO_LAB]: 'Release Registrations to Labs',
  [AdminUserScopes.MARQUEE_HEALTH_EXPORT]: 'Marquee CSV Export',
  [AdminUserScopes.PAYMENT]: 'User Payment',
  [AdminUserScopes.CUSTOM_TIMES]: 'Update Custom Times',
  [AdminUserScopes.CREATE_NOTIFICATION]: 'Create Notification',
  [AdminUserScopes.HOME_TEST]: 'Home Tests',
  [AdminUserScopes.USER_INCENTIVE]: 'User Incentive',
  [AdminUserScopes.PROVIDER_HEALTH_UPLOAD]: 'HPF Uploads',
  [AdminUserScopes.DELETE_USER_INCENTIVE]: 'Delete User Incentive',
  [AdminUserScopes.EXPORT_USER_INCENTIVES]: 'Export User Incentives',
  [AdminUserScopes.USER_REIMBURSEMENT]: 'Validate User Reimbursements',
  [AdminUserScopes.CREATE_USER_VACCINATION]: 'Create User Vaccination',
  [AdminUserScopes.CANCEL_EVENT]: 'Cancel Event',
  [AdminUserScopes.COMPANY_SECURITY_SETTINGS]: 'Update Company Security',
  [AdminUserScopes.MAINTENANCE]: 'Maintenance',
  [AdminUserScopes.CREATE_USER_WAIVER]: 'Create User Waiver Form'
};

/**
 * An admin user is a "simplified" user profile.
 * Currently we only have email, and "scopes"
 */
export interface AdminUser extends DbDocument {
  /**
   * The admin user's email, should be unique and
   * match with the google email login
   */
  email: string;
  /**
   * The Admin User's first name, to be used for messaging
   */
  firstName?: string;
  /**
   * The Admin User's last name, to be used for messaging
   */
  lastName?: string;
  /**
   * The list of scopes the admin has access to
   */
  scopes: AdminUserScopes[];
}
