import {
  ONSITE_BIO_FORM_SELF_REPORTED_FIELDS,
  ONSITE_FS_FORM_CODE_MAP
} from '.';
import { OnsiteService } from '../../models/event-service/onsite-service';
import { ServiceLabType } from '../../models/event-service/service-lab-type';
import {
  LabResultCode,
  UserTest,
  UserTestId
} from '../../models/user-test/user-test';
import { getId } from '../../util/get-id';
import { toMap } from '../../util/to-map';
import {
  OnsiteBioFormFieldWithTest,
  OnsiteBioFormSelfReportedFields,
  ONSITE_BIO_FORM_CODE_MAP
} from './onsite-bio-form';
import { OnsiteResultFormFieldWithTest } from './onsite-result-form';

/**
 * Returns the available fields to show.
 * This is a re-built simplification of the `getUserTest` and `getAvailableServices`
 * functions for the legacy `AdminResultFormService` class.
 *
 * This function is **dramatically** simpler and primarily handles each
 * individual part of the form separately, and combines them.
 */
export const getAvailableOnsiteResultFormFields = (params: {
  /**
   * The onsite-service we are to map user-tests for. We primarily leverage
   * this to check for "measured" tests that are not always shown.
   */
  onsiteService: OnsiteService;
  /**
   * Map of user-tests by their mongo-id, or an array
   * that will be mapped to their mongo-id.
   *
   * This is used to perform lookups against the onsite-service, which is
   * then checked for the specific services the form can recognize.
   */
  userTests: UserTest[] | Record<UserTestId, UserTest>;
  /**
   * Optional callback that will be called when
   * a user-test isn't found.
   */
  onMissing?: (code: UserTestId) => void;
}): Array<{
  field: OnsiteResultFormFieldWithTest;
  userTest: UserTest;
}> => [...getFsFormServices(params), ...getBioFormServices(params)];

/**
 * Helper function that returns the bio fields for the given service.
 * There are essentially 2 "classes" of bio fields.
 * 1. The types that are **always** given, which are the "self-reported"
 *   fields which are currently always shown in the req PDF.
 * 2. Measured fields, which are only provided/added **if** they are specified
 *   as part of the tests within the service that EHS specified.
 *
 * The validation logic for these fields are complicated based upon similar
 * rules, and are handled in a separate function. `getRequiredOnsiteResultFormFields`
 *
 *
 * Don't use directly, is used internally for `getAvailableOnsiteResultFormFields`.
 *
 * @see getAvailableOnsiteResultFormFields
 */
export const getBioFormServices = ({
  onsiteService,
  userTests,
  onMissing
}: {
  /**
   * The onsite-service we are to map user-tests for. We will also leverage
   * this service to check against conditionalSelfReported tests.
   */
  onsiteService: OnsiteService;

  /**
   * Map of user-tests by their mongo-id, or an array
   * that will be mapped to their mongo-id.
   *
   * This is used to perform lookups against the onsite-service, which is
   * then checked for the specific services the form can recognize.
   */
  userTests: UserTest[] | Record<UserTestId, UserTest>;
  /**
   * Optional callback that will be called when
   * a user-test isn't found.
   *
   * Can be called with UserTestId, or the field string.
   */
  onMissing?: (code: UserTestId | string) => void;
}): Array<{
  field: OnsiteBioFormFieldWithTest;
  userTest: UserTest;
}> => {
  if (!onsiteService || !userTests) {
    return [];
  }

  const userTestMap = toMap({
    // We re-cast regardless to ensure the data is in the correct map format
    entities: Array.isArray(userTests) ? userTests : Object.values(userTests)
  }) as Record<UserTestId, UserTest>;

  // Map of user-tests by lab-result-code, used for lookups that aren't
  // directly related to the service-tests available.
  const userTestByLabResultCode = toMap({
    entities: Array.isArray(userTests) ? userTests : Object.values(userTests),
    key: 'labResultCode'
  }) as Record<LabResultCode, UserTest>;

  // The list of ids for userTests that are explicitly set as available.
  const serviceTestIds = Array.from(
    new Set(
      [
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onsiteService.panel!,
        ...onsiteService.additionalServices,
        ...onsiteService.additionalTests,
        ...onsiteService.optionalTests
      ]
        .filter(Boolean)
        .map(getId)
    ).values()
  );

  // Map of of the service-tests, except to their corresponding user-test,
  // where the key is the lab-result-code, this will be used to match
  // with what we hard-coded for each of the onsite-bio-form-fields.
  const serviceTestsByLabResultCode = toMap({
    entities: serviceTestIds.reduce((acc, id) => {
      if (!userTestMap[UserTestId(id)]) {
        if (typeof onMissing === 'function') {
          onMissing(UserTestId(id));
        }

        return acc;
      }
      acc.push(userTestMap[UserTestId(id)]);

      return acc;
    }, []),
    key: 'labResultCode'
  }) as Record<LabResultCode, UserTest>;

  /**
   * Include all the self-reported fields by default, including the BMI
   * field, which is ignored on the client-side, but leveraged on the back-end.
   */
  const selfReportedFields: Array<{
    field: OnsiteBioFormFieldWithTest;
    userTest: UserTest;
  }> = ONSITE_BIO_FORM_SELF_REPORTED_FIELDS.map((field) => ({
    field,
    userTest: userTestByLabResultCode[ONSITE_BIO_FORM_CODE_MAP[field]]
  }))
    // Do not include fields that don't have a corresponding test, not including the bio-form-fields
    .filter(({ field, userTest }) => {
      if (!userTest) {
        if (typeof onMissing === 'function') {
          onMissing(field);
        }

        return false;
      }

      return true;
    });

  // Get the rest of the bio-fields that aren't self-reported.
  const availableBioFields = (
    Object.entries(ONSITE_BIO_FORM_CODE_MAP) as Array<
      [OnsiteBioFormFieldWithTest, LabResultCode]
    >
  ).reduce(
    (acc, [field, labResultCode]) => {
      if (
        ONSITE_BIO_FORM_SELF_REPORTED_FIELDS.includes(
          field as OnsiteBioFormSelfReportedFields
        )
      ) {
        // Don't re-include any self-reported fields, as these should be added by default
        return acc;
      }

      // Get the user-test that is within the event-service directly
      const userTest = serviceTestsByLabResultCode[labResultCode];

      if (!userTest) {
        // If the user-test isn't found in the service, then we can't add it
        // as we don't recognize it.
        return acc;
      }

      acc.push({
        field,
        userTest
      });

      return acc;
    },
    selfReportedFields as Array<{
      field: OnsiteBioFormFieldWithTest;
      userTest: UserTest;
    }>
  );

  return availableBioFields;
};

/**
 * Helper function that returns the fingerstick fields.
 * We have updated this logic to handle the following 2 cases:
 *
 * 1. If the service has a lab of EHS
 * 2. If the service has the fingerstick (LabResultCode('0000'))) as the panel
 *
 * Then we return **all** the fingerstick fields.
 */
export const getFsFormServices = ({
  onsiteService,
  userTests
}: {
  /**
   * The onsite-service we are to map user-tests for. We will also leverage
   * this service to check against conditionalSelfReported tests.
   */
  onsiteService: OnsiteService;
  /**
   * Map of user-tests by their mongo-id, or an array
   * that will be mapped to their mongo-id.
   */
  userTests: UserTest[] | Record<UserTestId, UserTest>;
}) => {
  if (!onsiteService || !userTests) {
    // Sanity check
    return [];
  }

  if (onsiteService.labType !== ServiceLabType.EHS) {
    return [];
  }

  const userTestsMap = toMap({
    entities: Array.isArray(userTests) ? userTests : Object.values(userTests),
    key: 'labResultCode'
  }) as Record<LabResultCode, UserTest>;

  // This is the map of all fingerstick fields and their corresponding tests.
  // this will allow us to perform lookups based on the test information
  // and the service, without hard-coding the lab-result-codes.
  const fsFieldsWithTests = (
    Object.entries(ONSITE_FS_FORM_CODE_MAP) as Array<
      [OnsiteResultFormFieldWithTest, LabResultCode]
    >
  )
    .map(([field, labResultCode]) => ({
      field,
      userTest: userTestsMap[labResultCode]
    }))
    .filter(({ userTest }) => !!userTest) as Array<{
    field: OnsiteResultFormFieldWithTest;
    userTest: UserTest;
  }>;
  const fingerstickPanelTest = fsFieldsWithTests.find(
    ({ field }) => field === 'fingerstick'
  );

  if (!fingerstickPanelTest || !fingerstickPanelTest.userTest) {
    // If there is no fingerstick panel, or corresponding user-test then we will consider
    // the service to be a non-fingerstick service, and thus return nothing
    return [];
  }

  if (getId(fingerstickPanelTest.userTest) !== getId(onsiteService.panel)) {
    // If the onsite panel doesn't match with the onsite panel, then we return nothing
    return [];
  }

  return fsFieldsWithTests;
};
