/**
 * We made the Marquee services an object {enabled: boolean} since for NOW, we only enable/disable
 * that feature. However since this is an early release, they might add more properties.
 * Only MarqueeIncentives require the additional type.
 */
export interface MarqueeService {
  enabled?: boolean;
}

/**
 * Extend the MarqueeService with the type of incentives being offered
 */
export interface MarqueeIncentiveService extends MarqueeService {
  incentiveTypes: MarqueeIncentiveTypes[];

  /** @deprecated - Use `incentiveTypes` instead as the admins now select a checkbox value */
  incentiveType?: MarqueeIncentiveTypes;
}

/**
 * Type of Incentives offered. This will defined what we redirect the users to
 * in the app.
 */
export enum MarqueeIncentiveTypes {
  REWARDS = 'rewards',
  OUTCOMES = 'outcomes'
}
