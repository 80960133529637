import { DbDocument } from './db-document';

export enum InsuranceProviderStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  DRAFT = 'draft'
}

/**
 * Represents the list of all insurance providers listed in GW Apps.
 */
export interface InsuranceProvider extends DbDocument {
  /**
   * Visibility status of the insurance provider within the AppBuilder
   */
  status: InsuranceProviderStatus;
  /**
   * The GW Apps Record ID
   */
  recordId: string;
  /**
   * The name of the Insurance Provider
   */
  name: string;
  /**
   * The short name for the insurance provider.
   * Only used in specific cases, and can use the `name`
   * property as a fallback.
   */
  shortName?: string;

  //Required length of policy number to be entered by users in insurance form
  policyNumberMaxLength?: number;

  // Required length of group number to be entered by users in insurance form
  groupNumberMaxLength?: number;
}
