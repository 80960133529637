import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { NotificationHttpService } from '../../http/notification-http.service';
import { errorReportActions } from '../error-report/error-report.actions';
import { notificationActions } from './notification.actions';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationHttpService: NotificationHttpService
  ) {}

  listNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.list),
      switchMap((action) =>
        this.notificationHttpService.list().pipe(
          map((entities) => notificationActions.listSuccess({ entities })),
          catchError((err: HttpErrorResponse) => [
            notificationActions.listFailed({ error: err.error }),
            errorReportActions.report({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.update),
      switchMap((action) =>
        this.notificationHttpService.update(action.notifications).pipe(
          map(() =>
            notificationActions.updateSuccess({
              notifications: action.notifications
            })
          ),
          catchError((err: HttpErrorResponse) => [
            notificationActions.updateFailed({ error: err.error }),
            errorReportActions.report({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.get),
      mergeMap((action) =>
        this.notificationHttpService.get(action.id).pipe(
          map((entity) => notificationActions.getSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            notificationActions.getFailed({ error: err.error }),
            errorReportActions.report({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );
}
