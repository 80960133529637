import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsApiErrorTableComponent } from './ehs-api-error-table.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [EhsApiErrorTableComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule
  ],
  exports: [EhsApiErrorTableComponent]
})
export class EhsApiErrorTableModule {}
