import { UserTest } from '../models/user-test/user-test';

/**
 * A list of user-test fields that are searched for.
 * Used for the admin-list-user-tests-route, and
 * corresponding client-side ngrx logic.
 */
export const USER_TEST_SEARCH_FIELDS: Array<keyof UserTest> = [
  'name',
  'technicalName',
  'panelNumbers',
  'primaryOrderCode',
  'orderCode',
  'labResultCode',
  'cptCode'
];
