<form #userForm="ngForm"
      (submit)="onSubmit(userForm)">
  <h1 mat-dialog-title>Update User</h1>
  <mat-dialog-content class="mat-typography">
    <ehs-user-form [user]="data.user"
                   [layout]="formLayout">
    </ehs-user-form>
  </mat-dialog-content>
  <mat-dialog-actions align="end"
                      fxLayoutGap="16px">
    <button type="button"
            class="grey-cancel"
            mat-button
            [mat-dialog-close]="false">
      Cancel
    </button>
    <button type="submit"
            color="primary"
            mat-button
            cdkFocusInitial>
      Update
    </button>
  </mat-dialog-actions>
</form>