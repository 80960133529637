import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { DateTime } from 'luxon';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[date]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateValidatorDirective,
      multi: true
    }
  ]
})
export class DateValidatorDirective implements Validator {
  /**
   * Returns if the date is valid with the format:
   * 'MM/dd/yyyy'
   * OR
   * 'yyy-MM-dd'
   */
  public validate(control: AbstractControl): {
    date: {
      value: string;
    };
  } | null {
    const { value } = control;

    if (!value) {
      // If given nothing, do nothing, as the field should be required
      return null;
    }

    if (
      DateTime.fromFormat(value, 'MM/dd/yyyy').isValid ||
      DateTime.fromFormat(value, 'yyyy-MM-dd').isValid
    ) {
      return null;
    }

    return {
      date: {
        value
      }
    };
  }
}
