import {
  CommonResponse,
  EventService,
  GcfHraEventServiceExportRequest,
  HraResult,
  OnsiteUserRegistration,
  UserResult
} from '@common';
import { createAction, props } from '@ngrx/store';

export const hraResultActions = {
  getCurrent: createAction(
    '[HRA_RESULT] GET_CURRENT',
    props<{
      saveToState?: boolean;
    }>()
  ),
  getCurrentSuccess: createAction(
    '[HRA_RESULT] GET_CURRENT_SUCCESS',
    props<{
      entity: HraResult;
    }>()
  ),
  getCurrentFailed: createAction(
    '[HRA_RESULT] GET_CURRENT_FAILED',
    props<{ error: CommonResponse }>()
  ),

  list: createAction(
    '[HRA_RESULT] LIST',
    props<{
      includeService?: boolean;
    }>()
  ),
  listSuccess: createAction(
    '[HRA_RESULT] LIST_SUCCESS',
    props<{
      entities: HraResult[];
    }>()
  ),
  listFailed: createAction(
    '[HRA_RESULT] LIST_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),
  get: createAction(
    '[HRA_RESULT] GET',
    props<{
      id: string;
      saveToState?: boolean;
    }>()
  ),
  getSuccess: createAction(
    '[HRA_RESULT] GET_SUCCESS',
    props<{
      entity: HraResult;
    }>()
  ),
  getFailed: createAction(
    '[HRA_RESULT] GET_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  create: createAction(
    '[HRA_RESULT] CREATE',
    props<{
      hraResult: Partial<HraResult>;
      saveToState?: boolean;
    }>()
  ),
  createSuccess: createAction(
    '[HRA_RESULT] CREATE_SUCCESS',
    props<{
      entity: HraResult;
    }>()
  ),
  createFailed: createAction(
    '[HRA_RESULT] CREATE_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  update: createAction(
    '[HRA_RESULT] UPDATE',
    props<{
      hraResult: HraResult;
      saveToState?: boolean;
    }>()
  ),
  updateSuccess: createAction(
    '[HRA_RESULT] UPDATE_SUCCESS',
    props<{
      entity: HraResult;
    }>()
  ),
  updateFailed: createAction(
    '[HRA_RESULT] UPDATE_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  remove: createAction(
    '[HRA_RESULT] REMOVE',
    props<{
      id: string;
    }>()
  ),
  removeSuccess: createAction(
    '[HRA_RESULT] REMOVE_SUCCESS',
    props<{
      entity: HraResult;
    }>()
  ),
  removeFailed: createAction(
    '[HRA_RESULT] REMOVE_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  getRelatedHraData: createAction(
    '[HRA_RESULT] GET_RELATED_HRA_DATA',
    props<{
      hraResult: string;
    }>()
  ),
  getRelatedHraDataSuccess: createAction(
    '[HRA_RESULT] GET_RELATED_HRA_DATA_SUCCESS',
    props<{
      eventService: EventService;
      userRegistration?: OnsiteUserRegistration;
      userResult?: UserResult;
    }>()
  ),
  getRelatedHraDataFailed: createAction(
    '[HRA_RESULT] GET_RELATED_HRA_DATA_FAILED',
    props<{ error: CommonResponse }>()
  ),

  // Admin specific
  exportServiceHras: createAction(
    '[HRA_RESULT] EXPORT_SERVICE_HRAS',
    props<Omit<GcfHraEventServiceExportRequest, 'email'>>()
  ),
  exportServiceHrasSuccess: createAction(
    '[HRA_RESULT] EXPORT_SERVICE_HRAS_SUCCESS'
  ),
  exportServiceHrasFailed: createAction(
    '[HRA_RESULT] EXPORT_SERVICE_HRAS_FAILED'
  ),

  exportAllCompanyHras: createAction('[HRA_RESULT] EXPORT_ALL_COMPANY_HRAS'),
  exportAllCompanyHrasSuccess: createAction(
    '[HRA_RESULT] EXPORT_ALL_COMPANY_HRAS_SUCCESS'
  ),
  exportAllCompanyHrasFailed: createAction(
    '[HRA_RESULT] EXPORT_ALL_COMPANY_HRAS_FAILED'
  )
};
