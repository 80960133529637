import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retryWithBackOff } from './retry-with-back-off';

@Injectable({
  providedIn: 'root'
})
export class HttpRetryInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has('no-retry')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      retryWithBackOff({
        // Only retry on rate limit errors
        only: [429]
      })
    );
  }
}
