import { HraForm } from './hra-form';
import { HraCategoryTypes } from './hra-category';
import {
  HraScoreCategoryTypes,
  defaultHraScoreColorDefs
} from './hra-score-category';
import { HraQuestionType } from './hra-question';
import {
  HraAnswerColors,
  hraAnswerFeedback,
  HraAnswerDisableFunc,
  HraIsCheckboxRequired
} from './hra-answer';
import { getBmi } from '../../util/get-bmi';
import { FingerStickCodes } from '../../constants/finger-stick-tests';
import { userResultHraValueHandler } from './user-result-hra-value-handler';
import { LabResultCode } from '../user-test/user-test';

/**
 * Factory to create checkboxDisabled question
 * @param questionCode the questionCode we want to be the non-disabled question.
 * Should also be the question that is set to "clear"
 */
export const checkboxDisabledFactory =
  (questionCode: string): HraAnswerDisableFunc =>
  ({ record, question, answer }) => {
    if (
      !record[question.code] ||
      !Array.isArray(record[question.code]) ||
      !record[question.code].length
    ) {
      return false;
    }

    if (answer.code === questionCode) {
      // The 25j field is NEVER disabled
      return false;
    }

    if (record[question.code].includes(questionCode)) {
      // If the field includes the 25j answer, then all fields are disabled
      return true;
    }

    // Otherwise keep this field enabled
    return false;
  };
// All answers on question 25 are disabled if 25j is selected.
export const question25Disabled = checkboxDisabledFactory('25j');

/**
 * Returns if the answer 25 or 26 should be required, this will
 * prevent the submission of the page
 */
export const isCheckboxRequired: HraIsCheckboxRequired = ({
  record,
  question
}) => {
  if (
    !record[question.code] ||
    !Array.isArray(record[question.code]) ||
    !record[question.code].length
  ) {
    // If nothing has been selected, the checkboxes are required
    return true;
  }

  if (record[question.code].length) {
    // If anything is selected, nothing is required anymore
    return false;
  }

  // Otherwise all the checkboxes are required
  return false;
};
/**
 * The current HRA form definition
 */
export const hraDef: HraForm = {
  questions: [
    {
      code: '1',
      description:
        'In a typical week, how many days do you get at least 30 minutes or more of exercise?',
      category: HraCategoryTypes.EXERCISE,
      scoreCategory: HraScoreCategoryTypes.ACTIVE_LIFESTYLE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '1a',
          description: '5 or more days per week',
          answer: 'I exercise 5 or more days per week.',
          feedback: hraAnswerFeedback.Q1_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 60
        },
        {
          code: '1b',
          description: '4 days per week',
          answer: 'I exercise 4 days per week.',
          feedback: hraAnswerFeedback.Q1_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 50
        },
        {
          code: '1c',
          description: '3 days per week',
          answer: 'I exercise 3 days per week.',
          feedback: hraAnswerFeedback.Q1_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 45
        },
        {
          code: '1d',
          description: '1 - 2 days per week',
          answer: 'I exercise 1-2 days per week.',
          feedback: hraAnswerFeedback.Q1_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 25
        },
        {
          code: '1e',
          description: 'I am currently not exercising.',
          answer: 'I am currently not exercising.',
          feedback: `Start by slowly adding more movement into your week. This could be walking or exercising 1-2 days per week for 30 minutes or more,
            and increasing these increments as you gain strength. You may find that upping your activity level gives you more energy to power through your day!`,
          color: HraAnswerColors.RED,
          value: -25
        }
      ]
    },
    {
      code: '2',
      description: 'How intense is your typical exercise?',
      category: HraCategoryTypes.EXERCISE,
      scoreCategory: HraScoreCategoryTypes.ACTIVE_LIFESTYLE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '2a',
          description:
            'Very intense (fast running, circuit weight training, soccer, etc.)',
          answer: 'The intensity of my exercise is very heavy.',
          feedback: hraAnswerFeedback.Q2_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 60
        },
        {
          code: '2b',
          description:
            'Intense (jogging, steady pace swimming, vigorous push-ups, tennis, etc.)',
          answer: 'The intensity of my exercise is heavy.',
          feedback: hraAnswerFeedback.Q2_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '2c',
          description:
            'Moderate intensity (brisk walking, weight training, dancing, recreational swimming, yoga, etc.)',
          answer: 'The intensity of my exercise is moderate.',
          feedback: hraAnswerFeedback.Q2_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 40
        },
        {
          code: '2d',
          description: 'Light intensity (stretching, walking, etc.)',
          answer: 'The intensity of my exercise is light.',
          feedback: hraAnswerFeedback.Q2_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 20
        }
      ],
      /**
       * Only show if the user picked 1a-1d in question 1
       */
      show: ({ record }) =>
        record &&
        record['1'] &&
        ['1a', '1b', '1c', '1d'].includes(record['1'] as string)
    },
    {
      code: '2A',
      description: 'I am interested in increasing my level of exercise and...',
      category: HraCategoryTypes.EXERCISE,
      scoreCategory: HraScoreCategoryTypes.ACTIVE_LIFESTYLE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '2Aa',
          description:
            'I have started a program to increase my level of exercise.',
          answer: 'I have started a program to increase my level exercise.',
          feedback: hraAnswerFeedback.Q2A_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 20
        },
        {
          code: '2Ab',
          description:
            'I plan to increase my level of exercise in the next 3 months.',
          answer:
            'I plan to increase my level of exercise in the next 3 months.',
          feedback: hraAnswerFeedback.Q2A_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 10
        },
        {
          code: '2Ac',
          description:
            'I think about increasing my level of exercise in the next 6 months.',
          answer:
            'I think about increasing my level of exercise in the next 6 months.',
          feedback: hraAnswerFeedback.Q2A_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 5
        },
        {
          code: '2Ad',
          description: 'I have no plans to change my exercise level.',
          answer: 'I have no plans to change my exercise level.',
          feedback: `Don't get discouraged! Exercise and physical activity are great ways to feel better,
            boost your health and have fun. It is important for you to create an exercise program that will fit within your lifestyle.`,
          color: HraAnswerColors.RED,
          value: 0
        }
      ],
      /**
       * Only show if the user selected 2c or 2d in question 2.
       */
      show: ({ record }) =>
        record &&
        (record['1'] === '1e' ||
          (record['2'] && ['2c', '2d'].includes(record['2'])))
    },
    {
      code: '3',
      description:
        'Do you fasten your seatbelt when you are in a car or moving vehicle?',
      category: HraCategoryTypes.SAFETY,
      scoreCategory: HraScoreCategoryTypes.PERSONAL_CARE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '3a',
          description: 'Always',
          answer: 'I always wear my seatbelt when in a car or moving vehicle.',
          feedback: hraAnswerFeedback.Q3_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 40
        },
        {
          code: '3b',
          description: 'Most of the time',
          answer:
            'Most of the time I wear my seatbelt when in a car or moving vehicle.',
          feedback: hraAnswerFeedback.Q3_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '3c',
          description: 'Not often enough',
          answer:
            'I do not wear my seatbelt often enough when in a car or moving vehicle.',
          feedback: hraAnswerFeedback.Q3_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: -25
        },
        {
          code: '3d',
          description: 'Never	',
          answer: 'I never wear my seatbelt when in a car or moving vehicle.',
          feedback: hraAnswerFeedback.Q3_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -40
        }
      ]
    },
    {
      code: '4',
      description: 'Do you follow the rules and laws when driving?',
      category: HraCategoryTypes.SAFETY,
      scoreCategory: HraScoreCategoryTypes.PERSONAL_CARE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '4a',
          description: 'Always',
          answer: 'I always follow the rules and laws when driving.',
          feedback: hraAnswerFeedback.Q4_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 40
        },
        {
          code: '4b',
          description: 'Most of the time',
          answer: 'Most of the time I follow the rules and laws when driving.',
          feedback: hraAnswerFeedback.Q4_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '4c',
          description: 'Not often enough',
          answer:
            'I do not follow the rules and laws often enough when driving.',
          feedback: hraAnswerFeedback.Q4_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: -25
        },
        {
          code: '4d',
          description: 'Never',
          answer: 'I never follow the rules and laws when driving.',
          feedback: hraAnswerFeedback.Q4_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -40
        }
      ]
    },
    // New question!
    {
      code: '5',
      description:
        'In the past month, how often have you felt calm or peaceful?',
      category: HraCategoryTypes.DEPRESSION,
      scoreCategory: HraScoreCategoryTypes.EMOTIONAL_HEALTH,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '5a',
          description: 'All of the time',
          answer:
            'I have felt calm or peaceful all of the time in the past month.',
          feedback: hraAnswerFeedback.Q5_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '5b',
          description: 'Most of the time',
          answer:
            'I have felt calm or peaceful most of the time in the past month.',
          feedback: hraAnswerFeedback.Q5_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '5c',
          description: 'A moderate amount',
          answer:
            'I have felt calm or peaceful a moderate amount of time in the past month.',
          feedback: hraAnswerFeedback.Q5_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '5d',
          description: 'A little',
          answer:
            'I have felt calm or peaceful a little amount of time in the past month.',
          feedback: hraAnswerFeedback.Q5_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '5e',
          description: 'Not at all',
          answer: 'I have not felt calm or peaceful at all in the past month.',
          feedback: hraAnswerFeedback.Q5_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -50
        }
      ]
    },
    {
      code: '6',
      description:
        'In the past month, how often have you felt down or depressed?',
      category: HraCategoryTypes.DEPRESSION,
      scoreCategory: HraScoreCategoryTypes.EMOTIONAL_HEALTH,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '6a',
          description: 'Not at all',
          answer: 'I have not felt down or depressed at all in the past month.',
          feedback: hraAnswerFeedback.Q6_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '6b',
          description: 'A little',
          answer: 'I have felt down or depressed a little in the past month.',
          feedback: hraAnswerFeedback.Q6_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '6c',
          description: 'A moderate amount',
          answer:
            'I have felt down or depressed a moderate amount in the past month.',
          feedback: hraAnswerFeedback.Q6_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '6d',
          description: 'Most of the time',
          answer:
            'I have felt down or depressed most of the time in the past month.',
          feedback: hraAnswerFeedback.Q6_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '6e',
          description: 'All of the time',
          answer:
            'I have felt down or depressed all of the time in the past month.',
          feedback: hraAnswerFeedback.Q6_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -50
        }
      ]
    },
    {
      code: '7',
      description:
        'In the past month, how often have you felt overwhelmed with pressure or stress?',
      category: HraCategoryTypes.STRESS,
      scoreCategory: HraScoreCategoryTypes.EMOTIONAL_HEALTH,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '7a',
          description: 'Not at all',
          answer:
            'I have not felt overwhelmed with pressure or stress at all in the past month.',
          feedback: hraAnswerFeedback.Q7_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '7b',
          description: 'A little',
          answer:
            'I have felt a little overwhelmed with pressure or stress in the past month.',
          feedback: hraAnswerFeedback.Q7_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '7c',
          description: 'A moderate amount',
          answer:
            'I have felt overwhelmed with pressure or stress a moderate amount of time in the past month.',
          feedback: hraAnswerFeedback.Q7_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '7d',
          description: 'Most of the time',
          answer:
            'I have felt overwhelmed with pressure or stress most of the time in the past month.',
          feedback: hraAnswerFeedback.Q7_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '7e',
          description: 'All of the time	',
          answer:
            'I have felt overwhelmed with pressure or stress all of the time in the past month.',
          feedback: hraAnswerFeedback.Q7_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -50
        }
      ]
    },
    // **Note** this should be hidden based upon previous selections
    // but it shown to test multi-select
    {
      code: '7A',
      description:
        'What areas of your life make you feel stressed, anxious or on edge?',
      category: HraCategoryTypes.STRESS,
      scoreCategory: HraScoreCategoryTypes.EMOTIONAL_HEALTH,
      type: HraQuestionType.MULTI,
      answers: [
        {
          code: '7A a',
          description: 'Home',
          answer: undefined,
          feedback: undefined,
          value: undefined,
          icon: 'home'
        },
        {
          code: '7A b',
          description: 'Work',
          answer: undefined,
          feedback: undefined,
          value: undefined,
          icon: 'work'
        },
        {
          code: '7A c',
          description: 'Relationships',
          answer: undefined,
          feedback: undefined,
          value: undefined,
          icon: 'supervisor_account'
        },
        {
          code: '7A d',
          description: 'Finances',
          answer: undefined,
          feedback: undefined,
          value: undefined,
          icon: 'attach_money'
        },
        {
          code: '7A e',
          description: 'Health',
          answer: undefined,
          feedback: undefined,
          value: undefined,
          icon: 'local_hospital'
        },
        {
          code: '7A f',
          description: 'World Events',
          answer: undefined,
          feedback: undefined,
          value: undefined,
          icon: 'public'
        }
      ],
      /**
       * Only show if 7c-7e were selected on question 7
       */
      show: ({ record }) =>
        record &&
        record['7'] &&
        ['7c', '7d', '7e'].includes(record['7'] as string)
    },
    {
      code: '8',
      description: 'How often do you stress over finances?',
      category: HraCategoryTypes.STRESS,
      scoreCategory: HraScoreCategoryTypes.EMOTIONAL_HEALTH,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '8a',
          description: 'Not at all',
          answer: 'I do not stress over finances at all.',
          feedback: hraAnswerFeedback.Q8_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 25
        },
        {
          code: '8b',
          description: 'A little',
          answer: 'I stress over finances a little.',
          feedback: hraAnswerFeedback.Q8_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 25
        },
        {
          code: '8c',
          description: 'A moderate amount',
          answer: 'I stress over finances some of the time.',
          feedback: hraAnswerFeedback.Q8_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '8d',
          description: 'Most of the time',
          answer: 'I stress over finances most of the time.',
          feedback: hraAnswerFeedback.Q8_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '8e',
          description: 'All of the time',
          answer: 'I stress over finances all of the time.',
          feedback: hraAnswerFeedback.Q8_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ]
    },
    {
      code: '9',
      description:
        'Are you interested in decreasing the amount of stress in your life?',
      category: HraCategoryTypes.STRESS,
      scoreCategory: HraScoreCategoryTypes.EMOTIONAL_HEALTH,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '9a',
          description: 'I rarely feel stressed.',
          answer: 'I rarely feel stressed.',
          feedback: hraAnswerFeedback.Q9_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '9b',
          description: 'I recently started working on decreasing stress.',
          answer: 'I recently started working on decreasing stress.',
          feedback: hraAnswerFeedback.Q9_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '9c',
          description: 'I will work on decreasing stress in the next 3 months.',
          answer: 'I will work on decreasing stress in the next 3 months.',
          feedback: hraAnswerFeedback.Q9_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '9d',
          description: 'I think about changing in the next 6 months.',
          answer: 'I think about changing in the next 6 months.',
          feedback: hraAnswerFeedback.Q9_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 0
        },
        {
          code: '9e',
          description: 'I have no plans to change at this time.',
          answer: 'I do not plan to decrease stress at this time.',
          feedback: hraAnswerFeedback.Q9_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ]
    },
    {
      code: '10',
      description:
        'How many servings of high fiber or whole grain foods do you typically eat each day? ' +
        '(Examples: 1 serving = 1 slice of 100% whole wheat bread, 1 cup of whole-grain or high-fiber ' +
        'ready-to-eat cereal, 1/2 cup cooked cereal such as oatmeal, 1/2 cup of cooked brown rice or whole wheat pasta)',
      category: HraCategoryTypes.NUTRITION,
      scoreCategory: HraScoreCategoryTypes.NUTRITION,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '10a',
          description: '6 or more',
          answer:
            'I eat 6 or more servings of high fiber or whole grain foods each day.',
          feedback: hraAnswerFeedback.Q10_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '10b',
          description: '5',
          answer:
            'I eat 5 servings of high fiber or whole grain foods each day.',
          feedback: hraAnswerFeedback.Q10_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '10c',
          description: '3 - 4',
          answer:
            'I eat 3-4 servings of high fiber or whole grain foods each day.',
          feedback: hraAnswerFeedback.Q10_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '10d',
          description: '1- 2',
          answer:
            'I eat 1-2 servings of high fiber or whole grain foods each day.',
          feedback: hraAnswerFeedback.Q10_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '10e',
          description: '0',
          answer: 'I do not eat any high fiber or whole grain foods each day.',
          feedback: hraAnswerFeedback.Q10_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ]
    },
    {
      // NOTE this question has MOVED to a different category
      code: '11',
      description:
        'How many sugar-sweetened beverages per day do you typically drink?',
      category: HraCategoryTypes.GLUCOSE,
      scoreCategory: HraScoreCategoryTypes.DIABETES_PREVENTION,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '11a',
          description: '0',
          answer: 'I do not drink sugar-sweetened beverages on a typical day.',
          feedback: hraAnswerFeedback.Q11_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 10
        },
        {
          code: '11b',
          description: '1',
          answer: 'I consume 1 sugar-sweetened beverage on a typical day.',
          feedback: hraAnswerFeedback.Q11_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 5
        },
        {
          code: '11c',
          description: '2-3',
          answer: 'I consume 2-3 sugar-sweetened beverages on a typical day.',
          feedback: hraAnswerFeedback.Q11_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -5
        },
        {
          code: '11d',
          description: '4 or more',
          answer:
            'I consume 4 or more sugar-sweetened beverages on a typical day.',
          feedback: hraAnswerFeedback.Q11_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -10
        }
      ]
    },
    {
      code: '12',
      description:
        'How many 8-ounce servings of water per day do you typically drink?',
      category: HraCategoryTypes.NUTRITION,
      scoreCategory: HraScoreCategoryTypes.NUTRITION,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '12a',
          description: '7 or more',
          answer: 'I drink 7 or more servings of 8 ounces of water per day.',
          feedback: hraAnswerFeedback.Q12_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '12b',
          description: '4 - 6',
          answer: 'I drink 4-6 servings of 8 ounces of water per day.',
          feedback: hraAnswerFeedback.Q12_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 40
        },
        {
          code: '12c',
          description: '2 - 3',
          answer: 'I drink 2-3 servings of 8 ounces of water per day.',
          feedback: hraAnswerFeedback.Q12_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '12d',
          description: '1',
          answer: 'I drink 1 serving of 8 ounces of water per day.',
          feedback: hraAnswerFeedback.Q12_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '12e',
          description: '0',
          answer: 'I do not drink any servings of water per day.',
          feedback: hraAnswerFeedback.Q12_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ]
    },
    {
      code: '13',
      description: `How many servings of fried or high-fat foods do you typically eat each day?
        (Examples: fried chicken or fish, bacon, french fries, potato or corn chips, doughnuts,
        creamy salad dressings, and foods made with whole milk, cream, cheese, mayo)`,
      category: HraCategoryTypes.NUTRITION,
      scoreCategory: HraScoreCategoryTypes.NUTRITION,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '13a',
          description: '0',
          answer: 'I do not eat any fried or high-fat foods per day.',
          feedback: hraAnswerFeedback.Q13_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '13b',
          description: '1',
          answer:
            'I typically eat 1 serving of fried or high-fat foods per day.',
          feedback: hraAnswerFeedback.Q13_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '13c',
          description: '2 - 3',
          answer:
            'I typically eat 2-3 servings of fried or high-fat foods per day.',
          feedback: hraAnswerFeedback.Q13_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '13d',
          description: '4 - 7',
          answer:
            'I typically eat 4-7 servings of fried or high-fat foods per day.',
          feedback: hraAnswerFeedback.Q13_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '13e',
          description: 'More than 7',
          answer:
            'I typically eat more than 7 servings of fried or high-fat foods per day.',
          feedback: hraAnswerFeedback.Q13_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ]
    },
    {
      code: '14',
      description:
        'How many servings of fruits and vegetables do you typically eat each day? ' +
        '(Examples: 1 serving = 1 cup of fresh vegetables, 1/2 cup cooked vegetables, 1 cup of fruit or 100% fruit juice)',
      category: HraCategoryTypes.NUTRITION,
      scoreCategory: HraScoreCategoryTypes.NUTRITION,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '14a',
          description: '5 or more',
          answer:
            'I typically eat 5 or more servings of fruits and vegetables each day.',
          feedback: hraAnswerFeedback.Q14_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '14b',
          description: '3 - 4',
          answer:
            'I typically eat 3-4 servings of fruits and vegetables each day.',
          feedback: hraAnswerFeedback.Q14_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '14c',
          description: '1-2',
          answer:
            'I typically eat 1-2 servings of fruits and vegetables each day.',
          feedback: hraAnswerFeedback.Q14_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '14d',
          description: 'Occasionally, less than one per day.',
          answer:
            'I typically eat less than one serving of fruits and vegetables each day.',
          feedback: hraAnswerFeedback.Q14_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '14e',
          description: '0',
          answer: 'I do not eat any servings of fruits or vegetables each day.',
          feedback: hraAnswerFeedback.Q14_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ]
    },
    {
      code: '15',
      description:
        'Would you like to improve your nutrition and eating habits?',
      category: HraCategoryTypes.NUTRITION,
      scoreCategory: HraScoreCategoryTypes.NUTRITION,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '15a',
          description: 'I meet all recommended levels for nutrition.',
          answer: 'I meet all recommended levels for nutrition.',
          feedback: hraAnswerFeedback.Q15_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 25
        },
        {
          code: '15b',
          description: 'I am actively working on improving.',
          answer:
            'I am actively working on improving my nutrition and eating habits.',
          feedback: hraAnswerFeedback.Q15_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 20
        },
        {
          code: '15c',
          description: 'I have recently started improving.',
          answer:
            'I have recently started to improve my nutrition and eating habits.',
          feedback: hraAnswerFeedback.Q15_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 10
        },
        {
          code: '15d',
          description: 'I plan to improve in the next 12 months.',
          answer:
            'I plan to improve my nutrition and eating habits in the next 12 months.',
          feedback: hraAnswerFeedback.Q15_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 0
        },
        {
          code: '15e',
          description: 'I have no plans to change at this time.',
          answer:
            'I have no plans to change my nutrition and eating habits at this time.',
          feedback: hraAnswerFeedback.Q15_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ]
    },
    {
      code: '16',
      description:
        'Have you ever used tobacco products (cigarettes, e-cigarettes, cigars, pipe, snuff, chewing tobacco, etc.)?',
      category: HraCategoryTypes.NICOTINE_USE,
      scoreCategory: HraScoreCategoryTypes.TOBACCO_USE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '16a',
          description: 'No',
          answer: 'I have never used tobacco products.',
          feedback: hraAnswerFeedback.Q16_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 100
        },
        {
          code: '16b',
          description: `Yes, I'm a current user.`,
          answer: 'I am a current user of tobacco products.',
          feedback: hraAnswerFeedback.Q16_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -75
        },
        {
          code: '16c',
          description: 'I did, but I quit.',
          answer: 'I used tobacco products but quit.',
          feedback: hraAnswerFeedback.Q16_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 65
        }
      ]
    },
    {
      code: '16A',
      description: 'Are you interested in quitting?',
      category: HraCategoryTypes.NICOTINE_USE,
      scoreCategory: HraScoreCategoryTypes.TOBACCO_USE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '16A a',
          description: 'I am currently in a program to quit.',
          answer: 'I am currently in a program to quit using tobacco.',
          feedback: hraAnswerFeedback.Q16A_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 100
        },
        {
          code: '16A b',
          description: 'I plan to quit in the next 3 months.',
          answer: 'I plan to quit using tobacco in the next 3 months.',
          feedback: hraAnswerFeedback.Q16A_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 25
        },
        {
          code: '16A c',
          description:
            'I have attempted quitting and plan to quit in the next year.',
          answer:
            'I ave attempted to quit using tobacco and plan to quit in the next year.',
          feedback: hraAnswerFeedback.Q16A_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 25
        },
        {
          code: '16A d',
          description: 'I have no plans to quit at this time.',
          answer: 'I have no plans to quit using tobacco at this time.',
          feedback: hraAnswerFeedback.Q16A_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ],
      /**
       * Only show if 16b was selected in question 16
       */
      show: ({ record }) =>
        record && record['16'] && (record['16'] as string) === '16b'
    },
    {
      code: '17',
      description:
        'Considering your age, how would you describe your overall physical health?',
      category: HraCategoryTypes.HEALTH_HISTORY,
      scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '17a',
          description: 'Excellent',
          answer: 'I would consider myself to be in excellent physical health.',
          feedback: hraAnswerFeedback.Q17_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 40
        },
        {
          code: '17b',
          description: 'Very Good',
          answer: 'I would consider myself to be in very good physical health.',
          feedback: hraAnswerFeedback.Q17_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 40
        },
        {
          code: '17c',
          description: 'Good',
          answer: 'I would consider myself to be in good physical health.',
          feedback: `Overall, you feel that you are in good health but that there is room for improvement.
            Continuing to exercise, practice self-care, maintain a nutritious diet, and get enough sleep can boost
            your perceived health (and keep you healthy)!`,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '17d',
          description: 'Fair',
          answer: 'I would consider myself to be in fair physical health.',
          feedback: hraAnswerFeedback.Q17_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '17e',
          description: 'Poor',
          answer: 'I would consider myself to be in poor physical health.',
          feedback: hraAnswerFeedback.Q17_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ]
    },
    // {
    //   code: '18',
    //   description:
    //     'In the past 7 days, on average, how much bodily pain have you felt?',
    //   category: HraCategoryTypes.CURRENT_HEALTH,
    //   scoreCategory: HraScoreCategoryTypes.HEALTH_HISTORY,
    //   type: HraQuestionType.SINGLE,
    //   answers: [
    //     { code: '18a', description: 'None', value: 50 },
    //     { code: '18b', description: 'Mild', value: 25 },
    //     { code: '18c', description: 'Moderate', value: 25 },
    //     { code: '18d', description: 'Severe', value: 0 },
    //     { code: '18e', description: 'Very Sever', value: -25 }
    //   ]
    // },
    {
      code: '19',
      description: `How long has it been since you've seen your primary care physician?`,
      category: HraCategoryTypes.HEALTH_HISTORY,
      scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '19a',
          description: 'Within the past 12 months',
          answer:
            'I have seen my primary care physician within the past 12 months.',
          feedback: hraAnswerFeedback.Q19_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 30
        },
        {
          code: '19b',
          description: 'Between 12 to 24 months',
          answer:
            'I have seen my primary care physician within the last 12 to 24 months.',
          feedback: hraAnswerFeedback.Q19_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 20
        },
        {
          code: '19c',
          description: 'More than 2 years',
          answer:
            'It has been more than 2 years since I have seen my primary care physician.',
          feedback: hraAnswerFeedback.Q19_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '19d',
          description: `I don't go to a doctor.`,
          answer: 'I do not go to see a primary care physician.',
          feedback: hraAnswerFeedback.Q19_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ]
    },
    {
      code: '20',
      description: 'Do you receive a yearly flu shot?',
      category: HraCategoryTypes.DISEASE_PREVENTION,
      scoreCategory: HraScoreCategoryTypes.PERSONAL_CARE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '20a',
          description: 'Yes',
          answer: 'I receive a yearly flu shot.',
          feedback: hraAnswerFeedback.Q20_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '20b',
          description: 'No',
          answer: 'I do not receive a yearly flu shot.',
          feedback: hraAnswerFeedback.Q20_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -50
        }
      ]
    },
    {
      code: '21',
      description:
        'Do you wash your hands after visiting the restroom, handling food, and before you eat?',
      category: HraCategoryTypes.DISEASE_PREVENTION,
      scoreCategory: HraScoreCategoryTypes.PERSONAL_CARE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '21a',
          description: 'Always',
          answer:
            'I always wash my hands after visiting the restroom, handling food and before I eat.',
          feedback: hraAnswerFeedback.Q21_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '21b',
          description: 'Most of the time',
          answer:
            'I wash my my hands most of the time after visiting the restroom, handling food and before I eat.',
          feedback: hraAnswerFeedback.Q21_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '21c',
          description: 'Not often enough',
          answer:
            'I do not wash my hands often enough after visiting the restroom, handling food of before I eat.',
          feedback: hraAnswerFeedback.Q21_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '21d',
          description: 'Never',
          answer:
            'I never wash my hands after visiting the restroom, handling food or before I eat.',
          feedback: hraAnswerFeedback.Q21_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -50
        }
      ]
    },
    {
      code: '22',
      description: 'Over the past 7 days, how many days did you drink alcohol?',
      category: HraCategoryTypes.ALCOHOL_USE,
      scoreCategory: HraScoreCategoryTypes.ALCOHOL_USE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '22a',
          description: '0',
          answer: 'I did not drink alcohol this week.',
          feedback: hraAnswerFeedback.Q22_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 75
        },
        {
          code: '22b',
          description: '1',
          answer: 'I drank alcohol 1 day in the past week.',
          feedback: hraAnswerFeedback.Q22_CONGRATS,
          color: undefined,
          value: undefined
        },
        {
          code: '22c',
          description: '2',
          answer: 'I drank alcohol 2 days in the past week.',
          feedback: hraAnswerFeedback.Q22_CONGRATS,
          color: undefined,
          value: undefined
        },
        {
          code: '22d',
          description: '3',
          answer: 'I drank alcohol 3 days in the past week.',
          feedback: hraAnswerFeedback.Q22_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: undefined
        },
        {
          code: '22e',
          description: '4',
          answer: 'I drank alcohol 4 days in the past week.',
          feedback: hraAnswerFeedback.Q22_CONGRATS,
          color: undefined,
          value: undefined
        },
        {
          code: '22f',
          description: '5',
          answer: 'I drank alcohol 5 days in the past week.',
          feedback: hraAnswerFeedback.Q22_CONGRATS,
          color: undefined,
          value: undefined
        },
        {
          code: '22g',
          description: '6',
          answer: 'I drank alcohol 6 days in the past week.',
          feedback: hraAnswerFeedback.Q22_CONGRATS,
          color: undefined,
          value: undefined
        },
        {
          code: '22h',
          description: '7',
          answer: 'I drank alcohol every day in the past week.',
          feedback: hraAnswerFeedback.Q22_CONGRATS,
          color: undefined,
          value: undefined
        }
      ]
    },
    {
      code: '22A',
      description:
        'On the days you drank alcohol, on average how many drinks did you have?',
      category: HraCategoryTypes.ALCOHOL_USE,
      scoreCategory: HraScoreCategoryTypes.ALCOHOL_USE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '22A a',
          description: '1 - 2',
          answer: 'I drank 1-2 drinks on average in a day.',
          feedback: hraAnswerFeedback.Q22A_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 75
        },
        {
          code: '22A b',
          description: '3',
          answer: 'I drank 3 drinks on average in a day.',
          feedback: `Consider monitoring your alcohol intake. Moderate alcohol consumption is
            defined as having up to 1 drink per day for women and up to 2 drinks per day for men.
            This refers to the amount consumed on any single day and is not intended as an average over several days.
            Drinking less can reduce the chance of several health risks. `,
          color: HraAnswerColors.YELLOW,
          value: 0
        },
        {
          code: '22A c',
          description: '4 - 5',
          answer: 'I drank 4-5 drinks on average in a day.',
          feedback: hraAnswerFeedback.Q22A_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -75
        },
        {
          code: '22A d',
          description: '6 or more',
          answer: 'I drank 6 or more drinks on average in a day.',
          feedback: hraAnswerFeedback.Q22A_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -100
        }
      ],
      /**
       * Only shown if ANY of the answers in 22, except 22a are selected
       */
      show: ({ record }) => record && record['22'] && record['22'] !== '22a'
    },
    {
      code: '22 B',
      description:
        'Are you interested in decreasing the amount of alcohol you drink?',
      category: HraCategoryTypes.ALCOHOL_USE,
      scoreCategory: HraScoreCategoryTypes.ALCOHOL_USE,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '22B a',
          description: 'I am currently decreasing my alcohol usage.',
          answer: 'I am currently decreasing my alcohol usage.',
          feedback: hraAnswerFeedback.Q22B_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 25
        },
        {
          code: '22B b',
          description: 'I plan to change in the next 3 months.',
          answer: 'I plan to decrease my alcohol usage in the next 3 months.',
          feedback: hraAnswerFeedback.Q22B_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '22B c',
          description: 'I think about changing.',
          answer: 'I think about decreasing my alcohol usage.',
          feedback: hraAnswerFeedback.Q22B_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '22B d',
          description: 'I have no plans to change at this time.',
          answer: 'I have no plans to decrease my alcohol usage.',
          feedback: `To live a healthier lifestyle, it is recommended that you decrease the amount
            of alcohol if you exceed the recommended amounts. If you do decide to change your drinking habits,
            there are plenty of programs and support systems that can help you achieve this goal. `,
          color: HraAnswerColors.RED,
          value: -25
        }
      ],
      /**
       * Only show if "22A a" was not selected in question 22A.
       */
      show: ({ record }) => record && record['22A'] && record['22A'] !== '22A a'
    },
    {
      code: '23',
      description: 'On average, how many hours of sleep do you get each night?',
      category: HraCategoryTypes.SLEEP,
      scoreCategory: HraScoreCategoryTypes.SLEEP,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '23a',
          description: 'More than 8 hours',
          answer: 'I get more than 8 hours of sleep each night.',
          feedback: hraAnswerFeedback.Q23_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '23b',
          description: '7 to 8 hours',
          answer: 'I get 7 to 8 hours of sleep each night.',
          feedback: hraAnswerFeedback.Q23_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '23c',
          description: '6 to 7 hours',
          answer: 'I get 6 to 7 hours of sleep each night.',
          feedback: hraAnswerFeedback.Q23_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '23d',
          description: 'More than 4 but less than 6',
          answer: 'I get 4 to 6 hours of sleep each night.',
          feedback: hraAnswerFeedback.Q23_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '23e',
          description: '4 or less hours',
          answer: 'I get 4 or less hours of sleep each night.',
          feedback: hraAnswerFeedback.Q23_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -50
        }
      ]
    },
    {
      code: '24',
      description:
        'In general, how would you describe the quality of sleep that you get?',
      category: HraCategoryTypes.SLEEP,
      scoreCategory: HraScoreCategoryTypes.SLEEP,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '24a',
          description: 'I sleep well.',
          answer: 'I sleep well.',
          feedback: hraAnswerFeedback.Q24_CONGRATS,
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: '24b',
          description:
            'I usually sleep well but occasionally I have difficulties.',
          answer: 'I sleep well but have occasional difficulties.',
          feedback: hraAnswerFeedback.Q24_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '24c',
          description: 'I often have sleep difficulties.',
          answer: 'I often have sleep difficulties.',
          feedback: hraAnswerFeedback.Q24_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '24d',
          description:
            'I regularly have sleep difficulties and usually sleep poorly.',
          answer:
            'I regularly have sleep difficulties and usually sleep poorly.',
          feedback: hraAnswerFeedback.Q24_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -50
        }
      ]
    },
    {
      code: '24A',
      description: 'Do you believe you could improve your sleep habits?',
      category: HraCategoryTypes.SLEEP,
      scoreCategory: HraScoreCategoryTypes.SLEEP,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: '24A a',
          description: 'I am currently working on this.',
          answer: 'I am currently working on improving my sleep habits.',
          feedback:
            'As you know, improving your sleep begins with identifying factors of ' +
            'poor sleep and a commitment to make a change. You are on the right track to a healthier and more enjoyable life! ',
          color: HraAnswerColors.GREEN,
          value: 25
        },
        {
          code: '24A b',
          description: 'I will change in the next 3 months.',
          answer: 'I will improve my sleep habits in the next 3 months.',
          feedback: hraAnswerFeedback.Q24A_CONGRATS,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: '24A c',
          description: 'I think about changing.',
          answer:
            'I think about improving my sleep habits in the next 3 months.',
          feedback: hraAnswerFeedback.Q24A_CONGRATS,
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: '24A d',
          description: 'I have no plans to change at this time.',
          answer:
            'I have no plans to improve my sleep habits in the next 3 months.',
          feedback: hraAnswerFeedback.Q24A_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        }
      ],
      /**
       * This should only be shown if 24c or 24d was selected in question 24
       */
      show: ({ record }) =>
        record &&
        record['24'] &&
        ['24c', '24d'].includes(record['24'] as string)
    },
    {
      code: '25',
      description:
        'Do you have or have you been diagnosed with any of the following conditions?',
      category: HraCategoryTypes.HEALTH_HISTORY,
      scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
      type: HraQuestionType.MULTI,
      answers: [
        // **Note** the values do not matter in this question
        // As it has a custom value
        {
          code: '25a',
          description: 'Anxiety or Depression',
          answer:
            'You selected you have been diagnosed with Anxiety or Depression.',
          feedback: '',
          color: undefined,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.EMOTIONAL_HEALTH,
          disable: question25Disabled,
          isCheckboxRequired
        },
        {
          code: '25b',
          description: 'Asthma, Bronchitis or Emphysema',
          answer:
            'You selected you have been diagnosed with Asthma, Bronchitis or Emphysema.',
          feedback: '',
          color: undefined,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
          disable: question25Disabled,
          isCheckboxRequired
        },
        {
          code: '25c',
          description: 'Cancer',
          answer: 'You selected you have been diagnosed with Cancer.',
          feedback: '',
          color: undefined,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
          disable: question25Disabled,
          isCheckboxRequired
        },
        {
          code: '25d',
          description: 'Diabetes',
          answer: 'You selected you have been diagnosed with Diabetes.',
          feedback: '',
          color: undefined,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.DIABETES_PREVENTION,
          disable: question25Disabled,
          isCheckboxRequired
        },
        {
          code: '25e',
          description: 'Heart Disease',
          answer: 'You selected you have been diagnosed with Heart Disease.',
          feedback: '',
          color: undefined,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.HEART_HEALTH,
          disable: question25Disabled,
          isCheckboxRequired
        },
        {
          code: '25f',
          description: 'High Blood Pressure',
          answer:
            'You selected you have been diagnosed with High Blood Pressure.',
          feedback: '',
          color: undefined,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.HEART_HEALTH,
          disable: question25Disabled,
          isCheckboxRequired
        },
        {
          code: '25g',
          description: 'High Cholesterol',
          answer: 'You selected you have been diagnosed with High Cholesterol.',
          feedback: '',
          color: undefined,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.HEART_HEALTH,
          disable: question25Disabled,
          isCheckboxRequired
        },
        {
          code: '25h',
          description: 'Sleep Disturbance or Sleep Apnea',
          answer:
            'You selected you have been diagnosed with Sleep Disturbance or Sleep Apnea.',
          feedback: '',
          color: undefined,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
          disable: question25Disabled,
          isCheckboxRequired
        },
        {
          // New answer
          code: '25i',
          description: 'Other serious health problem not listed above.',
          answer:
            'You selected you have been diagnosed with another serious health problem that was not included in the selection list.',
          feedback: '',
          color: undefined,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.SLEEP,
          disable: question25Disabled,
          isCheckboxRequired
        },
        {
          code: '25j',
          description: 'No serious health problem.',
          answer: 'You selected that you have no serious health problem.',
          feedback: '',
          color: undefined,
          value: 100,
          scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
          disable: question25Disabled,
          // When selected we clear the other values
          clear: true
        }
      ],
      /**
       * This field has more complex with #779.
       * This question's score calculation primarily impacts the current_health score, but
       * also impacts multiple different categories.
       * As before, if the user selects 25j, we give the user a score of 100, regardless
       * of what else the user selected.
       * Where we start with a score of 100, and for each answer checked, we add subtract 25
       * Down to zero.
       * Along with this, for each answer, we also subtract -5 from each answers category.
       *
       */
      customScoreCalc: ({ scores, question, record, answerMap }) => {
        if (!record) {
          return scores;
        }

        if (!record[question.code] || !Array.isArray(record[question.code])) {
          return scores;
        }
        const answers = answerMap[question.code];
        const values = record[question.code] as string[];

        // If any of the values are '26j', then we award 100 points
        if (values.includes('25j')) {
          return {
            ...scores,
            [HraScoreCategoryTypes.CURRENT_HEALTH]:
              (scores[HraScoreCategoryTypes.CURRENT_HEALTH] || 0) + 100
          };
        }

        const { scores: updatedScore, currentHealthValue } = values.reduce(
          (acc, value) => {
            // For every answer, subtract 25 from the currentHealthValue, unless its already 0
            acc.currentHealthValue =
              acc.currentHealthValue > 0 ? acc.currentHealthValue - 25 : 0;

            const answer = answers[value];

            if (!answer || !answer.scoreCategory) {
              // For some reason no answer was found with the code,
              return acc;
            }
            const newValue = (acc.scores[answer.scoreCategory] || 0) + -5;

            acc.scores[answer.scoreCategory] = newValue;

            return acc;
          },
          {
            scores,
            // This is the starting value we will subtract 25 from for every value until 0
            // After we will add this to the current_health category
            currentHealthValue: 100
          }
        );

        return {
          ...updatedScore,
          [HraScoreCategoryTypes.CURRENT_HEALTH]:
            (scores[HraScoreCategoryTypes.CURRENT_HEALTH] || 0) +
            currentHealthValue
        };
      }
    },
    {
      code: '26',
      description:
        'Are you currently being treated or take medications for the following conditions?',
      category: HraCategoryTypes.HEALTH_HISTORY,
      scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
      type: HraQuestionType.MULTI,
      answers: [
        {
          code: '26a',
          description: 'Anxiety or Depression',
          answer:
            'You selected you are being treated for Anxiety or Depression.',
          feedback: '',
          color: HraAnswerColors.GREEN,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.EMOTIONAL_HEALTH,
          isCheckboxRequired,
          disable: checkboxDisabledFactory('26j')
        },
        {
          code: '26b',
          description: 'Asthma, Bronchitis or Emphysema',
          answer:
            'You selected you are being treated for Asthma, Bronchitis or Emphysema.',
          feedback: '',
          color: HraAnswerColors.GREEN,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
          isCheckboxRequired,
          disable: checkboxDisabledFactory('26j')
        },
        {
          code: '26c',
          description: 'Cancer',
          answer: 'You selected you are being treated for Cancer.',
          feedback: '',
          color: HraAnswerColors.GREEN,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
          isCheckboxRequired,
          disable: checkboxDisabledFactory('26j')
        },
        {
          code: '26d',
          description: 'Diabetes',
          answer: 'You selected you are being treated for Diabetes.',
          feedback: '',
          color: HraAnswerColors.GREEN,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.DIABETES_PREVENTION,
          isCheckboxRequired,
          disable: checkboxDisabledFactory('26j')
        },
        {
          code: '26e',
          description: 'Heart Disease',
          answer: 'You selected you are being treated for Heart Disease.',
          feedback: '',
          color: HraAnswerColors.GREEN,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.DIABETES_PREVENTION,
          isCheckboxRequired,
          disable: checkboxDisabledFactory('26j')
        },
        {
          code: '26f',
          description: 'High Blood Pressure',
          answer: 'You selected you are being treated for High Blood Pressure.',
          feedback: '',
          color: HraAnswerColors.GREEN,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.DIABETES_PREVENTION,
          isCheckboxRequired,
          disable: checkboxDisabledFactory('26j')
        },
        {
          code: '26g',
          description: 'High Cholesterol',
          answer: 'You selected you are being treated for High Cholesterol.',
          feedback: '',
          color: HraAnswerColors.GREEN,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.DIABETES_PREVENTION,
          isCheckboxRequired,
          disable: checkboxDisabledFactory('26j')
        },
        {
          code: '26h',
          description: 'Sleep disturbance or Sleep Apnea',
          answer:
            'You selected you are being treated for Sleep Disturbance or Sleep Apnea.',
          feedback: '',
          color: HraAnswerColors.GREEN,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.SLEEP,
          isCheckboxRequired,
          disable: checkboxDisabledFactory('26j')
        },
        {
          code: '26i',
          description: 'Other serious health problem not listed above.',
          answer:
            'You selected you are being treated for a serious health problem that was not included in the selection list.',
          feedback: '',
          color: HraAnswerColors.GREEN,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
          isCheckboxRequired,
          disable: checkboxDisabledFactory('26j')
        },
        {
          code: '26j',
          description: 'No treatment prescribed at this time.',
          answer: 'You selected that you are not under treatment at this time.',
          feedback: '',
          color: HraAnswerColors.GREEN,
          value: undefined,
          scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
          isCheckboxRequired,
          disable: checkboxDisabledFactory('26j'),
          clear: true
        }
      ],
      /**
       * This should only show if 25i was NOT selected in question 25
       */
      show: ({ record }) =>
        record &&
        record['25'] &&
        Array.isArray(record['25']) &&
        !!record['25'].length &&
        !record['25'].includes('25j'),
      /**
       * This calculation has a similar approach as 25, except each answer selected
       * adds points back
       * 1. Starts at 0
       * 2. If 26k is selected, the user gets 45 points
       */
      customScoreCalc: ({ scores, question, record, answerMap }) => {
        if (!record) {
          return scores;
        }

        if (!record[question.code] || !Array.isArray(record[question.code])) {
          return scores;
        }
        const answers = answerMap[question.code];
        const values = record[question.code] as string[];

        return values.reduce((acc, value) => {
          const answer = answers[value];

          if (!answer || !answer.scoreCategory) {
            // For some reason no answer was found with the code,
            return acc;
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const newValue = acc[answer.scoreCategory]! + 5;

          acc[answer.scoreCategory] = newValue;

          return acc;
        }, scores);
      }
    },
    // **Note** there is no question 27
    {
      code: '27a',
      description: 'My current height in feet and inches is:',
      category: HraCategoryTypes.HEALTH_HISTORY,
      scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
      // The following attributes are handled by a custom component handler
      // defined within the template itself as this is a special case
      answers: [],
      type: HraQuestionType.CUSTOM
    },
    {
      code: '27b',
      description: 'My current height in inches is:',
      category: HraCategoryTypes.HEALTH_HISTORY,
      scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
      // The following attributes are handled by a custom component handler
      // defined within the template itself as this is a special case
      answers: [],
      type: HraQuestionType.CUSTOM
    },
    {
      code: '28',
      description: 'My current weight in pounds is:',
      category: HraCategoryTypes.HEALTH_HISTORY,
      scoreCategory: HraScoreCategoryTypes.CURRENT_HEALTH,
      // The following attributes are handled by a custom component handler
      // defined within the template itself as this is a special case
      answers: [],
      type: HraQuestionType.CUSTOM
    },
    // Biometric questions, for #778
    {
      code: 'Bio 1',
      description: 'Typically my Systolic Blood Pressure (the top number) is:',
      category: HraCategoryTypes.HEART_HEALTH,
      scoreCategory: HraScoreCategoryTypes.HEART_HEALTH,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: 'Bio 1a',
          description: 'Normal (< 120)',
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: 'Bio 1b',
          description: 'Prehypertension (120 - 139)',
          feedback: hraAnswerFeedback.BIO1_ENCOURAGEMENT,
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: 'Bio 1c',
          description: 'High BP - Stage 1 (140 - 159)',
          feedback: hraAnswerFeedback.BIO1_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -25
        },
        {
          code: 'Bio 1d',
          description: 'High BP - Stage 2 (160 or higher)',
          feedback: hraAnswerFeedback.BIO1_ENCOURAGEMENT,
          color: HraAnswerColors.RED,
          value: -50
        },
        {
          code: 'Bio 1e',
          description: `Very low (90 or lower)`,
          value: 0
        },
        {
          code: 'Bio 1f',
          description: `I don't know`,
          value: 25
        }
      ],
      /**
       * This field uses part of the user-result value if available
       * to return the value.
       */
      customValue: userResultHraValueHandler({
        code: FingerStickCodes.BLOOD_PRESSURE_SYSTOLIC,
        ranges: [
          {
            upperRangeToMatch: 90,
            valueToReturn: 0
          },
          {
            upperRangeToMatch: 139,
            valueToReturn: 25
          },
          {
            upperRangeToMatch: 159,
            valueToReturn: -25
          }
        ],
        defaultValueToReturn: -50
      })
    },
    {
      code: 'Bio 2',
      description:
        'Typically my Diastolic Blood Pressure (the bottom number) is:',
      category: HraCategoryTypes.HEART_HEALTH,
      scoreCategory: HraScoreCategoryTypes.HEART_HEALTH,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: 'Bio 2a',
          description: 'Normal (< 80)',
          color: HraAnswerColors.GREEN,
          value: 50
        },
        {
          code: 'Bio 2b',
          description: 'Prehypertension (80 - 89)',
          color: HraAnswerColors.YELLOW,
          value: 25
        },
        {
          code: 'Bio 2c',
          description: 'High BP - Stage 1 (90 - 99)',
          color: HraAnswerColors.RED,
          value: -25
        },
        {
          code: 'Bio 2d',
          description: 'High BP - Stage 2 (100 or higher)',
          color: HraAnswerColors.RED,
          value: -50
        },
        { code: 'Bio 2e', description: 'Very low (60 or lower)', value: 0 },
        { code: 'Bio 2f', description: `I don't know`, value: 25 }
      ],
      customValue: userResultHraValueHandler({
        code: FingerStickCodes.BLOOD_PRESSURE_DIASTOLIC,
        ranges: [
          {
            upperRangeToMatch: 60,
            valueToReturn: 0
          },
          {
            upperRangeToMatch: 80,
            valueToReturn: 50
          },
          {
            upperRangeToMatch: 89,
            valueToReturn: 25
          },
          {
            upperRangeToMatch: 99,
            valueToReturn: -25
          }
        ],
        defaultValueToReturn: -50
      })
    },
    {
      code: 'Bio 3',
      noForm: true, // THIS FIELD IS NOT SHOWN IN THE FORUM but is calculated
      description: 'Body Mass Index',
      category: HraCategoryTypes.HEART_HEALTH,
      scoreCategory: HraScoreCategoryTypes.DIABETES_PREVENTION,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: 'Bio 3a',
          description: '< 18.5',
          color: HraAnswerColors.YELLOW,
          value: 15
        },
        {
          code: 'Bio 3b',
          description: '18.5 - 24.9',
          color: HraAnswerColors.GREEN,
          value: 25
        },
        {
          code: 'Bio 3c',
          description: '25 - 29.9',
          color: HraAnswerColors.YELLOW,
          value: 15
        },
        {
          code: 'Bio 3d',
          description: '30 - 39.9',
          color: HraAnswerColors.YELLOW,
          value: 0
        },
        {
          code: 'Bio 3e',
          description: '> 40',
          color: HraAnswerColors.RED,
          value: -25
        }
      ],
      /**
       * The score calculation is calculated from the height, weight inputs
       * set on the record itself. **note** the ranges from the answers
       * are not used directly.
       *
       * This updates the nutrition score category
       *
       * TODO: add support for the hra + fingerstick logic
       */
      customScoreCalc: ({
        record,
        answerMap,
        scores,
        question,
        flatUserTestMap,
        userResult
      }) => {
        if (!record) {
          return scores;
        }

        const { heightFeet, heightInches, weight } = record;

        if (
          heightFeet === undefined ||
          heightInches === undefined ||
          weight === undefined
        ) {
          // We need all three to calculate the user's BMI
          return scores;
        }

        // Function to update the base scores passed.
        const getAndUpdateScore = (score?: number) => ({
          ...scores,
          [HraScoreCategoryTypes.NUTRITION]:
            (scores[HraScoreCategoryTypes.NUTRITION] || 0) + (score || 0)
        });
        // Default function to handle if we don't have all
        // the information to calculate from the user-result
        const defaultFn = () => {
          const height = heightFeet * 12 + heightInches;
          const bmi = getBmi({ height, weight });

          const getAnswerScore = (code: string) =>
            answerMap[question.code][code].value;

          if (bmi < 18.5) {
            return getAndUpdateScore(getAnswerScore('Bio 3a'));
          }

          if (bmi < 24.9) {
            return getAndUpdateScore(getAnswerScore('Bio 3b'));
          }

          if (bmi < 29.9) {
            return getAndUpdateScore(getAnswerScore('Bio 3c'));
          }

          if (bmi < 39.9) {
            return getAndUpdateScore(getAnswerScore('Bio 3d'));
          }

          if (bmi > 40) {
            return getAndUpdateScore(getAnswerScore('Bio 3e'));
          }

          // This technically is an error, but shouldn't happen if BMI is calculated correctly
          return getAndUpdateScore(0);
        };

        if (
          flatUserTestMap &&
          userResult &&
          flatUserTestMap[LabResultCode(FingerStickCodes.HEIGHT)] &&
          flatUserTestMap[LabResultCode(FingerStickCodes.WEIGHT)]
        ) {
          // If we have all the stuff for the user-result calculations, we use it instead
          const { userResultTestResult: heightResult } =
            flatUserTestMap[LabResultCode(FingerStickCodes.HEIGHT)];
          const { userResultTestResult: weightResult } =
            flatUserTestMap[LabResultCode(FingerStickCodes.WEIGHT)];

          if (
            isNaN(Number(heightResult.value)) ||
            isNaN(Number(weightResult.value))
          ) {
            return defaultFn();
          }

          const bmi = getBmi({
            height: Number(heightResult.value),
            weight: Number(weightResult.value)
          });

          if (bmi < 18.5) {
            return getAndUpdateScore(15);
          }

          if (bmi < 24.9) {
            return getAndUpdateScore(25);
          }

          if (bmi < 29.9) {
            return getAndUpdateScore(15);
          }

          if (bmi < 39.9) {
            return getAndUpdateScore(0);
          }

          return getAndUpdateScore(-25);
        }

        return defaultFn();
      }
    },
    {
      code: 'Bio 4',
      description: 'Typically my Total Cholesterol / HDL Ratio is:',
      category: HraCategoryTypes.HEART_HEALTH,
      scoreCategory: HraScoreCategoryTypes.HEART_HEALTH,
      type: HraQuestionType.SINGLE,
      answers: [
        { code: 'Bio 4a', description: 'Normal', value: 50 },
        { code: 'Bio 4b', description: 'Out of Range', value: 0 },
        { code: 'Bio 4c', description: 'High Risk', value: -50 },
        {
          code: 'Bio 5c',
          description: `I don't know`,
          value: 25
        }
      ],
      customValue: userResultHraValueHandler({
        // **NOTE** is this the right category?
        code: FingerStickCodes.HDL,
        ranges: [
          // **NOTE** verify these are correct, they are from the "normal" ranges
          {
            // PanicLow -> lowRisk
            upperRangeToMatch: 25,
            valueToReturn: -50
          },
          {
            // LowRisk -> lowNormal
            upperRangeToMatch: 39,
            valueToReturn: 0
          },
          {
            // LowNormal -> highNormal
            upperRangeToMatch: 175,
            valueToReturn: 50
          },
          {
            // HighNormal -> highRisk
            upperRangeToMatch: 200,
            valueToReturn: -50
          }
        ],
        defaultValueToReturn: -50
      })
    },
    {
      code: 'Bio 5',
      description: 'Typically my Triglycerides are:',
      category: HraCategoryTypes.HEART_HEALTH,
      scoreCategory: [
        HraScoreCategoryTypes.DIABETES_PREVENTION,
        HraScoreCategoryTypes.HEART_HEALTH
      ],
      type: HraQuestionType.SINGLE,
      answers: [
        { code: 'Bio 5a', description: 'Normal (<150)', value: 25 },
        {
          code: 'Bio 5b',
          description: 'Borderline High (150 - 199)',
          value: 0
        },
        { code: 'Bio 5c', description: 'High (200 or higher)', value: -25 },
        {
          code: 'Bio 5d',
          description: `I don't know`,
          value: 15
        }
      ],
      customValue: userResultHraValueHandler({
        code: FingerStickCodes.TRIGLYCERIDES,
        ranges: [
          {
            upperRangeToMatch: 150,
            valueToReturn: 25
          },
          {
            upperRangeToMatch: 199,
            valueToReturn: 0
          }
        ],
        defaultValueToReturn: -25
      })
    },
    {
      code: 'Bio 6',
      description: 'Typically my Glucose is considered:',
      category: HraCategoryTypes.HEART_HEALTH,
      scoreCategory: HraScoreCategoryTypes.DIABETES_PREVENTION,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: 'Bio 6a',
          description: 'Low (<65)',
          value: 75
        },
        { code: 'Bio 6b', description: 'Normal (65 - 99)', value: 100 },
        { code: 'Bio 6c', description: 'Prediabetes (100 - 125)', value: 75 },
        {
          code: 'Bio 6d',
          description: 'Diabetes (126 or higher)',
          value: -100
        },
        {
          code: 'Bio 6e',
          description: `I don't know`,
          value: 75
        }
      ],
      customValue: userResultHraValueHandler({
        code: FingerStickCodes.GLUCOSE,
        ranges: [
          {
            upperRangeToMatch: 64,
            valueToReturn: 75
          },
          {
            upperRangeToMatch: 99,
            valueToReturn: 100
          },
          {
            upperRangeToMatch: 125,
            valueToReturn: 75
          }
        ],
        defaultValueToReturn: -100
      })
    },
    {
      code: 'Bio 7',
      description: 'Typically my Hemoglobin A1c is considered:',
      category: HraCategoryTypes.HEART_HEALTH, // **Note** this was labeled as diabetes
      scoreCategory: HraScoreCategoryTypes.DIABETES_PREVENTION,
      type: HraQuestionType.SINGLE,
      answers: [
        {
          code: 'Bio 7a',
          description: 'Low (0 - 3.0)',
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: 'Bio 7b',
          description: 'Normal (3.1 - 5.69)',
          color: HraAnswerColors.GREEN,
          value: 20
        },
        {
          code: 'Bio 7c',
          description: 'Prediabetes (5.7 - 6.49)',
          color: HraAnswerColors.YELLOW,
          value: 10
        },
        {
          code: 'Bio 7d',
          description: 'Diabetes (≥ 6.5)',
          color: HraAnswerColors.RED,
          value: 0
        },
        {
          code: 'Bio 7e',
          description: 'Diabetic Target (6.5 - 7.0)',
          color: HraAnswerColors.YELLOW,
          value: 10,
          /**
           * We only show this option if the user selected
           * Diabetic in question 25. This question option should
           * automatically be applied when using "user-result" test
           * data for this test id.
           */
          show: ({ record }) =>
            record &&
            record['25'] &&
            Array.isArray(record['25']) &&
            record['25'].includes('25d')
        },
        {
          code: 'Bio 7f',
          description: `I don't know`,
          color: HraAnswerColors.GREEN,
          value: 10
        }
      ]
    }
  ],
  categories: [
    {
      type: HraCategoryTypes.EXERCISE,
      next: HraCategoryTypes.SAFETY
    },
    {
      previous: HraCategoryTypes.EXERCISE,
      type: HraCategoryTypes.SAFETY,
      next: HraCategoryTypes.DEPRESSION
    },
    {
      previous: HraCategoryTypes.SAFETY,
      type: HraCategoryTypes.DEPRESSION,
      next: HraCategoryTypes.STRESS
    },
    {
      previous: HraCategoryTypes.DEPRESSION,
      type: HraCategoryTypes.STRESS,
      next: HraCategoryTypes.NUTRITION
    },
    {
      previous: HraCategoryTypes.STRESS,
      type: HraCategoryTypes.NUTRITION,
      next: HraCategoryTypes.GLUCOSE
    },
    {
      // Only for question 11 currently
      previous: HraCategoryTypes.NUTRITION,
      type: HraCategoryTypes.GLUCOSE,
      next: HraCategoryTypes.NICOTINE_USE
    },
    {
      previous: HraCategoryTypes.GLUCOSE,
      type: HraCategoryTypes.NICOTINE_USE,
      next: HraCategoryTypes.HEALTH_HISTORY
    },
    {
      previous: HraCategoryTypes.NICOTINE_USE,
      type: HraCategoryTypes.HEALTH_HISTORY,
      next: HraCategoryTypes.DISEASE_PREVENTION
    },
    {
      previous: HraCategoryTypes.HEALTH_HISTORY,
      type: HraCategoryTypes.DISEASE_PREVENTION,
      next: HraCategoryTypes.ALCOHOL_USE
    },
    {
      previous: HraCategoryTypes.DISEASE_PREVENTION,
      type: HraCategoryTypes.ALCOHOL_USE,
      next: HraCategoryTypes.SLEEP
    },
    {
      previous: HraCategoryTypes.ALCOHOL_USE,
      type: HraCategoryTypes.SLEEP,
      next: HraCategoryTypes.HEART_HEALTH
    },
    {
      previous: HraCategoryTypes.SLEEP,
      type: HraCategoryTypes.HEART_HEALTH,
      next: 'submit'
    }
  ],
  scoreCategory: [
    {
      type: HraScoreCategoryTypes.ACTIVE_LIFESTYLE,
      ...defaultHraScoreColorDefs,
      total: 120,
      min: -25
    },
    {
      type: HraScoreCategoryTypes.PERSONAL_CARE,
      ...defaultHraScoreColorDefs,
      total: 180,
      min: -180
    },
    {
      type: HraScoreCategoryTypes.NUTRITION,
      ...defaultHraScoreColorDefs,
      total: 225,
      min: -125
    },
    {
      type: HraScoreCategoryTypes.EMOTIONAL_HEALTH,
      ...defaultHraScoreColorDefs,
      total: 225,
      min: -205
    },
    {
      type: HraScoreCategoryTypes.TOBACCO_USE,
      ...defaultHraScoreColorDefs,
      total: 100,
      min: -100
    },
    {
      type: HraScoreCategoryTypes.CURRENT_HEALTH,
      ...defaultHraScoreColorDefs,
      total: 170,
      min: -65
    },
    {
      type: HraScoreCategoryTypes.ALCOHOL_USE,
      ...defaultHraScoreColorDefs,
      total: 75,
      min: -125
    },
    {
      type: HraScoreCategoryTypes.SLEEP,
      ...defaultHraScoreColorDefs,
      total: 100,
      min: -130
    },
    {
      type: HraScoreCategoryTypes.HEART_HEALTH,
      ...defaultHraScoreColorDefs,
      total: 200,
      min: -215
    },
    {
      type: HraScoreCategoryTypes.DIABETES_PREVENTION,
      ...defaultHraScoreColorDefs,
      total: 180,
      min: -165
    }
  ]
};
