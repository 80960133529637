import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { healthProgramActions } from './health-program.actions';
import { HealthProgramHttpService } from '../../http/health-program-http.service';

@Injectable()
export class HealthProgramEffects {
  constructor(
    private actions$: Actions,
    private healthProgramHttp: HealthProgramHttpService,
    private errorReportFacade: ErrorReportFacade
  ) {}

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthProgramActions.list),
      mergeMap((action) =>
        this.healthProgramHttp.list().pipe(
          map((entities) => healthProgramActions.listSuccess({ entities })),
          catchError((err) => [
            healthProgramActions.listFailed({ error: err }),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthProgramActions.get),
      mergeMap((action) =>
        this.healthProgramHttp.get(action.id).pipe(
          map((entity) => healthProgramActions.getSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            healthProgramActions.getFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );
}
