import { ObjectId } from 'mongodb';
import { AdminNote } from './admin-note';
import { Company } from './company';
import { DbDocument } from './db-document';
import { EventService } from './event-service/event-service';
import { ServiceLabType } from './event-service/service-lab-type';
import { ServiceType } from './event-service/service-type';
import { Genders, User } from './user';
import { UserRegistration } from './user-registration';
import { UserRegistrationId } from './user-registration/base-user-registration';

export enum SupportedHomeTest {
  COLOCARE = 'colocare'
}

export enum UserHomeTestStatus {
  OPEN = 'open',
  PENDING = 'pending',
  COMPLETED = 'completed',
  CLOSED = 'closed',
  CANCELED = 'canceled'
}

export interface UserHomeTest extends DbDocument {
  /**
   * The company this home test belongs to
   */
  company: string | ObjectId | Company;

  /**
   * The user taken from the home test.
   */
  user: string | ObjectId | User;

  /**
   * The user registration tied to this home test
   */
  userRegistration: UserRegistrationId | ObjectId | UserRegistration;

  /**
   * The event service that the user registered for
   */
  eventService: string | ObjectId | EventService;

  /**
   * The type of the user-registration for the given event.
   * This should always be at home, thus always offsite
   */
  serviceType: ServiceType.OFFSITE;

  /** The array of home tests that they need to send the user **/
  homeTests: SupportedHomeTest[];

  /**
   * The status of the call for this record.
   */
  status: UserHomeTestStatus;

  /**
   * The type of lab this test goes for. Since we are only dealing with Offsite
   * for now, we can only have QUEST or Labcorp as the Labs
   */
  labType: ServiceLabType.QUEST | ServiceLabType.LAB_CORP;

  /**
   * List of notes admins can enter, and update over time
   */
  notes: AdminNote[];

  /**
   * First name of the user and
   * taken from the user's profile as-is at the time of test creation.
   */
  firstName: string;

  /**
   * Last name of the user and
   * taken from the user's profile as-is at the time of test creation.
   */
  lastName: string;

  /**
   * Gender of the user [Male | Female]
   */
  gender: Genders;

  /**
   * User's birth-day, saved as a string, and
   * taken from the user's profile as-is at the time of test creation.
   *
   * Saved as a date-string
   */
  birthDay: string;

  /**
   * User's Phone number. To contact him
   */
  phone?: string;

  /**
   * User's email
   */
  email?: string;

  /**
   * Name of the company,
   * loaded up to be saved as-is at the time of test creation.
   */
  companyName: string;

  /**
   * Start and End dates for filter range on home test list
   */
  startDate: string;
  endDate: string;
}

type UserHomeTestPickedFilter = Partial<
  Pick<
    UserHomeTest,
    | 'firstName'
    | 'company'
    | 'companyName'
    | 'lastName'
    | 'birthDay'
    | 'startDate'
    | 'endDate'
  >
>;

export interface UserHomeTestFilter extends UserHomeTestPickedFilter {
  status?: UserHomeTestStatus[];
}

export interface UserHomeTestForm extends UserHomeTestFilter {
  pageNumber: number;
}
