export interface GcfResultsImportRequest {
  /**
   * The email we will use to authenticate with, and create
   * a sheet in their drive. Should be a google email
   */
  email: string;

  /**
   * Company to import data to
   */
  company: string;

  /**
   * The id of the Google Sheet we are trying to import data from.
   */
  spreadsheetId?: string;

  /**
   * The ID of the sub-sheet (tab) that we need to process the results on.
   * In case the Google Sheet has multiple tabs, we will select the first one
   * that matches the following names: ['Fingerstick', 'Vaccination', 'Quest', 'Labcorp']
   * Otherwise we show an error that the Spreadsheet has no valid Sheets
   *
   * **note** this can be undefined when passed to the cloud function, we will then
   * default to the first sheet regardless of name.
   */
  sheetId?: number;
  /**
   * The Collection Date of the data
   */
  collectionDate?: Date;
}

export enum ImportSheetNameType {
  FINGERSTICK = 'fingerstick',
  VACCINATION = 'vaccination',
  QUEST = 'quest',
  LABCORP = 'labcorp'
}

export const validImportSheetNames = Object.values(ImportSheetNameType);
