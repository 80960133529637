/**
 * Takes a string, and makes it title-case.
 * If given multiple words, it makes every word title case.
 */
export const toTitleCase = (str: string): string => {
  if (!str || typeof str !== 'string') {
    return '';
  }

  return str
    .split(' ')
    .map((word) =>
      word.trim().length
        ? word.trim()[0].toUpperCase() + (word.slice(1) || '').toLowerCase()
        : ''
    )
    .join(' ')
    .trim();
};
