import { ObjectId } from 'mongodb';
import { OnsiteResultFormDeclinableField } from '../../requests/onsite-result-form';
import { Company } from '../company/company';
import { DbDocument } from '../db-document';
import { EventService } from '../event-service/event-service';
import { ServiceLabType } from '../event-service/service-lab-type';
import { HpnImportAudit, HpnImportAuditId } from '../hpn/hpn-import-audit';
import { Rack } from '../rack';
import { User } from '../user';
import { UserRegistration } from '../user-registration';
import { UserResultFingerstick } from './user-result-fingerstick';
import { UserResultFlu } from './user-result-flu';
import { UserResultTest } from './user-result-test';
import { UserResultTestStatus } from './user-result-test-result';
import { UserResultVaccine } from './user-result-vaccine';

/**
 * Insertion source, only supports a few import sources. Older
 * user-results will need to be migrated
 */
export enum UserResultSource {
  HPN = 'hpn',
  HPN_LIFEPOINT = 'hpn-lifepoint'
}
export type UserResultId = string & { readonly brand: unique symbol };
// tslint:disable-next-line: variable-name
export const UserResultId = (userResultId: string) =>
  userResultId as UserResultId;
/**
 * A user-result represents a "parsed"
 * de-normalized version of the LifePointResponse data return
 * from life-point.
 * This data should be loaded instead of the raw life-point response
 */
export interface UserResult extends DbDocument<UserResultId> {
  /**
   * The source of the import
   */
  source?: UserResultSource;
  /**
   * The req# or user-registration shortId. This is saved on newer user-results
   * to help mitigate issues found with #3670.
   */
  requisition?: string;
  /**
   * The accession#. This is saved on newer user-results to help with the new HPN lifepoint connection
   */
  accession?: string;
  /**
   * The reference to the import for this user. Used for auditing, and possible rollbacks.
   */
  hpn?: HpnImportAuditId | ObjectId | HpnImportAudit;
  /**
   * The Lab Account Number that was used from Lifepoint to generate the results
   */
  labAccount?: string;
  /**
   * The corresponding life-point response id. Used
   * to relate the user-result with the raw entry from life-point
   * only given for offsite user-results.
   *
   * **note** this doesn't reference the life-point-response, as to prevent loading in
   * the life-point-response type itself from life-point.
   */
  lifePointResponse?: string | ObjectId | unknown;
  /**
   * The corresponding user-registration
   */
  userRegistration: string | ObjectId | UserRegistration;
  /**
   * The user this result(s) corresponds to.
   */
  user: string | ObjectId | User;
  /**
   * The lab-type passed
   * **note** undefined when given by health-provider.
   */
  labType?: ServiceLabType;
  /**
   * The date the result was performed at.
   * This includes the time in UTC time.
   */
  collectionDate: Date;
  /**
   * The date the result was received (?)
   * Not given with by health-provider
   */
  receivedDate?: Date;
  /**
   * The date and time the result was reported
   * Not given with by health-provider
   */
  reportedDate?: Date;
  /**
   * The new HPN lifepoint connection has the site code being passed under physician.physicianId field.
   * This is to help the team identify what Site Code and event the user attended
   */
  siteCode?: string;
  /**
   * List of test results
   */
  tests: UserResultTest[];
  /**
   * All test result statuses. This indicates the status for all sub tests in the array of `tests`
   * For example if one panel in tests is `F` but the other is `P`, this would still be `P` as some
   * tests are still not completed
   */
  status?: UserResultTestStatus;
  /**
   * A base64 representation of the user-result information from the life-point.
   * Not provided for user-entered results
   */
  pdf?: string;
  /**
   * Nested flu data. Only added for onsite-flu user-results.
   */
  flu?: UserResultFlu;
  /**
   * Nested vaccine data. Currently only saving covid meta-data
   */
  vaccine?: UserResultVaccine;
  /**
   * Nested fingerstick data. Only added for onsite-fingerstick user-results.
   */
  fingerstick?: UserResultFingerstick;
  /**
   * The number of hours the user fasted before hand.
   * **note** only supported with fingerstick flow,
   * see updates with #1155
   *
   * @deprecated moving toward `fasted`, which is what we will use moving forward.
   * If 8 hours or more, its considered to be "fasting"
   */
  hoursFasted?: number;
  /**
   * New property that will replace `hoursFasted` moving forward, and will be leveraged
   * in the legacy `admin-result-form-bio` component and the future admin versions.
   */
  fasted?: boolean;
  /**
   * Self reported tobacco usage
   */
  usesTobacco?: boolean;
  /**
   * The height of the user, entered via the admin panel or self-reported on registration
   * In inches
   */
  height?: number;
  /**
   * The weight of the user, entered via the admin panel or self-reported on registration
   * In lbs.
   */
  weight?: number;
  /**
   * The blood pressure Diastolic of the user, entered via the admin panel or self-reported on registration
   */
  bloodPressureDia?: number;
  /**
   * The blood pressure Systolic of the user, entered via the admin panel or self-reported on registration
   */
  bloodPressureSys?: number;
  /**
   * The user registration height entered by the user.
   */
  selfReportedHeight?: number;
  /**
   * The user registration weight entered by the user.
   */
  selfReportedWeight?: number;
  /**
   * The list of fields declined.
   * This is only saved via the new user-result version. The legacy version
   * saves only for fingerstick.
   */
  declined?: OnsiteResultFormDeclinableField[];
  /**
   * The version, new data-entered versions will have 2
   */
  version?: number;
  /**
   * The Blood Rack number associated  when admin enter data as racks
   */
  rackNumber?: number;
  rackId?: string | ObjectId | Rack;
  // Added to help improve efficiency on marquee-sync
  company: ObjectId | string | Company;
  eventService?: ObjectId | string | EventService;
}
