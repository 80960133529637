import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EventLocation,
  EventLocationWithTimes,
  OnsiteRegistrationType
} from '@common';
import { Observable } from 'rxjs';
import { QueryList, QueryListService } from '../util/query-list.service';

@Injectable({
  providedIn: 'root'
})
export class EventLocationHttpService {
  constructor(private http: HttpClient, private queryList: QueryListService) {}

  public get(id: string): Observable<EventLocation> {
    return this.http.get<EventLocation>(`api/v1/event/${id}`);
  }

  public list(
    query?: QueryList<EventLocation> & { onsiteType?: OnsiteRegistrationType }
  ): Observable<EventLocation[] | EventLocationWithTimes[]> {
    const queryParams = this.queryList.build(query);

    return this.http.get<EventLocation[] | EventLocationWithTimes[]>(
      'api/v1/event',
      {
        params: query?.onsiteType
          ? queryParams.append('type', query.onsiteType)
          : queryParams
      }
    );
  }
}
