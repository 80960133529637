export enum PersonType {
  EMPLOYEE = 'employee',
  SPOUSE = 'spouse',
  DEPENDENT = 'dependent',
  RETIREE = 'retiree',
  CONTRACTOR = 'contractor',
  PART_TIME = 'part_time',
  OTHER = 'other'
}

/**
 * Map of person-type to user friendly names.
 * this is mainly done for the Part Time person-type.
 */
export const personTypeNames: { [key in PersonType]: string } = {
  [PersonType.EMPLOYEE]: 'Employee',
  [PersonType.SPOUSE]: 'Spouse',
  [PersonType.DEPENDENT]: 'Dependent',
  [PersonType.RETIREE]: 'Retiree',
  [PersonType.CONTRACTOR]: 'Contractor',
  [PersonType.PART_TIME]: 'Part Time',
  [PersonType.OTHER]: 'Other'
};

/**
 * Flipped mapping of personType names, where the key is the name.
 * Used for CSV parsing, where we are passed essentially the name,
 * and want to save the key
 */
export const personTypeNamesToKeys = Object.entries(personTypeNames).reduce(
  (acc, [personType, name]) => ({ ...acc, [name]: personType as PersonType }),
  {} as Record<string, PersonType>
);
