export enum EmailTemplates {
  // Misc
  VERIFY_EMAIL = 'verify_email',
  PASSWORD_RESET = 'password_reset',
  FORGOT_USERNAME = 'forgot_username',
  FAX_CONFIRM = 'fax_confirm',
  NEW_NOTIFICATION = 'new_notification',
  EVENT_RESCHEDULED = 'event_rescheduled',
  NO_SHOW = 'no_show',
  // Onsite
  ONSITE_REMINDER = 'onsite_reminder',
  ONSITE_SCREENING_SCHEDULED = 'onsite_screening_scheduled',
  ONSITE_COVID_SCHEDULED = 'onsite_covid_scheduled',
  ONSITE_FLU_SCHEDULE = 'onsite_flu_scheduled',
  ONSITE_SCREENING_AND_FLU_SCHEDULED = 'onsite_screening_and_flu_scheduled',
  ONSITE_SCREENING_COVID = 'onsite_screening_covid',
  ONSITE_COVID_FLU = 'onsite_covid_flu',
  // Offsite
  OFFSITE_LAB_CORP_SCHEDULED = 'offsite_lab_corp_scheduled',
  OFFSITE_QUEST_SCHEDULED = 'offsite_quest_scheduled',
  OFFSITE_REMINDER = 'offsite_reminder',
  // Provider
  PROVIDER_SCHEDULED = 'provider_scheduled',
  // Results
  PROVIDER_RESULTS_READY = 'provider_results_ready',
  RESULTS_READY = 'results_ready',
  //Coaching
  COACHING_SCHEDULED = 'coaching_scheduled',
  COACHING_CANCELED = 'coaching_canceled',
  COACHING_REMINDER = 'coaching_reminder',
  // HRA
  HRA_REMINDER = 'hra_reminder',

  //HPF
  HPF_REMINDER = 'hpf_reminder',
  // Event Cancelled
  EVENT_CANCELLED = 'event_cancelled',
  MFA_CREATED = 'mfa_created',

  INCENTIVE_REMINDER = 'incentive_reminder',
  INCENTIVE_HEALTHY_METRICS_REMINDER = 'incentive_healthy_metrics_reminder'
}
