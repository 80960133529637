import { CommonResponse } from './common.response';

/**
 * Response represents a response for users
 * not allowed to execute the given request.
 */
export const notAuthorizedResponse: CommonResponse = {
  code: 401,
  message: 'Not Authorized'
};
