import { ObjectId } from 'mongodb';
import { DbDocument } from '../models/db-document';

/**
 * Type-guard function that returns if the entity is a lookup-entity for a db-document
 */
export const isDbDocument = <T extends DbDocument>(
  entity: string | ObjectId | T
): entity is T => {
  return !!entity && typeof entity === 'object' && !!(entity as DbDocument)._id;
};
