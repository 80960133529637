/**
 * Represents an individual custom time slot for an event.
 *
 * This **overrides** the given slots for that time.
 */
export type EventLocationCustomTime =
  | EventLocationCustomTimeFlu
  | EventLocationCustomTimeScreening
  | (EventLocationCustomTimeFlu & EventLocationCustomTimeScreening);

export interface EventLocationCustomTimeBase {
  /**
   * The formatted time we are overriding.
   */
  time: string;
}

export interface EventLocationCustomTimeScreening
  extends EventLocationCustomTimeBase {
  /**
   * The number of screening "allotments" for this time slot we
   * are to override for.
   */
  screening: number;
}

export const isEventLocationCustomTimeScreening = (
  customTime: Partial<EventLocationCustomTime>
): customTime is EventLocationCustomTimeScreening =>
  typeof (customTime as EventLocationCustomTimeScreening).screening ===
  'number';

export interface EventLocationCustomTimeFlu
  extends EventLocationCustomTimeBase {
  /**
   * The number of flu "allotments" for this time slot we
   * are to override for.
   */
  flu: number;
}

export const isEventLocationCustomTimeFlu = (
  customTime: Partial<EventLocationCustomTime>
): customTime is EventLocationCustomTimeFlu =>
  typeof (customTime as EventLocationCustomTimeFlu).flu === 'number';
