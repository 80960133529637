import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsAdminCustomPageUploadInputComponent } from './ehs-admin-custom-page-upload-input.component';
import { FlexLayoutModule } from 'ng-flex-layout';
import { EhsCardModule } from '../ehs-card/ehs-card.module';
import { EhsPageContainerModule } from '../ehs-page-container/ehs-page-container.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [EhsAdminCustomPageUploadInputComponent],
  imports: [
    // Angular
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatProgressSpinnerModule,

    // EHS
    EhsPageContainerModule,
    EhsCardModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [EhsAdminCustomPageUploadInputComponent]
})
export class EhsAdminCustomPageUploadInputModule {}
