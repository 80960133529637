import { USER_AUDIT_TYPES } from '@common';
import { UserAuditListActionColumn } from './user-audit-list-table-column';

// Columns to display by type of userAudit
export const displayedColumnsMap = {
  [USER_AUDIT_TYPES.LOGIN]: [
    'createdAt',
    'type',
    'ipAddress',
    'signInOption',
    'actions'
  ],
  [USER_AUDIT_TYPES.HRA_VIEW]: ['createdAt', 'type'],
  [USER_AUDIT_TYPES.RESULT_VIEW]: ['createdAt', 'type'],
  [USER_AUDIT_TYPES.SENT_RESULTS_FAX]: [
    'createdAt',
    'type',
    'faxNumber',
    'physicianName',
    'actions'
  ],
  [USER_AUDIT_TYPES.RESOURCE_VIEW]: [
    'createdAt',
    'type',
    'resourceName',
    'resourceCategory',
    'actions'
  ],
  [USER_AUDIT_TYPES.THIRD_PARTY_RESOURCE]: [
    'createdAt',
    'type',
    'thirdPartyUrl',
    'actions'
  ],
  all: ['createdAt', 'type']
};
// Actions to show depending of the type of user audit
export const actionColumnsMap: {
  [key in USER_AUDIT_TYPES]?: UserAuditListActionColumn[];
} = {
  [USER_AUDIT_TYPES.LOGIN]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<UserAuditListActionColumn>,
  [USER_AUDIT_TYPES.RESOURCE_VIEW]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<UserAuditListActionColumn>,
  [USER_AUDIT_TYPES.THIRD_PARTY_RESOURCE]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<UserAuditListActionColumn>,
  [USER_AUDIT_TYPES.SENT_RESULTS_FAX]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<UserAuditListActionColumn>
};
