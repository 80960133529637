import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsSendRequisitionFaxDialogComponent } from './ehs-send-requisition-fax-dialog.component';
import { EhsSendRequisitionFaxDialogResponse } from './ehs-send-requisition-fax-dialog.response';

@Injectable()
export class EhsSendRequisitionFaxDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(): Observable<EhsSendRequisitionFaxDialogResponse | false> {
    return this.matDialog
      .open(EhsSendRequisitionFaxDialogComponent, { maxWidth: '800px' })
      .afterClosed();
  }
}
