import { ObjectId } from 'mongodb';
import { DbDocument } from '../models/db-document';

/**
 * Utility function to get id information
 *
 *
 */
export const getId = (
  id:
    | string
    | ObjectId
    | Pick<DbDocument, '_id'>
    | Partial<Pick<DbDocument, '_id'>>
): string => {
  if (!id) {
    return id as string;
  }

  if (typeof id === 'string') {
    return id;
  }

  if (typeof id === 'object' && !!(id as DbDocument)._id) {
    return (id as DbDocument)._id.toString();
  }

  if (typeof id === 'object' && (id as ObjectId).toHexString) {
    // This is an objectId
    return id.toString();
  }

  return '';
};
