import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { EhsOptOutMFADialogComponent } from './ehs-opt-out-mfa-dialog.component';
import { EhsOptOutMFADialogService } from './ehs-opt-out-mfa-dialog.service';

@NgModule({
  declarations: [EhsOptOutMFADialogComponent],
  imports: [
    CommonModule,
    // Angular material
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [EhsOptOutMFADialogComponent],
  providers: [EhsOptOutMFADialogService]
})
export class EhsOptOutMFADialogModule {}
