import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { selectId } from '../utils/select-id';
import { HealthProgram } from '@common';
import { healthProgramActions } from './health-program.actions';

export interface HealthProgramState extends EntityState<HealthProgram> {
  loading?: boolean;
}

const adapter = createEntityAdapter<HealthProgram>({
  selectId
});

const reducer = createReducer<HealthProgramState>(
  adapter.getInitialState({}),
  on(healthProgramActions.list, (state) => ({ ...state, loading: true })),
  on(healthProgramActions.listSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(healthProgramActions.listFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(healthProgramActions.get, (state) => ({ ...state, loading: true })),
  on(healthProgramActions.getSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(healthProgramActions.getFailed, (state) => ({ ...state, loading: false }))
);

export function HealthProgramReducer(
  state: HealthProgramState,
  action: Action
) {
  return reducer(state, action);
}
