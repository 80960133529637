/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AdminUser } from '@common';
import { EntityFacade } from '@ehs-ngrx/common';
import { select, Store } from '@ngrx/store';
import { adminCoreNgrxActions } from './admin-core-ngrx.actions';
import {
  adminCoreNgrxFeatureSelector,
  adminCoreNgrxSelectors
} from './admin-core-ngrx.selectors';
import {
  AdminCoreNgrxState,
  ParentAdminCoreNgrxState
} from './admin-core-ngrx.state';

@Injectable({
  providedIn: 'root'
})
export class AdminCoreNgrxFacade extends EntityFacade<
  AdminUser,
  string,
  ParentAdminCoreNgrxState,
  AdminCoreNgrxState
> {
  /**
   * The current admin-user
   */
  public user$ = this.store.pipe(
    select(adminCoreNgrxSelectors.adminUserSelector)
  );

  /**
   * The current admin-user's scopes
   */
  public adminScopes$ = this.store.pipe(
    select(adminCoreNgrxSelectors.adminUserScopesSelector)
  );

  /**
   * Platform Stats for current date range
   */
  public platformStats$ = this.store.pipe(
    select(adminCoreNgrxSelectors.platformStatsSelector)
  );

  /**
   * Start and End Dates from previous platformStats query
   */
  public dateRange$ = this.store.pipe(
    select(adminCoreNgrxSelectors.dateRangeSelector)
  );

  constructor(store: Store<ParentAdminCoreNgrxState>) {
    super(store, adminCoreNgrxFeatureSelector);
  }

  public setAdminUser(user: AdminUser) {
    this.store.dispatch(
      adminCoreNgrxActions.set({
        user
      })
    );
  }

  /**
   * Lists all admin-users, automatically includes scopes.
   */
  public list() {
    this.store.dispatch(adminCoreNgrxActions.list.req());
  }

  /**
   * Add a new admin, prompting a dialog to get the email
   */
  public addAdmin() {
    this.store.dispatch(adminCoreNgrxActions.addAdmin.req());
  }

  public removeAdmin(admin: AdminUser) {
    this.store.dispatch(adminCoreNgrxActions.removeAdmin.req(admin));
  }

  /**
   * Updates the given admin user
   */
  public update(admin: AdminUser) {
    this.store.dispatch(adminCoreNgrxActions.update.req({ admin }));
  }

  public getStats(params: { startDate: string; endDate: string }) {
    const { startDate, endDate } = params;

    this.store.dispatch(
      adminCoreNgrxActions.getStats.req({ startDate, endDate })
    );
  }
}
