<div class="ehs-api-error-table ehs-table-wrapper">
  <table class="ehs-table sticky-header">
    <tr>
      <th *ngFor="let col of columns;let i = index"
          [style.top]="headerTop"
          [style.--width]="i === columns.length - 1 ? '100%' : col.width">
        {{ col.title }}
      </th>
    </tr>

    <tr *ngFor="let error of errors">
      <td *ngFor="let col of columns">
        <ng-container [ngSwitch]="col.key">

          <ng-container *ngSwitchCase="'statusCode'">
            <mat-chip-set>
              <mat-chip [style.background-color]="getStatusCodeColor(error.statusCode)"
                        selected
                        style="font-size: 12px; min-height: 24px; padding: 0 8px;">
                {{ error.statusCode }}
              </mat-chip>
            </mat-chip-set>
          </ng-container>

          <ng-container *ngSwitchCase="'status'">
            <mat-chip-listbox *ngIf="editStatusId !== error._id; else editStatus">
              <mat-chip-option [style.background-color]="getStatusColor(error.status)"
                               selected
                               (click)="editStatusId = $any(error._id)"
                               style="font-size: 12px; min-height: 24px; padding: 0 8px;">
                {{ error.status | titlecase }}
              </mat-chip-option>
            </mat-chip-listbox>
            <ng-template #editStatus>
              <mat-form-field appearance="standard"
                              style="max-width: 110px;"
                              class="no-label"
                              (click)="$event.stopPropagation()">
                <mat-select name="statusValue"
                            [value]="error.status"
                            (selectionChange)="statusChange.emit({ error, status: statusInput.value })"
                            (openedChange)="onStatusSelectClosed($event)"
                            #statusInput>
                  <mat-option *ngFor="let status of statuses"
                              [value]="status">
                    <mat-chip-listbox disabled>
                      <mat-chip-option class="no-opacity"
                                       [style.background-color]="getStatusColor(status)"
                                       selected
                                       style="font-size: 12px; min-height: 24px; padding: 0 8px;">
                        {{status | titlecase}}
                      </mat-chip-option>
                    </mat-chip-listbox>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'action'">
            {{ getActionName(error) }}
          </ng-container>

          <ng-container *ngSwitchCase="'companyName'">
            {{ error?.companyName || 'N/A' }}
          </ng-container>

          <ng-container *ngSwitchCase="'createdAt'">
            {{ error?.createdAt | date: 'medium' }}
          </ng-container>

          <ng-container *ngSwitchCase="'notes'">
            <mat-form-field appearance="fill"
                            style="width: 100%; margin-top: 5px;"
                            class="no-label">
              <textarea name="notes"
                        rows="1"
                        [value]="error.notes || ''"
                        matInput
                        (change)="notesChange.emit({ error, notes: $any($event.target).value })"
                        placeholder="Enter your notes here"></textarea>
            </mat-form-field>
          </ng-container>

        </ng-container>
      </td>
    </tr>

    <tr *ngIf="hasNext">
      <button (click)="loadMore.emit()"
              style="margin: 16px 8px 24px"
              mat-flat-button
              color="primary"
              type="button">
        Load More
      </button>
    </tr>
  </table>

</div>