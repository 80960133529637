import { LabResultCode } from '../../models/user-test/user-test';

/**
 * Hard-values for fingerstick form fields that need to be double blind entered.
 * This will be used on the client-side to verify if the given field needs to be double
 * blind checked or disabled after the first "submit" is fired and the form state changes
 *
 * All of these can be declined at 1 time.
 *
 * @deprecated use ONSITE_FS_FORM_FIELDS
 */
export const ONSITE_FS_FORM_DB_FIELDS = [
  'totalCholesterol',
  'hdl',
  'ldl',
  'triglycerides',
  'glucose',
  'totalCholesterolRatio',
  'nonHdlCholesterol'
] as const;

/**
 * Hard-values for fingerstick form fields that can be not-reportable.
 * This mirrors the `ONSITE_FS_FORM_DB_FIELDS` right now.
 *
 * @deprecated use ONSITE_FS_FORM_FIELDS
 */
export const ONSITE_FS_FORM_NOT_REPORTABLE_FIELDS = [
  'totalCholesterol',
  'hdl',
  'ldl',
  'triglycerides',
  'glucose',
  'totalCholesterolRatio',
  'nonHdlCholesterol'
] as const;

/**
 * The list of fields that the fingerstick has. All fields are equal, and
 * this type can be used for not-reported, and double blind types and validation.
 */
export const ONSITE_FS_FORM_FIELDS = [
  'totalCholesterol',
  'hdl',
  'ldl',
  'triglycerides',
  'glucose',
  'totalCholesterolRatio',
  'nonHdlCholesterol'
] as const;

/**
 * This type represents the list of types of fields that need to be double-blind checked.
 *
 * This provides a strict TS level check against the fingerstick fields.
 */
export type OnsiteFsFormDbFields = (typeof ONSITE_FS_FORM_FIELDS)[number];

/**
 * Type-Guard to help check if the given OnsiteFsForm field requires double-blind
 * entry. This will "duck-type" the data.
 *
 * This should be combined with "notReportable" and "available" checks if this
 * matters or not.
 */
export const isOnsiteFsFormDbField = (
  field: keyof OnsiteFsForm
): field is OnsiteFsFormDbFields =>
  ONSITE_FS_FORM_FIELDS.includes(field as OnsiteFsFormDbFields);

/**
 * This type represents the list of fields that can be "not-reported".
 * This mirrors the double-blind fields.
 *
 * There is no type-guard available, as what is not-reportable should
 * be defined within the template.
 */
export type OnsiteFsFormNotReportableField =
  (typeof ONSITE_FS_FORM_FIELDS)[number];

/**
 * This map provides the lab-result-code (should be the same as primary-order-code)
 * for the fingerstick fields.
 */
export const ONSITE_FS_FORM_CODE_MAP = {
  fingerstick: LabResultCode('0000'),
  totalCholesterol: LabResultCode('0001'),
  hdl: LabResultCode('0002'),
  ldl: LabResultCode('0005'),
  triglycerides: LabResultCode('0003'),
  glucose: LabResultCode('0004'),
  totalCholesterolRatio: LabResultCode('0006'),
  nonHdlCholesterol: LabResultCode('0007')
};

/**
 * Represents the list of fingerstick fields that map to actual
 * user-tests within the platform.
 */
export type OnsiteFsFormFieldWithTest = keyof typeof ONSITE_FS_FORM_CODE_MAP;

export const isOnsiteFsFormFieldWithTest = (
  field: keyof OnsiteFsForm | string
): field is OnsiteFsFormFieldWithTest =>
  typeof field === 'string' &&
  (
    Object.keys(ONSITE_FS_FORM_CODE_MAP) as Array<OnsiteFsFormFieldWithTest>
  ).includes(field as OnsiteFsFormFieldWithTest);

/**
 * This type represents the onsite-fs-form data. This is the part of the form
 * used for onsite events where the EHS team acts "as the lab". This is usually
 * combined with the OnsiteBioForm data.
 *
 * Most of the fields are double blind, all are required, and instead of "declined"
 * the action is "not reportable".
 */
export interface OnsiteFsForm {
  /**
   * The user's total cholesterol value.
   *
   * LabResultCode: `0001`
   */
  totalCholesterol: string | number;

  /**
   * High-density lipoprotein value
   * mg/dl
   *
   * LabResultCode: `0002`
   */
  hdl: string | number;

  /**
   * Low Density Lipoprotein value
   * mg/dl
   *
   * LabResultCode: `0005`
   */
  ldl: string | number;

  /**
   * Triglycerides value
   * mg/dl
   *
   * LabResultCode: `0003`
   */
  triglycerides: string | number;

  /**
   * Glucose value
   * mg/dl
   *
   * LabResultCode: `0004`
   */
  glucose: string | number;

  /**
   * Total Cholesterol | HDL Ratio (TC/HDL RATIO)
   * mg/dl
   *
   * LabResultCode: `0006`
   */
  totalCholesterolRatio: string | number;

  /**
   * NonHDL Cholesterol
   * mg/dl
   *
   * LabResultCode: `0007`
   */
  nonHdlCholesterol: string | number;

  /**
   * The fingerstick's serial number.
   * Not required, no double-blind.
   *
   * Saved to the user-result.fingerstick object.
   */
  serialNumber: string;

  /**
   * The fingerstick's lot number.
   * Not required, no double-blind.
   *
   * Should be auto-populated from the event-location
   *
   * Saved to the user-result.fingerstick object.
   */
  lotNumber: string;

  /**
   * The machine number of the fingerstick.
   * Not required, no double-blind.
   *
   * Saved to the user-result.fingerstick object.
   */
  machineNumber: string;
}
