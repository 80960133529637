import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { userVaccinationActions } from './user-vaccination.actions';
import { UserVaccinationHttpService } from '../../http/user-vaccination-http.service';

@Injectable()
export class UserVaccinationEffects {
  constructor(
    private actions$: Actions,
    private userVaccinationHttp: UserVaccinationHttpService,
    private errorReportFacade: ErrorReportFacade
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userVaccinationActions.get),
      mergeMap((action) =>
        this.userVaccinationHttp.get(action.userVaccinationId).pipe(
          map((entity) => userVaccinationActions.getSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            userVaccinationActions.getFailed({ error: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  getByUserReg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userVaccinationActions.getByUserReg),
      mergeMap((action) =>
        this.userVaccinationHttp.getByUserRegId(action.userRegId).pipe(
          map((entity) =>
            userVaccinationActions.getByUserRegSuccess({
              entity
            })
          ),
          catchError((err: HttpErrorResponse) => [
            userVaccinationActions.getByUserRegFailed({ error: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userVaccinationActions.list),
      mergeMap((action) =>
        this.userVaccinationHttp.list().pipe(
          map((entities) =>
            userVaccinationActions.listSuccess({
              entities
            })
          ),
          catchError((err: HttpErrorResponse) => [
            userVaccinationActions.listFailed({ error: err.error }),
            this.errorReportFacade.create({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );
}
