import { Injectable } from '@angular/core';
import {
  EventServiceServiceType,
  EventServiceUtil,
  UserRegistration
} from '@common';
import { createSelector, select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppState } from '../app-state';
import { eventServiceActions } from './event-service.actions';
import { eventServiceSelectors } from './event-service.selectors';

@Injectable({
  providedIn: 'root'
})
export class EventServiceFacade {
  public loading$ = this.store.pipe(
    select(eventServiceSelectors.loadingSelector)
  );

  public services$ = this.store.pipe(
    select(eventServiceSelectors.entitiesArrSelector)
  );

  public servicesMap$ = this.store.pipe(
    select((state) => state.eventService.entities)
  );

  /**
   * Selector for relevant eventServices. These are event-services
   * that are available to register for according to their start and end dates
   */
  public relevantEventServices$ = this.store.pipe(
    select(eventServiceSelectors.relevantServicesSelector)
  );

  /**
   * Selector for relevant event-services, that are only part of the user's
   * current company. Should be used for sign-up
   */
  public currentCompanyRelevantServices$ = this.store.pipe(
    select(eventServiceSelectors.currentCompanyRelevantServicesSelector)
  );

  /**
   * If any service has the emmy/education feature active.
   */
  public hasEmmy$ = this.store.pipe(
    select(eventServiceSelectors.hasEmmySelector)
  );

  /**
   * If any service has the marquee challenges enabled
   */
  public hasMarqueeChallenges$ = this.store.pipe(
    select(eventServiceSelectors.hasMarqueeChallengesSelector)
  );

  /**
   * If any service has the marquee challenges runner enabled
   */
  public hasMarqueeChallengesRunner$ = this.store.pipe(
    select(eventServiceSelectors.hasMarqueeChallengesRunnerSelector)
  );

  /**
   * If any service has the marquee coaching enabled
   */
  public hasMarqueeCoaching$ = this.store.pipe(
    select(eventServiceSelectors.hasMarqueeCoachingSelector)
  );

  /**
   * If any service has the marquee incentives enabled
   */
  public getMarqueeIncentives$ = this.store.pipe(
    select(eventServiceSelectors.getMarqueeIncentivesSelector)
  );

  public hasUserIncentivesEnabled$ = this.store.pipe(
    select(eventServiceSelectors.hasIncentivesEnabledSelector)
  );

  public getIncentivesEnabled$ = this.store.pipe(
    select(eventServiceSelectors.getIncentivesEnabledSelector)
  );

  public hasUserReimbursementsEnabled$ = this.store.pipe(
    select(eventServiceSelectors.hasReimbursementsEnabledSelector)
  );

  public getReimbursementsEnabled$ = this.store.pipe(
    select(eventServiceSelectors.getReimbursementsEnabledSelector)
  );

  constructor(private store: Store<AppState>) {}

  public list() {
    this.store.dispatch(eventServiceActions.list());
  }

  public get(id: string) {
    this.store.dispatch(eventServiceActions.get({ id }));
  }

  public get$(id: string) {
    return this.store.pipe(
      select(
        createSelector(
          (state: AppState) => state.eventService.entities,
          (entities) => entities[id]
        )
      )
    );
  }

  /**
   * Returns the event-service for the given id,
   * **admin-only**
   */
  public getAny(id: string) {
    this.store.dispatch(eventServiceActions.getAny({ id }));
  }

  /**
   * Returns the corresponding service type for the given user-registration
   */
  public getEventServiceFromRegistration$(
    userRegistration: UserRegistration
  ): Observable<EventServiceServiceType> {
    return this.get$(userRegistration.eventService.toString()).pipe(
      filter((_) => !!_),
      map((eventService) =>
        EventServiceUtil.getSelectedService({
          serviceType: userRegistration.serviceType,
          eventService
        })
      )
    );
  }
}
