import { ObjectId } from 'bson';
import { States } from '../../constants/states';
import { COVIDVaccines } from '../../constants/vaccines';
import { AdminUser } from '../admin-user';
import { Company } from '../company';
import { DbDocument } from '../db-document';
import { EventService } from '../event-service/event-service';
import { LocationData } from '../location-data';
import { EventLocationCustomTime } from './event-location-custom-time';
import { EventLocationRegistrationTime } from './event-location-registration-time';

export enum EventLocationType {
  FLU_SHOT = 'fluShot',
  SCREENING = 'screening',
  BOTH = 'both'
}

export enum EventStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active'
}

export enum EventRegistrationMethod {
  PAPER = 'paper',
  ONLINE = 'online',
  WALKIN = 'walkin'
}

/**
 * A kinda redundant name, but returns the locationId and location-data
 * for a given location. Should be used to replace the original "code-value" setup
 * from before.
 */
export type EventLocationLocationData = Pick<
  EventLocation,
  'locationId' | keyof LocationData
>;

export interface EventLocationWithTimes extends EventLocation {
  times: EventLocationRegistrationTime[];
}

export interface EventLocation extends DbDocument, Partial<LocationData> {
  /**
   * The company this event-location is for
   */
  company: string | ObjectId | Company;
  /**
   * The service this event-location is linked to
   */
  service: string | ObjectId | EventService;
  /**
   * The GWApps id for the locationData properties.
   * Used to reference against the location information
   */
  locationId: string | ObjectId;
  /**
   * Account Manager Email - This is used for admin side to filter
   * against what account manager is responsible for this given event.
   */
  accountManager?: string;
  /**
   * The date of the event
   * saved in the format
   * YYYY-MM-DD
   * EX: 2019-12-31
   */
  eventDate: string;

  /**
   * Event GW Apps Record ID (example: E1711, E1712...)
   */
  eventId: string;

  /**
   * Location Site Code ID
   */
  siteCode?: string;

  /**
   * The status of the event available in GW Apps.
   */
  status: EventStatus;

  /** If the event has been canceled */
  canceled?: boolean;
  canceledBy?: {
    _id: AdminUser | string;
    email: string;
    firstName: string;
    lastName: string;
  };
  canceledOn?: Date;

  /**
   * If provided, only SHOW the event to the users if the registrationActive
   * date has passed. Example: registrationActive is October 10, and today we are
   * October 12, then we should see this eventLocation. If today was October 5 then
   * it should not be shown.
   * Note: Registration Active = Registration open on selected date @ noon
   */
  registrationActive?: string;

  /**
   * If provided, HIDE the event to the users if the registrationDeadline
   * has passed. Example: registrationDeadline is October 10, and today we are
   * October 12, then we should HIDE this eventLocation. If today was October 5 then
   * it should SHOW.
   * Note: Registration Deadline = Reg closed on selected date @ noon
   */
  registrationDeadline?: string;

  registrationCloseTime: string;
  /**
   * The type of event
   * @deprecated don't use this, its not being parsed and shouldn't be relied
   * upon. Currently always set to screening
   */
  eventType: EventLocationType;
  /**
   * The location's company name. Currently displayed during registration and
   * for requisitions to provide more context about the event.
   *
   * This is **different** than the event's parent companies `name` attribute,
   * which is more along the lines of the name of the client.
   */
  locationCompanyName: string;
  /**
   * The state the location is in
   * **note** this might make more sense in the location attribute maybe?
   */
  state: string | States;
  /**
   * The room this event will be in,
   * **note** might make more sense in the location attribute maybe?
   */
  room: string;
  /**
   * The number of people alloted for each time slot for a screening onsite service.
   */
  alloted: number;
  /**
   * The number of people that can register for each time slot for a Flu onsite service.
   */
  fluAlloted: number;
  /**
   * Military time of the starting time for the event
   */
  startTime: string;
  /**
   * Military time of the end time for the event
   */
  endTime: string;
  /**
   * The timezone of the event
   * TODO: based upon location
   */
  timezone: string;
  /**
   * Each event will have a specific supply of vaccine type. Users will be getting a dose
   * of either Moderna, Pfizer or AstraZeneca.
   */
  vaccineType?: COVIDVaccines;
  /**
   * The Dosage that the vaccine will be given at. Usually this will not change, but in case
   * it changes, it can read from the list coming from GW Apps.
   * e.g. 0.5 mL
   */
  vaccineDosage?: string;
  /**
   * The lot number for the vaccine, is used to pre-populate on the client-side.
   *
   */
  vaccineLotNumber?: string;
  /**
   * The vaccine expiration date, is used to pre-populate on the client-side.
   * Date string formatted: yyyy-mm-dd (not exact luxon format)
   */
  vaccineExpDate?: string;

  /**
   * @deprecated In favor of fluVacName
   */
  fluName?: string;
  /**
   * The Dosage that the flu will be given at.
   */
  fluDosage?: string;
  /**
   * @deprecated In favor of fluLot
   */
  fluLotNumber?: string;
  /**
   * @deprecated In favor of fluVacExpDate
   */
  fluExpDate?: string;

  /**
   * If we sent the "capacity warning" email to EHS.
   * for bypassing 80% capacity.
   *
   * **note** this should allow us to email about 80%
   * FIRST, if capacity is between 80-90, but not always.
   * If an event has limited capacity in the first place
   * the percentage of capacity could by-pass this.
   */
  capacityWarning80?: boolean;

  /**
   * Capacity email flag for 80% capacity for vaccines
   */
  vaccineCapacityWarning80?: boolean;

  /**
   * If we have sent the "capacity warning" email to EHS.
   * **note** this is for 90% capacity support.
   * 80% capacity is separate.
   */
  capacityWarning90?: boolean;

  /**
   * Capacity email flag for 90% capacity for vaccines
   */
  vaccineCapacityWarning90?: boolean;

  /**
   * If we have sent the "capacity full" email to EHS.
   * **note** this is for 100% capacity support.
   */
  capacityFull?: boolean;

  /**
   * Capacity email flag for full capacity for vaccines
   */
  vaccineCapacityFull?: boolean;

  /**
   * Account managers determine if the event will be handled in the app (online)
   * or if it will be done manually by paper or walkin. The app will only show
   * the event for users to register to if it is online. However in the ehs/admin
   * they can add results for an event that is paper or walkin.
   */
  registrationMethod?: EventRegistrationMethod;

  /**
   * List of custom times that **override** a given time slot.
   *
   * Added with #3797
   */
  customTimes?: EventLocationCustomTime[];
  /**
   * This would be the lot number for vaccinations,
   * replacing the previous lot number
   */
  fluLot?: string;
  /**
   * This would be the vaccination name for vaccinations,
   * replacing the previous vaccination name
   */
  fluVacName?: string;
  /**
   * This would be the vaccination exp date for vaccinations,
   * replacing the previous expiration date
   */
  fluVacExpDate?: string;
}
