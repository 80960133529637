import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserEmailAudit, USER_EMAIL_AUDIT_TYPES } from '@email-types';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AdminUserEmailAuditHttpService {
  private baseUrl = 'api/v1/admin/user-email-audits';

  constructor(private http: HttpClient) {}

  public list(params: {
    userId: string;
    template?: USER_EMAIL_AUDIT_TYPES;
    pageNumber: number;
    maxResults?: number;
  }): Observable<{ userEmailAudits: UserEmailAudit[]; hasNext: boolean }> {
    const { userId, template, pageNumber, maxResults } = params;
    let httpParams = new HttpParams()
      .append('user', userId)
      .append('pageNumber', pageNumber);

    if (template) {
      httpParams = httpParams.append('emailTemplate', template);
    }

    if (maxResults) {
      httpParams = httpParams.append('maxResults', maxResults);
    }

    return this.http.get<{
      userEmailAudits: UserEmailAudit[];
      hasNext: boolean;
    }>(`${this.baseUrl}/list`, {
      params: httpParams
    });
  }
}
