import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsAdminRestrictDataDialogData } from './ehs-admin-restrict-data-dialog.data';
import { EhsAdminRestrictDataDialogComponent } from './ehs-admin-restrict-data-dialog.component';
import { EhsAdminRestrictDataDialogResponse } from './ehs-admin-restrict-data-dialog.response';

@Injectable()
export class EhsAdminRestrictDataDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: EhsAdminRestrictDataDialogData
  ): Observable<EhsAdminRestrictDataDialogResponse | false> {
    return this.matDialog
      .open(EhsAdminRestrictDataDialogComponent, { data, maxWidth: '800px' })
      .afterClosed();
  }
}
