import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhsServiceTypesComponent } from './ehs-service-types.component';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [EhsServiceTypesComponent],
  imports: [CommonModule, MatChipsModule, RouterModule],
  exports: [EhsServiceTypesComponent]
})
export class EhsServiceTypesModule {}
