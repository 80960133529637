/**
 * Observation values, defaults to v01.
 */
export enum COVIDObservationValues {
  'V01' = 'V01',
  'V02' = 'V02',
  'V03' = 'V03',
  'V04' = 'V04',
  'V05' = 'V05'
}
