import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Company,
  EventLocation,
  EventLocationRegistrationTimesByType,
  EventLocationStats,
  EventService,
  getId
} from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminEventLocationHttpService {
  private baseUrl = 'api/v1/admin';
  private clientAdminUrl = 'api/v1/client';

  constructor(private http: HttpClient) {}

  /**
   * Returns the list of event-locations for the given company
   * @param companyId the company to get it for
   */
  public listEventLocations(
    companyId: string,
    params?: { clientAdmin: boolean }
  ): Observable<EventLocation[]> {
    return this.http.get<EventLocation[]>(
      `${params?.clientAdmin ? this.clientAdminUrl : this.baseUrl}/event/list`,
      {
        params: new HttpParams().append('company', companyId)
      }
    );
  }

  /**
   * Returns the list of event-locations for the given eventService
   * @param eventService the eventService to get it for
   */
  public listEventLocationsByService(params: {
    companyId: string;
    eventService: string;
    clientAdmin?: boolean;
  }): Observable<EventLocation[]> {
    const { companyId, eventService } = params;

    return this.http.get<EventLocation[]>(
      `${params?.clientAdmin ? this.clientAdminUrl : this.baseUrl}/event/list`,
      {
        params: new HttpParams()
          .append('company', companyId)
          .append('eventService', eventService)
      }
    );
  }

  public get(
    id: string,
    params?: { clientAdmin: boolean }
  ): Observable<EventLocation> {
    return this.http.get<EventLocation>(
      `${params?.clientAdmin ? this.clientAdminUrl : this.baseUrl}/event/${id}`
    );
  }

  /** Return the stats for all Event Locations */
  public getStats({
    company,
    eventService,
    clientAdmin
  }: {
    company?: string | Company;
    eventService?: string | EventService;
    clientAdmin?: boolean;
  }): Observable<EventLocationStats> {
    return this.http.get<EventLocationStats>(
      `${clientAdmin ? this.clientAdminUrl : this.baseUrl}/event/stats`,
      {
        params: {
          ...(company ? { company: getId(company) } : {}),
          ...(eventService ? { eventService: getId(eventService) } : {})
        }
      }
    );
  }

  /** Returns all event locations in the last 7 +- days */
  public listRecent(params?: { clientAdmin: boolean }): Observable<{
    eventLocations: EventLocation[];
    stats: EventLocationStats;
  }> {
    return this.http.get<{
      eventLocations: EventLocation[];
      stats: EventLocationStats;
    }>(
      `${params?.clientAdmin ? this.clientAdminUrl : this.baseUrl}/event/recent`
    );
  }

  /**
   * Release the event, with the optional passing of users
   * to release only specific users
   */
  public releaseEvent(params: {
    /**
     * The event-location we are to release events
     * for
     */
    eventLocation: string;
    /**
     * The list of user ids to
     * release records for, or nothing
     * to release all records related to the given event location
     */
    users?: string[];
  }) {
    const { eventLocation, users } = params;

    // TODO: Update url maybe?
    return this.http.post<unknown>(
      `api/v1/admin/event/release/${eventLocation}`,
      {
        users
      }
    );
  }

  public cancelEvent(params: { eventLocation: EventLocation | string }) {
    const { eventLocation } = params;

    return this.http.put(
      `api/v1/admin/event/cancel/${getId(eventLocation)}`,
      eventLocation
    );
  }

  /**
   * Returns the registration-times-by-type for a given event-location
   */
  public getRegTimes(
    eventLocation: string,
    params?: { clientAdmin: boolean }
  ): Observable<EventLocationRegistrationTimesByType> {
    return this.http.get<EventLocationRegistrationTimesByType>(
      `${
        params?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/event/times/${eventLocation}`
    );
  }

  public updateCustomTimes(
    eventLocation: Pick<EventLocation, 'customTimes' | '_id'>
  ): Observable<EventLocation> {
    return this.http.put<EventLocation>(
      `api/v1/admin/event/times/${getId(eventLocation)}`,
      eventLocation
    );
  }
}
