import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IsValidPhoneNumberValidator } from '../../shared/valid-phone-validator/valid-phone.validator';
import { matchValidator } from '../../shared/match-validator/match.validator';
import { EhsSendRequisitionFaxDialogResponse } from './ehs-send-requisition-fax-dialog.response';

@Component({
  selector: 'ehs-send-requisition-fax-dialog',
  templateUrl: './ehs-send-requisition-fax-dialog.component.html',
  styleUrls: ['./ehs-send-requisition-fax-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsSendRequisitionFaxDialogComponent {
  faxForm = new FormGroup(
    {
      phone: new FormControl('', {
        validators: [IsValidPhoneNumberValidator()]
      }),
      confirmPhone: new FormControl('', {
        validators: [IsValidPhoneNumberValidator()]
      })
    },
    { validators: [matchValidator('phone', 'confirmPhone')] }
  );

  constructor(
    private dialogRef: MatDialogRef<EhsSendRequisitionFaxDialogComponent>,
    private snackBar: MatSnackBar
  ) {}

  public onSubmit() {
    if (this.faxForm.invalid) {
      this.snackBar.open('Please fill out all required fields.', 'Ok');

      return;
    }

    this.dialogRef.close({
      phone: this.faxForm.get('phone')?.value
    } as EhsSendRequisitionFaxDialogResponse);
  }
}
