import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  Company,
  getId,
  toMap,
  UserHomeTestFilter,
  UserHomeTestStatus
} from '@common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ehs-user-home-tests-filters',
  templateUrl: './ehs-user-home-tests-filters.component.html',
  styleUrls: ['./ehs-user-home-tests-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsUserHomeTestsFiltersComponent {
  @Input() filters: UserHomeTestFilter = {};

  @Input() set companies(companies: Company[]) {
    this.companiesArr = companies.slice(0, 10);

    this.companies$.next(
      toMap({
        key: '_id',
        entities: companies
      })
    );
  }

  /**
   * If the filter changes,
   * **does not include keyup changes** such as firstName, lastName, and company
   */
  @Output() filterChange = new EventEmitter<UserHomeTestFilter>();

  @Output() keyupChange = new EventEmitter<UserHomeTestFilter>();
  @Output() clear = new EventEmitter();
  @Output() search = new EventEmitter();

  public statusOptions = Object.values(UserHomeTestStatus);

  public companiesArr: Company[] = [];

  private companies$ = new BehaviorSubject<Record<string, Company>>({});

  get company() {
    if (!this.filters.company) {
      return '';
    }

    return this.companies$.value[getId(this.filters.company)]?.name || '';
  }

  public onChange(params: UserHomeTestFilter) {
    this.filterChange.emit({ ...this.filters, ...params });
  }

  public onKeyup(params: UserHomeTestFilter) {
    this.keyupChange.emit({ ...this.filters, ...params });
  }

  /**
   * Hanldes the display of a company, or a query-string.
   */
  public displayFn(company: Company | string) {
    return typeof company === 'string' ? company : company?.name || '';
  }

  hasFilters() {
    return !!Object.values(this.filters).filter((_) => !!_).length;
  }
}
