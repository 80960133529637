import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommonResponse,
  LoginRequest,
  SignUpRequest,
  User,
  PasswordResetRequest,
  CommonResponseWithReason,
  SamlLoginDetailsRequest,
  VpSamlUser,
  SamlStrategy
} from '@common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ForgotUsernameRequest } from '@common';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the user's profile, if there is one
   */
  public getUser(params?: {
    insurance?: boolean;
  }): Observable<{ user: User; clientAdmin?: User }> {
    const insurance = params?.insurance;

    return this.http.get<{ user: User; clientAdmin?: User }>(
      'api/v1/auth/user',
      {
        params: insurance && new HttpParams().set('insurance', 'true')
      }
    );
  }

  /**
   * Gets the user's saml partial user, if there is one
   */
  public getSamlUser(): Observable<VpSamlUser> {
    return this.http.get<VpSamlUser>('api/v1/auth/saml/user');
  }

  /**
   * Logs the user into the application
   */
  public login(
    params: LoginRequest
  ): Observable<{ user: User; clientAdmin?: User }> {
    return this.http.post<{ user: User; clientAdmin?: User }>(
      'api/v1/auth/login',
      params
    );
  }

  public getIsLocked(params: {
    username: string;
  }): Observable<{ locked: boolean }> {
    const { username } = params;

    return this.http.get<{ locked: boolean }>('api/v1/auth/lock', {
      params: new HttpParams().set('username', username)
    });
  }

  /**
   * Locks the account associated with the
   */
  public lockAccount(params: { username: string }): Observable<CommonResponse> {
    return this.http.post<CommonResponse>('api/v1/auth/lock', params);
  }

  /**
   * Registers the user into the application
   */
  public register(params: {
    signUpRequest: SignUpRequest;
  }): Observable<CommonResponseWithReason> {
    const { signUpRequest } = params;

    return this.http.post<CommonResponseWithReason>(
      'api/v1/auth/register',
      signUpRequest
    );
  }

  public logout(): Observable<{ clientCode: string }> {
    return this.http.post<{ clientCode: string }>('api/v1/auth/logout', {});
  }

  /**
   * Updates user information
   */
  public updateUser(params: {
    user: Partial<User>;
    includeInsurance?: boolean;
  }): Observable<Partial<User>> {
    const { user } = params;

    return this.http.put<User>('api/v1/auth/user/update', user, {
      params: params.includeInsurance
        ? new HttpParams().append('insurance', true)
        : undefined
    });
  }

  /**
   * Returns if the given username is valid or not. If the username
   * is already taken, then we return false
   */
  public isValidUsername(params: { username: string }): Observable<boolean> {
    const { username } = params;

    return this.http
      .get<{ valid: boolean }>('api/v1/auth/user/valid', {
        params: {
          username
        }
      })
      .pipe(map(({ valid }) => valid));
  }

  /**
   * Resets the given user's password
   */
  public passwordReset(
    params: PasswordResetRequest
  ): Observable<CommonResponse> {
    const { ssn, lastName, birthDay, captcha } = params;

    return this.http.put<CommonResponse>('api/v1/auth/password/reset', {
      ssn,
      lastName,
      birthDay,
      captcha
    });
  }

  /**
   * Resets the given user's password
   */
  public forgotUsername(
    params: ForgotUsernameRequest
  ): Observable<CommonResponse> {
    const { birthDay, lastName, ssn, captcha } = params;

    return this.http.put<CommonResponse>('api/v1/auth/username/forgot', {
      birthDay,
      lastName,
      ssn,
      captcha
    });
  }

  /**
   * Complete the User SAML Login Flow
   */
  public completeSamlLogin(
    params: Partial<SamlLoginDetailsRequest>
  ): Observable<User> {
    const { gender, ssn, captcha } = params;

    return this.http.post<User>('api/v1/auth/saml/vp', {
      gender,
      ssn,
      captcha
    });
  }

  /**
   * Complete the User SAML Login Flow
   */
  public completeSsoLogin(
    params: SamlLoginDetailsRequest & { samlType: SamlStrategy }
  ): Observable<User> {
    const {
      gender,
      ssn,
      captcha,
      firstName,
      lastName,
      birthDay,
      phone,
      samlType
    } = params;

    return this.http.post<User>('api/v1/auth/sso/register', {
      gender,
      ssn,
      firstName,
      lastName,
      birthDay,
      phone,
      captcha,
      samlType
    });
  }

  /**
   * Sends a verify email to the currently logged in user.
   * TODO: the security for this endpoint should be updated with #443
   */
  public verifyEmail(params: {
    captcha: string;
    username: string;
  }): Observable<CommonResponse> {
    const { captcha, username } = params;

    return this.http.put<CommonResponse>('api/v1/auth/user/email/verify', {
      captcha,
      username
    });
  }
}
