import { createSelector, MemoizedSelector } from '@ngrx/store';
import { EntityStatsTypeParams } from './entity-stats-arg-type';
import { EntityStatsState } from './entity-stats-state';

/**
 *
 * @param DocumentKey - the type of _id of the document, usually just `string`
 * @param EntityStats - the actual stats data-type
 * @param AppState - the top-level state
 * @param EntityStatsState - the state for this feature
 */
export const entityStatsSelectorFactory = <
  AppState,
  State extends EntityStatsState<Params>,
  Params extends EntityStatsTypeParams
>({
  featureSelector
}: {
  featureSelector: MemoizedSelector<AppState, State>;
}) => {
  const statsSelector = createSelector(featureSelector, (state) => state.stats);

  return {
    /**
     * The base stats object as-is.
     */
    statsSelector,
    /**
     * If there are any stats loading at all.
     */
    statsLoadingSelector: createSelector(
      featureSelector,
      (state) => !!state.statsLoading
    ),
    /**
     * Factory function that can be used to create a selector for the given entity id,
     * and return the stats object for that entity.
     */
    entityStatsSelectorFactory: (entityId: Params['documentKey']) =>
      createSelector(
        statsSelector,
        (state) =>
          (state?.[entityId] as Params['stats']) || ({} as Params['stats'])
      ),
    /**
     * Factory function that can be used to create a selector for the given entity id,
     * and return if the stats are loading for this entity
     */
    isEntityStatsLoadingSelectorFactory: (entityId: Params['documentKey']) =>
      createSelector(
        featureSelector,
        (state) => !!state?.statsLoading?.includes(entityId)
      ),
    /**
     * Factory function that can be used to create a selector for the given entity id,
     * and return if there is any stats for that entity.
     */
    isEntityStatsLoadedSelectorFactory: (entityId: Params['documentKey']) =>
      createSelector(statsSelector, (state) => !!state?.[entityId])
  };
};
