import { ServiceType } from '../models/event-service/service-type';

/**
 * The different type of export reports that can be generated. All these use the user-registrations
 * as the base.
 */
export enum RegExportType {
  /**
   * List of user registrations. This export type can have covidReport or fullReport flags
   * to include more fields in the export.
   */
  REGISTRATION_REPORT = 'registration_report',
  /**
   * Account Manager list of all users that have gotten results that need to be billed.
   * Note: This export does not include user that registered unless they got their results back
   */
  BILLING_REPORT = 'billing_report',
  /**
   * Account Manager list of all users that have gotten results.
   * Note: This export does not include user that registered unless they got their results back
   */
  PARTICIPANT_REPORT = 'participant_report',
  /**
   * Account Manager list of all users that are registered for an event.
   */
  FIELD_STAFF_REPORT = 'field_staff_report'
}

/**
 * This represents multiple different types of GcfRegExportRequests.
 * There are essentially currently 3 different versions:
 * - company-level - export **all** registrations for a company
 * - event-level - export **all** registrations for a given event(s)
 * - service-level - export **all** registrations for a given service(s)
 */
export type GcfRegExportRequest =
  | GcfRegExportCompanyRequest
  | GcfRegExportServiceRequest
  | GcfRegExportEventRequest;

/**
 * These are properties supported for all reg-export-requests
 */
export interface BaseGcfRegExportRequest {
  /**
   * The email of who we are to share the final sheet with. This is usually the
   * admin that made the original request.
   */
  email: string;
  /**
   * Registration Export type
   */
  exportType?: RegExportType;
  /**
   * There are 2 user-registrations exports:
   * - Full report: Includes all the tests, all user information (SSN, DOB, etc...)
   * - Simple report: Only has the basic event info (first, last name only) and other event details
   */
  fullReport?: boolean;
  /**
   * If we are to include the covid properties.
   * Will include these properties at the end of the full report, or
   * end of the simple report.
   */
  covidReport?: boolean;
}

export const isGcfRegExportCompanyRequest = (
  request: BaseGcfRegExportRequest
): request is GcfRegExportCompanyRequest =>
  !!(request as GcfRegExportCompanyRequest).company;

/**
 * This represents the request for a company-level export of user-registrations.
 */
export interface GcfRegExportCompanyRequest extends BaseGcfRegExportRequest {
  /**
   * The company-id we are to export all user-registrations for.
   */
  company: string;
  /**
   * The service-type we can filter against, if not provided
   * we capture all service-types.
   */
  serviceType?: ServiceType | ServiceType[];
}

export const isGcfRegExportServiceRequest = (
  request: BaseGcfRegExportRequest
): request is GcfRegExportServiceRequest =>
  !!(request as GcfRegExportServiceRequest).service;

/**
 * Represents the request for a service-level export of user-registrations
 */
export interface GcfRegExportServiceRequest extends BaseGcfRegExportRequest {
  /**
   * The service-id we are to export all user-registrations for
   */
  service: string;
  /**
   * The service-type we can filter against, if not provided
   * we capture all service-types.
   */
  serviceType?: ServiceType | ServiceType[];
}

export const isGcfRegExportEventRequest = (
  request: BaseGcfRegExportRequest
): request is GcfRegExportEventRequest =>
  !!(request as GcfRegExportEventRequest).events;

export interface GcfRegExportEventRequest extends BaseGcfRegExportRequest {
  /**
   * The list of event-ids we are to export for.
   */
  events: string[];
}
