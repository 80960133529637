import { LocationData } from '../models/location-data';
import { EventLocation } from '../models/event-location/event-location';

/**
 * A static utility class that provides helper functions for location-data
 */
export class LocationDataUtil {
  /**
   * Returns an easily readable string from a given location-data
   */
  public static getDisplayLocation(params: {
    locationData: Partial<LocationData>;
  }): string {
    const { locationData } = params;

    if (!locationData) {
      return '';
    }
    const { address1, address2, state, zip, city } = locationData;

    return [address1, address2, city, state, zip].filter((_) => !!_).join(', ');
  }

  /**
   * Returns the event address
   */
  public static getEventAddress(params: {
    eventLocation: EventLocation;
  }): string {
    const { eventLocation } = params;

    if (!eventLocation) {
      return '';
    }
    const { address1, address2, state, zip, city } = eventLocation;

    return `${address1 || ''} ${address2 || ''}, ${city || ''} ${
      state || ''
    }, ${zip || ''}`;
  }
}
