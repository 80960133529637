import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ApiErrorAction,
  apiErrorActionNames,
  ApiErrorFilter,
  ApiErrorStatus
} from '@common';

@Component({
  selector: 'ehs-api-error-filters',
  templateUrl: './ehs-api-error-filters.component.html',
  styleUrls: ['./ehs-api-error-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsApiErrorFiltersComponent {
  @Input() filter: ApiErrorFilter = {};

  @Output() filterChange = new EventEmitter<ApiErrorFilter>();
  @Output() search = new EventEmitter();
  @Output() clear = new EventEmitter();

  statuses = Object.values(ApiErrorStatus);
  actionTypes = Object.values(ApiErrorAction);

  getActionTypeName(actionType: ApiErrorAction) {
    return apiErrorActionNames[actionType || ''];
  }

  get hasFilters() {
    return Object.values(this.filter).filter((_) => !!_).length;
  }

  onChange(filter: ApiErrorFilter) {
    this.filterChange.emit({ ...this.filter, ...filter });
  }
}
