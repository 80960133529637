import {
  bpDiasResultCodes,
  bpSysResultCodes
} from '../constants/blood-pressure';
import { BloodPressureRange } from '../models/hra/hra-result';
import { UserResultTestResult } from '../models/user-result/user-result-test-result';
import { LabResultCode } from '../models/user-test/user-test';
import { UserTestRange } from '../models/user-test/user-test-range';
import { UserTestUtil } from './user-test-util/user-test-util';

/**
 * Returns the blood pressure range, as based on the user-test values
 * calculated from the user-test+test-result.
 *
 * **note** the format of the params isn't optimal, but
 * can be optimized later if things change.
 */
export const getBloodPressureRange = (params: {
  /**
   * The systolic range result with test
   */
  bloodPressureSystolic: {
    userResultTestResult: UserResultTestResult;
  };
  /**
   * The calculated systolic range for the current user
   */
  bloodPressureSystolicRange: UserTestRange;
  /**
   * The diastolic range result with test
   */
  bloodPressureDiastolic: {
    userResultTestResult: UserResultTestResult;
  };
  /**
   * The calculated diastolic range for the current user
   */
  bloodPressureDiastolicRange: UserTestRange;
  /**
   * The language we are to display the final range in.
   * Defaults to en
   *
   * TODO: not fully implemented yet
   */
  language?: 'en' | 'es';
}): BloodPressureRange => {
  const {
    bloodPressureDiastolic,
    bloodPressureDiastolicRange,
    bloodPressureSystolic,
    bloodPressureSystolicRange
  } = params;

  const diastolicRange = UserTestUtil.getRange({
    userResultTestResult: bloodPressureDiastolic.userResultTestResult,
    userTestRange: bloodPressureDiastolicRange
  });
  const systolicRange = UserTestUtil.getRange({
    userResultTestResult: bloodPressureSystolic.userResultTestResult,
    userTestRange: bloodPressureSystolicRange
  });

  if (diastolicRange === 'highRisk' || systolicRange === 'highRisk') {
    return BloodPressureRange.HIGH;
  }

  if (diastolicRange === 'outOfRange' || systolicRange === 'outOfRange') {
    return BloodPressureRange.PREHYPERTENSIVE;
  }

  return BloodPressureRange.NORMAL;
};

export const isDiastolicBloodPressureTest = (params: {
  resultCode: LabResultCode;
}): boolean => {
  const { resultCode } = params;

  return bpDiasResultCodes.includes(resultCode);
};

export const isSystolicBloodPressureTest = (params: {
  resultCode: LabResultCode;
}): boolean => {
  const { resultCode } = params;

  return bpSysResultCodes.includes(resultCode);
};
