import { createAction, props } from '@ngrx/store';
import { Company, PersonType, CommonResponse } from '@common';

export const companyActions = {
  get: createAction('[COMPANY] GET'),
  getSuccess: createAction(
    '[COMPANY] GET_SUCCESS',
    props<{ company: Company }>()
  ),
  getFailed: createAction('[COMPANY] GET_FAILED'),

  getPersonTypes: createAction(
    '[COMPANY] GET_PERSON_TYPES',
    props<{
      clientCode: string;
    }>()
  ),
  getPersonTypesSuccess: createAction(
    '[COMPANY] GET_PERSON_TYPES_SUCCESS',
    props<{
      clientCode: string;
      personTypes: PersonType[];
    }>()
  ),
  getPersonTypesFailed: createAction(
    '[COMPANY] GET_PERSON_TYPES_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  isValidClientCode: createAction(
    '[COMPANY] IS_VALID_CLIENT_CODE',
    props<{
      clientCode: string;
    }>()
  ),
  isValidClientCodeSuccess: createAction(
    '[COMPANY] IS_VALID_CLIENT_CODE_SUCCESS',
    props<{
      valid: boolean;
    }>()
  ),
  isValidClientCodeFailed: createAction(
    '[COMPANY] IS_VALID_CLIENT_CODE_FAILED'
  ),
  clearIsValidClientCode: createAction('[COMPANY] CLEAR_IS_VALID_CLIENT_CODE'),

  getCompanyLogoUrl: createAction(
    '[COMPANY] GET_COMPANY_LOGO_URL',
    props<{
      clientCode: string;
    }>()
  ),
  getCompanyLogoUrlSuccess: createAction(
    '[COMPANY] GET_COMPANY_LOGO_URL_SUCCESS',
    props<{
      company: Pick<Company, '_id' | 'clientCode' | 'logoUrl'>;
    }>()
  ),
  getCompanyLogoUrlFailed: createAction(
    '[COMPANY] GET_COMPANY_LOGO_URL_FAILED',
    props<{
      error: CommonResponse;
    }>()
  )
};
