import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { JsonDialogComponent } from './json-dialog.component';

@Injectable()
export class JsonDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(data: { json: unknown; title?: string }): Observable<boolean> {
    return this.matDialog
      .open(JsonDialogComponent, {
        data,
        maxWidth: '100%',
        minWidth: '400px'
      })
      .afterClosed();
  }
}
