<div fxLayout="row"
     fxLayoutAlign="space-between center"
     *ngIf="eventService">

  <!-- left element -->
  <div fxLayout="row"
       fxLayoutAlign="space-between center"
       fxLayoutGap="8px"
       fxFlex="100">
    <a mat-button
       title="Redirects to Event Service"
       [routerLink]="eventServiceUrl"
       color="primary"
       style="padding-left: 0; text-decoration: underline;">
      {{eventService.serviceId}} - {{eventService.name}}
    </a>
  </div>
</div>

<div gdColumns="1fr 1fr"
     gdColumns.sm="1fr 1fr"
     gdColumns.xs="1fr"
     gdGap="8px">

  <ehs-card-label label="Start Date"
                  [valueStyles]="{color: !eventService?.startDate ? 'red' : ''}"
                  [value]="(eventService?.startDate | date:'mediumDate') || 'Not Set'">
  </ehs-card-label>

  <ehs-card-label label="End Date"
                  [valueStyles]="{color: !eventService?.endDate ? 'red' : ''}"
                  [value]="(eventService?.endDate | date: 'mediumDate') || 'Not Set'">
  </ehs-card-label>

  <ehs-card-label label="Members Registered"
                  [internalLink]="eventServiceUrl"
                  [value]="registrationTotals">
  </ehs-card-label>

  <ehs-card-label label="Members Results"
                  [value]="resultsTotals">
  </ehs-card-label>

</div>
