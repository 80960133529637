import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { UserTestUtil } from '@common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[maxBound]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxBoundValidatorDirective,
      multi: true
    }
  ]
})
export class MaxBoundValidatorDirective {
  /**
   * Similar to min max validators, but not limited to numeric input fields,
   * ignores estimation values, and checks values which are passed in as strings.
   * Returns an error if the value is greater than the minBound.
   */
  @Input() maxBound?: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public validate(control: AbstractControl): { [key: string]: unknown } | null {
    let { value } = control;

    if (this.maxBound === undefined) {
      return null;
    }

    const maxBound = Number(this.maxBound);

    if (UserTestUtil.isEstimationValue(value)) {
      return null;
    }

    value = Number(control.value);

    if (isNaN(value) || isNaN(maxBound)) {
      // If neither are numbers, then don't do anything
      return null;
    }

    if (value > maxBound) {
      // Return the "error"
      return {
        maxBound: {
          value
        }
      };
    }

    return null;
  }
}
