import { PersonType } from '../person-type';

/**
 * The type of coaching sessions supported
 * TODO: rename to CoachingSessionType for clarify
 */
export enum EventServiceCoachingType {
  HRA = 'hra',
  LAB = 'lab'
}
/**
 * If each event-service coaching period amount applies
 * to the month, or the full duration of the event-service
 *
 * **note** the INFINITE value doesn't **NOT** denote an infinite
 * period, rather it denotes the value within this period is infinite,
 * and thus we disregard the amount attribute.
 *
 * This was done primarily for ease of setting up the mongoose schema,
 * hopefully it doesn't come around and screw us over D:
 */
export enum EventServiceCoachingPeriod {
  MONTH = 'month',
  DURATION = 'duration',
  INFINITE = 'infinite'
}
/**
 * Defines settings for the coaching feature.
 * more attributes may be added in the future.
 */
export interface EventServiceCoaching {
  /**
   * The event-service coaching type. Only hra is supported right now.
   */
  types: EventServiceCoachingType[];
  /**
   * The period setting, if the period is infinite, then the amount
   * is overwritten.
   */
  period: EventServiceCoachingPeriod;
  /**
   * The list of person types supported by the Coaching - Who is allowed to schedule a consultation.
   * This is combined with the person-types within the event-service groups later.
   */
  participants: PersonType[];
  /**
   * The number of coaching sessions available for a given user to sign up for
   * during the duration of the event-service.
   * This can be skipped if period is infinite
   */
  amount?: number;
}
