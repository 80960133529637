<div *ngIf="loading"
     fxLayout="row"
     fxLayoutAlign="center center">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>

<div *ngIf="!loading"
     class="ehs-user-payments-tests-cost-table ehs-table-wrapper">
  <table class="ehs-table">
    <thead>
      <tr>
        <th *ngFor="let col of columns;let i = index"
            [ngClass]="col.key"
            [style.--width]="i === columns.length - 1 ? '100%' : col.width">
          {{ col.title }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let testCost of testCosts">
        <td *ngFor="let col of columns"
            [ngClass]="col.key">
          <ng-container [ngSwitch]="col.key">

            <ng-container *ngSwitchCase="'testName'">
              <div fxLayout="row"
                   fxLayoutAlign="start center"
                   fxLayoutGap="8px">
                <div>{{ getUserTestName(testCost.userTest) }}</div>
                <mat-chip-listbox *ngIf="isPanel(testCost.userTest)">
                  <mat-chip-option color="accent"
                                   class="panel-chip"
                                   selected>
                    Panel
                  </mat-chip-option>
                </mat-chip-listbox>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'cost'">
              {{ testCost.cost | currency }}
            </ng-container>

          </ng-container>
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr style="font-weight: 500">
        <td>Total: </td>
        <td class="cost">{{ total | currency }}</td>
      </tr>
    </tfoot>
  </table>

</div>