<!-- **note** the parent should show an empty message -->
<ng-container *ngIf="notes && notes.length">
  <table mat-table
         class="ehs-table"
         style="width: 100%;"
         [dataSource]="notes">
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell
          *matHeaderCellDef> Created At </th>
      <td mat-cell
          *matCellDef="let note">
        <span>
          {{note.createdAt | date:'medium'}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="admin">
      <th mat-header-cell
          *matHeaderCellDef> Admin </th>
      <td mat-cell
          *matCellDef="let note">
        {{getAdminName(note.admin) | emailName | titlecase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell
          *matHeaderCellDef> Note </th>
      <td mat-cell
          *matCellDef="let note">
        <mat-form-field>
          <textarea matInput
                    #strInput="ngModel"
                    [ngModel]="note.str"
                    (change)="updateNoteHandler(note, $any({str: strInput.value}))"
                    class="mat-body-1">
        </textarea>
        </mat-form-field>
    </ng-container>

    <ng-container matColumnDef="contactedAt">
      <th mat-header-cell
          *matHeaderCellDef>Contacted At</th>
      <td mat-cell
          *matCellDef="let note">
        <mat-form-field>
          <input matInput
                 type="date"
                 #contactedAtInput="ngModel"
                 [ngModel]="note.contactedAt"
                 (blur)="updateNoteHandler(note, $any({contactedAt: contactedAtInput.value}))"
                 autocomplete="off"
                 date>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="deliveryType">
      <th mat-header-cell
          *matHeaderCellDef>Delivery Type</th>
      <td mat-cell
          *matCellDef="let note">
        <mat-form-field>
          <mat-select [ngModel]="note.deliveryTypes"
                      [multiple]="true"
                      #deliveryTypeInput="ngModel"
                      (openedChange)="!$event ? updateNoteHandler(note,
          $any({deliveryTypes: deliveryTypeInput.value})) : null"
                      matInput>
            <mat-option *ngFor="let deliveryType of deliveryTypes"
                        [value]="deliveryType">
              {{deliveryType | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell
          *matHeaderCellDef></th>
      <td mat-cell
          *matCellDef="let note">
        <button mat-icon-button
                type="button"
                title="remove note"
                (click)="removeNote.emit(note)">
          <mat-icon>close</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let user; columns: displayedColumns;"></tr>

  </table>
</ng-container>
