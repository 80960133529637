import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Company,
  CompanySecurity,
  CompanySecurityLogin,
  PersonType
} from '@common';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Returns the company for the current user.
   */
  public get(): Observable<Company> {
    return this.http.get<Company>('api/v1/company');
  }

  /**
   * Returns if the client-code exists for any company.
   */
  public isValidClientCode(params: {
    clientCode: string;
  }): Observable<boolean> {
    const { clientCode } = params;

    return this.http
      .get<{ valid: boolean }>('api/v1/company/code', {
        params: new HttpParams().set('clientCode', clientCode)
      })
      .pipe(map(({ valid }) => valid));
  }

  /**
   * Returns the list of person-types available to select
   * for the given company.
   */
  public getSupportedPersonTypes(params: {
    clientCode: string;
  }): Observable<PersonType[]> {
    const { clientCode } = params;

    return this.http.get<Array<PersonType>>(
      `api/v1/company/types/${clientCode}`
    );
  }

  /**
   * Returns the company logo for the given client-code,
   * if there is one
   */
  public getCompanyLogoUrl(params: {
    clientCode: string;
  }): Observable<Pick<Company, '_id' | 'clientCode' | 'logoUrl'>> {
    const { clientCode } = params;

    return this.http.get<Pick<Company, '_id' | 'logoUrl'>>(
      'api/v1/company/logo',
      {
        params: new HttpParams().set('clientCode', clientCode)
      }
    );
  }

  /**
   * Returns the company sign in options, whether MS login or username/pwd login is allowed for this client
   */
  public getSignInOptions(params: {
    clientCode: string;
  }): Observable<CompanySecurityLogin> {
    const { clientCode } = params;

    return this.http
      .get<CompanySecurityLogin>('api/v1/company/sign-in-options', {
        params: new HttpParams().set('clientCode', clientCode)
      })
      .pipe(
        take(1),
        catchError((_) =>
          of({ clientCode, signInOptions: [] } as CompanySecurity)
        )
      );
  }
}
