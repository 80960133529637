import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminAudit, AdminAuditActionGroups } from '@common';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AdminAuditHttpService {
  private baseUrl = 'api/v1/admin/admin-audit';

  constructor(private http: HttpClient) {}

  public list(params: {
    type?: AdminAuditActionGroups;
    pageNumber: number;
    maxResults?: number;
  }): Observable<{ adminAudits: AdminAudit[]; hasNext: boolean }> {
    const { type, pageNumber, maxResults } = params;
    let httpParams = new HttpParams().append('pageNumber', pageNumber);

    if (type) {
      httpParams = httpParams.append('type', type);
    }

    if (maxResults) {
      httpParams = httpParams.append('maxResults', maxResults);
    }

    return this.http.get<{
      adminAudits: AdminAudit[];
      hasNext: boolean;
    }>(`${this.baseUrl}/list`, {
      params: httpParams
    });
  }
}
