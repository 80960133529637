import { PartialUser, User } from '@common';

/**
 * The list of user-properties that are supported by the
 * EhsUserInfoComponent.
 */
export type EhsUserInfoProps = keyof User | keyof PartialUser;

/**
 * Type representing a single column in the EhsUserInfoComponent.
 */
export type EhsUserInfoColumn =
  | EhsUserInfoColumnWithProp
  | EhsUserInfoColumnValueOverride;

export interface EhsUserInfoColumnBase {
  /**
   * The title of the column.
   */
  title: string;
}

export interface EhsUserInfoColumnWithProp extends EhsUserInfoColumnBase {
  /**
   * The property we are to display.
   */
  prop: EhsUserInfoProps;

  /**
   * If we are to apply the `titlecase` pipe.
   */
  titleCase?: boolean;

  /**
   * If we are to apply the `namecase` pipe.
   */
  nameCase?: boolean;

  /**
   * Flag that is used to denote specific custom template overrides.
   * These need to be denoted here and setup within the component itself.
   */
  templateOverride?: boolean;
}

export const isEhsUserInfoColumnWithProp = (
  column: EhsUserInfoColumn
): column is EhsUserInfoColumnWithProp =>
  (column as EhsUserInfoColumnWithProp).prop !== undefined;

export interface EhsUserInfoColumnValueOverride extends EhsUserInfoColumnBase {
  /**
   * Override function or value that can be used to manipulate the value shown.
   * This could also be used to display custom values not dependant on
   * the actual user's data.
   */
  override:
    | string
    | ((params: {
        user: Partial<User> | Partial<PartialUser>;
        hideFalsy?: boolean;
      }) => string);
}

export const isEhsUserInfoColumValueOverride = (
  column: EhsUserInfoColumn
): column is EhsUserInfoColumnValueOverride =>
  (column as EhsUserInfoColumnValueOverride).override !== undefined;
