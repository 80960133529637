/* eslint-disable @typescript-eslint/no-empty-interface */
export type EhsRegFilters =
  | OnsiteEhsRegFilters
  | OffsiteEhsRegFilters
  | ProviderEhsRegFilters;
/**
 * This represents the base query-param data that can be passed-in,
 * which are then emitted on change. All versions use this interface
 *
 */
export interface BaseEhsRegFilters {
  /**
   * The search string, applied and available on all filters.
   */
  search?: string;
  /** The Rack Number and release status of the rack applied to the user registration during admin result editing */
  rack?: string;
  released?: string | boolean;
}

export enum ScreeningFilters {
  SCREENING = 'screening',
  FLU = 'flu'
}

/**
 * This is the onsite filters available, and seen in the UI.
 */
export interface OnsiteEhsRegFilters extends BaseEhsRegFilters {
  /**
   * Date-string of the event-date. If not a date-string, then
   * it is ignored.
   *
   */
  eventDate?: string;
  /**
   * The date to "start" event-date searching against
   */
  startDate?: string;

  /**
   * The date to "end" event-date searching against
   */
  endDate?: string;

  /**
   * The _id of the admin-user we will apply during our searches,
   * compares against reviewedBy
   */
  admin?: string;
  /**
   * The type of event is filtered here
   */
  screeningFilter?: ScreeningFilters;
  vaccineLotNumber?: string;
  vaccineName?: string;
  vaccineExpDate?: Date;
  vaccinationAction?: 'enable' | 'disable' | undefined;
}

export interface OffsiteEhsRegFilters extends BaseEhsRegFilters {
  // TODO:
}

export interface ProviderEhsRegFilters extends BaseEhsRegFilters {
  // TODO:
}
