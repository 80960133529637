import { ObjectId } from 'mongodb';
import { LabResultCode, UserTest, UserTestId } from '../user-test/user-test';

export const userResultTestResultValues: Record<
  UserResultTestResultValueType,
  string
> = {
  NM: 'Numeric Value',
  TX: 'Text Value',
  ST: 'String Value'
};
export enum UserResultTestResultValueType {
  /**
   * Text value, usually a "reference" value
   */
  TX = 'TX',
  /**
   * String value, usually a value like Positive, or NEGATIVE.
   * Could be useful as an enum value.
   */
  ST = 'ST',
  /**
   * Numeric value, this value should be parsable to a float/integer
   * or at least null for "blank" values
   */
  NM = 'NM'
}
/**
 * Test result status.
 * F = Final Result
 * C = Correction to Results
 * P = Preliminary Results
 * X = Test Canceled
 * I = Pending Results
 */
export enum UserResultTestStatus {
  F = 'F',
  C = 'C',
  P = 'P',
  X = 'X',
  I = 'I'
}
export enum UserResultTestResultBoolean {
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}
export type UserResultTestResultRange =
  | UserResultTestResultBoolean
  | 'NotEstab.'
  | number
  | string
  | {
      low: number;
      high: number;
    };
export enum UserResultTestResultRangeStatus {
  PANIC_LOW = '<',
  BELOW_NORMAL = 'L',
  BELOW_NORMAL_PANIC = 'LL',
  NORMAL = 'N',
  ABOVE_NORMAL = 'H',
  ABOVE_NORMAL_PANIC = 'HH',
  PANIC_HIGH = '>',
  ABNORMAL = 'A',
  VERY_ABNORMAL = 'AA',
  NO_COMMENT = 'NC'
}

export const userResultTestResultRangeStatus: Record<
  UserResultTestResultRangeStatus,
  string
> = {
  [UserResultTestResultRangeStatus.PANIC_LOW]: 'Panic Low (<)',
  [UserResultTestResultRangeStatus.BELOW_NORMAL]: 'Below Normal (L)',
  [UserResultTestResultRangeStatus.BELOW_NORMAL_PANIC]:
    'Below Normal Panic (LL)',
  [UserResultTestResultRangeStatus.NORMAL]: 'Normal / No Comment (N)',
  [UserResultTestResultRangeStatus.ABOVE_NORMAL]: 'Above Normal (H)',
  [UserResultTestResultRangeStatus.ABOVE_NORMAL_PANIC]:
    'Above Normal Panic (HH)',
  [UserResultTestResultRangeStatus.PANIC_HIGH]: 'Panic High (>)',
  [UserResultTestResultRangeStatus.ABNORMAL]: 'Abnormal (A)',
  [UserResultTestResultRangeStatus.VERY_ABNORMAL]: 'Very Abnormal (AA)',
  [UserResultTestResultRangeStatus.NO_COMMENT]: 'Normal / No Comment (N)'
};

/**
 * Map of out of range range-statuses that are
 * above normal
 */
export const outOfRangeHighStatusMap = {
  [UserResultTestResultRangeStatus.ABOVE_NORMAL]:
    UserResultTestResultRangeStatus.ABOVE_NORMAL,
  [UserResultTestResultRangeStatus.ABOVE_NORMAL_PANIC]:
    UserResultTestResultRangeStatus.ABOVE_NORMAL_PANIC,
  [UserResultTestResultRangeStatus.PANIC_HIGH]:
    UserResultTestResultRangeStatus.PANIC_HIGH
};

/**
 * Map of out of range range-statuses that are
 * below normal
 */
export const outOfRangeLowStatusMap = {
  [UserResultTestResultRangeStatus.BELOW_NORMAL]:
    UserResultTestResultRangeStatus.BELOW_NORMAL,
  [UserResultTestResultRangeStatus.BELOW_NORMAL_PANIC]:
    UserResultTestResultRangeStatus.BELOW_NORMAL_PANIC,
  [UserResultTestResultRangeStatus.PANIC_LOW]:
    UserResultTestResultRangeStatus.PANIC_LOW
};
/**
 * The result of a test test
 * This represents whats in the
 * userResult.test[].result[]
 */
export interface UserResultTestResult {
  /**
   * List of unformatted string comments, could be an array of strings
   * or a single string (legacy)
   */
  comments: string | Array<string>;
  /**
   * The labResultCode. Used with the user-result labType property
   * to find the corresponding user-test, which has the id
   */
  resultCode: LabResultCode;
  /**
   * The labResultName. The name of the test taken
   */
  resultName?: string;
  /**
   * The corresponding user-test that goes should be added
   * based upon the result-code above.
   */
  userTest: UserTestId | ObjectId | UserTest;
  /**
   * The value type
   * * Looks to be only 3 potential value types
   * 1. TX (text)
   * 2. ST (string/text)
   * 3. NM (number?)
   * This should be parsed from the life-point-response-value-type
   * and saved as one of our value types for simplicity.
   */
  valueType: UserResultTestResultValueType;
  /**
   * The value of the test. Could be a number, or a string depending on the test value.
   * The parsing should
   */
  value: string | number;
  /**
   * The range of the value.
   * Could be a number of different values depending on
   * the type of test.
   */
  range: UserResultTestResultRange;
  /**
   * The date and time the collection took place
   */
  collectionDate: Date;
  /**
   * The range status, used to display the "top-level"
   * color
   */
  rangeStatus: UserResultTestResultRangeStatus;
  /**
   * The loincId, standardized code for this result.
   * can be matched with LoincCode on user-tests
   */
  loincId: string;
  /**
   * The standardized name of this result
   */
  loincName: string;

  /**
   * Test result status. We mostly care about `F` and `X`.
   * F means the test results are complete and X means it was canceled or failed
   */
  status: UserResultTestStatus;
  /**
   * Unit of measure of the test result
   * e.g. mIU/L, mg/dl etc...
   */
  unitsOfMeasure?: string;
  /**
   * Not sure what this is used for yet.
   * e.g. `CB` | `EDIWK`, etc...
   */
  producersId?: string;
}
