import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatusHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Returns the `config.json` file, which can be used
   * to notify of application updates in the future,
   * or support session refreshes.
   */
  public get() {
    return this.http.get('api/v1/status');
  }
}
