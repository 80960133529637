import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toMap } from '@common';
import { AdminEventLocationHttpService } from '@http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CLIENT_ADMIN_CORE_INJECTION_TOKEN } from '@ehs-ngrx/common';
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  take,
  tap
} from 'rxjs/operators';
import { EventLocationNgrxFacade } from '..';
import { eventLocationNgrxActions } from './event-location-ngrx.actions';

@Injectable()
export class EventLocationNgrxEffects {
  constructor(
    @Inject(CLIENT_ADMIN_CORE_INJECTION_TOKEN)
    private clientAdmin: boolean,
    private actions$: Actions,
    private adminEventLocationHttp: AdminEventLocationHttpService,
    private eventLocationNgrx: EventLocationNgrxFacade,
    private snackBar: MatSnackBar
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationNgrxActions.get.req),
      mergeMap(({ payload: { id } }) =>
        this.adminEventLocationHttp
          .get(id, { clientAdmin: this.clientAdmin })
          .pipe(
            map((entity) => eventLocationNgrxActions.get.success({ entity })),
            catchError(() => [eventLocationNgrxActions.get.failed()])
          )
      )
    )
  );

  getStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationNgrxActions.getStats.req),
      mergeMap((action) =>
        this.adminEventLocationHttp
          .getStats({
            company: action.payload.company,
            eventService: action.payload.eventService,
            clientAdmin: this.clientAdmin
          })
          .pipe(
            map((stats) =>
              eventLocationNgrxActions.getStats.success({
                stats: toMap({ entities: stats })
              })
            ),
            catchError(() => [eventLocationNgrxActions.getStats.failed()])
          )
      )
    )
  );

  listByCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationNgrxActions.listByCompany.req),
      mergeMap(({ payload }) =>
        // TODO: this will change!
        this.adminEventLocationHttp
          .listEventLocations(payload.company, {
            clientAdmin: this.clientAdmin
          })
          .pipe(
            map((entities) =>
              eventLocationNgrxActions.listByCompany.success({ entities })
            ),
            catchError(() => [eventLocationNgrxActions.listByCompany.failed()])
          )
      )
    )
  );

  listByService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationNgrxActions.listByService.req),
      mergeMap(({ payload }) =>
        this.adminEventLocationHttp
          .listEventLocationsByService({
            companyId: payload.company,
            eventService: payload.eventService,
            clientAdmin: this.clientAdmin
          })
          .pipe(
            map((entities) =>
              eventLocationNgrxActions.listByService.success({ entities })
            ),
            catchError(() => [eventLocationNgrxActions.listByService.failed()])
          )
      )
    )
  );

  releaseEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationNgrxActions.release.req),
      mergeMap(({ payload: { eventLocation, users } }) =>
        this.adminEventLocationHttp.releaseEvent({ eventLocation, users }).pipe(
          map(() => eventLocationNgrxActions.release.success()),
          catchError(() => [eventLocationNgrxActions.release.failed()])
        )
      )
    )
  );

  cancelEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationNgrxActions.cancelEvent.req),
      mergeMap(({ payload: { eventLocation } }) =>
        this.adminEventLocationHttp.cancelEvent({ eventLocation }).pipe(
          map(() => eventLocationNgrxActions.cancelEvent.success()),
          catchError(() => [eventLocationNgrxActions.cancelEvent.failed()])
        )
      )
    )
  );

  recentEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationNgrxActions.listRecent.req),
      mergeMap(() =>
        this.adminEventLocationHttp
          .listRecent({ clientAdmin: this.clientAdmin })
          .pipe(
            mergeMap((entities) => [
              eventLocationNgrxActions.listRecent.success({
                entities: entities?.eventLocations || []
              }),
              eventLocationNgrxActions.getStats.success({
                stats: toMap({ entities: entities?.stats || [] })
              })
            ]),
            catchError(() => [eventLocationNgrxActions.listRecent.failed()])
          )
      )
    )
  );

  getRegTimes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationNgrxActions.getRegTimes.req),
      mergeMap(({ payload: { eventLocation } }) =>
        this.adminEventLocationHttp
          .getRegTimes(eventLocation, { clientAdmin: this.clientAdmin })
          .pipe(
            map((timesByType) =>
              eventLocationNgrxActions.getRegTimes.success(timesByType)
            ),
            catchError(() => [eventLocationNgrxActions.getRegTimes.failed()])
          )
      )
    )
  );

  /**
   * This effect exists only to consolidate remove and update events
   * before dispatching the actual http request action to update the event-location
   */
  removeOrUpdateCustomTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        eventLocationNgrxActions.removeCustomTime,
        eventLocationNgrxActions.updateCustomTime
      ),
      // This is required to let the reducer trigger first and update the state
      debounceTime(0),
      mergeMap(({ eventLocation }) =>
        // This is required as `get$` doesn't auto-complete.
        this.eventLocationNgrx.get$(eventLocation).pipe(take(1))
      ),
      map((eventLocation) =>
        eventLocationNgrxActions.updateEventLocationCustomTimes.req({
          eventLocation
        })
      )
    )
  );

  /**
   * This effect is **not** ran directly, instead its dispatched by
   * `removeOrUpdateCustomTime$`
   */
  updateEventLocationCustomTimes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eventLocationNgrxActions.updateEventLocationCustomTimes.req),
      mergeMap(({ payload: { eventLocation } }) =>
        this.adminEventLocationHttp.updateCustomTimes(eventLocation).pipe(
          tap(
            () => this.snackBar.open('Custom times updated', 'Ok'),
            () => this.snackBar.open('Oops, there was an error', 'Ok')
          ),
          map((eventLocation) =>
            eventLocationNgrxActions.updateEventLocationCustomTimes.success({
              eventLocation
            })
          ),
          catchError(() => [
            eventLocationNgrxActions.updateEventLocationCustomTimes.failed()
          ])
        )
      )
    )
  );
}
