import { FingerStickCodes } from '../../constants/finger-stick-tests';
import { LabResultCode } from '../user-test/user-test';

export enum ProviderHealthTestValues {
  INCLUDED = 'included',
  REQUIRED = 'required',
  N_A = 'n/a'
}
/**
 * Definition of data for the test requested.
 * All values are radio buttons from GWApps.
 * If the field is included, then we add it,
 * If it is required then we add it on the pdf and add *
 * so the doctor knows it is required. If it is N/A, or null
 * then it is not displayed on the pdf.
 */
export interface ProviderHealthTestsRequested {
  hoursFasted?: ProviderHealthTestValues;
  tobaccoUser?: ProviderHealthTestValues;
  hdlCholesterol?: ProviderHealthTestValues;
  ldlCholesterol?: ProviderHealthTestValues;
  totalCholesterol?: ProviderHealthTestValues;
  height?: ProviderHealthTestValues;
  weight?: ProviderHealthTestValues;
  waist?: ProviderHealthTestValues;
  bloodPressure?: ProviderHealthTestValues;
  glucose?: ProviderHealthTestValues;
  tcHdlRatio?: ProviderHealthTestValues;
  triglycerides?: ProviderHealthTestValues;
  hemoglobinA1c?: ProviderHealthTestValues;
}

/**
 * Map of the keys of the tests requested, to the actual
 * test-code. These are provided and should be the same
 * as the finger-stick/admin-result codes.
 *
 * **NOTE** the types are weird due to blood pressure being weird.
 *
 * **NOTE** the types where changed from `PrimaryOrderCode` to `LabResultCode`, as
 * part of the move away from `PrimaryOrderCode`.
 */
export const PROVIDER_HEALTH_TESTS_REQUESTED_MAP: Record<
  keyof Omit<ProviderHealthTestsRequested, 'tobaccoUser' | 'hoursFasted'>,
  | LabResultCode
  | {
      systolic: LabResultCode;
      diastolic: LabResultCode;
    }
> = {
  hdlCholesterol: LabResultCode(FingerStickCodes.HDL),
  ldlCholesterol: LabResultCode(FingerStickCodes.LDL),
  totalCholesterol: LabResultCode(FingerStickCodes.TOTAL_CHOLESTEROL),
  tcHdlRatio: LabResultCode(FingerStickCodes.TOTAL_CHOLESTEROL_RATIO),

  height: LabResultCode(FingerStickCodes.HEIGHT),
  weight: LabResultCode(FingerStickCodes.WEIGHT),
  waist: LabResultCode(FingerStickCodes.WAIST),
  bloodPressure: {
    systolic: LabResultCode(FingerStickCodes.BLOOD_PRESSURE_SYSTOLIC),
    diastolic: LabResultCode(FingerStickCodes.BLOOD_PRESSURE_DIASTOLIC)
  },
  triglycerides: LabResultCode(FingerStickCodes.TRIGLYCERIDES),
  glucose: LabResultCode(FingerStickCodes.GLUCOSE),
  hemoglobinA1c: LabResultCode(FingerStickCodes.HEMOGLOBIN_A1C)
};

/**
 * Map of the keys of the provider-health tests, to their
 * ProviderHealthTestRequested key.
 * This is the reverse of the PROVIDER_HEALTH_TESTS_REQUESTED_MAP.
 *
 * **note** the 1 special case, for `bloodPressure` means those 2 are duplicated
 * as values.
 */
export const PROVIDER_HEALTH_TESTS_REQUESTED_CODE_MAP: Record<
  LabResultCode,
  keyof Omit<ProviderHealthTestsRequested, 'tobaccoUser' | 'hoursFasted'>
> = {
  [LabResultCode(FingerStickCodes.HDL)]: 'hdlCholesterol',
  [LabResultCode(FingerStickCodes.LDL)]: 'ldlCholesterol',
  [LabResultCode(FingerStickCodes.TOTAL_CHOLESTEROL)]: 'totalCholesterol',
  [LabResultCode(FingerStickCodes.TOTAL_CHOLESTEROL_RATIO)]: 'tcHdlRatio',

  [LabResultCode(FingerStickCodes.HEIGHT)]: 'height',
  [LabResultCode(FingerStickCodes.WEIGHT)]: 'weight',
  [LabResultCode(FingerStickCodes.WAIST)]: 'waist',
  [LabResultCode(FingerStickCodes.BLOOD_PRESSURE_SYSTOLIC)]: 'bloodPressure',
  [LabResultCode(FingerStickCodes.BLOOD_PRESSURE_DIASTOLIC)]: 'bloodPressure',
  [LabResultCode(FingerStickCodes.TRIGLYCERIDES)]: 'triglycerides',
  [LabResultCode(FingerStickCodes.GLUCOSE)]: 'glucose',
  [LabResultCode(FingerStickCodes.HEMOGLOBIN_A1C)]: 'hemoglobinA1c'
};
/**
 * Utility function that returns the required
 * tests for the given requested ones
 */
export const getRequiredTestsRequested = (
  testsRequested: ProviderHealthTestsRequested
) =>
  testsRequested
    ? (
        Object.entries(testsRequested) as Array<
          [
            keyof ProviderHealthTestsRequested,
            ProviderHealthTestValues | undefined
          ]
        >
      ).reduce(
        (acc, [key, value]) =>
          value === ProviderHealthTestValues.REQUIRED ? [...acc, key] : acc,
        [] as Array<keyof ProviderHealthTestsRequested>
      )
    : [];

/**
 * Utility function that returns the included tests
 * tests for the given requested ones
 */
export const getIncludedTestsRequested = (
  testsRequested: ProviderHealthTestsRequested
) =>
  testsRequested
    ? (
        Object.entries(testsRequested) as Array<
          [
            keyof ProviderHealthTestsRequested,
            ProviderHealthTestValues | undefined
          ]
        >
      ).reduce(
        (acc, [key, value]) =>
          value === ProviderHealthTestValues.INCLUDED ? [...acc, key] : acc,
        [] as Array<keyof ProviderHealthTestsRequested>
      )
    : [];
