import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserVaccination } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserVaccinationHttpService {
  constructor(private http: HttpClient) {}

  public get(id: string): Observable<UserVaccination> {
    return this.http.get<UserVaccination>(`api/v1/vaccinations/${id}`);
  }

  public getByUserRegId(userRegId: string): Observable<UserVaccination> {
    return this.http.get<UserVaccination>(
      `api/v1/vaccinations/registrations/${userRegId}`
    );
  }

  public list(): Observable<UserVaccination[]> {
    return this.http.get<UserVaccination[]>('api/v1/vaccinations');
  }
}
