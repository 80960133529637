/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

/**
 * A QueryList is sent to the backend during
 * "list" as http params
 */
export type QueryList<T> = Partial<{
  [key in keyof T]: any;
}> & {
  /**
   * Any generic search string that could be applied
   */
  query?: string;
  /**
   * The limit of items returned
   */
  limit?: number;
  /**
   * The number of items to skip by
   */
  skip?: number;
};

@Injectable({
  providedIn: 'root'
})
export class QueryListService {
  /**
   * Builds an http-params object from a given query
   */
  public build<T = any>(query: QueryList<T>): HttpParams {
    if (!query) {
      return new HttpParams();
    }

    return Object.entries(query).reduce((httpParams: HttpParams, entry) => {
      // This cast needs to be done here
      const [key, value] = entry as [keyof QueryList<T>, any];

      if (value === null || value === undefined) {
        return httpParams;
      }

      if (Array.isArray(value)) {
        return this.buildArray({ httpParams, key: String(key), values: value });
      }

      if (typeof value === 'object') {
        return httpParams;
      }

      return httpParams.set(String(key), '' + value);
    }, new HttpParams());
  }

  /**
   * Adds the array of http params
   */
  private buildArray(params: {
    httpParams: HttpParams;
    key: string;
    values: any[];
  }): HttpParams {
    const { key, values } = params;

    return values.reduce(
      (httpParams: HttpParams, value) => httpParams.append(key, '' + value),
      params.httpParams
    );
  }
}
