import { InjectionToken } from '@angular/core';

/**
 * Type representing the `config.json` file properties generated
 * for production environments by the `build-config` script.
 *
 * This could be moved into `@common` if needed in the future.
 */
export interface EhsConfig {
  revision: string;
  ngVersion: string;
  tag: string;
  time: string;
  env?: 'local' | 'dev' | 'prod';
}

/**
 * Provider that can be used to override the ehs-config.
 */
export const EHS_CONFIG_TOKEN = new InjectionToken<EhsConfig>(
  'EHS_CONFIG_TOKEN'
);
