import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ClientAdminCoreNgrxFacade } from '@ehs-ngrx/client-admin-core-ngrx';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { AuthFacade } from '../app-store/auth/auth.facade';

@Injectable({
  providedIn: 'root'
})
class ClientAdminGuardService {
  constructor(
    private authFacade: AuthFacade,
    private clientAdminCoreNgrxFacade: ClientAdminCoreNgrxFacade,
    private router: Router,
    private location: Location
  ) {}

  canActivate() {
    this.authFacade.getUser();

    // **Note** this keeps watching, to keep things in sync
    this.authFacade.clientAdmin$
      .pipe(
        filter((user) => !!user.clientAdmin),
        take(1)
      )
      .subscribe((user) =>
        this.clientAdminCoreNgrxFacade.setClientAdminUser(user)
      );

    return combineLatest([
      this.authFacade.loading$,
      this.authFacade.clientAdmin$
    ]).pipe(
      // Required for wait for user to load in reducer
      debounceTime(0),
      filter(([loading]) => !loading),
      map(([, user]) => !!user && user.clientAdmin),
      tap((isAdmin) => {
        if (!isAdmin) {
          this.router.navigate(['/'], {
            queryParams: {
              redirectUrl: this.location.path()
            }
          });
        }
      })
    );
  }
}

export const ClientAdminGuard: CanActivateFn = () => {
  return inject(ClientAdminGuardService).canActivate();
};
