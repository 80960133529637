import { ObjectId } from 'mongodb';
import { DbDocument } from '../db-document';
import { Genders } from '../user';

/**
 * Returns a more readable version of the test range from
 * the UserTestUtil.getRange method.
 */
export const getEndUserTestRangeName = (
  rangeName: 'highRisk' | 'inRange' | 'outOfRange'
): string =>
  ({
    highRisk: 'High Risk',
    inRange: 'In Range',
    outOfRange: ' Out of Range'
  }[rangeName] || '');
/**
 * A user-test range **does not have its own schema**
 * this is saved in the `ranges` property under the user-test
 * schema.
 *
 * **note** on the graphing logic:
 * Basically the graph is the following
 * panicLow | lowRisk | lowNormal | highNormal | highRisk | panicHigh
 * Where:
 *  panicLow --> lowRisk = RED / outOfRange
 *  lowRisk --> lowNormal = YELLOW / highRisk
 *  lowNormal --> highNormal = GREEN / inRange
 *  highNormal --> highRisk = YELLOW / highRisk
 *  highRisk --> PanicHigh = RED / outOfRange
 *
 * **note** this entity has an _id property to match it with the
 * range in GWApps, but it does not have createdAt/updatedAt.
 */
export interface UserTestRange extends Pick<DbDocument, '_id'> {
  /**
   * The test-id related to the parent userTest.
   * This is passed from gw-apps due to user-test-ranges being its own form.
   * should be an id
   */
  testId: string | ObjectId;
  /**
   * The gender this test applies to.
   * Could be male, female or both
   */
  gender: Genders | 'both';
  /**
   * The age to apply this test for, inclusive
   */
  ageLow: number;
  /**
   * The age to apply this test for, inclusive
   */
  ageHigh: number;

  /**
   * The low range for "panic", inclusive.
   * This is the FIRST "bar", on the left
   */
  panicLow: number;
  /**
   * The low low-risk range, inclusive
   * This is the SECOND "bar", on the left
   */
  lowRisk: number;
  /**
   * The low of normal range, inclusive
   * This is the THIRD "bar", on the left
   */
  lowNormal: number;
  /**
   * The high for normal range, inclusive
   */
  highNormal: number;
  /**
   * The high risk range, inclusive
   */
  highRisk: number;
  /**
   * The high range for "panic", inclusive
   */
  panicHigh: number;
  /**
   * TODO: What is this?
   * **note** this isn't shown in the UI yet
   */
  qcHigh: number;
  /**
   * TODO: What is this?
   * **note** this isn't shown in the UI yet
   */
  atRisk: number;
}
