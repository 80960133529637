import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EhsUserFormModule } from '../../components/ehs-user-form/ehs-user-form.module';
import { EhsEditUserDialogComponent } from './ehs-edit-user-dialog.component';
import { EhsEditUserDialogService } from './ehs-edit-user-dialog.service';

@NgModule({
  declarations: [EhsEditUserDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    // Angular Material,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,

    // Sub modules
    EhsUserFormModule
  ],
  exports: [EhsEditUserDialogComponent],
  providers: [EhsEditUserDialogService]
})
export class EhsEditUserDialogModule {}
