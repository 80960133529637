import { Injectable } from '@angular/core';
import {
  EhsFormFieldLayout,
  EhsFormRow
} from '../ehs-form-field-layout/ehs-form-field-layout';
import {
  EhsUserForm,
  EhsUserFormFieldTypes
} from '../ehs-form-field-layout/ehs-form-field-types';

/**
 * This service is used to calculate the displayed data
 * in the ehs-user-form components.
 *
 * Naturally handles 2 use-cases.
 * - admin-walk-in forms
 * - admin-edit-user forms
 */
@Injectable()
export class EhsUserFormDisplayService {
  /**
   * Returns the layout for an admin create of a user. Similar
   * to the HPN import that creates multiple "partial users", but this
   * form is used to create a single version, without a reference to an
   * hpn user.
   *
   * **note** the form doesn't include the `company` property, this will
   * need to be handled by the parent component if its relevant to the use-case.
   *
   * This version will also required the form to be checked against pre-existing
   * user information to prevent collisions. However this should be handled externally
   * rather than included by this form.
   */
  public getAdminCreateLayout(): EhsFormFieldLayout<EhsUserForm> {
    const commonRowSpec: Omit<EhsFormRow<EhsUserForm>, 'fields'> = {
      gdColumns: 2,
      gdColumnsSm: 1,
      gdColumnsXs: 1
    };

    // **Note** this can be optimized, but first check with Lee to see if
    // the format/layout is what they want. Its easier to change at this time.
    return {
      rows: [
        {
          ...commonRowSpec,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.FIRST_NAME,
              required: true
            },
            {
              fieldType: EhsUserFormFieldTypes.LAST_NAME,
              required: true
            }
          ]
        },
        {
          ...commonRowSpec,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.SSN,
              required: true
            },
            {
              fieldType: EhsUserFormFieldTypes.BIRTH_DATE,
              required: true
            }
          ]
        },
        {
          ...commonRowSpec,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.GENDER,
              required: true
            },
            {
              fieldType: EhsUserFormFieldTypes.PERSON_TYPE,
              required: true
            }
          ]
        },
        // These fields are not required, and thus shown on the bottom half
        {
          ...commonRowSpec,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.PHONE
            },
            {
              fieldType: EhsUserFormFieldTypes.EMAIL
            }
          ]
        },
        {
          ...commonRowSpec,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.ADDRESS_1
            },
            {
              fieldType: EhsUserFormFieldTypes.ADDRESS_2
            }
          ]
        },
        {
          gdColumns: 3,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.CITY
            },
            {
              fieldType: EhsUserFormFieldTypes.STATE
            },
            {
              fieldType: EhsUserFormFieldTypes.ZIP
            }
          ]
        }
      ]
    };
  }

  /**
   * Returns the walk-in layout with configuration flags.
   */
  public getWalkinLayout(params: {
    /**
     * If the corresponding service for the walk-in event
     * is a COVID event. Then we will show covid related fields.
     *
     * If covid, then we will show the race+ethnicity fields.
     */
    showRaceAndEthnicity?: boolean;
  }): EhsFormFieldLayout<EhsUserForm> {
    const { showRaceAndEthnicity } = params;

    return {
      rows: [
        {
          gdColumns: 2,
          gdColumnsSm: 1,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.PHONE,
              required: false
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 1,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.LAST_NAME,
              required: true
            },
            {
              fieldType: EhsUserFormFieldTypes.FIRST_NAME,
              // This has been made required as of #3876
              required: true
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 1,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.ADDRESS_1,
              required: false
            },
            {
              fieldType: EhsUserFormFieldTypes.ADDRESS_2,
              required: false
            }
          ]
        },
        {
          gdColumns: 3,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.CITY,
              required: false
            },
            {
              fieldType: EhsUserFormFieldTypes.STATE,
              required: false
            },
            {
              fieldType: EhsUserFormFieldTypes.ZIP,
              required: false
            }
          ]
        },
        {
          gdColumns: 3,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.EMAIL,
              // No longer required during walk-in signup
              required: false
            },
            {
              fieldType: EhsUserFormFieldTypes.SSN,
              required: true
            },
            {
              fieldType: EhsUserFormFieldTypes.BIRTH_DATE,
              required: true
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.GENDER,
              required: true
            },
            {
              fieldType: EhsUserFormFieldTypes.PERSON_TYPE,
              required: true
            }
          ]
        },
        ...(showRaceAndEthnicity
          ? [
              {
                gdColumns: 2,
                gdColumnsSm: 2,
                gdColumnsXs: 1,
                fields: [
                  {
                    fieldType: EhsUserFormFieldTypes.RACE,
                    required: true
                  },
                  {
                    fieldType: EhsUserFormFieldTypes.ETHNICITY,
                    required: true
                  }
                ]
              }
            ]
          : [])
      ]
    };
  }

  /**
   * **Note** this is in a dialog, so its always max columns 2.
   */
  public getAdminUserEditLayout(): EhsFormFieldLayout<EhsUserForm> {
    // TODO:
    // **note** height is not required, no ethnicity.
    return {
      rows: [
        {
          gdColumns: 2,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.PHONE,
              required: false
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.LAST_NAME,
              required: true
            },
            {
              fieldType: EhsUserFormFieldTypes.FIRST_NAME,
              // This isn't required during sign-up
              required: false
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.ADDRESS_1,
              required: false
            },
            {
              fieldType: EhsUserFormFieldTypes.ADDRESS_2,
              required: false
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.CITY,
              required: false
            },
            {
              fieldType: EhsUserFormFieldTypes.STATE,
              required: false
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.ZIP,
              required: false
            },
            {
              fieldType: EhsUserFormFieldTypes.EMAIL,
              required: true
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.SSN,
              required: true
            },
            {
              fieldType: EhsUserFormFieldTypes.BIRTH_DATE,
              required: true
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.HEIGHT,
              required: false
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.GENDER,
              required: true
            },
            {
              fieldType: EhsUserFormFieldTypes.PERSON_TYPE,
              required: true
            }
          ]
        },
        {
          gdColumns: 2,
          gdColumnsSm: 2,
          gdColumnsXs: 1,
          fields: [
            {
              fieldType: EhsUserFormFieldTypes.RACE,
              required: false
            },
            {
              fieldType: EhsUserFormFieldTypes.ETHNICITY,
              required: false
            }
          ]
        }
      ]
    };
  }
  // TODO: Add end user version
}
