import { UserResult } from '../../models/user-result/user-result';
import {
  bpDiasResultCodes,
  bpSysResultCodes,
  EHS_BP_DIA_RESULT_CODE,
  EHS_BP_SYS_RESULT_CODE,
  LABCORP_BP_DIA_RESULT_CODE,
  LABCORP_BP_SYS_RESULT_CODE,
  QUEST_BP_DIA_RESULT_CODE,
  QUEST_BP_SYS_RESULT_CODE
} from '../../constants/blood-pressure';
import {
  BiometricValues,
  QuestBiometricValues
} from '../../models/biometric-values';
import {
  EHS_HEIGHT_RESULT_CODE,
  heightObjectToInches,
  heightResultCodes,
  LABCORP_HEIGHT_RESULT_CODE,
  QUEST_HEIGHT_FT_RESULT_CODE,
  QUEST_HEIGHT_IN_RESULT_CODE
} from '../../constants/height';
import {
  EHS_WEIGHT_RESULT_CODE,
  LABCORP_WEIGHT_RESULT_CODE,
  QUEST_WEIGHT_RESULT_CODE,
  weightResultCodes
} from '../../constants/weight';
import { ServiceLabType } from '../../models/event-service/service-lab-type';
import { ALT_WEIGHT_CODE } from '../../requests/onsite-result-form';
import {
  UserResultTestResult,
  UserResultTestResultRangeStatus
} from '../../models/user-result/user-result-test-result';
import { CategoryTestData } from './test-category-map';
import { getBmi } from '../get-bmi';
import { UserTest } from '../../models/user-test/user-test';
import {
  EHS_BMI_RESULT_CODE,
  LABCORP_BMI_RESULT_CODE
} from '../../constants/bmi';
import { Genders } from '../../models/user';
import { UserTestUtil } from './user-test-util';

export class UserTestBiometricUtil {
  /**
   * Returns the height, weight & blood pressure (sys & dia) from the user-result.
   * It will first check if the test results have any of the properties, if it does
   * then it will return those values, otherwise it will return the height & weight
   * at the highest level.
   * @param params
   */
  public static getBiometricDataFrom(params: {
    userResult: Partial<UserResult>;
  }): BiometricValues {
    const { userResult } = params;
    const labBiometrics = (userResult?.tests || []).reduce(
      (biometric, test) => {
        const { results } = test || {};

        if (!results || !Array.isArray(results) || !results.length) {
          return biometric;
        }

        const subBiometrics = results.reduce((bioTests, resultTest) => {
          const { value, resultCode } = resultTest || {};

          if (!value || typeof value !== 'number') {
            return bioTests;
          }

          if (heightResultCodes.includes(resultCode)) {
            bioTests.height = value as number;
          }

          if (
            weightResultCodes.includes(resultCode) ||
            ALT_WEIGHT_CODE === resultCode
          ) {
            bioTests.weight = value as number;
          }

          if (bpDiasResultCodes.includes(resultCode)) {
            bioTests.bloodPressureDia = value as number;
          }

          if (bpSysResultCodes.includes(resultCode)) {
            bioTests.bloodPressureSys = value as number;
          }

          // Note: Quest passes 2 different result codes for height feet & inches
          if (resultCode === QUEST_HEIGHT_FT_RESULT_CODE) {
            bioTests.questHeightFeet = value as number;
          }

          if (resultCode === QUEST_HEIGHT_IN_RESULT_CODE) {
            bioTests.questHeightInches = value as number;
          }

          return bioTests;
        }, {} as QuestBiometricValues);

        if (!Object.keys(subBiometrics).length) {
          return biometric;
        }

        return {
          ...biometric,
          ...subBiometrics
        };
      },
      {} as QuestBiometricValues
    );

    return {
      height: this.getBiometricHeight({ userResult, labBiometrics }),
      weight: labBiometrics?.weight || userResult?.weight,
      bloodPressureDia:
        labBiometrics?.bloodPressureDia || userResult?.bloodPressureDia,
      bloodPressureSys:
        labBiometrics?.bloodPressureSys || userResult?.bloodPressureSys
    };
  }

  /**
   * Calculate the biometric Height.
   * Since QUEST labs passes the height as 2 lab result codes, 1 for Height Feet
   * and one for Height Inches, we need to calculate the height in inches so it matches
   * the other labs.
   */
  private static getBiometricHeight({
    userResult,
    labBiometrics
  }: {
    userResult: Partial<UserResult>;
    labBiometrics: QuestBiometricValues;
  }) {
    if (!labBiometrics && !userResult) {
      return undefined;
    }

    // If the lab is not quest, we return the height as is
    if (userResult?.labType !== ServiceLabType.QUEST) {
      return labBiometrics.height || userResult.height;
    }

    // If the quest height feet or inches are missing we return the height as is
    if (
      !labBiometrics?.questHeightFeet ||
      typeof labBiometrics?.questHeightInches !== 'number'
    ) {
      return labBiometrics.height || userResult.height;
    }

    // Otherwise we calculate the height & feet into inches
    return (
      heightObjectToInches({
        feet: labBiometrics.questHeightFeet,
        inches: labBiometrics.questHeightInches
      }) || userResult.height
    );
  }

  /**
   * Verify user Test is a for height by checking the result code
   */
  private static isHeightTest(test: UserResultTestResult): boolean {
    if (!test?.resultCode) {
      return false;
    }

    return [
      EHS_HEIGHT_RESULT_CODE,
      QUEST_HEIGHT_FT_RESULT_CODE,
      QUEST_HEIGHT_IN_RESULT_CODE,
      LABCORP_HEIGHT_RESULT_CODE
    ].includes(test.resultCode);
  }

  /**
   * Verify user Test is a for weight by checking the result code
   */
  private static isWeightTest(test: UserResultTestResult): boolean {
    if (!test?.resultCode) {
      return false;
    }

    return [
      EHS_WEIGHT_RESULT_CODE,
      QUEST_WEIGHT_RESULT_CODE,
      LABCORP_WEIGHT_RESULT_CODE
    ].includes(test.resultCode);
  }

  /**
   * Verify user Test is a for Systolic Blood Pressure by checking the result code
   */
  private static isBpSystolicTest(test: UserResultTestResult): boolean {
    if (!test?.resultCode) {
      return false;
    }

    return [
      EHS_BP_SYS_RESULT_CODE,
      QUEST_BP_SYS_RESULT_CODE,
      LABCORP_BP_SYS_RESULT_CODE
    ].includes(test.resultCode);
  }

  /**
   * Verify user Test is a for Diastolic Blood Pressure by checking the result code
   */
  private static isBpDiastolicTest(test: UserResultTestResult): boolean {
    if (!test?.resultCode) {
      return false;
    }

    return [
      EHS_BP_DIA_RESULT_CODE,
      QUEST_BP_DIA_RESULT_CODE,
      LABCORP_BP_DIA_RESULT_CODE
    ].includes(test.resultCode);
  }

  /**
   * Verify user Test is a for Body Mass Index by checking the result code
   */

  private static isBmiTest(test: UserResultTestResult): boolean {
    if (!test?.resultCode) {
      return false;
    }

    return [EHS_BMI_RESULT_CODE, LABCORP_BMI_RESULT_CODE].includes(
      test.resultCode
    );
  }

  /**
   * Master function that will take the biometrics array (if it exists) and add any missing default tests by calling the following two functions
   */

  public static getMissingBiometricTests(params: {
    tests: CategoryTestData[];
    userResult: UserResult;
    userTestMap: Record<string, UserTest>;
    age: number;
    gender: Genders;
  }): CategoryTestData[] {
    const { tests, userResult, userTestMap, age, gender } = params;
    const missingTests = this.getMissingBiometricTestNames(tests);

    missingTests.forEach((test) => {
      const data = this.getMissingBiometricTestData({
        testName: test,
        userResult,
        userTestMap,
        age,
        gender
      });

      if (data) {
        tests.push(data);
      }
    });

    // ToDo: Re-order tests: Create object of order, height:0,weight:1,bmi:2,bpSys:3,bpDia:4

    return tests;
  }

  /**
   * Given an array of biometric tests, we want to return which, if any, default tests (height, weight, blood pressure, BMI) are missing from our biometric array
   */

  private static getMissingBiometricTestNames(
    tests: CategoryTestData[]
  ): Array<'height' | 'weight' | 'bpSystolic' | 'bpDiastolic' | 'bmi'> {
    // If biometric array doesn't exist or is empty, all the default tests are missing
    if (!tests || !tests.length) {
      return ['height', 'weight', 'bpSystolic', 'bpDiastolic', 'bmi'];
    }

    const biometricTestsFound = (tests || []).reduce(
      (missingTests, test) => {
        if (this.isHeightTest(test.userResultTestResult)) {
          missingTests.height = true;
        } else if (this.isWeightTest(test.userResultTestResult)) {
          missingTests.weight = true;
        } else if (this.isBpSystolicTest(test.userResultTestResult)) {
          missingTests.bpSystolic = true;
        } else if (this.isBpDiastolicTest(test.userResultTestResult)) {
          missingTests.bpDiastolic = true;
        } else if (this.isBmiTest(test.userResultTestResult)) {
          missingTests.bmi = true;
        }

        return missingTests;
      },
      {
        height: false,
        weight: false,
        bmi: false,
        bpSystolic: false,
        bpDiastolic: false
      }
    );

    // Filter to only add the keys that are false
    return Object.entries(biometricTestsFound)
      .filter(([_, found]) => !found)
      .map(
        ([key, _]: [
          'height' | 'weight' | 'bpSystolic' | 'bpDiastolic' | 'bmi',
          boolean
        ]) => key
      );
  }

  /**
   * Helper function that populates UserResultUserTest objects with data from user result when default values are missing from biometrics array
   */

  private static getMissingBiometricTestData(params: {
    testName: string;
    userResult: UserResult;
    userTestMap: Record<string, UserTest>;
    age: number;
    gender: Genders;
  }): CategoryTestData | undefined {
    const { testName, userResult, userTestMap, age, gender } = params;
    const { height, weight } = UserTestBiometricUtil.getBiometricDataFrom({
      userResult
    });

    const mergedHeight = userResult.height || height;
    const mergedWeight = userResult.weight || weight;

    switch (testName) {
      case 'height':
        return userResult.height
          ? {
              userResultTestResult: {
                range: UserResultTestResultRangeStatus.NORMAL,
                value: userResult.height
              } as UserResultTestResult,
              userTest: userTestMap[EHS_HEIGHT_RESULT_CODE],
              userTestRange: UserTestUtil.getUserTestRange({
                userTest: userTestMap[EHS_HEIGHT_RESULT_CODE],
                age,
                gender
              })
            }
          : undefined;
      case 'weight':
        return userResult.weight
          ? {
              userResultTestResult: {
                range: UserResultTestResultRangeStatus.NORMAL,
                value: userResult.weight
              } as UserResultTestResult,
              userTest: userTestMap[EHS_WEIGHT_RESULT_CODE],
              userTestRange: UserTestUtil.getUserTestRange({
                userTest: userTestMap[EHS_WEIGHT_RESULT_CODE],
                age,
                gender
              })
            }
          : undefined;
      case 'bmi':
        if (mergedHeight && mergedWeight) {
          const bmiValue = getBmi({
            height: mergedHeight,
            weight: mergedWeight
          }).toFixed(2);

          return {
            userResultTestResult: {
              value: Number(bmiValue)
            } as UserResultTestResult,
            userTest: userTestMap[EHS_BMI_RESULT_CODE],
            userTestRange: UserTestUtil.getUserTestRange({
              userTest: userTestMap[EHS_BMI_RESULT_CODE],
              age,
              gender
            })
          };
        }
        break;
      case 'bpSystolic':
        return userResult.bloodPressureSys
          ? {
              userResultTestResult: {
                value: userResult.bloodPressureSys
              } as UserResultTestResult,
              userTest: userTestMap[EHS_BP_SYS_RESULT_CODE],
              userTestRange: UserTestUtil.getUserTestRange({
                userTest: userTestMap[EHS_BP_SYS_RESULT_CODE],
                age,
                gender
              })
            }
          : undefined;
      case 'bpDiastolic':
        return userResult.bloodPressureDia
          ? {
              userResultTestResult: {
                value: userResult.bloodPressureDia
              } as UserResultTestResult,
              userTest: userTestMap[EHS_BP_DIA_RESULT_CODE],
              userTestRange: UserTestUtil.getUserTestRange({
                userTest: userTestMap[EHS_BP_DIA_RESULT_CODE],
                age,
                gender
              })
            }
          : undefined;
    }

    return undefined;
  }
}
