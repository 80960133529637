import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { UserCritical, UserUtil } from '@common';
import { UserCriticalsColumn } from './user-criticals-column';

@Component({
  selector: 'ehs-user-criticals-table',
  templateUrl: './ehs-user-criticals-table.component.html',
  styleUrls: ['./ehs-user-criticals-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsUserCriticalsTableComponent {
  @Input() columns: UserCriticalsColumn[] = [];
  @Input() rows: UserCritical[] = [];
  @Input() hasNext?: boolean;

  @Output() loadMore = new EventEmitter();
  @Output() rowClicked = new EventEmitter<string>();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  /**
   * To keep the header positioning:sticky inside cdk viewport
   */
  get headerTop() {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }

    const offset = this.viewPort['_renderedContentOffset'];

    return `-${offset}px`;
  }

  trackByFn(index: number) {
    return index;
  }

  getAge(user: UserCritical) {
    const eventDate = user.collectionDate
      ? new Date(user.collectionDate)
      : undefined;

    if (eventDate) {
      return UserUtil.getAgeAtEventDate({
        user,
        eventDate: eventDate
      });
    }

    return UserUtil.getAge(user);
  }
}
