import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError, ApiErrorListHttpParam, getId } from '@common';
import { Observable } from 'rxjs';
import { QueryList, QueryListService } from '../util/query-list.service';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorHttpService {
  constructor(private http: HttpClient, private queryList: QueryListService) {}

  public list(params: {
    query: QueryList<ApiErrorListHttpParam>;
    start?: string;
    end?: string;
  }): Observable<{ apiErrors: ApiError[]; hasNext: boolean }> {
    const { query, start, end } = params;

    let queryList = this.queryList.build(query);

    if (start) {
      queryList = queryList.append('start', start);
    }

    if (end) {
      queryList = queryList.append('end', end);
    }

    return this.http.get<{ apiErrors: ApiError[]; hasNext: boolean }>(
      'api/v1/admin/errors',
      {
        params: queryList
      }
    );
  }

  public get(params: {
    id: string;
    loadUser?: boolean;
    loadCompany?: boolean;
    loadResult?: boolean;
  }): Observable<ApiError> {
    const { id, loadUser, loadCompany, loadResult } = params;

    return this.http.get<ApiError>(`api/v1/admin/errors/${id}`, {
      params: new HttpParams()
        .append('user', '' + !!loadUser)
        .append('company', '' + !!loadCompany)
        .append('loadResult', '' + !!loadResult)
    });
  }

  public update(apiError: ApiError): Observable<ApiError> {
    return this.http.put<ApiError>(
      `api/v1/admin/errors/${getId(apiError)}`,
      apiError
    );
  }
}
