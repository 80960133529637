import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CLIENT_ADMIN_CORE_INJECTION_TOKEN } from '@ehs-ngrx/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CompanyNgrxProviderDirective } from './company-ngrx-provider.directive';
import { CompanyNgrxEffects } from './company-ngrx.effects';
import { companyReducer, COMPANY_NGRX_STORE_KEY } from './company-ngrx.state';

@NgModule({
  declarations: [CompanyNgrxProviderDirective],
  imports: [
    CommonModule,
    StoreModule.forFeature(COMPANY_NGRX_STORE_KEY, companyReducer),
    EffectsModule.forFeature([CompanyNgrxEffects]),

    MatSnackBarModule
  ],
  exports: [CompanyNgrxProviderDirective]
})
export class CompanyNgrxModule {
  public static forFeature(config?: {
    clientAdmin: boolean;
  }): ModuleWithProviders<CompanyNgrxModule> {
    return {
      ngModule: CompanyNgrxModule,
      providers: [
        {
          provide: CLIENT_ADMIN_CORE_INJECTION_TOKEN,
          useValue: config?.clientAdmin
        }
      ]
    };
  }
}
