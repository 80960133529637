import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * This service holds the "state" of the http loading. It isn't located in ngrx
 * to prevent debugging spam.
 */
@Injectable({
  providedIn: 'root'
})
export class HttpLoadingStateService {
  // tslint:disable-next-line: variable-name
  private _loading = new BehaviorSubject<boolean>(false);

  public setLoading(loading: boolean) {
    this._loading.next(!!loading);
  }

  public loading$() {
    return this._loading.pipe(distinctUntilChanged());
  }
}
