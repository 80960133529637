import { ObjectId } from 'mongodb';
import { Company } from './company';
import { DbDocument } from './db-document';
import { ServiceLabType } from './event-service/service-lab-type';
import { ServiceType } from './event-service/service-type';
import { Genders, User } from './user';
import { UserTest } from './user-test/user-test';
import { UserRegistrationId } from './user-registration/base-user-registration';
import { UserRegistration } from './user-registration';
import { EventService } from './event-service/event-service';
import { AdminNote } from './admin-note';

export enum UserPaymentStatus {
  OPEN = 'open',
  PENDING = 'pending',
  CLOSED = 'closed',
  CANCELED = 'canceled'
}

export interface PaymentTestCost {
  /**
   * The corresponding user-test that was registered
   */
  userTest: string | ObjectId | UserTest;

  /**
   * Cost of the Test
   */
  cost?: number;
}

export interface UserPaymentFilters
  extends Partial<
    Pick<UserPayment, 'firstName' | 'lastName' | 'birthDay' | 'createdAt'>
  > {
  company?: Company | string;
  status?: UserPaymentStatus[];
}

export interface UserPaymentSearchForm extends UserPaymentFilters {
  pageNumber: number;
}

/**
 * A critical result is a reference of a user's result, except used
 * for admin only features.
 */
export interface UserPayment extends DbDocument {
  /**
   * The company this result belongs to
   */
  company: string | ObjectId | Company;

  /**
   * The user taken from the user result.
   */
  user: string | ObjectId | User;

  /**
   * The user registration tied to this payment
   */
  userRegistration: UserRegistrationId | ObjectId | UserRegistration;

  /**
   * The event service that the user registered for
   */
  eventService: string | ObjectId | EventService;

  /**
   * The type of the user-registration for the given event.
   * IE onsite/offsite. Taken from the user-registration.
   * Note: Now we will only support offsite, but we might need to add support for onsite in the future.
   */
  serviceType: ServiceType.OFFSITE;

  /**
   * The status of the call for this record.
   */
  status: UserPaymentStatus;

  /**
   * The list of tests ordered & their costs
   */
  testsCost: PaymentTestCost[];

  /**
   * The total cost the user has to pay for optional tests
   * & additional services
   */
  totalCost: number;

  /**
   * The type of lab this test goes for. Since we are only dealing with Offsite
   * for now, we can only have QUEST or Labcorp as the Labs
   */
  labType: ServiceLabType.QUEST | ServiceLabType.LAB_CORP;

  /**
   * List of notes admins can enter, and update over time
   */
  notes: AdminNote[];

  /**
   * First name of the user and
   * taken from the user's profile as-is at the time of result creation.
   */
  firstName: string;

  /**
   * Last name of the user and
   * taken from the user's profile as-is at the time of result creation.
   */
  lastName: string;

  /**
   * Gender of the user [Male | Female]
   */
  gender: Genders;

  /**
   * User's birth-day, saved as a string, and
   * taken from the user's profile as-is at the time of result creation.
   *
   * Saved as a date-string, and carries the
   */
  birthDay: string;

  /**
   * User's Phone number. To contact him
   */
  phone?: string;

  /**
   * User's email
   */
  email?: string;

  /**
   * Name of the company,
   * loaded up to be saved as-is at the time of result creation.
   */
  companyName: string;
}
