import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Company,
  PhoneUtil,
  ProviderHealthService,
  User,
  ProviderHealthUserRegistration
} from '@common';
import { ProviderHealthPdfImages } from '@provider-health-pdf';
import { combineLatest, forkJoin, from, Observable } from 'rxjs';
import { map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EhsProviderReqService {
  public images$: Observable<ProviderHealthPdfImages>;

  constructor(private http: HttpClient) {
    this.images$ = this.getImages$();
  }

  private getImages$(): Observable<ProviderHealthPdfImages> {
    const getPath = (filename: string) => `assets/img/${filename}`;

    return forkJoin(
      // Load all images
      [
        'ehs_logo.png',
        'ehs-logo-white.png',
        'doctors-img.png',
        'calendar-search-icon.png'
      ].map((img) => this.http.get(getPath(img), { responseType: 'blob' }))
    ).pipe(
      // Convert every image to base64
      mergeMap((blobs) =>
        forkJoin(
          blobs.map((blob) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            return new Promise<string>(
              (resolve) =>
                (reader.onloadend = () => resolve(reader.result as string))
            );
          })
        )
      ),
      map(
        ([logo, whiteLogo, doctors, calendarIcon]) =>
          ({
            logo,
            whiteLogo,
            doctors,
            calendarIcon
          } as ProviderHealthPdfImages)
      ),
      take(1),
      // Only execute this 1 time, the first time.
      shareReplay(1)
    );
  }

  public generate(params: {
    user: User;
    company: Company;
    providerHealth: ProviderHealthService;
    providerHealthRegistration: ProviderHealthUserRegistration;
  }): Observable<Buffer> {
    const { user, company, providerHealth, providerHealthRegistration } =
      params;

    return combineLatest([
      from(import('@provider-health-pdf')),
      this.images$,
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ]).pipe(
      take(1),
      tap(
        ([, , { default: pdfMake }, { default: pdfFonts }]) =>
          (pdfMake.vfs = pdfFonts.pdfMake.vfs)
      ),
      mergeMap(([{ getProviderHealthPdf }, images, { default: pdfMake }]) =>
        getProviderHealthPdf({
          images,
          company,
          providerHealth,
          user,
          providerHealthRegistration
        }).then(
          (pdf) =>
            new Promise<Buffer>((resolve) =>
              pdfMake.createPdf(pdf).getBuffer(resolve)
            )
        )
      )
    );
  }

  public generateAndDownload(params: {
    user: User;
    company: Company;
    providerHealth: ProviderHealthService;
    providerHealthRegistration: ProviderHealthUserRegistration;
  }): void {
    const { user, company, providerHealth, providerHealthRegistration } =
      params;

    combineLatest([
      from(import('@provider-health-pdf')),
      this.images$,
      // Pdf libraries
      from(import('pdf-lib')),
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ])
      .pipe(
        take(1),
        mergeMap(
          ([
            { getProviderHealthPdf },
            images,
            { PDFDocument },
            { default: pdfMake },
            { default: pdfFonts }
          ]) =>
            from(
              new Promise<Buffer>((resolve) => {
                pdfMake.vfs = pdfFonts.pdfMake.vfs;

                getProviderHealthPdf({
                  images,
                  company,
                  providerHealth,
                  user,
                  providerHealthRegistration
                }).then((pdf) => pdfMake.createPdf(pdf).getBuffer(resolve));
              })
            ).pipe(
              mergeMap(async (buffer) => PDFDocument.load(buffer)),
              mergeMap((pdf) => pdf.saveAsBase64())
            )
        )
      )
      .subscribe((base64) => {
        if (PhoneUtil.isSafari()) {
          PhoneUtil.openBase64Pdf(base64);

          return;
        }

        const downloadLinkElement: HTMLAnchorElement =
          document.createElement('a');

        downloadLinkElement.href = `data:application/pdf;base64,${base64}`;
        downloadLinkElement.download = `provider-${new Date().getTime()}.pdf`;
        downloadLinkElement.click();
      });
  }
}
