import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AdminGwNgrxModule } from '@ehs-ngrx/admin-gw-ngrx';
import { EhsGwappsCompanyModule } from '../../components/ehs-gwapps-company/ehs-gwapps-company.module';
import { EhsMoveUserCompanyDialogComponent } from './ehs-move-user-company-dialog.component';
import { EhsMoveUserCompanyDialogService } from './ehs-move-user-company-dialog.service';

@NgModule({
  declarations: [EhsMoveUserCompanyDialogComponent],
  imports: [
    EhsGwappsCompanyModule,
    AdminGwNgrxModule,

    // Angular
    CommonModule,
    FormsModule,
    RouterModule,

    // Material
    FlexLayoutModule,
    MatDialogModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule
  ],
  exports: [EhsMoveUserCompanyDialogComponent],
  providers: [EhsMoveUserCompanyDialogService]
})
export class EhsMoveUserCompanyDialogModule {}
