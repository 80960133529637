/**
 * The vaccine action type, currently always using to A by
 * default
 * RXA 21
 *
 * Starts to A
 */
export enum VaccineAction {
  ADD = 'a',
  DELETE = 'd',
  UPDATE = 'u'
}
