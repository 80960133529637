import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { PDFDocument } from 'pdf-lib';

export class PhoneUtil {
  private static phoneUtil = PhoneNumberUtil.getInstance();
  /**
   * The list of supported regions we are to support for phone numbers
   */
  public static SUPPORTED_REGIONS = ['US', 'PR', 'VI'];
  /**
   * Returns if the given value is a valid US phone number
   */
  public static isValidPhone(
    value: string,
    options?: {
      /**
       * If we are to allow something to start with 1.
       * Useful in some situations
       */
      mustStartWithOne?: boolean;
      /**
       * If we are to allow empty, this will let the `required` field be applied
       */
      allowEmpty?: boolean;
    }
  ): boolean {
    try {
      const mustStartWithOne = options?.mustStartWithOne;
      const allowEmpty = options?.allowEmpty;

      if (allowEmpty && !value) {
        // If empty and value is blank then its valid
        return true;
      }

      if (
        mustStartWithOne &&
        !value.trim().startsWith('1') &&
        !value.startsWith('+1')
      ) {
        return false;
      }

      return !!PhoneUtil.SUPPORTED_REGIONS.find((region) =>
        this.phoneUtil.isValidNumberForRegion(
          this.phoneUtil.parseAndKeepRawInput(value, region),
          region
        )
      );
    } catch (err) {
      return false;
    }
  }

  public static getAreaCode(value: string): string {
    const nationalNumber = this.phoneUtil.format(
      // **Note** this automatically returns the area code even for
      // Puerto Rico numbers
      this.phoneUtil.parseAndKeepRawInput(value, 'US'),
      PhoneNumberFormat.NATIONAL
    );

    return nationalNumber.match(/\d+/g)[0];
  }

  public static getLocalNumber(value: string): string {
    const nationalNumber = this.phoneUtil.format(
      // **Note** this automatically returns the local-number even
      // for Puerto Rico numbers
      this.phoneUtil.parseAndKeepRawInput(value, 'US'),
      PhoneNumberFormat.NATIONAL
    );
    const [, first, second] = nationalNumber.match(/\d+/g);

    return `${first || ''}${second || ''}`;
  }

  /**
   *  Checks if current user is using safari browser
   * */
  public static isSafari(): boolean {
    return (
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1
    );
  }

  /**
   * On safari mobile browser, users can pinch to zoom out creating wide margins
   * and also drag our content around the page. This prevents resizing that would
   * affect the scaling.
   */
  public static setAntiZoomEventListener() {
    // Works more consistently on pwa than mobile browser
    if (PhoneUtil.isSafari()) {
      ['touchmove', 'touchend'].forEach((type) =>
        window.addEventListener(
          type,
          (event) => {
            if (event['scale'] && event['scale'] !== 1) {
              event.preventDefault();
            }
          },
          { passive: false }
        )
      );
    }
  }

  /**
   * Opens base64 pdfs in a new window. Primarly a workaround
   * for mobile Safari browser which fails to download pdfs
   * through creating download link elements.
   * @param base64Pdf A pdf in base64 string form
   */
  public static openBase64Pdf(base64Pdf: string | Uint8Array | ArrayBuffer) {
    PDFDocument.load(base64Pdf).then(async (pdf) => {
      const pdfBytes = await pdf.save();

      const blob = new Blob([pdfBytes], { type: 'application/pdf' });

      const blobURL = URL.createObjectURL(blob);

      window.open(blobURL);
    });
  }

  /**
   * Format given number to E.164
   * @param phoneNumber
   * @returns phone number in US E.164 format
   */
  public static getFormattedNumber(phoneNumber: string) {
    if (!this.isValidPhone(phoneNumber)) {
      return '';
    }

    return this.phoneUtil.format(
      this.phoneUtil.parse(phoneNumber, 'US'),
      PhoneNumberFormat.E164
    );
  }
}
