import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { EhsSideNavElementComponent } from './ehs-side-nav-element/ehs-side-nav-element.component';
import { EhsSideNavComponent } from './ehs-side-nav.component';
import { EhsAnimationsModule } from '../../ehs-animations/ehs-animations.module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [EhsSideNavComponent, EhsSideNavElementComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    // Animations
    EhsAnimationsModule,
    MatDividerModule
  ],
  exports: [EhsSideNavComponent, EhsSideNavElementComponent]
})
export class EhsSideNavModule {}
