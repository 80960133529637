import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonResponse, GcfMarqueeExportRequest } from '@common';

@Injectable({
  providedIn: 'root'
})
export class AdminMarqueeHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Syncs **all** of the marquee data directly.
   */
  public sync(req: Omit<GcfMarqueeExportRequest, 'email'>) {
    return this.http.post<CommonResponse>('api/v1/admin/marquee/sync', req);
  }
}
