import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  EventLocation,
  EventLocationCustomTime,
  EventLocationCustomTimeFlu,
  EventLocationCustomTimeScreening,
  EventLocationRegistrationTimesByType,
  isEventLocationCustomTimeFlu,
  isEventLocationCustomTimeScreening,
  TimeUtil,
  toMap
} from '@common';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject
} from 'rxjs';
import { debounceTime, filter, map, shareReplay, take } from 'rxjs/operators';

/**
 * Internal type used to display the rows in the table.
 */
interface EventTimeTableTimeSlot {
  /**
   * The time we are displaying, this is essentially
   * the "slot" of the time.
   */
  time: string;
  /**
   * The custom time for this slot, if there is one.
   */
  customTime?: EventLocationCustomTime;
  /**
   * The data for this current time slot for who is already
   * registered.
   */
  registered?: {
    screening: number;
    flu: number;
  };
}

@Component({
  selector: 'ehs-event-time-table',
  templateUrl: './ehs-event-time-table.component.html',
  styleUrls: ['./ehs-event-time-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsEventTimeTableComponent {
  /**
   * Passed to disable actions, and show a loading spinner in the upper right in the actions column.
   *
   * **note** this column isn't shown when things are in readonly mode, and thus
   * the loading spinner wont show either.
   */
  @Input() loading?: boolean;

  /**
   * The event location we will display within the table.
   * This is the primary "base" times, but doesn't include the stats.
   */
  @Input() set eventLocation(eventLocation: EventLocation | null) {
    if (eventLocation) {
      this.eventLocation$.next(eventLocation);
    }
  }

  public get eventLocation() {
    return this.eventLocation$.value;
  }

  private eventLocation$ = new BehaviorSubject<EventLocation | undefined>(
    undefined
  );

  /**
   * The times for the event-location, used to display the amount of
   * users registered for each type.
   */
  @Input()
  set eventLocationRegistrationTimes(
    eventLocationRegistrationTimes: EventLocationRegistrationTimesByType | null
  ) {
    if (eventLocationRegistrationTimes) {
      this.eventLocationRegistrationTimes$.next(eventLocationRegistrationTimes);
    }
  }

  private eventLocationRegistrationTimes$ =
    new ReplaySubject<EventLocationRegistrationTimesByType>(1);

  /**
   * The time the user is editing.
   *
   * **note** this is separate from `editedCustomTime` which is the internal
   * form state and managed separately.
   */
  @Input() set editingTime(editingTime: string) {
    this._editingTime = editingTime;

    if (this._editingTime) {
      // If this is set, then set the defaults for the customTime data
      this.timeSlots$
        .pipe(
          // I think this is required to prevent race-conditions
          debounceTime(0),
          map((timeSlots) =>
            timeSlots.find(
              (existingTimeSlots) =>
                existingTimeSlots.time === this._editingTime
            )
          ),
          take(1),
          filter((_) => !!_)
        )
        .subscribe(
          (timeSlot) => (this.customTime = this.getCustomTimeDefaults(timeSlot))
        );
    }
  }

  public get editingTime() {
    return this._editingTime;
  }

  private _editingTime: string | undefined;
  /**
   * Event emitted when the user enters "edit mode" for a given custom time.
   * This is emitted when editing **or** creating a new entry.
   *
   * Emits the military time of what is being edited.
   *
   * Will emit undefined when done editing.
   */
  @Output() editingTimeChange = new EventEmitter<string | undefined>();

  /**
   * Flag to hide the "actions" column
   */
  @Input() set readonly(readonly: boolean) {
    this.readonly$.next(readonly);
  }

  // **Note** this isn't a replay subject as we want to default to false as
  // the input isn't always given.
  public readonly$ = new BehaviorSubject<boolean>(false);

  /**
   * Event emitted when the user removes an existing custom time.
   */
  @Output() removeCustomTime = new EventEmitter<
    Pick<EventLocationCustomTime, 'time'>
  >();

  /**
   * Event emitted when the user adds or updates a custom time.
   * Internally this component doesn't update anything, its up to the
   * parent component to update the event-location properly.
   */
  @Output() updatedCustomTime = new EventEmitter<EventLocationCustomTime>();

  /**
   * Columns are computed based on what the event-location supports,
   * and the readonly flag
   */
  public columns$ = combineLatest([this.eventLocation$, this.readonly$]).pipe(
    map(([eventLocation, readonly]) => {
      const columns: string[] = ['time'];

      if (eventLocation?.alloted) {
        // If there is alloted, then screening is supported
        columns.push('screeningRate', 'registeredScreening');
      }

      if (eventLocation?.fluAlloted) {
        columns.push('fluRate', 'registeredFlu');
      }

      if (!readonly) {
        columns.push('actions');
      }

      return columns;
    })
  );

  /**
   * The list of time-increments for the current event-location.
   *
   * This serves as the basis of what is shown in the table.
   */
  public eventLocationTimeIncrements$ = this.eventLocation$.pipe(
    filter((_) => !!_),
    map((eventLocation) => TimeUtil.getIncrements(eventLocation)),
    // Cache and return the last call
    shareReplay({
      refCount: true,
      bufferSize: 1
    })
  );

  /**
   * Returns a map of the registration-times for each time with
   * specific numbers for screening and flu
   */
  public registrationTimesMap$ = combineLatest([
    this.eventLocationTimeIncrements$,
    this.eventLocationRegistrationTimes$
  ]).pipe(
    map(([timeIncrements, registrationTimes]) => {
      const fluTimes =
        registrationTimes?.flu && registrationTimes?.flu !== 'not-supported'
          ? registrationTimes?.flu.reduce(
              (acc, { time, taken }) => ({
                ...acc,
                [time]: taken
              }),
              {} as Record<string, number>
            )
          : {};

      const screeningTimes =
        registrationTimes?.screening &&
        registrationTimes?.screening !== 'not-supported'
          ? registrationTimes?.screening.reduce(
              (acc, { time, taken }) => ({
                ...acc,
                [time]: taken
              }),
              {} as Record<string, number>
            )
          : {};

      return timeIncrements.reduce(
        (acc, time) => ({
          ...acc,
          [time]: {
            screening: screeningTimes[time] || 0,
            flu: fluTimes[time] || 0
          }
        }),
        {}
      );
    }),
    // Cache and return the last call
    shareReplay({
      refCount: true,
      bufferSize: 1
    })
  );

  /**
   * Observable of the time-slots to display within the table.
   *
   * This is essentially calculated from all the other data provided via
   * inputs into this component.
   */
  public timeSlots$: Observable<Array<EventTimeTableTimeSlot>> = combineLatest([
    this.eventLocation$,
    this.eventLocationTimeIncrements$,
    this.registrationTimesMap$
  ]).pipe(
    map(([eventLocation, timeIncrements, registrationTimesMap]) => {
      if (!eventLocation) {
        return [];
      }

      const customTimesMap = toMap({
        entities: eventLocation.customTimes || [],
        key: 'time'
      });

      return timeIncrements.map((time) => ({
        time,
        registered: registrationTimesMap[time],
        customTime: customTimesMap[time]
      }));
    })
  );

  /**
   * This object is used as a "temp" local object state
   * for when the user enters "edit" mode for a given time-slot.
   */
  public customTime?: Partial<EventLocationCustomTime> = {};

  /**
   * Returns the defaults for a custom-time
   */
  private getCustomTimeDefaults(timeSlot: EventTimeTableTimeSlot) {
    // Set the default values so the UI "starts" in a similar sensible
    // state as before
    const flu =
      typeof (timeSlot.customTime as EventLocationCustomTimeFlu)?.flu ===
      'number'
        ? (timeSlot.customTime as EventLocationCustomTimeFlu)?.flu
        : this.eventLocation?.fluAlloted || 0;

    const screening =
      typeof (timeSlot.customTime as EventLocationCustomTimeScreening)
        ?.screening === 'number'
        ? (timeSlot.customTime as EventLocationCustomTimeScreening)?.screening
        : this.eventLocation?.alloted || 0;

    return {
      flu,
      screening,
      time: timeSlot.time
    };
  }

  /**
   * Event handler when the user edits a given time-slot.
   * This turns the time-slot into "edit" mode, and sets the
   * current values for custom-times to be defaulted to
   * the default times
   */
  public onEditingCustomTime(timeSlot: EventTimeTableTimeSlot) {
    // Emit to let the parent know the user is editing this time
    this.editingTimeChange.emit(timeSlot.time);
    // Set the current time to be edited to update the template, so the table
    // enters "edit mode"
    this.editingTime = timeSlot.time;
    this.customTime = this.getCustomTimeDefaults(timeSlot);
  }

  /**
   * Event handler when the user clicks on the green "check-mark" button
   * when they are done editing a custom time.
   */
  public onStopEditingCustomTime() {
    const screeningChanged =
      isEventLocationCustomTimeScreening(this.customTime) &&
      this.customTime.screening !== this.eventLocation.alloted;
    const fluChanged =
      isEventLocationCustomTimeFlu(this.customTime) &&
      this.customTime.flu !== this.eventLocation.fluAlloted;

    if (screeningChanged || fluChanged) {
      // If neither changed then don't emit this isn't an update.
      this.updatedCustomTime.emit(this.customTime as EventLocationCustomTime); // Lazy cast, sorry.
    }

    this.editingTime = undefined;
    this.customTime = undefined;
  }

  /**
   * Event handler when the user removes a given time-slot.
   */
  public onRemoveCustomTime(timeSlot: EventTimeTableTimeSlot) {
    this.removeCustomTime.emit({
      time: timeSlot.time
    });
  }

  /**
   * Returns if the given time-slot delete button is disabled.
   * Follows the same logic as other "input" actions in the component,
   * but also considers if removing the data sane.
   */
  public removeDisabled(timeSlot: EventTimeTableTimeSlot) {
    if (this.loading || this.editingTime || !this.eventLocation) {
      return true;
    }

    // Otherwise, verify that deleting this custom-time wont cut off
    // pre-existing user-registrations
    if (this.eventLocation.alloted) {
      if (timeSlot.registered.screening > this.eventLocation.alloted) {
        return true;
      }
    }

    if (this.eventLocation.fluAlloted) {
      if (timeSlot.registered.flu > this.eventLocation.fluAlloted) {
        return true;
      }
    }

    // This state technically isn't possible, but just in case
    // allow the admin to delete this custom-time, even though it
    // couldn't actually override anything.
    return false;
  }

  /**
   * **Note** this is the TimeAmPmPipe, but it hasn't been moved
   * over to ehs-ui yet.
   */
  public toAmPm(time: string): string {
    return TimeUtil.timeToAmPm(time);
  }

  /**
   * Returns the screening rate for this time. Defaults to the alloted
   * or shows the custom time.
   */
  public getScreeningRate(timeSlot: EventTimeTableTimeSlot): number {
    if (
      typeof (timeSlot.customTime as EventLocationCustomTimeScreening)
        ?.screening === 'number'
    ) {
      return (timeSlot.customTime as EventLocationCustomTimeScreening)
        .screening;
    }

    return this.eventLocation.alloted || 0;
  }

  /**
   * Returns the custom styling for the time-slot
   */
  public getScreeningRateStyle(timeSlot: EventTimeTableTimeSlot): unknown {
    if (
      typeof (timeSlot.customTime as EventLocationCustomTimeScreening)
        ?.screening === 'number'
    ) {
      return { color: 'green' };
    }

    return {};
  }

  /**
   * Returns the screening rate for this time. Defaults to the fluAlloted
   * or shows the custom time.
   */
  public getFluRate(timeSlot: EventTimeTableTimeSlot): number {
    if (
      typeof (timeSlot.customTime as EventLocationCustomTimeFlu)?.flu ===
      'number'
    ) {
      return (timeSlot.customTime as EventLocationCustomTimeFlu).flu;
    }

    return this.eventLocation.fluAlloted || 0;
  }

  /**
   * Returns the custom styling for the time-slot
   */
  public getFluRateStyle(timeSlot: EventTimeTableTimeSlot): unknown {
    if (
      typeof (timeSlot.customTime as EventLocationCustomTimeFlu)?.flu ===
      'number'
    ) {
      return { color: 'green' };
    }

    return {};
  }
}
