import { GwApiUtilsFormMappings } from '@common';
import { createReducer, on } from '@ngrx/store';
import { adminGwNgrxActions } from './admin-gw-ngrx.actions';

export const ADMIN_GW_NGRX_STORE_KEY = 'adminGwNgrx';

export interface ParentAdminGwNgrxState {
  [ADMIN_GW_NGRX_STORE_KEY]: AdminGwNgrxState;
}
/**
 * Note this state is a simplified version of the
 * AdminGwState in the ng-portal app.
 */
export interface AdminGwNgrxState {
  /**
   * What properties are loading/getting synced.
   *
   * TODO: change to the names of the properties that
   * can be synced in gwapps
   */
  loading?: Array<keyof GwApiUtilsFormMappings>;
}

export const adminGwNgrxReducer = createReducer<AdminGwNgrxState>(
  {},
  on(adminGwNgrxActions.sync.req, (state, { payload: { syncResource } }) => ({
    ...state,
    loading: state.loading ? [...state.loading, syncResource] : [syncResource]
  })),
  on(
    adminGwNgrxActions.sync.success,
    (state, { payload: { syncResource } }) => ({
      ...state,
      loading: (state.loading || []).filter(
        (loadingResource) => loadingResource !== syncResource
      )
    })
  ),
  on(
    adminGwNgrxActions.sync.failed,
    (state, { payload: { syncResource } }) => ({
      ...state,
      loading: (state.loading || []).filter(
        (loadingResource) => loadingResource !== syncResource
      )
    })
  ),

  on(
    adminGwNgrxActions.validateData.req,
    (state, { payload: { resource } }) => ({
      ...state,
      loading: state.loading ? [...state.loading, resource] : [resource]
    })
  ),
  on(
    adminGwNgrxActions.validateData.success,
    (state, { payload: { resource } }) => ({
      ...state,
      loading: (state.loading || []).filter(
        (loadingResource) => loadingResource !== resource
      )
    })
  ),
  on(
    adminGwNgrxActions.validateData.failed,
    (state, { payload: { resource } }) => ({
      ...state,
      loading: (state.loading || []).filter(
        (loadingResource) => loadingResource !== resource
      )
    })
  )
);
