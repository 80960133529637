<h1 mat-dialog-title>{{data.title ? data.title : 'Raw Data'}}</h1>
<mat-dialog-content style="background-color: #fafafa;">
  <pre>{{data.json | json}}</pre>
</mat-dialog-content>
<mat-dialog-actions align="end"
                    fxLayoutGap="16px">
  <button type="button"
          mat-button
          [mat-dialog-close]="false">
    OK
  </button>
</mat-dialog-actions>
