import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateWaiverFormRequest, Waiver } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WaiverHttpService {
  private baseUrl = 'api/v1/admin/waivers';
  constructor(private http: HttpClient) {}

  public list(params: { eventService: string; pageNumber: number }) {
    return this.http.get<{ waivers: Waiver[]; hasNext: boolean }>(
      `${this.baseUrl}/list`,
      {
        params: Object.entries(params).reduce(
          (_params, [key, value]) =>
            value ? _params.append(key, '' + value) : _params,
          new HttpParams()
        )
      }
    );
  }

  public create(params: CreateWaiverFormRequest) {
    return this.http.post<Waiver>(`${this.baseUrl}/create`, params);
  }

  public delete(params: {
    waiverId: string;
  }): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      `${this.baseUrl}/${params.waiverId}/delete`
    );
  }

  /**
   * Returns a signed url to download a Lifepoint Lab Requisition PDF
   */
  public getWaiverPdfSignedUrl(params: {
    /**
     * The id of the corresponding waiver
     */
    id: string;
  }): Observable<{ url?: string; contentType?: string }> {
    const { id } = params;

    return this.http.get<{ url: string; contentType?: string }>(
      `${this.baseUrl}/${id}/download`
    );
  }
}
