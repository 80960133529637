import { EhsNavData } from '../models/ehs-nav-data';

/**
 * If the given ehs-nav-data is to be put on the "start" side
 */
export const isStartEhsNavData = (data: EhsNavData): boolean =>
  data && (!data.side || data.side === 'start');

/**
 * If the given ehs-nav-data is to be put on the "end" side
 */
export const isEndEhsNavData = (data: EhsNavData): boolean =>
  data && data.side === 'end';
