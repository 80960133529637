import { OnsiteRegistrationType } from './onsite-registration-type';

/**
 * NOTE this type is similar to the user-registration type, but purposely
 * made different to differentiate.
 *
 * **do not save this to the database, it should only be used as an intermediate type**
 */
export enum OnsiteRegistrationComboType {
  SCREENING_ONLY = 'screening',
  FLU_VACCINATION_ONLY = 'flu_vaccination_only',
  COVID_VACCINATION_ONLY = 'covid_vaccination_only',
  SCREENING_AND_FLU = 'screening_and_flu',
  SCREENING_AND_COVID = 'screening_and_covid'
}

/**
 * Mapping function that maps combo-types to arrays of onsite-registration-types.
 * **note** if you want to go the other way, @see {UserRegistrationUtil.getOnsiteRegistrationComboTypesFromReg}
 *
 */
export const mapOnsiteRegistrationComboToType = (
  onsiteRegistrationComboType: OnsiteRegistrationComboType
): Array<OnsiteRegistrationType> =>
  ({
    [OnsiteRegistrationComboType.SCREENING_ONLY]: [
      OnsiteRegistrationType.SCREENING
    ],
    [OnsiteRegistrationComboType.FLU_VACCINATION_ONLY]: [
      OnsiteRegistrationType.FLU_VACCINE
    ],
    [OnsiteRegistrationComboType.COVID_VACCINATION_ONLY]: [
      OnsiteRegistrationType.COVID_VACCINE
    ],
    [OnsiteRegistrationComboType.SCREENING_AND_FLU]: [
      OnsiteRegistrationType.SCREENING,
      OnsiteRegistrationType.FLU_VACCINE
    ],
    [OnsiteRegistrationComboType.SCREENING_AND_COVID]: [
      OnsiteRegistrationType.SCREENING,
      OnsiteRegistrationType.COVID_VACCINE
    ]
  }[onsiteRegistrationComboType]);
