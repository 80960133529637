/**
 * Test categories are a list of values used for
 * user results and user tests. These are
 * also used to display values on the dashboard
 * under different categories.
 */
export enum TestCategories {
  // Health Risk Categories
  METABOLIC_SYNDROME = 'metabolicSyndrome',
  // Result Categories
  ALLERGY_PANEL = 'allergyPanel',
  BIOMETRIC = 'biometric',
  BLOOD_TYPE_GROUP = 'bloodTypeGroup',
  CBC = 'cbc',
  DIABETES = 'diabetes',
  HEART = 'heart',
  INFLAMMATORY_JOINT = 'inflammatoryJoint',
  LAB_MESSAGE = 'labMessage',
  LIPIDS = 'lipids',
  LIPIDS_WITH_GLUCOSE = 'lipidsWithGlucose',
  METABOLIC = 'metabolic',
  MINERALS = 'minerals',
  PANEL = 'panel',
  SPECIAL = 'special',
  THYROID = 'thyroid'
}

/**
 * Mapping of readable version of test categories.
 */
export const readableTestCategories = {
  [TestCategories.METABOLIC]: 'Metabolic Tests',
  [TestCategories.BLOOD_TYPE_GROUP]: 'Blood Type Group Tests',
  [TestCategories.THYROID]: 'Thyroid Tests',
  [TestCategories.ALLERGY_PANEL]: 'Allergy Panel Tests',
  [TestCategories.INFLAMMATORY_JOINT]: 'Inflammatory Joint Tests',
  [TestCategories.CBC]: 'Complete Blood Count Tests',
  [TestCategories.LIPIDS]: 'Lipids Tests',
  [TestCategories.BIOMETRIC]: 'Biometric Measurements',
  [TestCategories.HEART]: 'Heart Tests',
  [TestCategories.SPECIAL]: 'Special Tests',
  [TestCategories.PANEL]: 'Panel Tests',
  [TestCategories.DIABETES]: 'Diabetes Tests',
  [TestCategories.LIPIDS_WITH_GLUCOSE]: 'Lipids With Glucose Tests',
  [TestCategories.LAB_MESSAGE]: 'Lab Message Tests',
  [TestCategories.MINERALS]: 'Mineral Tests'
};

/**
 * Utility function that attempts to return a string to return a test category enum value.
 * If nothing matches, we return undefined
 */
export const getTestCategory = (
  rawTestCategory: string
): TestCategories | undefined => {
  if (!rawTestCategory) {
    return undefined;
  }
  const baseValue = rawTestCategory.trim().toLowerCase().replace(/\s|/g, '');
  const testCategories = Object.values(TestCategories);
  const match = testCategories.findIndex(
    (testCategory) => testCategory.toLowerCase() === baseValue
  );

  if (match === -1) {
    return undefined;
  }

  return testCategories[match];
};
