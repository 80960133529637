import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { EhsPageContainerComponent } from './ehs-page-container.component';

@NgModule({
  declarations: [EhsPageContainerComponent],
  imports: [
    CommonModule,

    // Angular Material
    FlexLayoutModule
  ],
  exports: [EhsPageContainerComponent]
})
export class EhsPageContainerModule {}
