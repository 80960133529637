import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventLocation } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminEventLocationHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Returns the list of event-locations for the given company
   * @param companyId the company to get it for
   */
  public listEventLocations(companyId: string): Observable<EventLocation[]> {
    return this.http.get<EventLocation[]>('api/v1/admin/event/list', {
      params: new HttpParams().append('company', companyId)
    });
  }

  public get(id: string): Observable<EventLocation> {
    return this.http.get<EventLocation>(`api/v1/admin/event/${id}`);
  }
}
