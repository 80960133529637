import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { EhsUserResultListComponent } from './ehs-user-result-list.component';

@NgModule({
  declarations: [EhsUserResultListComponent],
  imports: [
    CommonModule,
    RouterModule,
    // Angular Material
    FlexLayoutModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule
  ],
  exports: [EhsUserResultListComponent]
})
export class EhsUserResultListModule {}
