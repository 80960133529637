import { Injectable } from '@angular/core';
import {
  Company,
  EventLocation,
  EventLocationCustomTime,
  EventService,
  getId,
  SingleEventStats
} from '@common';
import { EntityFacade } from '@ehs-ngrx/common';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { eventLocationNgrxActions } from './event-location-ngrx.actions';
import {
  eventLocationNgrxFeatureSelector,
  eventLocationNgrxSelectors
} from './event-location-ngrx.selectors';
import {
  EventLocationNgrxState,
  ParentEventLocationNgrxState
} from './event-location-ngrx.state';

@Injectable({
  providedIn: 'root'
})
export class EventLocationNgrxFacade extends EntityFacade<
  EventLocation,
  string,
  ParentEventLocationNgrxState,
  EventLocationNgrxState
> {
  /**
   * All recent (+-7 days) Event-Locations
   */
  public recentEvents$ = this.store.pipe(
    select(eventLocationNgrxSelectors.recentEventsSelector)
  );

  /**
   * Event-locations for the current company, relative
   * to the company-route within 14 days.
   */
  public recentForCompany$ = this.store.pipe(
    select(eventLocationNgrxSelectors.recentForCompanySelector)
  );

  public activeForCompany$ = this.store.pipe(
    select(eventLocationNgrxSelectors.activeForCompanySelector)
  );

  /**
   * Event-locations for the current company, relative
   * to the company-route.
   */
  public allForCompany$ = this.store.pipe(
    select(eventLocationNgrxSelectors.allForCompanySelector)
  );

  /**
   * Event-locations for the current event-service, relative
   * to the event-service-id param.
   */
  public recentForServiceParam$ = this.store.pipe(
    select(eventLocationNgrxSelectors.recentForServiceParamSelector)
  );

  public currentByRoute$ = this.store.pipe(
    select(eventLocationNgrxSelectors.currentByRouteParamSelector)
  );

  /**
   * The event-location currently selected
   * - used in Walkin user registration form #3954
   */
  public eventLocation$ = this.store.pipe(
    select(eventLocationNgrxSelectors.eventLocationSelector)
  );

  constructor(store: Store<ParentEventLocationNgrxState>) {
    super(store, eventLocationNgrxFeatureSelector);
  }

  /**
   * Returns the stats for the given event
   */
  public getStats$() {
    return this.store.pipe(select(eventLocationNgrxSelectors.statsSelector));
  }

  /**
   * Returns the stats for all the event-locations for the given-params
   *
   * **Note** this is used often, and then used in the template with a lookup
   * against the ID. Instead migrate to `getSingleEventStats$`
   */
  public getEventLocationStats(params: {
    company?: string | Company;
    eventService?: string | EventService;
  }) {
    const { company, eventService } = params;

    return this.store.dispatch(
      eventLocationNgrxActions.getStats.req({
        company,
        eventService
      })
    );
  }

  /**
   * Returns the single-event stats currently loaded
   * for the given event-location.
   */
  public getSingleEventStats$(
    eventLocation: string | EventLocation
  ): Observable<SingleEventStats> {
    return this.store.pipe(
      select(
        eventLocationNgrxSelectors.entityStatsSelectorFactory(
          getId(eventLocation)
        )
      )
    );
  }

  public get(id: string) {
    this.store.dispatch(eventLocationNgrxActions.get.req({ id }));
  }

  public listByCompany({ company }: { company: string | Company }) {
    this.store.dispatch(
      eventLocationNgrxActions.listByCompany.req({
        company: getId(company)
      })
    );
  }

  // Returns all recent/active event locations for provided eventService
  public listByService({
    company,
    eventService
  }: {
    eventService: string | EventService;
    company: string | Company;
  }) {
    this.store.dispatch(
      eventLocationNgrxActions.listByService.req({
        eventService: getId(eventService),
        company: getId(company)
      })
    );
  }

  /**
   * Releases the event's user-registration, or th entire event.
   */
  public release(params: { eventLocation: string; users?: string[] }) {
    const { eventLocation, users } = params;

    this.store.dispatch(
      eventLocationNgrxActions.release.req({
        eventLocation: getId(eventLocation),
        users
      })
    );
  }

  /**
   * Get all recent event locations
   */
  public getRecent() {
    this.store.dispatch(eventLocationNgrxActions.listRecent.req());
  }

  /**
   * Returns the registration times by type, and "saves" them in the
   * stats objects, as they follow the same 1-to-1 pattern but aren't
   * part of the actual event-location.
   */
  public getRegTimes(params: { eventLocation: string | EventLocation }) {
    this.store.dispatch(
      eventLocationNgrxActions.getRegTimes.req({
        eventLocation: getId(params.eventLocation)
      })
    );
  }

  /**
   * Removes the given custom-time from the event-location
   */
  public removeCustomTime(
    params: Pick<EventLocationCustomTime, 'time'> & {
      eventLocation: string | EventLocation;
    }
  ) {
    const { eventLocation, time } = params;

    this.store.dispatch(
      eventLocationNgrxActions.removeCustomTime({
        eventLocation: getId(eventLocation),
        time
      })
    );
  }

  /**
   * Adds or updates a given custom-time for the given event-location
   */
  public updateCustomTime(
    params: EventLocationCustomTime & {
      eventLocation: string | EventLocation;
    }
  ) {
    this.store.dispatch(
      eventLocationNgrxActions.updateCustomTime({
        ...params,
        eventLocation: getId(params.eventLocation)
      })
    );
  }

  public cancelEvent(eventLocation: EventLocation) {
    this.store.dispatch(
      eventLocationNgrxActions.cancelEvent.req({ eventLocation })
    );
  }

  /** Set Event Location
   * used in walkin user registration form #3954
   */
  public setEventLocation(params: { eventLocation: string | EventLocation }) {
    const { eventLocation } = params;

    this.store.dispatch(
      eventLocationNgrxActions.setEventLocation({
        eventLocation: getId(eventLocation)
      })
    );
  }
}
