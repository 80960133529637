import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { EhsCardLabelModule } from '../ehs-card-label/ehs-card-label.module';
import { EhsCompanyStatsComponent } from './ehs-company-stats.component';

@NgModule({
  declarations: [EhsCompanyStatsComponent],
  imports: [
    CommonModule,
    // Angular Material
    MatButtonModule,
    FlexLayoutModule,
    RouterModule,
    EhsCardLabelModule
  ],
  exports: [EhsCompanyStatsComponent]
})
export class EhsCompanyStatsModule {}
