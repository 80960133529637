import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ForgotUsernameRequest,
  LoginRequest,
  PasswordResetRequest,
  SignUpRequest,
  User
} from '@common';
import { Actions, ofType } from '@ngrx/effects';
import { Action, createSelector, select, Store } from '@ngrx/store';
import { AuthError } from 'firebase/auth';
import { DateTime } from 'luxon';
import { combineLatest, merge, Observable, timer } from 'rxjs';
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { AppState } from '../app-state';
import { authActions } from './auth.actions';
import { authSelectors } from './auth.selectors';
import { TempSignUp } from './auth.state';

export class FirebaseAuthError extends Error {
  constructor(public code: string, public message: string) {
    super(message);
  }
}
@Injectable({
  providedIn: 'root'
})
export class AuthFacade {
  /**
   * The difference in seconds to the next verification email send
   */
  public secondsUntilResendVerifyEmail$ = combineLatest([
    this.store.pipe(
      select((state: AppState) => state.auth.lastSentVerificationEmail)
    ),
    timer(0, 1000)
  ]).pipe(
    map(([dateStr]) => {
      if (!dateStr) {
        return 0;
      }
      const seconds = 60;
      const diff =
        seconds -
        (Math.abs(DateTime.fromISO(dateStr).diffNow('seconds').seconds) || 0);

      if (diff < 0) {
        return 0;
      }

      return Math.ceil(diff);
    })
  );

  /**
   * If the verification email button is disabled.
   * The user can only fire this every 30 seconds.
   */
  public resendVerifyEmailDisabled$ = this.store
    .pipe(
      select(
        createSelector(
          (state: AppState) => state.auth.lastSentVerificationEmail,
          (_) => _
        )
      )
    )
    .pipe(
      switchMap((dateStr) =>
        // **Note** this keeps firing when subscribed, not sure how to stop it!
        timer(0, 1000).pipe(
          map(() => {
            if (!dateStr) {
              // If no dateStr don't do anything.
              return false;
            }

            return (
              Math.abs(DateTime.fromISO(dateStr).diffNow('seconds').seconds) <=
              60
            );
          })
        )
      ),
      startWith(false)
    );

  public name$ = this.store.pipe(
    select(
      createSelector(
        authSelectors.userSelector,
        (user) => user && `${user.firstName} ${user.lastName}`
      )
    )
  );

  public userHasMFA$ = this.store.pipe(
    select(
      createSelector(authSelectors.userSelector, (user) =>
        user ? user.hasMFA : false
      )
    )
  );

  public tempSignUp$ = this.store.pipe(select(authSelectors.tempUserSelector));
  public loading$ = this.store.pipe(select(authSelectors.loadingSelector));
  public user$ = this.store.pipe(select(authSelectors.userSelector));
  public clientAdmin$ = this.store.pipe(
    select(authSelectors.clientAdminSelector)
  );

  public isAuthenticated$ = this.store.pipe(
    select(authSelectors.isAuthenticatedSelector)
  );

  public loginErrorType$ = this.store.pipe(
    select(authSelectors.loginErrorTypeSelector)
  );

  public invalidSignUp$ = this.store.pipe(
    select(authSelectors.invalidSignUpSelector)
  );

  public isValidUsername$ = combineLatest([
    this.store.pipe(select((state: AppState) => state.auth.isValidUsername)),
    this.store.pipe(select(authSelectors.loadingSelector))
  ]).pipe(
    filter(([, loading]) => !loading),
    map(([isValidUsername]) => isValidUsername)
  );

  public isValidUsernameLoading$ = this.store.pipe(
    select(authSelectors.isValidUsernameLoadingSelector)
  );

  public age$ = this.store.pipe(
    select(authSelectors.ageSelector),
    filter((_) => !!_)
  );

  public gender$ = this.store.pipe(
    select(authSelectors.genderSelector),
    filter((_) => !!_)
  );

  public sentSignUpRequest$ = this.store.pipe(
    select(authSelectors.sentSignUpRequestSelector)
  );

  public verifyEmailLoading$ = this.store.pipe(
    select((state: AppState) => state.auth.verifyEmailLoading)
  );

  public reAuthLoading$ = this.store.pipe(
    select(authSelectors.reAuthLoadingSelector)
  );

  public updatePasswordLoading$ = this.store.pipe(
    select(authSelectors.updatePasswordLoadingSelector)
  );

  public inUpdateSensitiveInfoFlow$ = this.store.pipe(
    select(authSelectors.inUpdateSensitiveInfoFlowSelector)
  );

  public inPasswordResetFlow$ = this.store.pipe(
    select(authSelectors.inPasswordResetFlowSelector)
  );

  public inVerifyMFAFlow$ = this.store.pipe(
    select(authSelectors.inVerifyMFAFlowSelector)
  );

  constructor(private store: Store<AppState>, private actions$: Actions) {}

  public getUser(params?: {
    /**
     * If we are to include the insurance property in the projection.
     * By default this isn't returned
     */
    insurance?: boolean;
  }) {
    const insurance = !!params?.insurance;

    this.store.dispatch(
      authActions.getUser({
        insurance
      })
    );
  }

  public samlLogin(user: User) {
    this.store.dispatch(authActions.loginSuccess({ user }));
  }

  public login(loginRequest: LoginRequest) {
    this.store.dispatch(authActions.login({ loginRequest }));

    return this.actions$.pipe(
      ofType(authActions.loginSuccess, authActions.loginFailed)
    );
  }

  /**
   * Dispatch an error that logging in via firebase didn't work.
   * DO NOT PASS THE ENTIRE ERR as you will get an unserializable error!
   */
  public firebaseLoginFailed(params: {
    err: Pick<AuthError, 'code' | 'message'>;
    username: string;
  }) {
    const { err, username } = params;

    this.store.dispatch(authActions.firebaseLoginFailed({ err, username }));
  }

  /**
   * Returns if the user is locked or not
   */
  public getIsLocked(params: { username: string }): Observable<boolean> {
    const { username } = params;

    this.store.dispatch(authActions.getIsLocked({ username }));

    return this.actions$.pipe(
      ofType(authActions.getIsLockedSuccess, authActions.getIsLockedFailed),
      map((action) => {
        if (action.type === authActions.getIsLockedFailed.type) {
          throw new HttpErrorResponse({});
        }

        return action.locked;
      })
    );
  }

  /**
   * Clears login errors
   */
  public clearLoginError() {
    this.store.dispatch(authActions.clearLoginError());
  }

  /**
   * Returns an observable for when the user signs-up
   */
  public signUp(signUpRequest: SignUpRequest): Observable<Action> {
    this.store.dispatch(authActions.signUp({ signUpRequest }));

    return merge(
      this.actions$.pipe(ofType(authActions.signUpSuccess)),
      this.actions$.pipe(ofType(authActions.signUpFailed))
    );
  }

  /**
   * Sets the sign-up request which should be cleared once
   * within that route
   */
  public setSentSignUpRequest(sentSignUpRequest: SignUpRequest) {
    this.store.dispatch(
      authActions.setSentSignUpRequest({ sentSignUpRequest })
    );
  }

  public clearSentSignUpRequest() {
    this.store.dispatch(authActions.clearSentSignUpRequest());
  }

  public tempSignUp(tempSignUp: TempSignUp) {
    this.store.dispatch(authActions.tempSignUp({ tempSignUp }));
  }

  public tempSignUpClear() {
    this.store.dispatch(authActions.tempSignUpClear());
  }

  public logout() {
    this.store.dispatch(authActions.logout());
  }

  /**
   * Clears the current user information, is distinct from logout,
   * as this should be called when the user "already logged out"
   */
  public clear() {
    this.store.dispatch(authActions.clear());
  }

  public updateUser(
    user: Partial<User>,
    config?: {
      includeInsurance?: boolean;
    }
  ) {
    this.store.dispatch(
      authActions.updateUser({
        user,
        includeInsurance: config?.includeInsurance
      })
    );

    return merge(
      this.actions$.pipe(ofType(authActions.updateUserSuccess)),
      this.actions$.pipe(
        ofType(authActions.updateUserFailed),
        map(() => {
          throw new HttpErrorResponse({});
        })
      )
    ).pipe(take(1));
  }

  public isValidUsername(params: { username: string }) {
    this.store.dispatch(authActions.isValidUsername(params));
  }

  /**
   * Sends the verification email to the backend
   * Returns an observable for when the action went thru
   */
  public verifyEmail(params: { captcha: string; username: string }) {
    this.store.dispatch(authActions.verifyEmail(params));

    return merge(
      this.actions$.pipe(ofType(authActions.verifyEmailSuccess)),
      this.actions$.pipe(
        ofType(authActions.verifyEmailFailed),
        map(() => {
          // **Note** we could pass in the actual error
          throw new HttpErrorResponse({});
        })
      )
    ).pipe(take(1));
  }

  /**
   * Makes a request to email username to the user
   */
  public forgotUsername(params: ForgotUsernameRequest) {
    const { lastName, birthDay, ssn, captcha } = params;

    this.store.dispatch(
      authActions.forgotUsername({
        lastName,
        birthDay,
        ssn,
        captcha
      })
    );

    return merge(
      this.actions$.pipe(ofType(authActions.forgotUsernameSuccess)),
      this.actions$.pipe(
        ofType(authActions.forgotUsernameFailed),
        map(() => {
          throw new HttpErrorResponse({});
        })
      )
    ).pipe(take(1));
  }

  /**
   * Makes a request to reset the given user's password
   */
  public resetPassword(params: PasswordResetRequest) {
    const { ssn, lastName, birthDay, captcha } = params;

    this.store.dispatch(
      authActions.resetPassword({
        ssn,
        lastName,
        birthDay,
        captcha
      })
    );

    return merge(
      this.actions$.pipe(ofType(authActions.resetPasswordSuccess)),
      this.actions$.pipe(
        ofType(authActions.resetPasswordFailed),
        map(() => {
          throw new HttpErrorResponse({});
        })
      )
    ).pipe(take(1));
  }

  /**
   * ReAuthenticate the existing user
   */
  public reAuth(password: string) {
    this.store.dispatch(authActions.reAuth({ password }));

    return this.actions$.pipe(
      ofType(authActions.reAuthSuccess, authActions.reAuthFailed),
      map((action) => {
        if (authActions.reAuthFailed.type === action.type) {
          throw new FirebaseAuthError(action.err.code, action.err.message);
        }

        return;
      })
    );
  }

  /**
   * Updates the user's password, call
   * the reAuth method to re-authenticate the user with
   * their password FIRST
   */
  public updatePassword(newPassword: string) {
    this.store.dispatch(authActions.updatePassword({ newPassword }));

    return this.actions$.pipe(
      ofType(
        authActions.updatePasswordSuccess,
        authActions.updatePasswordFailed
      ),
      map((action) => {
        if (authActions.updatePasswordFailed.type === action.type) {
          throw new FirebaseAuthError(action.err.code, action.err.message);
        }

        return;
      })
    );
  }

  /**
   * Turns off the password flow. Should be called within the guard automatically
   */
  public resetPasswordFlowDone() {
    this.store.dispatch(authActions.resetPasswordFlowDone());
  }

  public setInMFAFlow() {
    this.store.dispatch(authActions.verifyMFA());
  }

  public reAuthMFASuccesful() {
    this.store.dispatch(authActions.reAuthSuccess());
  }

  public clearInUpdateSensitiveInfoFlow() {
    this.store.dispatch(authActions.clearInUpdateSensitiveInfoFlow());
  }
}
