import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification, NotificationForm } from '@common';
import { Observable } from 'rxjs';
import { QueryListService } from '../../util/query-list.service';

const BASE_URL = '/api/v1/notifications';
const ADMIN_URL = '/api/v1/admin/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationHttpService {
  constructor(private http: HttpClient, private queryList: QueryListService) {}

  public list(): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${BASE_URL}/list`);
  }

  public get(id: string) {
    return this.http.get<Notification>(`${BASE_URL}/${id}`);
  }

  /**
   * These endpoints are only available to admins
   */
  public adminList(params: NotificationForm) {
    return this.http.get<{ notifications: Notification[]; hasNext: boolean }>(
      `${ADMIN_URL}/list`,
      {
        params: Object.entries(params).reduce(
          (_params, [key, value]) =>
            value ? _params.append(key, '' + value) : _params,
          new HttpParams()
        )
      }
    );
  }

  public adminListByUser(userId: string) {
    return this.http.get<{ notifications: Notification[] }>(
      `${ADMIN_URL}/list/user/${userId}`
    );
  }

  public adminListByCompany(companyId: string) {
    return this.http.get<{ notifications: Notification[] }>(
      `${ADMIN_URL}/list/company/${companyId}`
    );
  }

  public adminCreate(params: Partial<Notification>) {
    return this.http.post<Notification[]>(ADMIN_URL, params);
  }

  public update(notifications: (Notification | string)[]) {
    return this.http.put<Notification>(BASE_URL, { notifications });
  }

  public adminDelete(notifications: (Notification | string)[]) {
    return this.http.delete(ADMIN_URL, { body: { notifications } });
  }
}
