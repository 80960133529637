import { ObjectId } from 'mongodb';
import { DbDocument } from './db-document';
import { User } from './user';
import { CompanySignInOptions } from './company';
import { CategoryIds } from '../constants/education-categories';
import { UserResult } from './user-result/user-result';

export enum USER_AUDIT_TYPES {
  LOGIN = 'login',
  HRA_VIEW = 'hra_view',
  RESULT_VIEW = 'result_view',
  SENT_RESULTS_FAX = 'sent_results_fax',
  RESOURCE_VIEW = 'resource_view',
  THIRD_PARTY_RESOURCE = 'third_party_resource'
}
/**
 * User audit type
 */
export interface BaseUserAudit extends DbDocument {
  /**
   * The user the audit is for
   */
  user: string | ObjectId | User;
  /**
   * Type of audit
   */
  type: USER_AUDIT_TYPES;
}

/**
 * User audit to log the log ins the user has done in the platform
 */
export interface UserLoginAudit extends BaseUserAudit {
  type: USER_AUDIT_TYPES.LOGIN;
  /**
   * IP were the user logged in
   */
  ipAddress: string;
  /**
   * Browser information were the login happened
   */
  browser: string;

  /**
   * Login method,if normal login use ehs
   */
  signInOption: CompanySignInOptions | 'ehs';
}

export interface UserResultViewAudit extends BaseUserAudit {
  type: USER_AUDIT_TYPES.HRA_VIEW | USER_AUDIT_TYPES.RESULT_VIEW;
}

export interface UserFaxResultsAudit extends BaseUserAudit {
  type: USER_AUDIT_TYPES.SENT_RESULTS_FAX;
  /**
   * User result that was faxed
   */
  userResult: string | ObjectId | UserResult;
  /**
   * Fax number the result was sent to
   */
  faxNumber: string;
  /**
   * Physician name that requested the fax
   */
  physicianName: string;
}

export interface UserResourceViewAudit extends BaseUserAudit {
  type: USER_AUDIT_TYPES.RESOURCE_VIEW;

  /**
   * Name of the resource the user viewed
   */
  resourceName: string;
  /**
   * Category the resource belongs to
   */
  resourceCategory: CategoryIds;
}

export interface UserThirdPartyResourceAudit extends BaseUserAudit {
  type: USER_AUDIT_TYPES.THIRD_PARTY_RESOURCE;

  /**
   * Url that the user was redirected to
   */
  thirdPartyUrl: string;
}

export type UserAudit =
  | UserThirdPartyResourceAudit
  | UserResultViewAudit
  | UserFaxResultsAudit
  | UserLoginAudit
  | UserResourceViewAudit
  | BaseUserAudit;

// Guard functions
export const isUserLoginAudit = (audit: UserAudit): audit is UserLoginAudit =>
  (audit as UserLoginAudit).type === USER_AUDIT_TYPES.LOGIN;

export const isUserResultViewAudit = (
  audit: UserAudit
): audit is UserResultViewAudit =>
  (audit as UserResultViewAudit).type === USER_AUDIT_TYPES.HRA_VIEW ||
  (audit as UserResultViewAudit).type === USER_AUDIT_TYPES.RESULT_VIEW;

export const isUserFaxResultsAudit = (
  audit: UserAudit
): audit is UserFaxResultsAudit =>
  (audit as UserFaxResultsAudit).type === USER_AUDIT_TYPES.SENT_RESULTS_FAX;

export const isUserResourceViewAudit = (
  audit: UserAudit
): audit is UserResourceViewAudit =>
  (audit as UserResourceViewAudit).type === USER_AUDIT_TYPES.RESOURCE_VIEW &&
  !!(audit as UserResourceViewAudit).resourceName &&
  !!(audit as UserResourceViewAudit).resourceCategory;

export const isUserThirdPartyResourceAudit = (
  audit: UserAudit
): audit is UserThirdPartyResourceAudit =>
  (audit as UserThirdPartyResourceAudit).type ===
    USER_AUDIT_TYPES.THIRD_PARTY_RESOURCE &&
  !!(audit as UserThirdPartyResourceAudit).thirdPartyUrl;
