import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsMergeUserDialogData } from './ehs-merge-user-dialog-data';
import { EhsMergeUserDialogResponse } from './ehs-merge-user-dialog-response';
import { EhsMergeUserDialogComponent } from './ehs-merge-user-dialog.component';

@Injectable()
export class EhsMergeUserDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: EhsMergeUserDialogData
  ): Observable<EhsMergeUserDialogResponse | undefined> {
    return this.matDialog
      .open(EhsMergeUserDialogComponent, {
        data
      })
      .afterClosed();
  }
}
