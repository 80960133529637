import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { HraResult } from '@common';
import { selectId } from '../utils/select-id';
import { hraResultActions } from './hra-result.actions';

export interface HraResultState extends EntityState<HraResult> {
  loading?: boolean;
}

const adapter = createEntityAdapter<HraResult>({
  selectId
});

const reducer = createReducer<HraResultState>(
  adapter.getInitialState({}),
  on(hraResultActions.getCurrent, (state) => ({ ...state, loading: true })),
  on(hraResultActions.getCurrentSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(hraResultActions.getCurrentFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(hraResultActions.list, (state) => ({ ...state, loading: true })),
  on(hraResultActions.listSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(hraResultActions.listFailed, (state) => ({ ...state, loading: false })),

  on(hraResultActions.get, (state) => ({ ...state, loading: true })),
  on(hraResultActions.getSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(hraResultActions.getFailed, (state) => ({ ...state, loading: false })),

  on(hraResultActions.create, (state) => ({ ...state, loading: true })),
  on(hraResultActions.createSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(hraResultActions.createFailed, (state) => ({ ...state, loading: false })),

  on(hraResultActions.update, (state) => ({ ...state, loading: true })),
  on(hraResultActions.updateSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(hraResultActions.updateFailed, (state) => ({ ...state, loading: false })),

  on(hraResultActions.remove, (state) => ({ ...state, loading: true })),
  on(hraResultActions.removeSuccess, (state, { entity }) =>
    adapter.removeOne(entity._id.toString(), { ...state, loading: false })
  ),
  on(hraResultActions.removeFailed, (state) => ({ ...state, loading: false })),
  on(hraResultActions.getRelatedHraData, (state) => ({
    ...state,
    loading: true
  })),
  on(hraResultActions.getRelatedHraDataSuccess, (state) => ({
    ...state,
    loading: false
  })),
  on(hraResultActions.getRelatedHraDataFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(hraResultActions.exportServiceHras, (state) => ({
    ...state,
    loading: true
  })),
  on(hraResultActions.exportServiceHrasSuccess, (state) => ({
    ...state,
    loading: false
  })),
  on(hraResultActions.exportServiceHrasFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(hraResultActions.exportAllCompanyHras, (state) => ({
    ...state,
    loading: true
  })),
  on(hraResultActions.exportAllCompanyHrasSuccess, (state) => ({
    ...state,
    loading: false
  })),
  on(hraResultActions.exportAllCompanyHrasFailed, (state) => ({
    ...state,
    loading: false
  }))
);

export function HraResultReducer(state: HraResultState, action: Action) {
  return reducer(state, action);
}
