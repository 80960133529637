import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserRegistration } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationHttpService {
  constructor(private http: HttpClient) {}

  public get(id: string): Observable<UserRegistration> {
    return this.http.get<UserRegistration>(`api/v1/registrations/${id}`);
  }

  public list(params?: {
    includeReq?: boolean;
    includeService?: boolean;
    includeLocation?: boolean;
  }): Observable<UserRegistration[]> {
    const includeReq = params && params.includeReq;
    const includeService = params && params.includeService;
    const includeLocation = params && params.includeLocation;

    let httpParams = new HttpParams();

    if (includeReq) {
      httpParams = httpParams.append('includeReq', 'true');
    }

    if (includeService) {
      httpParams = httpParams.append('includeService', 'true');
    }

    if (includeLocation) {
      httpParams = httpParams.append('includeLocation', 'true');
    }

    return this.http.get<UserRegistration[]>(`api/v1/registrations`, {
      params: httpParams
    });
  }

  public update(
    entity: Partial<UserRegistration>
  ): Observable<UserRegistration> {
    return this.http.put<UserRegistration>(
      `api/v1/registrations/${entity._id}`,
      entity
    );
  }

  public create(
    entity: Partial<UserRegistration>
  ): Observable<UserRegistration> {
    return this.http.post<UserRegistration>(`api/v1/registrations`, entity);
  }

  public remove(id: string): Observable<UserRegistration> {
    return this.http.delete<UserRegistration>(`api/v1/registrations/${id}`);
  }

  public downloadProviderPdf(id: string): Observable<Blob> {
    return this.http.get(`api/v1/registrations/${id}/pdf/providerHealth`, {
      responseType: 'blob'
    });
  }

  /**
   * Returns a signed url to upload a provider user registration result file.
   */
  public getProviderUploadSignedUrl(params: {
    /**
     * The id of the corresponding user-registration
     */
    id: string;
    /**
     * The type of the file we will upload
     */
    contentType: string;
  }): Observable<{ url: string }> {
    const { id, contentType } = params;

    return this.http.get<{ url: string }>(
      `api/v1/registrations/${id}/upload/url`,
      {
        params: new HttpParams().set('contentType', contentType)
      }
    );
  }

  /**
   * Returns a signed url to download a provider user registration result file
   */
  public getProviderDownloadSignedUrl(params: {
    /**
     * The id of teh corresponding user-registration
     */
    id: string;
    /** The id generated for the file in google storage */
    fileId: string;
  }): Observable<{ url: string }> {
    const { id, fileId } = params;

    return this.http.get<{ url: string }>(
      `api/v1/registrations/${id}/download/url/${fileId}`
    );
  }

  /**
   * Returns a signed url to download a Lifepoint Lab Requisition PDF
   */
  public getLabRequisitionPdfSignedUrl(params: {
    /**
     * The id of the corresponding user-registration
     */
    id: string;
  }): Observable<{ url: string }> {
    const { id } = params;

    return this.http.get<{ url: string }>(
      `api/v1/registrations/${id}/download/requisition`
    );
  }
}
