import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { FirebaseAuthService } from '../firebase/firebase-auth.service';

/**
 * Protects the verify MFA route from users not in the MFA flow.
 */
@Injectable({
  providedIn: 'root'
})
class AddMFAGuardService {
  constructor(
    private firebaseAuth: FirebaseAuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.firebaseAuth.hasLatestCredentials$().pipe(
      map((authenticated) => {
        return authenticated || this.router.createUrlTree(['/']);
      }),
      take(1)
    );
  }
}

export const AddMFAGuard: CanActivateFn = () => {
  return inject(AddMFAGuardService).canActivate();
};
