import { EventService } from '@common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { selectId } from '../utils/select-id';
import { eventServiceActions } from './event-service.actions';

export interface EventServiceState extends EntityState<EventService> {
  loading: boolean;
}

const adapter = createEntityAdapter({
  selectId
});

const reducer = createReducer(
  adapter.getInitialState({} as EventServiceState),
  on(eventServiceActions.get, (state: EventServiceState) => ({
    ...state,
    loading: true
  })),
  on(eventServiceActions.getSuccess, (state: EventServiceState, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(eventServiceActions.getFailed, (state: EventServiceState) => ({
    ...state,
    loading: false
  })),
  on(eventServiceActions.get, (state) => ({
    ...state,
    loading: true
  })),
  on(eventServiceActions.getAnySuccess, (state, { entity }) =>
    adapter.upsertOne(entity, {
      ...state,
      loading: false
    })
  ),
  on(eventServiceActions.getFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(eventServiceActions.list, (state: EventServiceState) => ({
    ...state,
    loading: true
  })),
  on(
    eventServiceActions.listSuccess,
    (state: EventServiceState, { eventServices }) =>
      adapter.upsertMany(eventServices, { ...state, loading: false })
  ),
  on(eventServiceActions.listFailed, (state: EventServiceState) => ({
    ...state,
    loading: false
  })),
  on(eventServiceActions.set, (state: EventServiceState, { eventService }) =>
    adapter.upsertOne(eventService, state)
  ),

  on(eventServiceActions.remove, (state: EventServiceState, { eventService }) =>
    adapter.removeOne(eventService, state)
  )
);

export function EventServiceReducer(state: EventServiceState, action: Action) {
  return reducer(state, action);
}
