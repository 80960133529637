import { InjectionToken } from '@angular/core';

/**
 * This injection token is used to show/hide the "View Company"
 * option in **all** ehs-gwapps components.
 *
 * By default we **don't** show the "View Company" option.
 *
 * This should be provided at 2 levels.
 * 1. At the platform level where its **true**
 * 2. At the company level where its **false**
 *
 *
 */
export const EHS_SHOW_COMPANY_INJECTION_TOKEN = new InjectionToken(
  'EHS_SHOW_COMPANY_INJECTION_TOKEN'
);
