import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Injection token that represents a behavior-subject, that can be used
 * to show/hide all the GWApps actions within the app.
 *
 * This is done to provide a way to easily show/hide all instances of GWApps actions
 * components, without providing multiple prop-drilling.
 *
 * This is also decoupled from the state management solution of choice, and
 * can be provided as a feature flag.
 */
export const EHS_GWAPPS_ACTIONS_DISPLAY_INJECTION_TOKEN = new InjectionToken(
  'EHS_GWAPPS_ACTIONS_DISPLAY_INJECTION_TOKEN'
);

/**
 * The actual value that should be used for the injection token.
 *
 * **note** by default these actions are shown.
 */
export const EhsGwAppsActionsDisplay = new BehaviorSubject<boolean>(true);
