import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AdminUpdateAdditionalTestsDialogData } from './admin-update-additional-tests-dialog-data';
import { AdminUpdateAdditionalTestsDialogResponse } from './admin-update-additional-tests-dialog-response';
import { EhsAdminUpdateAdditionalTestsDialogComponent } from './ehs-admin-update-additional-tests-dialog.component';

@Injectable()
export class EhsAdminUpdateAdditionalTestsDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: AdminUpdateAdditionalTestsDialogData
  ): Observable<AdminUpdateAdditionalTestsDialogResponse | undefined> {
    return this.matDialog
      .open(EhsAdminUpdateAdditionalTestsDialogComponent, {
        data,
        width: '500px'
      })
      .afterClosed();
  }
}
