import { ObjectId } from 'mongodb';
import { Company } from './company';
import { DbDocument } from './db-document';
import { User } from './user';
import { UserRegistration } from './user-registration';
import { UserResult } from './user-result/user-result';

export enum ApiErrorAction {
  /**
   * When the lifepoint authenticate API is called
   */
  LIFEPOINT_AUTH = 'lifepoint_auth',
  /**
   * When the lifepoint authenticate API is called
   */
  LIFEPOINT_SEND_ORDER = 'lifepoint_send_order',
  /**
   * When the minnesota state API is called
   */
  COVID_MN_ORDER = 'covid_mn_order',
  /**
   * When the Illinois state API is called
   */
  COVID_IL_ORDER = 'covid_il_order'
}

/**
 * List of admin user scopes to human friendly names
 */
export const apiErrorActionNames: {
  [key in ApiErrorAction]: string;
} = {
  [ApiErrorAction.LIFEPOINT_AUTH]: 'Lifepoint Auth',
  [ApiErrorAction.LIFEPOINT_SEND_ORDER]: 'Lifepoint Send Order',
  [ApiErrorAction.COVID_MN_ORDER]: 'Minnesota Covid Order',
  [ApiErrorAction.COVID_IL_ORDER]: 'Illinois Covid Order'
};

export enum ApiErrorStatus {
  /**
   * When the request has been fixed or taken care of
   */
  COMPLETED = 'completed',
  /**
   * When the error has not been handled by anyone yet.
   */
  OPENED = 'opened',
  /**
   * When the api call was successful we mark it as received
   */
  RECEIVED = 'received'
}

export interface ApiErrorFilter
  extends Pick<Partial<ApiError>, 'status' | 'action' | 'username'> {
  start?: string;
  end?: string;
}

export interface ApiErrorListHttpParam extends ApiErrorFilter {
  pageNumber?: number;
}

export interface ApiError extends DbDocument {
  /**
   * The action taken for the API call.
   * e.g. LIFEPOINT_ADD, MN_COVID, etc...
   */
  action: ApiErrorAction;
  /**
   * The API URL being called
   */
  apiUrl: string;
  /**
   * HTTP Status Code returned by the API
   */
  statusCode: number;
  /**
   * The status of the error.
   * Opened = No one took action yet
   * Completed = It has been addressed
   */
  status: ApiErrorStatus;
  /**
   * Http error status message: e.g. Not Found
   */
  statusMessage?: string;
  /**
   * Error body content type (application/json, xml, text, html, etc...)
   */
  errorContentType: string;
  /**
   * The error message returned in the body
   */
  error?: string;
  /**
   * Admin comments on that given error.
   */
  notes?: string;
  /**
   * Company Name & username Used to minimize lookup and populate calls, when we need more data about the company
   * or more data about the user, we can always query it. This way we can search by company
   * name or user name.
   * Recommended by MongoDB Team.
   */
  companyName?: string;
  username?: string;
  /**
   * Extra meta data for the given action type.
   */
  meta: {
    company?: string | ObjectId | Company;
    user?: string | ObjectId | User;
    /**
     * The corresponding user-registration, only currently for fingerstick
     */
    userRegistration?: string | ObjectId | UserRegistration;
    /**
     * The corresponding user-result, only currently for fingerstick
     */
    userResult?: string | ObjectId | UserResult;
  };
}
