import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserPayment, UserPaymentSearchForm } from '@common';

const BASE_URL = '/api/v1/admin/payment';

@Injectable({
  providedIn: 'root'
})
export class AdminUserPaymentHttpService {
  constructor(private http: HttpClient) {}

  list(params: UserPaymentSearchForm) {
    return this.http.get<{ userPayments: UserPayment[]; hasNext: boolean }>(
      `${BASE_URL}/list`,
      {
        params: Object.entries(params).reduce(
          (_params, [key, value]) =>
            value ? _params.append(key, '' + value) : _params,
          new HttpParams()
        )
      }
    );
  }

  get(id: string) {
    return this.http.get<UserPayment>(`${BASE_URL}/${id}`);
  }

  update(userPayment: UserPayment) {
    return this.http.put<UserPayment>(`${BASE_URL}`, userPayment);
  }
}
