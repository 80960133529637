import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  AdminUser,
  getId,
  toMap,
  AdminNote,
  UserDeliveryContactType
} from '@common';

@Component({
  selector: 'ehs-admin-notes',
  templateUrl: './ehs-admin-notes.component.html',
  styleUrls: ['./ehs-admin-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsAdminNotesComponent<Note extends AdminNote> {
  public readonly deliveryTypes = Object.values(UserDeliveryContactType);

  /**
   * The list of notes the admin can edit.
   */
  @Input() notes: Array<Note> = [];

  /**
   * The list or map of admin users.
   */
  @Input() admins: AdminUser[] | Record<string, AdminUser> = {};

  /**
   * Event emitted when a single note is updated.
   * Usually emitted on a "change" or "blur" event.
   */
  @Output() updateNote = new EventEmitter<Note>();

  /**
   * Event emitted when a note is removed, where the parent must handle it.
   */
  @Output() removeNote = new EventEmitter<Note>();

  public displayedColumns = [
    'note',
    'contactedAt',
    'deliveryType',
    'createdAt',
    'admin',
    'action'
  ];

  /**
   * The map of admins by id. This is only used for
   * notes that don't save the admin information.
   * currently only critical-result notes.
   */
  public get adminsMap(): Record<string, AdminUser> {
    return Array.isArray(this.admins)
      ? toMap({ entities: this.admins })
      : this.admins;
  }

  /**
   * Returns the admin from the admin map.
   *
   * **note** this automatically handles the different data-types
   * of the admin's email.
   */
  public getAdminName(admin: Note['admin']): string {
    // This cast is somewhat unsafe, but ObjectId isn't available.
    // and the denormalized admin and actual admin user both will work here.
    return (
      // First get the "map" version, which is the latest.
      this.adminsMap[getId(admin as AdminUser)]?.email ||
      // Otherwise, use the internal denormalized version.
      (admin as AdminUser)?.email ||
      ''
    );
  }

  /**
   * Template handler that handles partial updates to a note.
   * This is required as the template can't handle `...` operators
   */
  public updateNoteHandler(note: Note, update: Partial<Note>) {
    this.updateNote.emit({
      ...note,
      ...update
    });
  }
}
