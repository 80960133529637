import {
  User,
  CustomReportImages,
  CustomReportStatus,
  Languages,
  States
} from '@common';

/**
 * Function To return if a specific custom report is applicable based on state settings on the report and the user, as well as language
 */
export const getCustomReport = (params: {
  user: User;
  customReports: CustomReportImages[];
  spanish?: boolean;
}) => {
  const { user, customReports, spanish } = params;

  let inclState = false;

  const filteredReports = (customReports || []).filter((report) => {
    if (report.status === CustomReportStatus.INACTIVE) {
      return false;
    }

    if (report.states?.length && user.state) {
      const isStateIncluded = (report.states || []).includes(
        user.state as States
      );

      inclState = isStateIncluded;

      return spanish
        ? report.language === Languages.SPANISH && isStateIncluded
        : report.language === Languages.ENGLISH && isStateIncluded;
    }

    if (spanish) {
      return report.language === Languages.SPANISH;
    }

    return report.language === Languages.ENGLISH;
  });

  // Find the report that meets all requirements
  const matchingReport = (filteredReports || []).find((report) => {
    if (report.states?.length && user.state) {
      return (report.states || []).includes(user.state as States);
    }

    // Adding this to make sure the first match of just english/spanish isn't the report returned if there is a state specific report in the list.
    if (inclState) {
      return false;
    }

    return true;
  });

  return matchingReport;
};
