import { DbDocument } from '@common';
import { EntityState } from '@ngrx/entity';
import {
  DefaultProjectorFn,
  MemoizedSelector,
  select,
  Store
} from '@ngrx/store';
import DefaultFuse from 'fuse.js';
import { combineLatest, from, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map
} from 'rxjs/operators';
import { entitySelectorsFactory } from './entity-selector-factory';

/**
 * Class that interacts with base entity-states via entity-selector-factory,
 * and provides methods to dispatch common actions for the same entity state.
 */
export class EntityFacade<
  Document extends DbDocument<DocumentKey>,
  DocumentKey extends string,
  AppState,
  DocumentState extends EntityState<Document> & { loading?: boolean }
> {
  /**
   * The base entity-selectors from the entity-selectors-factory.
   */
  public readonly entitySelectors = entitySelectorsFactory<
    Document,
    DocumentKey,
    AppState,
    DocumentState
  >({
    featureSelector: this.featureSelector
  });

  /**
   * The list of ids within the entity-state.
   */
  public readonly ids$ = this.store.pipe(
    select(this.entitySelectors.idsSelector)
  );

  /**
   * The map of entities, where the key is the id, and value the entity itself
   */
  public readonly entities$ = this.store.pipe(
    select(this.entitySelectors.entitiesSelector)
  );

  /**
   * If the entity-state is loading
   */
  public readonly loading$ = this.store.pipe(
    select(this.entitySelectors.loadingSelector)
  );

  /**
   * The array of entities.
   */
  public readonly entitiesArray$ = this.store.pipe(
    select(this.entitySelectors.entitiesArraySelector)
  );

  /**
   * The fuse instance, used for searching
   */
  protected fuse = from(import('fuse.js').then(({ default: Fuse }) => Fuse));
  constructor(
    protected store: Store<AppState>,
    protected featureSelector: MemoizedSelector<
      AppState,
      DocumentState,
      DefaultProjectorFn<DocumentState>
    >
  ) {}

  /**
   * Return the reference of the document from the store, if there is one
   */
  public get$(
    id: DocumentKey,
    params?: {
      /**
       * If we are to wait for the state to finish loading before returning
       * the entity.
       */
      waitForLoad?: boolean;
    }
  ) {
    if (params?.waitForLoad) {
      return combineLatest([
        this.loading$,
        this.store.pipe(select(this.entitySelectors.entitySelectorFactory(id)))
      ]).pipe(
        // Used to prevent edge-cases where the reducers get executed
        // after this emits, resulting in the entity possibly being blank.
        debounceTime(0),
        filter(([loading]) => !loading),
        map(([, entity]) => entity)
      );
    }

    return this.store.pipe(
      select(this.entitySelectors.entitySelectorFactory(id))
    );
  }

  /**
   * Returns an observable of all the entities, with the applied
   * search and filter. Internally uses a fusejs instance
   */
  public createSearch$(params: {
    /**
     * Observable search string applied to the given keys.
     */
    search$: Observable<string>;
    /**
     * An observable to the filter function,
     * will automatically be applied **before**
     * searching is applied.
     *
     * This is an observable so this function can be
     * "triggered" when the filter changes.
     */
    filterFn$?: Observable<
      (entity: Document, index?: number, arr?: Document[]) => boolean
    >;
    /**
     * The array of keys to apply the search to
     * using Fusejs
     */
    keys: Array<DefaultFuse.FuseOptionKey<any>>;
  }) {
    const { search$, filterFn$, keys } = params;
    const filteredEntities$ = combineLatest([
      this.entitiesArray$.pipe(distinctUntilChanged()),
      filterFn$ || of(() => true)
    ]).pipe(map(([entities, filterFn]) => (entities || []).filter(filterFn)));

    const fuse$ = combineLatest([search$, this.fuse, filteredEntities$]).pipe(
      map(([search, Fuse, entities]) =>
        search
          ? new Fuse(entities || [], {
              keys
            })
          : entities
      )
    );

    return combineLatest([search$, fuse$]).pipe(
      map(([search, source]) =>
        Array.isArray(source)
          ? source
          : source.search(search).map(({ item }) => item)
      )
    );
  }
}
