import { PartialUser } from '../models/partial-user';
import { User } from '../models/user';
import { DateUtil } from './date-util';

export enum MergeUserMode {
  BOTH_NORM = 'both_norm',
  BOTH_PARTIAL = 'both_partial',
  MIXED = 'mixed'
}
/**
 * This class will contain different set of functions and utilities that validate and help with certain user information
 */
export class UserUtil {
  /**
   * Regexp to validate
   */
  public static userNameRegExp =
    /^(?![-.])(?!.*[-.]{2})(?!.*[-.]$)[-a-zA-Z0-9.]{5,25}$/;

  /**
   * Regex to validate the password strength. This validates there is at least:
   * - 1 lower case letter
   * - 1 upper case letter
   * - 1 number
   * - 1 Special Character (!@#$%^&*)
   * - 8 characters
   */
  // eslint-disable-next-line no-useless-escape
  public static passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

  /** 4 Digits SSN validation */
  public static ssnRegExp = /^[0-9]{4}/;

  /**
   * Returns the merge mode for the given users in the array.
   * Will return null for invalid options, and more than 2 users.
   *
   * There are 3 combinations
   * 1. both-norm - both users are normal, we can prompt to know which is the "primary"
   * 2. both-partial - both users are partial, we can prompt to know which is the "primary"
   * 3. they are mixed
   */
  public static getMergeMode(
    users: Array<User | PartialUser>
  ): MergeUserMode | null {
    if (!users || !Array.isArray(users)) {
      return null;
    }

    if (users.length !== 2) {
      return null;
    }

    const isPartial = (user: User | PartialUser) => !!user?.partial;
    const [firstUser, secondUser] = users;

    if (isPartial(firstUser) && isPartial(secondUser)) {
      return MergeUserMode.BOTH_PARTIAL;
    }

    if (!isPartial(firstUser) && !isPartial(secondUser)) {
      return MergeUserMode.BOTH_NORM;
    }

    return MergeUserMode.MIXED;
  }

  /**
   * Returns if the given username is valid. The validity will be checked against a regexp that will
   * test for username length for now 3 min and 20 max character combinations: we only allow numbers,letters '.' and '-'
   * the username shouldn't start with '.' or '-' we also don't allow repetition of those characters.
   *  Function that will check iff the username is valid
   * @param value the username being passed and validated
   */
  public static isValidUserName(value: string) {
    return this.userNameRegExp.test(value);
  }

  /**
   * Utility function that returns the age of the given user based
   * upon their birth date.
   */
  public static getAge(user: Pick<User, 'birthDay'>) {
    if (!user) {
      return null;
    }
    let dateDiff = 0;

    dateDiff = Date.now() - new Date(user.birthDay).getTime();
    const ageDate = new Date(dateDiff); // Milliseconds from epoch

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  public static getAgeAtEventDate({
    user,
    eventDate
  }: {
    user: Pick<User, 'birthDay'>;
    eventDate: Date;
  }) {
    if (!user || !eventDate) {
      return null;
    }
    let dateDiff = 0;

    dateDiff = eventDate.getTime() - new Date(user.birthDay).getTime();

    const ageDate = new Date(dateDiff); // Milliseconds from epoch

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  /**
   * Returns a formatted string for lookups against "unique" user information.
   *
   * **note** be careful of the birthDay format when performing lookup!
   */
  public static getLookupString(
    user: Pick<User, 'birthDay' | 'ssn' | 'lastName'>
  ): string {
    return `${DateUtil.formatDate(user.birthDay)}/${user.ssn}/${
      user.lastName
    }`.toLowerCase();
  }

  /**
   * Returns the user's full name
   */
  public static getFullName(user: User): string {
    if (!user) {
      return '';
    }

    return [user.firstName, user.middleName, user.lastName]
      .filter((_) => !!_)
      .join(' ');
  }
}
