import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { EhsHeaderElementComponent } from './ehs-header-element/ehs-header-element.component';
import { EhsHeaderComponent } from './ehs-header.component';
import { EhsAnimationsModule } from '../../ehs-animations/ehs-animations.module';

@NgModule({
  declarations: [EhsHeaderComponent, EhsHeaderElementComponent],
  imports: [
    CommonModule,
    RouterModule,

    FlexLayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    EhsAnimationsModule
  ],
  exports: [EhsHeaderComponent]
})
export class EhsHeaderModule {}
