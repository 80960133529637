import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AppState } from '../app-state';
import { AdminCoreReducer } from './admin-core.state';
import { EffectsModule } from '@ngrx/effects';
import { AdminCoreEffects } from './admin-core.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('adminCore' as keyof AppState, AdminCoreReducer),
    EffectsModule.forFeature([AdminCoreEffects])
  ]
})
export class AdminCoreStoreModule {}
