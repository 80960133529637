import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserTest } from '@common';
import { Observable } from 'rxjs';
import { QueryList, QueryListService } from '../util/query-list.service';

@Injectable({
  providedIn: 'root'
})
export class UserTestHttpService {
  constructor(private http: HttpClient, private queryList: QueryListService) {}

  public get(id: string): Observable<UserTest> {
    return this.http.get<UserTest>(`api/v1/test/${id}`);
  }

  public list(query: QueryList<UserTest>): Observable<UserTest[]> {
    return this.http.get<UserTest[]>('api/v1/test', {
      params: this.queryList.build(query)
    });
  }

  public listByCode(query: QueryList<UserTest>): Observable<UserTest[]> {
    return this.http.get<UserTest[]>('api/v1/test/code', {
      params: this.queryList.build(query)
    });
  }

  /**
   * This endpoint is only available to admins
   */
  public listAsAdmin(params: {
    query: QueryList<UserTest>;
  }): Observable<UserTest[]> {
    const { query } = params;

    return this.http.get<UserTest[]>('api/v1/admin/tests', {
      params: this.queryList.build(query)
    });
  }
}
