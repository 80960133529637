import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthFacade } from '../app-store/auth/auth.facade';

/**
 * Protects the verify MFA route from users not in the MFA flow.
 */
@Injectable({
  providedIn: 'root'
})
class InMFAGuardService {
  constructor(private authFacade: AuthFacade, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authFacade.inVerifyMFAFlow$.pipe(
      map((inMFA) => {
        return inMFA || this.router.createUrlTree(['/']);
      }),
      take(1)
    );
  }
}

export const InMFAGuard: CanActivateFn = () => {
  return inject(InMFAGuardService).canActivate();
};
