import { BaseSiteUserRegistration } from './base-site-user-registration';
import { EventLocation } from '../event-location';
import { ObjectId } from 'mongodb';
import { ServiceType } from '../event-service/service-type';
import { ServiceLabType } from '../event-service/service-lab-type';
import { SiteVaccinationType } from '../event-service/site-service';
import { AdminUser } from '../admin-user';
import { UserRequisition } from '../user-requisition';
import { UserRegistration } from '../user-registration';
/**
 * Interface for an onsite user registration
 */
export interface OnsiteUserRegistration extends BaseSiteUserRegistration {
  serviceType: ServiceType.ONSITE;
  /**
   * The eventLocation that goes with this onsite-user-registration
   */
  eventLocation?: string | ObjectId | EventLocation;
  /**
   * The corresponding user-registration with this user-registration.
   * Only applies to user-requisitions with offsite labs
   */
  userRequisition?: string | ObjectId | UserRequisition;
  /**
   * The time registered for
   */
  eventTime: string;

  /**
   * The date registered for.
   * yyyy-mm-dd
   */
  eventDate: string;

  /**
   * The lab type for the user-registration, taken from the service
   * Onsite can have "none" as the lab-type
   */
  labType: ServiceLabType | 'none';

  /**
   * Whether or not the user wants to do a screening or not. Determined by
   * the selection during the onsite-type selection.
   *
   * This used to represent if we are to send this data to lifepoint or not, but
   * this isn't currently supported.
   *
   * Directly is used and "translated" to OnsiteUserRegistration
   */
  screening: boolean;

  /**
   * If the user wants to have a vaccination, then he will select the vaccinationTypes
   * he wishes to get. Currently the only one supported is "flu". But in the future,
   * the user can select "flu" and "covid-19" or just "covid-19" or just "flu"...
   */
  vaccination?: {
    vaccinationType: SiteVaccinationType[];
  };
  /**
   * Which admin created a "result" for this user-registrations
   */
  reviewedBy?: string | ObjectId | AdminUser;
  /**
   * When the admin created a "result" for this user-registration.
   */
  reviewedAt?: Date;
  /**
   * When the admin created a "userVaccination" for this user-registration.
   */
  fluReviewedAt?: Date;
}

/**
 * Type-guard for onsite-user-registrations
 */
export const isOnsiteUserRegistration = (
  userRegistration: UserRegistration
): userRegistration is OnsiteUserRegistration =>
  userRegistration?.serviceType === ServiceType.ONSITE;

/**
 * Returns if the given user-registration is a covid user-registration
 */
export const isCOVIDUserRegistration = (
  userRegistration: OnsiteUserRegistration
): boolean =>
  !!(userRegistration?.vaccination?.vaccinationType || []).includes(
    SiteVaccinationType.COVID_19
  );

/**
 * Returns if the given user-registration is a flu user-registration
 */
export const isFluUserRegistration = (
  userRegistration: OnsiteUserRegistration
): boolean =>
  !!(userRegistration?.vaccination?.vaccinationType || []).includes(
    SiteVaccinationType.FLU
  );

export const isOnsiteScreening = (
  userRegistration: OnsiteUserRegistration
): boolean => !!userRegistration?.screening;
