<form #form="ngForm"
      (submit)="submit(form)"
      novalidate>
  <h1 mat-dialog-title>Reset Password</h1>

  <mat-dialog-content gdGap="16px"
                      class="mat-typography">

    <div style="width: 100%;"
         gdColumns="1fr 3fr">
      <div class="label">Name</div>
      <div>{{data.user.firstName}} {{data.user.lastName}}</div>
      <div class="label">Email</div>
      <div>{{data.user.email}}</div>
      <div class="label">Birthday</div>
      <div>{{data.user.birthDay | date: 'mediumDate'}}</div>
      <div class="label">SSN</div>
      <div>###-##-{{data.user.ssn}}</div>
    </div>

    <div class="label"
         style="margin: 8px 0">
      Fill out the fields below to reset user's password
    </div>

    <div>
      <!-- New Password -->
      <mat-form-field style="width: 100%"
                      appearance="outline">
        <mat-label>New Password</mat-label>
        <input matInput
               [type]="showPassword ? 'text' : 'password'"
               name="password"
               #passwordInput="ngModel"
               minlength="8"
               maxlength="30"
               ngModel
               [pattern]="passwordRegExp"
               autocomplete="off"
               pwdConfirm
               [altControl]="confirmPasswordInput"
               (change)="confirmPasswordInput.control.updateValueAndValidity()"
               required />
        <button mat-icon-button
                type="button"
                matSuffix
                (click)="showPassword = !showPassword"
                [attr.aria-label]="'Show Password'"
                [attr.aria-pressed]="showPassword"
                title="Show password">
          <mat-icon>
            {{showPassword ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </button>

        <mat-error *ngIf="passwordInput.hasError('required')">
          This field is required
        </mat-error>
        <mat-error *ngIf="passwordInput.hasError('minlength')">
          Password has to be minimum 8 characters
        </mat-error>
        <mat-error *ngIf="passwordInput.hasError('maxlength')">
          Password has to be maximum 8 characters
        </mat-error>
        <mat-error *ngIf="passwordInput.hasError('pwdMatch')">
          Passwords do not match
        </mat-error>
        <mat-error *ngIf="passwordInput.hasError('pattern')">
          Password must have at least 1 lower case, 1 upper case, 1 number
          and 1 special character (!&#64;#$%^&*)
        </mat-error>
      </mat-form-field>

      <mat-form-field style="width: 100%"
                      appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input matInput
               [type]="showConfirmPassword ? 'text' : 'password'"
               name="confirmPwd"
               #confirmPasswordInput="ngModel"
               ngModel
               pwdConfirm
               [altControl]="passwordInput"
               (change)="passwordInput.control.updateValueAndValidity()"
               autocomplete="off"
               required />
        <button mat-icon-button
                type="button"
                matSuffix
                (click)="showConfirmPassword = !showConfirmPassword"
                [attr.aria-label]="'Show Confirm Password'"
                [attr.aria-pressed]="showConfirmPassword"
                title="Show confirm password">
          <mat-icon>
            {{showConfirmPassword ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </button>
        <!-- **note** all other errors should be "caught" by the pwdMatch.-->
        <mat-error *ngIf="confirmPasswordInput.hasError('required')">
          This field is required
        </mat-error>
        <mat-error *ngIf="confirmPasswordInput.hasError('pwdMatch')">
          Passwords do not match
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end"
                      fxLayoutGap="16px">
    <button type="button"
            class="grey-cancel"
            mat-button
            [mat-dialog-close]="false">
      Cancel
    </button>
    <button type="submit"
            color="primary"
            mat-button>
      Reset Password
    </button>
  </mat-dialog-actions>
</form>
