import { Injectable } from '@angular/core';
import {
  AdminUser,
  AdminUserScopes,
  getId,
  isOffsiteUserRegistration,
  isOnsiteUserRegistration,
  OffsiteUserRegistration,
  OnsiteUserRegistration,
  ProviderHealthUserRegistration,
  UserRegistration
} from '@common';
import {
  EhsRegTableOffsiteActionTypes,
  EhsRegTableOnsiteActionTypes,
  EhsRegTableProviderActionTypes
} from './ehs-reg-table-action-types';
import {
  EhsRegTableColumn,
  EhsRegTableColumnAction,
  EhsRegTableColumnActionDef,
  isColumnProp
} from './ehs-reg-table-column';
import { ScreeningFilters } from '../ehs-reg-filters/ehs-reg-filters';

/**
 * Generic service that provides logic to generate the different columns
 * for different rows.
 */
@Injectable({
  providedIn: 'root'
})
export class EhsRegTableService {
  private applyDefaults(column: EhsRegTableColumn<UserRegistration>) {
    if (isColumnProp(column) && column.width === undefined) {
      column.width = '200px';
    }

    return column;
  }

  /**
   * Returns the columns for the provider-health
   * scenario.
   */
  public getProviderColumns({
    admin
  }: {
    /**
     * The current admin, we will use for security checks
     */
    admin: AdminUser;
  }): EhsRegTableColumn<ProviderHealthUserRegistration>[] {
    return (
      [
        {
          prop: 'checkbox',
          width: '50px'
        },
        {
          columnTitle: 'Last Name',
          prop: 'lastName'
        },
        {
          columnTitle: 'First Name',
          prop: 'firstName'
        },
        {
          columnTitle: 'Date of Birth',
          prop: 'birthDay',
          width: '120px'
        },
        {
          columnTitle: 'Email',
          prop: 'email'
        },
        {
          columnTitle: 'HPF Requested',
          // This is the createdAt for the userRegistration
          prop: 'createdAt'
        },
        {
          columnTitle: 'Date of Service',
          // Collection-date saved on the user-result
          prop: 'collectionDate'
        },
        {
          columnTitle: 'Results Entered',
          prop: 'resultsUploaded'
        },
        {
          prop: 'actions',
          actions: ({ company, userRegistration, userResult }) => {
            const canCreateResult = admin.scopes.includes(
              AdminUserScopes.CREATE_RESULT
            );
            const canRemoveResult = admin.scopes.includes(
              AdminUserScopes.REMOVE_RESULT
            );
            const actions: EhsRegTableColumnActionDef<EhsRegTableProviderActionTypes>[] =
              [];

            if (canRemoveResult) {
              if (userResult) {
                actions.push({
                  name: 'Remove Result',
                  actionType: 'removeResult'
                });
              }

              actions.push({
                name: 'Remove Result and Registration',
                actionType: 'removeResultAndRegistration'
              });
            }

            if (!userResult && canCreateResult) {
              // If the result doesn't exist, then let admins create it
              actions.push({
                name: 'Add Results',
                actionType: 'link',
                route: [
                  '/ehs/admin/company',
                  company,
                  'service',
                  getId(userRegistration.eventService),
                  'provider',
                  'form'
                ],
                routeQueryParams: {
                  // This is required to override the redirect "back" button from
                  // going to the legacy pages.
                  future: true,
                  userRegistration: getId(userRegistration)
                }
              });
            }

            if (userResult && canCreateResult) {
              // The user-result exists, so this means admins can edit
              // the provider-results.
              actions.push({
                name: 'Edit Results',
                actionType: 'link',
                route: [
                  '/ehs/admin/company',
                  company,
                  'service',
                  getId(userRegistration.eventService),
                  'provider',
                  'form'
                ],
                routeQueryParams: {
                  userRegistration: getId(userRegistration),
                  future: true
                }
              });
            }

            // Any admin can download results, if there are any
            if (userResult) {
              actions.push(
                {
                  name: 'Download Report',
                  actionType: 'downloadReport'
                },
                {
                  name: 'Download Full Report (ES)',
                  actionType: 'downloadReportEs'
                },
                {
                  name: 'View Raw Result',
                  actionType: 'viewRawResult'
                }
              );

              if (canCreateResult) {
                actions.push({
                  name: 'Data Correction',
                  actionType: 'link',
                  route: [
                    '/ehs/admin/company',
                    company,
                    'user',
                    getId(userRegistration.user),
                    'result',
                    'correction',
                    getId(userResult)
                  ],
                  routeQueryParams: {
                    redirect: 'provider'
                  }
                });
              }
            }

            // Always show this last
            actions.push({
              name: 'View Raw Registration',
              actionType: 'viewRawRegistration'
            });

            return actions;
          }
        } as EhsRegTableColumnAction<
          ProviderHealthUserRegistration,
          EhsRegTableProviderActionTypes
        >
      ] as EhsRegTableColumn<ProviderHealthUserRegistration>[]
    ).map(this.applyDefaults);
  }

  /**
   * Returns the columns for the offsite user registrations table
   */
  public getOffsiteColumns({
    admin
  }: {
    admin: AdminUser;
  }): EhsRegTableColumn<OffsiteUserRegistration>[] {
    return (
      [
        {
          prop: 'checkbox',
          width: '50px'
        },
        {
          columnTitle: 'Last Name',
          prop: 'lastName',
          width: '150px'
        },
        {
          columnTitle: 'First Name',
          prop: 'firstName',
          width: '150px'
        },
        {
          columnTitle: 'DOB',
          prop: 'birthDay',
          width: '70px'
        },
        {
          columnTitle: 'SSN',
          prop: 'ssn',
          width: '50px'
        },
        {
          columnTitle: 'Status',
          prop: 'status',
          width: '50px'
        },
        {
          columnTitle: 'Requisition #',
          prop: 'shortId',
          width: '100px'
        },
        {
          columnTitle: 'Group Type',
          prop: 'groupType',
          width: '200px'
        },
        {
          columnTitle: 'Resulted',
          prop: 'hasResults',
          width: '60px'
        },
        {
          columnTitle: 'Failed Test',
          prop: 'hasFailedTest',
          width: '70px'
        },
        {
          columnTitle: 'Released',
          prop: 'hasBeenReleased',
          width: '60px'
        },
        {
          columnTitle: 'Failed',
          prop: 'labOrderFailed',
          width: '60px'
        },
        {
          columnTitle: 'Ordered At',
          // **Note** this matches with the user-registration createdAt
          prop: 'createdAt',
          width: '90px'
        },
        {
          columnTitle: 'Collection Date',
          prop: 'collectionDate',
          width: '100px'
        },
        {
          columnTitle: 'Results Created At',
          prop: 'resultsCreatedAt',
          width: '130px'
        },
        // {
        //   columnTitle: 'Biometric',
        //   prop: 'biometric'
        // },
        {
          prop: 'actions',
          actions: ({ company, userResult, userRegistration }) => {
            const canRemoveResult = admin.scopes.includes(
              AdminUserScopes.REMOVE_RESULT
            );
            const canEditResult = admin.scopes.includes(
              AdminUserScopes.CREATE_RESULT
            );

            const actions: Array<
              EhsRegTableColumnActionDef<EhsRegTableOffsiteActionTypes>
            > = [];

            if (canRemoveResult && userResult) {
              if (userResult) {
                actions.push({
                  name: 'Remove Result',
                  actionType: 'removeResult'
                });
              }

              actions.push({
                name: 'Remove Result and Registration',
                actionType: 'removeResultAndRegistration'
              });
            }

            if (userResult) {
              actions.push(
                {
                  name: 'Download Full Report',
                  actionType: 'downloadReport'
                },
                {
                  name: 'Download Full Report (ES)',
                  actionType: 'downloadReportEs'
                },
                {
                  name: 'Download Lab PDF',
                  actionType: 'downloadLabPdf'
                },
                {
                  name: 'View Raw Result',
                  actionType: 'viewRawResult'
                }
              );

              if (canEditResult) {
                actions.push({
                  name: 'Data Correction',
                  actionType: 'link',
                  route: [
                    '/ehs/admin/company',
                    company,
                    'user',
                    getId(userRegistration.user),
                    'result',
                    'correction',
                    getId(userResult)
                  ],
                  routeQueryParams: {
                    redirect: 'offsite'
                  }
                });
              }
            }

            if (
              userRegistration &&
              isOffsiteUserRegistration(userRegistration)
            ) {
              actions.push({
                name: 'Resend Lab Order',
                actionType: 'resendLabOrder'
              });
            }

            if (
              userRegistration &&
              (isOffsiteUserRegistration(userRegistration) ||
                isOnsiteUserRegistration(userRegistration)) &&
              userRegistration.userRequisition
            ) {
              actions.push({
                name: 'Download Req',
                actionType: 'downloadReq' as EhsRegTableOffsiteActionTypes
              });
            }

            // Always show this last
            actions.push({
              name: 'View Raw Registration',
              actionType: 'viewRawRegistration'
            });

            return actions;
          }
        } as EhsRegTableColumnAction<
          OffsiteUserRegistration,
          EhsRegTableOffsiteActionTypes
        >
      ] as EhsRegTableColumn<OffsiteUserRegistration>[]
    ).map(this.applyDefaults);
  }

  /**
   * Returns the columns for the onsite-user-registrations scenario.
   */
  public getOnsiteColumns({
    admin,
    reg,
    vaccinationAction
  }: {
    /**
     * The current admin, we will use for security checks
     */
    admin: AdminUser;
    reg?: string;
    vaccinationAction?: 'enable' | 'disable' | undefined;
  }): EhsRegTableColumn<OnsiteUserRegistration>[] {
    return (
      [
        {
          prop: 'checkbox',
          width: '50px'
        },
        {
          columnTitle: 'Last Name',
          prop: 'lastName',
          width: '150px'
        },
        {
          columnTitle: 'First Name',
          prop: 'firstName',
          width: '150px'
        },
        {
          columnTitle: 'DOB',
          prop: 'birthDay',
          width: '70px'
        },
        {
          columnTitle: 'SSN',
          prop: 'ssn',
          width: '50px'
        },
        {
          columnTitle: 'Registered On',
          // **Note** this matches the user-registration createdAt
          prop: 'createdAt',
          width: '130px'
        },
        reg === ScreeningFilters.FLU && vaccinationAction === 'enable'
          ? {
              columnTitle: 'Vaccination Arm',
              prop: 'shotLocation',
              width: '160px'
            }
          : {
              columnTitle: 'Status',
              prop: 'status',
              width: '50px'
            },
        {
          columnTitle: 'Failed Test',
          prop: 'hasFailedTest',
          width: '70px'
        },
        {
          columnTitle: 'Rack #',
          prop: 'rackNumber',
          width: '130px'
        },
        reg === ScreeningFilters.FLU
          ? {
              columnTitle: 'Flu Reviewed At',
              prop: 'fluReviewedAt',
              width: '150px'
            }
          : {
              columnTitle: 'Reviewed At',
              prop: 'reviewedAt',
              width: '150px'
            },
        {
          columnTitle: 'Released At',
          prop: 'releasedAt',
          width: '130px'
        },
        {
          columnTitle: 'Released By',
          prop: 'releasedBy',
          width: '130px'
        },
        {
          columnTitle: 'Resulted',
          prop: 'hasResults',
          width: '70px'
        },
        {
          columnTitle: 'Requisition #',
          prop: 'shortId',
          width: '160px'
        },
        {
          prop: 'actions',
          actions: ({
            company,
            userResult,
            userRegistration,
            userVaccination
          }) => {
            const canRemoveResult = admin.scopes.includes(
              AdminUserScopes.REMOVE_RESULT
            );
            const canCreateResult = admin.scopes.includes(
              AdminUserScopes.CREATE_RESULT
            );
            const actions: EhsRegTableColumnActionDef<EhsRegTableOnsiteActionTypes>[] =
              [];

            if (canRemoveResult) {
              if (userResult) {
                actions.push({
                  name: 'Remove Result',
                  actionType: 'removeResult'
                });
              }

              actions.push({
                name: 'Remove Result and Registration',
                actionType: 'removeResultAndRegistration'
              });

              if (userVaccination) {
                actions.push({
                  name: 'Remove / Edit Vaccination',
                  actionType: 'removeVaccination'
                });
              }

              actions.push({
                name: 'Remove Vaccination and Registration',
                actionType: 'removeVaccinationAndRegistration'
              });
            }

            if (userResult) {
              if (canCreateResult) {
                actions.push(
                  {
                    name: 'Edit Results',
                    actionType: 'link',
                    route: [
                      '/ehs/admin/company',
                      company,
                      'service',
                      getId(userRegistration.eventService),
                      'event',
                      isOnsiteUserRegistration(userRegistration)
                        ? getId(userRegistration.eventLocation)
                        : undefined,
                      'results'
                    ],
                    routeQueryParams: {
                      userRegistration: getId(userRegistration)
                    }
                  },
                  {
                    name: 'Data Correction',
                    actionType: 'link',
                    route: [
                      '/ehs/admin/company',
                      company,
                      'user',
                      getId(userRegistration.user),
                      'result',
                      'correction',
                      getId(userResult)
                    ],
                    routeQueryParams: {
                      redirect: 'onsite'
                    }
                  }
                );
              }

              actions.push(
                {
                  name: 'Download Full Report',
                  actionType: 'downloadReport'
                },
                {
                  name: 'Download Full Report (ES)',
                  actionType: 'downloadReportEs'
                },
                {
                  name: 'Download Lab PDF',
                  actionType: 'downloadLabPdf'
                },
                {
                  name: 'View Raw Result',
                  actionType: 'viewRawResult'
                }
              );
            } else {
              if (canCreateResult) {
                actions.push({
                  name: 'Add Results',
                  actionType: 'link',
                  route: [
                    '/ehs/admin/company',
                    company,
                    'service',
                    getId(userRegistration.eventService),
                    'event',
                    isOnsiteUserRegistration(userRegistration)
                      ? getId(userRegistration.eventLocation)
                      : undefined,
                    'results'
                  ],
                  routeQueryParams: {
                    userRegistration: getId(userRegistration)
                  }
                });
              }

              actions.push({
                name: 'Release To Lab',
                actionType: 'releaseToLab'
              });
            }

            if (
              userRegistration &&
              isOnsiteUserRegistration(userRegistration) &&
              userRegistration.screening
            ) {
              actions.push({
                name: 'Download Requisition(s)',
                // This should automatically handle **which** reqs are downloaded internally
                actionType: 'downloadReq'
              });
            }

            // Always show this last
            actions.push({
              name: 'View Raw Registration',
              actionType: 'viewRawRegistration'
            });

            return actions;
          }
        }
      ] as EhsRegTableColumn<OnsiteUserRegistration>[]
    ).map(this.applyDefaults);
  }

  /** Columns for Client Admin Tables */
  /**
   * Returns the columns for the onsite-user-registrations scenario.
   */
  public getClientAdminOnsiteColumns(): EhsRegTableColumn<OnsiteUserRegistration>[] {
    return (
      [
        {
          prop: 'checkbox',
          width: '50px'
        },
        {
          columnTitle: 'Last Name',
          prop: 'lastName',
          width: '150px'
        },
        {
          columnTitle: 'First Name',
          prop: 'firstName',
          width: '150px'
        },
        {
          columnTitle: 'Registered On',
          // **Note** this matches the user-registration createdAt
          prop: 'createdAt',
          width: '130px'
        },
        {
          columnTitle: 'Event Date',
          prop: 'eventDate',
          width: '130px'
        },
        {
          columnTitle: 'Time',
          prop: 'eventTime',
          width: '130px'
        },
        {
          prop: 'actions',
          actions: ({ company, userResult, userRegistration }) => {
            const actions: EhsRegTableColumnActionDef<EhsRegTableOnsiteActionTypes>[] =
              [];

            if (userResult) {
              actions.push(
                {
                  name: 'Download Full Report',
                  actionType: 'downloadReport'
                },
                {
                  name: 'Download Full Report (ES)',
                  actionType: 'downloadReportEs'
                }
              );
            }

            return actions;
          }
        }
      ] as EhsRegTableColumn<OnsiteUserRegistration>[]
    ).map(this.applyDefaults);
  }
}
