import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonResponse, GcfHraExportRequest } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminHraHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Exports the HRAs for the given company+eventServiceId
   *
   * **note** the company passed isn't used right now, but should
   * still be passed
   */
  public exportHras(
    params: Omit<GcfHraExportRequest, 'email'>
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      'api/v1/admin/company/hra/export',
      params
    );
  }
}
