import { AdminAuditAction, AdminAuditActionGroups } from '@common';
import { AdminAuditListActionColumn } from './admin-audit-list-table-column';

// Columns to display by type of admin audit
export const displayedColumnsMap = {
  [AdminAuditActionGroups.LOGIN]: ['createdAt', 'type', 'actions'],
  [AdminAuditActionGroups.LOGIN_AS_USER]: ['createdAt', 'type', 'actions'],
  [AdminAuditActionGroups.PASSWORD_RESET]: ['createdAt', 'type', 'actions'],
  [AdminAuditActionGroups.USER_MANAGEMENT]: ['createdAt', 'type', 'actions'],
  [AdminAuditActionGroups.RESULTS]: ['createdAt', 'type', 'actions'],
  all: ['createdAt', 'type', 'actions']
};
// Actions to show depending of the type of admin audit
export const actionColumnsMap: {
  [key in AdminAuditAction]?: AdminAuditListActionColumn[];
} = {
  [AdminAuditAction.LOGIN]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.LOGIN_AS_USER]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.PASSWORD_RESET]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.CREATE_PARTIAL_USER]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.CREATE_REGISTRATION]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.CREATE_INSURANCE_CLAIM]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.MERGE_USER_PRIMARY]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.MERGE_USER_ORPHANED]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.MOVE_USER_COMPANY]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.MFA_DELETE]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.VERIFY_EMAIL]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.CREATE_USER_RESULT]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.UPDATE_USER_RESULT]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.UPDATE_USER_VACCINATION]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>,
  [AdminAuditAction.CREATE_USER_VACCINATION]: [
    {
      type: 'view',
      name: 'View Raw Data'
    }
  ] as Array<AdminAuditListActionColumn>
};
