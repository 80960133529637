import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneUtil } from '@common';

export function IsValidPhoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    return !PhoneUtil.isValidPhone(value, { mustStartWithOne: false })
      ? { invalidNumber: true }
      : null;
  };
}
