import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AdminUpdateServiceTypesDialogData } from './admin-update-service-types-dialog-data';
import { AdminUpdateServiceTypesDialogResponse } from './admin-update-service-types-dialog-response';
import { EhsAdminUpdateServiceTypesDialogComponent } from './ehs-admin-update-service-types-dialog.component';

@Injectable()
export class EhsAdminUpdateServiceTypesDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: AdminUpdateServiceTypesDialogData
  ): Observable<AdminUpdateServiceTypesDialogResponse | undefined> {
    return this.matDialog
      .open(EhsAdminUpdateServiceTypesDialogComponent, {
        data,
        width: '500px'
      })
      .afterClosed();
  }
}
