import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { clientLogger } from '../../client-logger';
import { CompanyHttpService } from '../../http/company-http.service';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { companyActions } from './company.actions';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private errorReportFacade: ErrorReportFacade,
    private companyHttp: CompanyHttpService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.get),
      mergeMap((action) =>
        this.companyHttp.get().pipe(
          map((company) => companyActions.getSuccess({ company })),
          catchError((err) => [
            companyActions.getFailed(),
            this.errorReportFacade.create({ action, err: err.error })
          ])
        )
      )
    )
  );

  isValidClientCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.isValidClientCode),
      mergeMap((action) =>
        this.companyHttp.isValidClientCode(action).pipe(
          map((valid) => companyActions.isValidClientCodeSuccess({ valid })),
          catchError((err) => {
            clientLogger.error(err);

            return [companyActions.isValidClientCodeFailed()];
          })
        )
      )
    )
  );

  getPersonTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.getPersonTypes),
      mergeMap((action) =>
        this.companyHttp.getSupportedPersonTypes(action).pipe(
          map((personTypes) =>
            companyActions.getPersonTypesSuccess({
              clientCode: action.clientCode,
              personTypes
            })
          ),
          catchError((err: HttpErrorResponse) => [
            companyActions.getPersonTypesFailed({ error: err.error })
          ])
        )
      )
    )
  );

  getCompanyLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyActions.getCompanyLogoUrl),
      mergeMap((action) =>
        this.companyHttp.getCompanyLogoUrl(action).pipe(
          map((company) =>
            companyActions.getCompanyLogoUrlSuccess({ company })
          ),
          catchError((err: HttpErrorResponse) => [
            companyActions.getCompanyLogoUrlFailed({ error: err.error })
          ])
        )
      )
    )
  );
}
