export type EhsRegEditForm = EhsRegEditFormFieldTypes;
export type EhsUserForm = EhsUserFormFieldTypes;

/**
 * Registration edit form related fields.
 */
export enum EhsRegEditFormFieldTypes {
  // Disabled, only displayed
  STATUS = 'status',
  PERSON_TYPE = 'personType',
  SERVICE_TYPE = 'service_type',

  // Editable
  GROUP_TYPE = 'group_type',
  INSURANCE_POLICY = 'insurance_policy',
  // Hidden, Editable only if a completely new insurance object is being entered
  INSURANCE_PROVIDER = 'insurance_provider',
  INSURANCE_TYPE = 'insurance_type',
  INSURANCE_GROUP = 'insurance_group'
}

/**
 * Type of the fields within the user-form-field.
 * These are the keys of the form-fields, and
 * are used for different aspects to configure the user-form.
 *
 * **note** height is displayed via the key `HEIGHT`, but is saved
 * into 2 parts: `heightFeet` and `heightInches`.
 */
export enum EhsUserFormFieldTypes {
  // User-editable fields
  EMAIL = 'email',
  PHONE = 'phone',
  ADDRESS_1 = 'address1',
  ADDRESS_2 = 'address2',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zip',

  // These are COVID specific
  COUNTY = 'countyCode',
  RACE = 'race',
  ETHNICITY = 'ethnicity',

  // These are biometric specific
  HEIGHT = 'height',
  WEIGHT = 'weight',

  // Admin-only editable fields
  LAST_NAME = 'lastName',
  FIRST_NAME = 'firstName',
  SSN = 'ssn',
  BIRTH_DATE = 'birthDay',
  GENDER = 'gender',
  PERSON_TYPE = 'personType'
}
