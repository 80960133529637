import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAudit } from '@common';
import { Observable } from 'rxjs';
const BASE_URL = '/api/v1/user-audits';

@Injectable({
  providedIn: 'root'
})
export class UserAuditHttpService {
  constructor(private http: HttpClient) {}

  /** Generate new user audit */
  public create(entity: Partial<UserAudit>): Observable<UserAudit> {
    return this.http.post<UserAudit>(`${BASE_URL}/create`, entity);
  }
}
