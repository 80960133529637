import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output
} from '@angular/core';
import { DbDocument, getId, gwappsUrls } from '@common';
import { Observable } from 'rxjs';
import { EHS_GWAPPS_ACTIONS_DISPLAY_INJECTION_TOKEN } from './ehs-gwapps-actions-display-injection-token';

@Component({
  selector: 'ehs-gwapps-actions',
  templateUrl: './ehs-gwapps-actions.component.html',
  styleUrls: ['./ehs-gwapps-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsGwappsActionsComponent {
  /**
   * The type of entity in GWApps we are to use for syncing and
   * loading the data via the links.
   *
   * **note** partially wraps:
   * ```
   * keyof GwApiUtilsFormMappings
   * ```
   *
   * except only a few are supported via the `gwppsUrls` mapping.
   */
  @Input() entityType?:
    | 'company'
    | 'eventService'
    | 'eventLocation'
    | 'userTest'
    | 'article';

  /**
   * The entity we are to reference
   */
  @Input() entity?: Pick<DbDocument<string>, '_id'>;
  /**
   * If we are loading any data, and thus all buttons are disabled
   */
  @Input() loading?: boolean;
  /**
   * Event emitted when the user clicks on the view button
   */
  @Output() view = new EventEmitter<void>();
  /**
   * Event emitted when the user selects the validate button
   */
  @Output() validate = new EventEmitter<void>();
  /**
   * The id of the entity.
   */
  public get id(): string {
    return this.entity ? getId(this.entity) : '';
  }

  /**
   * The external URL to the GWApps entity.
   */
  public get externalUrl(): string {
    return this.entityType ? gwappsUrls[this.entityType] + this.id : '';
  }

  constructor(
    @Inject(EHS_GWAPPS_ACTIONS_DISPLAY_INJECTION_TOKEN)
    @Optional()
    public displayActions$?: Observable<boolean>
  ) {}
}
