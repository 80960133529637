/**
 * The list types of Hra categories within the form.
 */
export enum HraCategoryTypes {
  EXERCISE = 'exercise',
  SAFETY = 'safety',
  DEPRESSION = 'depression',
  STRESS = 'stress',
  NUTRITION = 'nutrition',
  NICOTINE_USE = 'nicotine_use',
  HEALTH_HISTORY = 'health_history',
  DISEASE_PREVENTION = 'disease_prevention',
  ALCOHOL_USE = 'alcohol_use',
  SLEEP = 'sleep',
  GLUCOSE = 'glucose',
  HEART_HEALTH = 'heart_health'
}
/**
 * List of hra types to human friendly names
 */
export const hraCategoryNames: {
  [key in HraCategoryTypes]: string;
} = {
  [HraCategoryTypes.EXERCISE]: 'Exercise',
  [HraCategoryTypes.SAFETY]: 'Safety',
  [HraCategoryTypes.DEPRESSION]: 'Depression',
  [HraCategoryTypes.STRESS]: 'Stress',
  [HraCategoryTypes.NUTRITION]: 'Nutrition',
  [HraCategoryTypes.NICOTINE_USE]: 'Nicotine Use',
  [HraCategoryTypes.HEALTH_HISTORY]: 'Health History',
  [HraCategoryTypes.DISEASE_PREVENTION]: 'Disease Prevention',
  [HraCategoryTypes.ALCOHOL_USE]: 'Alcohol Use',
  [HraCategoryTypes.SLEEP]: 'Sleep',
  [HraCategoryTypes.GLUCOSE]: 'Glucose',
  [HraCategoryTypes.HEART_HEALTH]: 'Heart Health'
};

/**
 * List of hra types to icons
 */
export const hraCategoryIcons: {
  [key in HraCategoryTypes]: string;
} = {
  [HraCategoryTypes.EXERCISE]: 'fitness_center',
  [HraCategoryTypes.SAFETY]: 'directions_car',
  [HraCategoryTypes.DEPRESSION]: 'sentiment_dissatisfied',
  [HraCategoryTypes.STRESS]: 'priority_high',
  [HraCategoryTypes.NUTRITION]: 'local_dining',
  [HraCategoryTypes.NICOTINE_USE]: 'smoke_free',
  [HraCategoryTypes.HEALTH_HISTORY]: 'local_hospital',
  [HraCategoryTypes.DISEASE_PREVENTION]: 'healing',
  [HraCategoryTypes.ALCOHOL_USE]: 'local_drink',
  [HraCategoryTypes.SLEEP]: 'single_bed',
  [HraCategoryTypes.GLUCOSE]: 'scatter_plot',
  [HraCategoryTypes.HEART_HEALTH]: 'favorite'
};

/**
 * An Hra category represents a "tab" or step within
 * the hra form. It is used primarily for display purposes
 * within the form.
 */
export interface HraCategory {
  /**
   * The type or tab to display within the form.
   */
  type: HraCategoryTypes;
  /**
   * Icon to display in the Tabs
   */
  icon?: string;
  /**
   * The previous category we are to "go back" to
   */
  previous?: HraCategoryTypes;
  /**
   * The next category, or if we are to show the "submit"
   * button
   */
  next: HraCategoryTypes | 'submit';
  // TODO: Add icon, other form specific properties
}
