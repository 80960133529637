import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonResponse } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminCoachingSessionHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Export coaching sessions for the given company
   *
   */
  public exportCs(params: { company: string }): Observable<CommonResponse> {
    const { company } = params;

    return this.http.post<CommonResponse>('api/v1/admin/session/export', {
      company
    });
  }
}
