import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
  transform(value: boolean | undefined): string {
    if (value === undefined) {
      return 'Not Specified';
    } else {
      return value ? 'Yes' : 'No';
    }
  }
}
