import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonResponse } from '@common';
import { Action } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportService {
  constructor(private http: HttpClient) {}

  /**
   * Makes an http report to report a client-side error
   * TODO: Add more metrics, like the current user
   * and modify the error to correctly report error
   */
  public reportError(params: {
    /**
     * The original action that caused an error
     */
    action: Action;
    /**
     * The client-side error we will try to report
     */
    err: Error | any;
  }) {
    const { err, action } = params;

    return this.http.post<CommonResponse>('api/v1/error', { err, action });
  }
}
