export enum MimeFileExtensions {
  'application/pdf' = 'pdf',
  'image/svg+xml' = 'svg',
  'image/tiff' = 'tiff',
  'image/webp' = 'webp',
  'image/png' = 'png',
  'image/jpeg' = 'jpeg',
  'image/gif' = 'gif',
  'image/apng' = 'apng'
}
