<h1 mat-dialog-title>
  Change Additional Tests
</h1>

<mat-dialog-content>
  <div class="mat-body-1"
       *ngIf="additionalUserTests">
    <ng-container *ngIf="!additionalUserTests.length">
      <div>
        No additional tests to change
      </div>
    </ng-container>
    <div fxLayout="column"
         fxLayoutAlign="start start"
         fxLayoutGap="16px">
      <div *ngFor="let additionalTest of additionalUserTests">
        <mat-checkbox #additionalTestCheckbox
                      (change)="toggleAdditionalTest(additionalTest)"
                      [checked]="isAdditionalChecked(additionalTest)"
                      color="primary">
          {{getTestName(additionalTest)}}
        </mat-checkbox>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end"
                    fxLayoutGap="16px">
  <button type="button"
          class="grey-cancel"
          mat-button
          [mat-dialog-close]="undefined">
    Cancel
  </button>
  <button type="button"
          color="primary"
          mat-button
          (click)="close()"
          cdkFocusInitial>
    Confirm
  </button>
</mat-dialog-actions>
