import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomReportImages, getId, Company } from '@common';
import { Observable } from 'rxjs';

const BASE_URL = '/api/v1/admin/custom-reports';

@Injectable({
  providedIn: 'root'
})
export class CustomReportHttpService {
  constructor(private http: HttpClient) {}

  public get(id: string) {
    return this.http.get<CustomReportImages>(`${BASE_URL}/${id}`);
  }

  public listByCompany(company: Company | string) {
    return this.http.get<CustomReportImages[]>(
      `${BASE_URL}/company/${getId(company)}`
    );
  }

  public upsert(params: {
    customReport: Partial<CustomReportImages>;
  }): Observable<CustomReportImages> {
    return this.http.put<CustomReportImages>(BASE_URL, params);
  }

  /**
   * Returns a signed url to upload a provider user registration result file.
   */
  public getCustomReportUploadSignedUrl(params: {
    /**
     * The id of the corresponding CustomReport object
     */
    id: string;
    /**
     * The type of the file we will upload
     */
    contentType: string;
  }): Observable<{ url: string; fileId: string }> {
    const { contentType, id } = params;

    return this.http.get<{ url: string; fileId: string }>(
      `${BASE_URL}/upload/url`,
      {
        params: { contentType, id }
      }
    );
  }

  /**
   * Returns a signed url to download a CustomReport page image
   */
  public getCustomReportDownloadSignedUrl(params: {
    /**
     * The id of the corresponding CustomReport object
     */
    id: string;
    /** The id generated for the file in google storage */
    fileId: string;
  }): Observable<{ url: string }> {
    const { id, fileId } = params;

    return this.http.get<{ url: string }>(
      `${BASE_URL}/${id}/download/url/${fileId}`
    );
  }

  public remove(customReportImages: CustomReportImages) {
    const customReportImagesId = getId(customReportImages);

    return this.http.delete<CustomReportImages>(
      `${BASE_URL}/${customReportImagesId}`
    );
  }
}
