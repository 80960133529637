import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonResponse, InsuranceClaim } from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminInsuranceClaimHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Submits the insurance claim form to the backend
   */
  public createInsuranceClaim(
    insuranceClaim: InsuranceClaim
  ): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      'api/v1/admin/insurance/claim/create',
      insuranceClaim
    );
  }
}
