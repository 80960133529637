/* eslint-disable @typescript-eslint/no-explicit-any */

import { User, CommonResponse } from '@common';
import { entityHttpActionFactory } from '@ehs-ngrx/common';
import { createAction, props } from '@ngrx/store';

export const clientAdminCoreNgrxActions = {
  set: createAction(
    '[CLIENT_ADMIN_CORE_NGRX] SET',
    props<{
      user: User;
    }>()
  ),

  list: entityHttpActionFactory<
    undefined,
    {
      entities: User[];
    }
  >('[CLIENT_ADMIN_CORE_NGRX] LIST'),

  addClientAdmin: entityHttpActionFactory<
    string,
    undefined,
    { error: CommonResponse }
  >('[CLIENT_ADMIN_CORE_NGRX] ADD_ADMIN'),

  removeClientAdmin: entityHttpActionFactory<
    User,
    { adminId: string },
    { error: CommonResponse }
  >('[CLIENT_ADMIN_CORE_NGRX] REMOVE_ADMIN'),

  update: entityHttpActionFactory<
    { admin: User },
    { admin: User },
    { error: CommonResponse }
  >('[CLIENT_ADMIN_CORE_NGRX] UPDATE')
};
