<table mat-table
       [dataSource]="userResults"
       style="width: 100%">
  <ng-container matColumnDef="createdAt">
    <div>
      <th mat-header-cell
          [ngStyle]="{'padding-left': '0px;'}"
          *matHeaderCellDef> Screening Date </th>
      <td mat-cell
          [ngStyle]="{'padding-left': '0px;'}"
          *matCellDef="let userResult">
        <span>
          <!--
            TODO: Change to "date of event/date of collection"
            Will need to review this is correct
          -->
          {{userResult.collectionDate | date:'mediumDate' : 'UTC'}}
        </span>
      </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="lab">
    <div>
      <th mat-header-cell
          *matHeaderCellDef> Lab </th>
      <td mat-cell
          *matCellDef="let userResult">
        {{ userResult.labType | titlecase}}
      </td>
    </div>
  </ng-container>


  <ng-container matColumnDef="serviceType">
    <div>
      <th mat-header-cell
          *matHeaderCellDef> Service Type </th>
      <td mat-cell
          *matCellDef="let userResult">
        {{ getUserRegistration(userResult)?.serviceType | titlecase}}
      </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="requisition">
    <div>
      <th mat-header-cell
          *matHeaderCellDef> Requisition </th>
      <td mat-cell
          *matCellDef="let userResult">
        {{ getRequisition(userResult)}}
      </td>
    </div>
  </ng-container>


  <ng-container matColumnDef="hpn">
    <div>
      <th mat-header-cell
          *matHeaderCellDef> Import </th>
      <td mat-cell
          *matCellDef="let userResult">
        <mat-icon [title]="userResult.hpn ? 'HPN Import' : 'Lab'"
                  [ngStyle]="{color:userResult.hpn ? '#1b5e20' : '#FF0000'}">
          {{userResult.hpn ? 'check' : 'close'}}
        </mat-icon>
      </td>
    </div>
  </ng-container>

  <ng-container matColumnDef="actions">
    <div>
      <th mat-header-cell
          *matHeaderCellDef> </th>
      <td mat-cell
          [ngStyle]="{'padding-right': '1px;'}"
          *matCellDef="let userResult">
        <div fxLayout="row"
             fxLayoutAlign="end"
             fxLayoutGap="8px">

          <a mat-flat-button
             *ngIf="companyId"
             title="Opens the raw data-correction tool"
             [routerLink]="getEditUrl(userResult)"
             [queryParams]="{
               redirect: 'user'
             }">
            <mat-icon>edit</mat-icon>
            Edit
          </a>

          <button mat-icon-button
                  type="button"
                  aria-label="Menu"
                  [matMenuTriggerFor]="userActionMenu"
                  [matMenuTriggerFor]="userActionMenu"
                  [matMenuTriggerData]="{ userResult: userResult }">
            <mat-icon>
              more_vert
            </mat-icon>
          </button>
          <!-- TODO: Add menu options-->

          <mat-menu #userActionMenu="matMenu">
            <ng-template matMenuContent
                         let-userResult="userResult">

              <ng-container *ngFor="let action of getUserResultSpecificActions(userResult); trackBy: trackByFn">
                <ng-container *ngIf="action.type === 'viewEventService' else otherActions">
                  <a mat-menu-item
                     title="View Event Service"
                     [routerLink]="getViewLink(userResult)">
                    <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
                    View Event Service
                  </a>
                </ng-container>
                <ng-template #otherActions>
                  <button mat-menu-item
                          (click)="actionChange.emit({ actionType: $any(action.type), userResult})"
                          [title]="action.name"
                          type="button">
                    <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
                    {{action.name}}
                  </button>
                </ng-template>
              </ng-container>

            </ng-template>
          </mat-menu>
        </div>
      </td>
    </div>
  </ng-container>

  <tr mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row
      *matRowDef="let userResult; columns: displayedColumns;"></tr>

</table>
