import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';

import { EhsAddWaiverDialogComponent } from './ehs-add-waiver-dialog.component';
import { EhsAddWaiverDialogData } from './ehs-add-waiver-dialog.data';
import { getId } from '@common';
import { EhsAddWaiverDialogResponse } from './ehs-add-waiver-dialog.response';

@Injectable()
export class EhsAddWaiverialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: EhsAddWaiverDialogData
  ): Observable<EhsAddWaiverDialogResponse | false> {
    if (!data.eventService) {
      return of(false);
    }

    return this.matDialog
      .open(EhsAddWaiverDialogComponent, { data, width: '300px' })
      .afterClosed();
  }
}
