<div fxLayout="row wrap"
     fxLayoutAlign="start start"
     fxLayoutGap="8px"
     style="margin-bottom:8px">

  <!-- First Name -->
  <mat-form-field style="width: 200px">
    <mat-label>
      First Name
    </mat-label>
    <input matInput
           autocomplete="off"
           [value]="userFilter.firstName || ''"
           (change)="onChange({ firstName: $any($event.target).value })"
           (keyup)="onKeyupChange({ firstName: $any($event.target).value })"
           type="text">
    <button mat-icon-button
            matSuffix
            *ngIf="userFilter.firstName"
            type="button"
            title="Clear first name"
            (click)="onChange({ firstName: undefined })"
            aria-label="Clear first name">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
    <mat-hint>Min 3 characters</mat-hint>
  </mat-form-field>

  <!-- Last Name -->
  <mat-form-field style="width: 200px">
    <mat-label>
      Last Name
    </mat-label>
    <input matInput
           autocomplete="off"
           [value]="userFilter.lastName || ''"
           (change)="onChange({ lastName: $any($event.target).value })"
           (keyup)="onKeyupChange({ lastName: $any($event.target).value })"
           type="text">
    <button mat-icon-button
            matSuffix
            *ngIf="userFilter.lastName"
            type="button"
            (click)="onChange({ lastName: undefined })"
            title="Clear last name"
            aria-label="Clear last name">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
    <mat-hint>Min 3 characters</mat-hint>
  </mat-form-field>

  <!-- Username -->
  <mat-form-field style="width: 200px"
                  *ngIf="!clientAdmin">
    <mat-label>
      Username
    </mat-label>
    <input matInput
           autocomplete="off"
           [value]="userFilter.username || ''"
           (change)="onChange({ username: $any($event.target).value })"
           (keyup)="onKeyupChange({ username: $any($event.target).value })"
           type="text">
    <button mat-icon-button
            matSuffix
            (click)="onChange({ username: undefined })"
            *ngIf="userFilter.username"
            type="button"
            title="Clear username"
            aria-label="Clear username">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- SSN -->
  <mat-form-field style="max-width: 120px;"
                  *ngIf="!clientAdmin">
    <mat-label>SSN</mat-label>
    <input type="number"
           inputmode="numeric"
           [value]="userFilter.ssn || ''"
           (change)="onChange({ ssn: $any($event.target).value })"
           (keyup)="onKeyupChange({ ssn: $any($event.target).value })"
           placeholder="SSN"
           matInput>
    <mat-hint>Last 4 of SSN</mat-hint>
    <button mat-icon-button
            matSuffix
            (click)="onChange({ ssn: undefined })"
            *ngIf="userFilter.ssn"
            type="button"
            title="Clear ssn"
            aria-label="Clear ssn">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Short ID -->
  <mat-form-field style="max-width: 180px;"
                  *ngIf="!clientAdmin">
    <mat-label>Short ID</mat-label>
    <input type="text"
           [value]="userFilter.shortId || ''"
           (change)="onChange({ shortId: $any($event.target).value })"
           (keyup)="onKeyupChange({ shortId: $any($event.target).value })"
           placeholder="Short ID"
           maxlength="12"
           minlength="12"
           matInput>
    <mat-hint>12 Characters</mat-hint>
    <button mat-icon-button
            matSuffix
            (click)="onChange({ shortId: undefined })"
            *ngIf="userFilter.shortId"
            type="button"
            title="Clear Short ID"
            aria-label="Clear Short Id">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
  </mat-form-field>

  <!-- Birthday -->
  <mat-form-field *ngIf="!clientAdmin">
    <mat-label>Birth Date</mat-label>
    <input matInput
           placeholder="MM/DD/YYYY"
           type="date"
           [value]="userFilter.birthDay || ''"
           (change)="onChange({ birthDay: $any($event.target).value })"
           autocomplete="off"
           birthDate>
    <button mat-icon-button
            matSuffix
            type="button"
            *ngIf="userFilter.birthDay"
            title="Clear Birthday"
            (click)="onChange({ birthDay: undefined })"
            aria-label="Clear birthday">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
    <mat-hint> MM/DD/YYYY (e.g 02/20/2020) </mat-hint>
  </mat-form-field>

  <!-- Company -->
  <mat-form-field *ngIf="!hideCompany"
                  style="max-width: 240px;">
    <mat-label>Company</mat-label>
    <input type="text"
           [value]="company"
           (keyup)="onKeyupChange({ company: $any($event.target).value })"
           placeholder="Company"
           autocomplete="off"
           [matAutocomplete]="companyAuto"
           matInput>
    <button mat-icon-button
            matSuffix
            (click)="onChange({ company: undefined })"
            *ngIf="userFilter.company"
            type="button"
            title="Clear Company"
            aria-label="Clear Company">
      <mat-icon class="icon-small">close</mat-icon>
    </button>
    <mat-autocomplete #companyAuto="matAutocomplete"
                      (optionSelected)="onChange({ company: $event.option.value })">
      <mat-option *ngFor="let company of companiesArr"
                  [value]="company">
        {{ company.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- Search Button -->
  <div class="align-with-mat-field-with-hint-start"><button mat-flat-button
            type="button"
            color="primary"
            style="height: 50px;"
            (click)="search.emit()">
      <mat-icon class="search-button">search</mat-icon>
    </button></div>

  <!-- Clear Button -->
  <div class="align-with-mat-field-with-hint-start"><button mat-flat-button
            *ngIf="hasFilters()"
            title="Clear Filters"
            type="button"
            color="accent"
            style="height: 50px;"
            (click)="clear.emit();">
      <mat-icon class="search-button">close</mat-icon>
    </button></div>

</div>