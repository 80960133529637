import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { userTestActions } from './user-test.actions';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { UserTestHttpService } from '../../http/user-test-http.service';
import { errorReportActions } from '../error-report/error-report.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserTestEffects {
  constructor(
    private actions$: Actions,
    private userTestHttp: UserTestHttpService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userTestActions.get),
      mergeMap((action) =>
        this.userTestHttp.get(action.id).pipe(
          map((entity) => userTestActions.getSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            userTestActions.getFailed(),
            errorReportActions.report({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userTestActions.list),
      mergeMap((action) =>
        this.userTestHttp.list(action.query).pipe(
          map((entities) => userTestActions.listSuccess({ entities })),
          catchError((err: HttpErrorResponse) => [
            userTestActions.listFailed(),
            errorReportActions.report({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userTestActions.listAsAdmin),
      mergeMap((action) =>
        this.userTestHttp.listAsAdmin(action).pipe(
          map((entities) => userTestActions.listAsAdminSuccess({ entities })),
          catchError((err: HttpErrorResponse) => [
            userTestActions.listAsAdminFailed({ err: err.error }),
            errorReportActions.report({
              action,
              err: err.error
            })
          ])
        )
      )
    )
  );
}
