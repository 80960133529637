import { createAction, props } from '@ngrx/store';
import { EntitySelectionActions } from './entity-selection-actions';

/**
 * Returns a list of common "selection" actions,
 * unlike the `entityActionFactory` logic, this version should
 * be used **directly** with others as an all-in-one solution to manage
 * ngrx selections in a single place.
 *
 * **note** this does not include a "select-all", as such actions
 * could possibly miss unloaded assets. As such, all ids must be
 * passed to the `selectMany` action manually.
 *
 * @params prefix - the prefix of the action, usually TITLE_CASE:
 * `ENTITY_NAME`
 */
export const entitySelectionActionFactory = <
  DocumentKey extends string,
  Prefix extends string
>(
  prefix: Prefix
): EntitySelectionActions<DocumentKey, Prefix> => ({
  /**
   * Clears the selection state.
   */
  selectionClear: createAction(`[${prefix}] SELECTION_CLEAR`),
  /**
   * Action to select a given element. If that element
   * is already selected, then this doesn't do anything.
   */
  select: createAction(`[${prefix}] SELECT`, ({ id }) => ({ id })),
  /**
   * Action to unselect a given element.
   * If that element is not selected, then this
   * doesn't do anything.
   */
  unselect: createAction(
    `[${prefix}] UNSELECT`,
    props<{
      id: DocumentKey;
    }>()
  ),
  /**
   * Action to select multiple elements.
   * If the given elements are already selected, this action doesn't do
   * anything.
   */
  selectMany: createAction(
    `[${prefix}] SELECT_MANY`,
    props<{
      ids: DocumentKey[];
    }>()
  ),
  /**
   * Action to unselect multiple elements.
   * If the given elements are not selected, this action doesn't do
   * anything.
   */
  unselectMany: createAction(
    `[${prefix}] UNSELECT_MANY`,
    props<{
      ids: DocumentKey[];
    }>()
  ),
  /**
   * Action to toggle the selection of the given element.
   *
   * **note** it is more clear if the select or unselect varients are used.
   */
  selectionToggle: createAction(
    `[${prefix}] SELECTION_TOGGLE`,
    props<{
      id: DocumentKey;
    }>()
  )
});
