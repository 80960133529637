/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Generic map function used to convert an array to
 * a map with the given key. Key defaults to _id
 */
export const toMap = <T>(params: {
  /**
   * The key to use, defaults to _id
   */
  key?: keyof T;
  /**
   * The map of entities
   */
  entities: T[];
}): Record<keyof T, T> => {
  const { key = '_id', entities } = params;

  if (!entities) {
    return {} as Record<keyof T, T>;
  }

  return entities.reduce((acc, entity) => {
    acc[entity[key as any]] = entity;

    return acc;
  }, {} as Record<keyof T, T>);
};
