<div class="ehs-user-criticals-table ehs-table-wrapper">
  <cdk-virtual-scroll-viewport itemSize="40"
                               minBufferPx="40"
                               maxBufferPx="500"
                               class="viewport">
    <table class="ehs-table sticky-header">
      <tr>
        <th *ngFor="let col of columns; let i = index"
            [style.top]="headerTop"
            [style.--width]="i === columns.length - 1 ? '100%' : col.width">
          {{ col.title }}
        </th>
      </tr>

      <tr *cdkVirtualFor="let row of rows"
          (click)="rowClicked.emit(row._id)"
          class="row">
        <td *ngFor="let col of columns">
          <ng-container *ngIf="row.labType !== 'ehs'"
                        [ngSwitch]="col.prop">
            <ng-container *ngSwitchCase="'name'">
              {{ row.firstName }} {{ row.lastName }}
            </ng-container>
            <ng-container *ngSwitchCase="'birthDay'">
              {{ getAge(row) }}
            </ng-container>
            <ng-container *ngSwitchCase="'gender'">
              {{ row.gender | titlecase }}
            </ng-container>
            <ng-container *ngSwitchCase="'collectionDate'">
              {{ row.collectionDate | date: 'mediumDate': '-00:00' }}
            </ng-container>
            <ng-container *ngSwitchCase="'labType'">
              <!-- this formatting is dumb, but is what my editor is doing.-->
              {{
              ['labcorp', 'quest'].includes(row.labType)
              ? row.labType
              : 'EHS Biometric'
              }}
            </ng-container>
            <ng-container *ngSwitchCase="'status'">
              <mat-chip-listbox>
                <mat-chip-option [color]="row.status === 'open' ? 'warn' : ''"
                                 selected>
                  {{ row.status | titlecase }}
                </mat-chip-option>
              </mat-chip-listbox>
            </ng-container>
            <ng-container *ngSwitchCase="'notes'">
              <ng-container *ngIf="row.notes.length">
                Last Note Created: {{ row.notes[row.notes.length - 1].createdAt
                | date: 'mediumDate' }}
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span [title]="row[col.prop]">{{ row[col.prop] }}</span>
            </ng-container>
          </ng-container>
        </td>
      </tr>

      <tr *ngIf="hasNext">
        <button (click)="loadMore.emit()"
                style="margin: 16px 8px 24px"
                mat-flat-button
                color="primary"
                type="button">
          Load More
        </button>
      </tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>