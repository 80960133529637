import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { JsonDialogComponent } from './json-dialog.component';
import { JsonDialogService } from './json-dialog.service';

@NgModule({
  declarations: [JsonDialogComponent],
  imports: [
    CommonModule,

    // Angular Material
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule
  ],
  providers: [JsonDialogService]
})
export class JsonDialogModule {}
