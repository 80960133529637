import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayWeightPipe } from './display-weight.pipe';
import { DisplayHeightPipe } from './display-height.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { EmailNamePipe } from './email-name.pipe';
import { TimeAmPmPipe } from './time-ampm.pipe';
import { ServiceTypePipe } from './service-type.pipe';
import { NameCasePipe } from './namecase.pipe';
import { TimeSincePipe } from './time-since.pipe';
import { DisplayBloodPressure } from './display-blood-pressure.pipe';
import { ConvertTextToUrlsPipe } from './convert-text-to-url.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [
    TimeAmPmPipe,
    DisplayHeightPipe,
    DisplayWeightPipe,
    DisplayBloodPressure,
    YesNoPipe,
    EmailNamePipe,
    ServiceTypePipe,
    NameCasePipe,
    TimeSincePipe,
    ConvertTextToUrlsPipe,
    SafeUrlPipe,
    SafeHtmlPipe
  ],
  exports: [
    TimeAmPmPipe,
    DisplayHeightPipe,
    DisplayWeightPipe,
    DisplayBloodPressure,
    YesNoPipe,
    EmailNamePipe,
    ServiceTypePipe,
    NameCasePipe,
    TimeSincePipe,
    ConvertTextToUrlsPipe,
    SafeUrlPipe,
    SafeHtmlPipe
  ],
  imports: [CommonModule]
})
export class PipesModule {}
