<form novalidate
      style="max-height: calc(100vh - 355px); overflow-y: auto;"
      #form="ngForm">
  <table mat-table
         [dataSource]="timeSlots$ | async"
         style="width: 100%">

    <ng-container matColumnDef="time">
      <th mat-header-cell
          *matHeaderCellDef>
        Time Slot
      </th>
      <td mat-cell
          *matCellDef="let timeSlot">
        {{ toAmPm(timeSlot.time)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="screeningRate">
      <th mat-header-cell
          *matHeaderCellDef>
        Screening Rate
      </th>
      <td mat-cell
          *matCellDef="let timeSlot">
        <ng-container
                      *ngIf="editingTime === timeSlot.time; else showScreeningRate">
          <!-- Add margin top to "center" the input -->

          <mat-form-field style="margin-top: 18px">
            <mat-label>Screening Rate</mat-label>
            <input type="number"
                   matInput
                   [name]="'screeningRate_' + timeSlot.time"
                   #screeningRateInput="ngModel"
                   [(ngModel)]="$any(customTime).screening"
                   [min]="timeSlot.registered?.screening || 0">
            <mat-error *ngIf="screeningRateInput.hasError('min')">
              Value is too low
            </mat-error>
          </mat-form-field>

        </ng-container>
        <ng-template #showScreeningRate>
          <div [ngStyle]="getScreeningRateStyle(timeSlot)">
            {{ getScreeningRate(timeSlot)}}
          </div>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="registeredScreening">
      <th mat-header-cell
          *matHeaderCellDef>
        Registered Screening
      </th>
      <td mat-cell
          *matCellDef="let timeSlot">
        <ng-container
                      *ngIf="registrationTimesMap$ | async as registrationTimesMap">
          {{ registrationTimesMap?.[timeSlot.time]?.screening || 0}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="fluRate">
      <th mat-header-cell
          *matHeaderCellDef>
        Flu Rate
      </th>
      <td mat-cell
          *matCellDef="let timeSlot">
        <ng-container *ngIf="editingTime === timeSlot.time; else showFluRate">
          <!-- Add margin top to "center" the input -->
          <mat-form-field style="margin-top: 18px">
            <mat-label>Flu Rate</mat-label>
            <input type="number"
                   matInput
                   [name]="'fluRate_' + timeSlot.time"
                   #fluRateInput="ngModel"
                   [(ngModel)]="$any(customTime).flu"
                   [min]="timeSlot.registered?.flu || 0">
            <mat-error *ngIf="fluRateInput.hasError('min')">
              Value is too low
            </mat-error>
          </mat-form-field>
        </ng-container>
        <ng-template #showFluRate>
          <div [ngStyle]="getFluRateStyle(timeSlot)">
            {{ getFluRate(timeSlot)}}
          </div>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="registeredFlu">
      <th mat-header-cell
          *matHeaderCellDef>
        Registered Flu
      </th>
      <td mat-cell
          *matCellDef="let timeSlot">
        <ng-container
                      *ngIf="registrationTimesMap$ | async as registrationTimesMap">
          {{ registrationTimesMap?.[timeSlot.time]?.flu || 0}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell
          *matHeaderCellDef>
        <mat-spinner *ngIf="loading"
                     class="circle-center"
                     diameter="24"></mat-spinner>
      </th>
      <td mat-cell
          *matCellDef="let timeSlot">
        <div fxLayout="row"
             fxLayoutAlign="start center"
             fxLayoutGap="8px">

          <ng-container *ngIf="editingTime === timeSlot.time; else showEdit">
            <!--
              we are editing show the "close" button to "stop editing",
              updates happen automatically rather than on a "save" event
            -->
            <button mat-icon-button
                    type="submit"
                    (click)="onStopEditingCustomTime()"
                    [title]="form.valid ? 'stop editing' : 'fields are invalid'"
                    aria-label="stop editing"
                    [ngStyle]="form.valid ? { color: 'green' } : { color: 'grey' }"
                    [disabled]="form.invalid || loading">
              <mat-icon>done</mat-icon>
            </button>
          </ng-container>
          <ng-template #showEdit>

            <!--
              Editing mode will "focus" the component on this
              timeSlot. But changes are not permanent until the user his
              "save" to submit their changes to the parent component,
              which will handle the actual proccess of updating the data.

              This is disabled when the user is editing a time slot
            -->
            <button mat-icon-button
                    type="button"
                    (click)="onEditingCustomTime(timeSlot)"
                    title="edit time"
                    aria-label="edit time"
                    [disabled]="editingTime || loading">
              <mat-icon>edit</mat-icon>
            </button>
          </ng-template>

          <!-- always take up this much room to prevent "shifting" when the trash icon doesn't exist-->
          <div style="min-width: 40px">
            <ng-container *ngIf="timeSlot.customTime">
              <!--
            If there is a custom time for this slot, then we can "delete" this custom time

            This is disabled when the user is editing a time slot
          -->
              <button mat-icon-button
                      type="button"
                      (click)="onRemoveCustomTime(timeSlot)"
                      title="delete custom time"
                      aria-label="delete custom time"
                      [disabled]="removeDisabled(timeSlot)">
                <mat-icon>delete</mat-icon>
              </button>
            </ng-container>
          </div>

        </div>
      </td>
    </ng-container>




    <tr mat-header-row
        *matHeaderRowDef="columns$ | async; sticky: true"></tr>
    <tr mat-row
        *matRowDef="let timeSlot; columns: columns$ | async"></tr>
  </table>

</form>
