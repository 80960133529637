import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerifyEmailDialogComponent } from './ehs-verify-email-dialog.component';
import { EhsVerifyEmailDialogService } from './ehs-verify-email-dialog.service';
import { FlexLayoutModule } from 'ng-flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [VerifyEmailDialogComponent],
  imports: [
    CommonModule,
    // Angular material
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [VerifyEmailDialogComponent],
  providers: [EhsVerifyEmailDialogService]
})
export class EhsVerifyEmailDialogModule {}
