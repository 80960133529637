import { AdminUser } from '@common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, Action, on } from '@ngrx/store';
import { selectId } from '../utils/select-id';
import { adminCoreActions } from './admin-core.actions';
/**
 * This is the admin-core state, currently primarily used
 * for "meta" admin features, with primary focus on admin-user features.
 *
 * Try to not use that much.
 */
export interface AdminCoreState extends EntityState<AdminUser> {
  loading?: boolean;
  user?: AdminUser;
}

const adapter = createEntityAdapter<AdminUser>({
  selectId
});

const reducer = createReducer<AdminCoreState>(
  adapter.getInitialState(),
  on(adminCoreActions.setMultipleAdminUsers, (state, { users }) =>
    adapter.upsertMany(users, state)
  ),
  on(adminCoreActions.getAdminUser, (state) => ({ ...state, loading: true })),
  on(adminCoreActions.getAdminUserSuccess, (state, { user }) => ({
    ...state,
    user,
    loading: false
  })),
  on(adminCoreActions.getAdminUserFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(adminCoreActions.getAdminList, (state) => ({ ...state, loading: true })),
  on(adminCoreActions.getAdminListSuccess, (state, { users }) =>
    adapter.upsertMany(users, {
      ...state,
      loading: false
    })
  ),
  on(adminCoreActions.getAdminListFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(adminCoreActions.createAdmin, (state) => ({
    ...state,
    loading: true
  })),
  on(adminCoreActions.createAdminSuccess, (state) => ({
    ...state,
    loading: false
  })),
  on(adminCoreActions.createAdminFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(adminCoreActions.updateAdminScopes, (state) => ({
    ...state,
    loading: true
  })),
  on(adminCoreActions.updateAdminScopesSuccess, (state) => ({
    ...state,
    loading: false
  })),
  on(adminCoreActions.updateAdminScopesFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(adminCoreActions.deleteAdmin, (state) => ({
    ...state,
    loading: true
  })),
  on(adminCoreActions.deleteAdminSuccess, (state) => ({
    ...state,
    loading: false
  })),
  on(adminCoreActions.deleteAdminFailed, (state) => ({
    ...state,
    loading: false
  }))
);

export function AdminCoreReducer(state: AdminCoreState, action: Action) {
  return reducer(state, action);
}
