/**
 * Takes a string, and makes it title-case.
 * If given multiple words, it makes every word title case.
 * If dashes, spaces or apostrophe's are included, the trailing character is capitalized
 */
export const toNameCase = (str: string): string => {
  if (!str || typeof str !== 'string') {
    return '';
  }

  const strArr = str.split('');

  str = strArr
    .map((char, idx) => {
      if (idx === 0) {
        return char.toUpperCase();
      }

      const prevChar = strArr[idx - 1];

      // Lineage suffix
      if (prevChar === ' ' && char === 'i' && strArr[idx + 1] === 'i') {
        return char.toLowerCase();
      }

      if (prevChar === ' ' || prevChar === '-' || prevChar === "'") {
        return char.toUpperCase();
      }

      return char;
    })
    .join('')
    .trim();

  str = str.replace(/ Iii/gi, ' III');
  str = str.replace(/ Ii/gi, ' II');

  return str;
};
