import { ObjectId } from 'mongodb';
import { DbDocument } from '../db-document';
import { EventService } from '../event-service/event-service';
import { Company } from '../company';

export enum HealthProgramType {
  WALKING = 'walking'
}

/**
 * A physical activity tracking program tied to a service
 * which allows users to track their progress and compare
 * with others in their organization.
 */
export interface HealthProgram extends DbDocument {
  /**
   * The displayed name of the program
   */
  name: string;
  /**
   * The type of health program. Currently only walking is supported.
   */
  type: HealthProgramType;
  /**
   * The associated event service. Used to determine date ranges.
   */
  eventService: string | ObjectId | EventService;
  /**
   * The associated company. Used to search for included users.
   */
  company: string | ObjectId | Company;
  /**
   * A description of the program and its goals.
   */
  description: string;
  /**
   * The maximum number of users that can be on a team.
   */
  maxTeamSize: number;
  /**
   * Start date of the program
   */
  startDate: string;
  /**
   * End date of the program
   */
  endDate: string;
}
