import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { UserVaccination } from '@common';
import { createReducer, Action, on } from '@ngrx/store';
import { selectId } from '../utils/select-id';
import { userVaccinationActions } from './user-vaccination.actions';

export interface UserVaccinationState extends EntityState<UserVaccination> {
  loading?: boolean;
  faxLoading?: boolean;
}

const adapter = createEntityAdapter<UserVaccination>({
  selectId
});

const reducer = createReducer<UserVaccinationState>(
  adapter.getInitialState({}),
  on(userVaccinationActions.get, (state) => ({ ...state, loading: true })),
  on(userVaccinationActions.getSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(userVaccinationActions.getFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(userVaccinationActions.getByUserReg, (state) => ({
    ...state,
    loading: true
  })),
  on(userVaccinationActions.getByUserRegSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(userVaccinationActions.getByUserRegFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(userVaccinationActions.list, (state) => ({ ...state, loading: true })),
  on(userVaccinationActions.listSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(userVaccinationActions.listFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(userVaccinationActions.set, (state, { userVaccination }) =>
    adapter.upsertOne(userVaccination, state)
  )
);

export function UserVaccinationReducer(
  state: UserVaccinationState,
  action: Action
) {
  return reducer(state, action);
}
