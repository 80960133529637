import { Injectable } from '@angular/core';
import { UserCriticalsColumn } from './user-criticals-column';

@Injectable({
  providedIn: 'root'
})
export class EhsUserCriticalsTableService {
  getColumns(): UserCriticalsColumn[] {
    return [
      {
        title: 'Name',
        prop: 'name',
        width: '230px'
      },
      {
        title: 'Age',
        prop: 'birthDay',
        width: '55px'
      },
      {
        title: 'Gender',
        prop: 'gender',
        width: '80px'
      },
      {
        title: 'Phone',
        prop: 'phone',
        width: '100px'
      },
      {
        title: 'Email',
        prop: 'email',
        width: '150px'
      },
      {
        title: 'Client',
        prop: 'companyName',
        width: '200px'
      },
      {
        title: 'Collection Date',
        prop: 'collectionDate',
        width: '170px'
      },
      {
        title: 'Status',
        prop: 'status',
        width: '140px'
      },
      {
        title: 'Lab Type',
        prop: 'labType',
        width: '150px'
      },
      {
        title: 'Notes',
        prop: 'notes',
        width: '200px'
      }
    ];
  }
}
